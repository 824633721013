import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';

@Component({
  selector: 'app-language-test-card',
  templateUrl: './language-test-card.component.html',
  styleUrls: ['./language-test-card.component.scss']
})
export class LanguageTestCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the country */
  @Input() id: string;
  /** Variable that holds the name of the test */
  @Input() name: string;
  /** Variable that holds the name of the country of the service */
  @Input() language: string;
  /** Variable that holds the price of the service */
  @Input() cost: number;
  /** Variable that holds the currency of the service */
  @Input() currency: string;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the provider */
  @Input() provider: string;
  /** Variable that holds the type of the test */
  @Input() type: string;

  constructor(private shoppingCartService: ShoppingCartService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onAddToCart(): void {
    this.shoppingCartService.addLanguageTest({
      id: this.id
    });
  }

  onAdditionalInfoClick(): void {
    this.dialog.open(ServiceDetailModalComponent, {
      data: {
        service: 'language',
        name: this.name,
        language: this.language,
        cost: this.cost,
        currency: this.currency,
        provider: this.provider,
        image: this.image,
        type: this.type,
      }
    });
  }
}
