import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-one-time-fee',
  templateUrl: './one-time-fee.component.html',
  styleUrls: ['./one-time-fee.component.scss']
})
export class OneTimeFeeComponent implements OnInit {

  /** CONSTANTS */
  /** API route for retrieving configurations */
  readonly CONFIG_API = 'api/config/'
  /** API route for creating payments */
  readonly PAYMENT_U360_API = 'api/pagou360';
  /** Api for submitting the transactions */
  readonly TRANSACTION_API = 'api/transaccion/';
  /** API for creating payment signatures */
  readonly SIGNATURE_API = 'api/transaccion/payU/token/';
   /** API for making payments */
   readonly PAYMENT_API = 'api/transaccion/payU/pay';
   /** API for the user data */
  readonly USER_API = 'api/user/';
  /** Variable that holds the api for getting  the information of the legal representative by student*/
  readonly LEGAL_REPRESENTATIVE_API = 'api/acudiente/estudiante/';
  /** Variable that holds the api for getting the academic history by student*/
  readonly ACADEMIC_HISTORY_API = 'api/academic-history/estudiante/';
  /** API route for retrieving prices */
  readonly PAYMENT_APLICATION_API = 'api/aplicacion/';
  /** VARIABLES */
  /** Variable that holds the id of the current user */
  userId: string = '';

  /** Variable that holds the id of the current application */
  applicationId: string = '';

  /** Reference Code for PayU */
  refCode: string = '';

  /** Current page status
   * 0 --> Purchase overview page
   * 1 --> Checkout page
   */
  pageStatus = 0;
  /** Subtotal of the item that is going to be paid */
  subtotal = 0;
  /** Discount of the item that is going to be paid (if applicable) */
  discount = 0;
  /** Total price of the item that is going to be paid */
  price = 0;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private ngxSpinner: NgxSpinnerService,
              private location: Location,
              private translate: TranslateService,
              private navigationService: NavigationService,
              private activatedRoute: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId) { }

  ngOnInit(): void {
    this.getU360PaymentPrice();
  }

  /**
   * Retrieves the U360 payment price from the backend
   */
  getU360PaymentPrice(): void {
    this.ngxSpinner.show();
    this.applicationId = this.activatedRoute.snapshot.queryParams.aplicacion;
    this.apiService.get({api: this.PAYMENT_APLICATION_API + this.applicationId }).subscribe((response) => {
      // 
      if(response.u360Fee !== null && response.u360FeeConfirmado){
        this.subtotal = response.u360Fee;
      } else {
        this.subtotal = 600000;
      }
      this.userId = this.activatedRoute.snapshot.params.estudiante;
      this.getProfileInfo();
    }, err => {
      this.alertService.showError({msg: 'There was an error please try again later or contact support'});
      this.ngxSpinner.hide();
    }, (complete?) => {
      this.ngxSpinner.hide();
    })
  }

  /**
   * Retrieves the information of the user profile from the backend
   */
  getProfileInfo(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user._id;
    let userRequest = this.getUserInfo();
    let legalRequest = this.getLegalrepresentative();
    let academicHistoryRequest = this.getAcademicHistory();
    this.refCode = this.userId + "&&" + this.applicationId + "&&" + (Math.floor(Math.random() * 99 + 1)).toString();
    this.ngxSpinner.show();
    forkJoin([userRequest, legalRequest, academicHistoryRequest]).subscribe((response) => {
      if (response[0].u360FeeConfirmado) {
        this.subtotal = response[0].u360Fee;
      }
      this.ngxSpinner.hide();
      this.setDiscount({user: response[0], legalRepresentative: response[1], academicHistory: response[2] });
    }, err => {
      this.alertService.showError({
        msg: this.translate.instant('Profile.load-error'),
      });
      this.ngxSpinner.hide();
    })
  }

  /**
   * Retrieves user information from the backend
   */
  getUserInfo(): Observable<any> {
    return this.apiService.get({ api: this.USER_API + this.userId });
  }

  /**
   * Retrieves the legal representative information from the backend
   */
  getLegalrepresentative(): Observable<any> {
    return this.apiService.get({ api: this.LEGAL_REPRESENTATIVE_API + this.userId });
  }

  /** Retrieves the academic history information from the backend */
  getAcademicHistory(): Observable<any> {
    return this.apiService.get({api: this.ACADEMIC_HISTORY_API + this.userId});
  }

  /**
   * Handles the click on the back button
   */
  onBackClick(): void {
    this.location.back();
  }

  /**
   * Handles the click on the make payment button
   */
  onMakePaymentClick(): void {
    this.pageStatus = 1;
  }

  /** Handles click on return from checkout button */
  onReturnFromCheckout(): void {
    this.pageStatus = 0;
  }

  /**
   * First step of creating a one time fee payment, creates a payment to U360 record in the backend
   * @param paymentInformation PayU payment information, passed in by child component
   */
  startPurchase(paymentInformation): void {
    /** Create the shopping cart data */
    this.ngxSpinner.show();
    const total = this.price.toFixed(0);
    const today = new Date();
    const paymentData = {
      estudiante: this.userId,
      valor: total,
      createdAt: today,
      medioDePago: 'PayU',
    };
    // Submit the shopping cart data to the backend
    this.apiService
      .post({ api: this.PAYMENT_U360_API, data: paymentData })
      .subscribe(
        (response) => {
          // retrieve the cart id and add the value and the currency to the payment information data
          paymentInformation['value'] = total;
          paymentInformation['currency'] = 'COP';
          const paymentId = response.result._id;
          this.createTransaction({ paymentInformation, paymentId });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /**
   * Second step of payment, creates a transaction in the backend
   * @param paymentInformation consolidated payment information
   * @param paymentId id of the payment in the backend
   */
  createTransaction({ paymentInformation, paymentId }): void {
    const total = this.price.toFixed(0);
    const transaction = {
      estudiante: paymentInformation.merchantPayerId,
      valor: total,
      pagoAU360: paymentId,
    };

    this.apiService
      .post({ api: this.TRANSACTION_API, data: transaction })
      .subscribe(
        (response) => {
          // Get the transaction id and add it to the paymentInformation
          const transactionId = response.result._id;
          paymentInformation['referenceCode'] = transactionId;
          this.createSignature({ paymentInformation, transactionId });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /** Third step of the payment creates a signature for the payment
   * @param paymentInformation consolidated payment information
   * @param transactionId transaction id as it appears in the backend
   */
  createSignature({ paymentInformation, transactionId }): void {
    const total = this.price.toFixed(0)
    const signature = {
      referencePayu: transactionId,
      amount: total,
      currency: 'COP',
    };
    this.apiService
      .post({ api: this.SIGNATURE_API, data: signature })
      .subscribe(
        (response) => {
          // Get the signature and add it to the payment information
          const signatureId = response.firma;
          paymentInformation['signature'] = signatureId;
          this.makePayment({ paymentInformation });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /** Fourth and last method of payment, makes the payment
   * @param paymentInformation: Consolidated payment information
   */
  makePayment({ paymentInformation }): void {
    this.ngxSpinner.show();
    this.apiService
      .post({ api: this.PAYMENT_API, data: paymentInformation })
      .subscribe(
        (response) => {
          if (response.success === true) {
            this.alertService.showSuccess({
              msg: this.translate.instant('Shopping-cart.payment-succesfull'),
            });
            this.ngxSpinner.hide();
            this.location.back();
          } else {
            this.alertService.showError({ msg: response.msg });
            this.ngxSpinner.hide();
          }

        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /** Configures the discount of the fee based on the profile completion  */
  setDiscount({user, legalRepresentative, academicHistory}: {user: any, legalRepresentative: any, academicHistory: any}): void {
    let step1Completed = false;
    // Verify if step 1 is completed
    if (user.nombre !== null && user.nombre !== undefined && user.nombre !== ''
    && user.apellido !== null && user.apellido !== undefined && user.apellido !== ''
    && user.email !== null && user.email !== undefined && user.email !== ''
    && user.telefono !== null && user.telefono !== undefined && user.telefono !== ''
    && user.ciudad !== null && user.ciudad !== undefined && user.ciudad !== ''
    && user.pais !== null && user.pais !== undefined && user.pais !== '') {
      step1Completed = true;
    } else {
      step1Completed = false;
    }

    // Verify if step 2 is completed
    let step2Completed = false;
    if (legalRepresentative !== null) {
      if (legalRepresentative.acudientes.length > 0) {
        if (legalRepresentative.acudientes[0].nombre !== null && legalRepresentative.acudientes[0].nombre !== undefined && legalRepresentative.acudientes[0].nombre !== ''
        && legalRepresentative.acudientes[0].apellido !== null && legalRepresentative.acudientes[0].apellido !== undefined && legalRepresentative.acudientes[0].apellido !== ''
        && legalRepresentative.acudientes[0].parentesco !== null && legalRepresentative.acudientes[0].parentesco !== undefined && legalRepresentative.acudientes[0].parentesco !== ''
        && legalRepresentative.acudientes[0].telefono !== null && legalRepresentative.acudientes[0].telefono !== undefined && legalRepresentative.acudientes[0].telefono !== ''
        && legalRepresentative.acudientes[0].correo !== null && legalRepresentative.acudientes[0].correo !== undefined && legalRepresentative.acudientes[0].correo !== '') {
          step2Completed = true;
        }
         else {
          step2Completed = false;
        }
      } else {
        step2Completed = false;
      }
    }

    //Verify if step 3 is completed
    let step3Completed = false;
    if (user.pasaporte !== null && user.pasaporte !== undefined && user.pasaporte !== ''
    && user.paisPasaporte !== null && user.paisPasaporte !== undefined && user.paisPasaporte !== ''
    && user.numeroPasaporte !== null && user.numeroPasaporte !== undefined && user.numeroPasaporte !== ''
    && user.viajesUltimos3Anios && user.viajesUltimos3Anios !== ''
    && user.viajes3A5Anios && user.viajes3A5Anios !== '') {
      step3Completed = true;
    } else {
      step3Completed = false;
    }

    // Verify if step 4 is completed
    let step4Completed = false;
    if (user.interesesAcademicos.length > 0) {
      if (user.interesesAcademicos[0].disciplina !== null && user.interesesAcademicos[0].disciplina !== '' && user.interesesAcademicos[0].disciplina !== undefined
      && user.interesesAcademicos[0].pais !== null && user.interesesAcademicos[0].pais !== '' && user.interesesAcademicos[0].pais !== undefined
      && user.interesesAcademicos[0].nivelDeEstudios !== null && user.interesesAcademicos[0].nivelDeEstudios !== '' && user.interesesAcademicos[0].nivelDeEstudios !== undefined) {
        step4Completed = true;
      } else {
        step4Completed = false;
      }

    }  else {
      step4Completed = false;
    }

    // Verify if step 5 is completed
    let step5Completed = false;
    if (user.presupuestoMaximo !== null && user.presupuestoMaximo !== undefined
        && user.presupuestoMinimo !== null && user.presupuestoMinimo !== undefined
        && user.tipoFinanciacion.length > 0) {
          step5Completed = true;
    }
    else {
      step5Completed = false;
    }

    // Verify if step 6 is completed
    let step6Completed = false;
    if (user.idiomas.length > 0) {
      if(user.idiomas[0].nivel !== '' && user.idiomas[0].nivel !== undefined && user.idiomas[0].nivel !== null
      && user.idiomas[0].nombre !== '' && user.idiomas[0].nombre !== undefined && user.idiomas[0].nombre !== null) {
        step6Completed = true;
      } else {
        step6Completed = false;
      }
    } else {
      step6Completed = false;
    }

    // Verify if step 7 is completed
    let step7Completed = false;
    if (academicHistory !== null) {
      if (academicHistory.historial.length > 0) {
        if (academicHistory.historial[0].estudiosRealizados !== null && academicHistory.historial[0].estudiosRealizados !== '' && academicHistory.historial[0].estudiosRealizados !== undefined
        && academicHistory.historial[0].tituloAlcanzado !== null && academicHistory.historial[0].tituloAlcanzado !== '' && academicHistory.historial[0].tituloAlcanzado !== undefined
        && academicHistory.historial[0].fechaDeInicio !== null && academicHistory.historial[0].fechaDeInicio !== '' && academicHistory.historial[0].fechaDeInicio !== undefined
        && academicHistory.historial[0].fechaDeFinalizacion !== null && academicHistory.historial[0].fechaDeFinalizacion !== '' && academicHistory.historial[0].fechaDeFinalizacion !== undefined
        && academicHistory.historial[0].notaPromedio !== null && academicHistory.historial[0].notaPromedio !== '' && academicHistory.historial[0].notaPromedio !== undefined
        && academicHistory.historial[0].certificadoDeNotas !== null && academicHistory.historial[0].certificadoDeNotas !== '' && academicHistory.historial[0].certificadoDeNotas !== undefined
        && academicHistory.historial[0].diploma !== null && academicHistory.historial[0].diploma !== '' && academicHistory.historial[0].diploma !== undefined) {
          step7Completed = true;
        }
        else {
          step7Completed = false;
        }
      } else {
        step7Completed = false;
      }
    }

    // Verify if step 8 is completed
     // Setup of status of step 8
     let step8Completed = false;
     if (user.tieneExperiencia === false) {
      step8Completed = true
    } else if (user.tieneExperiencia === true && user.experiencias.length > 0) {
      if (user.experiencias[0].posicion && user.experiencias[0].posicion !== ''
      && user.experiencias[0].nombreEmpresa && user.experiencias[0].nombreEmpresa !== ''
      && user.experiencias[0].anoInicio && user.experiencias[0].anoFin ) {
        step8Completed = true;
      } else {
        step8Completed = false;
      }
    } else {
      step8Completed = false;
    }

    // If all steps are completed apply discount, otherwise, don't do anything
    if (step1Completed && step2Completed && step3Completed && step4Completed && step5Completed && step6Completed && step7Completed ) {
      this.discount = 20
      this.price = this.subtotal * (1 - (this.discount/100));
    } else {
      this.discount = 0;
      this.price = this.subtotal;
    }
  }

}
