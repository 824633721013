import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { translationMap } from './translationMap.js';
import { AlertService } from '../services/alert.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.scss'],
})
export class SchoolDashboard implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly GET_STUDENTS = 'api/skillTestByColegio/student-list';
  public chartOptions: any;
  public averageChartoptions: any;
  public chartProgrammsOptions: any;
  public testChart: any;
  public chartLanguagesOptions: any;
  public skillsLevelChart: any;
  translationMap: translationMap;
  studentsPerPage = 25;
  searchText: string = '';
  testPresentedFilter: string = '';
  nombreColegio: '';
  dataSource: any;
  studentData: any;
  studentPerPage: any;
  pageLimit: any;
  students: any[] = [];
  queryStudents: any = {};
  aprendizajePermanenteLevel: any;
  globalMindLevel: any;
  inteligenciaEmocionalLevel: any;
  creatividadInnovacionLevel: any;
  impactoResponsabilidadLevel: any;
  liderazgoLevel: any;
  orientacionResultadosLevel: any;
  averageLevel: any;
  selectedGrade: string = '';
  selectedGrades: any;
  schoolData: any;
  studentsData: any;
  schoolName = '';
  sortBy: string = '';
  sortOrder: string = '';
  totalStudents: any;
  generalScore: any;
  sizePagination = [];
  skills: any;
  studentFilter: any;
  listTable: any = [];
  seriesLevel: any;
  skillAverages: any;
  selectedChartData: any;
  currentPageHorizontal: any;
  optionsOfGrades: any;
  grades: any;
  gradesLevels: any;
  selectedGradeFilter: any;
  lastSelectedGrade: any;
  matchedGradeLevels: any[] = [];
  selectedLevels: string[] = [];
  selectedLevel: string = '';
  studentsFilteredByLevel: any;
  filteredStudents: any;
  subjects = [
    { value: 'Bachillerato 7mo' },
    { value: 'Bachillerato 9no' },
    { value: 'Bachillerato 10mo' },
    { value: 'Bachillerato 11vo' },
  ];
  skillsOptions = [
    { value: 'Average', label: 'Promedio General' },
    { value: 'AprendizajePermanente', label: 'Aprendizaje Permanente' },
    { value: 'CreatividadInovacion', label: 'Creatividad e Innovación' },
    { value: 'GlobalMind', label: 'Global Mind' },
    { value: 'InteligenciaEmocional', label: 'Inteligencia Emocional' },
    { value: 'ImpactoResponsabilidad', label: 'Impacto y Responsabilidad' },
    { value: 'Liderazgo', label: 'Liderazgo' },
    { value: 'OrientacionResultados', label: 'Orientación a Resultados' },
  ];

  themePalette = ['#4ccdb2', '#fab902', '#775dd0', '#3dc8e1', '#ea447c', '#2b96f3', '#Ee8c2c', '#Bbda86'];
  resultPaginate: any;
  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService
  ) {}
  convertObjectToArray(obj) {
    if (obj) {
      return Object.values(obj);
    } else {
      return [];
    }
  }
  ngOnInit() {
    this.selectedChartData = 'Average';
    this.selectedGrades = ['Todos'];
    this.lastSelectedGrade = null;
    this.currentPageHorizontal = 0;
    this.getColegio(this.currentPageHorizontal);
    this.seriesLevel = this.convertObjectToArray(this.averageLevel);
    this.updateSkillsLevelChart();
  }

  ngOnDestroy(): void {}

  clearFilters() {
    this.selectedGrades = ['Todos'];
    this.filterStudents();
  }

  filterGrades() {
    this.students = [];
    this.studentsFilteredByLevel = [];
    this.lastSelectedGrade = null;
    if (this.selectedGrades.includes('Todos')) {
      this.students = this.resultPaginate.filter((student) => {
        const condition =
          !(student.fullTest && student.fullTest.length <= 0) &&
          !(student.skillsScore && student.skillsScore.length <= 0);
        return condition;
      });
      this.filteredStudents = this.students;
    } else {
      for (const gradeLevel of this.gradesLevels.gradesLevels) {
        const condition = this.selectedGrades.includes(gradeLevel.grade[0]);
        if (condition) {
          this.matchedGradeLevels = gradeLevel.levels.map((level) => level[0]);
        }
      }
      this.students = this.resultPaginate.filter((student) => {
        if (student.fullTest && student.fullTest.length > 0) {
          const gradeCondition = this.selectedGrades.includes(student.schoolGrade[0]);
          return gradeCondition;
        } else {
          return false;
        }
      });
      this.filteredStudents = this.students;
    }
    this.filterStudents();
  }

  filterLevels(selectedLevel) {
    const levelExists = this.students.some(
      (student) => student.fullTest && student.fullTest.length > 0 && selectedLevel.includes(student.schoolGradeLevel)
    );
    if (levelExists) {
      this.studentsFilteredByLevel = this.students.filter(
        (student) => student.fullTest && student.fullTest.length > 0 && selectedLevel.includes(student.schoolGradeLevel)
      );
      this.filteredStudents = this.studentsFilteredByLevel;
    } else {
      this.filteredStudents = [];
    }

    this.filterStudents();
  }

  filterStudents() {
    this.filteredStudents = this.filteredStudents ? this.filteredStudents : this.students;

    const englishLevelCounter = {
      A1: 0,
      A2: 0,
      B1: 0,
      B2: 0,
      C1: 0,
      C2: 0,
    };
    for (const student of this.filteredStudents) {
      if (student.englishRange) {
        const englishLevel = student.englishRange;
        if (englishLevelCounter[englishLevel] !== undefined) {
          englishLevelCounter[englishLevel]++;
        }
      }
    }
    const skillTotals = {
      Liderazgo: 0,
      GlobalMind: 0,
      ImpactoResponsabilidad: 0,
      OrientacionResultados: 0,
      CreatividadInovacion: 0,
      AprendizajePermanente: 0,
      InteligenciaEmocional: 0,
    };

    for (const student of this.filteredStudents) {
      if (student.skillsScore && student.skillsScore.length > 0) {
        const skillsPoints = student.skillsScore[0];
        skillTotals.Liderazgo += skillsPoints.Liderazgo || 0;
        skillTotals.GlobalMind += skillsPoints.GlobalMind || 0;
        skillTotals.ImpactoResponsabilidad += skillsPoints.ImpactoResponsabilidad || 0;
        skillTotals.OrientacionResultados += skillsPoints.OrientacionResultados || 0;
        skillTotals.CreatividadInovacion += skillsPoints.CreatividadInovacion || 0;
        skillTotals.AprendizajePermanente += skillsPoints.AprendizajePermanente || 0;
        skillTotals.InteligenciaEmocional += skillsPoints.InteligenciaEmocional || 0;
        this.generalScore =
          skillsPoints.Liderazgo +
          skillsPoints.GlobalMind +
          skillsPoints.ImpactoResponsabilidad +
          skillsPoints.OrientacionResultados +
          skillsPoints.CreatividadInovacion +
          skillsPoints.AprendizajePermanente;
      } else {
      }
    }

    const disciplineCount = {};
    for (const student of this.filteredStudents) {
      try {
        const discipline = student.fullTest[0].personalData.discipline;
        if (disciplineCount.hasOwnProperty(discipline)) {
          disciplineCount[discipline]++;
        } else {
          disciplineCount[discipline] = 1;
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
    for (const student of this.filteredStudents) {
      try {
        const discipline = student.fullTest[0].personalData.academicLevel;
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }

    const selectedOptionUniversityCount = {};
    for (const student of this.filteredStudents) {
      if (student.universityType[0]) {
        const selectedOption = student.universityType[0];

        if (selectedOptionUniversityCount[selectedOption]) {
          selectedOptionUniversityCount[selectedOption]++;
        } else {
          selectedOptionUniversityCount[selectedOption] = 1;
        }
      }
    }

    const universities = Object.keys(selectedOptionUniversityCount);
    const counts = Object.values(selectedOptionUniversityCount);
    const programsLabels = Object.keys(disciplineCount);
    const translatedProgramsLabels = programsLabels.map((label) => translationMap[label] || label);
    const programsSeries = programsLabels.map((label) => disciplineCount[label]);
    const skillsAverage = parseFloat((this.generalScore / 6).toFixed(2));
    const totalStudents = this.filteredStudents.length;
    this.skillAverages = {
      Liderazgo: (skillTotals.Liderazgo / totalStudents).toFixed(2),
      GlobalMind: (skillTotals.GlobalMind / totalStudents).toFixed(2),
      ImpactoResponsabilidad: (skillTotals.ImpactoResponsabilidad / totalStudents).toFixed(2),
      OrientacionResultados: (skillTotals.OrientacionResultados / totalStudents).toFixed(2),
      CreatividadInovacion: (skillTotals.CreatividadInovacion / totalStudents).toFixed(2),
      AprendizajePermanente: (skillTotals.AprendizajePermanente / totalStudents).toFixed(2),
      InteligenciaEmocional: (skillTotals.InteligenciaEmocional / totalStudents).toFixed(2),
    };
    const ranges = {
      Master: { min: 90, max: 100 },
      Professional: { min: 80, max: 90 },
      Higher: { min: 70, max: 80 },
      Average: { min: 50, max: 70 },
      Low: { min: 0, max: 50 },
    };

    const skillLevelCount = {
      Liderazgo: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      GlobalMind: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      ImpactoResponsabilidad: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      OrientacionResultados: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      CreatividadInovacion: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      AprendizajePermanente: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      InteligenciaEmocional: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
      Average: { Master: 0, Professional: 0, Higher: 0, Average: 0, Low: 0 },
    };

    for (const student of this.filteredStudents) {
      const skillsPoints = student.skillsScore[0] || {};
      let totalScore = 0;
      let totalSkills = 0;

      for (const skill in skillsPoints) {
        if (skillsPoints.hasOwnProperty(skill)) {
          const score = skillsPoints[skill];
          totalScore += score;
          totalSkills++;
          let level = null;
          for (const rangeName in ranges) {
            if (ranges.hasOwnProperty(rangeName)) {
              const range = ranges[rangeName];
              if (score >= range.min && score <= range.max) {
                level = rangeName;
                break;
              }
            }
          }
          if (level) {
            skillLevelCount[skill][level]++;
          }
        }
      }
      const skillsAverage = totalSkills > 0 ? totalScore / totalSkills : 0;
      let averageLevel = null;
      for (const rangeName in ranges) {
        if (ranges.hasOwnProperty(rangeName)) {
          const range = ranges[rangeName];
          if (skillsAverage >= range.min && skillsAverage <= range.max) {
            averageLevel = rangeName;
            break;
          }
        }
      }
      if (averageLevel) {
        skillLevelCount.Average[averageLevel]++;
      }
    }
    this.averageLevel = skillLevelCount.Average;
    this.aprendizajePermanenteLevel = skillLevelCount.AprendizajePermanente;
    this.creatividadInnovacionLevel = skillLevelCount.CreatividadInovacion;
    this.globalMindLevel = skillLevelCount.GlobalMind;
    this.inteligenciaEmocionalLevel = skillLevelCount.InteligenciaEmocional;
    this.impactoResponsabilidadLevel = skillLevelCount.ImpactoResponsabilidad;
    this.liderazgoLevel = skillLevelCount.Liderazgo;
    this.orientacionResultadosLevel = skillLevelCount.OrientacionResultados;
    this.seriesLevel = this.convertObjectToArray(this.averageLevel);
    this.updateSkillsLevelChart();

    this.chartLanguagesOptions = {
      series: [
        {
          name: 'Nivel de Inglés',
          data: [
            englishLevelCounter['A1'],
            englishLevelCounter['A2'],
            englishLevelCounter['B1'],
            englishLevelCounter['B2'],
            englishLevelCounter['C1'],
            englishLevelCounter['C2'],
          ],
          menu: false,
        },
      ],
      theme: {
        palette: 'palette1',
      },
      chart: {
        type: 'bar',
        height: 350,
        window: 200,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          horizontal: false,
        },
      },
      stroke: {
        width: 3,
      },
      fill: {
        opacity: 0.8,
      },
      xaxis: {
        categories: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      },
      yaxis: {
        max:
          Math.max.apply(Math, [
            englishLevelCounter['A1'],
            englishLevelCounter['A2'],
            englishLevelCounter['B1'],
            englishLevelCounter['B2'],
            englishLevelCounter['C1'],
            englishLevelCounter['C2'],
          ]) * 1.2,
      },
    };
    this.chartProgrammsOptions = {
      series: programsSeries,
      chart: {
        type: 'donut',
        height: 280,
      },
      fill: {},
      colors: this.themePalette,
      dataLabels: {
        enabled: false,
      },
      labels: translatedProgramsLabels,
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    };

    this.skills = [
      { name: 'Liderazgo', percentage: this.skillAverages.Liderazgo, color: '#e8f3f3', nivel: 'Avanzado' },
      {
        name: 'Orientacion a resultados',
        percentage: this.skillAverages.OrientacionResultados,
        color: '#faf6d2',
        nivel: 'Promedio',
      },
      {
        name: 'Aprendizaje continuo',
        percentage: this.skillAverages.AprendizajePermanente,
        color: '#e4e9f7',
        nivel: 'Medio',
      },
      {
        name: 'Impacto',
        percentage: this.skillAverages.ImpactoResponsabilidad,
        color: '#e4fbe4',
        nivel: 'Avanzado',
      },
      { name: 'Global Mind', percentage: this.skillAverages.GlobalMind, color: '#e8f3f3', nivel: 'Promedio' },
      {
        name: 'Creatividad e innovación',
        percentage: this.skillAverages.CreatividadInovacion,
        color: '#faf6d2',
        nivel: 'Medio',
      },
      {
        name: 'Inteligencia Emocional',
        percentage: this.skillAverages.InteligenciaEmocional,
        color: '#e4e9f7',
        nivel: 'Avanzado',
      },
    ];
    this.averageChartoptions = {
      series: [skillsAverage],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
        },
      },
      labels: ['Promedio General'],
    };

    this.chartOptions = {
      series: counts,
      chart: {
        type: 'donut',
        height: 280,
      },
      colors: this.themePalette,
      dataLabels: {
        enabled: false,
      },
      labels: universities,
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    };
  }

  updateSkillsLevelChart() {
    switch (this.selectedChartData) {
      case 'AprendizajePermanente':
        this.seriesLevel = this.convertObjectToArray(this.aprendizajePermanenteLevel);
        break;
      case 'CreatividadInovacion':
        this.seriesLevel = this.convertObjectToArray(this.creatividadInnovacionLevel);
        break;
      case 'GlobalMind':
        this.seriesLevel = this.convertObjectToArray(this.globalMindLevel);
        break;
      case 'InteligenciaEmocional':
        this.seriesLevel = this.convertObjectToArray(this.inteligenciaEmocionalLevel);
        break;
      case 'ImpactoResponsabilidad':
        this.seriesLevel = this.convertObjectToArray(this.impactoResponsabilidadLevel);
        break;
      case 'Liderazgo':
        this.seriesLevel = this.convertObjectToArray(this.liderazgoLevel);
        break;
      case 'OrientacionResultados':
        this.seriesLevel = this.convertObjectToArray(this.orientacionResultadosLevel);
        break;
      case 'Average':
        this.seriesLevel = this.convertObjectToArray(this.averageLevel);
        break;
      default:
        break;
    }

    // Paso 1: Convierte valores a porcentajes
    const total = this.seriesLevel.reduce((acc, val) => acc + val, 0);
    this.seriesLevel = this.seriesLevel.map((value) => (value / total) * 100);

    // Paso 2: Actualiza newYMax para reflejar el nuevo valor máximo
    const maxPercentage = Math.max(...this.seriesLevel);
    const twentyPercentMore = maxPercentage * 0.2;
    const newYMax = maxPercentage + twentyPercentMore;

    // Paso 3: Actualiza la configuración del gráfico
    this.skillsLevelChart = {
      series: [
        {
          name: 'Puntuaciones',
          data: this.seriesLevel,
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {},
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          distributed: true,
        },
      },
      stroke: {
        width: 3,
      },
      fill: {
        opacity: 0.8,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      theme: {
        palette: 'palette1',
      },
      xaxis: {
        categories: ['Master', 'Professional', 'Higher', 'Average', 'Low'],
        labels: {
          show: false,
          style: {
            fontSize: '12px',
          },
        },
      },
      // Actualiza el eje y para mostrar porcentajes
      yaxis: {
        max: newYMax,
        labels: {
          formatter: function (value) {
            return value.toFixed(2) + '%';
          },
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }

  async getColegio(page: number) {
    try {
      const response = await this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).toPromise();

      if (response._id) {
        this.schoolData = response;
        this.getGradeLevelsBySchoolId(this.schoolData._id);
        this.grades = response.selectedGrades.map((grade) => grade.toString());
        this.nombreColegio = this.schoolData.nombre;

        this.queryStudents = {
          colegio: this.schoolData._id,
          page: page,
          limit: this.studentsPerPage,
          searchText: this.searchText,
          selectedGrade: this.selectedGrade,
          testPresentedFilter: this.testPresentedFilter,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        };
        this.getStudentsList(this.queryStudents);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    } finally {
      this.ngxSpinnerService.hide();
    }
  }

  getStudentsList(queryStudents: object) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.apiService.post({ api: this.GET_STUDENTS, data: queryStudents }).subscribe(
      (resultPaginate) => {
        this.resultPaginate = resultPaginate.allStudentsInfo;
        if (resultPaginate.allStudentsInfo && resultPaginate.allStudentsInfo.length > 0) {
          this.students = this.resultPaginate.filter(
            (student) =>
              student.fullTest && student.fullTest.length > 0 && student.skillsScore && student.skillsScore.length > 0
          );
          this.filterStudents();
          const formatData = [];
          this.listTable = formatData;
          this.studentsData = new MatTableDataSource(formatData);
          this.ngxSpinnerService.hide();
          Swal.close();
        }
      },
      () => this.ngxSpinnerService.hide()
    );
  }

  getGradeLevelsBySchoolId(schoolId: string) {
    const apiUrl = `api/getGradeLevelsById/${schoolId}`;
    this.apiService.get({ api: apiUrl }).subscribe((response) => {
      this.gradesLevels = response;
    });
  }
}
