import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { skillsArray } from '../../../skillsObject/skills.js';
import { questions } from './admissionTest.js';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-test-dashboard',
  templateUrl: './testDashboard.component.html',
  styleUrls: ['./testDashboard.component.scss'],
})
export class TestDashboardComponent implements OnInit, OnDestroy {
  countries: any[];
  public progress: any;
  public progress2: any;
  public progress3: any;
  public progress4: any;
  public progressLiderazgo: any;
  public progress5: any;

  public scrollBar = 100;
  public scrollBar2 = 20.0;
  public scrollBar3 = 60.0;
  public scrollBar4 = 70.0;
  public scrollBar5 = 50.0;
  public TopTier = [
    { habilidad: 'Liderazgo', porcentaje: 85 },
    { habilidad: 'Global Mind', porcentaje: 90 },
    { habilidad: 'Impacto Social', porcentaje: 85 },
    { habilidad: 'Logro y Acción', porcentaje: 95 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 95 },
    { habilidad: 'Promedio', porcentaje: 90 },
  ];
  public Experiential = [
    { habilidad: 'Liderazgo', porcentaje: 70 },
    { habilidad: 'Global Mind', porcentaje: 65 },
    { habilidad: 'Impacto Social', porcentaje: 50 },
    { habilidad: 'Logro y Acción', porcentaje: 65 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 70 },
    { habilidad: 'Promedio', porcentaje: 64 },
  ];
  public PracticalLearning = [
    { habilidad: 'Liderazgo', porcentaje: 50 },
    { habilidad: 'Global Mind', porcentaje: 50 },
    { habilidad: 'Impacto Social', porcentaje: 30 },
    { habilidad: 'Logro y Acción', porcentaje: 65 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 60 },
    { habilidad: 'Promedio', porcentaje: 51 },
  ];
  public Vocational = [
    { habilidad: 'Liderazgo', porcentaje: 70 },
    { habilidad: 'Global Mind', porcentaje: 65 },
    { habilidad: 'Impacto Social', porcentaje: 65 },
    { habilidad: 'Logro y Acción', porcentaje: 75 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 70 },
    { habilidad: 'Promedio', porcentaje: 69 },
  ];
  englishLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
  public userData = JSON.parse(localStorage.getItem('user'));
  readonly getTestInternalizacion = 'api/test_internalizacionUser/';
  readonly getData = 'api/skillsTest/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  readonly deleteTest = 'api/delete_internalizacionUser/';
  readonly GET_STUDENT = 'api/oneUser/';
  readonly API_GET_CODE = 'api/codigoTestByEmailUser/';
  readonly ADMISSIONTEST = 'api/admissionTest/';
  readonly getInstitutionOptions = 'api/getInstitutionOptions/';
  readonly getSkill = 'api/getSkill/';
  admissionQuestions = questions;
  dashboardData: any;
  name: any;
  age: any;
  date: any;
  profile: any;
  skillsArray = skillsArray;
  skillsScore: any;
  IdTest_: any;
  skillsPointsAverage: any;
  institutionType: any;
  generalScore: any;
  finalQuestion: any;
  recommendationsLiderazgo: any[] = [];
  recommendationsGlobal: any[] = [];
  recommendationsImpacto: any[] = [];
  recommendationsLogro: any[] = [];
  recommendationsAprendizaje: any[] = [];
  recommendationsPromedio: any[] = [];
  selectedOptionUniversity: any;
  englishLevel: any;
  averageChartoptions: any;
  displayScreenFree: boolean = false;
  scoreLiderazgo: any;
  scoreGlobalMind: any;
  scoreImpactoSocial: any;
  scoreLogroAccion: any;
  scoreAprendizaje: any;
  globalIndicators: { habilidad: string; porcentaje: number }[];
  indicatorLiderazgo: string;
  indicatorGlobal: string;
  indicatorImpacto: string;
  indicatorLogro: string;
  indicatorAprendizaje: string;
  progressGlobal: string;
  progressImpacto: string;
  progressLogro: string;
  progressCompetencia: string;
  progressGeneral: string;
  indicatorGeneral: number;
  closedQuestions = [];
  budgetQuestions = [];
  countriesQuestions = [];
  public idTest: any;
  finalProfile: any;
  sourceInsignia: any;
  semestreAct: any;
  profileType: any;
  buttonsActions: boolean;
  isReadOnly: boolean;
  test = 0;
  data: any;
  radarChart: any;
  questionForm: FormGroup;
  filteredResponse: any;
  combinedData: any;
  skillScoreMap: any;
  skillValues: any;
  desiredInstitutionAverage: any;
  institutionLevel: any;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private location: Location,
    private formBuilder: FormBuilder
  ) {}

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.getSkillData();
  }
  getSkillData(): void {
    this.apiService.get({ api: this.getData + this.userData._id }).subscribe(
      (response) => {
        if (response.length !== 0) {
          this.getUniversityType({ id: this.userData._id });
          this.displayScreenFree = false;
          this.getSkills();
          this.activatedRoute.data.pipe(take(1)).subscribe((data) => {
            this.isReadOnly = data.readOnly;
          });

          if (window.location.pathname.includes('viewDashboardStudent')) {
            let student = window.location.pathname;
            student = student.slice(26);
            this.getDataStudent({ data: student });
          } else {
            this.loadActivedSemester();
          }
          this.questionForm = this.formBuilder.group({
            questions: this.formBuilder.array([]),
          });
          this.getTest();
          this.dashboardData = response;
          this.IdTest_ = response._id;
          this.skillsScore = this.dashboardData.skillPoints;

          const resp = response;
          if (this.skillsScore) {
            this.skillsScore = response.skillPoints;
            const averageSkills = { ...this.skillsScore };

            this.generalScore = Object.keys(this.skillsScore)
              .filter((key) => key !== 'Inteligencia Emocional')
              .reduce((sum, key) => sum + this.skillsScore[key], 0);
            const primarySkills = Object.keys(this.skillsScore).filter((skill) => skill !== 'Inteligencia Emocional');

            delete averageSkills['Inteligencia Emocional'];

            this.skillsPointsAverage = (this.generalScore / primarySkills.length).toFixed(2);
            this.skillsScore.Promedio = +this.skillsPointsAverage;

            this.getSkills();
            this.skillValues = Object.values(averageSkills);
            const categories = Object.keys(averageSkills).map((skill) => `${skill} ${averageSkills[skill]}%`);

            this.radarChart = {
              chart: {
                height: 500,
                width: 470,
                type: 'radar',
                toolbar: {
                  tools: {
                    download: false,
                  },
                },
              },
              series: [
                {
                  name: 'Skills',
                  data: this.skillValues,
                  color: 'rgba(255, 255, 255, 0.5)',
                },
              ],
              plotOptions: {
                radar: {
                  size: 150,
                  polygons: {
                    strokeColors: '#969FFB',
                    fill: {
                      colors: ['#d7dbff'],
                    },
                  },
                },
              },
              xaxis: {
                categories: categories,
                labels: {
                  style: {
                    colors: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700'],
                  },
                },
              },
              yaxis: {
                show: false,
                min: 0,
                max: 100,
              },
              tooltip: {
                enabled: false,
              },
              legend: {
                show: false,
              },
            };
          }

          const listaPreguntas = resp.questions;
          this.name = resp.name;
          this.age = resp.age;
          this.date = moment(resp.createdAt).format('DD-MMMM-YYYY');
          this.assignBadge();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Para ver resultados, por favor completa el test de Skills',
          });
          return false;
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }
  displayScreen(skill) {
    const equivalentSkillName = this.skillScoreMap[skill.name];

    this.displayScreenFree = true;
    this.router.navigate(['/skillsDetails', equivalentSkillName, skill]);
  }
  handleHowToImproveClick(competence: string): void {
    const equivalentSkillName = this.skillScoreMap[competence];
    this.displayScreenFree = true;
    this.router.navigate(['/skillsSuggestions', equivalentSkillName]);
  }

  getSkillDescription(skillId: string): string {
    const skill = skillsArray.find((skill) => skill.id === skillId);
    return skill ? skill.descriptions.general : '';
  }

  goToTest() {
    this.navigationService.navigateTo({ path: '/leaderTest' });
  }
  goToUniversityType() {
    this.navigationService.navigateTo({ path: '/universityType' });
  }
  loadData() {
    if (this.userData) {
      this.apiService.get({ api: this.getTestInternalizacion + this.userData._id }).subscribe(
        (response) => {
          if (response) {
            this.idTest = response._id;
            this.test = response.test;
            const resp = response;

            resp.test = JSON.parse(response.test);

            const listaPreguntas = resp.test.preguntas;
            this.name = resp.test.nameUSer;
            this.age = resp.test.ageActual;
            this.date = moment(resp.createdAt).format('DD-MMMM-YYYY');
            for (const uni of resp.test.preguntas[2].preguntas[0].opciones) {
              if (uni.interesa) {
                this.profile = uni.institucion;
              }
            }
            this.assignBadge();
            listaPreguntas.map((registro) => {
              if (registro.tipo == 'optener_datos') {
                registro.preguntas.map((pregunta) => {
                  if (!pregunta.opciones && !pregunta.lista) {
                    this.closedQuestions.push(pregunta);
                  }

                  if (pregunta.opciones) {
                    pregunta.opciones.map((presupuesto) => {
                      this.budgetQuestions.push(presupuesto);
                    });
                  }

                  if (pregunta.lista) {
                    this.countriesQuestions.push(pregunta);
                  }
                });
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Para ver resultados, por favor completa el test de Skills, Creo que soy yo',
            });
            return false;
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
  }

  resetTest() {
    Swal.fire({
      title: '¿Estas seguro que deseas reiniciar el test?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.apiService.delete({ api: this.deleteTest + this.idTest }).subscribe((response) => {
          if (response) {
            this.router.navigate(['leaderTest']);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al reiniciar el test',
            });
            return false;
          }
        });
      }
    });
  }

  assignBadge(): void {
    switch (this.finalProfile) {
      case 'Nivel Alto':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/master.png';
        break;

      case 'Nivel Medio':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/avanzado.png';
        break;

      case 'Nivel Principiante':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/en-construccion.png';
        break;

      default:
    }
  }
  getTest() {
    const id = this.userData?._id;
    if (id) {
      this.apiService.get({ api: `api/admissionTest/${id}` }).subscribe((response) => {
        if (response.data && response.data.questionsAndAnswers) {
          this.admissionQuestions = response.data.questionsAndAnswers.map((item, index) => {
            return {
              pregunta: item.pregunta,
              opcionSeleccionada: item.opcionSeleccionada || '',
            };
          });
        } else {
          console.error('La respuesta recibida no contiene datos de preguntas y respuestas');
        }
      });
    } else {
      console.error('No se pudo obtener el ID del usuario');
    }
  }

  getUniversityType({ id }) {
    this.apiService.get({ api: this.GET_STUDENT + id }).subscribe((response) => {
      this.englishLevel = response.languageLevel;
      this.selectedOptionUniversity = response.institutionType;
      this.getInstitutions();
    });
  }

  getInstitutions() {
    this.apiService.get({ api: this.getInstitutionOptions }).subscribe((response) => {
      this.filteredResponse = response.filter(
        (university) => university.institutionOptionName === this.selectedOptionUniversity
      );

      if (this.filteredResponse.length > 0) {
        this.desiredInstitutionAverage = this.filteredResponse[0].selectedSkills.filter(
          (institution) => institution.name === 'Desempeño Global Competencias'
        );
        if (this.desiredInstitutionAverage.length > 0) {
          this.getSkills();
        } else {
          console.error("No se encontraron promedios de institución para 'Desempeño Global Competencias'");
        }
      } else {
        console.error('No se encontraron instituciones que coincidan con la opción seleccionada');
      }
    });
  }
  getSkills() {
    this.apiService.get({ api: this.getSkill }).subscribe((response) => {
      this.skillScoreMap = {
        'Impacto y Responsabilidad Social': 'ImpactoResponsabilidad',
        'Aprendizaje Continuo': 'AprendizajePermanente',
        'Creatividad E Innovación': 'CreatividadInovacion',
        Liderazgo: 'Liderazgo',
        'Global Mind': 'GlobalMind',
        'Orientación a Resultados': 'OrientacionResultados',
        'Inteligencia Emocional': 'InteligenciaEmocional',
      };
      if (this.desiredInstitutionAverage && this.desiredInstitutionAverage.length > 0) {
        const desiredSkill = response.find((skill) => skill.skillName === 'Desempeño Global Competencias');

        if (desiredSkill) {
          this.institutionLevel = this.findLevel(desiredSkill.levels, this.skillsPointsAverage);

          const averageObject = {
            name: 'Desempeño Global Competencias',
            score: this.skillsPointsAverage,
            level: this.institutionLevel.level,
            levelDescription: this.institutionLevel.description,
          };
          this.displayAverageChart(averageObject);

          this.combinedData = this.filteredResponse[0].selectedSkills
            .map((selectedSkill) => {
              const matchingSkill = response.find((skill) => skill.skillName === selectedSkill.name);
              const skillScoreKey = selectedSkill.name;
              const skillScore = this.skillsScore && skillScoreKey ? this.skillsScore[skillScoreKey] : undefined;
              if (matchingSkill && skillScore !== undefined) {
                const level = this.findLevel(matchingSkill.levels, skillScore);
                return {
                  name: selectedSkill.name,
                  desiredScore: selectedSkill.score,
                  description: matchingSkill.skillDescription,
                  skillScore: skillScore,
                  proficiencyLevel: level.level,
                  proficiencyDescription: level.description,
                };
              }

              return null;
            })
            .filter(Boolean);
          this.displayRadarChart(this.combinedData);
        } else {
          console.error("No se encontró el skill 'Desempeño Global Competencias'");
        }
      }
    });
  }
  displayAverageChart(averageScore) {
    const averageScore2 = averageScore.score;

    this.averageChartoptions = {
      series: [averageScore2],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
        },
      },
      labels: [`${averageScore.level}`],
    };
  }
  displayRadarChart(skillObjects) {
    const categories = skillObjects.map((skill) => {
      const formattedSkillName = skill.name.split(' ').join('<br>');
      return `${formattedSkillName} ${skill.skillScore}%-${skill.proficiencyLevel}`;
    });

    const skillValues = skillObjects.map((skill) => skill.skillScore);

    this.radarChart = {
      chart: {
        height: 500,
        width: 900,
        type: 'radar',
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      series: [
        {
          name: 'Skills',
          data: skillValues,
          color: 'rgba(255, 255, 255, 0.5)',
        },
      ],
      plotOptions: {
        radar: {
          size: 150,
          polygons: {
            strokeColors: '#969FFB',
            fill: {
              colors: ['#d7dbff'],
            },
          },
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700'],
          },
          formatter: function (value) {
            return value.replace(/<br>/g, '\n');
          },
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    };
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }
  onSubmit() {
    const id = this.userData._id;
    const postData = {
      admissionQuestions: this.admissionQuestions,
      user: this.userData._id,
    };
    const data = {
      api: `api/admissionTest/${id}`,
      data: postData,
    };
    this.apiService.put(data).subscribe(
      (response) => {
        Swal.fire({
          icon: 'success',
          title: '¡Prueba exitosa!',
          text: 'Tu test se ha guardado correctamente.',
        });
      },
      (error) => {
        console.error('Error in the POST request:', error);
        Swal.fire({
          icon: 'error',
          title: '¡Oops!',
          text: 'Tu test no se guardó correctamente. Inténtalo de nuevo más tarde.',
        });
      }
    );
  }
  loadActivedSemester(): void {
    this.ngxSpinnerService.show();

    this.apiService.get({ api: this.API_GET_CODE + this.userData.email }).subscribe((register) => {
      if (register.length > 0) {
        const dataColegio = register[0].idColegio;
        this.semestreAct = dataColegio.semestreActivo;
        localStorage.setItem('semestreActivo', dataColegio.semestreActivo);
        this.loadData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se le ha asignado ningún código',
        });
      }

      this.ngxSpinnerService.hide();
    });
  }

  getDataStudent({ data }) {
    this.apiService.get({ api: this.GET_STUDENT + data }).subscribe((response) => {
      this.userData = response;
      this.loadActivedSemester();
    });
  }

  /**
   * Handles the click on the back button
   */
  onBackButtonClick(): void {
    this.location.back();
  }
}
