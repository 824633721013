import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';
import { ApiService } from '../../services/api.service';
import { AuthComponent } from '../../auth/auth.component';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';




@Component({
  selector: 'app-scholarship-card',
  templateUrl: './scholarship-card.component.html',
  styleUrls: ['./scholarship-card.component.scss']
})
export class ScholarshipCardComponent implements OnInit {
  /** Variable that holds the university name */
  @Input() university: string;
  /** Variable that holds the percentage */
  @Input() percentage: number;
  /** Variable that holds the image */
  @Input() image: string;
  /** Variable that holds the description of the scholarship*/
  @Input() description: string;
  @Input() scholarship;
  /** All countries */
  @Input() countries;
  /** Variable that shows if the user is logged in */
  loggedIn = false;
  /** Variable that holds the is logged in subscription */
  logInSubscription: Subscription;

  applyStatus: boolean = false;
  public countryScholarship: any = {};

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private authService: AuthService,
    private alertService: AlertService


  ) { }

  ngOnInit(): void {
    this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
      this.loggedIn = logged;
    });
    this.getApply();
    this.findCountry({countries: this.countries})
  }

  ngOnDestroy(): void {
    this.logInSubscription.unsubscribe();
  }


  /** Handles the click on the additional information icon */
  onAdditionalInfoClick(): void {
    this.dialog.open(ServiceDetailModalComponent, {
      data: {
        service: 'scholarship',
        university: this.university,
        percentage: this.percentage,
        image: this.image,
        overview: this.description,
        country: this.countryScholarship.nombre,
        fuente: this.scholarship.fuente
      }
    });
  }

  /**Apply scholarship */
  public async  onApply() {
    try {
      if (this.loggedIn) {
        const user = JSON.parse(localStorage.getItem('user'));
        let obj = {
          universidad: this.scholarship.universidad._id,
          beca: this.scholarship._id,
          user: user._id,
          descripcion: this.scholarship.descripcion,
          porcentaje: this.scholarship.porcentaje,
          pais: this.scholarship.pais,
        };
        await this.apiService.postPromise( { api: `api/aplicacion-beca`, data: obj });
        // Check apply;
        this.applyStatus = false;
        this.alertService.showSuccess({msg: 'You applied successfully'});
        
      } else {
        // No hay sesión abierta
        this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      }
    } catch(err) {
      this.alertService.showError({msg: 'An error occurred. Try later'});
      console.error(`ocurrio un error al aplicar a la beca ${err}`);
    }
  }

  /** Check if user already apply for the scholarship */
  public getApply() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user && this.loggedIn) {
    this.apiService.get({api: `api/aplicacion-beca/${this.scholarship._id}/user/${user._id}`}) 
      .subscribe((data) => {
        if(!data) {
          this.applyStatus = true;
        } else {
          this.applyStatus = false;
        }
      }, er => {
        console.error(`Erro al validar si ya esta aplicando a la beca ${er}`)
      })
    } else {
        this.applyStatus = true;
    }
  }

  /**
   * Find country of the scholarship
   * @param countries Countries of the scholarships
   */
  public findCountry({ countries }) {
    for (let i = 0; i < countries.length; i++) {
      const element = countries[i];
      if(element._id === this.scholarship.pais) {
        this.countryScholarship = {...element};
      }
    }
  }

}
