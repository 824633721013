import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SeoService } from '../../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthComponent } from '../../auth/auth.component';
import { NavigationService } from '../../services/navigation.service';
import { ViewApplyComponent } from '../../shared/view-apply/view-apply.component';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss'],
})
export class ProgramDetailComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /** API */
  /** Constant that has the api path for programs */
  readonly PROGRAM_API = 'api/programa/';

  private readonly API_FORM = 'api/formulario';

  readonly PROGRAM_API_SLUG = 'api/programa/slug/';

  readonly API_VISITED_PROGRAMS_BY_STUDENT =
    'api/programas-visitados/estudiante/';
  readonly API_VISITED_PROGRAMS = 'api/programas-visitados';

  thumbnails = [];

  /** Variable that holds the data of the program */
  program = {
    _id: '',
    nombre: '',
    acreditacion: '',
    universidad: {
      nombre: '',
      linkVideo: '',
      ciudad: {
        _id: '',
        nombre: '',
        pais: {
          nombre: '',
          moneda: ''
        },
      },
      latitud: '',
      longitud: '',
      fotos: [],
      logo: '',
      tipo: '',
      pgwp: false,
      acomodacion: '',
      slug: '',
      videosResuelve: '',
      internacionales: undefined,
      workStudying: undefined,
      livingCost: undefined,
      applicationFee: undefined,
    },
    yearTuition: 0,
    programTuition: 0,
    languageRequirements: [],
    gradeRequirements: [],
    requirements: '',
    overview: '',
    overviewIngles: '',
    length: '',
    starts: [],
    slug: '',
    closeDate: '',
    coOp: false,
    stdLength: '',
    acreditacionShown: '',
    provinciaUniversidad: '',
    prioridad: 0,
    countryFilter: '',
  };

  /** Variable that contains the similar programs */
  similarPrograms = [];

  photos:any;
  videos:any;

  /** Variable that holds the subscription to the url fragment */
  fragmentSubscription: Subscription;
  /** Variable that holds the value of the fragment */
  fragment: string;
  /** Variable that determines if the requisites accordeon should be open */
  isOpen = false;

  /** Language */
  lang;

  /** Variable that shows if the user is logged in */
  loggedIn = false;
  /** Variable that holds the is logged in subscription */
  logInSubscription: Subscription;

  /** Constant that holds the API for retrieving configuration */
  readonly CONFIG_API = 'api/config/';
  /** Variable that holds the maximum number of visits withou login */
  private maxVisit;

  showMore: boolean = false;

  /* Child References */
  createForm: FormGroup = this.fb.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    correo: ['', [Validators.required, Validators.email]],
    anioInteres: ['', [Validators.required]],
    edad: ['', [Validators.required]],
    telefono: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
    programa: ['', [Validators.required]],
    pregunta: ['', [Validators.required]],
  });

  // Display form
  public displayForm: boolean = false;

  // Declare width variable
  scrWidth: any;

  // Guarda los nombres de las estaciones del programa
  availableStarts: string[] = [];

  requirements: string;
  gradeRequirements: string;
  languageRequirements: string;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private location: Location,
    private authService: AuthService,
    private dialog: MatDialog,
    private seo: SeoService,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.getLanguage();
    this.setSEO();
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.fragmentSubscription = this.activatedRoute.fragment.subscribe(
      (fragment) => (this.fragment = fragment)
    );
    if (
      !this.authService.loggedIn.value &&
      isPlatformBrowser(this.platformId)
    ) {
      this.apiService.get({ api: this.CONFIG_API }).subscribe((config) => {
        this.maxVisit = config[0].maxProgramsWithOutLogin;
        const visitedNoLogin = parseInt(localStorage.getItem('visited') ?? '0');
        if (visitedNoLogin && visitedNoLogin >= this.maxVisit) {
          // Display material login dialog
          this.dialog.open(AuthComponent, {
            data: { authAction: 'login' },
            disableClose: true,
          });
        } else {
          localStorage.setItem(
            'visited',
            ((visitedNoLogin ?? 0) + 1).toString()
          );
        }
      });
    }

    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
        this.loggedIn = logged;
      });
    }

    this.activatedRoute.url.subscribe(url =>{
      this.getProgram();
    });
  }

  /**
   * Gets language from local storage
   */
  getLanguage(): void {
    if (isPlatformBrowser(this.platformId)) {
      const l = localStorage.getItem('lang');
      if (l) {
        this.lang = l;
      } else {
        this.lang = 'en';
      }
    }
  }

  ngOnDestroy(): void {
    this.fragmentSubscription.unsubscribe();
    if (this.logInSubscription) {
      this.logInSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this.fragment !== undefined && isPlatformBrowser(this.platformId)) {
      this.isOpen = true;
      try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) {}
    }
  }

  /** Set the SEO information with SEO Service */
  setSEO(): void {
    this.seo.setSEO({
      title: this.program.nombre || 'Program',
      description: this.program.overview || 'Program on U360',
      url: 'program/' + this.program.slug,
      image: this.program.universidad.logo,
      main: false,
    });
  }

  /**
   * Registers when the user visits the program
   */
  registerVisitedProgram() {
    // gets the user from cookies
    let data = this.authService.getCurrentLoggedUser();
    let user = undefined;
    if (data) {
      user = JSON.parse(data);
    }
    // if there is a current user
    if (user) {
      // get previous visited programs of the user
      this.apiService
        .get({ api: `${this.API_VISITED_PROGRAMS_BY_STUDENT}${user._id}` })
        .subscribe((visited: any) => {
          // if doesnt exist the user visited programs register
          if (visited === null || visited === undefined) {
            // create new visited empty object
            const newVisited = {
              estudiante: user._id,
              listaProgramas: [],
            };
            // create it in backend
            this.apiService
              .post({ api: `${this.API_VISITED_PROGRAMS}`, data: newVisited })
              .subscribe((response: any) => {
                // get the empty recently created list
                const v = response.result.listaProgramas as any[];
                // verify isnt there already
                const contains =
                  v.filter((p) => p.estudiante._id === this.program._id)
                    .length > 0;
                if (!contains) {
                  // push the current program to the array
                  v.push(this.program._id);
                  // create new saved visited program array
                  const newData = {
                    listaProgramas: v,
                  };
                  // update it on the backend
                  this.apiService
                    .put({
                      api: `${this.API_VISITED_PROGRAMS}/${response.result._id}`,
                      data: newData,
                    })
                    .subscribe((added: any) => {});
                }
              });
            // if the visited programs object has been already created
          } else {
            // get the previous saved visited programs
            const v = visited.listaProgramas as any[];
            // verify isnt there already
            const contains =
              v.filter((p) => p._id === this.program._id).length > 0;
            if (!contains) {
              // push the current program to the list
              v.push(this.program._id);
              // create new object to save
              const newData = {
                listaProgramas: v,
              };
              // update the array in the backend
              this.apiService
                .put({
                  api: `${this.API_VISITED_PROGRAMS}/${visited._id}`,
                  data: newData,
                })
                .subscribe((added: any) => {});
            }
          }
        });
    }
  }

  /**
   * Retrieves the program from the activatedRoute data
   */
  getProgram(): void {
    try {
      const response = this.activatedRoute.snapshot.data.program;
      this.program._id = response._id;
      this.program.nombre =
        this.lang === 'es' && response.nombreEspaniol ? response.nombreEspaniol : response.nombre;
      this.program.acreditacion = response.acreditacion;
      response.universidad.tuitionPerYear = response.yearTuition || response.programTuition || response.semesterTution;
      response.universidad.starts = response.starts;
      this.program.universidad = response.universidad;
      this.program.yearTuition = response.yearTuition;
      this.program.programTuition = response.programTuition;
      this.program.languageRequirements =
        this.lang === 'es'
          ? response.languageRequirementsEspaniol
          : response.languageRequirements;
      this.program.gradeRequirements =
        this.lang === 'es'
          ? response.gradeRequirementsEspaniol
          : response.gradeRequirements;
      this.program.requirements =
        this.lang === 'es'
          ? response.requirementsEspaniol
          : response.requirements;
      this.program.overview =
        this.lang === 'es' ? response.overviewEspaniol : response.overview;
      this.program.overview = this.program.overview?.replace(/•/g, '<br>•')
      this.program.overviewIngles = response.overview;
      this.program.overviewIngles = this.program.overviewIngles?.replace(/•/g, '<br>•')
      this.program.length = response.length;
      this.program.starts = response.starts;
      for (let start of this.program.starts) {
        let startPeriod = '';
        start = parseInt(start);
        switch (start) {
          case 1:
            startPeriod += 'Winter';
            break;
          case 2:
            startPeriod += 'Spring';
            break;
          case 3:
            startPeriod += 'Summer';
            break;
          case 4:
            startPeriod += 'Fall';
            break;
        }
        this.availableStarts.push(startPeriod);
      }
      this.program.slug = response.slug.replaceAll('(', '%28').replaceAll(')', '%29');
      this.program.closeDate = response.closeDate;
      this.program.coOp = response.coOp;
      this.program.stdLength = response.stdLength;
      this.program.acreditacionShown = response.acreditacionShown;
      this.program.provinciaUniversidad = response.provinciaUniversidad;
      this.program.countryFilter = response.countryFilter;
      this.program.prioridad = response.prioridad;
      // requirements string chain validations
      this.program.gradeRequirements = Array.isArray(this.program.gradeRequirements) ? this.program.languageRequirements.filter(req => req !== '<p>ND<p>') : [];
      this.program.languageRequirements = Array.isArray(this.program.languageRequirements) ? this.program.languageRequirements.filter(req => req !== '<p>ND<p>') : [];
      this.program.requirements = this.program.requirements !== '<p>ND</p>' ? this.program.requirements : undefined;
      this.requirements = this.program.requirements.toString().replace(/•/g, '<br>•')
      this.gradeRequirements = this.program.gradeRequirements.toString().replace(/•/g, '<br>•')
      this.languageRequirements = this.program.languageRequirements.toString().replace(/•/g, '<br>•')
      // ---------------------------
      const photos = response.universidad.fotos.filter(
        (e) => e.tipo === 'foto'
      );
      const videos = response.universidad.fotos.filter(
        (e) => e.tipo === 'video'
      );
      this.videos = videos;
      this.photos = photos;
      this.program.universidad["videosMedia"] = this.videos;
      this.program.universidad["fotosMedia"] = this.photos;
      // add them in order, photos first, videos later
      this.program.universidad.fotos = [...photos, ...videos];
       

      // add only urls to thumbnails
      this.thumbnails = [...photos.map((p) => p.url)];
      // get video previews and push them to thumbnails
      for (const vid of videos) {
        const code = vid.url.split('/embed/');
        this.thumbnails.push(
          'https://img.youtube.com/vi/' + code[1] + '/1.jpg'
        );
      }
    } catch (error) {
       
      this.alertService.showError({
        msg: this.translate.instant(
          'Program-detail.there-was-a-problem-program'
        ),
      });
    }
    this.registerVisitedProgram();
  }

  /**
   * Handles the click on the back button
   */
  onBackButtonClick(): void {
    this.location.back();
  }

  /**
   * Handles the click on the university name -> Navigates university detail
   */
   onClickUniversity(): void {
    this.navigationService.navigateTo({
      path: 'university/' + this.program.universidad.slug,
    });
  }

  /**
   * Handles the click on the Apply button
   */
   onApplyClick(): void {
    this.dialog.open(ViewApplyComponent, { data: { program: this.program } });
  }

  /**
   * Check sesion
   * Open url program.universidad.brochure
   *
   * @param {*} open URL to open
   */
   public onViewRequires(open) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user && !this.loggedIn) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      return
    }
    window.open(open);
  }

  /**
   * Validate that the fields are correct
   * @param field field to validate
   */
   public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched &&
      this.createForm.controls[field].invalid
    );
  }

  onSubmit({ event }): void {
    if (this.createForm.valid) {
      const form = {
        nombre: this.createForm.controls.nombre.value,
        apellido: this.createForm.controls.apellido.value,
        correo: this.createForm.controls.correo.value,
        anioInteres: this.createForm.controls.anioInteres.value,
        edad: this.createForm.controls.edad.value,
        telefono: this.createForm.controls.telefono.value,
        programa: this.createForm.controls.programa.value,
        pregunta: this.createForm.controls.pregunta.value,
        fuente: this.program.nombre+" - "+this.program.universidad.nombre,
      };
      this.createFormByContact({ form });
    } else {
      this.createForm.markAllAsTouched();
    }
  }

  /**
   * create a form
   * @param form
   */
  public async createFormByContact({ form }) {
    let resp = await this.apiService
      .post({ api: `${this.API_FORM}`, data: form })
      .toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Formulario creado`,
      });
    }
  }

  public showDetails({ item }) {
    if (item == 'form') {
      this.displayForm = !this.displayForm;
    }
  }

  public changeShowMore(){
    this.showMore = !this.showMore;
  }

}
