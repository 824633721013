<div class="free-guides" *ngIf="isDataStudentComplete">
  <div class="free-guides-container">
    <div class="free-guides-right">
      <img
        (click)="goBack()"
        class="left-arrow display-mobile"
        src="/assets/wizard-images/left-arrow.png"
        alt="left-arrow" />
      <h2 class="free-guides-subtitle">
        Tu potencial es ilimitado. Participa en nuestro test de competencias y desbloquea las habilidades que te
        llevarán a la cima de tus objetivos.
      </h2>
      <video src="/assets/new-wizard-videos/bienvenida.mp4" controls></video>
      <h1 class="free-guides-title">¡Anímate y realiza nuestro test!</h1>
      <div class="row">
        <p class="conditions">
          Compromiso Moral y Ético Estimado(a) participante, Antes de comenzar con esta prueba, es importante que
          comprendas que su propósito es exclusivamente diagnóstico. Los resultados de esta prueba no tendrán ninguna
          implicación académica ni afectarán tu desempeño escolar. Su única finalidad es obtener una visión honesta y
          precisa de tus pensamientos y/o comportamientos en diferentes situaciones. Por favor, responde cada pregunta
          de la manera que más se ajuste a tu forma de pensar y actuar. Tu sinceridad es fundamental para que el
          diagnóstico sea efectivo y útil. Confía en que todas tus respuestas serán tratadas con confidencialidad y
          respeto. Tu honestidad y colaboración son esenciales para el éxito de este diagnóstico. Agradecemos tu
          compromiso y responsabilidad en este proceso. Declaro que responderé esta prueba con sinceridad y conforme a
          mi verdadera forma de pensar y actuar.
        </p>
        <div class="col-1">
          <img
            *ngIf="seleccionado == 0"
            (click)="cambiarSeleccionado()"
            style="height: auto; width: 20px"
            src="/assets/utils/radio-boton-gris-vacio.png"
            alt="acepta" />
          <img
            *ngIf="seleccionado == 1"
            style="height: auto; width: 20px"
            src="/assets/utils/radio-boton-gris-lleno.png"
            alt="acepta" />
        </div>

        <div class="col-10">
          <p class="conditions">
            Acepto los
            <span class="negrilla">términos y condiciones y politica de privacidad</span>
          </p>
        </div>
      </div>
      <div class="buttons-container">
        <button class="btnComenzar" (click)="comenzarTest()">Empezar test</button>
      </div>
    </div>
  </div>
</div>
<div class="d-flex mt-3 mt-lg-5 withoutSkillTestMessage" *ngIf="!isDataStudentComplete">
  <div>
    <label class="about-me-title txt-style mb-2 mb-lg-4">
      ¡No has completado toda tu información! ¡Completa toda tu información para realizar el test!
    </label>
  </div>
  <button class="btn-experiences" (click)="goToPersonalInfo()">Completar</button>
</div>
