<div class="service-detail-container">
  <div class="close">
    <mat-icon (click)="selfClose()">close</mat-icon>
  </div>

  <div *ngIf="data.service === 'visa'">
    <div class="title">
      <img class="country-img" [src]="data.image" alt="{{ data.country }}" />
      {{ data.country }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.overview' | translate }}
    </div>
    <div class="type" *ngIf="data && data.type">
      {{ data.type }}
    </div>
    <p class="overview">
      {{ data.overview }}
    </p>
    <div class="subtitle">
      {{ 'Service-detail-modal.price' | translate }}
    </div>
    <div class="price">${{ data.cost | number }} {{ data.currency }}</div>
  </div>

  <div *ngIf="data.service === 'medical'">
    <div class="title">
      {{ data.name }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.Provider' | translate }}
    </div>
    <div class="provider">
      <img class="country-img" [src]="data.image" alt="{{ data.provider }}" />
      {{ data.provider }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.overview' | translate }}
    </div>
    <p class="overview">
      {{ data.overview }}
    </p>
    <div *ngIf="data.file !== undefined && data.file !== null">
      <a [href]="data.file" target="_blank">View additional information</a>
    </div>
    <div class="countries">
      <div class="subtitle">
        {{ 'Service-detail-modal.countries' | translate }}
      </div>
      <div class="country" *ngFor="let country of data.countries">
        <img class="country-img-2" [src]="country.icono" alt="{{ country.nombre }}" />
        {{ country.nombre }}
      </div>
    </div>
  </div>

  <div *ngIf="data.service === 'scholarship'">
    <div class="title">
      <img class="university-img" [src]="data.image" alt="{{ data.university }}" />
      {{ data.university }} - {{ data.country }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.overview' | translate }}
    </div>
    <p class="overview">
      {{ data.overview }}
    </p>
    <p class="overview">
      {{ data.fuente }}
    </p>
    <div class="subtitle">
      {{ 'Service-detail-modal.scholarship-perc' | translate }}
    </div>
    <div class="percentage">{{ data.percentage }} %</div>
  </div>

  <div *ngIf="data.service === 'language'">
    <div class="title">
      {{ data.name }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.Provider' | translate }}
    </div>
    <div class="provider">
      <img class="provider-img" [src]="data.image" alt="{{ data.provider }}" />
      {{ data.provider }}
    </div>
    <div class="subtitle" *ngIf="data.type !== 'vocacional'">
      {{ 'Service-detail-modal.language' | translate }}
    </div>
    <div class="language" *ngIf="data.type !== 'vocacional'">
      {{ data.language }}
    </div>
    <div class="subtitle">
      {{ 'Service-detail-modal.price' | translate }}
    </div>
    <div class="price">${{ data.cost | number }} {{ data.currency }}</div>
  </div>
</div>
