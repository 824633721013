<div class="chartsContainer">
  <div class="filterContainer">
    <button class="clearFilters" mat-raised-button (click)="clearFilters()">Limpiar Filtros</button>
    <mat-form-field class="gradeFilter">
      <mat-select [(ngModel)]="selectedGrades" (ngModelChange)="filterGrades()">
        <mat-option [value]="'Todos'">Todos</mat-option>
        <mat-option *ngFor="let grade of grades" [value]="grade">
          {{ grade }}
        </mat-option>
      </mat-select>
      <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
    </mat-form-field>

    <mat-form-field class="gradeFilter" *ngIf="selectedGrades !== 'Todos'">
      <mat-select (selectionChange)="filterLevels($event.value)" [(ngModel)]="lastSelectedGrade">
        <mat-option *ngFor="let level of matchedGradeLevels" [value]="level">
          {{ level }}
        </mat-option>
      </mat-select>
      <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
    </mat-form-field>
  </div>

  <div class="scrollableContainer">
    <div class="skillCardsContainer">
      <div class="skillCard" *ngFor="let skill of skills" [style.background-color]="skill.color">
        <div class="skillName">{{ skill.name }}</div>
        <div class="skillInfo">
          <span class="skillPercentage">{{ skill.percentage }}%</span>
          <span class="skillLevel">
            {{
              skill.percentage >= 66
                ? 'Alto'
                : skill.percentage > 33
                ? 'Medio'
                : skill.percentage <= 33
                ? 'Principiante'
                : skill.percentage >= 0
                ? 'Principiante'
                : ''
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="middleRowContainer">
    <div class="skillsLevelChartContainer">
      <div class="dropdownContainer">
        <p class="skillsLevelTitle">Estudiantes por Nivel Habilidad.</p>
        <mat-form-field class="skillsFilter">
          <mat-select id="dataSelector" [(ngModel)]="selectedChartData" (selectionChange)="updateSkillsLevelChart()">
            <mat-option *ngFor="let skill of skillsOptions" [value]="skill.value">{{ skill.label }}</mat-option>
          </mat-select>
          <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
        </mat-form-field>
      </div>
      <apx-chart
        [dataLabels]="skillsLevelChart.dataLabels"
        [yaxis]="skillsLevelChart.yaxis"
        [theme]="skillsLevelChart.theme"
        [series]="skillsLevelChart.series"
        [chart]="skillsLevelChart.chart"
        [plotOptions]="skillsLevelChart.plotOptions"
        [fill]="skillsLevelChart.fill"
        [stroke]="skillsLevelChart.stroke"
        [labels]="skillsLevelChart.xaxis.categories"
        [legend]="skillsLevelChart.legend"></apx-chart>
    </div>
    <div class="averageChartContainer">
      <p class="skillsLevelTitle">Promedio General.</p>
      <apx-chart
        [series]="averageChartoptions.series"
        [chart]="averageChartoptions.chart"
        [plotOptions]="averageChartoptions.plotOptions"
        [fill]="averageChartoptions.fill"
        [stroke]="averageChartoptions.stroke"
        [labels]="averageChartoptions.labels"></apx-chart>
    </div>
  </div>

  <div class="bottomRowContainer">
    <div class="universityTypeContainer">
      <p class="skillsLevelTitle">Intención de Tipo de Universidad.</p>
      <div class="universityTypeChart">
        <apx-chart
          [colors]="chartOptions.colors"
          [dataLabels]="chartOptions.dataLabels"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [labels]="chartOptions.labels"
          [legend]="chartOptions.legend"
          [responsive]="chartOptions.responsive"></apx-chart>
      </div>
    </div>
    <div class="languagesChart">
      <p class="skillsLevelTitle">Estudiantes por Nivel de Inglés.</p>
      <apx-chart
        [theme]="chartLanguagesOptions.theme"
        [fill]="chartLanguagesOptions.fill"
        [yaxis]="chartLanguagesOptions.yaxis"
        [series]="chartLanguagesOptions.series"
        [chart]="chartLanguagesOptions.chart"
        [xaxis]="chartLanguagesOptions.xaxis"
        [stroke]="chartLanguagesOptions.stroke"
        [plotOptions]="chartLanguagesOptions.plotOptions"
        [toolbar]="chartLanguagesOptions.toolbar"></apx-chart>
    </div>
  </div>
</div>
