<div id="chart" *ngIf="shouldUpdateChart">
  <apx-chart
    [series]="chartOptions.series"
    [legend]="chartOptions.legend"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [tooltip]="chartOptions.tooltip"
    [dataLabels]="chartOptions.dataLabels"
    [annotations]="chartOptions.annotations"></apx-chart>
</div>
