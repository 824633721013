<div class="more-information">
  <div class="container-information">
    <div class="title-information">
      <img src="assets/utils/birrete-amarillo.png" alt="yellow line">
      <label>Más información</label>
    </div>
    <div class="container-img-information">
      <div class="container-sections">
        <div class="img-text">
          <img src="assets/utils/tipo-de-programa-blanco.png" alt="Type of university">
          <label>Tipo: (Pública/Privada): {{ ((university.tipo | lowercase) == 'public') ?
            'Pública':'Privada'}}</label>
        </div>
        <div class="img-text">
          <img src="assets/utils/programa-blanco.png" alt="program graduation work permit">
          <label>Post-Graduation Work Permit: {{ (university.pgwp)?"Sí":"No"}}</label>
        </div>
        <div class="img-text">
          <img src="assets/utils/international.png" alt="international">
          <label *ngIf="university.programasInternacionales">Programas para internacionales: {{ university.programasInternacionales }}</label>
          <div class="label-container" *ngIf="!university.programasInternacionales">Programas para internacionales: <span class="text-decoration" (click)="goToUniversitySearch()">Ver más</span></div>
        </div>
        <div class="img-text">
          <img src="assets/utils/tuition.png" alt="tuition">
          <label>{{(university.tuitionPerYear) ? 'Matrícula Anual Promedio:':'Costos por año:'}} {{(university.tuitionPerYear) ?
            (university.tuitionPerYear || university.costsPerYear | number) + university.ciudad.pais.moneda + '/ Año':
            "ND" }}
          </label>
        </div>
      </div>
      <div>
        <div class="img-text">
          <img src="assets/utils/facilities-campus.png" alt="facilities">
          <label>{{(university.starts) ? 'Temporada:':'Instalaciones:'}} {{(university.starts) ? getStart():'Campus'}}</label>
        </div>
        <div class="img-text">
          <img src="assets/utils/work.png" alt="can work while studying">
          <label>Trabajar mientras estudia: {{ (university.workStudying)? 'Sí':'No' || "ND"}}</label>
        </div>
        <div class="img-text">
          <img src="assets/utils/living-cost.png" alt="living cost">
          <label>Costo de vida: (Costo de vida sin renta): {{university.livingCost | number}}
            {{university.ciudad.pais.moneda}} / Mes</label>
        </div>
        <div class="img-text">
          <img src="assets/utils/fee-blanco.png" alt="application fee">
          <label>Cuota de aplicación: {{ university.applicationFee ? (university.applicationFee | number) + university.ciudad.pais.moneda : 'ND'}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
