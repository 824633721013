export const questions = [
  {
    pregunta: '¿Tienes promedio académico por encima del 80% en los últimos tres años?',
    opcionSeleccionada: '',
  },
  { pregunta: '¿Has trabajado durante tus vacaciones?', opcionSeleccionada: '' },
  { pregunta: '¿Perteneces a grupos de Investigación?', opcionSeleccionada: '' },
  {
    pregunta: '¿Has sacado adelante un plan de negocio o creado algún emprendimiento?',
    opcionSeleccionada: '',
  },
  {
    pregunta:
      '¿Tienes un portafolio con logros o muestras de tu trabajo artístico? (Responde esta pregunta únicamente si eres artista o te interesa esta área)',
    opcionSeleccionada: '',
  },
  { pregunta: 'Voluntariado Ambiental', opcionSeleccionada: '' },
  { pregunta: 'Voluntariado Cultural', opcionSeleccionada: '' },
  { pregunta: 'Ayuda Civil', opcionSeleccionada: '' },
  { pregunta: '¿Cuentas con cartas de recomendación?', opcionSeleccionada: '' },
  { pregunta: '¿Cuentas con ensayos de perspectiva profesional?', opcionSeleccionada: '' },
  {
    pregunta: '¿Cuentas con un Portafolio? (Aplica principalmente para artes, música, industria creativa)',
    opcionSeleccionada: '',
  },
  {
    pregunta: '¿Tienes experiencias académicas, voluntariados u otros con personas de culturas de otra lengua?',
    opcionSeleccionada: '',
  },

  {
    pregunta: 'Cuento con Presupuesto para Estudiar en USA (30.000 USD A 80.000 USD al año)',

    opcionSeleccionada: '',
  },
  {
    pregunta:
      'Cuento con Presupuesto para Estudiar en otro destino de Norteamérica o Europa (18.000 USD a 30.000 USD al año)',

    opcionSeleccionada: '',
  },
  {
    pregunta: 'Cuento con Presupuesto para estudiar en una universidad Privada Top en Colombia (14.000 USD)',

    opcionSeleccionada: '',
  },
  {
    pregunta:
      'Cuento con Presupuesto para estudiar en una universidad Privada de menor costo en Colombia (10.000 USD ó menos)',

    opcionSeleccionada: '',
  },
];
