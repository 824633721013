import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-student-documents',
  templateUrl: './student-documents.component.html',
  styleUrls: ['./student-documents.component.scss']
})
export class StudentDocumentsComponent implements OnInit {

  readonly GET_STUDENT = 'api/oneUser/'
  
  loading:boolean=false;

  studentName:any;

  documents=[];

  constructor(
    @Inject (MAT_DIALOG_DATA) public studentObject,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
     
    let id = this.studentObject.student
    this.getStudentData({id})
  }

  getStudentData({id}){
    this.loading=true
    this.apiService.get({api:this.GET_STUDENT+id}).subscribe(
      (response)=>{
        if(response){
          this.studentName = `${response.nombre} ${response.apellido}`
          let studentDocs = response.documentosAdicionales
          for (const item of studentDocs) {
            if(item.nombre!="photo"){
              this.documents.push(item)
            }
          };
          this.loading=false
        }else{
          this.loading=false
        }
      }
    )
  }
}
