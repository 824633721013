import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  /** CONSTANTS */
  /** Api path to retrive the translation services */
  readonly TRANSLATION_SERVICES_API = 'api/servicio-traduccion/buscar';
  /** Api path to retrieve medical services */
  readonly MEDICAL_SERVICES_API = 'api/servicio-medico/buscar';
  /** Api path to retrieve visa services */
  readonly VISA_SERVICES_API = 'api/servicio-visa/buscar';
  /** Api path to retrieve visa services */
  readonly CONSULTORIA_SERVICES_API = 'api/consultoria/buscar';
  /** Api path to retrieve language test services */
  readonly LANGUAGE_TESTS_API = 'api/test-idioma/buscar';
  /** Api path to retrieve scholarship services */
  readonly SCHOLARSHIP_API = 'api/beca/buscar';
  /** Api path to retrieve the list of countries */
  readonly COUNTRIES_API = 'api/pais/';

  /** CONSTANT that handles how many services are displayed per page */
  readonly SERVICES_PER_PAGE = 10;

  /** List of services types */
  readonly services = [{
    nombre: this.translate.instant("Marketplace.translation-name") ,
    value: 'traducciones',
  }, {
    nombre: this.translate.instant("Marketplace.visas-name") ,
    value: 'visas',
  }, {
    nombre: this.translate.instant("Marketplace.travel-insurance-name"),
    value: 'servicios-medicos',
  }, {
    nombre: this.translate.instant("Marketplace.scholarship-bank-name"),
    value: 'banco-de-becas',
  }, {
    nombre: this.translate.instant("Marketplace.language-test-name"),
    value: 'test-de-idioma',
  }, {
    nombre: this.translate.instant("Marketplace.vip-consultancy-name"),
    value: 'consultoria-vip'
  }, {
    nombre: this.translate.instant("Marketplace.vocational-tests-name"),
    value: 'gallup',
  }];

  /** Variable that initializes the language tests tags  */
  readonly languageTestTags = [
    { name: 'simulacro', selected: false },
    { name: 'idioma', selected: false },
    { name: 'all', selected: true },
  ];

  /** Variable that initializes the vip consultancy tags */
  readonly vipConsultancyTags = [
    { name: 'migracion', selected: false },
    { name: 'estudiantil', selected: false },
    { name: 'all', selected: true },
  ];

  /** Variable that holds the index of the current type of service that is being searched */
  currentServiceType = 0;

  /** Variable that holds the list of services that is being displayed */
  servicesData: any[] = [];
  filterServicesData: any[] = [];

  /** Variable that holds the list of countries */
  countries: any[] = [];

  /** Variable that haolds the currently selected country */
  country = '';

  /** Variable that holds the number of the current page */
  page = 1;

  /** Variable that holds the total number of services */
  servicesNumber = 1;

  /** Tags for the filter of the services */
  public tags: any[] = [];

  /** Variable that holds the current tag  */
  currentTag = '';

  constructor(private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private alertService: AlertService,
              private navigationService: NavigationService,
              private ngxSpinnerService: NgxSpinnerService,
              private translate: TranslateService
              ) { }

  ngOnInit(): void {
    this.getCountries();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.servicesData = [];
      this.filterServicesData = [];
      const serviceType = params.get('serviceType');
      const country = params.get('country');
      const page = params.get('page');
      const filterTags = params.get('tags');
      this.currentTag = filterTags;
      if (page !== null && page !== undefined && page !== '') {
        this.page = Number.parseInt(page);
      } else {
        this.page = 1;
      }
      let query = {
        page: this.page,
        limit: this.SERVICES_PER_PAGE
      }
      let path = '';
      switch (serviceType) {
        case 'traducciones':
          path = this.TRANSLATION_SERVICES_API;
          this.currentServiceType  = 0;
          this.country = '';
          if (this.tags.length > 0) {
            this.tags = []
          }
          break;
        case 'visas':
          path = this.VISA_SERVICES_API;
          this.currentServiceType = 1;
          if (country !== null && country !== undefined && country !== '') {
            query['pais'] = country;
            this.country = country;
          }
          if (this.tags.length > 0) {
            this.tags = []
          }
          // query['tipo'] = ['visa'];
          break;
        case 'servicios-medicos':
          path = this.MEDICAL_SERVICES_API;
          this.currentServiceType = 2;
          if (country !== null && country !== undefined && country !== '') {
            query['pais'] = country;
            this.country = country;
          }
          break;
        case 'banco-de-becas':
          path = this.SCHOLARSHIP_API;
          this.currentServiceType = 3;
          if (country !== null && country !== undefined && country !== '') {
            query['pais'] = country;
            this.country = country;
          }
          if (this.tags.length > 0) {
            this.tags = []
          }
          break;
        case 'test-de-idioma':
          path = this.LANGUAGE_TESTS_API;
          this.currentServiceType = 4;
          let tagscopy = this.languageTestTags.slice();
          let queryTags = []
          for (let i = 0; i < tagscopy.length; i ++) {
            if (tagscopy[i].name === filterTags) {
              tagscopy[i].selected = true;
              queryTags.push(tagscopy[i].name);
            } else {
              tagscopy[i].selected = false;
            }
          }
          this.tags = tagscopy;
          query['tags'] = queryTags;
          break;
        case 'consultoria-vip':
          path = this.CONSULTORIA_SERVICES_API,
          this.currentServiceType = 5;
          let tagscopy1 = this.vipConsultancyTags.slice();
          let queryTags1 = [];
          for (let i = 0; i < tagscopy1.length; i ++) {
            if (tagscopy1[i].name === filterTags) {
              tagscopy1[i].selected = true;
              queryTags1.push(tagscopy1[i].name);
            } else {
              tagscopy1[i].selected = false;
            }
          }
          this.tags = tagscopy1;
          query['tags'] = queryTags1;
          break;
          case 'gallup':
          path = this.LANGUAGE_TESTS_API,
          this.currentServiceType = 10;
          if (this.tags.length > 0) {
            this.tags = []
          }
          query['tipo'] = ['vocacional'];
          break;
        default:
          path = this.TRANSLATION_SERVICES_API;
          this.currentServiceType = 0;
          break;
      }
      this.getServices({apiPath: path, query});
    });
  }

  /** Retrieves the services of the given service type from the backend
   * @param apiPath api path of the services that must be retrieved
   * @param query query that is going to be searched in the backend
   */
  getServices({apiPath, query}: {apiPath: string, query: any}): void {
    this.ngxSpinnerService.show();
    if (apiPath !== '') {
      this.apiService.post({api: apiPath, data: query}).subscribe((response) => {
        this.servicesNumber = response.totalDocs;
        this.servicesData = response.docs;
        this.filterServicesData = response.docs;
      }, err => {
        this.alertService.showError({msg: this.translate.instant("Marketplace.error-load-services")  + err.msg });
      }, (complete?) => {
        this.ngxSpinnerService.hide();
      } );
    }
  }

  /** Retrieves the list of countries from the backend */
  getCountries(): void {
    this.apiService.get({api: this.COUNTRIES_API}).subscribe((response) => {
      this.countries = response;
    }, err => {
      this.alertService.showError({msg: this.translate.instant("Marketplace.error-load-country") + err.msg});
    });
  }

  /**
   * Handles the change on country selector
   * @param event change event
   */
  onCountryChange(): void {
    this.navigationService.navigateTo({path: '/marketplace', params: {
      serviceType: this.services[this.currentServiceType].value,
      country: this.country,
      page: 1
    }});
  }

  /** Handles the event change in the pagination controls */
  onPageChange(event): void {
    this.navigationService.navigateTo({path: '/marketplace', params: {
      serviceType: this.services[this.currentServiceType].value,
      country: this.country,
      page: event,
      tags: this.currentTag,
    }});
  }

  /** Seleted type for the service in the tags */
   onSelectTag({ tag } ){
    this.navigationService.navigateTo({path: '/marketplace', params: {
      serviceType: this.services[this.currentServiceType].value,
      country: this.country,
      tags: tag.name,
    }});
   }
}
