import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['./visa.component.scss']
})
export class VisaComponent implements OnInit {

  @Input() stepData: any;
  /** Variable that holds the list of steps */
  substeps = [{
    name: `1. ${this.translate.instant("Get-your-visa.embassy-documents-title")}`,
    status: 'not-started',
  }, {
    name: `2. ${this.translate.instant("Get-your-visa.funds-demostration-title")}`,
    status: 'not-started',
  }, {
    name: `3. ${this.translate.instant("Get-your-visa.cover-letter-title")}`,
    status: 'not-started',
  }, {
    name: `4. ${this.translate.instant("Get-your-visa.document-review-title")}`,
    status: 'not-started',
  }, {
    name: `5. ${this.translate.instant("Get-your-visa.visa-form-title")}`,
    status: 'not-started',
  }, {
    name: `6. ${this.translate.instant("Get-your-visa.visa-interview-title")}`,
    status: 'not-started',
  }, {
    name: `7. ${this.translate.instant("Get-your-visa.biometric-marking-title")}`,
    status: 'not-started',
  }, {
    name: `8. ${this.translate.instant("Get-your-visa.receive-your-visa")}`,
    status: 'not-started',
  }];
  /** Variable that holds the data of the step */
  getYourVisa = {
    documentosEmbajada: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    inicioDemostracionFondos: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    cartaPresentacion: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    revisionDocumentos: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    formularioVisa: {
      fechaMaxima: '',
      completado: false,
      fechaCompletado: ''
    },
    citaVisa: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    tomaBiometricos: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    recepcionVisa: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
  };

  /** Variable that indicates which tab is currently selected */
  selected = 0;
  /** Variable that holds the current application id */
  applicationId: string;

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    if (this.stepData !== undefined) {
      // Setup first substep
      this.getYourVisa.documentosEmbajada.completado = this.stepData.documentosEmbajada.completado;
      if (this.getYourVisa.documentosEmbajada.completado) {
        this.substeps[0].status = 'completed';
      } else {
        this.substeps[0].status = 'not-started';
      }

      // Setup second substep
      this.getYourVisa.inicioDemostracionFondos.completado = this.stepData.inicioDemostracionFondos.completado;
      if (this.getYourVisa.inicioDemostracionFondos.completado) {
        this.substeps[1].status = 'completed';
      } else {
        this.substeps[1].status = 'not-started';
      }

      // Setup third substep
      this.getYourVisa.cartaPresentacion.completado = this.stepData.cartaPresentacion.completado;
      if (this.getYourVisa.cartaPresentacion.completado) {
        this.substeps[2].status = 'completed';
      } else {
        this.substeps[2].status = 'not-started';
      }

      // Setup fourth substep
      this.getYourVisa.revisionDocumentos.completado = this.stepData.revisionDocumentos.completado;
      if (this.getYourVisa.revisionDocumentos.completado) {
        this.substeps[3].status = 'completed';
      } else {
        this.substeps[3].status = 'not-started';
      }

      // Setup fifth substep
      this.getYourVisa.formularioVisa.completado = this.stepData.formularioVisa.completado;
      let formStarted = false;
      for(const property in this.stepData.formularioVisa.formulario) {
        if (typeof this.stepData.formularioVisa.formulario[property] === 'string' && property !== '_id') {
          formStarted = true;
          break;
        }
        else if(Array.isArray(this.stepData.formularioVisa.formulario[property])) {
          if(this.stepData.formularioVisa.formulario[property].length > 0) {
            formStarted = true;
            break;
          }
        }
      }
      if (this.getYourVisa.formularioVisa.completado) {
        this.substeps[4].status = 'completed';
      } else if( formStarted && !this.getYourVisa.formularioVisa.completado) {
        this.substeps[4].status = 'progress';
      } else {
        this.substeps[4].status = 'not-started';
      }

      // Setup sixth step
      this.getYourVisa.citaVisa.completado = this.stepData.citaVisa.completado;
      if (this.getYourVisa.citaVisa.completado) {
        this.substeps[5].status = 'completed';
      } else {
        this.substeps[5].status = 'not-started';
      }

      // Setup seventh step
      this.getYourVisa.tomaBiometricos.completado = this.stepData.tomaBiometricos.completado;
      if (this.getYourVisa.tomaBiometricos.completado) {
        this.substeps[6].status = 'completed';
      } else {
        this.substeps[6].status = 'not-started';
      }

      // Setup eigth step
      this.getYourVisa.recepcionVisa.completado = this.stepData.recepcionVisa.completado;
      if (this.getYourVisa.recepcionVisa.completado) {
        this.substeps[7].status = 'completed';
      } else {
        this.substeps[7].status = 'not-started';
      }

    }
  }

  /**
   * Changes the currently selected tab
   * @param index index of the new selected tab
   */
  onChangeTab({ index }: { index: number }): void {
    this.selected = index;
  }

  /**
   * Redirects the user to the visa form page
   */
  onGoToForm(): void {
    this.navigationService.navigateTo({ path: 'application/' + this.applicationId + '/visa' });
  }

}
