import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { AcademicLevelExplanationComponent } from '../../profile/academic-interests/academic-level-explanation/academic-level-explanation.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  /** CONSTANTS */
  /** Backend ip for countries */
  readonly COUNTRIES_API = 'api/pais/';
  /** Configuration for the dropdown settings of lists that are configured as constants */
  readonly settingsA: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  /**
   * Configuration for the dropdown settings that come from the backend
   */
  readonly settingsB: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'nombre',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  /** Dropdown that holds the list of options for the discipline filter */
  disciplineDropdownList = [
    {
      value: 'Arts, Design and Architecture',
      name: 'Home.arts-architecture',
      text: '',
    },
    {
      value: 'Business and Management',
      name: 'Home.business',
      text: '',
    },
    {
      value: 'Computer Sciences and IT',
      name: 'Home.education',
      text: '',
    },
    {
      value: 'Engineering',
      name: 'Home.engineering',
      text: '',
    },
    {
      value: 'Sciences and Mathematics',
      name: 'Home.sciences-math',
      text: '',
    },
    {
      value: 'Social Sciences and Humanities',
      name: 'Home.social-sciences',
      text: '',
    },
    {
      value: 'Education',
      name: 'Home.education',
      text: '',
    },
    {
      value: 'Community Services',
      name: 'Home.community',
      text: '',
    },
    {
      value: 'Environmental Studies and Earth Sciences',
      name: 'Home.env-studies',
      text: '',
    },
    {
      value: 'Health',
      name: 'Home.health',
      text: '',
    },
    {
      value: 'Journalism and Media',
      name: 'Home.journalism',
      text: '',
    },
    {
      value: 'Law and Politic Sciences',
      name: 'Home.law-politics',
      text: '',
    },
    {
      value: 'Languages',
      name: 'Home.languages',
      text: '',
    },
    {
      value: 'Tourism and Hospitality',
      name: 'Home.tourism-hospitality',
      text: '',
    },
  ];

  /**
   * Variable that holds the list of options for the accreditation filter
   */
  accreditationDropdownList = [
    {
      value: 'Language - Courses & Pathways',
      name: 'Home.Language-Courses-Pathways',
      text: '',
    },
    {
      value: 'Undergraduate - Preparation & Pathways',
      name: 'Home.Undergraduate-Preparation-Pathways',
      text: '',
    },
    {
      value: 'Undergraduate - Certificates & Diplomas',
      name: 'Home.Undergraduate-Certificates-Diplomas',
      text: '',
    },
    {
      value: `Undergraduate - Bachelor`,
      name: 'Home.Undergraduate-Bachellors-Degree',
      text: '',
    },
    {
      value: 'Postgraduate - Preparation & Pathways',
      name: 'Home.Postgraduate-Preparation-Pathways',
      text: '',
    },
    {
      value: 'Postgraduate - Certificates & Diplomas',
      name: 'Home.Postgraduate-Certificates-Diplomas',
      text: '',
    },
    {
      value: 'Postgraduate - Master`s Degree and Doctorates',
      name: 'Home.Postgraduate-Master',
      text: '',
    }
  ];

  countriesDropdownList = [
    {
      value: 'Colombia',
      name: 'Colombia',
      text: ''
    },
    {
      value: 'Canada',
      name: 'Canada',
      text: ''
    },
    {
      value: 'Germany',
      name: 'Germany',
      text: ''
    },
    {
      value: 'United Kingdom',
      name: 'United Kingdom',
      text: ''
    },
    {
      value: 'United States',
      name: 'United States',
      text: ''
    },
    {
      value: 'Otros Europa',
      name: 'Otros Europa',
      text: ''
    }
  ];

  /** VARIABLES */
  /** Variable that holds the value of the location input */
  location: any[] = [];
  /** Variable that holds the value of the discipline input */
  discipline: any[] = [];
  /** Variable that holds the value of the  degree level input */
  degreeLevel: any[] = [];
  /** Variable that holds the value of the professions in high demand input */
  highDemand = false;
  /** List of countries */
  countries: Array<any> = [];
  countryFilter: Array<any> = [];

  /** Handles the translation events and updates the data accordingly */
  translationSubcription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslateService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    this.translationSubcription = this.translate.onLangChange.subscribe(
      (event) => {
        this.setupTranslations();
      }
    );
    this.setupTranslations();
    this.apiService.get({ api: this.COUNTRIES_API }).subscribe(
      (response) => {
        this.countries = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Academic-interests.there-was-an-issue'),
        });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.translationSubcription) {
      this.translationSubcription.unsubscribe();
    }
  }

  /**
   * Sets up  the translations of the dropdowns
   */
  setupTranslations(): void {
    const accreditations = this.accreditationDropdownList.slice();
    for (let i = 0; i < accreditations.length; i++) {
      accreditations[i].text = this.translate.instant(
        this.accreditationDropdownList[i].name
      );
    }
    this.accreditationDropdownList = accreditations;

    const disciplines = this.disciplineDropdownList.slice();
    for (let i = 0; i < disciplines.length; i++) {
      disciplines[i].text = this.translate.instant(
        this.disciplineDropdownList[i].name
      );
    }
    this.disciplineDropdownList = disciplines;

    const countryFilter = this.countriesDropdownList.slice();
    for (let i = 0; i < countryFilter.length; i++) {
      countryFilter[i].text = this.translate.instant(
        this.countriesDropdownList[i].name
      );
    }
    this.countriesDropdownList = countryFilter;
  }

  /**
   * Handles the click on the search button
   */
  onSearchClick(): void {
    this.navigationService.navigateTo({ path: '/program/search' });
  }

  /**
   * Handles the apply filters click event
   */
  onSubmitSearch(): void {
    const countryParam = this.location.map((c) => c._id);
    const accreditationParam = this.degreeLevel.map((a) => a.value);
    const disciplineParam = this.discipline.map((d) => d.value);
    const highDemand = this.highDemand;
    const countriesParam = this.countryFilter.map((s) => s.value);
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        discipline: disciplineParam,
        acreditacion: accreditationParam,
        pais: countryParam,highDemand,
        countryFilter: countriesParam,
      },
    });
  }

  /**
   * Handles the click on the info icon
   */
  onInfoClick(): void {
    this.dialog.open(AcademicLevelExplanationComponent);
  }
}
