import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-enrolment',
  templateUrl: './enrolment.component.html',
  styleUrls: ['./enrolment.component.scss']
})
export class EnrolmentComponent implements OnInit {

  @Input() stepData: any;
  /** Variable that holds the list of substeps */
  substeps = [{
    name: `1. ${this.translate.instant("Enrolment.farewell-session")}`,
    status: 'not-started',
  }, {
    name: `2. ${this.translate.instant("Enrolment.travel-day")}`,
    status: 'not-started'
  }, {
    name: `3. ${this.translate.instant("Enrolment.course-start")}`,
    status: 'not-started'
  }];
  /** Variable that holds the consolidated data of the step */
  enrolment = {
    sesionDespedida: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    diaViaje: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    },
    fechaInicioCurso: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    }
  };

  /** Variable that holds the current selected tab */
  selected = 0;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.stepData !== undefined) {
      // Setup first substep
      this.enrolment.sesionDespedida.completado = this.stepData.sesionDespedida.completado;
      this.enrolment.sesionDespedida.fechaMaxima = this.stepData.sesionDespedida.fechaMaxima;
      if (this.enrolment.sesionDespedida.completado) {
        this.substeps[0].status = 'completed';
      } else {
        this.substeps[0].status = 'not-started';
      }

      // Setup second substep
      this.enrolment.diaViaje.completado = this.stepData.diaViaje.completado;
      this.enrolment.diaViaje.fechaMaxima = this.stepData.diaViaje.fechaMaxima;
      if (this.enrolment.diaViaje.completado) {
        this.substeps[1].status = 'completed';
      } else {
        this.substeps[1].status = 'not-started';
      }

      // Setup third substep
      this.enrolment.fechaInicioCurso.completado = this.stepData.fechaInicioCurso.completado;
      this.enrolment.fechaInicioCurso.fechaMaxima = this.stepData.fechaInicioCurso.fechaMaxima;
      if (this.enrolment.fechaInicioCurso.completado) {
        this.substeps[2].status = 'completed';
      } else {
        this.substeps[2].status = 'not-started';
      }
    }
  }

  /**
   * Changes the current selected tab
   * @param index index of the new selected tab
   */
  onChangeTab({ index }: { index: number }): void {
    this.selected = index;
  }

}
