<div class="chartsContainer">
  <div class="filterContainer">
    <mat-form-field class="gradeFilter">
      <mat-select [(ngModel)]="selectedOption" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
      <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
    </mat-form-field>
  </div>
  <div class="skillsLevelsContainer">
    <div class="skillsfilterContainer">
      <p class="skillsLevelTitle">Comparación de otros colegios por habilidad.</p>
      <mat-form-field class="skillsFilter">
        <mat-select [(ngModel)]="selectedSkill" (selectionChange)="filterBySkill()">
          <mat-option *ngFor="let skill of skillsOptions" [value]="skill.value">{{ skill.label }}</mat-option>
        </mat-select>
        <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
      </mat-form-field>
    </div>
    <apx-chart
      [options]="skillsChartsOptions.options"
      [series]="skillsChartsOptions.series"
      [chart]="skillsChartsOptions.chart"
      [plotOptions]="skillsChartsOptions.plotOptions"
      [dataLabels]="skillsChartsOptions.dataLabels"
      [legend]="skillsChartsOptions.legend"
      [xaxis]="skillsChartsOptions.xaxis"
      [yaxis]="skillsChartsOptions.yaxis"
      [labels]="skillsChartsOptions.labels"
      [responsive]="skillsChartsOptions.responsive"
      [fill]="skillsChartsOptions.fill"
      [stroke]="skillsChartsOptions.stroke"
      [theme]="skillsChartsOptions.theme"></apx-chart>
  </div>

  <div class="stackedBarContainer">
    <div class="stackedBar">
      <div class="skillsfilterContainer">
        <p class="skillsLevelTitle">Cantidad de estudiantes por nivel habilidad.</p>
        <mat-form-field class="skillsFilter">
          <mat-select [(ngModel)]="selectedLevelsSkill" (ngModelChange)="filterCategoriesSkill()">
            <mat-option *ngFor="let skill of skillsOptions" [value]="skill.value">{{ skill.label }}</mat-option>
          </mat-select>
          <img src="../../../assets/school-dashboard/expand.png" alt="expand icon" class="expandIcon" />
        </mat-form-field>
      </div>
      <apx-chart
        [fill]="skillsLevelsChartOptions.fill"
        [options]="skillsLevelsChartOptions.options"
        [series]="skillsLevelsChartOptions.series"
        [chart]="skillsLevelsChartOptions.chart"
        [plotOptions]="skillsLevelsChartOptions.plotOptions"
        [dataLabels]="skillsLevelsChartOptions.dataLabels"
        [legend]="skillsLevelsChartOptions.legend"
        [xaxis]="skillsLevelsChartOptions.xaxis"
        [labels]="skillsLevelsChartOptions.labels"
        [responsive]="skillsLevelsChartOptions.responsive"
        [grid]="skillsLevelsChartOptions.grid"
        [stroke]="skillsLevelsChartOptions.stroke"
        [theme]="skillsLevelsChartOptions.theme"
        [yaxis]="skillsLevelsChartOptions.yaxis"></apx-chart>
    </div>
    <div class="stackedBar">
      <p class="skillsLevelTitle">Cantidad de estudiantes por nivel de inglés .</p>
      <apx-chart
        [fill]="languagesChart.fill"
        [options]="languagesChart.options"
        [series]="languagesChart.series"
        [chart]="languagesChart.chart"
        [plotOptions]="languagesChart.plotOptions"
        [dataLabels]="languagesChart.dataLabels"
        [legend]="languagesChart.legend"
        [xaxis]="languagesChart.xaxis"
        [labels]="languagesChart.labels"
        [responsive]="languagesChart.responsive"
        [grid]="languagesChart.grid"
        [stroke]="languagesChart.stroke"
        [theme]="languagesChart.theme"></apx-chart>
    </div>
  </div>
</div>
