<div class="recruitment-modal">
  <mat-icon class="close-button" (click)="onClose()">close</mat-icon>
  <p> {{'Recruitment-partner.select-student'|translate}}:  </p>
  <select class="select-student-input" [(ngModel)]="selectedStudent">
    <option *ngFor="let student of students" [value]="student._id">{{student.nombre}} {{student.apellido}}</option>
  </select>
  <br>
  <button
  *ngIf="!loading"
  id="execute-action-button"
  [disabled]="selectedStudent === ''"
  (click)="onExecuteAction()"> {{'Recruitment-partner.confirm-student'|translate}}</button>
  <mat-progress-spinner *ngIf="loading" class="apply-spinner" mode="indeterminate" diameter="35">
  </mat-progress-spinner>
</div>
