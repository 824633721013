<div class="card">
    <figure>
        <!-- <img [src]="webinar.foto" alt=""> -->
        <img [src]="webinar.foto? webinar.foto : './assets/profile/city.jpeg'" alt="webinar photo">
    </figure>

    <h3 class="title">{{ webinar.nombre }}</h3>
    <h6 class="university-name">{{ webinar.universidad.nombre}}</h6>

    <div class="container-dates-hour">
        <div class="date">Fecha: {{ webinar.fecha | date: 'dd/MM/yyyy'}}</div>
        <div class="hour">{{ webinar.hora | changeHours }} </div>
    </div>

    <div class="duration">
        {{ webinar.duracion || "" }}
    </div>

    <p class="description">
        {{ webinar.descripcion }}
    </p>

    <div class="btn-booking">
        <button (click)="onApply()">
            {{ "Webinar.apply" | translate }}
        </button>
    </div>

</div>