import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss'],
})
export class CreateAlertComponent implements OnInit {
  /** CONSTANTS */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api for getting  the students by recruitment */
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** API path to get application by student */
  readonly APPLICATION_API = 'api/aplicacion/estudiante/';
  /** API path for ticket operations */
  readonly TICKET_API = 'api/ticket/';

  /** VARIABLES */
  /** Variable that holds the list of applications of the user */
  applications = [];
  /** Holds the id of the selected application in the select field */
  selectedApplicationId = '';
  /** Holds the reason of the creation of the ticket */
  ticketReason = '';
  /** Holds the priority of the ticket */
  priority = '';
  /** Holds the title of the ticket */
  title = '';
  /** selected student id */
  selectedStudent;
  /** Holds the array of students */
  students = [];
  /** Holds if is recruitment */
  isRecruitment;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private dialogRef: MatDialogRef<CreateAlertComponent>
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.tipo === 'Recruitment') {
      this.isRecruitment = true;
      this.getRecruitmentStudents({ id: user._id }).subscribe((response) => {
        this.students = response as any[];
      });
    } else {
      this.isRecruitment = false;
      const id = user._id;
      this.getApplications({ id });
    }
  }

  /**
   * Retrieves recruitment partner students
   * @param object with recruitment partner id
   * @returns Observable with response from backend
   */
  getRecruitmentStudents({ id }): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + id });
  }

  /**
   * Retrieve the applications of the user from the backend
   * @param id id of the student
   */
  getApplications({ id }: { id: string }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.APPLICATION_API + id }).subscribe(
      (response) => {
        this.applications = response;
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was an error, please try again later or contact support',
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Closes self
   */
  public selfClose(): void {
    this.dialogRef.close();
  }

  /**
   * Handles on change student and refreshes applications
   * @param id id of the student
   */
  onChangeStudent({ id }): void {
    this.getApplications({ id });
  }

  /**
   * Handles a click on the create ticket button
   */
  onCreateTicket(): void {
    this.ngxSpinnerService.show();
    const data = {
      aplicacion: this.selectedApplicationId,
      prioridad: this.priority,
      titulo: this.title,
      razon: this.ticketReason,
    };
    this.apiService.post({ api: this.TICKET_API, data }).subscribe(
      (response) => {
        if (response.success === true) {
          this.alertService.showSuccess({
            msg: "Ticket created, we'll send a response as soon as possible",
          });
          this.selfClose();
        } else {
          this.alertService.showError({
            msg: 'There was an error, plese try again later or contact support',
          });
        }
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was an error, plese try again later or contact support',
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
}
