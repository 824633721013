import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { NavigationService } from '../../../services/navigation.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-educationalGoal',
  templateUrl: './educationalGoal.component.html',
  styleUrls: ['./educationalGoal.component.scss'],
})
export class EducationalGoalComponent implements OnInit, OnDestroy {
  @Output() nextButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedTarget: EventEmitter<string> = new EventEmitter<string>();

  optionSelected: boolean = false;
  public preguntas_seccion01: any;
  selectedOption: string = '';

  constructor(private apiService: ApiService, private navigationService: NavigationService) {}

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.navigationService.setIsVisibleSidebar(false);
  }

  emitNextButtonClick() {
    if (this.optionSelected) {
      this.nextButtonClick.emit();
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes seleccionar una opción antes de continuar.',
        icon: 'error',
      });
    }
  }

  emitPreviousButtonClick() {
    this.previousButtonClick.emit();
  }


  selectCard(seleccion: string) {
    if (this.preguntas_seccion01 && this.preguntas_seccion01.preguntas && this.preguntas_seccion01.preguntas[0]) {
      for (let item of this.preguntas_seccion01.preguntas[0].opciones) {
        item.seleccionada = item.titulo === seleccion;
      }
    }
    this.selectedOption = seleccion;
    this.selectedTarget.emit(seleccion);
    this.optionSelected = true;
  }
}
