<main class="manifest-principal">
  <div class="main-max-width">
    <div class="manifest-container">
      <h1 class="manifest-title">Nuestra creencia</h1>
      <p class="manifest-p">
        En U360 creemos que hoy en día todos somos capaces de ser quienes queramos y dónde queramos serlo. Existe un lugar en el mundo ideal para cada persona, que ya no es necesariamente donde nacemos o nos imponen, pues estamos frente a un mundo de apertura global. Qué mejor forma de encontrar ese lugar, que por medio de la educación internacional?
      </p>
      <p class="manifest-p">
        Por eso nace U360, el buscador de programas internacionales que reúne y facilita en un solo lugar las herramientas información y caminos necesarios para conectar a futuros estudiantes con su lugar en el mundo.
      </p>
      <p class="manifest-p">
        Usamos la tecnología para abrir el espectro de oportunidades y maximizar las posibilidades de acceso a educación internacional, con búsquedas optimizadas, decisiones informadas y un acompañamiento experto en la industria, que cubre los aspectos de la individualidad de cada estudiante, institución y país.
      </p>
      <p class="manifest-p">
        En U360, cada persona encuentra su lugar, para estar donde quiere y ser quien quieren.
      </p>
    </div>
  </div>
</main>
