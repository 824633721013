import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { CookiesService } from '../../services/cookies.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-info-pse',
  templateUrl: './payment-info-pse.component.html',
  styleUrls: ['./payment-info-pse.component.scss'],
})
export class PaymentInfoPseComponent implements OnInit {
  @Input() refCode: string;
  @Input() banksPse: any;
  @Input() subtotal: number;
  /** variable that holds the payment information form */
  paymentInformationForm: FormGroup;
  /**document type */
  document_type: any = [
    { iso: 'CC', nombre: 'Cédula de ciudadanía.' },
    { iso: 'CE', nombre: 'Cédula de extranjería.' },
    { iso: 'NIT', nombre: 'Número de Identificación Tributario.' },
    { iso: 'TI', nombre: 'Tarjeta de Identidad.' },
    { iso: 'PP', nombre: 'Pasaporte.' },
    { iso: 'IDC', nombre: 'Identificador único.' },
    { iso: 'RC', nombre: 'Registro civil de nacimiento.' },
    { iso: 'DE', nombre: 'Documento de identificación extranjero.' },
  ];
  /** currency type */
  currency_type: any = [
    { iso: 'ARS', descripcion: 'Peso argentino'},
    { iso: 'BRL', descripcion: 'Real brasileño'},
    { iso: 'CLP', descripcion: 'Peso chileno'},
    { iso: 'COP', descripcion: 'Peso colombiano'},
    { iso: 'MXN', descripcion: 'Peso mexicano'},
    { iso: 'PEN', descripcion: 'Nuevo Sol peruano'},
    { iso: 'USD', descripcion: 'Dólar americano'},
  ]
  /** client IP */
  clientIP: string;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private translate: TranslateService,
    private cookies: CookiesService) {
      this.getClientIP();
  }

  ngOnInit(): void {
    // let ident = "_gid="
    // let resultadoGalleta;
    // let galletas = document.cookie.split(';');
    // for(let i = 0; i < galletas.length; i++) {
    //   let c = galletas[i];
    //   while (c.charAt(0) == ' ') {
    //     c = c.substring(1);
    //   }
    //   if (c.indexOf(ident) == 0) {
    //     resultadoGalleta = c.substring(ident.length, c.length);
    //   }
    // }
    this.subtotal = Math.round(this.subtotal * 1e2) / 1e2;
    let txBase = Math.round((this.subtotal / 1.19) * 1e2) / 1e2;
    let txTax = Math.round((txBase * 0.19) * 1e2) / 1e2 - 0.01;
    this.paymentInformationForm = this.fb.group({
      informacionOrden: {
        referenciaPayU: this.refCode,
        clientIP: ''
      },
      informacionPago: {
        moneda: this.currency_type[6].iso, // Not created for other kinds of currencies yet
        montoTransaccion: this.subtotal,
        montoBase: txBase,
        montoIVA: txTax
      },
      informacionPersonal: this.fb.group({
        nombreCompleto: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telefono: ['', Validators.required],
        numeroDeIdentificacion: ['', Validators.required],
        banco: ['', Validators.required],
        tipoUsuario: ['', Validators.required],
        documentType: ['', Validators.required],
        document: ['', Validators.required],
      }),
    });
  }

  /**
   * Handles the click on the payment form subsmission
   */
  onSubmitClick(): void {
     
    this.createPse();
  }

  getClientIP(){
    this.cookies.getClientIPAddress().subscribe((res:any) => {
       
      this.clientIP = res.ip;
    })
  }

  /**Método para crear pago PSE  */
  createPse() {
    let info = this.paymentInformationForm.value;
    let dateNow = Date.now().toString();
    info.informacionOrden.clientIP = this.clientIP + dateNow;
    info.esPrueba = environment.production ? false : true;
     
    this.apiService.post({ api: 'api/pse-create', data: info }).subscribe(
      (res) => {
         
        let pse = res;
        // 
        if (res.success) {
          if(res.pse.state=="DECLINED"){
            alert(res.pse.paymentNetworkResponseErrorMessage);
          }else{
            window.open(pse.pse.extraParameters.BANK_URL, '_self');
          }
        } else {
           
          alert(this.translate.instant("Payment-info-pse.pse-not-available"));
        }
      },
      (err) => {
        alert(this.translate.instant("Payment-info-pse.pse-not-available"));
      }
    );
  }
}
