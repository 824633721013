import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthComponent } from '../auth/auth.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';
import { MdlJoinWebinarComponent } from '../view-webinars/mdl-join-webinar/mdl-join-webinar.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-view-webinars',
  templateUrl: './view-webinars.component.html',
  styleUrls: ['./view-webinars.component.scss']
})
export class ViewWebinarsComponent implements OnInit {
  // CONTANTES
  readonly API_WEBINAR = 'api/webinar';


  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;
  /** Variable that determines if the user is logged in */
  loggedIn = false;
  /**All webinars */
  public webinars: any = [];
  public filterWebinars: any = [];
  /**Input for loooking */
  public search: string;

  constructor(
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private authService: AuthService,
    private apiService: ApiService,
    private ngxSpinnerService: NgxSpinnerService,
  ) {
    this.getWebinasrs();
  }

  ngOnInit(): void {
    this.logInSubscription = this.authService.loggedIn.subscribe(
      (logInStatus) => {
        this.loggedIn = logInStatus;
      }
    );
  }

  /**
   * Check Sesion
   * View mld apply webinar
   */
  public onApplyWebinar({ webinar }) {
    if (this.loggedIn === false) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      this.dialog.open(MdlJoinWebinarComponent, { data: { webinar: webinar } });
    }
  }

  /**
   * Get all webinars 
   * @memberof ViewWebinarsComponent
   */
  public async getWebinasrs() {
    this.ngxSpinnerService.show();
    try {
      let responWebinars: any = await this.apiService.getAsPromise({ api: `${this.API_WEBINAR}/future` });
      if (responWebinars && responWebinars.length > 0) {
        this.webinars = responWebinars;
        this.filterWebinars = responWebinars;

      }
      this.ngxSpinnerService.hide();

    } catch (err) {
      this.ngxSpinnerService.hide();
      console.error(`Error al obtener los webinars`);
    }
  }

  public onSubmitSearch(event) {
    if (event.length > 1) {
      this.webinars = this.filterWebinars.filter((webi) => webi.nombre.toLowerCase().includes(event.toLowerCase()));
    } else {
      this.webinars = this.filterWebinars;
    }

  }


}
