import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCity',
  pure: false,
})
export class FilterCityPipe implements PipeTransform {

  /** Filters the cities that belong to the given country, if there is no country, returns all the cities
   * @param cities list of cities
   * @param country name of the country
   */
  transform(cities: any[], country: any[]): unknown {
    let filtered: any[];
    if (country.length < 1){
      filtered = cities;
    } else {
      filtered = cities.filter((city) => {
        if (country.map(c => c._id).indexOf(city.pais._id) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    return filtered;
  }

}
