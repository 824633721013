import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from '../../privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../../terms-conditions/terms-conditions.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { FormAmbassadorComponent } from './form-ambassador/form-ambassador.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private readonly API_FORM = 'api/formulario';

  // Declare height and width variables
  scrWidth:any;

  /* Child References */
  createForm: FormGroup = this.fb.group({
    correo: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
  });

  // Display popular
  public displayPopularTopics:boolean = false;
  public displayDestinations:boolean = false;
  public displayInterests:boolean = false;
  public displayAboutUs:boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService) {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  /**
   * Opens privacy modal
   */
  openPrivacyModal() {
    this.dialog.open(PrivacyPolicyComponent, {
      maxHeight: '500px'
    });
  }

  /**
   * Opens terms and conditions modal
   */
  openTermsModal() {
    this.dialog.open(TermsConditionsComponent, {
      maxHeight: '500px'
    });
  }

  public showDetails({ item }) {
    if (item == 'topic') {
      this.displayPopularTopics = !this.displayPopularTopics;
    } else if (item == 'destination'){
      this.displayDestinations = !this.displayDestinations;
    } else if (item == "interest"){
      this.displayInterests = !this.displayInterests;
    } else {
      this.displayAboutUs = !this.displayAboutUs;
    }
  }

  onSubmit({ event }): void {
    if (this.createForm.valid) {
      const form = {
        nombre: "ND",
        apellido: "ND",
        correo: this.createForm.controls.correo.value,
        anioInteres: "ND",
        edad: "ND",
        telefono: "ND",
        programa: "ND",
        pregunta: "ND",
        fuente: "Newsletter",
      };
      this.createFormByContact({ form });
    } else {
      this.alertService.showError({
        msg: `Llena el campo correctamente`,
      });
    }
  }

  navigateUniversitiesSection(){
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        state: "Universities_active"
      },
    })
  }

  /**
   * create a form
   * @param form
   */
   public async createFormByContact({ form }) {
    let resp = await this.apiService
      .post({ api: `${this.API_FORM}`, data: form })
      .toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Incripción exitosa`,
      });
    }
  }

  filterProgramByCountry({ page,country }: { page: number,country:string }){
    const countryParam = country
    if(country=='Paises bajos' || country=='spain'){
      this.navigationService.navigateTo({
        path:'program/search',
        params:{
          page,
          name: countryParam,
        }
      })
    }else{
      this.navigationService.navigateTo({
        path:'program/search',
        params:{
          page,
          countryFilter: countryParam,
        }
      })
    }
    
  }

  openModalAmbassador(){
    this.dialog.open(FormAmbassadorComponent) 
   }
}
