import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(public title: Title, public meta: Meta) { }

  /**
   * Método para configurar el SEO de la página que lo invoque
   * @param title El título a mostrar en los buscadores y en redes sociales. El método agrega ' | U360'.
   * @param description La descripción a mostrar.
   * @param keywords Lista de palabras clave para los buscadores. Opcional.
   * @param image Ruta de la imagen a mostrar. Puede ser real ('http://u360.herokuapp.com/.../imagen') o relativa ('.../imagen'). Opcional.
   * @param url URL a la que dirigiran las redes sociales. DEBE ser la URL relativa, el método agrega 'http://u360.herokuapp.com/'. Opcional.
   * @param main Booleano que indica si se configura el SEO para la página principal o para otras páginas. Opcional.
   */
  public setSEO(data: {title: string, description: string, keywords?: string, image?: string, url?: string, main?: boolean}): void {
    let concat = data.main ? '' :' | U360';
    //Se configura el titulo de la página
    this.title.setTitle(data.title + concat);
    this.meta.updateTag({ name: 'title', content: data.title + concat});
    this.meta.updateTag({ itemprop: 'name', content: data.title + concat});
    this.meta.updateTag({ property: 'og:title', content: data.title + concat});
    this.meta.updateTag({ property: 'twitter:title', content: data.title + concat});

    //Se configuran los parámetros básicos de los tags
    this.meta.updateTag({ property: 'og:type', content: 'website'});
    this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image'});

    //Se configura la descripción de la página en metatags, incluídos las descripciones para redes sociales
    this.meta.updateTag({ name: 'description', content: data.description});
    this.meta.updateTag({ itemprop: 'description', content: data.description});
    this.meta.updateTag({property: 'og:description', content: data.description});
    this.meta.updateTag({property: 'twitter:description', content: data.description});

    //Se configuran las keywords
    if(data.keywords) {
      this.meta.updateTag({ name: 'keywords', content: data.keywords});
    }

    //Se configura la imagen para redes sociales
    if(data.image) {
      this.meta.updateTag({property: 'twitter:image', content: data.image});
      this.meta.updateTag({property: 'og:image', content: data.image});
    }

    //Se configuran las urls para redes sociales
    if(data.url) {
      this.meta.updateTag({property: 'twitter:url', content: 'https://www.u360education.com/' + data.url});
      this.meta.updateTag({property: 'og:url', content: 'https://www.u360education.com/' + data.url});
    }
  }
}
