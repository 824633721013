<form [formGroup]="form" class="letter-edit">
  <div class="d-flex justify-content-between">
    <div class="display-mobile-none">
      <img class="h-30" routerLink="/cover-letter" src="../../../../assets/curriculum/back.png" alt="" />
    </div>
    <div class="w-95">
      <div class="d-flex justify-content-between w-100 mb-2 mb-lg-4">
        <label class="txt-style title blue">Carta de intención</label>
        <div class="d-flex justify-content-between margin-r-5 display-mobile-none" (click)="openPreview()">
          <img class="h-30 mx-1" src="../../../../assets/curriculum/eye.png" alt="" />
          <label class="txt-style txt-a-button blue align-self-center">Vista previa</label>
        </div>
      </div>
      <div>
        <label class="txt-style txt black">
          Sigue las instrucciones paso por paso para construir tu carta de intención ideal.
        </label>
        <div class="d-flex">
          <label class="txt-style txt-info black">
            ¿No sabes que es una carta de intención o por donde empezar? Lee nuestros
            <span class="txt-style txt-a-button ourTips" (click)="openTips()">U360 Tips.</span>
          </label>
          <!-- <label class="txt-style txt-a-button blue" (click)="openTips(content)"> U360 Tips.</label> -->
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Destinatario</label>
    </div>
    <div class="div-list">
      <ul>
        <li class="txt-style txt-info black">Lugar y fecha</li>
        <li class="txt-style txt-info black">Nombre de la facultad</li>
        <li class="txt-style txt-info black">Nombre del departamento dentro de la facultad</li>
        <li class="txt-style txt-info black">Nombre de la universidad</li>
      </ul>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="destinatario"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Introducción</label>
    </div>
    <div class="div-list">
      <label class="txt-style txt-info black">Para esta sección, responde las siguientes preguntas:</label>
      <ul>
        <li class="txt-style txt-info black">
          ¿Por qué eres diferente al resto de personas (tu esfuerzo, tu historia, tu gentilidad, etc) y cómo conecta
          esto con tu objetivo académico y profesional?
        </li>
        <li class="txt-style txt-info black">¿Qué te hace particular?, ¿Por qué y cómo has trabajado para lograrlo?</li>
        <li class="txt-style txt-info black">¿Cómo pretendes impactar el mundo?</li>
      </ul>
      <label class="txt-style txt-info black">Demuestra interés en lo que te ofrece la universidad.</label>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="introduccion"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Antecedentes académicos, laborales y extracurriculares</label>
    </div>
    <div class="div-list">
      <label class="txt-style txt-info black">Para esta sección, responde las siguientes preguntas:</label>
      <ul>
        <li class="txt-style txt-info black">¿Cómo desde la parte académica has venido proyectando tu plan?</li>
        <li class="txt-style txt-info black">
          ¿Cómo has llevado tus conocimientos y competencias a lo extracurricular?
        </li>
      </ul>
      <label class="txt-style txt-info black">
        Debes contar de tu experiencia profesional si la tienes, portafolio, etc. En general que has hecho para
        proyectarte y mejorar tu perfil. Explica la experiencia laboral y extracurricular.
      </label>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="antecendente"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Justificación de selección de programa</label>
    </div>
    <div class="div-list">
      <label class="txt-style txt-info black">Para esta sección, responde las siguientes preguntas:</label>
      <ul>
        <li class="txt-style txt-info black">¿Por qué escoges el curriculum en particular?</li>
        <li class="txt-style txt-info black">¿Cómo el curriculum se conecta con tu proyecto?</li>
        <li class="txt-style txt-info black">¿Cómo se conecta con tus habilidades probadas?</li>
      </ul>
      <label class="txt-style txt-info black">
        Detalla algunos puntos clave que te hacen escoger la universidad (Habla de metodología, reputación, enfoques
        particulares)
      </label>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="justificacion"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Metas académicas y profesionales</label>
    </div>
    <div class="div-list">
      <label class="txt-style txt-info black">
        Demostrando conocimiento en tu campo, detalla que pretendes hacer una vez te gradúes y como generarás impacto
        posgraduación. No hables de temas que induzcan un objetivo migratorio.
      </label>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="meta"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Parrafo de cierre (conclusiones)</label>
    </div>
    <div class="div-list">
      <label class="txt-style txt-info black">
        Escribe un resumen de lo dicho anteriormente, muéstrate muy atento y convencido para abrir tus puertas
        profesionales y superar los obstáculos que se presenten. Muestra porqué la universidad debe confiar en ti para
        elegirte con argumentos. Nunca hables de temas financieros en la carta.
      </label>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="conclusion"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>

  <div class="mt-3 mt-lg-5">
    <div class="border-bottom-div">
      <label class="txt-style subtitle blue">Datos personales</label>
    </div>
    <div class="div-list">
      <ul>
        <li class="txt-style txt-info black">Nombre del candidato</li>
        <li class="txt-style txt-info black">Teléfono</li>
        <li class="txt-style txt-info black">Correo electónico</li>
      </ul>
    </div>

    <div class="div-angular-editor">
      <angular-editor
        placeholder="Escribe aquí (máx. 125 palabras)"
        formControlName="datoPersonal"
        [config]="editorConfig"></angular-editor>
    </div>
  </div>
</form>

<div class="d-flex justify-content-center mt-xl-4 mb-xl-4 pb-4">
  <div class="mx-2">
    <button class="button-save w-100 h-30" (click)="onSubmit()">Guardar cambios</button>
  </div>
  <div class="mx-2">
    <button class="button-cancel w-100 h-30" (click)="backToCurriculum()">Cancelar</button>
  </div>
</div>

<!-- U360 TIPS -->
<!-- <ng-template #content let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="col-xl-12">
            <h2 class="title-tip">¿Qué es una carta de intención?</h2>
        </div>

        <div class="col-xl-12">
            <p>
                La carta de intención o conocida también por su siglas en inglés SOP (Statement of Purpose) ,
                es quizá uno de los requisitos más importantes para ser aceptado en una institución universitaria
                internacional o nacional; a través de este documento tienes la oportunidad de presentarte y convencer
                a los Admision Officers de que eres la persona indicada y eres elegible para un cupo en su universidad.
            </p>
        </div>

        <div class="col-xl-12 container-sub-title">
            <h3 class="sub-title-tip">¡Para tener en cuenta!</h3>
        </div>
        <div class="col-xl-12">
            <p>Los siguientes son elementos que definen un excelente SOP (Statement of Purpose)</p>
        </div>
        <div class="row container-item-sop">
            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/documento-estrella.png" alt="">
                <label>Experiencia personal</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/hombre-estrella.png" alt="">
                <label>Experiencia profesional</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/mano-moneda.png" alt="">
                <label>Soporte financiero</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/universidad.png" alt="">
                <label>Justifica tu elección de universidad, país y programa</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/gorro-universidad.png" alt="">
                <label>Intereses y planes  académicos/profesionales</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/Group.png" alt="">
                <label>Descripción de tus planes futuros</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/certificado.png" alt="">
                <label>Logros notables académicos y extracurriculares</label>
            </div>

            <div class="col-xl-6 box-item-sop">
                <img class="img-tip" src="/assets/u360tips/mano-corazon.png" alt="">
                <label>Servicio comunitario y trabajo caritativo</label>
            </div>

        </div>

	</div>
</ng-template> -->
