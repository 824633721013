<div class="blog-detail">
    <div class="blog-header">
        <img (click)="onBackClick()" class="back-button" role="button" src="assets/utils/back.png" alt="go back">
        <h3 class="detail-title">Volver</h3>
    </div>
    <div class="blog-content">
        <div class="all-blog-content">
            <iframe *ngIf="blogData.video !== ''" [src]="blogData.video | safe" class="blog-video"></iframe>
            <img class="blog-banner" [src]="blogData.image" *ngIf="blogData.image !== ''" alt="blog image">
            <div class="blog-title"> {{blogData.title}} </div>
            <div class="blog-author"> {{blogData.author}}</div>
            <div class="blog-sub-header">
                <div class="blog-tags">
                    <div class="tag" *ngFor="let tag of blogData.tags">
                        {{tag | hashtag}}
                    </div>
                </div>
            </div>
            <div class="blog-text" [innerHTML]="blogData.content"></div>
            <div class="blog-ad">
                <span class="ad-text">
                    Publicado el {{blogData.date | date}}
                </span>
            </div>
            <div class="blog-ad">
                <div class="ad-text">
                    {{ "Blog-detail.i-want-to-find" | translate }}
                </div>
                <button id="discover-button" (click)="onDiscoverClick()"> {{ "Blog-detail.discover" | translate }} </button>
            </div>
        </div>
    </div>
    <div class="line-blogs">
        <hr>
    </div>
    <div class="blog-content">
        <div class="all-blog-content">
            <div class="related-container">
                <div class="related-title">
                    {{ "Blog-detail.related-articles" | translate }}
                </div>
                <div class="blogs-container">
                    <app-blog-card [blog]="blog" *ngFor="let blog of relatedBlogs"></app-blog-card>
                </div>
            </div>
        </div>
    </div>
</div>
<app-test-section></app-test-section>
