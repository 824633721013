import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-not-verified',
  templateUrl: './not-verified.component.html',
  styleUrls: ['./not-verified.component.scss']
})
export class NotVerifiedComponent implements OnInit {

  constructor(private navigationService: NavigationService,
              private dialogRef: MatDialogRef<NotVerifiedComponent>) { }

  ngOnInit(): void {
  }

  /**
   * Handles the click on the ok button
   */
   onOkClick(): void {
    this.navigationService.navigateTo({path: 'home'});
    this.dialogRef.close();
  }

}
