import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CityComponent } from '../../city/city.component';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-cultural-match-card',
  templateUrl: './cultural-match-card.component.html',
  styleUrls: ['./cultural-match-card.component.scss'],
})
export class CulturalMatchCardComponent implements OnInit {
  /** CONSTANTS */

  /** Variable that holds the index of the card, is used to display the correct medal */
  @Input() index: number;

  /** Variable that holds the data of the city that is passed in by the paren component */
  @Input() city: any;

  /** Variable that holds the data that is displayed in the card */
  matchData = {
    countryImage: '',
    countryName: '',
    cityName: '',
    cityId: '',
  };

  constructor(
    private navigationService: NavigationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.matchData.cityName = this.city.nombre;
    this.matchData.countryName = this.city.pais.nombre;
    this.matchData.cityId = this.city._id;
    this.matchData.countryImage = this.city.pais.icono;
  }

  /** redirects the user to the search view of the city in the match */
  onViewProgramsClick(): void {
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        city: this.matchData.cityId,
      },
    });
  }

  /** Opens city modal information */
  showCity() {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.matchData.cityId,
      },
    });
  }
}
