import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-terms-conditions',
  templateUrl: './application-terms-conditions.component.html',
  styleUrls: ['./application-terms-conditions.component.scss']
})
export class ApplicationTermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
