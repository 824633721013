import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/public/services/alert.service';
import { S3Service } from 'src/app/public/services/s3.service';

@Component({
  selector: 'app-be-accepted',
  templateUrl: './be-accepted.component.html',
  styleUrls: ['./be-accepted.component.scss']
})
export class BeAcceptedComponent implements OnInit, OnChanges {


  /** Component childs */
  @ViewChild('substep0') substep0: TemplateRef<any>;
  @ViewChild('substep1') substep1: TemplateRef<any>;
  @ViewChild('substep2') substep2: TemplateRef<any>;

  @Output() updateUploadedDocument = new EventEmitter<any>();
  @Output() updateLOARead = new EventEmitter<any>();

  @Input() stepData: any;

  /** Variable that holds the list of substeps with their respective status */
  substeps = [{
    name: `1. ${this.translate.instant("Be-accepted.receive-offer-title")}`,
    status: 'not-started'
  }, {
    name: `2. ${this.translate.instant("Be-accepted.receive-loa-title")}`,
    status: 'not-started',
  }, {
    name: `3. ${this.translate.instant("Be-accepted.pay-university-quota-title")}`,
    status: 'not-started',
    template: 'substep2'
  }];

  /** Variable that holds the data of the steps */
  beAccepted = {
    recepcionOferta: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      offerLetter: '',
    },
    recepcionLOA: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      aceptacionTerminosLOA: false,
      LOA: '',
    },
    pagoCupo: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      comprobantePago: '',
    },
  };

  /** Variable that holds the application id of the current application */
  applicationId: string;

  /** Variable that handles which tab is currently selectec */
  selected = 0;
  constructor( public translate: TranslateService,
               private ngxSpinnerService: NgxSpinnerService,
               private alertService: AlertService,
               private activatedRoute: ActivatedRoute,
               private s3Service: S3Service,

  ) { }

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    if (this.stepData !== undefined) {

      // Setup the first substep
      this.beAccepted.recepcionOferta.completado = this.stepData.recepcionOferta.completado;
      if (this.beAccepted.recepcionOferta.completado) {
        this.substeps[0].status = 'completed';
      } else if(this.stepData.recepcionOferta.offerLetter && !this.stepData.recepcionOferta.completado) {
        this.substeps[0].status = 'progress';
      }
      else {
        this.substeps[0].status = 'not-started';
      }
      this.beAccepted.recepcionOferta.offerLetter = this.stepData.recepcionOferta.offerLetter;

      // Setup the second substep
      this.beAccepted.recepcionLOA.completado = this.stepData.recepcionLOA.completado;
      if (this.beAccepted.recepcionLOA.completado) {
        this.substeps[1].status = 'completed';
      } else if((this.stepData.recepcionLOA.aceptacionTerminosLOA || this.stepData.recepcionLOA.LOA)  && !this.stepData.recepcionLOA.completado) {
        this.substeps[1].status = 'progress';
      }
      else {
        this.substeps[1].status = 'not-started';
      }
      this.beAccepted.recepcionLOA.LOA = this.stepData.recepcionLOA.LOA;
      if (this.beAccepted.recepcionLOA.aceptacionTerminosLOA !== undefined) {
        this.beAccepted.recepcionLOA.aceptacionTerminosLOA = this.stepData.recepcionLOA.aceptacionTerminosLOA;
      }


      // Setup the third substep
      this.beAccepted.pagoCupo.completado = this.stepData.pagoCupo.completado;
      if (this.beAccepted.pagoCupo.completado) {
        this.substeps[2].status = 'completed';
      } else if (this.stepData.pagoCupo.comprobantePago && !this.stepData.pagoCupo.completado) {
        this.substeps[2].status = 'progress';
      }
      else {
        this.substeps[2].status = 'not-started';
      }
      this.beAccepted.pagoCupo.comprobantePago = this.stepData.pagoCupo.comprobantePago;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  /**
   * Changes the currently selected tab
   * @param index index of the new selected tab
   */
  changeSelected({ index }: { index: number }): void {
    this.selected = index;
  }

  getTemplateRef({ index }: { index: number }): TemplateRef<any> {
    switch (index) {
      case 0:
        return this.substep0;
      case 1:
        return this.substep1;
      case 2:
        return this.substep2;
    }
  }

    /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event, nombreDoc }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant("Academic-history.you-must-upload-1-file"));
    } else {
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const key = `application/${this.applicationId}/accepted/${nombreDoc}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinnerService.hide();
          this.alertService.showError({ msg: 'There was an error uploading the document, please try again later or contact support' });
        } else {
          // Assign the value to the corresponding component
          switch (nombreDoc) {
            case 'offer-letter':
              this.updateUploadedDocument.emit({ docName: 'offer-letter', url: data.Location });
              break;
            case 'LOA':
              this.updateUploadedDocument.emit({ docName: 'LOA', url: data.Location });
              break;
            case 'payment-proof':
              this.updateUploadedDocument.emit({ docName: 'accepted-payment-proof', url: data.Location });
              break;
          }
          this.ngxSpinnerService.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

  /**
   * Emits an event to mark the LOA as read
   */
  onLOARead(): void {
    this.updateLOARead.emit();
  }

}
