<div class="visa-substeps">
  <div class="substep-container" *ngFor="let step of substeps; index as i"
    [ngStyle]="{'opacity': selected !== i?'0.3':'1'}">
    <app-substep-card (click)="onChangeTab({index: i})" [stepName]="step.name" [status]="step.status">
    </app-substep-card>
    <div class="substep-data" [ngSwitch]="selected" [ngClass]="selected === i?'substep-data-active':''">
      <div *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="documentList"></ng-container>
      </div>
      <div *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="fundsDemonstration"></ng-container>
      </div>
      <div *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="coverLetter"></ng-container>
      </div>
      <div *ngSwitchCase="3">
        <ng-container *ngTemplateOutlet="documentReview"></ng-container>
      </div>
      <div *ngSwitchCase="4">
        <ng-container *ngTemplateOutlet="visaForm"></ng-container>
      </div>
      <div *ngSwitchCase="5">
        <ng-container *ngTemplateOutlet="visaInterview"></ng-container>
      </div>
      <div *ngSwitchCase="6">
        <ng-container *ngTemplateOutlet="biometricsStep"></ng-container>
      </div>
      <div *ngSwitchCase="7">
        <ng-container *ngTemplateOutlet="visaReception"></ng-container>
      </div>
    </div>
  </div>
</div>
<div [ngSwitch]="selected" class="visa-content">
  <div *ngSwitchCase="0">
    <ng-container *ngTemplateOutlet="documentList"></ng-container>
  </div>
  <div *ngSwitchCase="1">
    <ng-container *ngTemplateOutlet="fundsDemonstration"></ng-container>
  </div>
  <div *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="coverLetter"></ng-container>
  </div>
  <div *ngSwitchCase="3">
    <ng-container *ngTemplateOutlet="documentReview"></ng-container>
  </div>
  <div *ngSwitchCase="4">
    <ng-container *ngTemplateOutlet="visaForm"></ng-container>
  </div>
  <div *ngSwitchCase="5">
    <ng-container *ngTemplateOutlet="visaInterview"></ng-container>
  </div>
  <div *ngSwitchCase="6">
    <ng-container *ngTemplateOutlet="biometricsStep"></ng-container>
  </div>
  <div *ngSwitchCase="7">
    <ng-container *ngTemplateOutlet="visaReception"></ng-container>
  </div>
</div>

<ng-template #documentList>
  <div *ngIf="substeps[0].status !== 'completed'" class="document-list">
    <p> {{ 'Get-your-visa.you-need-to-present' | translate }}:</p>
    <ol>
      <li>{{ 'Get-your-visa.copy-of-id' | translate }}</li>
      <li>{{ 'Get-your-visa.study-grades' | translate }}</li>
      <li>{{ 'Get-your-visa.certificate-of-stay' | translate }}</li>
      <li>{{ 'Get-your-visa.acceptance' | translate }}</li>
    </ol>
    <p>{{'Get-your-visa.if-underage' | translate}}: </p>
    <ol>
      <li>{{'Get-your-visa.certificate-of-departure' | translate}}</li>
      <li>{{'Get-your-visa.authenticated-custody' | translate}}</li>
    </ol>
  </div>
  <p class="done-text" *ngIf="substeps[0].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #fundsDemonstration>
  <div *ngIf="substeps[1].status !== 'completed'">
    <p> {{ 'Get-your-visa.funds-documents-demostration' | translate }}</p>
    <ol>
      <li>{{'Get-your-visa.economic-commitment' | translate}}</li>
      <li>{{'Get-your-visa.bank-statements' | translate}}</li>
      <li>{{'Get-your-visa.bank-balance-certificate' | translate}}</li>
      <li>{{'Get-your-visa.working-letter' | translate}}</li>
      <li>{{'Get-your-visa.payroll-slips' | translate}}</li>
      <li>{{'Get-your-visa.income-statements' | translate}}</li>
      <li>{{'Get-your-visa.freedom-certificate' | translate}}</li>
      <li>{{'Get-your-visa.accomodation-certificate' | translate}}</li>
    </ol>
    <P> {{'Get-your-visa.if-independent' | translate}}:</P>
    <ol>
      <li>{{'Get-your-visa.rut-and' | translate}}</li>
      <li>{{'Get-your-visa.income-certificate' | translate}}</li>
      <li>{{'Get-your-visa.accountant-information'}}</li>
    </ol>
  </div>
  <p class="done-text" *ngIf="substeps[1].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #coverLetter>
  <p *ngIf="substeps[2].status !== 'completed'" > {{ 'Get-your-visa.cover-letter-description' | translate }}</p>
  <p class="done-text" *ngIf="substeps[2].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #documentReview>
  <p *ngIf="substeps[3].status !== 'completed'" > {{ 'Get-your-visa.reviewing-documents' | translate }}</p>
  <p class="done-text" *ngIf="substeps[3].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #visaForm>
  <div *ngIf="substeps[4].status !== 'completed'">
    <p >{{ 'Get-your-visa.you-need-to-fill' | translate }}</p>
    <button (click)="onGoToForm()" class="form-button">
      {{ 'Get-your-visa.fill-the-form' | translate }}
    </button>
  </div>
  <p class="done-text" *ngIf="substeps[4].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #visaInterview>
  <p *ngIf="substeps[5].status !== 'completed'">{{ 'Get-your-visa.your-visa-interview' | translate }}</p>
  <p class="done-text" *ngIf="substeps[5].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #biometricsStep>
  <p *ngIf="substeps[6].status !== 'completed'">{{ 'Get-your-visa.biometric-taking' | translate }}</p>
  <p class="done-text" *ngIf="substeps[6].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
<ng-template #visaReception>
  <p *ngIf="substeps[7].status !== 'completed'">{{ 'Get-your-visa.receive-date' | translate }}</p>
  <p class="done-text" *ngIf="substeps[7].status === 'completed'">
    {{'Get-your-visa.done' | translate}}
  </p>
</ng-template>
