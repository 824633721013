<div class="row test-container d-flex centerItems">
  <div class="col-xl-4 mx-0 px-0 d-flex align-items-center display-mobile-none d-lg-none d-xl-flex centerItems">
    <img class="girl-test-size" src="/assets/pre-test/girl-test.png" alt="" />
  </div>

  <div class="row col-xl-7 pl-4 centerItems">
    <div class="row col-xl-12 centerItems">
      <h4 class="title-test" style="font-weight: bold">¿No estas seguro? ¡Vamos a la fija!</h4>
    </div>

    <div class="row col-xl-12 centerItems">
      <p class="description-test" style="font-weight: 600">
        ¡Adiós al miedo! ¡A un paso de lograr tus objetivos!
        <br class="display-mobile-none" />
        Conoce las mejores opciones según tu perfil.
        <br class="display-mobile-none" />
        Usa las herramientas para guiar tu proyecto.
      </p>
    </div>

    <div class="row col-xl-12 centerItems">
      <div
        class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-2 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
        <img class="img-todo-test" src="/assets/pre-test/world.png" alt="ia" />
        <div class="d-block px-2 pt-3">
          <h4
            class="subtitle"
            [ngClass]="{
              'red-subtitle': testIntStatus?.alt == 'start' || testIntStatus?.alt == 'pending',
              'green-subtitle': testIntStatus?.alt == 'back' || testIntStatus?.alt == 'done'
            }">
            IA
            <br class="display-mobile-none" />
            Skills
          </h4>
          <div
            *ngIf="testIntStatus"
            class="item-status-mobile display-mobile"
            [ngSwitch]="testIntStatus.alt"
            (click)="goToTestInt()">
            <p *ngSwitchCase="'start'">Comenzar</p>
            <p *ngSwitchCase="'back'">Volver a realizar</p>
            <p *ngSwitchCase="'pending'">Comenzar</p>
            <p *ngSwitchCase="'done'">Volver a realizar</p>
          </div>
          <div class="img-status-container display-mobile-none">
            <img
              *ngIf="testIntStatus"
              class="img-status-test"
              [src]="testIntStatus.src"
              [alt]="testIntStatus.alt"
              [hidden]="testIntStatus.alt != 'done' && testIntStatus.alt != 'pending'"
              (click)="onImage('inter', testIntStatus.alt)" />

            <img
              *ngIf="testIntStatus"
              class="img-status-test"
              src="/assets/pre-test/status-start.png"
              alt="start"
              [hidden]="testIntStatus.alt != 'start'"
              (click)="goToTestInt()" />

            <img
              *ngIf="testIntStatus"
              class="img-status-test"
              src="/assets/pre-test/status-back.png"
              alt="back"
              [hidden]="testIntStatus.alt != 'back'"
              [ngClass]="{ 'back-fade': testIntStatus.alt == 'back' }"
              (click)="goToTestInt()" />
          </div>
        </div>
        <img
          class="img-action display-mobile"
          src="/assets/pre-test/rigth-arrow.png"
          alt="arrow"
          (click)="goToTestInt()" />
      </div>

      <div
        class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-3 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
        <img class="img-todo-test" src="/assets/pre-test/test-bw.png" alt="ingles" />
        <div class="d-block px-2 pt-3">
          <h4
            class="subtitle"
            [ngClass]="{
              'red-subtitle': testEnglish?.alt == 'start' || testEnglish?.alt == 'pending',
              'green-subtitle': testEnglish?.alt == 'back' || testEnglish?.alt == 'done'
            }">
            English
            <br class="display-mobile-none" />
            Test
          </h4>
          <div
            *ngIf="testEnglish"
            class="item-status-mobile display-mobile"
            [ngSwitch]="testEnglish.alt"
            (click)="goToEnglishTest()">
            <p *ngSwitchCase="'start'">Comenzar</p>
            <p *ngSwitchCase="'back'">Volver a realizar</p>
            <p *ngSwitchCase="'pending'">Comenzar</p>
            <p *ngSwitchCase="'done'">Volver a realizar</p>
          </div>
          <div class="img-status-container display-mobile-none">
            <img
              *ngIf="testEnglish"
              class="img-status-test"
              [src]="testEnglish.src"
              [alt]="testEnglish.alt"
              [hidden]="testEnglish.alt != 'done' && testEnglish.alt != 'pending'"
              (click)="onImage('englishTest', testEnglish.alt)" />

            <img
              *ngIf="testEnglish"
              class="img-status-test"
              src="/assets/pre-test/status-start.png"
              alt="start"
              [hidden]="testEnglish.alt != 'start'"
              (click)="goToEnglishTest()" />

            <img
              *ngIf="testEnglish"
              class="img-status-test"
              src="/assets/pre-test/status-back.png"
              alt="back"
              [hidden]="testEnglish.alt != 'back'"
              [ngClass]="{ 'back-fade': testEnglish.alt == 'back' }"
              (click)="goToEnglishTest()" />
          </div>
        </div>
        <img
          class="img-action display-mobile"
          src="/assets/pre-test/rigth-arrow.png"
          alt="arrow"
          (click)="goToEnglishTest()" />
      </div>
    </div>
  </div>
</div>
