<div class="language-test-card">
  <div class="language-test-card-header">
    <mat-icon (click)="onAdditionalInfoClick()" class="info-button">info</mat-icon>
  </div>
  <div class="language-test-info">
    <div>
      <img [src]="image" class="language-test-img" alt='language test'>
    </div>
    <div class="language-test-data">
      <span [matTooltip]="name + ' : ' + language" class="test-name">{{name | ellipsis:12}}</span> <span *ngIf="type !== 'vocacional'">: {{language}}</span> <br>
      <span class="language-test-cost">${{cost | number}} {{currency}}</span>
    </div>
  </div>
  <button (click)="onAddToCart()" class="add-to-cart">{{'Marketplace.add-to-cart'|translate}}</button>
</div>
