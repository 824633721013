import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ExperienceCardsComponent } from '../experiencesCards/experiencesCards.component';
import { ActivatedRoute } from '@angular/router';
import { ExperienceModalComponent } from '../experienceModalComponent/experienceModalComponent';
import { ApiService } from '../../public/services/api.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { skillScore } from '../skillsObject/skillScore.js';
interface SkillItem {
  skill: string;
  score: number;
}

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('out => in', animate('500ms ease-in-out')),
      transition('in => out', animate('500ms ease-in-out')),
    ]),
  ],
})
export class ExperienceComponent {
  private id: string;
  student: boolean = false;
  formList: any[] = [];
  isModalOpen = false;
  modalRef: NgbModalRef;
  editingMode: boolean = false;
  isLoading: boolean = true;

  user = JSON.parse(localStorage.getItem('user'));
  loggedUserId: any;
  experienceReviewsStatus = {
    approved: 'Aprobado',
    rejected: 'Rechazado',
    pending: 'Pendiente por ajustes',
  };

  mySkills: SkillItem[] = skillScore;

  @ViewChild(ExperienceCardsComponent, { static: false })
  private experienceCardsComponent: ExperienceCardsComponent;

  @ViewChild(ExperienceModalComponent, { static: false })
  private experienceModalComponent: ExperienceModalComponent;

  constructor(private modalService: NgbModal, private apiService: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.loadData();
    this.loggedUserId = this.user ? this.user._id : null
  }
  

  loadData() {
    const getData = {
      api: 'api/experiences',
    };
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
    
      this.apiService.get(getData).subscribe(
        (response) => {
          this.formList = response.filter((student) => {
            return student.user.includes(this.id);
          });
          this.isLoading = false;
          this.mySkills.forEach((skillObj) => {
            skillObj.score = 0;
          });

          this.formList.forEach((experience) => {
            if (experience.approve === this.experienceReviewsStatus.approved) {
              if (experience.hasOwnProperty('skillsScore') && typeof experience.skillsScore === 'object') {
                Object.keys(experience.skillsScore).forEach((skillName) => {
                  const skillObj = this.mySkills.find((item) => item.skill === skillName);
                  if (skillObj) {
                    skillObj.score += experience.skillsScore[skillName];
                  }
                });
              }
              if (
                experience.hasOwnProperty('secondarySkillsScore') &&
                typeof experience.secondarySkillsScore === 'object'
              ) {
                Object.keys(experience.secondarySkillsScore).forEach((skillName) => {
                  const skillObj = this.mySkills.find((item) => item.skill === skillName);
                  if (skillObj) {
                    skillObj.score += experience.secondarySkillsScore[skillName];
                  }
                });
              }
            }
          });
        },
        (error) => {
          console.error('Error al obtener la lista de experiencias:', error);
          this.isLoading = false;
        }
      );
    });
  }

  onExperienceCreated() {
    this.loadData();
  }

  activateEditingMode() {
    this.editingMode = true;
  }

  openModal() {
    const content = this.experienceModalComponent.getContent();
    this.modalRef = this.modalService.open(content);
    this.modalRef.result
      .then(
        (result) => {
          this.loadData();
        },
        (reason) => {
          this.loadData();
        }
      )
      .catch((err) => {
        this.loadData();
      });
    this.experienceModalComponent.setModalRef(this.modalRef);
    this.isModalOpen = true;
  }

  closeModal() {
    this.modalRef.dismiss();
    this.isModalOpen = false;
  }
}
