import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-legal-representative',
  templateUrl: './legal-representative.component.html',
  styleUrls: ['./legal-representative.component.scss'],
})
export class LegalRepresentativeComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the api for the legal guardian */
  readonly USER_API = 'api/acudiente/';
  readonly REMOVE_ACUDIENTE_BY_STUDENT = 'api/acudiente-remove-student/'
  /** Variable that holds the api for the legal guardian by student */
  readonly USER_BY_STUDENT_API = 'api/acudiente/estudiante/';

  /** Form array that will contain the list of legal representatives */
  legalRepresentatives: FormArray;
  /** variable that holds the current user id */
  id: string;
  /** Variable that shows if the information is being edited or created */
  editing = false;
  /** variable that holds the id of the data that is being deleted If the component is currently editing */
  dataId: string;
  /** Variable that signals if the component is currently loading */
  loading = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private navigationService: NavigationService,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private ngxSpinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.legalRepresentatives = this.fb.array([]);
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getLegalrepresentative();
    }
  }

  /**
   * Adds a new contact to the legal representatives form array
   */
  onAddContact(): void {
    const contact = this.fb.group({
      nombre: '',
      apellido: '',
      parentesco: '',
      telefono: '',
      correo: '',
    });
    this.legalRepresentatives.push(contact);
  }

  /**
   * Removes an entry from the legal representative array
   * @param param0
   */
  onRemoveEntry({index}: {index: number}) {
    this.legalRepresentatives.removeAt(index);
  }

  /**
   * Retrieves the information of the legal represantatives from the beckend
   */
  getLegalrepresentative(): void {
    this.ngxSpinner.show();
    this.apiService.get({ api: this.USER_BY_STUDENT_API + this.id }).subscribe(
      (response) => {
        // If the response is null a new contact should be created
        if (response === null) {
          this.editing = false;
          this.onAddContact();
        } else {
          // Setup the form
          this.editing = true;
          for (const acudiente of response.acudientes) {
            this.dataId = response._id;
            this.legalRepresentatives.push(
              this.fb.group({
                nombre: acudiente.nombre,
                apellido: acudiente.apellido,
                parentesco: acudiente.parentesco,
                telefono: acudiente.telefono,
                correo: acudiente.correo,
              })
            );
          }
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Legal-representative.load-error'),
        });
        this.ngxSpinner.hide();
      }
    , (complete?) => {
      this.ngxSpinner.hide();
    });
  }

  /** Handles the form submission */
  onSubmitData(): void {
    this.loading = true;
    const data = {
      estudiante: this.id,
      acudientes: this.legalRepresentatives.value,
    };
    if (this.editing) {
      if(data.acudientes.length==0){
        this.apiService.delete({api:this.REMOVE_ACUDIENTE_BY_STUDENT+this.id}).subscribe(
          (response)=>{
            if (response.success) {
              this.alertService.showSuccess({
                msg: 'Se han actualizado los datos correctamente',
              });
              this.navigationService.navigateTo({ path: 'profile'});
            }
          }
        )
      }else{

        let errorForm;
        for (const contact of data.acudientes) {
          if (contact.apellido.length < 1 || contact.nombre < 1 || contact.parentesco < 1 || contact.telefono < 1) {
            errorForm = true
          } else {
            errorForm = false
          }
        }

        if(errorForm==true){
          this.alertService.showError({
            msg: this.translate.instant('Porfavor llena todos los campos obligatorios'),
          });
          this.loading=false;
        }else{
          this.apiService.put({ api: this.USER_API + this.dataId, data }).subscribe(
            (response) => {
              if (response.success) {
                this.alertService.showSuccess({
                  msg: 'Se han actualizado los datos correctamente',
                });
                this.navigationService.navigateTo({ path: 'profile'});
              } else {
                this.alertService.showError({
                  msg: 'Hubo error actualizando los datos',
                });
              }
            },
            (err) => {
              this.alertService.showError({
                msg: 'Hubo error actualizando los datos',
              });
            }
          );
        }
        
      }
      
    } else {
      let errorForm;
      for (const contact of data.acudientes) {
        if(contact.apellido.length<1 || contact.nombre<1 || contact.parentesco<1 || contact.telefono<1){
          errorForm=true
        }else{
          errorForm=false
        }
      }

      if(errorForm==true){
        this.alertService.showError({
          msg: this.translate.instant('Porfavor llena todos los campos obligatorios'),
        });
        this.loading=false;
      }else{
        this.apiService.post({ api: this.USER_API, data }).subscribe(
          (response) => {
            if (response.success) {
              this.alertService.showSuccess({
                msg: this.translate.instant(
                  'Se han actualizado los datos correctamente'
                ),
              });
              this.navigationService.navigateTo({ path: 'profile' });
            } else {
              this.alertService.showError({
                msg: 'Hubo error actualizando los datos',
              });
            }
          },
          (err) => {
            this.alertService.showError({
              msg: 'Hubo error actualizando los datos',
            });
          }
        );
      }
    }
  }
}
