<div class="purchase-history">
  <h3 class="purchase-history-header"> {{ "Purchase-history.purchase-history" | translate }} </h3>
  <div class="purchase-history-content">
    <div *ngIf="shoppingCarts.length === 0" class="empty-disclaimer">
      {{'Purchase-history.you-have-not'|translate}}
    </div>
    <div *ngFor="let cart of shoppingCarts" class="shopping-cart">
      <h4> {{cart.createdAt | date}} </h4>
      <h5> {{ "Purchase-history.total" | translate }} ${{cart.valor}} USD</h5>
      <div class="shopping-cart-content">
        <app-medical-services-shopping-card [id]="service.servicio._id" [serviceName]="service.servicio.nombre"
          [numberOfDays]="service.dias" [plans]="service.servicio.planes" [currency]="service.servicio.moneda"
          [image]="service.servicio.imagen" [isShoppingCart]="false" *ngFor="let service of cart.serviciosMedicos">
        </app-medical-services-shopping-card>
        <app-translation-services-shopping-card [id]="service.servicio._id"
          [serviceName]="service.servicio.tipoDocumento" [numberOfPages]="service.paginas"
          [pricePerPage]="service.servicio.precioPagina" [image]="service.servicio.imagen" [currency]="'COP'"
          [isShoppingCart]="false" *ngFor="let service of cart.serviciosTraduccion">
        </app-translation-services-shopping-card>
        <app-visa-services-shopping-card [id]="service.servicio._id" [serviceName]="service.servicio.pais.nombre"
          [image]="service.servicio.imagen" [currency]="service.servicio.pais.moneda" [cost]="service.servicio.precio"
          *ngFor="let service of cart.serviciosVisa"></app-visa-services-shopping-card>
      </div>
    </div>
  </div>
</div>
