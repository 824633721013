<div class="row">
  <div class="col-12">
    <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
    <h4 class="titulo-wizard">Datos personales</h4>
    <p>Cuéntanos un poco sobre ti completando esta información</p>
    <hr class="hrLine" />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <img src="/assets/wizard-images/Test-4.png" class="imagen" alt="chica" />
  </div>
  <div class="col-md-5">
    <form>
      <!-- <div class="form-group">
        <label for="exampleFormControlSelect1">Género:</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          [(ngModel)]="gender"
          [ngModelOptions]="{standalone: true}">
          <option selected>Selecciona</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
          <option value="Otro">Otro</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Colegio o universidad:</label>
        <input
          type="text"
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Escribe el nombre de tu institución"
          [(ngModel)]="school"
          [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Tu edad:</label>
        <input
          type="number"
          min="0"
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Escribe tu edad"
          [(ngModel)]="age"
          [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="form-group">
        <label for="exampleFormControlSelect1">Nivel académico actual:</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          [(ngModel)]="academicLevel"
          [ngModelOptions]="{standalone: true}">
          <option selected>Selecciona tu nivel</option>
          <option value="Bachillerato 6to">Bachillerato 6to</option>
          <option value="Bachillerato 7mo">Bachillerato 7mo</option>
          <option value="Bachillerato 8vo">Bachillerato 8vo</option>
          <option value="Bachillerato 9no">Bachillerato 9no</option>
          <option value="Bachillerato 10mo">Bachillerato 10mo</option>
          <option value="Bachillerato 11vo">Bachillerato 11vo</option>
          <option value="Bachillerato 12vo">Bachillerato 12vo</option>
          <option value="Pregrado en Curso">Pregrado en Curso</option>
          <option value="Pregrado Terminado">Pregrado Terminado</option>
        </select>
      </div> -->
      <div class="form-group">
        <label for="exampleFormControlSelect1">¿Qué te gustaría estudiar?</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          [(ngModel)]="discipline"
          [ngModelOptions]="{standalone: true}">
          <option selected>Selecciona una disciplina</option>
          <option *ngFor="let item of disciplinesList" [value]="item.value">{{item.value}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="otherOpcional">¿Qué otra carrera quieres estudiar? (Opcional)</label>
        <input
          type="text"
          class="form-control"
          id="otherOpcional"
          rows="3"
          placeholder="Escribe que te gustaria estudiar"
          [(ngModel)]="optionalDiscipline"
          [ngModelOptions]="{standalone: true}" />
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn-continuar" (click)="emitNextButtonClick()">Continuar</button>
    <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
  </div>
</div>
