import { AlertService } from './../services/alert.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { CreateAlertComponent } from './create-alert/create-alert.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, OnDestroy {
  /** CONSTANTS */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api for getting  the students by recruitment */
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** Constant that holds the api path for retrieving alerts */
  readonly ALERTS_BY_STUDENT_API = 'api/alerta/usuario/';
  /** Constant that holds the pi path for retrieving tickets */
  readonly TICKETS_BY_STUDENT_API = 'api/ticket/estudiante/';

  /** VARIABLES */
  /** Variable that holds the currently selected tab
   * 0 -- Alerts are displayed
   * 1 -- Tickets and messages are displayed
   */
  selectedTab = 0;
  /** Variable that indicates if the only the favourite alerts should be displayed */
  favouritesFilter = false;
  /** Variable that holds the url subscription */
  urlSubscription: Subscription;
  /** Variable that holds the current alert list */
  alerts = [];
  /** Variable that holds the current tickets list */
  tickets = [];
  /** Variable that determines if there are unread messages */
  unreadMessages = false;
  /** Variable that determines if there are unread alerts */
  unreadAlerts = false;
  /** Selected student */
  student = '';

  /** Array with current recruiter students */
  recruitmentStudents;

  /** boolean if is recruitment */
  isRecruitment = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    @Inject(PLATFORM_ID) private platformId,
    private apiService: ApiService,
    private AlertService: AlertService,
    private ngxSpinner: NgxSpinnerService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.urlSubscription = this.activatedRoute.queryParamMap.subscribe(
        (params) => {
          const currentStudent = localStorage.getItem('alertsStudent');
          this.student = currentStudent;
          const user = JSON.parse(localStorage.getItem('user'));
          const filterParam = params.get('filter');
          const filter = filterParam === 'true';
          this.favouritesFilter = filter;
          if (user.tipo === this.TIPO_RECRUITMENT) {
            this.ngxSpinner.show();
            this.isRecruitment = true;
            this.getRecruitmentStudents({ id: user._id }).subscribe(
              (response) => {
                this.recruitmentStudents = response as any[];
                if (this.student) {
                  this.getAlertsRecruitment({ id: this.student, filter });
                } else {
                  this.getAlertsRecruitment({ id: '0', filter });
                }
                this.ngxSpinner.hide();
              }
            );
          } else {
            const id = user._id;
            this.isRecruitment = false;
            let alertsRequest = this.getAlerts({ id, filter });
            let ticketRequest = this.getTickets({ id, filter });
            this.ngxSpinner.show();
            forkJoin([alertsRequest, ticketRequest]).subscribe(
              (result) => {
                this.alerts = result[0];
                this.tickets = result[1];
                if (this.tickets.length > 0) {
                  this.checkForUnread();
                  this.checkForUnreadAlerts();
                }
              },
              (err) => {
                this.AlertService.showError({
                  msg: 'There was an error, please try again later',
                });
              },
              (complete?) => {
                this.ngxSpinner.hide();
              }
            );
          }
        }
      );
    }
  }

  /**
   * Handles on change students
   * @param object with id of student and read filter boolean
   */
  getAlertsRecruitment({ id, filter }): void {
    if (!filter) {
      filter = this.favouritesFilter;
    }
    localStorage.setItem('alertsStudent', id);
    if (id !== '0') {
      this.ngxSpinner.show();
      let alertsRequest = this.getAlerts({ id, filter });
      let ticketRequest = this.getTickets({ id, filter });
      forkJoin([alertsRequest, ticketRequest]).subscribe(
        (result) => {
          this.alerts = result[0];
          this.tickets = result[1];
          if (this.tickets.length > 0) {
            this.checkForUnread();
            this.checkForUnreadAlerts();
          }
        },
        (err) => {
          this.AlertService.showError({
            msg: 'There was an error, please try again later',
          });
        },
        (complete?) => {
          this.ngxSpinner.hide();
        }
      );
    } else {
      this.ngxSpinner.show();
      const alerts_ = [];
      const tickets_ = [];
      this.recruitmentStudents.forEach((element) => {
        alerts_.push(this.getAlerts({ id: element._id, filter }));
        tickets_.push(this.getTickets({ id: element._id, filter }));
      });

      forkJoin(alerts_).subscribe((result) => {
        (result as []).forEach((r: any[]) => {
          this.alerts.push(...r);
        });
        this.checkForUnreadAlerts();
        this.ngxSpinner.hide();
      });
      this.ngxSpinner.show();
      forkJoin(tickets_).subscribe((result) => {
        (result as []).forEach((r: any[]) => {
          this.tickets.push(...r);
        });
        this.checkForUnread();
        this.ngxSpinner.hide();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.urlSubscription !== null && this.urlSubscription !== undefined) {
      this.urlSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves recruitment partner students
   * @param object with recruitment partner id
   * @returns Observable with response from backend
   */
  getRecruitmentStudents({ id }): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + id });
  }

  /**
   * Method that retrieves the student alerts from the backend
   * @param id id of the student
   */
  getAlerts({ id, filter }: { id: string; filter: boolean }): Observable<any> {
    this.alerts = [];
    const data = {
      leido: filter,
    };
    return this.apiService.post({ api: this.ALERTS_BY_STUDENT_API + id, data });
  }

  /**
   * Methods that gets the tickets from the backend
   */
  getTickets({ id, filter }: { id: string; filter: boolean }): Observable<any> {
    this.tickets = [];
    const data = {
      leido: filter,
    };
    return this.apiService.post({
      api: this.TICKETS_BY_STUDENT_API + id,
      data,
    });
  }

  /**
   * Changes the currently displayed tab
   * @param newTab: name of the new tab, can be alerts or messages
   */
  onChangeSelectedTab({ newTab }: { newTab: number }) {
    this.selectedTab = newTab;
  }

  /**
   * Handles the change in the favourites filter
   */
  onChangeFavouritesFilter({ event }): void {
    let tab = '';
    if (this.selectedTab === 0) {
      tab = 'alerts';
    } else {
      tab = 'messages';
    }
    this.navigationService.navigateTo({
      path: 'notifications',
      params: {
        filter: event,
        view: tab,
      },
    }).then(() => {
      window.location.reload();
    });
  }

  /**
   * Handles clicks on the create ticket button
   */
  onCreateTicketClick(): void {
    const dialogRef = this.dialog.open(CreateAlertComponent);
    dialogRef.afterClosed().subscribe((event) => {
      this.ngOnInit();
    });
  }

  /** Checks if there are unread messages */
  checkForUnread(): void {
    for (const ticket of this.tickets) {
      if (ticket.ultimoMensajeLeidoPorEstudiante === false) {
        this.unreadMessages = true;
        break;
      }
    }
  }

  /** Checks if there are unread alerts */
  checkForUnreadAlerts(): void {
    for (const alert of this.alerts) {
      if (alert.leido === false) {
        this.unreadAlerts = true;
      }
    }
  }
}
