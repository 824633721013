import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/public/services/api.service';
import { AlertService } from 'src/app/public/services/alert.service';

@Component({
  selector: 'app-form-ambassador',
  templateUrl: './form-ambassador.component.html',
  styleUrls: ['./form-ambassador.component.scss']
})
export class FormAmbassadorComponent implements OnInit {

  private readonly API_FORM = 'api/formulario';

  createForm: FormGroup = this.contactForm.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    correo: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
    pregunta: ['', [Validators.required]],
  });

  constructor(
    private contactForm: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched &&
      this.createForm.controls[field].invalid
    );
  }

  onSubmit({ event }): void {
    if (this.createForm.valid) {
      const form = {
        nombre: this.createForm.controls.nombre.value,
        apellido: this.createForm.controls.apellido.value,
        correo: this.createForm.controls.correo.value,
        telefono: this.createForm.controls.telefono.value,
        pregunta: this.createForm.controls.pregunta.value,
        fuente: "Quiero ser embajador",
        anioInteres: "ND",
        edad: "ND",
        programa: "ND",
      };
      this.createFormAmbassador({ form });
    } else {
      this.alertService.showError({
        msg: `Llena el campo correctamente`,
      });
    }
  }

  /**
   * create a form
   * @param form
   */
  public async createFormAmbassador({ form }) {
    let resp = await this.apiService
      .post({ api: `${this.API_FORM}`, data: form })
      .toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Incripción exitosa`,
      });
    }else{
       
    }
  }
}
