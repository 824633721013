import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-set-phone',
  templateUrl: './set-phone.component.html',
  styleUrls: ['./set-phone.component.scss']
})
export class SetPhoneComponent implements OnInit {

  /** Variable that holds the login form */
  phone: number;
  /** Variable that holds the promotional code */
  code: string;

  /** Constant that hols the API route for validate code */
  readonly GETCODE = 'api/codigoTestCode/';

  /** Constant that hols the API route for update user */
  readonly USER_PUT_PHONE = 'api/user/';

  isCodeValidated: boolean;

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<SetPhoneComponent>,
    private cookieService: CookieService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit(): void { }

  public async onSaveNumber(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      telefono: this.phone,
      procedenciaComoLead: this.isCodeValidated ? this.code : 'NA'
    };
    this.apiService.put({ api: this.USER_PUT_PHONE + user._id, data }).subscribe((respond) => {
      user.telefono = this.phone;
      user.procedenciaComoLead = data.procedenciaComoLead;
      this.cookieService.set('user', JSON.stringify(user));
      localStorage.setItem('user', JSON.stringify(user));
      this.dialogRef.close({ phone: this.phone, code: this.code });
    });
  }

  validar(code: string): void {
    this.apiService.get({ api: this.GETCODE + code }).subscribe((response) => {
        if ( response.length > 0) {
          if (response[0] && response[0].emailUsuario  === this.data?.email){
            this.isCodeValidated = true;
            Swal.fire({
              icon: 'success',
              title: 'Validación',
              text: 'El código ingresado fue validado',
            });
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'El código ingresado no esta asignado a tu email',
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El código ingresado no existe',
          });
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El código ingresado no existe',
        });
      }
    );
  }

  onCodeChange(): void {
    if (this.isCodeValidated) { this.isCodeValidated = false; }
  }
}
