import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-free-guides-detail',
  templateUrl: './free-guides-detail.component.html',
  styleUrls: ['./free-guides-detail.component.scss'],
})
export class FreeGuidesDetailComponent implements OnInit {
  freeGuideId: string = '';
  freeGuide: any = {};
  constructor(private route: ActivatedRoute, private apiService: ApiService, private navService: NavigationService) {}

  ngOnInit(): void {
    this.freeGuideId = this.route.snapshot.params['id'];
    this.getGuideInfo();
  }

  getGuideInfo() {
    this.apiService.get({ api: `api/guia-gratuita/${this.freeGuideId}` }).subscribe((response) => {
      if (response.success) {
        this.freeGuide = response.result;
      }
    });
  }

  goBack() {
    this.navService.navigateTo({
      path: '/free-guides/resources',
      params: { category: this.freeGuide.categoria.palabraClave },
    });
  }

  goToDownload() {
    this.navService.navigateTo({ path: '' });
  }
}
