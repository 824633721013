import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-application',
  templateUrl: './delete-application.component.html',
  styleUrls: ['./delete-application.component.scss']
})
export class DeleteApplicationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteApplicationComponent>) { }

  ngOnInit(): void {
  }

  /** Handles the click on the ok button */
  onOkClick(): void {
    this.dialogRef.close();
  }

}
