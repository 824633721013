import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../services/api.service';
import { isPlatformBrowser, Location } from '@angular/common';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {

  /** City api */
  public readonly API_CITY = 'api/ciudad';

  /** Full city object */
  city: any = {
    nombre: '',
    descripcion: '',
    descripcionIngles: '',
    pais: {
      nombre: '',
      icono: '',
    },
    estado: '',
    poblacion: undefined,
  };

  /** Current language in localStorage */
  lang;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {cityId: string},
              private dialogRef: MatDialogRef<CityComponent>,
              @Inject(PLATFORM_ID) private platformId,
              private api: ApiService,
              private location: Location,
  ) { }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const l = localStorage.getItem('lang');
      if (l) {
        this.lang = l;
      } else {
        this.lang = 'en';
      }
    }
    this.city = await this.getCityInformation();
  }

  /**
   * Closes self
   */
  public selfClose() {
    this.dialogRef.close();
  }

  /** Gets city information from current id */
  public getCityInformation() {
    return new Promise((resolve, reject) => {
      this.api.get({api: `${this.API_CITY}/${this.data.cityId}`}).subscribe(response => {
        resolve(response);
      },
      error => {
        reject(error);
      });
    });
  }

    /** Handles the click on the go back button */
    onBackClick(): void {
      this.location.back();
    }
}
