<div class="col-12"  *ngIf="university.fotos.length && isBrowser">

  <!-- CARD DESCRIPTION -->
  <label class="text-media card-media liston-video" *ngIf="university?.videosResuelve?.length > 0">Vídeos</label>
  <div class="card-desc" *ngIf="university?.videosMedia?.length">
    <!-- VIDEOS -->
    <div class="container-carousel" >
      
      <carousel id="carousel-1" cellsToShow="2"  style="height:180px;" *ngIf="university?.videosMedia?.length != 1">
        <div *ngFor="let video of university?.videosMedia; index as i" class="carousel-cell">
          <div >
              <iframe id="university-video{{ i }}" frameborder="0" type="text/html" [src]="video.url | safe"
                   title="YouTube video player"
                   [ngClass]="university?.videosMedia?.length > 1 ? 'claseVideoW50' : 'claseVideoW100'"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
              </iframe>
          </div>
          
        </div>
        <!-- <div>
          <iframe *ngIf="university?.videosMedia?.length == 1" id="university-video0" frameborder="0" type="text/html"
            [src]="university?.videosMedia[0]?.url | safe" class="video-framePrincipal" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div> -->
      </carousel>
    </div>

  </div>
  <!-- CARD CC -->
  <label class="text-media card-media">Fotos</label>
  <div class="card-desc" *ngIf="university?.fotosMedia?.length">
    <div class="container-carousel">
      <carousel id="carousel-2" cellsToShow="2"  class="carousel slide carousel-multi-item">
        <div *ngFor="let picture of university?.fotosMedia; index as i" class="carousel-cell">
          <img   [ngClass]="university?.fotosMedia?.length > 1 ? 'claseImgW50' : 'claseImgW100'"(click)="onPhotoClick(i)" [src]="picture?.url" alt="{{ 'Picture number ' + i }}" />
        </div>
      </carousel>
    </div>
  </div>
</div>
