import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.scss']
})
export class SignUpSuccessComponent implements OnInit {

  constructor(private navigationService: NavigationService,
              private dialogRef: MatDialogRef<SignUpSuccessComponent>) { }

  ngOnInit(): void {
  }

  /**
   * Handles the click on the ok button
   */
  onOkClick(): void {
    this.dialogRef.close();
  }

}
