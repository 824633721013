<div class="wrapper">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Conoce los tipos de competencias</h4>
      <p>Explora entre los tipos de competencias</p>
      <hr class="hrLine" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3" *ngFor="let item of skills; index as i">
          <div class="news-card">
            <div class="card-body pad0">
              <div class="headerSection">
                <label class="universityTitle">{{ item.skillName }}</label>
                <label class="universityDescription">¿Qué es {{ item.skillName }}?</label>
                <em class="fas fa-solid fa-plus floatR" (click)="openShowMoreComponent(item)">
                  <span class="showMessage">Ver más</span>
                </em>
              </div>
              <iframe *ngIf="item.video" [src]="getSafeVideoUrl(item.video)" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="news-card-footer">
              <button class="addAnotherButton" (click)="selectSkill(item.skillName)">Experiencias Asociadas</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
