<div class="modalContainer">
  <div class="modal-header customHeader">
    <h4 class="modalTitle">Planeador de experiencias</h4>
  </div>
  <div class="modal-body customBody">
    <mat-form-field>
      <mat-label>Experiencia asociada</mat-label>
      <mat-select
        (ngModelChange)="onExperienceChange($event)"
        [disabled]="experienceData"
        [(ngModel)]="plannedExperience.experienceName">
        <mat-option *ngFor="let option of experiences" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Descripción de lo que quiero hacer:</mat-label>
      <textarea [(ngModel)]="plannedExperience.experienceDescription" matInput></textarea>
    </mat-form-field>

    <div class="d-flex justify-content-between">
      <div class="skillsContainer">
        <mat-form-field>
          <mat-label>Competencia principal</mat-label>
          <mat-select (ngModelChange)="onDropdownChange($event)" [(ngModel)]="plannedExperience.principalSkill">
            <mat-option *ngFor="let option of skills" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="skillsContainer">
        <mat-form-field>
          <mat-label>Competencia secundaria</mat-label>
          <mat-select [(ngModel)]="plannedExperience.secondarySkill">
            <mat-option *ngFor="let option of secondarySkills" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <label>Rango de realización</label>
    <div class="d-flex justify-content-between">
      <div class="datesContainer">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Fecha de inicio"
            [(ngModel)]="plannedExperience.startDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="datesContainer">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="secondPicker"
            placeholder="Fecha final"
            [(ngModel)]="plannedExperience.endDate" />
          <mat-datepicker-toggle matSuffix [for]="secondPicker"></mat-datepicker-toggle>
          <mat-datepicker #secondPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer customFooter d-flex justify-content-between">
    <button *ngIf="plannedExperience.isEdit" mat-button color="warn" (click)="onDelete()">Eliminar</button>
    <button mat-button color="primary" (click)="onSubmit()">
      {{ plannedExperience.isEdit ? 'Actualizar' : 'Planear' }}
    </button>
  </div>
</div>
