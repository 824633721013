import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { NavigationService } from 'src/app/public/services/navigation.service';
import { S3Service } from 'src/app/public/services/s3.service';

@Component({
  selector: 'app-platform-payment',
  templateUrl: './platform-payment.component.html',
  styleUrls: ['./platform-payment.component.scss'],
})
export class PlatformPaymentComponent implements OnInit {
  /** CONSTANTS */
  /** API route to get payments by student */
  readonly U360_PAYMENT_API = 'api/pago-relacion/estudiante/';
  /** API route to create payments */
  readonly U360_CREATE_PAYMENT_API = 'api/pagou360';
  /** API route to get payment config */
  readonly CONFIG_API = 'api/config/';

  /** VARIABLES */
  /** Variable that holds the current application id */
  applicationId: string;
  /** Variable that holds the information of the platform payment */
  platformPayment = {
    completado: false,
    fechaMaxima: '',
  };
  /** Variable that holds the user id of the student of the application*/
  @Input() userId: string;

  private paymentValue = 0;

  constructor(
    private navigationService: NavigationService,
    private alertService: AlertService,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId,
    private ngxSpinnerService: NgxSpinnerService,
    private s3Service: S3Service,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    this.getPayments({ id: this.userId });
  }

  /**
   * Retrieves payments to U360  by student from the backend
   * @param studentId
   */
  getPayments({ id }: { id: string }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.U360_PAYMENT_API + id + '/aplicacion/' + this.applicationId }).subscribe(
      (response) => {
        if (response === true) {
          this.platformPayment.completado = true;
        } else {
          this.getPaymentConfig();
        }
      },
      (err) => {
        this.alertService.showError({ msg: 'There was an error, please try again later or contact suppor' });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Retrieves the payment value from the backend
   */
  getPaymentConfig(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.CONFIG_API }).subscribe(
      (response) => {
        this.paymentValue = response[0].usePaymentCost;
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was an error loading the information, please contact support or try again later',
        });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Handles the click on the make payment button
   */
  onMakePayment(): void {
    this.navigationService.navigateTo({
      path: 'payment/u360-payment/' + this.userId,
      params: { aplicacion: this.applicationId },
    });
  }

  /**
   * Handles the click on the upload payment proof button
   */
  onUploadPaymentProof({ event }: { event: any }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Academic-history.you-must-upload-1-file'));
    } else {
      const secondKey = this.s3Service.generateKey();
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const key = `student/${this.userId}/payment-proof/${secondKey}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          this.ngxSpinnerService.hide();
          this.alertService.showError({
            msg: 'There was an error uploading the document, please try again later or contact support',
          });
        } else {
          // Create the payment
          const payment = {
            medioDePago: 'Transferencia',
            documentoPrueba: data.Location,
            estudiante: this.userId,
            valor: this.paymentValue,
          };
          this.apiService.post({ api: this.U360_CREATE_PAYMENT_API, data: payment }).subscribe(
            (response) => {
              if (response.success === true) {
                this.alertService.showSuccess({
                  msg: 'Your payment proof has been uploaded, an advisor will review the document and give you access to the platform',
                });
              } else {
                this.alertService.showError({ msg: 'There was an error creating the payment, please try again later' });
              }
            },
            (err) => {
              this.alertService.showError({ msg: 'There was an error creating the payment, please try again later' });
              this.ngxSpinnerService.hide();
            },
            (complete?) => {
              this.ngxSpinnerService.hide();
            }
          );
        }
        this.ngxSpinnerService.hide();
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }
}
