<div class="translation-service-card">
  <img class="translation-service-image" [src]="image" alt="translation">
  <p class="translation-info">
    {{provider}} <br>
    {{docType}}
  </p>
  <p class="page-numbers">{{ "Translation-service-card.number-of-pages" | translate }} </p>
  <div class="page-counter">
    <img (click)="onRemovePage()" src="assets/utils/menos.png" class="minus-button" alt="remove">
    <div class="counter">
      <div class="counter-cell">
      <div class="input-number-pages clickable" (click)="onChangeToEditable()">
        {{numberOfPages}}
      </div>
      <input (keyup.enter)="onSaveNumberOfPages()" *ngIf="editingPages" class="input-number-pages"  type="text" [(ngModel)]="newNumber">
      </div>
    </div>
    <img (click)="onAddPage()" src="assets/utils/mas.png" class="plus-button" alt="add">
  </div>
  <div class="price">
    ${{cost | number}} {{currency}}
  </div>
  <button [disabled]="editingPages" (click)="onAddToCart()" class="add-to-cart">
    {{ "Translation-service-card.buy" | translate }}
  </button>
</div>
