import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
@Component({
  selector: 'ngx-create-student',
  templateUrl: './createStudent.component.html',
  styleUrls: ['./createStudent.component.scss'],
})
export class CreateStudentComponent implements OnInit {
  readonly API_REGISTER_USER = 'users/registrar-estudiante';
  readonly collectionCodigos = 'api/saveListCode/';
  readonly GENERATEST = 'api/crearTestUser';
  readonly API_COLEGIO = 'api/colegio';
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';

  types = [
    {
      display: 'Advisor',
      value: 'Advisor',
    },
    {
      display: 'Recruitment partner',
      value: 'Recruitment',
    },
    {
      display: 'Estudiante',
      value: 'Estudiante',
    },
  ];

  /** User object to create */
  user = {
    primerNombre: '',
    segundoNombre: '',
    primerApellido: '',
    segundoApellido: '',
    email: '',
    school: '',
    password: '',
    confirmPassword: '',
    procedenciaComoLead: '',
    birthDay: '',
    gradeLevel: '',
    grade: '',
    tipo: 'Estudiante',
  };
  datepickerConfig: Partial<BsDatepickerConfig>;
  userData = JSON.parse(localStorage.getItem('user'));
  spinner: boolean;
  usuario: any = {};
  schoolGradeLevels: any;
  schoolGradesResponse: any;
  justGrades: any;
  filteredGrades: { value: string; display: string }[] = [];
  loading = false;
  getSchoolsData = 'api/colegio';
  allColegios: any;
  schoolsList: any;
  cantidad: 1;
  listCode: any;
  data: any;
  schoolId: any;
  private activeDialog: any;
  dataList: any;
  id: any;
  grades: any;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'YYYY-MM-DD',
    };
  }

  ngOnInit(): void {
    if (this.userData._id === undefined || this.userData._id === null) {
    } else {
      this.getSchoolId();
    }

    this.fetchColegiosData();

    this.usuario = JSON.parse(localStorage.getItem('usuario'));

    if (this.usuario.tipo === 'Advisor') {
      this.types = [
        {
          display: 'Estudiante',
          value: 'Estudiante',
        },
      ];
    }
  }
  getSchoolId() {
    this.apiService.get({ api: this.GET_DATA_SCHOOL + this.userData._id }).subscribe((response: any) => {
      this.schoolId = response._id;
      this.user.school = this.schoolId;
      this.getGradeLevelsBySchoolId(this.schoolId);
    });
  }
  onSchoolSelectionChange(): void {
    if (this.user.school) {
      this.getGradeLevelsBySchoolId(this.user.school);
    }
  }
  resetForm(): void {
    this.user = {
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      email: '',
      school: '',
      password: '',
      confirmPassword: '',
      procedenciaComoLead: '',
      birthDay: null,
      gradeLevel: '',
      grade: '',
      tipo: 'Estudiante',
    };
  }
  async getGradeLevelsBySchoolId(schoolId) {
    this.spinner = true;
    const apiUrl = `api/getGradeLevelsById/${schoolId}`;
    await this.apiService.get({ api: apiUrl }).subscribe(
      (response) => {
        if (response) {
          const formattedGrades = this.formatGrades(response);
          this.grades = formattedGrades;
          const justGrades = this.getJustGrades(response);
        }
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }

  formatGrades(gradesData): { value: string; display: string }[] {
    let formattedGrades = [];
    const gradeLevels = gradesData.gradesLevels;

    gradeLevels.forEach((item) => {
      const grade = item.grade[0];
      const levels = item.levels;

      levels.forEach((levelPosition) => {
        levelPosition.forEach((level) => {
          const formattedGrade = `${grade}${level}`;
          formattedGrades.push({
            value: formattedGrade,
            display: formattedGrade,
          });
        });
      });
    });

    return formattedGrades;
  }

  getJustGrades(gradesData): string[] {
    this.justGrades = [];
    const gradeLevels = gradesData.gradesLevels;

    gradeLevels.forEach((item) => {
      const grade = item.grade[0].toString();
      this.justGrades.push(grade);
    });

    return this.justGrades;
  }
  filterGradeLevels() {
    if (this.user.grade) {
      const filteredGrades = this.grades.filter((item) => item.value.startsWith(this.user.grade));
      this.filteredGrades = filteredGrades;
    } else {
      this.filteredGrades = this.grades;
    }
  }

  public crearCodigos() {
    this.cantidad = undefined;
    this.listCode = undefined;
  }

  fetchColegiosData(): void {
    this.apiService.get({ api: this.getSchoolsData }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.allColegios = response.allSchoolData;
          this.setColegiosData({ colegios: response.allSchoolData });
        } else {
          this.displayErrorAlert();
        }
      },
      (err) => {
        this.displayErrorAlert();
      }
    );
  }

  displayErrorAlert(): void {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Hubo un problema al recuperar los colegios. Por favor, inténtelo de nuevo más tarde.',
    });
  }

  setColegiosData({ colegios }) {
    const filteredSchoolData = [];
    for (const colegio of colegios) {
      const schoolObject = {
        id: '',
        nombre: '',
        slug: '',
      };

      schoolObject.id = colegio._id;
      schoolObject.nombre = colegio.primerNombre;
      schoolObject.slug = colegio.slug;

      filteredSchoolData.push(schoolObject);
    }
    this.schoolsList = filteredSchoolData;
  }

  /**
   * Saves current user
   * @param form object containing value and valid properties
   */
  saveUser({ valid, value }): void {
    if (!valid) {
      this.alertService.showWarning({
        msg: 'Por favor llene todos los campos',
      });
      return;
    }

    if (value.password !== value.confirmPassword) {
      this.alertService.showError({
        msg: 'Las contraseñas no coinciden',
      });
      return;
    }

    delete this.user.confirmPassword;

    this.loading = true;
    if (this.usuario.tipo === 'Advisor') {
      this.user['advisor'] = this.usuario._id;
    }

    this.generateSchoolUniqueCode();
    this.user['procedenciaComoLead'] = this.listCode.codigo;
    this.dataList = this.user;
    this.dataList.birthDay = this.datePipe.transform(this.user.birthDay, 'yyyy-MM-ddTHH:mm:ss.SSSZ');

    this.apiService
      .post({ api: this.API_REGISTER_USER, data: this.user })
      .pipe(
        switchMap((response) => {
          const userId = response.user._id;
          const schoolId = response.user.school;
          const birthDay = response.user.birthDay;
          const grade = response.user.grade;
          if (response && response.success) {
            this.data = {
              usuario: userId,
              colegio: schoolId,
              birthDay: birthDay,
              grade: grade,
              semestreActivo: '',
              nombreColegio: '',
              nivelAcademico: '',
              disciplina: '',
              hojaTest: 0,
              codigo: 0,
              liderazgo: 0,
              gobal_mind: 0,
              impacto_social: 0,
              logro_accion: 0,
              puntaje_general: 0,
              competencia_academica: 0,
              isFirstTest: true,
              test: [],
            };
            this.alertService.showSuccess({
              msg: 'Usuario registrado',
            });
            return this.apiService.post({
              api: this.GENERATEST,
              data: this.data,
            });
          } else {
            const errorMsg = response && response.msg ? response.msg : 'Error en el sistema';
            this.alertService.showError({
              msg: errorMsg,
            });
          }
        })
      )
      .subscribe(
        (response) => {},
        (error) => {
          this.alertService.showError({
            msg: error || 'Error en el sistema',
          });
        },
        () => {
          this.loading = false;
        }
      );
  }

  public generateSchoolUniqueCode() {
    if (!this.user.school) {
      this.alertService.showError({ msg: 'Falta información para generar el código.' });
      return;
    }

    const selectedSchool = this.schoolsList.find((school) => school.id === this.user.school);

    if (selectedSchool) {
      const codigo = selectedSchool.nombre + '-' + this.generateUniqueCode(5);

      this.listCode = {
        idColegio: this.user.school,
        codigo: codigo,
      };
    } else {
      this.alertService.showError({
        msg: 'No se encontró el colegio seleccionado',
      });
      return;
    }
  }

  public generateUniqueCode(num) {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split(''),
      result = '';
    if (num > characters.length) return false;
    for (let i = 0; i < num; i++) {
      let randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.splice(randomIndex, 1)[0];
    }
    return result;
  }

  public guardarLista() {
    let uniqueCodeData = {
      emailUsuario: this.dataList.email,
      idColegio: this.dataList.school,
      codigo: this.dataList.procedenciaComoLead,
    };
    this.apiService.post({ api: this.collectionCodigos, data: uniqueCodeData }).subscribe((response) => {});
  }
  onInputValueChange(event: any) {}
}
