<div class="main">
  <div class="header">
    <div class="title">
      <h3>{{ city ? city.nombre : "" }}</h3>
    </div>
    <div class="close">
      <label (click)="selfClose()"> {{ "City.close" | translate }} </label>
    </div>
  </div>
  <div class="descripcion">
    <label>{{
      (city ?  lang == "es" ? city.descripcion : city.descripcionIngles : '') || ''
      }}</label>
  </div>
  <br>
  <div>
    <div class="detail-section">
      <h5 class="match-card-subtitle"> {{ "City.country" | translate }} </h5>
      <div class="country">
        <img class="country-image" src="{{city ? city.pais.icono : ''}}" alt="{{city?.pais?.nombre?'city?.pais?.nombre':'coutry image'}}" />
        {{ city ? city.pais.nombre : ''}}
      </div>
    </div>
    <div class="detail-section">
      <h5 class="match-card-subtitle"> {{ "City.state" | translate }} </h5>
      <div class="city">
        {{ city ? city.estado : ''}}
      </div>
    </div>
  </div>
  <br>
  <div>
    <div class="detail-section">
      <h5 class="match-card-subtitle"> {{ "City.population" | translate }} </h5>
      <div class="country">
        {{ city.poblacion | number }}
      </div>
    </div>
  </div>
</div>