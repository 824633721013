import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './dateInput.component.html',
  styleUrls: ['./dateInput.component.scss'],
})
export class DateInputComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() label: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['control']) {
      this.updateInputValue();
    }
  }

  private updateInputValue(): void {
    if (this.control) {
      const value = this.control.value;
      if (value) {
        // Convert ISO string to Date object
        const date = new Date(value);
        // Set the value in the control
        this.control.setValue(date, { emitEvent: false });
      }
    }
  }

  onDateChange(event: any): void {
    const date = event.value;
    if (date instanceof Date) {
      // Convert Date object to ISO string
      const isoString = date.toISOString();
      this.control.setValue(isoString);
    }
  }
}
