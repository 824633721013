<div class="travel-service-card">
  <div class="travel-service-card-header">
    <mat-icon (click)="onAdditionalInfoClick()" class="info-button">info
    </mat-icon>
  </div>
  <img [src]="image" class="travel-service-img" alt="travel">
  <p class="travel-service-name">
    {{serviceName}} <br>
    {{provider}}
  </p>
  <p class="days-number-label">
    {{ "Travel-service-card.number-of-days" | translate }}
  </p>
  <div class="page-counter">
    <img (click)="onRemoveDay()" src="assets/utils/menos.png" class="minus-button" alt="remove">
    <div class="counter">
      <div class="counter-cell">
        <div class="input-number-pages" (click)="onChangeEditing()">
          {{numberOfDays}}
        </div>
      <input (keyup.enter)="onSaveNumberOfDays()" class="input-number-pages" *ngIf="editing" type="text" [(ngModel)]="newNumber">
      </div>
    </div>
    <img (click)="onAddDay()" src="assets/utils/mas.png" class="plus-button" alt="add">
  </div>
  <div class="cost">
    ${{cost | number}} {{currency}}
  </div>
  <button (click)="onAddToCart()" class="add-to-cart">
    {{ "Travel-service-card.buy" | translate }}
  </button>
</div>
