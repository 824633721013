import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-leader-test',
  templateUrl: './leader-test.component.html',
  styleUrls: ['./leader-test.component.scss'],
})
export class LeaderTestComponent implements OnInit, OnDestroy {
  public user: any;
  public formulario_test: any;
  readonly TESTGET = 'api/test_internalizacionUserSemestre/';
  readonly GENERATEST = 'api/crearTestUser';
  readonly GETCODE = 'api/codigoTestCode/';
  public seleccionado = 0;
  readonly USER_API = 'api/oneUser/';
  userData = JSON.parse(localStorage.getItem('user'));
  isDataStudentComplete: boolean;
  constructor(
    private dialogService: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.getStudent();
    this.navigationService.setIsVisibleSidebar(false);
    this.formulario_test = {
      idUsuario: 0,
      nombreUser: '',
      colegio: 0,
      nombreColegio: '',
      edadActual: '',
      disciplina: '',
      genero: '',
      nivel_academico: '',
      pasoActivo: 0,
      habilidades: [
        {
          nombre: 'Liderazgo',
          puntaje: 0,
          puntajeMinimo: 0,
          puntajeMaximo: 85,
          resultado: 0,
        },
        {
          nombre: 'Global Mind',
          puntaje: 0,
          puntajeMinimo: 0,
          puntajeMaximo: 80,
          resultado: 0,
        },
        {
          nombre: 'Impacto Social',
          puntaje: 0,
          puntajeMaximo: 40,
          puntajeMinimo: 0,
          resultado: 0,
        },
        {
          nombre: 'Logro y Acción',
          puntaje: 0,
          puntajeMaximo: 45,
          puntajeMinimo: 0,
          resultado: 0,
        },
        {
          nombre: 'Compentecia Academica / Aprendizaje',
          puntaje: 0,
          puntajeMaximo: 50,
          puntajeMinimo: 0,
          resultado: 0,
        },
      ],
      preguntas: [
        {
          seccion: '01',
          tipo: 'data',
          invertida: false,
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                'Creemos juntos tu perfil para maximizar tu éxito de admisión a la universidad de tus sueños. Elige el objetivo',
              opciones: [
                { titulo: 'Pregrado', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Postgrado', tipo_respuesta: 'card_seleccion', seleccionada: false },
              ],
            },
          ],
        },
        {
          seccion: '02',
          tipo: 'data',
          habilidad: 'N/A',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta: 'Cuéntanos un poco sobre ti completando esta información',
              opciones: [
                { titulo: 'Género', tipo_respuesta: 'input', respuesta: '' },
                { titulo: 'Colegio o universidad:', tipo_respuesta: 'input', respuesta: '' },
                { titulo: 'Tu edad:', tipo_respuesta: 'input', respuesta: '' },
                {
                  titulo: 'Nivel académico actual:',
                  tipo_respuesta: 'option select',
                  respuesta: '',
                  opciones_respuesta: [{ option: '1' }, { option: '2' }],
                },
                {
                  titulo: '¿Qué te gustaría estudiar?',
                  tipo_respuesta: 'option select',
                  respuesta: '',
                  opciones_respuesta: [{ option: '1' }, { option: '2' }],
                },
                {
                  titulo: '¿Qué otra carrera quieres estudiar? (Opcional)?',
                  tipo_respuesta: 'input',
                  respuesta: '',
                },
              ],
            },
          ],
        },
        {
          seccion: '03',
          tipo: 'data',
          habilidad: 'N/A',
          titulo_seccion: 'Conoce los tipos de universidades y elige tu favorita',
          indicaciones: 'Elige entre las siguientes opciones el tipo de universidad en la que te interesaría aplicar.',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                'Creemos juntos tu perfil para maximizar tu éxito de admisión a la universidad de tus sueños. Elige el objetivo',
              opciones: [
                { institucion: 'Top Tier', interesa: false },
                { institucion: 'Experiential University', interesa: false },
                { institucion: 'Practical learning', interesa: false },
                { institucion: 'Vocational / Passion', interesa: false },
              ],
            },
          ],
        },
        {
          seccion: '04',
          tipo: 'informativa',
          habilidad: 'N/A',
          titulo_seccion: 'Conoce las competencias de un estudiante exitoso del mundo',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          indicaciones:
            'Sabías que las instituciones internacionales valoran ciertas características en los estudiantes, que les permitirán a los postulados desarrollarse de manera exitosa en un ámbito académico y laboral internacional.',
        },
        {
          seccion: '05',
          tipo: 'puntaje',
          habilidad: 'Liderazgo',
          titulo_seccion: 'Hablemos de Liderazgo',
          indicaciones: 'Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda.',
          leyenda: '1) Nunca 2) Casi nunca 3) A veces 4) Casi siempre 5) Siempre',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                '¿Reconozco con claridad cuáles son mis capacidades y habilidades y he logrado expresararlas con éxito en mi contexto?',
              opcionSeccionada: 0,
              recomendacion:
                'Aprender a reconocer tus habilidades te pondra en una posición de ventaja dado que a partir de ellas puedes marcar diferencia. Enfocate en mejorar en lo que eres bueno.',
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta: '¿Hago parte de un grupo o comunidad relacionada con el campo o área que me interesan?.',
              opcionSeccionada: 0,
              recomendacion:
                'Pertenecer a grupos afines a mis intereses me permite mejorar conocimiento, competitividad, habilidades sociales. No esperes para integrarte a grupos que compartan aquello que te apasiona.',
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Global Mind' }],
            },
            {
              pregunta:
                'Puedo afirmar lo que le ocurrirá a mi campo de interés en 5 años. (Recursos Humanos, Impacto Ambiental, Proyección Económica).',
              opcionSeccionada: 0,
              recomendacion:
                'Entender el mundo con una visión de sistema te ayudará a proyectarte como lider, dado que serás capaz de entender los impactos positivos de tus acciones en el largo y mediano plazo, interesate por conocer sobre tu campo de acción y cómo a través de él puedes impactar vidas en el futuro.',
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [
                { habilidad: 'Liderazgo' },
                { habilidad: 'Global Mind' },
                { habilidad: 'Impacto Social' },
                { habilidad: 'Compentecia Academica / Aprendizaje' },
              ],
            },
            {
              pregunta:
                'Tengo una definición clara de quién quiero ser y actúo con hábitos diarios congruentes para lograrlo.',
              recomendacion:
                'Reconocer nuestras fortalezas y crear hábitos y repeticiones sobre ellas define lo que eres y te ayuda a motivarte, mantenerte y positivo  y cumplir metas. Define que te apasiona y repitelo cada día.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta: 'He estudiado reiteradamente cómo puedo mejorar mi entorno desde mi campo o carrera de interés',
              opcionSeccionada: 0,
              recomendacion:
                'Mantener la pasión y el deseo por cumplir metas determinadas es lo que llamamos pasión. Encuetra que te apasiona y busca los medios para conseguir resultados',
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [
                { habilidad: 'Liderazgo' },
                { habilidad: 'Global Mind' },
                { habilidad: 'Impacto Social' },
                { habilidad: 'Compentecia Academica / Aprendizaje' },
              ],
            },
            {
              pregunta: 'Suelo ayudar a otros cuando tienen sentimientos como tristeza, rabia ó frustración.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'Entender los sentimientos de los otros es la base para poderlos influenciar y guiar hacia propositos conjuntos, una meta es más facil cuando es común y actuamos en grupo para lograrla.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                '¿Puedo expresar de forma apropiada mis propias emociones, sin verme afectad@ por el contexto y/o interlocutor?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'Las emociones positivas y negativas estan presentes en la vida de todos, aprender a reconocerlas y hacer conciencia de ellas, permite que jueguen a nuestro favor y no nos hagan pasar malos momentos.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                'Los demás me consideran una persona siempre amable. Hago siempre un poco más de lo normal para que los demás estén bien.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'La ley de la reciprocidad esta presente en la vida de todos, si ayudamos a otros, otros nos ayudarán a nosotros.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                '¿Puedo establecer con facilidad relaciones participativas y cooperativas, compartiendo recursos y conocimiento fomentando ambientes abiertos y flexibles?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion: 'Ser amables nos conecta con personas del mundo que pueden ayudarnos. Intentalo cada día.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                'Tengo una rica red de relaciones que me permiten saber perfectamente lo que ocurre en mi entorno.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'Hay quienes dicen que la riqueza se mide por la red de contactos que estan dispuestos a ayudarte, ¿ya tienes la tuya?',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta: 'Me quejo regularmente del sistema.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion: 'Proponer más de lo que me quejo demostrará al mundo de que eres capaz. ¿Estas dispuesto?',
              invertida: true,
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Liderazgo' }],
            },
            {
              pregunta: 'Para mi todo tiene que quedar perfecto y considero que mi criterio es generalmente adecuado.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion: '',
              invertida: true,
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Global Mind' }],
            },
            {
              pregunta: 'Sé de que forma quiero impactar al mundo. He investigado para entenderlo.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'Tener visión de largo plazo te ayudará a construir un plan para con pequeños o grandes aportes aportarle al mundo. Estas listo?',
              puntaje_ideal: 5,
              invertida: false,
              afecciones: [
                { habilidad: 'Liderazgo' },
                { habilidad: 'Global Mind' },
                { habilidad: 'Impacto Social' },
                { habilidad: 'Compentecia Academica / Aprendizaje' },
              ],
            },
          ],
        },
        {
          seccion: '06',
          tipo: 'data',
          habilidad: 'Liderazgo',
          titulo_seccion: 'Hablemos de liderazgo',
          indicaciones:
            'A continuación, déjanos saber si te has involucrado con alguna de las siguientes afirmaciones.',
          leyenda: '',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                'Creemos juntos tu perfil para maximizar tu éxito de admisión a la universidad de tus sueños. Elige el objetivo',
              opciones: [
                { label: 'Grupos de investigación', interesa: false },
                { label: 'Dirección de Proyecto', interesa: false },
                { label: 'Liderazgo deportivo', interesa: false },
                { label: 'Líder de Equipo', interesa: false },
                { label: 'Participación activa', interesa: false },
              ],
            },
          ],
        },
        {
          seccion: '07',
          tipo: 'optener_datos',
          titulo_seccion: 'Hablemos de liderazgo',
          indicaciones:
            'A continuación, déjanos saber si te has involucrado con alguna de las siguientes afirmaciones.',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta: '¿Tienes promedio académico por encima del 80% en los últimos tres años?',
              opcionSeccionada: 'no',
            },
            { pregunta: '¿Has trabajado durante tus vacaciones?', opcionSeccionada: 'no' },
            { pregunta: '¿Perteneces a grupos de Investigación?', opcionSeccionada: 'no' },
            {
              pregunta: '¿Has sacado adelante un plan de negocio o creado algún emprendimiento?',
              opcionSeccionada: 'no',
            },
            {
              pregunta:
                '¿Tienes un portafolio con logros o muestras de tu trabajo artístico? (Responde esta pregunta únicamente si eres artista o te interesa esta área)',
              opcionSeccionada: 'no',
            },
          ],
        },
        {
          seccion: '08',
          tipo: 'puntaje',
          habilidad: 'Logro y Acción',
          titulo_seccion: 'Hablemos de Logro y Acción',
          indicaciones: 'Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda.',
          leyenda: '1) Nunca 2) Casi nunca 3) A veces 4) Casi siempre 5) Siempre',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta: 'Soy persona autoexigente, que se impone estándares altos de desempeño constantemente.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Compentecia Academica / Aprendizaje' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta:
                'Soy una persona competitiva que se interesa por alcanzar metas desafiantes con retos exigentes.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }],
            },
            {
              pregunta:
                '¿He emprendido y mantenido acciones que me han ayudado a desarrollar mis habilidades y competencias en las áreas que me interesa?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta:
                '¿Puedo continuar voluntariamente con acciones, procesos o proyectos a pesar de la presencia de obstáculos, dificultades o del agotamiento?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta: 'Considero fácil implementar hábitos positivos en mi vida. (Y tengo suficientes).',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta:
                'Entrego mis trabajos a tiempo, y mis notas suelen estar por encima del promedio debido al orden y la calidad.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta:
                'En los últimos 6 meses he participado todas las semanas en una actividad extracurricular de mi preferencia',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                '¿Terminas lo que inicias?, Estar cambiando de proposito cada poco tiempo no habla bien de ti, así que escoge los proyectos que quieres terminar y demuestra que consistentemente los terminas.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta: 'Has trabajado o colaborado en algún negocio de manera dedicada en tus vacaciones.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }],
            },
          ],
        },
        {
          seccion: '09',
          tipo: 'optener_datos',
          titulo_seccion: 'Hablemos de Impacto Social',
          indicaciones: 'Identifica las causas sociales que realizas o en las que te gustaría participar.',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            { pregunta: 'Voluntariado Ambiental', opcionSeccionada: 'no' },
            { pregunta: 'Voluntariado Cultural', opcionSeccionada: 'no' },
            { pregunta: 'Ayuda Civil', opcionSeccionada: 'no' },
          ],
        },
        {
          seccion: '10',
          tipo: 'puntaje',
          titulo_seccion: 'Hablemos de Impacto Social',
          indicaciones: 'Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda.',
          leyenda: '1) Nunca 2) Casi nunca 3) A veces 4) Casi siempre 5) Siempre',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                'Tengo una rica red de relaciones que me permiten saber perfectamente lo que ocurre en mi entorno.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion:
                'Hay quienes miden la riqueza por su red de contactos, a través del networking podrás alcanzar tus metas y aporvechar las fortalezas de otros a tu favor.',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta: 'Cuando trabajo en equipo logro armonizar puntos de vista aparentemente contrapuestos.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion: '',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta:
                'Me preocupa y me interesa percatarme de las cuestiones más preocupantes a nivel político, social, ambiental, económico y humanitario, de mi país y del mundo.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion: '',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Impacto Social' }, { habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                'Recurrentemente recojo basura de otros en la naturaleza (Playa, Trocha, Lago, Rio, Páramo, etc.)',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              recomendacion:
                'La conciencia ambiental empieza en nuestra casa, en nuestro colegio, en nuestra ciudad. Si queremos un mundo mejor empecemos por cuidar nuestros ambientes más cercanos, ¿estás listo?',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta: 'He apoyado fundaciones ambientales, o he liderado alguna iniciativa para contaminar menos',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion:
                'Todos nos quejamos de la problemática ambiental a nivel mundial pero pocos hacemos. Manos a la obra y demuestra en tu Hoja de Vida Internacional que eres capaz de aportar tu grano de arena.',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta: 'He conseguido certificados de voluntariados civiles, ambientales ó culturales.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion:
                'Para el entorno académico internacional es de vital importancia entender que eres conciente frente a las necesidades en las que vive tu comunidad, y esto lo demuestras a través de voluntariados recurrentes. ¿Sabias que hacerlo mejora tu hoja de vida de estudiante internacional?',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Liderazgo' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta:
                'Ayudar a personas en estado de vulnerabilidad debe de ser responsabilidad total del estado, ¡allí deberían invertir nuestros impuestos!',
              opcionSeccionada: 0,
              invertida: true,
              recomendacion:
                'Una de las mejores formas de potenciar tu perfil es ayudando a población vulnerable, debes demostrarlo a través de voluntariados certificados, U360 te ayuda a encontrarlos, ¿estás listo?',
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Liderazgo' }, { habilidad: 'Impacto Social' }],
            },
            {
              pregunta: 'Me gusta tener iniciativas para ayudar a personas en estado de vulnerabilidad, y las realizo.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion: '',
              puntaje_ideal: 5,
              afecciones: [
                { habilidad: 'Global Mind' },
                { habilidad: 'Impacto Social' },
                { habilidad: 'Logro y Acción' },
              ],
            },
            {
              pregunta:
                'Me intereso por el patrimonio cultural de mi país o la humanidad y me interesaría difundir la cultura.',
              opcionSeccionada: 0,
              recomendacion: '',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Impacto Social' }],
            },
          ],
        },
        {
          seccion: '11',
          tipo: 'puntaje',
          titulo_seccion: 'Hablemos de Compentecia Académica',
          indicaciones: 'Ingresa el aproximado del promedio de tus notas de los últimos 3 años en escala de 0% a 100%.',
          leyenda:
            '¡No te preocupes! Aunque las calificaciones son importantes para muchas instituciones, también tendrán en cuenta otros factores cómo tus competencias o cursos preuniversitarios.',
          habilidades: [
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            { pregunta: 'Notas 9°', opcionSeccionada: '' },
            { pregunta: 'Notas 10°', opcionSeccionada: '' },
            { pregunta: 'Notas 11°', opcionSeccionada: '' },
            { pregunta: 'Notas 12°', opcionSeccionada: '' },
            {
              pregunta: 'Promedio Nota°',
              opcionSeccionada: '',
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            { pregunta: 'Evaluación de opciones universitarias', opcionSeccionada: 'No' },
          ],
        },
        {
          seccion: '12',
          tipo: 'puntaje',
          titulo_seccion: 'Hablemos de Compentecia Académica',
          indicaciones: '',
          habilidades: [
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },

            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta: 'Actualmente cómo es la comprensión general de una segunda Lengua.',
              opciones: [
                { titulo: 'Básico - A1', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Intermedio - B1', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Avanzado - C1', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Básico - A2', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Intermedio - B2', tipo_respuesta: 'card_seleccion', seleccionada: false },
                { titulo: 'Avanzado - C2', tipo_respuesta: 'card_seleccion', seleccionada: false },
              ],
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
          ],
        },
        {
          seccion: '13',
          tipo: 'puntaje',
          titulo_seccion: 'Hablemos de Competencia académica',
          indicaciones: 'Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda.',
          leyenda: '1) Nunca 2) Casi nunca 3) A veces 4) Casi siempre 5) Siempre',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta: 'Conozco cuales son mis métodos de aprendizaje convenientes, y los hago parte de mi rutina.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta:
                'Cuento con la capacidad para analizar de forma precisa y profunda los datos o hechos disponibles; soy detallista, reviso de forma completa toda la información identificando sus relaciones, validez y utilidad.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Compentecia Academica / Aprendizaje' }, { habilidad: 'Logro y Acción' }],
            },
            {
              pregunta: '¿Suelo solucionar los problemas con aportes originales y creativos de manera recurrente?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta:
                'He mantenido una constante actitud de búsqueda y exploración en los campos y áreas que me interesan.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Compentecia Academica / Aprendizaje' }, { habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                'He desarrollado nuevas habilidades y competencias a partir de la exploración con distintas fuentes de conocimiento.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta:
                '¿Tengo la capacidad para organizar y establecer a futuro los recursos que necesito para lograr cumplir con mis metas?.',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Liderazgo' }],
            },
            {
              pregunta:
                'Tengo rutinas de estudio o aprendizaje definidas (Recurrentes en hora, lugar y tiempo suficiente dedicado)',
              opcionSeccionada: 0,
              tipo_respuesta: 'radio',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Logro y Acción' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
          ],
        },
        {
          seccion: '14',
          tipo: 'optener_datos',
          titulo_seccion: 'Hablemos de Global Mind',
          indicaciones:
            'Algunas instituciones te pedirán que demuestres tus habilidades y competencias, estás son algunas formas de presentar tu potencial.',
          leyenda: '¿Ya has empezado a crearlos?',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            { pregunta: '¿Cuentas con cartas de recomendación?', opcionSeccionada: 'no' },
            { pregunta: '¿Cuentas con ensayos de perspectiva profesional?', opcionSeccionada: 'no' },
            {
              pregunta: '¿Cuentas con un Portafolio? (Aplica principalmente para artes, música, industria creativa)',
              opcionSeccionada: 'no',
            },
            {
              pregunta:
                '¿Tienes experiencias académicas, voluntariados u otros con personas de culturas de otra lengua?',
              opcionSeccionada: 'no',
            },
          ],
        },
        {
          seccion: '15',
          tipo: 'puntaje',
          titulo_seccion: 'Hablemos de Global Mind',
          indicaciones: 'Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda.',
          leyenda: '1) Nunca 2) Casi nunca 3) A veces 4) Casi siempre 5) Siempre',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                '¿Me lleva un tiempo tolerar cambios muy radicales de costumbres, estilos de vida o pensamientos?.',
              opcionSeccionada: 0,
              recomendacion:
                'Cada cultura y cada persona hace las cosas de manera diferente, aprender a eliminar barreras te abrir{a puertas y te acercará a ser un ciudadano del mundo.',
              invertida: true,
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Global Mind' }],
            },
            {
              pregunta:
                'Busco tener nuevas experiencias culturales y sociales; estoy abierto a integrar de manera positiva nuevas costumbres y estilos de vida a mi realidad.',
              opcionSeccionada: 0,
              recomendacion:
                'Acercate a comunidades y culturas, con formas y estilos diferentes de hacer las cosas, cuando llegues al mundo laorar y academico universitari tendras un diferenciador frente a otros.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }],
            },
            {
              pregunta: '¿Suelo generar respuestas innovadoras cuando las circunstancias lo exigen?',
              opcionSeccionada: 0,
              recomendacion:
                'Busca siempre maneras diferentes de hacer las cosas, la innovación consiste en abrirnos y probar nuevas oportunidades.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }],
            },
            {
              pregunta:
                '¿Logro trabajar en situaciones y condiciones variadas, con diversos individuos o grupos; manteniéndome fiel a mis principios y convicciones?.',
              opcionSeccionada: 0,
              recomendacion:
                'Entender el contexto general, escuchar a cada parte, y construir sobre los diferentes puntos de vista te ayudará a plantear soluciones acertadas a problemas de tu contexto o comunidad.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Global Mind' }],
            },
            {
              pregunta: 'Creo que las cosas salen mejor a mi manera',
              opcionSeccionada: 0,
              recomendacion:
                'Entender a los otros, sus motivaciones y cómo podemos aportar en equipo hará que consigamos resultados más facil, ¿Quieres conocer cómo?',
              invertida: true,
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Global Mind' }],
            },
            {
              pregunta: 'Suelo investigar sobre situaciones mundiales en mis campos de interés profesional.',
              opcionSeccionada: 0,
              recomendacion:
                'Enteder cómo funciona el mundo en mi campo de interés, te formará cómo un estudiante integro y tendrás una visión sistemica para solucionar problemas.',
              invertida: false,
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta: 'Existen culturas en el mundo o en mi país con las que no me gustaría interactuar.',
              opcionSeccionada: 0,
              recomendacion:
                'El lider global puede trabajar con todas las culturas, generos y razas,  el no discrimar e interesarte siempre y sin pena por interactuar con otras culturas jugará a tu favor en un proceso académico o profesional internacional.',
              invertida: true,
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Global Mind' }],
            },
            {
              pregunta: 'Me gusta conocer y comprender las normas inclusive en otras culturas.',
              opcionSeccionada: 0,
              invertida: false,
              recomendacion:
                'No solo basta con que te intereses por interactuar con otras culturas, si aprendes sobre ellas tendrás un punto que juega a tu favor.',
              puntaje_ideal: 5,
              afecciones: [{ habilidad: 'Global Mind' }, { habilidad: 'Compentecia Academica / Aprendizaje' }],
            },
            {
              pregunta: 'Se me dificulta interactuar con personas de culturas diferente a la mía',
              opcionSeccionada: 0,
              invertida: true,
              puntaje_ideal: 1,
              recomendacion:
                'Pon en práctica el interactuar con otras culturas y ojalá en otras lenguas, si tienes experiencia en este campo sin duda te estás preparando para ser un lider global.',
              afecciones: [{ habilidad: 'Liderazgo' }, { habilidad: 'Global Mind' }],
            },
            {
              pregunta: 'Creo que los otros deben adaptarse a mi cultura si estoy en mi ciudad',
              opcionSeccionada: 0,
              invertida: true,
              recomendacion:
                'La inteligencia cultural se trata también de aceptar y acoger a otros, de otras regiones de nuestro país, de otras nacionalidades, de otras culturas, de otras razas. Interactua con otras culturas y potenciarás tu perfil dado que esta competencia es apreciada internacionalmente.',
              puntaje_ideal: 1,
              afecciones: [{ habilidad: 'Global Mind' }],
            },
          ],
        },
        {
          seccion: '16',
          tipo: 'optener_datos',
          titulo_seccion: 'Hablemos de Global Mind',
          indicaciones: '¿En cuál de los siguientes países te gustaría estudiar?',
          leyenda: '',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [{ pregunta: 'Lista paises', lista: [] }],
        },
        {
          seccion: '12',
          tipo: 'optener_datos',
          titulo_seccion: 'Hablemos de Presupuesto',
          indicaciones: '',
          habilidades: [
            {
              nombre: 'Liderazgo',
              puntaje: 0,
            },
            {
              nombre: 'Global Mind',
              puntaje: 0,
            },
            {
              nombre: 'Impacto Social',
              puntaje: 0,
            },
            {
              nombre: 'Logro y Acción',
              puntaje: 0,
            },
            {
              nombre: 'Compentecia Academica / Aprendizaje',
              puntaje: 0,
            },
          ],
          preguntas: [
            {
              pregunta:
                'Es importante tener en cuenta el presupuesto con el que cuentas este momento para iniciar tu camino hacia el éxito. Selecciona una de las siguientes opciones',
              opciones: [
                {
                  titulo: 'Cuento con Presupuesto para Estudiar en USA (30.000 USD A 80.000 USD al año)',
                  tipo_respuesta: 'checkox',
                  seleccionada: false,
                },
                {
                  titulo:
                    'Cuento con Presupuesto para Estudiar en otro destino de Norteamérica o Europa (18.000 USD a 30.000 USD al año)',
                  tipo_respuesta: 'checkox',
                  seleccionada: false,
                },
                {
                  titulo:
                    'Cuento con Presupuesto para estudiar en una universidad Privada Top en Colombia (14.000 USD)	',
                  tipo_respuesta: 'checkox',
                  seleccionada: false,
                },
                {
                  titulo:
                    'Cuento con Presupuesto para estudiar en una universidad Privada de menor costo en Colombia (10.000 USD ó menos)',
                  tipo_respuesta: 'checkox',
                  seleccionada: false,
                },
              ],
            },
          ],
        },
      ],
    };
  }
  getStudent() {
    this.apiService.get({ api: this.USER_API + this.userData._id }).subscribe(
      (response) => {
        if (
          response.primerNombre &&
          response.primerApellido &&
          response.birthDay &&
          response.grade &&
          response.gradeLevel &&
          response.email
        ) {
          this.isDataStudentComplete = true;
        } else {
          this.isDataStudentComplete = false;
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error cargando la infromación. Por favor vuelve a intentarlo',
        });
        this.ngxSpinnerService.hide();
        return false;
      }
    );
  }

  public cambiarSeleccionado() {
    if (this.seleccionado == 0) {
      this.seleccionado = 1;
    } else {
      this.seleccionado = 0;
    }
  }
  public comenzarTest(): boolean {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.ngxSpinnerService.show();
    if (!this.user) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe iniciar sesión para continuar',
      });
      this.ngxSpinnerService.hide();
      return false;
    } else {
      if (this.seleccionado === 1) {
        this.apiService.get({ api: this.GETCODE + this.user.procedenciaComoLead }).subscribe(
          (response) => {
            this.confirmCode(response);
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Por favor vuelve a intentarlo',
            });
            this.ngxSpinnerService.hide();
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Debe aceptar los términos y condiciones para continuar',
        });
        this.ngxSpinnerService.hide();
      }
    }
  }

  /**
   * Se verifica si el usuario contiene ya registrado el test en la base de datos, en caso de que no exista le pide código para comenzar el registro
   * @param idUser ID de usuario conectado
   */
  public verificarTest(idUser, codigo, semestre, colegioId) {
    this.apiService.get({ api: this.TESTGET + idUser + '/' + semestre }).subscribe(
      (response) => {
        if (response) {
          this.ngxSpinnerService.hide();
          this.router.navigate(['wizard']);
        } else {
          this.formulario_test.colegio = colegioId;
          this.formulario_test.idUsuario = idUser;
          this.formulario_test.nombreUser = this.user.nombre;
          const insert = {
            usuario: idUser,
            colegio: colegioId,
            semestreActivo: semestre,
            nombreColegio: '',
            nivelAcademico: '',
            disciplina: '',
            hojaTest: 0,
            codigo,
            liderazgo: 0,
            gobal_mind: 0,
            impacto_social: 0,
            logro_accion: 0,
            puntaje_general: 0,
            competencia_academica: 0,
            test: JSON.stringify(this.formulario_test),
          };
          this.ngxSpinnerService.hide();
          this.generateTest(insert);
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        this.ngxSpinnerService.hide();
        return false;
      }
    );
  }
  public generateTest(data) {
    this.apiService.post({ api: this.GENERATEST, data }).subscribe(
      (response) => {
        if (response.success) {
          this.router.navigate(['wizard']);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      },
      (err) => {}
    );
  }
  /**
   * Método que recibe respuesta de validación de código ingresado
   * @param res Respuesta del código validado
   * @returns
   */
  public confirmCode(res) {
    if (res.length > 0) {
      this.apiService.setSemestre(res[0].data_colegio[0].semestreActivo);
      this.verificarTest(
        this.user._id,
        res[0].codigo,
        res[0].data_colegio[0].semestreActivo,
        res[0].data_colegio[0]._id
      );
    }
  }

  goBack(): void {
    this.navigationService.navigateTo({ path: 'preTest' });
  }

  goToPersonalInfo() {
    this.navigationService.navigateTo({ path: '/profile/personal-info' });
  }
}
