import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private http: HttpClient) {
  }
  public getClientIPAddress()

  getClientIPAddress(){
    if(environment.production){
      return this.http.get("https://api.ipify.org/?format=json");
    } else {
      return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");
    }
  }
}
