import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss']
})
export class MoreInformationComponent implements OnInit {

  @Input() university: any;

  periodos = [
		{
			number: 1,
			text: "Winter",
		},
		{
			number: 2,
			text: "Spring",
		},
		{
			number: 3,
			text: "Summer",
		},
		{
			number: 4,
			text: "Fall",
		},
	];

  constructor(
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
     

  }

  getStart(){
    if (this.university.starts) {
      let temp = "";
      this.university.starts.forEach((start) => {
        let numero = this.periodos.find((periodo) => {
            return start && periodo.number === start;
        });
        if (numero) {
            temp += numero.text+", ";
        }
    });
    if (temp == "") {
      temp = "ND"
    }
      return temp;
    }
  }

  goToUniversitySearch(){
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        name: this.university.nombre
      }
    })
  }

}
