<div class="my-languages">
  <div class="my-languages-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back" />
    <h3 class="my-languages-title">{{ 'My-languages.language-information' | translate }}</h3>
  </div>
  <div class="my-languages-form">
    <form #langaugesForm (ngSubmit)="onSaveChanges()">
      <div class="language" *ngFor="let language of languages.controls; index as i" [formGroup]="language">
        <div class="form-group">
          <label [for]="'language' + i" class="form-label">
            {{ 'My-languages.language' | translate }} (
            <span style="color: red">*</span>
            )
          </label>
          <br />
          <select class="form-select" [id]="'language' + i" formControlName="nombre">
            <option *ngFor="let lang of languageOptions" [value]="lang.value">{{ lang.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label [for]="'level' + i" class="form-label">
            {{ 'My-languages.level' | translate }} (
            <span style="color: red">*</span>
            )
          </label>
          <br />
          <input
            [id]="'level' + i"
            class="form-input"
            type="text"
            [placeholder]="'My-languages.level' | translate"
            formControlName="nivel" />
        </div>
        <div class="certificates-section" formArrayName="certificados">
          <div
            class="certificate"
            *ngFor="let cert of onGetcertificates({ index: i }).controls; index as j"
            [formGroupName]="j">
            <div class="form-group">
              <label class="subform-array" [for]="'certificateName' + i + j">
                {{ 'My-languages.certificate-name' | translate }}
              </label>
              <input
                *ngIf="language.get('nombre').value !== 'Inglés'"
                [id]="'certificateName' + i + j"
                class="subform-input"
                type="text"
                [placeholder]="'My-languages.certificate-name' | translate"
                formControlName="nombre" />
              <select *ngIf="language.get('nombre').value === 'Inglés'" class="subform-input" formControlName="nombre">
                <option value="Academic IELTS">{{ 'My-languages.ielts-a' | translate }}</option>
                <option value="General IELTS">{{ 'My-languages.ielts-g' | translate }}</option>
                <option value="Duolingo">Duolingo</option>
                <option value="TOEFL IBT">TOEFL IBT</option>
                <option value="TOEFL PBT">TOEFL PBT</option>
                <option value="Otros">{{ 'My-languages.others' | translate }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="subform-array" [for]="'certificateScore' + i + j">
                {{ 'My-languages.certificate-grade' | translate }}
              </label>
              <br />
              <input
                [id]="'certificateScore' + i + j"
                class="subform-input"
                type="number"
                placeholder="Certificate grade"
                formControlName="calificacion" />
            </div>
            <div class="form-group">
              <label class="upload-label">
                <img src="assets/utils/camera.png" class="camera-image" alt="camera" />
                {{ 'My-languages.upload-certificate' | translate }}
                <input
                  (change)="onUploadLanguageCertificate({ event: $event, langIndex: i, certIndex: j })"
                  type="file"
                  class="upload-input" />
              </label>
            </div>
            <div class="form-group">
              <button
                type="button"
                (click)="onRemoveCertificate({ languageIndex: i, certificateIndex: j })"
                class="delete-certificate">
                {{ 'My-languages.delete-certificate' | translate }}
              </button>
            </div>
            <a
              *ngIf="
                cert.get('archivo').value !== undefined &&
                cert.get('archivo').value !== null &&
                cert.get('archivo').value !== ''
              "
              class="certificate-link"
              target="_blank"
              [href]="cert.get('archivo').value">
              {{ 'My-languages.view-certificate' | translate }}
            </a>
          </div>
          <button class="add-certificate" type="button" (click)="onAddCertificate({ index: i })">
            {{ 'My-languages.add-certificate' | translate }}
          </button>
        </div>
        <button class="delete-language" type="button" (click)="onRemoveLanguage({ index: i })">
          {{ 'My-languages.delete-language' | translate }}
        </button>
        <div class="separator"></div>
      </div>
      <button id="add-language" type="button" (click)="onAddLanguage()">
        {{ 'My-languages.add-new-language' | translate }}
      </button>
      <button *ngIf="!loading" id="save-changes" type="submit">{{ 'My-languages.save-changes' | translate }}</button>
      <mat-spinner class="loading-spinner" *ngIf="loading" diameter="40"></mat-spinner>
    </form>
  </div>
</div>
