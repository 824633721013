import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-universityBudget',
  templateUrl: './universityBudget.component.html',
  styleUrls: ['./universityBudget.component.scss'],
})
export class UniversityBudgetComponent implements OnInit {
  @Output() nextButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedBudget = new EventEmitter<{ question: string; answer: boolean }>();
  selectedAnswers: { question: string; answer: boolean }[] = [];
  public preguntas_seccion17: any;
  public dataFrm17: any;
  public dataFrm17Copy: any;
  public questionStack: any[] = [];
  public userData13: any;
  readonly getTestPaso13 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest13 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.questionStack = [
      { question: 'Cuento con Presupuesto para Estudiar en USA (30.000 USD A 80.000 USD al año)', selected: '' },
      {
        question:
          'Cuento con Presupuesto para Estudiar en otro destino de Norteamérica o Europa (18.000 USD a 30.000 USD al año)',
        selected: '',
      },
      {
        question: 'Cuento con Presupuesto para estudiar en una universidad Privada Top en Colombia (14.000 USD)',
        selected: '',
      },
      {
        question:
          'Cuento con Presupuesto para estudiar en una universidad Privada de menor costo en Colombia (10.000 USD ó menos)',
        selected: '',
      },
    ];
  }
  updateSelectedAnswers() {
    this.selectedAnswers = this.questionStack.map(question => {
      return { question: question.question, answer: question.selectedYes ? true : question.selectedNo ? false : null };
    });
  }


  emitNextButtonClick() {
    const atLeastOneQuestionAnswered = this.questionStack.some(question => question.selectedYes !== undefined || question.selectedNo !== undefined);

    if (atLeastOneQuestionAnswered) {
      this.updateSelectedAnswers();
      this.nextButtonClick.emit();
      this.selectedAnswers.forEach((answer) => {
        this.selectedBudget.emit(answer);
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes responder al menos una pregunta antes de continuar.',
        icon: 'error',
      });
    }
  }

  emitPreviousButtonClick() {
    this.previousButtonClick.emit();
  }
}
