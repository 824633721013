<form  >
    <div class="container-form-label">
        <h2 class="free-guides-subtitle">¿Aún no tienes código para el test?</h2>
        <h1 class="free-guides-title">¡Solicítalo ya!</h1>

        <input class="form-control inputCode" type="text" placeholder="Ingresa el código" [(ngModel)]="code" [ngModelOptions]="{standalone: true}">
    </div>
    <button class="buttonCancelar" (click)="closeModal(null)">Cancelar</button>
    <button class="buttonEnviar" (click)="validar(code)">Validar</button>
</form>

