<div class="cardWrapper">
  <div class="card cardContent">
    <div class="card-header">Crear usuario</div>
    <div class="card-body">
      <form #createForm="ngForm">
        <div class="row">
          <div class="col">
            <label>Primer nombre</label>
            <input
              class="form-control"
              name="primerNombre"
              #primerNombre="ngModel"
              [(ngModel)]="user.primerNombre"
              required
              pattern="[a-zA-Z]+" />
            <div *ngIf="primerNombre.invalid && (primerNombre.dirty || primerNombre.touched)" class="error">
              <div class="requiredInput" *ngIf="primerNombre.errors?.pattern">Solo se permiten letras.</div>
            </div>
          </div>
          <div class="col">
            <label>Segundo nombre</label>
            <input
              class="form-control"
              name="segundoNombre"
              #segundoNombre="ngModel"
              [(ngModel)]="user.segundoNombre"
              required
              pattern="[a-zA-Z]+" />
            <div *ngIf="segundoNombre.invalid && (segundoNombre.dirty || segundoNombre.touched)" class="error">
              <div class="requiredInput" *ngIf="segundoNombre.errors?.pattern">Solo se permiten letras.</div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Primer apellido</label>
            <input
              class="form-control"
              name="primerApellido"
              #primerApellido="ngModel"
              [(ngModel)]="user.primerApellido"
              required
              pattern="[a-zA-Z]+" />
            <div *ngIf="primerApellido.invalid && (primerApellido.dirty || primerApellido.touched)" class="error">
              <div class="requiredInput" *ngIf="primerApellido.errors?.pattern">Solo se permiten letras.</div>
            </div>
          </div>
          <div class="col">
            <label>Segundo apellido</label>
            <input
              class="form-control"
              name="segundoApellido"
              #segundoApellido="ngModel"
              [(ngModel)]="user.segundoApellido"
              required
              pattern="[a-zA-Z]+" />
            <div *ngIf="segundoApellido.invalid && (segundoApellido.dirty || segundoApellido.touched)" class="error">
              <div class="requiredInput" *ngIf="segundoApellido.errors?.pattern">Solo se permiten letras.</div>
            </div>
          </div>
        </div>
        <br />
        <div *ngIf="userData._id == undefined" class="row">
          <div class="col">
            <label for="genderSelect">Colegio:</label>
            <select
              class="form-control schoolSelector"
              name="school"
              [(ngModel)]="user.school"
              (ngModelChange)="onSchoolSelectionChange()">
              <option *ngFor="let school of schoolsList" [value]="school.id">
                {{ school.nombre }}
              </option>
            </select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Grado</label>
            <select class="form-control" name="grade" [(ngModel)]="user.grade" (ngModelChange)="filterGradeLevels()">
              <option *ngFor="let item of justGrades" [value]="item">{{ item }}</option>
            </select>
          </div>
          <div class="col">
            <label>Nivel de Grado</label>
            <select class="form-control" name="gradeLevel" #gradeLevel="ngModel" [(ngModel)]="user.gradeLevel" required>
              <option *ngFor="let item of filteredGrades" [value]="item.value">{{ item.display }}</option>
            </select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Correo electrónico</label>
            <input
              class="form-control emailSelector"
              name="email"
              #email="ngModel"
              [(ngModel)]="user.email"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
              <div class="requiredInput" *ngIf="email.errors?.pattern">
                Por favor, ingrese una dirección de correo electrónico válida.
              </div>
            </div>
          </div>
          <div class="col-6 fundationDateContainer">
            <label>Fecha de nacimiento</label>

            <input
              type="text"
              class="modal-input form-control"
              bsDatepicker
              name="birthDay"
              #birthDay="ngModel"
              [bsConfig]="datepickerConfig"
              [(ngModel)]="user.birthDay"
              placeholder="Fecha de nacimiento" />
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Contraseña</label>
            <input
              type="password"
              #password="ngModel"
              [(ngModel)]="user.password"
              name="password"
              class="form-control"
              required
              pattern="(?=.*[A-Z])(?=.*[0-9]).*" />
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error">
              <div class="requiredInput" *ngIf="password.errors?.pattern">
                La contraseña debe contener al menos una mayúscula y un número.
              </div>
            </div>
          </div>
          <div class="col">
            <label>Confirmar contraseña</label>
            <input
              type="password"
              name="confirmPassword"
              #confirmPassword="ngModel"
              [(ngModel)]="user.confirmPassword"
              class="form-control"
              required />
          </div>
        </div>
        <br />
        <button
          class="btn btn-primary"
          (click)="saveUser(createForm); generateSchoolUniqueCode(); guardarLista(); resetForm()">
          Registrar
        </button>
      </form>
    </div>
  </div>
</div>
