<div class="alert-card">
  <div class="alert-card-header">
    <div class="alert-date">
      {{alert.createdAt | date}}
    </div>
  </div>
  <div (click)="onDisplayFullAlert()" class="alert-card-content">
    <div class="row">
      <div class="col-sm-1 col-md-1 col-lg-1">
        <div class="outer-frame">
          <div class="frame">
            <img class="alert-image" src="assets/notifications/alert.png" alt="alerta">
          </div>
        </div>
      </div>
      <div class="col-sm-1 col-md-1 col-lg-11 alert-card-content">
        <h3 class="alert-title"> <mat-icon *ngIf="alert.leido === false" class="not-read-message-icon">info</mat-icon> {{'Alerts.alert'|translate}} </h3>
        <div class="alert-program">
          {{alert.aplicacion.programa.nombre}}
        </div>
        <div class="alert-message-preview">
          {{alert.mensaje | ellipsis:95}}
        </div>
      </div>
    </div>
  </div>
</div>
