<div class="student-card" (click)="onClickStudent()">
  <div>
    <img
      *ngIf="student.foto && student.fotovideo != 'video/mp4'"
      id="img-student"
      [src]="student.foto"
      alt="student photo" />
    <img
      *ngIf="!student.foto && !student.fotovideo"
      id="img-student"
      src="assets/utils/image-not-found.png"
      alt="student photo" />
    <video class="videoInit" *ngIf="student.foto && student.fotovideo == 'video/mp4'" controls>
      <source [src]="student.foto" type="video/mp4" />
    </video>
  </div>

  <div *ngIf="student.logoUniversidad.length > 0 && student.foto" class="university-content">
    <img id="img-university" [src]="student.logoUniversidad" alt="{{ student.universidad?.nombre }}" />
    <img
      *ngIf="student.universidad?.ciudad?.pais?.icono"
      id="img-city"
      [src]="student.universidad?.ciudad?.pais?.icono"
      alt="{{ student.universidad?.ciudad?.pais?.nombre }}" />
  </div>

  <div class="student-info" *ngIf="student">
    <p>
      <span style="font-weight: bold">Nombre:</span>
      {{ student.nombre }}
    </p>
    <p *ngIf="student.programa">
      <span style="font-weight: bold">Titulo:</span>
      {{ student.programa }}
    </p>
    <p *ngIf="student.descripcion">{{ student.descripcion | truncate : [200] }}</p>

    <footer class="button-container">
      <div>
        <button (click)="$event.stopPropagation(); onClickForm()">¡Hablemos!</button>
      </div>
    </footer>
  </div>
</div>
