import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  /** CONSTANTS */
  /** API route for retrieving information about applications */
  readonly APPLICATION_API = 'api/aplicacion/';
  /** API route to get platform payments by student */
  readonly U360_PAYMENT_API = 'api/pago-relacion/estudiante/';

  /** VARIABLES */
  /** Variable that holds the name of the program */
  programName = '';
  /** Variable that holds the name of the university */
  universityName = '';
  /** Variable that holds the status of the application */
  status = '';
  /** Variable that holds the steps an the information of each step */
  steps = [
    {
      name: 'Application-list.eligibility',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/elegibility.png',
      completado: 'false',
      data: {
        completado: false,
        fechaMaxima: '',
        fechaCompletado: '',
        terminosAceptados: false,
      },
      alt: 'elegibility',
    },
    {
      name: 'Application-list.payment-to-u360',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/payment.png',
      data: {},
      completado: 'false',
      alt: 'payment',
    },
    {
      name: 'Application-list.application-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/apply.png',
      completado: 'false',
      data: {
        formularioInformacion: {
          formulario: {},
          fechaMaxima: '',
          completado: false,
          fechaCompletado: '',
        },
        documentos: {
          pasaporte: '',
          pasaporteAprobado: false,
          diploma: '',
          diplomaAprobado: false,
          notas: '',
          notasAprobado: false,
          diplomaPregrado: '',
          diplomaPregradoAprobado: false,
          notasPregrado: '',
          notasPregradoAprobado: false,
          diplomaTraducido: '',
          diplomaAprobadoTraducido: false,
          notasTraducido: '',
          notasAprobadoTraducido: false,
          diplomaPregradoTraducido: '',
          diplomaPregradoAprobadoTraducido: false,
          notasPregradoTraducido: '',
          notasPregradoAprobadoTraducido: false,
          documentosAdicionales: [],
          fechaMaxima: '',
          completado: false,
          fechaCompletado: '',
        },
        feePagado: {
          comprobantePago: '',
          aceptacionTerminosTerceros: false,
        },
      },
      applicationFee: undefined,
      applicationFeeConfirmado: false,
      currency: '',
      alt: 'apply',
    },
    {
      name: 'Application-list.accepted-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/accepted.png',
      completado: 'false',
      data: {
        recepcionOferta: {
          offerLetter: '',
        },
        recepcionLOA: {
          LOA: '',
          aceptacionTerminosLOA: false,
        },
        pagoCupo: {
          comprobantePago: '',
        },
      },
      alt: 'accepted',
    },
    {
      name: 'Application-list.visa-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/visa.png',
      alt: 'visa',
      completado: 'false',
      data: {},
    },
    {
      name: 'Application-list.travel-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/travel.png',
      alt: 'travel',
      completado: 'false',
      data: {
        compraTiquetes: {
          ticket: '',
        },
        definicionAlojamiento: {
          comprobanteAlojamiento: '',
        },
        pagoAlojamiento: {
          comprobantePagoAlojamiento: '',
        },
      },
    },
    {
      name: 'Application-list.tuition-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/tuition.png',
      alt: 'tuition',
      completado: 'false',
      data: {
        paymentProof: '',
      },
    },
    {
      name: 'Application-list.enrolment-name',
      date: 'Application-list.deadline-not-assigned',
      img: 'assets/utils/steps/enrolment.png',
      alt: 'enrolment',
      completado: 'false',
      data: {},
    },
  ];

  /** Variable that signals which step is currently selected */
  selected = 0;

  /** Variable that holds the application id */
  applicationId: string;

  /** Variable that holds the userId of the student that applied to the program */
  studentId: String;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private ngxSpinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user._id;
      let applicationRequest = this.getApplication({ id: this.applicationId });
      const payments = applicationRequest.pipe(
        mergeMap((application) => {
          this.setApplication({ application });
          this.studentId = application.estudiante._id;
          return this.getPayments({ id: application.estudiante._id });
        })
      );
      this.ngxSpinner.show();
      payments.subscribe(
        (response) => {
          if (response === true) {
            this.steps[1].completado = 'true';
          }
        },
        (err) => {
          this.alertService.showError({ msg: this.translate.instant('Programs-list.success-create-information-err') });
          this.ngxSpinner.hide();
        },
        (complete?) => {
          this.ngxSpinner.hide();
        }
      );
    }
  }

  /** Handles the changes on step selection */
  onStepSelectChange({ event }): void {
    if (event < this.selected) {
      this.selected = event;
      return;
    }
    if (this.steps[this.selected].completado === 'true') {
      this.selected = event;
    }
  }

  /**
   * Retrieves the data of the application that has the given id from the backend
   * @param id application id
   */
  getApplication({ id }): Observable<any> {
    return this.apiService.get({ api: this.APPLICATION_API + id });
  }

  /**
   * Retrieves the data of all the platform payments by student
   * @param studentId
   */
  getPayments({ id }): Observable<any> {
    return this.apiService.get({ api: this.U360_PAYMENT_API + id + '/aplicacion/' + this.applicationId });
  }

  /**
   * Updates the UI binded values, so that the information is displayed to the user
   * @param application application data
   */
  setApplication({ application }): void {
    this.status = application.estado;
    this.programName = application.programa.nombre;
    this.universityName = application.programa.universidad.nombre;

    // Setup step 1 -- Elegibility
    this.steps[0].data = application.pasos.elibigilidad;
    const elegibilityMaxDate = application.pasos.elibigilidad.fechaMaxima;
    if (elegibilityMaxDate !== null && elegibilityMaxDate !== undefined) {
      this.steps[0].date = this.datePipe.transform(elegibilityMaxDate);
    } else {
      this.steps[0].date = "Application-list.deadline-not-assigned";
    }
    if (application.pasos.elibigilidad.completado === true) {
      this.steps[0].completado = 'true';
    } else {
      this.steps[0].completado = 'false';
    }

    // Setup step 2 -- Payment to U360
    const paymentMaxDate = application.pasos.payment.fechaMaxima;
    if (paymentMaxDate !== null && paymentMaxDate !== undefined) {
      this.steps[1].date = this.datePipe.transform(paymentMaxDate);
    } else {
      this.steps[1].date = "Application-list.deadline-not-assigned";
    }

    // Setup step 3 -- Apply
    if (
      application.pasos.submitAplicacion.aplicacionUniversidad.completado &&
      application.pasos.submitAplicacion.documentos.completado &&
      application.pasos.submitAplicacion.feePagado.completado &&
      application.pasos.submitAplicacion.formularioInformacion.completado
    ) {
      this.steps[2].completado = 'true';
    } else if (
      application.pasos.submitAplicacion.aplicacionUniversidad.completado === false &&
      application.pasos.submitAplicacion.documentos.completado === false &&
      application.pasos.submitAplicacion.feePagado.completado === false &&
      application.pasos.submitAplicacion.formularioInformacion.completado === false
    ) {
      this.steps[2].completado = 'false';
    } else {
      this.steps[2].completado = 'in-progress';
    }
    this.steps[2].data = application.pasos.submitAplicacion;
    const applyMaxDate = application.pasos.submitAplicacion.aplicacionUniversidad.fechaMaxima;
    if (applyMaxDate !== null && applyMaxDate !== undefined) {
      this.steps[2].date = this.datePipe.transform(applyMaxDate);
    } else {
      this.steps[2].date = "Application-list.deadline-not-assigned";
    }
    // 

    this.steps[2].applicationFee = application.applicationFee;
    this.steps[2].applicationFeeConfirmado = application.applicationFeeConfirmado;
    this.steps[2].currency = application.programa.universidad.ciudad.pais.moneda;

    // Setup step 4 -- beAccepted
    if (
      application.pasos.beAccepted.pagoCupo.completado &&
      application.pasos.beAccepted.recepcionLOA.completado &&
      application.pasos.beAccepted.recepcionOferta.completado
    ) {
      this.steps[3].completado = 'true';
    } else if (
      application.pasos.beAccepted.pagoCupo.completado === false &&
      application.pasos.beAccepted.recepcionLOA.completado === false &&
      application.pasos.beAccepted.recepcionOferta.completado === false
    ) {
      this.steps[3].completado = 'false';
    } else {
      this.steps[3].completado = 'in-progress';
    }
    this.steps[3].data = application.pasos.beAccepted;
    const acceptedMaxDate = application.pasos.beAccepted.pagoCupo.fechaMaxima;
    if (acceptedMaxDate !== null && acceptedMaxDate !== undefined) {
      this.steps[3].date = this.datePipe.transform(acceptedMaxDate);
    } else {
      this.steps[3].date = "Application-list.deadline-not-assigned";
    }

    // Setup step 5 -- VISA
    if (
      application.pasos.getYourVisa.cartaPresentacion.completado &&
      application.pasos.getYourVisa.citaVisa.completado &&
      application.pasos.getYourVisa.documentosEmbajada.completado &&
      application.pasos.getYourVisa.formularioVisa.completado &&
      application.pasos.getYourVisa.inicioDemostracionFondos.completado &&
      application.pasos.getYourVisa.recepcionVisa.completado &&
      application.pasos.getYourVisa.revisionDocumentos.completado &&
      application.pasos.getYourVisa.tomaBiometricos.completado
    ) {
      this.steps[4].completado = 'true';
    } else if (
      application.pasos.getYourVisa.cartaPresentacion.completado === false &&
      application.pasos.getYourVisa.citaVisa.completado === false &&
      application.pasos.getYourVisa.documentosEmbajada.completado === false &&
      application.pasos.getYourVisa.formularioVisa.completado === false &&
      application.pasos.getYourVisa.inicioDemostracionFondos.completado === false &&
      application.pasos.getYourVisa.recepcionVisa.completado === false &&
      application.pasos.getYourVisa.revisionDocumentos.completado === false &&
      application.pasos.getYourVisa.tomaBiometricos.completado === false
    ) {
      this.steps[4].completado = 'false';
    } else {
      this.steps[4].completado = 'in-progress';
    }
    this.steps[4].data = application.pasos.getYourVisa;
    const visaMaxDate = application.pasos.getYourVisa.recepcionVisa.fechaMaxima;
    if (visaMaxDate !== null && visaMaxDate !== undefined) {
      this.steps[4].date = this.datePipe.transform(visaMaxDate);
    } else {
      this.steps[4].date = "Application-list.deadline-not-assigned";
    }
    // Setup step 6 -- VISA
    if (
      application.pasos.prepareYourTravel.compraTiquetes.completado &&
      application.pasos.prepareYourTravel.definicionAlojamiento.completado &&
      application.pasos.prepareYourTravel.pagoAlojamiento.completado
    ) {
      this.steps[5].completado = 'true';
    } else if (
      application.pasos.prepareYourTravel.compraTiquetes.completado === false &&
      application.pasos.prepareYourTravel.definicionAlojamiento.completado === false &&
      application.pasos.prepareYourTravel.pagoAlojamiento.completado === false
    ) {
      this.steps[5].completado = 'false';
    } else {
      this.steps[5].completado = 'in-progress';
    }
    this.steps[5].data = application.pasos.prepareYourTravel;
    const prepareTraveMaxDate = application.pasos.prepareYourTravel.pagoAlojamiento.fechaMaxima;
    if (prepareTraveMaxDate !== null && prepareTraveMaxDate !== undefined) {
      this.steps[5].date = this.datePipe.transform(prepareTraveMaxDate);
    } else {
      this.steps[5].date = "Application-list.deadline-not-assigned";
    }
    // Setup step 7 -- Tuition
    if (application.pasos.tuitionPayment.completado) {
      this.steps[6].completado = 'true';
    } else {
      this.steps[6].completado = 'false';
    }
    this.steps[6].data = application.pasos.tuitionPayment;
    const tuitionPaymentMaxDate = application.pasos.tuitionPayment.fechaMaxima;
    if (tuitionPaymentMaxDate !== null && tuitionPaymentMaxDate !== undefined) {
      this.steps[6].date = this.datePipe.transform(tuitionPaymentMaxDate);
    } else {
      this.steps[6].date = "Application-list.deadline-not-assigned";
    }
    // Setup step 8
    if (
      application.pasos.enrolment.diaViaje.completado &&
      application.pasos.enrolment.fechaInicioCurso.completado &&
      application.pasos.enrolment.sesionDespedida.completado
    ) {
      this.steps[7].completado = 'true';
    } else if (
      application.pasos.enrolment.diaViaje.completado === false &&
      application.pasos.enrolment.fechaInicioCurso.completado === false &&
      application.pasos.enrolment.sesionDespedida.completado === false
    ) {
      this.steps[7].completado = 'false';
    } else {
      this.steps[7].completado = 'in-progress';
    }
    this.steps[7].data = application.pasos.enrolment;
    const enrolmentMaxDate = application.pasos.enrolment.fechaInicioCurso.fechaMaxima;
    if (enrolmentMaxDate !== null && enrolmentMaxDate !== undefined) {
      this.steps[7].date = this.datePipe.transform(enrolmentMaxDate);
    } else {
      this.steps[7].date = "Application-list.deadline-not-assigned";
    }
  }

  /**
   * Updates one of the main documents in the backend.
   * @param event event emitted by child component
   */
  updateDocument(event): void {
    const docName = event.docName;
    const url = event.url;
    const steps = {
      elibigilidad: this.steps[0].data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    switch (docName) {
      case 'passport':
        steps.submitAplicacion.documentos.pasaporte = url;
        break;
      case 'school-diploma':
        steps.submitAplicacion.documentos.diploma = url;
        break;
      case 'school-grades':
        steps.submitAplicacion.documentos.notas = url;
        break;
      case 'undergraduate-diploma':
        steps.submitAplicacion.documentos.diplomaPregrado = url;
        break;
      case 'undergraduate-grades':
        steps.submitAplicacion.documentos.notasPregrado = url;
        break;
      case 'school-diploma-translated':
        steps.submitAplicacion.documentos.diplomaTraducido = url;
        break;
      case 'school-grades':
        steps.submitAplicacion.documentos.notasTraducido = url;
        break;
      case 'undergraduate-diploma':
        steps.submitAplicacion.documentos.diplomaPregradoTraducido = url;
        break;
      case 'undergraduate-grades':
        steps.submitAplicacion.documentos.notasPregradoTraducido = url;
        break;
      case 'payment-proof':
        steps.submitAplicacion.feePagado.comprobantePago = url;
        break;
      case 'offer-letter':
        steps.beAccepted.recepcionOferta.offerLetter = url;
        break;
      case 'LOA':
        steps.beAccepted.recepcionLOA.LOA = url;
        break;
      case 'accepted-payment-proof':
        steps.beAccepted.pagoCupo.comprobantePago = url;
        break;
      case 'tuition-payment-proof':
        steps.tuitionPayment.paymentProof = url;
        break;
      case 'ticket':
        steps.prepareYourTravel.compraTiquetes.ticket = url;
        break;
      case 'accomodation-proof':
        steps.prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento = url;
        break;
      case 'accomodation-payment-proof':
        steps.prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento = url;
        break;
    }
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }

  /**
   * Updates the list of additional documents in the backend
   * @param event Event that was emitted by child component
   */
  updateAdditionalDocuments(event): void {
    const docList = event.documentList;
    const steps = {
      elibigilidad: this.steps[0].data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    steps.submitAplicacion.documentos.documentosAdicionales = docList;
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }

  /**
   * Updates an application in the backend
   * @param data data that is going to be updated
   */
  updateApplication({ data }: { data: any }): void {
    this.ngxSpinner.show();
    this.apiService.put({ api: this.APPLICATION_API + this.applicationId, data }).subscribe(
      (response) => {
        if (response.success) {
          this.alertService.showSuccess({ msg: this.translate.instant('Programs-list.success-create-information') });
          this.getApplication({ id: this.applicationId }).subscribe((response) => {
            this.setApplication({ application: response });
          });
        } else {
          this.alertService.showError({ msg: response.msg });
        }
      },
      (err) => {
        this.alertService.showError({ msg: this.translate.instant('Programs-list.success-create-information-err') });
        this.ngxSpinner.hide();
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  /**
   * Updates the general information form in the backend.
   * @param event event that is triggered by the child component
   */
  updateGeneralInformationForm(event): void {
    const form = event.formValue;
    const steps = {
      elibigilidad: this.steps[0].data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    steps.submitAplicacion.formularioInformacion.formulario = form;
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }

  /**
   * Signals that the student accepted the terms and conditions of the LOA
   */
  onMarkLOAAsRead(): void {
    const steps = {
      elibigilidad: this.steps[0].data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    steps.beAccepted.recepcionLOA.aceptacionTerminosLOA = true;
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }

  /**
   * Signals that the student accepted the payment to third party steps
   */
  onThirdPartyTermsAccepted(): void {
    const steps = {
      elibigilidad: this.steps[0].data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    steps.submitAplicacion.feePagado.aceptacionTerminosTerceros = true;
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }

  /**
   * Updates only elegibility object from event
   * @param event containing new object
   */
  updateElegibilidad(event): void {
    const steps = {
      elibigilidad: event.data,
      submitAplicacion: this.steps[2].data,
      beAccepted: this.steps[3].data,
      getYourVisa: this.steps[4].data,
      prepareYourTravel: this.steps[5].data,
      tuitionPayment: this.steps[6].data,
      enrolment: this.steps[7].data,
    };
    const data = {
      pasos: steps,
    };
    this.updateApplication({ data });
  }
}
