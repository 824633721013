<main class="component-container">
    <div class="row col-xl-12 tests-container display-mobile-none">
        <div class="test-icons-container">
            <img class="test-icons" src="/assets/pre-test/world.png" alt="match">
            <p class="tests-labels">Cultura<br>
                match
            </p>
        </div>

        <div class="test-icons-container">
            <img class="test-icons" src="/assets/pre-test/test.png" alt="english">
            <p class="tests-labels">English<br>
                Test
            </p>
        </div>

        <div class="test-icons-container">
            <img class="test-icons" src="/assets/pre-test/world.png" alt="IA">
            <p class="tests-labels">IA<br>
                Skills
            </p>
        </div>

        <div class="test-icons-container">
            <img class="test-icons" src="/assets/pre-test/visa.png" alt="visa">
            <p class="tests-labels">Visa<br>
                Test
            </p>
        </div>

        <div style="align-self: center;">
            <label class="tests-desc">Completa tus Test U360 para un match más exacto.</label>
        </div>
    </div>

    <div class="col-xl-12 pb-1">
        <h2 class="title-section">Afinidad de instituciones</h2>
    </div>


  <div class="row col-xl-12 tests-container display-mobile">
    <div style="align-self: center;">
      <label class="tests-desc pr-3">Completa tus Test U360 para un match más exacto.</label>
    </div>

    <div class="test-icons-container">
      <img class="test-icons" src="/assets/pre-test/world.png" alt="match">
      <p class="tests-labels">Cultura<br>
        match
      </p>
    </div>

    <div class="test-icons-container">
      <img class="test-icons" src="/assets/pre-test/test.png" alt="english">
      <p class="tests-labels">English<br>
        Test
      </p>
    </div>

    <div class="test-icons-container">
      <img class="test-icons" src="/assets/pre-test/world.png" alt="IA">
      <p class="tests-labels">IA<br>
        Skills
      </p>
    </div>

    <div class="test-icons-container">
      <img class="test-icons" src="/assets/pre-test/visa.png" alt="visa">
      <p class="tests-labels">Visa<br>
        Test
      </p>
    </div>
  </div>

    <div class="col-xl-12" *ngIf="userFavourites; else noresult">
        <div *ngFor="let program of userFavourites" class="mb-3">
            <app-program-wishlist-card [program]="program" (reloadWishlist)="ngOnInit()"></app-program-wishlist-card>
        </div>
    </div>

    <ng-template #noresult>
        <div class="col-xl-12">
            <p>No has añadido programas favoritos... ¡Añádelos!
                <!-- <button></button> -->
            </p>
        </div>
    </ng-template>

</main>
