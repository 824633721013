<div class="elegibility-content">
    <p class="elegibility-text" *ngIf="!elibigility.completado">
      {{ 'Elegibility.verifying-fulfill-requirements' | translate }}
    </p>
    <p class="elegibility-text" *ngIf="elibigility.completado">
      {{ 'Elegibility.apply-program' | translate }}
    </p>
    <div class="row-center">
      <input type="checkbox" [disabled]="cantEdit" (change)="onChangeCheckbox($event.target.value)" [(ngModel)]="elibigility.terminosAceptados" class="checkbox"><p class="terms-text">{{ 'Elegibility.accept-terms-no-clickable' | translate}}<a class="link" (click)="openTerms()">{{ 'Elegibility.accept-terms-clickable' | translate}}</a></p>
    </div>
</div>
