import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-similar-programs',
  templateUrl: './similar-programs.component.html',
  styleUrls: ['./similar-programs.component.scss']
})
export class SimilarProgramsComponent implements OnInit {

  /** API */

  /** Constant that has the api path for programs */
  readonly PROGRAM_API = 'api/programa/';

  /** Variable that contains the similar programs */
  similarPrograms = [];

  @Input() program?: any;
  @Input() isUniversity?: boolean = false;

  constructor(private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslateService,) { }

  ngOnInit(): void {
    if (this.cookieService.get('programs')) {
      let progs = JSON.parse(this.cookieService.get('programs'));
      this.getProgramsAux({ programs: progs });
    }
  }

  public getProgramsAux({ programs }) {
    this.similarPrograms = [];
    for (let index = 0; index < programs.length; index++) {
      this.getSimilarProgram({ id: programs[index] });
    }
  }

  /**
   * Retrieves the programs from the backend
   */
  public getSimilarProgram({ id }): void {
    let dynamicAPI = ''
    if(this.isUniversity){
      dynamicAPI = this.PROGRAM_API + 'universidad/';
      this.apiService.get({ api: `${dynamicAPI}${id}` }).subscribe(
        (response) => {
          const index = Math.floor(Math.random() * response.length + 1)
          const randomProgram = response[index];
          if (this.program && randomProgram) {
            if (randomProgram.slug != this.program.slug) {
              this.similarPrograms.push(randomProgram);
            }
          } else if (randomProgram) {
            this.similarPrograms.push(randomProgram);
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Programs-list.there-problem-querying'),
          });
        }
      );
    } else {
      dynamicAPI = this.PROGRAM_API
      this.apiService.get({ api: `${dynamicAPI}${id}` }).subscribe(
        (response) => {
          if (this.program && response) {
            if (response.slug != this.program.slug) {
              this.similarPrograms.push(response);
            }
          } else if (response) {
            this.similarPrograms.push(response);
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Programs-list.there-problem-querying'),
          });
        }
      );
    }
  }

}
