<div class="invoice-container">
  <div class="invoice-header">
    {{ "Invoice.summary" | translate }}
  </div>
  <div class="invoice-content">
    <div class="invoice-row">
      <div class="invoice-item"> {{ "Invoice.subtotal" | translate }} </div>
      <div class="invoice-number"> ${{subtotal | number : '1.2-2'}} USD </div>
    </div>
    <div class="invoice-row">
      <div class="invoice-item"> {{ "Invoice.tax" | translate }} </div>
      <div class="invoice-number"> ${{tax | number : '1.2-2'}} USD </div>
    </div>
    <div class="invoice-row">
      <div class="invoice-item"> {{ "Invoice.total" | translate }} </div>
      <div class="invoice-number"> ${{total | number : '1.2-2' }} USD </div>
    </div>
    <div class="invoice-footer">
      <button *ngIf="pageStatus === 0" (click)="onContinueClick()" class="continue-button">{{ "Invoice.continue" |
        translate }}</button>
    </div>
  </div>
</div>
