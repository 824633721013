<div class="header">
  <img (click)="handleBackClick()" src="assets/utils/back.png" class="back-button" alt="go back">
</div>
<div>

  <select *ngIf="isRecruitment" class="student-select" [(ngModel)]="studentId" >
    <option disabled value=""> Select the student </option>
    <option *ngFor="let student of students" [value]="student._id">{{student.nombre}} {{student.apellido}}</option>
  </select>
</div>
<div class="row" *ngIf="payment_type == ''">
  <div class="col-md-6 text-center">
    <div class="payment-type" (click)="payment_type = 'TC'">
      <img src="assets/payment-icons/payu-icon.png" class="img-payu" alt="pay with credit card">
      {{ "Payment-info.pagar-con-tc" | translate }}
    </div>
  </div>
  <div class="col-md-6 text-center">
    <div class="payment-type" (click)="getBanks()">
      <img src="assets/payment-icons/pse-icon.png" class="img-pse" alt="pay with pse">
      {{ "Payment-info.pagar-con-pse" | translate }}
    </div>
  </div>
</div>
<div *ngIf="payment_type == 'PSE'">
  <app-payment-info-pse [banksPse]="banksPse" [refCode]="refCode" [subtotal]="subtotal"></app-payment-info-pse>
</div>
<div class="payment-information-form" *ngIf="payment_type == 'TC'">
  <form [formGroup]="paymentInformationForm" (ngSubmit)="onSubmitClick()">
    <h4> {{ "Payment-info.delivery-info" | translate }}</h4>
    <div class="sub-form" formGroupName="informacionDeEnvio">
      <div class="row">
        <div class="col-sm-6">
          <label for="address"> {{ "Payment-info.address" | translate }} </label>
          <input class="full-width form-input" id="address" type="text" formControlName="direccion"
            placeholder="Address">
        </div>
        <div class="col-sm-6">
          <label for="country"> {{ "Payment-info.country" | translate }} </label>
          <input class="full-width form-input" id="country" type="text" formControlName="pais" placeholder="Country">
        </div>
        <div class="col-sm-6">
          <label for="state"> {{ "Payment-info.state" | translate }} </label>
          <input class="full-width form-input" id="state" type="text" formControlName="estado" placeholder="State">
        </div>
        <div class="col-sm-6">
          <label for="city"> {{ "Payment-info.city" | translate }} </label>
          <input class="full-width form-input" id="city" type="text" formControlName="ciudad" placeholder="State">
        </div>
        <div class="col-sm-6">
          <label for="post-code"> {{ "Payment-info.postal-code" | translate }} </label>
          <input class="full-width form-input" id="post-code" type="text" formControlName="codigoPostal"
            placeholder="Postal code">
        </div>
        <div class="col-sm-6">
          <label for="delivery-telephone"> {{ "Payment-info.telephone" | translate }} </label>
          <input class="full-width form-input" id="delivery-telephone" type="tel" formControlName="telefono"
            placeholder="Telephone number">
        </div>
      </div>
    </div>
    <h4> {{ "Payment-info.personal-info" | translate }} </h4>
    <div class="sub-form" formGroupName="informacionPersonal">
      <div class="row">
        <div class="col-sm-6">
          <label for="name"> {{ "Payment-info.name" | translate }} </label>
          <input class="full-width form-input" id="name" type="text" formControlName="nombreCompleto"
            placeholder="Full name">
        </div>
        <div class="col-sm-6">
          <label for="email"> {{ "Payment-info.email" | translate }} </label>
          <input class="full-width form-input" id="email" type="email" formControlName="email" placeholder="E-mail">
        </div>
        <div class="col-sm-6">
          <label for="personal-telephone"> {{ "Payment-info.telephone" | translate }} </label>
          <input class="full-width form-input" id="personal-telephone" type="tel" formControlName="telefono"
            placeholder="Telephone number">
        </div>
        <div class="col-sm-6">
          <label for="id-number"> {{ "Payment-info.id-number" | translate }} </label>
          <input class="full-width form-input" id="id-number" type="text" formControlName="numeroDeIdentificacion"
            placeholder="ID number">
        </div>
      </div>
    </div>
    <h4> {{ "Payment-info.payment-info" | translate }} </h4>
    <h5 *ngIf="formerCreditCards.length > 0"> {{ "Payment-info.saved-payment-methods" | translate }} </h5>
    <div class="row saved-credit-cards">
      <div (click)="onUseSavedCreditCard({index: i})" [ngClass]="selectedCreditCard === i? 'credit-card-selected':''"
        class="col-sm-4 credit-card-container" *ngFor="let creditCard of formerCreditCards; index as i">
        <mat-icon class="credit-icon">{{ "Payment-info.credit-card" | translate }} </mat-icon>
        <div class="saved-card-franchise"> {{creditCard.franquicia | uppercase}} </div>
        <div class="saved-card-number">**** **** **** {{creditCard.ultimos}}</div>
      </div>
      <div (click)="onAddCreditCard()" [ngClass]="selectedCreditCard === -1? 'credit-card-selected':''"
        class="col-sm-2 add-credit-card">
        <div class="add-credit-card-text">{{ "Payment-info.add-cc" | translate }} </div>
        <div class="add-credit-card-plus">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div>
    <div class="sub-form" *ngIf="paymentInformationForm.get('informacionTarjeta')!== null"
      formGroupName="informacionTarjeta">
      <h5>{{ "Payment-info.add-new-cc" | translate }} </h5>
      <p> {{ "Payment-info.please-fill" | translate }} </p>
      <div class="row">
        <div class="col-sm-6">
          <label for="fullCardName"> {{ "Payment-info.name" | translate }} </label>
          <input class="full-width form-input" id="fullCardName" type="text" formControlName="fullCardName"
            placeholder="Full name">
        </div>
        <div class="col-sm-6">
          <label for="idNumber"> {{ "Payment-info.id-number" | translate }} </label>
          <input class="full-width form-input" id="idNumber" type="text" formControlName="idNumber"
            placeholder="Id number">
        </div>
        <div class="col-sm-6">
          <label for="cc-number"> {{ "Payment-info.credit-card" | translate }} </label>
          <input class="full-width form-input" id="cc-number" minlength="15" maxlength="19" name="cc-number" type="tel"
            #ccNumber="ccNumber" formControlName="cardNumber" autocomplete="cc-number" placeholder="Credit card number"
            ccNumber>
          <div class="scheme"> {{ "Payment-info.brand" | translate }} <span
              #creditCardFranchise>{{ccNumber.resolvedScheme$ | async | uppercase}}</span>
          </div>
        </div>
        <div class="col-sm-6">
          <label for="expDate"> {{ "Payment-info.exp-date" | translate }} </label>
          <input class="full-width form-input" id="expDate" type="tel" formControlName="expDate" placeholder="05 / 2022"
            autocomplete="cc-exp" ccExp>
        </div>
      </div>
    </div>
    <div class="submit-section">
      <button id="submit-button" type="submit" [disabled]="paymentInformationForm.invalid || studentId === ''"> {{
        "Payment-info.make-payment" | translate }} </button>
    </div>
  </form>
</div>
