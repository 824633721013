<div class="my-budget">
  <div class="my-budget-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back" />
    <h3 class="budget-title">{{ 'My-budget.budget' | translate }}</h3>
  </div>
  <div class="budget-subtitle">
    {{ 'My-budget.tell-us' | translate }}
  </div>
  <div class="budget-question">
    {{ 'My-budget.what-budget' | translate }}
    <br />
    {{ 'My-budget.it-should-include' | translate }}
  </div>

  <div class="finance">
    <label class="financing-label" for="financing-method">{{ 'My-budget.financing' | translate }}</label>
    <br />
    <ng-multiselect-dropdown
      id="financing-method"
      [settings]="dropdownSettings"
      [data]="financingOptionsDropdownList"
      [value]="financingType"
      [(ngModel)]="financialData"
      [placeholder]="'My-budget.financing-method' | translate"
      (onSelect)="onFinancingMethodSelect($event)"></ng-multiselect-dropdown>
  </div>

  <div class="finance-slider">
    <ngx-slider
      class="custom-slider"
      *ngIf="isBrowser"
      [(value)]="minBudget"
      [(highValue)]="maxBudget"
      [options]="budgetSliderOptions"></ngx-slider>
  </div>
  <button *ngIf="!loading" (click)="onSaveChanges()" id="save-changes">
    {{ 'My-budget.save-changes' | translate }}
  </button>
  <mat-spinner *ngIf="loading" diameter="40"></mat-spinner>
</div>
