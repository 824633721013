<div class="platform-payment-content">
  <p *ngIf="!platformPayment.completado">
    Para continuar, debes pagar el fee de la plataformaque incluirá un asesor experto y especializado en cada parte de tu proyecto:<br><br>
    - Consultor en educación internacional.<br>
    - Asesor de admisiones.<br>
    - Guía especializada en estrategia y proceso de visa.<br>
    - Asesor en preparación de viaje.<br><br>
    Planeamos a detalle de tu paso a paso, <strong>para maximizar probabilidad de éxito de aceptación</strong><br><br>
    *El equipo de expertos te contactarán para una reunión personalizada donde se explicará a detalle todo lo que se incluye en el acompañamiento y lo límites, y establecer tu línea de tiempos personalizada.<br><br>
    <strong>Instrucciones de pago:</strong><br>
    Para realizarlo tienes dos opciones:<br>
    1. Pagar en línea (Tarjeta de Crédito o Débito PSE).<br>
    2. Realizar una transferencia bancaria (y subir comprobante) a la cuenta XXX  Bancolombia. Higher Education…<br><br>
    * Si necesitas ayuda ponte en contacto con tu Asesor de Registros.
  </p>
  <p *ngIf="platformPayment.completado"> {{ 'Payment.payed-text' | translate }} </p>
  <span *ngIf="!platformPayment.completado">
    <button (click)="onMakePayment()" id="make-payment-button">{{"Payment.pay-button" | translate}}</button>
    <span> {{"Payment.or" | translate}} </span>
    <label id="upload-payment-proof-label" for="upload-payment-proof">{{"Payment.upload-proof" | translate}}</label>
    <input id="upload-payment-proof" type="file" (change)="onUploadPaymentProof({event: $event})">
  </span>
</div>
