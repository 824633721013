<div class="row">
  <div class="col-12">
    <div class="card-content">
      <div class="first-content row">
        <div *ngIf="showMoreData.video" class="detalle-video col-lg-6" id="detalle-video">
          <iframe
            width="560"
            height="315"
            [src]="getSafeVideoUrl(showMoreData.video)"
            frameborder="0"
            allowfullscreen></iframe>
        </div>

        <div class="card cardinfo" id="detalle-informacion">
          <h5 class="skillName">{{ showMoreData.name }}</h5>
          <p>{{ showMoreData.description }}</p>
        </div>
        <div *ngIf="showMoreData.requirements" class="card cardinfo" id="detalle-requisitos">
          <h5 class="skillName">Requisitos generales admisión:</h5>
          <ul *ngFor="let item of showMoreData.requirements">
            <li>
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
