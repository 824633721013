<div class="privacy">
  <h3 style="font-size: 24px; text-align: center; font-weight: bold;">POLÍTICA DE PRIVACIDAD</h3>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
    <strong
      ><span style="font-size: 16px;"
        >Higher Education Consulting S.A.S.&nbsp;</span
      ></strong
    ><span style="font-size: 16px;"
      >sociedad &nbsp;debidamente constituida y existente de conformidad con las
      leyes de la Rep&uacute;blica de Colombia e identificada con NIT
      901.255.403-8 y cualquiera de sus afiliados (en adelante
      &ldquo;<u>HEC</u>&rdquo;),&nbsp;</span
    ><span style="font-size: 16px;"
      >informa a todos sus grupos de inter&eacute;s que cuenta con una
      Pol&iacute;tica de Protecci&oacute;n y Tratamiento de Datos Personales (en
      adelante la &ldquo;<u>Pol&iacute;tica de Privacidad</u
      >&rdquo;).&nbsp;</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
    <span style="font-size: 16px;"
      >El presente Aviso de Privacidad (en adelante el
      &ldquo;<u>Aviso</u>&rdquo;) establece los t&eacute;rminos y condiciones en
      virtud de los cuales <strong>HEC</strong> realizar&aacute; el Tratamiento
      de Datos Personales contenidos en sus registros, archivos o Bases de
      Datos.&nbsp;</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
    <span style="font-size: 16px;"
      >La informaci&oacute;n y Datos Personales suministrados a
      <strong>HEC</strong> podr&aacute;n ser objeto de almacenamiento, uso,
      transmisi&oacute;n, transferencia, circulaci&oacute;n,
      actualizaci&oacute;n y supresi&oacute;n, de acuerdo con lo establecido en
      la Pol&iacute;tica de Privacidad, seg&uacute;n sean aplicables y
      principalmente para las finalidades descritas tanto en el formato de
      autorizaci&oacute;n como en la Pol&iacute;tica de Privacidad.&nbsp;</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
      text-indent: 0.25in;
    "
  >
    <span style="font-size: 16px;"
      >Como Titular de sus Datos Personales usted tiene derecho a:</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="1"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style="font-size: 12pt"
          >Acceder de forma gratuita a los datos proporcionados que hayan sido
          objeto de tratamiento;</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px;"
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="2"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 10px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style="font-size: 12pt"
          >Conocer, actualizar y rectificar su informaci&oacute;n frente a datos
          parciales, inexactos, incompletos, fraccionados, que induzcan a error,
          o aquellos cuyo tratamiento est&eacute; prohibido o no haya sido
          autorizado;</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px; "
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="3"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style="font-size: 12pt"
          >Solicitar prueba de la autorizaci&oacute;n otorgada;</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px; "
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="4"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style=" font-size: 12pt"
          >Presentar ante la Superintendencia de Industria y Comercio (SIC)
          quejas por infracciones a lo dispuesto en la normatividad
          vigente;</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px;"
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="5"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style=" font-size: 12pt"
          >Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del
          dato, siempre que no exista un deber legal o contractual que impida
          eliminarlos;&nbsp;</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px; "
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="6"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style="font-size: 12pt"
          >Abstenerse de responder las preguntas sobre datos sensibles.
          Tendr&aacute; car&aacute;cter facultativo las respuestas que versen
          sobre datos sensibles o sobre datos de las ni&ntilde;as y ni&ntilde;os
          y adolescentes; y</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 87pt;
      line-height: normal;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span style="font-size: 16px;"
      >&nbsp;</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      start="7"
      style="
        margin-bottom: 0in;
        list-style-type: lower-alpha;
        margin-left: 5px;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <span style="font-size: 12pt"
          >Dem&aacute;s derechos que se establezcan en el art&iacute;culo 8 de
          la Ley 1581 de 2012 y el cap&iacute;tulo 25 del Decreto 1074 de
          2015.</span
        >
      </li>
    </ol>
  </div>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
      text-align: justify;
    "
  >
    <span
      style="
        font-size: 16px;
        line-height: 115%;
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
    <span
      style="
        font-size: 16px;
        line-height: 115%;
      "
      >Los derechos anteriormente se&ntilde;alados s&oacute;lo podr&aacute;n ser
      ejercidos por las siguientes personas: i) Por el Titular, quien
      deber&aacute; acreditar su identidad en forma suficiente; ii) Por sus
      causahabientes, quienes deber&aacute;n acreditar tal calidad; iii) Por el
      representante y/o apoderado del Titular, previa acreditaci&oacute;n de la
      representaci&oacute;n o apoderamiento y; iv) Por estipulaci&oacute;n a
      favor de otro o para otro.</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
    <strong
      ><span
        style="
          font-size: 16px;
          line-height: 115%;
        "
        >HEC</span
      ></strong
    ><span
      style="
        font-size: 16px;
        line-height: 115%;
      "
      >&nbsp;tratar&aacute; sus Datos Personales para las siguientes finalidades
      y aquellas que se describan en la Pol&iacute;tica de Privacidad y en sus
      modificaciones:&nbsp;</span
    ><span
      style="
        font-size: 16px;
        line-height: 115%;
      "
      >gesti&oacute;n de clientes, gesti&oacute;n administrativa,
      prospecci&oacute;n comercial, fidelizaci&oacute;n de clientes, marketing,
      publicidad propia, el env&iacute;o de comunicaciones comerciales sobre
      productos y campa&ntilde;as de actualizaci&oacute;n de datos e
      informaci&oacute;n de cambios en el tratamiento de datos personales;
      asimismo, para compartir dicha informaci&oacute;n con aliados
      estrat&eacute;gicos y para dar cumplimiento a las obligaciones
      establecidas en los contratos que&nbsp;</span
    ><strong
      ><span
        style="
          font-size: 16px;
          line-height: 115%;
        "
        >HEC</span
      ></strong
    ><span
      style="
        font-size: 16px;
        line-height: 115%;
      "
      >&nbsp;suscriba con terceros, as&iacute; como para el cumplimiento de
      cualquier disposici&oacute;n legal, sea esta de car&aacute;cter laboral,
      tributaria, comercial, aduanera, entre otros.</span
    >
  </p>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
      text-align: justify;
      text-indent: 35.4pt;
    "
  >
  </p>
  <h5>Ver también: <a routerLink="/personal-data-policy">Política de protección y tratamiento de datos personales</a></h5>
</div>
