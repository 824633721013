import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ReusableFormComponent } from './reusableForm/reusableForm.component';

@Component({
  selector: 'app-initial-form',
  templateUrl: './initialForm.component.html',
  styleUrls: ['./initialForm.component.scss'],
})
export class InitialFormComponent implements OnInit {
  readonly getInitialForm = 'api/getInitialDataFormById/';
  user = JSON.parse(localStorage.getItem('user'));
  formData: any;

  @ViewChild(ReusableFormComponent) reusableFormComponent: ReusableFormComponent;
  @Output() dataCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private apiService: ApiService, public dialogRef: MatDialogRef<InitialFormComponent>) {}

  ngOnInit(): void {
    const url = `${this.getInitialForm}${this.user._id}`;
    this.apiService.get({ api: url }).subscribe(
      (response) => {
        this.formData = response;
      },
      (error) => {
        console.error('Error fetching initial data:', error);
      }
    );
  }

  async saveChanges() {
    if (this.reusableFormComponent) {
      await this.reusableFormComponent.submitData('submitForm');
      this.dataCompleted.emit(this.formData.isPublished);
    }
  }
}
