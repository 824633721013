const questionary = {
  Liderazgo: [
    {
      question: 'Al utilizar mis capacidades y habilidades en el desarrollo de actividades y tareas',
      answers: {
        answer1: {
          answer: 'Establezco canales de comunicación con el grupo',
          points: 2,
          analysis:
            'Logra establecer canales de comunicación con el grupo en el desarrollo de actividades y tareas, puede llegar a ser orientador.',
        },
        answer2: {
          answer: 'Oriento al grupo para obtener los resultados',
          points: 3,
          analysis:
            'Logra expresar con éxito sus capacidades y habilidades en el desarrollo de actividades y tareas, orientando al grupo para obtener resultados.',
        },
        answer3: {
          answer: 'Superviso periódicamente los avances del grupo',
          points: 1,
          analysis:
            'Logra implementar actividades de supervisión para ver los avances del grupo en el desarrollo de actividades y tareas, puede llegar a ser un buen orientador, estableciendo eficientes canales de comunicación',
        },
      },
    },
    {
      question:
        'Al momento de pertenecer a grupos en la iglesia, escuela, mi familia o en la comunidad en los que puedo participar en actividades relacionadas con lo que quisiera estudiar',
      answers: {
        answer1: {
          answer: 'Propongo objetivos prácticos que logran inspirar a los miembros del grupo',
          points: 3,
          analysis:
            'Pertenece a grupos, asociaciones, o comunidades relacionadas con su campo de interés, siendo inspiración.',
        },
        answer2: {
          answer: 'Dirijo al grupo hacía el logro de los objetivos definidos, retroalimentándolos',
          points: 2,
          analysis:
            'Participa en grupos, asociaciones, o comunidades relacionadas con su campo de interés, facilitando el logro de objetivos, brindando retroalimentación, puede llegar a ser inspiración',
        },
        answer3: {
          answer: 'Puedo desarrollar actividades en el grupo realizando seguimiento de estas',
          points: 1,
          analysis:
            'Participa en grupos, asociaciones, o comunidades relacionadas con su campo de interés, realizando seguimiento, puede llegar a ser inspiración.',
        },
      },
    },
    {
      question: 'Para lograr objetivos que tengan impacto en mi comunidad',
      answers: {
        answer1: {
          answer: 'Desarrollo actividades como lo sé hacer sintiéndome confiado',
          points: 1,
          analysis:
            'Desarrolla actividades como sabe hacerlo sintiéndose confiado, puede llegar a ser ejemplo de valores personales adoptando nuevos hábitos.',
        },
        answer2: {
          answer: 'Realizo cambios cuando necesito ganarme la confianza del grupo',
          points: 2,
          analysis:
            'Realiza cambios cuando necesita ganarse la confianza del grupo, puede llegar a ser ejemplo de valores personales.',
        },
        answer3: {
          answer: 'Adopto hábitos que me permiten ser ejemplo de valores personales',
          points: 3,
          analysis:
            'Adopta hábitos para lograr objetivos con alto impacto social o ambiental, siendo ejemplo de valores personales.',
        },
      },
    },
    {
      question: 'Tengo claro lo que voy a estudiar en el futuro porque',
      answers: {
        answer1: {
          answer: 'Comprendo cómo puedo generar mejoras en mi entorno',
          points: 3,
          analysis: 'Comprende cómo puede mejorar su entorno trabajando desde mi campo de interés.',
        },
        answer2: {
          answer: 'Sé que puedo generar cambios en diferentes ámbitos',
          points: 2,
          analysis: 'Sabe que puede generar cambios en diferentes ámbitos, trabajando desde su campo de interés.',
        },
        answer3: {
          answer: 'Aprendo cosas nuevas e importantes de mi entorno',
          points: 1,
          analysis:
            'Aprende cosas nuevas e importantes de su entorno que le permitirán generar cambios al trabajar desde su campo de interés.',
        },
      },
    },
    {
      question: 'Cuando trabajo en equipo',
      answers: {
        answer1: {
          answer: 'Tiendo a realizar seguimiento brindando feedback a los demás',
          points: 2,
          analysis: 'Tiende a realizar seguimiento brindando feedback a los demás, puede llegar a ser cooperativo.',
        },
        answer2: {
          answer: 'Decido cooperar, compartiendo recursos y conocimientos',
          points: 3,
          analysis: 'Establece relaciones cooperativas, compartiendo recursos y conocimientos.',
        },
        answer3: {
          answer: 'Recomiendo acciones al grupo desde mi punto de vista',
          points: 1,
          analysis: 'Recomienda acciones al grupo desde su punto de vista, puede llegar a ser cooperativo.',
        },
      },
    },
    {
      question: 'A la hora de tomar decisiones de grupo',
      answers: {
        answer1: {
          answer: 'Valoro las recomendaciones y propuestas de los demás sobre las propias',
          points: 2,
          analysis:
            'A la hora de tomar decisiones de grupo valora las recomendaciones y propuestas de los demás sobre las propias, puede aprender a evaluar el impacto de las alternativas.',
        },
        answer2: {
          answer: 'Elijo mi criterio personal basándome en mi experiencia para evitar errores',
          points: 1,
          analysis:
            'A la hora de tomar decisiones de grupo, elige su criterio personal basándose en su experiencia para evitar errores, puede aprender a tener en cuenta las propuestas de los demás y evaluar el impacto de las mismas.',
        },
        answer3: {
          answer: 'Evalúo el impacto de las alternativas para que sean tenidas en cuenta',
          points: 3,
          analysis:
            'A la hora de tomar decisiones de grupo evalúa el impacto de las alternativas para que sean tenidas en cuenta.',
        },
      },
    },
    {
      question: 'Para alcanzar objetivos de alto impacto',
      answers: {
        answer1: {
          answer: 'Elaboro los planes y los llevo a cabo con el grupo',
          points: 3,
          analysis: 'Elabora planes y los ejecuta con grupos de apoyo.',
        },
        answer2: {
          answer: 'Diseño los planes y hago seguimiento al grupo',
          points: 2,
          analysis: 'Diseña los planes y hace seguimiento al grupo, puede aprender a ejecutarlos con el grupo.',
        },
        answer3: {
          answer: 'Cumplo los planes y soy cooperativo con el grupo',
          points: 1,
          analysis: 'Cumple los planes y es cooperativo con el grupo, puede aprender a elaborar los planes.',
        },
      },
    },
    {
      question: 'Al momento de motivar a los demás hacia el cumplimiento de objetivos comunes',
      answers: {
        answer1: {
          answer: 'Reconozco sus habilidades inspirándolos a la acción contínua',
          points: 3,
          analysis: 'Motiva a los grupos reconociendo las habilidades de los demás.',
        },
        answer2: {
          answer: 'Realizo seguimiento brindando retroalimentación a los integrantes',
          points: 2,
          analysis:
            'Realiza seguimiento brindando retroalimentación a los integrantes, puede aprender a ser motivador.',
        },
        answer3: {
          answer: 'Establezco puntos de control ayudando a superar dificultades',
          points: 1,
          analysis:
            'Establece puntos de control ayudando a superar dificultades, puede aprender a reconocer a los demás y ser motivador.',
        },
      },
    },
    {
      question: 'Para establecer relaciones de confianza',
      answers: {
        answer1: {
          answer: 'Retroalimento a los demás sobre sus errores y fortalezas',
          points: 2,
          analysis: 'Retroalimenta a los demás sobre sus errores y fortalezas, puede aprender a crear confianza.',
        },
        answer2: {
          answer: 'Hago seguimiento contínuo a las acciones de los demás',
          points: 1,
          analysis: 'Inspira a los demás hacia una visión común que fomente la confianza.',
        },
        answer3: {
          answer: 'Inspiro a los demás hacia una visión común que la fomente',
          points: 3,
          analysis: 'Inspira a los demás hacia una visión común que fomente la confianza.',
        },
      },
    },
  ],
  InteligenciaEmocional: [
    {
      question: 'Cuando una persona siente tristeza, frustración o enojo, tiendo a',
      answers: {
        answer1: {
          answer: 'Ayudarla',
          points: 2,
          analysis: 'Ayuda a los demás cuando sienten tristeza, frustración o enojo.',
        },
        answer2: {
          answer: 'Escucharla',
          points: 1,
          analysis:
            'Escucha a los demás cuando sienten tristeza, frustración o enojo, puede aprender a prestarles ayuda.',
        },
        answer3: {
          answer: 'Evitarla',
          points: 3,
          analysis:
            'Evita a los demás cuando sienten tristeza, frustración o enojo, puede aprender a escucharlos y prestarles ayuda.',
        },
      },
    },
    {
      question: 'Cuando siento tristeza o alegría,',
      answers: {
        answer1: {
          answer: 'Me expreso comprendiendo su efecto en los demás',
          points: 3,
          analysis: 'Expresa sus emociones sin afectar a los demás, demuestra autocontrol.',
        },
        answer2: {
          answer: 'Me controlo con el fin de evitar afectar a los demás',
          points: 2,
          analysis:
            'Controla sus emociones para evitar afectar a los demás, puede aprender a expresarse asertivamente.',
        },
        answer3: {
          answer: 'Me desinhibo independientemente de los demás',
          points: 1,
          analysis: 'Expresa sus emociones independientemente de los demás, puede aprender a expresarse asertivamente',
        },
      },
    },
    {
      question: 'A la hora de resolver conflictos en los que me veo involucrado',
      answers: {
        answer1: {
          answer: 'Comprendo el punto de vista de los demás',
          points: 2,
          analysis:
            'Comprende el punto de vista de los demás en los conflictos, puede aprender a identificar las necesidades de las partes involucradas para solucionarlos.',
        },
        answer2: {
          answer: 'Demuestro que puedo controlar mis emociones',
          points: 1,
          analysis:
            'Controla sus emociones en los conflictos, puede aprender a comprender el punto de vista de los demás al identificar las necesidades de las partes involucradas para solucionarlos.',
        },
        answer3: {
          answer: 'Logro crear consensos entre puntos de acuerdo',
          points: 3,
          analysis: 'Identifica las necesidades de las partes involucradas en los conflictos para solucionarlos.',
        },
      },
    },
    {
      question: 'Sobre mis relaciones interpersonales puedo decir que he',
      answers: {
        answer1: {
          answer: 'Logrado desarrollarlas porque comprendo las emociones de los otros',
          points: 3,
          analysis: 'Entiende las emociones de los demás, desarrolla sus relaciones.',
        },
        answer2: {
          answer: 'Podido crearlas porque analizo las emociones de los que me rodean',
          points: 2,
          analysis:
            'Analiza las emociones de los demás, puede aprender a comprenderlas para desarrollar mejores relaciones.',
        },
        answer3: {
          answer: 'Comenzado a valorarlas porque conozco mis debilidades y fortalezas',
          points: 1,
          analysis:
            'Conoce sus debilidades y fortalezas, puede aprender a comprender las emociones de los demás para desarrollar mejores relaciones.',
        },
      },
    },
    {
      question: 'A la hora de analizar la perspectiva y el punto de vista de los demás logro',
      answers: {
        answer1: {
          answer: 'Diferenciarlos de mi propio punto de vista',
          points: 1,
          analysis: 'Comprende las cosas desde la perspectiva de los demás y es empático y persuasivo',
        },
        answer2: {
          answer: 'Comprenderlos ajustando mi punto de vista',
          points: 2,
          analysis: 'Comprende el punto de vista de los demás, puede aprender a ser empático y persuasivo.',
        },
        answer3: {
          answer: 'Persuadirlos hacia un punto de vista común',
          points: 3,
          analysis:
            'Puede diferenciar el punto de vista de los demás del propio, al aprender a comprenderlos podrá ser empático y persuasivo.',
        },
      },
    },
    {
      question: 'En situaciones en las que se deben tomar decisiones y las emociones se tornan intensas',
      answers: {
        answer1: {
          answer: 'Creo consensos con el grupo',
          points: 3,
          analysis: 'Crea consensos en la toma de decisiones en grupo.',
        },
        answer2: {
          answer: 'Analizo las emociones del grupo',
          points: 2,
          analysis: 'Analiza las emociones del grupo, puede aprender a crear consensos.',
        },
        answer3: {
          answer: 'Controlo mis emociones',
          points: 1,
          analysis: 'Controla sus propias emociones, puede aprender a crear consensos.',
        },
      },
    },
    {
      question: 'Para lograr persuadir a alguien o a un grupo hacia una meta deseada, tengo en cuenta',
      answers: {
        answer1: {
          answer: 'Sus características',
          points: 3,
          analysis: 'Orienta al grupo hacia las metas deseadas teniendo en cuenta sus características personales.',
        },
        answer2: {
          answer: 'Las debilidades',
          points: 2,
          analysis:
            'Al momento de persuadir a un grupo hacia una meta se centra en las debilidades cuando podría incluir también sus fortalezas',
        },
        answer3: {
          answer: 'Mis fortalezas',
          points: 1,
          analysis:
            'Tiene en cuenta sus características personales y opiniones a la hora de persuadir al grupo hacia la meta. Recuerda desarrollar el autoconocimiento y la comprensión de los demás',
        },
      },
    },
  ],
  GlobalMind: [
    {
      question: 'Cuando debo enfrentar cambios en mi entorno, los adopto y',
      answers: {
        answer1: {
          answer: 'Me adapto recibiendo apoyo',
          points: 2,
          analysis: 'Adapta, cuando recibe apoyo, los cambios de su entorno',
        },
        answer2: {
          answer: 'Me adapto con el tiempo',
          points: 1,
          analysis: 'Adopta con el tiempo los cambios de su entorno. ',
        },
        answer3: {
          answer: 'Me adapto fácilmente',
          points: 3,
          analysis: 'Adopta fácilmente los cambios de su entorno. ',
        },
      },
    },
    {
      question: 'Al realizar viajes a países extranjeros o lugares con culturas muy diferentes a la mía',
      answers: {
        answer1: {
          answer: 'Comprendo las diferencias y me desenvuelvo',
          points: 3,
          analysis: 'Comparte nuevas experiencias con diferentes culturas y grupos sociales.',
        },
        answer2: {
          answer: 'Entiendo las diferencias y las asimilo',
          points: 2,
          analysis:
            'Entiende las diferencias y las asimila, puede aprender a compartir con otras culturas y grupos sociales.',
        },
        answer3: {
          answer: 'Identifico las diferencias y voy adaptándome',
          points: 1,
          analysis:
            'Identifica las diferencias y se va adaptando, puede aprender a compartir con otras culturas y grupos sociales.',
        },
      },
    },
    {
      question:
        'Cuando tengo la oportunidad de conocer nuevas costumbres culturales relacionadas con alimentación, rutinas y celebraciones',
      answers: {
        answer1: {
          answer: 'Las integro eficientemente a mi estilo de vida',
          points: 3,
          analysis: 'Integra nuevas costumbres a su estilo de vida.',
        },
        answer2: {
          answer: 'Las comprendo para adaptar mi estilo de vida',
          points: 2,
          analysis: 'Comprende costumbres nuevas, puede aprender a integrarlas a su estilo de vida.',
        },
        answer3: {
          answer: 'Las comparo con las costumbres de mi estilo de vida',
          points: 1,
          analysis: 'Compara otras costumbres con las propias, puede aprender a integrarlas a su estilo de vida. ',
        },
      },
    },
    {
      question: 'Cuando estoy de viaje',
      answers: {
        answer1: {
          answer: 'Creo relaciones a largo plazo',
          points: 3,
          analysis:
            'Establece relaciones interpersonales exitosas en situaciones con grupos o individuos con diversidad cultural.',
        },
        answer2: {
          answer: 'Establezco relaciones transitorias',
          points: 2,
          analysis:
            'Establece relaciones interpersonales transitorias en situaciones con grupos o individuos culturalmente diversos, puede trabajar para crearlas a largo plazo',
        },
        answer3: {
          answer: 'Espero a que otros me aborden',
          points: 1,
          analysis:
            'Espera que otros lo aborden en situaciones con grupos o individuos culturalmente diversos, puede trabajar para crear relaciones exitosas.',
        },
      },
    },
    {
      question: 'Soy esa persona que se caracteriza por',
      answers: {
        answer1: {
          answer: 'Expresar interés en conocer diferentes culturas y sus normas de comportamiento',
          points: 2,
          analysis: 'Conoce diferentes culturas y sus normas de comportamiento, puede aprender a comprenderlas.',
        },
        answer2: {
          answer: 'Mostrar interés en aceptar mi cultura y comprender sus normas de comportamiento',
          points: 1,
          analysis:
            'Acepta su cultura y comprende sus normas de comportamiento. Puede explorar otras culturas y sus comportamientos.',
        },
        answer3: {
          answer: 'Demostrar interés en comprender diferentes culturas y sus normas de comportamiento',
          points: 3,
          analysis: 'Comprende diferentes culturas y sus normas de comportamiento.',
        },
      },
    },
    {
      question: 'Al conocer personas de diferentes culturas o nacionalidades',
      answers: {
        answer1: {
          answer: 'Aprendo de ellas',
          points: 3,
          analysis: 'Aprende de personas con diferentes culturas. ',
        },
        answer2: {
          answer: 'Indago sobre ellas',
          points: 1,
          analysis: 'Interactúa con personas de diferentes culturas.',
        },
        answer3: {
          answer: 'Interactúo con ellas',
          points: 3,
          analysis: 'Indaga sobre personas de diferentes culturas, puede aprender a interactuar con ellas.',
        },
      },
    },
  ],
  ImpactoResponsabilidad: [
    {
      question: 'Para generar cambios en mi entorno',
      answers: {
        answer1: {
          answer: 'Sigo planes establecidos con mi círculo social',
          points: 1,
          analysis: '',
        },
        answer2: {
          answer: 'Propongo planes que impacten a largo plazo',
          points: 3,
          analysis: '',
        },
        answer3: {
          answer: 'Desarrollo actividades con mi familia y amigos',
          points: 2,
          analysis: '',
        },
      },
    },
    {
      question: 'Cuando debo construir propuestas y se presentan puntos de vista diferentes a los míos',
      answers: {
        answer1: {
          answer: 'Escucho y analizo las diferentes opiniones',
          points: 2,
          analysis: '',
        },
        answer2: {
          answer: 'Manifiesto y defiendo mis opiniones',
          points: 1,
          analysis: '',
        },
        answer3: {
          answer: 'Construyo soluciones positivas y pacíficas',
          points: 3,
          analysis: '',
        },
      },
    },
    {
      question: 'Al ver las problemáticas de mi entorno',
      answers: {
        answer1: {
          answer: 'Investigo en internet y busco información al respecto para entenderlos',
          points: 1,
          analysis:
            'Busca entender a profundidad los factores que generan problemas en su entorno, puede aprender a predecir los efectos de los mismos y proponer acciones que los mitiguen.',
        },
        answer2: {
          answer: 'Interpreto y predigo posibles resultados que impacten la problemática',
          points: 2,
          analysis:
            'Entiende los posibles efectos que generan los problemas en la sociedad, puede aprender a proponer soluciones.',
        },
        answer3: {
          answer: 'Comparo los diferentes factores que los producen y genero soluciones',
          points: 3,
          analysis:
            'Comprende y genera soluciones a los problemas sociales, ambientales, políticos o económicos de relevancia en su entorno y el mundo.',
        },
      },
    },

    {
      question: 'Cuando cuido el medio ambiente',
      answers: {
        answer1: {
          answer: 'Organizo actividades con mi comunidad para recoger basuras y sembrar árboles',
          points: 3,
          analysis:
            'Contribuye activamente con el cuidado del medio ambiente liderando a la comunidad en sus propuestas.',
        },
        answer2: {
          answer: 'Aplico cambios en mi rutina para minimizar el impacto ambiental y ayudar al ecosistema',
          points: 2,
          analysis:
            'Es consciente del cuidado del medio ambiente y toma acciones personales para mejorarlo. Puede empezar a participar en acciones comunales para alcanzar los propósitos.',
        },
        answer3: {
          answer: 'Veo videos sobre reciclaje para identificar elementos contaminantes y evitar su uso',
          points: 1,
          analysis:
            'Toma acciones para informarse sobre cómo cuidar el medio ambiente. Puede empezar a aplicar los cambios de forma individual, y comunal.',
        },
      },
    },
    {
      question: 'Cuando me encuentro con personas en situación de vulnerabilidad',
      answers: {
        answer1: {
          answer: 'Ofrezco mi ayuda inmediata para que se sientan seguras',
          points: 2,
          analysis:
            'Ofrece su ayuda frente a situaciones de vulnerabilidad que afecten a personas de su entorno. Puede aprender a participar en grupos u organizaciones que tengan un mayor alcance.',
        },
        answer2: {
          answer: 'Me involucro con grupos para tomar acciones efectivas',
          points: 3,
          analysis:
            'Tiene presente la efectividad de involucrarse en acciones grupales y organizacionales para el apoyo a personas en situación de vulnerabilidad.',
        },
        answer3: {
          answer: 'Informo a las autoridades para que ellos se hagan cargo',
          points: 1,
          analysis:
            'Tiene claros los actores que pueden ofrecer ayuda a personas en situación de vulnerabilidad. Puede aprender a tomar un rol activo, participando personalmente en las actividades de ayuda.',
        },
      },
    },
    {
      question: 'Para proteger el patrimonio cultural de mi país',
      answers: {
        answer1: {
          answer: 'Participo en voluntariados o eventos culturales que visibilicen la riqueza de nuestro patrimonio',
          points: 2,
          analysis:
            'Participa de acciones para visibilizar el patrimonio del país. Podría adquirir un rol más activo en la promoción de su protección. ',
        },
        answer2: {
          answer:
            'Realizo actividades que me permitan aprender sobre la historia, las tradiciones y la cultura de mi país',
          points: 1,
          analysis:
            'Busca comprender cuáles son los elementos que componen el patrimonio cultural de su país. Puede aprender a tomar parte en acciones que lo visibilicen y protejan.',
        },
        answer3: {
          answer: 'Soy promotor de actividades que inspiren a los demás a participar en la protección del patrimonio',
          points: 3,
          analysis: 'Promueve acciones para proteger y difundir el patrimonio cultural de su país.',
        },
      },
    },
    {
      question: 'En relación con el patrimonio cultural de mi país',
      answers: {
        answer1: {
          answer: 'Promuevo el reconocimiento y el respeto por mi cultura',
          points: 3,
          analysis:
            'Reconoce el valor y promueve la diversidad cultural de su país para que su comunidad también lo haga.',
        },
        answer2: {
          answer: 'Apoyo organizaciones que preservan y promueven la cultura',
          points: 2,
          analysis:
            'Apoya ocasionalmente la preservación y reconocimiento de la cultura de su país. Podría adquirir un rol más activo en la promoción de la misma. ',
        },
        answer3: {
          answer: 'Visito lugares históricos o culturales que me ayuden a aprender',
          points: 1,
          analysis:
            'Participa en actividades para conocer su patrimonio cultural. Puede aprender a reconocerlo y promoverlo.',
        },
      },
    },
    {
      question: 'Cuando encuentro grupos de labor socioambiental',
      answers: {
        answer1: {
          answer: 'Participo activamente de sus proyectos con mi familia y amigos',
          points: 2,
          analysis:
            'Apoya las iniciativas de organizaciones y participa en ellas con su círculo social. Puede aprender a proponer proyectos que apoyen la labor de las organizaciones.',
        },
        answer2: {
          answer: 'Me enlisto como gestor y propongo actividades de impacto',
          points: 3,
          analysis:
            'Apoya fundaciones o grupos comprometidos con obras sociales y ambientales, proponiendo actividades de impacto. ',
        },
        answer3: {
          answer: 'Sigo sus redes sociales y comparto sus iniciativas en las mías',
          points: 1,
          analysis:
            'Entiende el valor de las propuestas de otros y las comparte con su círculo social a través de sus redes sociales. Puede aprender a participar activamente o proponer proyectos de impacto socioambiental.',
        },
      },
    },
    {
      question: 'Al ver las problemáticas de mi entorno',
      answers: {
        answer1: {
          answer: 'Busco ayuda en libros y sitios web identificando alternativas para abordarlas',
          points: 2,
          analysis:
            'Contribuye a su comunidad al buscar alternativas o propuestas hechas por otros para solucionar las problemáticas que le aquejan. Puede aprender a participar en grupos o fundaciones de voluntariado que hagan lo mismo.',
        },
        answer2: {
          answer: 'Investigo la problemática para aprender sobre las causas que las generan',
          points: 1,
          analysis:
            'Entiende la problemática que aqueja a su entorno al consultar los factores que la causan. Puede aprender a generar posibles soluciones a las mismas, bien sea desde su círculo cercano, o participando en organizaciones o fundaciones de voluntariado.',
        },
        answer3: {
          answer: 'Tomo medidas para solucionarlas con grupos y fundaciones de voluntariado',
          points: 3,
          analysis:
            'Represento grupos o fundaciones de voluntariado proponiendo acciones para abordar las problemáticas de mi entorno.',
        },
      },
    },
  ],
  AprendizajePermanente: [
    {
      question: 'Cuando recibo nueva información, tras analizarla y evaluarla acostumbro a',
      answers: {
        answer1: {
          answer: 'Informar a mis amigos que aprendí algo nuevo',
          points: 2,
          analysis:
            'Acostumbra a analizar datos e información para compartirla a su grupo inmediato, puede crear canales de comunicación que le permitan extender este nuevo conocimiento con audiencias más amplias. ',
        },
        answer2: {
          answer: 'Compartirla con las personas que me rodean',
          points: 3,
          analysis:
            'Tras analizar datos e información, los relaciona, evalua su validez y utilidad para compartirlas con las personas que le rodean. ',
        },
        answer3: {
          answer: 'Investigar más profundidad el fenómeno',
          points: 1,
          analysis:
            'Una vez analiza y evalua la información recibida, continua investigándola a profundidad. Puede diseñar estrategias que le permitan comunicarlas con su círculo inmediato y audiencias más amplias. ',
        },
      },
    },
    {
      question: 'Me motiva investigar y adquirir conocimientos en diversos temas que me interesan, para así',
      answers: {
        answer1: {
          answer: 'Realizar nuevos aportes y ser reconocido como un referente internacional',
          points: 3,
          analysis:
            'Se encuentra motivado para investigar y adquirir nuevos conocimientos en diversos temas de interés, actitud que lo puede llevar a ser un referente nacional e internacional. ',
        },
        answer2: {
          answer: 'Brindar a mi comunidad las soluciones necesarias y mejorar su calidad de vida',
          points: 2,
          analysis:
            'Encuentra motivación en brindar a la comunidad soluciones que mejoren la calidad de vida a través de la adquisición de nuevos conocimientos. Podría llegar a ser un referente en las temáticas investigadas.  ',
        },
        answer3: {
          answer: 'Plantear un plan de acción que me ayude en mi desarrollo personal y profesional',
          points: 1,
          analysis:
            'Investiga y adquiere nuevos conocimientos para el desarrollo personal y profesional, puede usarlos para apoyar a la comunidad y convertirse en referente de los temas que le interesan. ',
        },
      },
    },
    {
      question: 'Aprendo de las experiencias de la vida para',
      answers: {
        answer1: {
          answer: 'Aportar soluciones a la comunidad desarrollando propuestas de impacto',
          points: 2,
          analysis:
            'Aprende de las experiencias de la vida para el desarrollo habilidades y competencias para aportar a la comunidad y círculo más próximo, puede crear estrategias que le también permitan impactar la sociedad en general.',
        },
        answer2: {
          answer: 'Desarrollarme constantemente logrando metas personales y profesionales',
          points: 1,
          analysis:
            'Aprende de las experiencias de la vida para el desarrollo habilidades y competencias aportando a su logro de metas personales y profesionales, puede ampliar el impacto de estas acciones alcanzando amigos, comunidad e incluso la sociedad en general. ',
        },
        answer3: {
          answer: 'Consolidarme como una persona que promueve el cambio en la sociedad',
          points: 3,
          analysis:
            'Aprende de las experiencias de la vida para el desarrollo habilidades y competencias, lo cual le permitirá promover cambios positivos en la sociedad.',
        },
      },
    },
    {
      question: 'Cuando noto que una estrategia que he implementado me ayuda a aprender mejor,',
      answers: {
        answer1: {
          answer: 'La mantengo para tener éxito',
          points: 1,
          analysis:
            'Usa constantemente las estrategias que le resultan efectivas, puede buscar formas de compartirlas con los compañeros y enseñárselas a los demás. ',
        },
        answer2: {
          answer: 'La comparto siendo facilitador',
          points: 3,
          analysis: 'Es facilitador de las estrategias que le resultan efectivas. ',
        },
        answer3: {
          answer: 'La uso con mis compañeros',
          points: 2,
          analysis:
            'Comparte con sus compañeros las estrategias que le resultan efectivas, puede buscar formas para enseñarlas a los demás.',
        },
      },
    },
    {
      question: 'Me interesa aprender sobre nuevas tecnologías para',
      answers: {
        answer1: {
          answer: 'Compartir lo aprendido',
          points: 2,
          analysis:
            'Aprende sobre nuevas tecnologías para compartirlas con los demás, puede buscar formas de solucionar problemáticas. ',
        },
        answer2: {
          answer: 'Dar solución a problemas',
          points: 3,
          analysis: 'Aprende sobre nuevas tecnologías para dar solución a los problemas. ',
        },
        answer3: {
          answer: 'Estar mejor capacitado',
          points: 1,
          analysis:
            'Aprende sobre nuevas tecnologías para beneficio propio, puede buscar formas para compartirlas con los demás, y solucionar problemáticas. ',
        },
      },
    },
    {
      question: 'A la hora de aprender busco',
      answers: {
        answer1: {
          answer: 'Desenvolverme como un agente de cambio y compartir estas nuevas ideas',
          points: 3,
          analysis: 'Aprende para desenvolverse como un agente de cambio y compartir nuevas ideas.',
        },
        answer2: {
          answer: 'Comprender lo que sucede y plantear estrategias de mejora si son necesarias',
          points: 1,
          analysis:
            'Aprende para comprender el mundo que le rodea, puede desenvolverse como un agente de cambio al compartir estas nuevas ideas con la familia, amigos y círculos más amplios. ',
        },
        answer3: {
          answer: 'Crear canales de comunicación y alimentar el crecimiento de los que me rodean',
          points: 2,
          analysis:
            'Aprende para apoyar a la familia y amigos, puede desenvolverse como un agente de cambio al compartir estas nuevas ideas con círculos más amplios. ',
        },
      },
    },
  ],
  CreatividadInovacion: [
    {
      question: 'Cuando tengo que enfrentar un problema nuevo',
      answers: {
        answer1: {
          answer:
            'Investigo las soluciones existentes que han sido efectivas y las implemento con confianza para superarlo',
          points: 1,
          analysis:
            'Al enfrentar nuevas problemáticas suele emplear soluciones que usan los demás, puede tratar de idear soluciones nuevas o ajustar aquellas que ha usado con éxito en el pasado.',
        },
        answer2: {
          answer:
            'Recomiendo soluciones que he ideado previamente y que me han servido para resolver problemas pasados',
          points: 2,
          analysis:
            'Al enfrentar nuevas problemáticas, recomienda acciones que le llevaron al éxito en el pasado. Puede tratar de idear soluciones nuevas y originales. ',
        },
        answer3: {
          answer:
            'Ideo una solución original y a medida que sea diferente a las que uso normalmente en mi vida cotidiana',
          points: 3,
          analysis: 'Idea soluciones originales al enfrentar problemas emergentes. ',
        },
      },
    },
    {
      question: 'Al desarrollar un proyecto en mi comunidad',
      answers: {
        answer1: {
          answer: 'Ajusto estrategias pasadas y evalúo resultados',
          points: 2,
          analysis:
            'Ajusta ideas pasadas y evalúa sus resultados. Cuando desarrolle actividades o proyectos se recomienda evitar las tendencias usadas anteriormente y explorar nuevas ideas.',
        },
        answer2: {
          answer: 'Busco estrategias efectivas y las implemento',
          points: 1,
          analysis:
            'Tras buscar estrategias efectivas, las implementa. Puede explorar, a través de la evaluación de resultados, nuevas estrategias y formas de hacer las cosas. ',
        },
        answer3: {
          answer: 'Diseño estrategias creativas e innovadoras',
          points: 3,
          analysis: 'Logra objetivos y metas al proponer ideas creativas e innovadoras. ',
        },
      },
    },
    {
      question: 'Cuando debo hacer propuestas para generar mejoras',
      answers: {
        answer1: {
          answer: 'Las identifico fácilmente siendo original',
          points: 3,
          analysis:
            'Se le facilita identificar las formas de mejorar las situaciones y motiva a los demás a utilizar diferentes estrategias.',
        },
        answer2: {
          answer: 'Discuto con el grupo siendo conservador',
          points: 2,
          analysis:
            'Busca consensos entre el grupo para mejorar la situación. Se recomienda tomar un rol más arriesgado.',
        },
        answer3: {
          answer: 'Tengo en cuenta las instrucciones y manuales',
          points: 1,
          analysis:
            'Se le facilita identificar las formas de mejorar las situaciones y motiva a los demás a utilizar diferentes estrategias.',
        },
      },
    },
    {
      question: 'Para atender posibles riesgos cuando estoy ideando soluciones,',
      answers: {
        answer1: {
          answer: 'Los evalúo',
          points: 3,
          analysis: 'Se destaca evaluando los riesgos e implementando ideas innovadoras.',
        },
        answer2: {
          answer: 'Los tengo en cuenta',
          points: 2,
          analysis: 'Tiene en cuenta los riesgos de las ideas nuevas, puede evaluarlos antes de implementarlas. ',
        },
        answer3: {
          answer: 'Los identifico',
          points: 1,
          analysis: 'Tiene en cuenta los riesgos de las ideas nuevas, puede evaluarlos antes de implementarlas. ',
        },
      },
    },
    {
      question: 'Al considerar ideas novedosas propuestas por alguien más las',
      answers: {
        answer1: {
          answer: 'Las complemento',
          points: 3,
          analysis: 'Tiene en cuenta los riesgos de las ideas nuevas, puede evaluarlos antes de implementarlas. ',
        },
        answer2: {
          answer: 'Las evalúo',
          points: 2,
          analysis:
            'Evalúa las ideas que le proponen, podría aceptarlas e implementarlas de acuerdo con sus conocimientos. ',
        },
        answer3: {
          answer: 'Las adopto',
          points: 1,
          analysis:
            'Adopta ideas nuevas e innovadoras, se recomienda buscar formas de evaluarlas y complementarlas con las propias. ',
        },
      },
    },
    {
      question: 'Al momento de organizar mis actividades suelo',
      answers: {
        answer1: {
          answer: 'Apoyarme en mi familia y amigos',
          points: 1,
          analysis:
            'Busca apoyo en su círculo más próximo para realizar sus actividades. Puede aprender a crear estrategias innovadoras para organizar sus actividades y proyectos. ',
        },
        answer2: {
          answer: 'Diseñar nuevas estrategias',
          points: 3,
          analysis: 'Se le facilita crear estrategias creativas para organizar sus actividades y proyectos. ',
        },
        answer3: {
          answer: 'Aplicar mi propio método',
          points: 2,
          analysis:
            'Tiene un método propio para organizar sus actividades y proyectos. Se recomienda emplear nuevas estrategias siempre que sea necesario.',
        },
      },
    },
    {
      question: 'Cuando estoy tratando de resolver un problema en mi comunidad',
      answers: {
        answer1: {
          answer: 'Busco idear soluciones creativas que le gusten a todos',
          points: 2,
          analysis:
            'Presenta ideas y busca aprobación del grupo constantemente. Se recomienda dar espacio para que todos los involucrados puedan proponer ideas y realizar consensos. ',
        },
        answer2: {
          answer: 'Implemento las ideas que he diseñado previamente',
          points: 1,
          analysis:
            'Aplica la solución a las problemáticas usando su experiencia y conocimiento. Se recomienda buscar el apoyo de otros que también puedan proponer ideas y realizar consensos.',
        },
        answer3: {
          answer: 'Suelo motivar para que todos propongan nuevas ideas',
          points: 3,
          analysis:
            'Crea espacios de intercambio donde todos los involucrados pueden proponer ideas y realizar consensos. ',
        },
      },
    },
  ],
  OrientacionResultados: [
    {
      question: 'Cuando estoy haciendo tareas o trabajos en el colegio',
      answers: {
        answer1: {
          answer: 'Comparo mi trabajo para ajustarlo a lo que me han solicitado',
          points: 1,
          analysis:
            'Realiza sus tareas teniendo en cuenta los requisitos mínimos de las mismas. Puede empezar a gestionar mejor su tiempo para mejorar la calidad del trabajo entregado.',
        },
        answer2: {
          answer: 'Hago planes para gestionar mi tiempo y mantenerme enfocado',
          points: 2,
          analysis:
            'Gestiona su tiempo eficientemente para cumplir con las tareas propuestas. Puede aprender a realizarlos con mayores grados de calidad.',
        },
        answer3: {
          answer: 'Propongo formas de llevarlos a cabo dándole un valor agregado',
          points: 3,
          analysis: 'Busca constantemente formas de mejorar el trabajo en pro de la calidad y la excelencia.',
        },
      },
    },
    {
      question: 'Al enfrentarme a un nuevo reto',
      answers: {
        answer1: {
          answer: 'Lo acepto y utilizo métodos conocidos para superarlo',
          points: 1,
          analysis:
            'Utiliza métodos que funcionaron en el pasado para superar retos similares. Puede aprender a buscar nuevas herramientas para superarlo desde otras perspectivas.',
        },
        answer2: {
          answer: 'Lo acojo con calma y busco herramientas para alcanzarlo fácilmente',
          points: 2,
          analysis:
            'Busca herramientas que faciliten alcanzar las metas. Puede aprender a fijar actividades y tiempos para alcanzarlas.',
        },
        answer3: {
          answer: 'Lo asumo como una oportunidad y fijo actividades para lograrlo',
          points: 3,
          analysis: 'Asume metas desafiantes y trabaja para alcanzarlas con eficiencia.',
        },
      },
    },
    {
      question: 'Cuando quiero alcanzar una meta',
      answers: {
        answer1: {
          answer: 'Organizo planes y calculo líneas de tiempo que lleven a cumplirla',
          points: 2,
          analysis:
            'Realiza planes que le permitan organizar las actividades para alcanzar metas. Se recomienda implementar estrategias que le permitan ser más constante con la realización de actividades.',
        },
        answer2: {
          answer: 'Emprendo y mantengo acciones que apoyen a su desarrollo y logro',
          points: 3,
          analysis: 'Emprende y mantiene acciones que apoyen el desarrollo y logro de metas.',
        },
        answer3: {
          answer: 'Busco y clasifico información sobre cómo otros han alcanzado metas similares',
          points: 1,
          analysis:
            'Utiliza a su favor el conocimiento de otros métodos que hayan sido útiles en la solución de retos similares. Puede aprender a realizar planes consistentes que apoyen a su desarrollo y logro.',
        },
      },
    },
    {
      question: 'Cuando se presenta un obstáculo en un proyecto que estoy desarrollando',
      answers: {
        answer1: {
          answer: 'Implemento diferentes estrategias para superarlo sin afectar su avance',
          points: 3,
          analysis:
            'Utiliza diferentes estrategias para superar los obstáculos en el desarrollo de actividades y proyectos.',
        },
        answer2: {
          answer: 'Pongo a prueba diferentes alternativas para identificar la que funciona mejor',
          points: 2,
          analysis:
            'Utiliza un método de "ensayo y error" para determinar la mejor forma de superar un obstáculo. Puede aprender a evaluarlos simultáneamente para solucionar la dificultad en el menor tiempo posible.',
        },
        answer3: {
          answer: 'Replanteo los objetivos teniendo en cuenta las causas que provocaron la dificultad',
          points: 1,
          analysis:
            'Está atento a las causas que podrían generar obstáculos en el desarrollo de un proyecto. Puede aprender a identificar diferentes métodos de afrontar el problema y superarlo en el menor tiempo posible.',
        },
      },
    },
    {
      question: 'Al enfrentarme a un número alto de tareas al mismo tiempo',
      answers: {
        answer1: {
          answer: 'Priorizo para resolverlas en orden de importancia',
          points: 3,
          analysis: 'Prioriza las tareas siendo puntual en la entrega de las mismas.',
        },
        answer2: {
          answer: 'Adapto los requerimientos para cumplirlas',
          points: 1,
          analysis:
            'Realiza cambios puntuales para lograr cumplir con los requerimientos de la tarea. Puede aprender a gestionar mejor el tiempo y priorizarlas.',
        },
        answer3: {
          answer: 'Gestiono planes para entregarlas a tiempo',
          points: 2,
          analysis:
            'Da importancia al manejo adecuado del tiempo para entregar sus tareas. Puede aprender a priorizarlas dependiendo su importancia y/o dificultad.',
        },
      },
    },
    {
      question: 'En mi tiempo libre prefiero enfocarme en',
      answers: {
        answer1: {
          answer: 'Aprender cosas nuevas y entretenidas que me distraigan',
          points: 2,
          analysis:
            'Participa en actividades en su tiempo libre que le permitan cambiar de rutina. Puede aprender a usar su tiempo en actividades que estén enfocadas en el logro de sus metas u objetivos.',
        },
        answer2: {
          answer: 'Comprometerme con actividades que me diviertan',
          points: 1,
          analysis:
            'Participa de actividades divertidas, que le alejan de sus tareas rutinarias. Puede aprender a seleccionar actividades que le aporten directa o indirectamente a sus metas y objetivos.',
        },
        answer3: {
          answer: 'Hacer algo que me genere ganancias o aprendizajes',
          points: 3,
          analysis:
            'Participa en actividades productivas en su tiempo libre, siendo diligente con el logro de sus metas u objetivos.',
        },
      },
    },
  ],
};

export default questionary;
