<div class="main">
    <div class="flex-end">
      <div class="close">
        <label (click)="selfClose()"> {{ "City.close" | translate }} </label>
      </div>
    </div>
    <div class="carousel">
        <ngb-carousel [activeId]="data.currentIndex+''" interval="false">
          <ng-template ngbSlide [id]="i+''" *ngFor="let picture of data.pictures; index as i">
            <div class="picsum-img-wrapper">
              <img [src]="picture.url" alt="{{ 'Picture number ' + i }}"  *ngIf="picture.tipo == 'foto'" />
            </div>
            <iframe id="university-video{{i}}" frameborder="0" type="text/html" [src]="picture.url | safe" *ngIf="picture.tipo == 'video'"></iframe>
          </ng-template>
        </ngb-carousel>
      </div>
</div>
