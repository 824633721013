<main class="transaccion-container">
  <button class="boton-cerrar" type="button" (click)="closeDialog()">X</button>
  <div class="contenido">
    <figure class="imagenes">
      <img class="imagen-estado" src="../../../../assets/payment-icons/caritafeliz.png" alt="transaccion aprobada cara" *ngIf="estadoTransaccion === 'APPROVED' || estadoTransaccion === 'PENDING'">
      <img class="imagen-estado" src="../../../../assets/payment-icons/caritatriste.png" alt="transaccion rechazada cara" *ngIf="estadoTransaccion === 'DECLINED'">
    </figure>
    <p class="mensaje-estado" *ngIf="estadoTransaccion === 'APPROVED'">¡Tu pago ha sido exitoso!</p>
    <p class="mensaje-estado" *ngIf="estadoTransaccion === 'PENDING'">¡Tu pago está pendiente!</p>
    <p class="mensaje-estado" *ngIf="estadoTransaccion === 'DECLINED'">¡Tu pago ha sido rechazado!</p>
  </div>
  <button class="accept" (click)="closeDialog()">Aceptar</button>
</main>
