import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthComponent } from '../auth/auth.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  loadingCalculo: boolean = false;
  /** API service */
  /** Api for retrieving medical services information */
  readonly MEDICAL_SERVICES_API = 'api/servicio-medico/lista-por-id';
  /** Api for retrieving visa services information */
  readonly VISA_SERVICES_API = 'api/servicio-visa/lista-por-id';
  /** Api for retrieving visa services information */
  readonly TRANSLATION_SERVICES_API = 'api/servicio-traduccion/lista-por-id';
  /** Api for retrieving language tests information */
  readonly LANGUAGE_TEST_API = 'api/test-idioma/lista-por-id';
  /** Api for retrievinf consultancy services information */
  readonly CONSULTANCY_SERVICES_API = 'api/consultoria/lista-por-id';
  /** Api for retrieving the exchange rates of the currencies */
  readonly EXCHANGE_RATES_API = 'api/tasa-cambio/actual/';
  /** Api for submitting the shopping carts */
  readonly SHOPPING_CART_API = 'api/carrito/';
  /** Api for submitting the transactions */
  readonly TRANSACTION_API = 'api/transaccion/';
  /** API for creating payment signatures */
  readonly SIGNATURE_API = 'api/transaccion/payU/token/';
  /** API for making payments */
  readonly PAYMENT_API = 'api/transaccion/payU/pay';

  /** Subscriptions that monitor the status of the shopping cart */
  /** Subcriptuon for the translation services */
  translationServicesSubscription: Subscription;
  /** Subcription for the visa services */
  visaServicesSubscription: Subscription;
  /** Subscription for the insurance services */
  insuranceServicesSubscription: Subscription;
  /** Subscription for the language tests */
  languageTestsSubscription: Subscription;
  /** subscription for consultancy services */
  consultancyServicesSubscription: Subscription;

  /** Variable that holds the translation services */
  translationServices: any[] = [];
  /** Variable that holds the list of visa services  */
  visaServices: any[] = [];
  /** Variable that holds the list of insurance services */
  insuranceServices: any[] = [];
  /** Variable that holds the list of language tests */
  languageTests: any[] = [];
  /** Variable that holds the list of consultancy services */
  consultancyServices: any[] = [];

  /** Variable that holds the exchange rates */
  exchangeRates: any;

  /** Variable that holds the subtotal of the purchase */
  subtotal: number = 0;
  /** Variable that holds the taxes of the purchase */
  taxes = 0;
  /** Variable that holds the total of the purchase */
  total: number = 0;
  /** Variable that holds the reference code of the transaction */
  refCode = '';
  
  subtotal_final:number;
  taxes_final;
  total_final:number;
  /** variable that holds the current status of the page
   * 0 --> Confirm order
   * 1 --> Payment and checkout
   */
  pageStatus = 0;

  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;
  /** Variable that checks if the student is logged in */
  loggedIn = false;

  reset:boolean = false;
  count:number=0
  arregloPrecios=[]
  removeOne:boolean = false;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private apiService: ApiService,
    private alertService: AlertService,
    private ngxSpinner: NgxSpinnerService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe(
        (logInStatus) => {
          this.loggedIn = logInStatus;
          this.getReferenceCode()
        }
      );
    }
    this.reset=false;

    // Get the information of the exchange rates
    this.getExchangeRates()
      .then(
        (response) => {
          this.exchangeRates = response;
          //  
          // If the retrieval is successful create the subscriptions for getting the info from the backend
          this.translationServicesSubscription = this.shoppingCartService.serviciosTraduccion.subscribe(
            (productList) => {
              // Only get the information if there are products in the list
              if (productList.length > 0 && this.removeOne==false) {
                //  
                //  
                this.getTranslationServiceInfo({ serviceList: productList });
              } else {
                this.translationServices = [];
                //  
                if(this.reset==true){
                  this.subtotal=0
                  this.taxes=0
                  this.total=0
                }else{
                  //  
                  // let type="translation"
                  // this.calculateInvoiceValue({type});


                }

                
                  
              }
            }
          );
          // If the retrieval is successful create the subscriptions for getting the info from the backend
          this.visaServicesSubscription = this.shoppingCartService.serviciosVisa.subscribe(
            (productList) => {
              // Only get the information if there are products in the list
              if (productList.length > 0 && this.removeOne==false) {
                //  
                //  
                this.getVisaServiceInfo({ serviceList: productList });
              } else {
                this.visaServices = [];
                if(this.reset==true){
                  this.subtotal=0
                  this.taxes=0
                  this.total=0
                }else{
                  // let type="visa"
                  // this.calculateInvoiceValue({type});
                }
              }
            }
          );
          // If the retrieval is successful create the subscriptions for getting the info from the backend
          this.insuranceServicesSubscription = this.shoppingCartService.serviciosMedicos.subscribe(
            (productList) => {
              // Only get the information if there are products in the list
              if (productList.length > 0 && this.removeOne==false) {
                //  
                //  
                this.getInsuranceServiceInfo({ serviceList: productList });
              } else {
                this.insuranceServices = [];
                //  
                if(this.reset==true){
                  this.subtotal=0
                  this.taxes=0
                  this.total=0
                }else{
                  // let type="insurance"
                  // this.calculateInvoiceValue({type});
                }
              }
            }
          );
          // If the retrieval is successful create the subscriptions for getting the info from the backend
          this.languageTestsSubscription = this.shoppingCartService.languageTests.subscribe(
            (productList) => {
              // Only retrieve the information if there are products in the list
              if (productList.length > 0 && this.removeOne==false) {
                //  
                //  
                this.getLanguageTestsInfo({ serviceList: productList });
              } else {
                this.languageTests = [];
                //  
                if(this.reset==true){
                  this.subtotal=0
                  this.taxes=0
                  this.total=0
                }else{
                  // let type="language"
                  // this.calculateInvoiceValue({type});
                }
              }
            }
          );
          this.consultancyServicesSubscription = this.shoppingCartService.consultancyServices.subscribe(
            (productList) => {
              if (productList.length > 0 && this.removeOne==false) {
                //  
                //  
                this.getConsultancyServiceInfo({ serviceList: productList });
              } else {
                this.consultancyServices = [];
                //  
                if(this.reset==true){
                  this.subtotal=0
                  this.taxes=0
                  this.total=0
                }else{
                  // let type="consultancy"
                  // this.calculateInvoiceValue({type});
                }
              }
            }
          )
        },
        (error) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      )
      .finally(() => {
        
      });

      
      //  
      //  
      //  

     
  }

  ngOnDestroy(): void {
    if (
      this.translationServicesSubscription !== null &&
      this.translationServicesSubscription !== undefined
    ) {
      this.translationServicesSubscription.unsubscribe();
    }
    if (
      this.visaServicesSubscription !== null &&
      this.visaServicesSubscription !== undefined
    ) {
      this.visaServicesSubscription.unsubscribe();
    }
    if (
      this.insuranceServicesSubscription !== null &&
      this.insuranceServicesSubscription !== undefined
    ) {
      this.insuranceServicesSubscription.unsubscribe();
    }
    if (
      this.languageTestsSubscription !== null &&
      this.languageTestsSubscription !== undefined
    ) {
      this.languageTestsSubscription.unsubscribe();
    }
    if (
      this.consultancyServicesSubscription !== null &&
      this.consultancyServicesSubscription !== undefined
    ) {
      this.consultancyServicesSubscription.unsubscribe();
    }
    if (
      this.logInSubscription !== null &&
      this.logInSubscription !== undefined
    ) {
      this.logInSubscription.unsubscribe();
    }
  }

  /** Completely empties the shopping cart */
  onClearShoppingCart(): void {
    this.reset=true;
    this.shoppingCartService.clearShoppingCart();
    
  }

  /** Create a reference code for the transaction */
  getReferenceCode(){
    const localUser = JSON.parse(localStorage.getItem("user"));
    let usuarioId = localUser?._id
    let fechaActual = Date.now();
    this.refCode = `COMPRASERVICIOS-${usuarioId}-${fechaActual}`
  }

  /**
   * Retrieves the information of the translation services that are in the shopping cart from the backend
   */
  async getTranslationServiceInfo({ serviceList }: { serviceList: any[] }) {
    //  
    let type="translation"
    const idList = [];
    for (const product of serviceList) {
      idList.push(product.id);
    }
    await this.apiService
      .post({ api: this.TRANSLATION_SERVICES_API, data: { servicios: idList } })
      .subscribe(
        (response) => {
          //  
          const servicesWithData = [];
          let obj;
          let numberOfPages;
          for (const service of response) {
            for (const secondaryService of serviceList) {
              if (secondaryService.id === service._id) {
                numberOfPages = secondaryService.numberOfPages;
              }
            }
            obj = {
              id: service._id,
              docType: service.tipoDocumento,
              numberOfPages: numberOfPages,
              pricePerPage: service.precioPagina,
              currency: 'COP',
              image: service.imagen,
              applyTaxes: service.aplicaImpuestos,
              taxPercentage: service.porcentajeImpuestos,
              typeService:"translation"
            };
            servicesWithData.push(obj);
          }
          this.translationServices = servicesWithData;
          //  
          //  
          this.calculateInvoiceValue({type});
          
          // this.calculateInvoiceValue();
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      );
      
  }

  /**
   * Retrieves the information of the consultancy services from the backend
   * @param param0
   */
  async getConsultancyServiceInfo({serviceList}: {serviceList: any[]}) {
    const idList = [];
    let type="consultancy"
    for (const product of serviceList) {
      idList.push(product.id);
    }
    await this.apiService
      .post({ api: this.CONSULTANCY_SERVICES_API, data: { servicios: idList } })
      .subscribe(
        (response) => {
          const servicesWithData = [];
          let obj;
          for (const service of response) {
            obj = {
              id: service._id,
              country: service.pais.nombre,
              currency: service.pais.moneda,
              img: service.imagen,
              cost: service.precio,
              applyTaxes: service.aplicaImpuestos,
              taxPercentage: service.porcentajeImpuestos,
              name: service.nombre,
              typeService:"consultancy"
            };
            servicesWithData.push(obj);
          }
          this.consultancyServices = servicesWithData;
          //  
          //  
          this.calculateInvoiceValue({type});
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      );
      // this.calculateInvoiceValue();
  }

  /**
   * Retrieves the information of the medical services that are in the shopping cart from the backend
   */
  async getInsuranceServiceInfo({ serviceList }: { serviceList: any[] }) {
    const idList = [];
    let type="insurance"
    for (const product of serviceList) {
      idList.push(product.id);
    }
    await this.apiService
      .post({ api: this.MEDICAL_SERVICES_API, data: { servicios: idList } })
      .subscribe(
        (response) => {
          const servicesWithData = [];
          let obj;
          let numberOfDays;
          for (const service of response) {
            for (const secondaryService of serviceList) {
              if (secondaryService.id === service._id) {
                numberOfDays = secondaryService.numberOfDays;
              }
            }
            obj = {
              id: service._id,
              serviceName: service.nombre,
              numberOfDays:numberOfDays,
              plans: service.planes,
              currency: service.moneda,
              image: service.imagen,
              applyTaxes: service.aplicaImpuestos,
              taxPercentage: service.porcentajeImpuestos,
              typeService:"insurance"
            };
            //  
            servicesWithData.push(obj);
          }
          this.insuranceServices = servicesWithData;
          //  
          //  
          this.calculateInvoiceValue({type});
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      );
      // this.calculateInvoiceValue();
  }

  /**
   * Retrieves the information of the visa services that are in the shopping cart from the backend
   */
  async getVisaServiceInfo({ serviceList }: { serviceList: any[] }) {
    const idList = [];
    let type="visa"
    for (const product of serviceList) {
      idList.push(product.id);
    }
    await this.apiService
      .post({ api: this.VISA_SERVICES_API, data: { servicios: idList } })
      .subscribe(
        (response) => {
          const servicesWithData = [];
          let obj;
          for (const service of response) {
            obj = {
              id: service._id,
              country: service.pais.nombre,
              currency: service.pais.moneda,
              img: service.imagen,
              cost: service.precio,
              applyTaxes: service.aplicaImpuestos,
              taxPercentage: service.porcentajeImpuestos,
              typeService:"visa"
            };
            servicesWithData.push(obj);
          }
          this.visaServices = servicesWithData;
          //  
          //  
          this.calculateInvoiceValue({type});
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      );
      // this.calculateInvoiceValue();
  }

  /** Retrieves the information of the language tests that are in the shopping cart from the backend */
  async getLanguageTestsInfo({ serviceList }: { serviceList: any[] }) {
    const idList = [];
    let type="language"
    for (const product of serviceList) {
      idList.push(product.id);
    }
    this.apiService
      .post({ api: this.LANGUAGE_TEST_API, data: { servicios: idList } })
      .subscribe(
        (response) => {
          const testsWithData = [];
          let obj;
          for (const test of response) {
            obj = {
              id: test._id,
              language: test.idioma,
              name: test.nombre,
              cost: test.precio,
              currency: test.moneda,
              image: test.icono,
              applyTaxes: test.aplicaImpuestos,
              taxPercentage: test.porcentajeImpuestos,
              type: test.tipo,
              typeService:"language"
            };
            testsWithData.push(obj);
          }
          this.languageTests = testsWithData;
          //  
          //  
          this.calculateInvoiceValue({type});
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.load-error'),
          });
        }
      );
      // this.calculateInvoiceValue();
  }

  /** Retrieves the exchange rates from the backend */
  getExchangeRates(): Promise<any> {
    return this.apiService.getAsPromise({ api: this.EXCHANGE_RATES_API });
  }

  /** Calculates the subtotal, taxes and total of the invoice  */
  async calculateInvoiceValue({type}): Promise<void> {
    let count_consul=0
    let count_trans=0
    let count_visa=0
    let count_insu=0
    let count_leng=0


    this.loadingCalculo=true;
    
    let subtotal = 0;
    let total = 0;
    // this.total = 0;
    // this.subtotal = 0;
    // this.taxes = 0;

    // Calculate the total of the translation services
    if(type=="translation"){
      for (const service of this.translationServices) {
        //  
        //  
        let calculaCurrency
        calculaCurrency =  await this.getExchangeRate({ currency: service.currency });
        //  
        //  
        if(calculaCurrency){
          count_trans++
          //  
          const copToUSD = calculaCurrency
          const priceInCop = service.numberOfPages * service.pricePerPage;
          const priceInUSD = priceInCop * copToUSD;
          //  
          //  
          //  
          let tax = 0;
          subtotal = Math.round(priceInUSD * 1e2) / 1e2;
          total = Math.round(priceInUSD * 1e2) / 1e2;
          if (service.applyTaxes) {
            const serviceTax = priceInUSD * (service.taxPercentage / 100);
            this.taxes += serviceTax;
            tax = serviceTax;
          }
          this.subtotal += subtotal;
          this.total += total + tax;
          
  
          let obj={
            id:service.id,
            applyTaxes:service.applyTaxes,
            subtotal:subtotal,
            subtotalInUSD:total,
            taxPercentage:service.taxPercentage,
            tax:tax,
            total:total,
            typeService:"translation"
          }
          this.arregloPrecios.push(obj)
          subtotal=0
  
          //  
          //  
          //  
        }    
      }
    }
    
    // Calculate the cost of the visa services
    if(type=="visa"){
      
     for (const service of this.visaServices) {
      let visa
      visa = await this.getExchangeRate({ currency: service.currency });
      //  
      if(visa){
        count_visa++
        //  
        const exchangeRate = visa;
        const priceInUSD = service.cost * exchangeRate;
        let tax = 0;
        subtotal = Math.round(priceInUSD * 1e2) / 1e2;
        total = Math.round(priceInUSD * 1e2) / 1e2;
        if (service.applyTaxes) {
          const serviceTax = priceInUSD * (service.taxPercentage / 100);
          this.taxes += serviceTax;
          tax = serviceTax;
        }
        this.subtotal += subtotal;
        this.total += total + tax;
        

        let obj={
          id:service.id,
          applyTaxes:service.applyTaxes,
          subtotal:subtotal,
          subtotalInUSD:total,
          taxPercentage:service.taxPercentage,
          tax:tax,
          total:total,
          typeService:"visa"
        }
        this.arregloPrecios.push(obj)
        subtotal=0
      }
     /* this.getExchangeRate({ currency: service.currency }).then(response => {
        const exchangeRate = response;
         
        const priceInUSD = service.cost * exchangeRate;
        let tax = 0;
        subtotal = Math.round(priceInUSD * 1e2) / 1e2;
        total = Math.round(priceInUSD * 1e2) / 1e2;
        if (service.applyTaxes) {
          const serviceTax = priceInUSD * (service.taxPercentage / 100);
          this.taxes += serviceTax;
          tax = serviceTax;
        }
        this.subtotal += subtotal;
        this.total += total + tax;
      });*/
      //  
    }
    }
    
    // Calculate the cost of the insurance services
    if(type=="insurance"){
      
    for (const service of this.insuranceServices) {
      //  
      let ins;
      ins = await this.getExchangeRate({ currency: service.currency })
        let exchangeRate = ins;
        let cost;
        let tax = 0;
        for (const plan of service.plans) {
          if (
            service.numberOfDays >= plan.diasInferior &&
            service.numberOfDays <= plan.diasSuperior
          ) {
            cost = service.numberOfDays * plan.precioDiario;
            break;
          }
        }
        count_insu++
        //  
        const priceInUSD = cost * exchangeRate;
        subtotal = Math.round(priceInUSD * 1e2) / 1e2;
        total = Math.round(priceInUSD * 1e2) / 1e2;
        if (service.applyTaxes) {
          const serviceTax = priceInUSD * (service.taxPercentage / 100);
          this.taxes += serviceTax;
          tax = serviceTax;
        }
        this.subtotal += subtotal;
        this.total += total + tax;
        

        let obj={
          id:service.id,
          applyTaxes:service.applyTaxes,
          subtotal:subtotal,
          subtotalInUSD:total,
          taxPercentage:service.taxPercentage,
          tax:tax,
          total:total,
          typeService:"insurance"
        }
        this.arregloPrecios.push(obj)
        subtotal=0
    }
    }
    

    // Calculate the cost of the language tests
    if(type=="language"){
      for (const test of this.languageTests) {
        let lang;
        lang=await this.getExchangeRate({ currency: test.currency })
          const exchangeRate = lang
          const priceInUSD = test.cost * exchangeRate;
          let tax = 0;
          subtotal = Math.round(priceInUSD * 1e2) / 1e2;
          total = Math.round(priceInUSD * 1e2) / 1e2;
          if (test.applyTaxes) {
            count_leng++
            //  
            const serviceTax = priceInUSD * (test.taxPercentage / 100);
            //  
            this.taxes += serviceTax;
            tax = serviceTax;
          }
          this.subtotal += subtotal;
          this.total += total + tax;
          
  
          let obj={
            id:test.id,
            applyTaxes:test.applyTaxes,
            subtotal:subtotal,
            subtotalInUSD:total,
            taxPercentage:test.taxPercentage,
            tax:tax,
            total:total,
            typeService:"language"
          }
          this.arregloPrecios.push(obj)
          subtotal=0
        
      }
    }
    

    // Calculate the cost of the consultancy services
    if(type=="consultancy"){
      for (const service of this.consultancyServices) {
        let consult;
        consult = await this.getExchangeRate({ currency: service.currency });
          const exchangeRate = consult;
          const priceInUSD = service.cost * exchangeRate;
          let tax = 0;
          subtotal = Math.round(priceInUSD * 1e2) / 1e2;
          total = Math.round(priceInUSD * 1e2) / 1e2;
          if (service.applyTaxes) {
            count_consul++
            //  
            const serviceTax = priceInUSD * (service.taxPercentage / 100);
            this.taxes += serviceTax;
            tax = serviceTax;
          }
          this.subtotal += subtotal;
          this.total += total + tax;
          
  
          let obj={
            id:service.id,
            applyTaxes:service.applyTaxes,
            subtotal:subtotal,
            subtotalInUSD:total,
            taxPercentage:service.taxPercentage,
            tax:tax,
            total:total,
            typeService:"consultancy"
          }
          this.arregloPrecios.push(obj)
          subtotal=0
        
      }
    }
      //  

      // if(this.reset==)
      // this.subtotal_final=this.subtotal;
      // this.taxes_final=this.taxes
      // this.total_final=this.total

      //  
      //  
      //  
      this.loadingCalculo=false;
      // this.removeOne==false
  }

  /**
   * Gets a exchange rate from the specified currency to USD using the API cambio.today
   * @param currency currency that is going to be converted into USD;
   */
  async getExchangeRate({ currency }: { currency: string }) {
    //  
    // let esPrueba = environment.production === true ? false : true;
    return new Promise((resolve, reject) => {
      this.apiService.get({api: `api/tasa-cambio/cambio-today/${currency}`}).subscribe((response) => {
        //  
        resolve(response.respuesta);
      });
    })
  }

  /**
   * Removes a medical service from the medical services list
   * @param id: id of the medical service that is to be removed
   */
  async removeMedicalService(id: string) {
    //  
    this.removeOne==true
    this.arregloPrecios.map(e=>{
      if(e.typeService=="insurance"){
        this.subtotal-=e.subtotalInUSD
        if(e.applyTaxes==true){
          this.taxes-= (e.subtotalInUSD*(e.taxPercentage/100))
          this.total-= (e.subtotalInUSD+(e.subtotalInUSD*(e.taxPercentage/100)))
        }else{
          this.total-= e.subtotalInUSD
        }
      }
    })

    let finalProducts = this.arregloPrecios.filter(item=>item.typeService!="insurance")
    this.arregloPrecios=finalProducts
    await this.shoppingCartService.removeMedicalService({ id });
    
  }

  /**
   * Removes a visa service from the medical services list
   * @param id: id of the visa service that is to be removed
   */
  async removeVisaService(id: string) {
    //  
    this.removeOne==true
    this.arregloPrecios.map(e=>{
      if(e.typeService=="visa"){
        this.subtotal-=e.subtotalInUSD
        if(e.applyTaxes==true){
          this.taxes-= (e.subtotalInUSD*(e.taxPercentage/100))
          this.total-= (e.subtotalInUSD+(e.subtotalInUSD*(e.taxPercentage/100)))
        }else{
          this.total-= e.subtotalInUSD
        }
      }
    })

    let finalProducts = this.arregloPrecios.filter(item=>item.typeService!="visa")
    this.arregloPrecios=finalProducts
    await this.shoppingCartService.removeVisaService({ id });
    
  }

  /**
   * Removes a visa service from the medical services list
   * @param id: id of the visa service that is to be removed
   */
  async removeConsultancyService(id: string) {
    //  
    this.removeOne==true
    this.arregloPrecios.map(e=>{
      if(e.typeService=="consultancy"){
        this.subtotal-=e.subtotalInUSD
        if(e.applyTaxes==true){
          this.taxes-= (e.subtotalInUSD*(e.taxPercentage/100))
          this.total-= (e.subtotalInUSD+(e.subtotalInUSD*(e.taxPercentage/100)))
        }else{
          this.total-= e.subtotalInUSD
        }
      }
    })

    let finalProducts = this.arregloPrecios.filter(item=>item.typeService!="consultancy")
    this.arregloPrecios=finalProducts
    await this.shoppingCartService.removeConsultancyService({ id });
    
  }

  /**
   * Removes a visa service from the medical services list
   * @param id: id of the translation service that is to be removed
   */
    removeTranslationService(id: string) {
    //  
    this.removeOne==true
    this.arregloPrecios.map(e=>{
      //  
      if(e.typeService=="translation"){
        this.subtotal-=e.subtotalInUSD
        if(e.applyTaxes==true){
          this.taxes-= (e.subtotalInUSD*(e.taxPercentage/100))
          this.total-= (e.subtotalInUSD+(e.subtotalInUSD*(e.taxPercentage/100)))
        }else{
          this.total-= e.subtotalInUSD
        }
      }
    })

    let finalProducts = this.arregloPrecios.filter(item=>item.typeService!="translation")
    this.arregloPrecios=finalProducts
    this.shoppingCartService.removeTranslationService({ id });
    
  }

  /**
   * Removes a language test from the language tests list
   * @param id id of the language test that is to be removed
   */
  async removeLanguageTest(id: string) {
    //  
    this.removeOne==true
    this.arregloPrecios.map(e=>{
      if(e.typeService=="language"){
        this.subtotal-=e.subtotalInUSD
        if(e.applyTaxes==true){
          this.taxes-= (e.subtotalInUSD*(e.taxPercentage/100))
          this.total-= (e.subtotalInUSD+(e.subtotalInUSD*(e.taxPercentage/100)))
        }else{
          this.total-= e.subtotalInUSD
        }
      }
    })

    let finalProducts = this.arregloPrecios.filter(item=>item.typeService!="language")
    this.arregloPrecios=finalProducts
    await this.shoppingCartService.removeLanguageTest({ id });
    
  }

  /** Changes the number of pages of a translation service
   * @param id: id of the translation service
   * @param newQuantity: new quantity of pages to be translated
   */
  changeTranslationServiceQuantity({
    id,
    newQuantity,
  }: {
    id: string;
    newQuantity: number;
  }): void {
     
   this.shoppingCartService.changeTranslationServiceQuantity({
      id,
      quantity: newQuantity,
    });
    //this.calculateInvoiceValue();
  }

  /** Changes the number of pages of a translation service
   * @param id: id of the translation service
   * @param newQuantity: new quantity of pages to be translated
   */
  changeInsuranceServiceQuantity({
    id,
    newQuantity,
  }: {
    id: string;
    newQuantity: number;
  }): void {
    this.shoppingCartService.changeInsuranceServiceQuantity({
      id,
      quantity: newQuantity,
    });
  }

  /**
   * Changes the page status to checkout status
   */
  proceedToCheckout(): void {
    alert("entrando....")
    if (this.total <= 0) {
      this.alertService.showError({
        msg: 'There are no elements in your shopping cart',
      });
    } else if (this.loggedIn) {
      this.pageStatus = 1;
    } else {
      // Display login popup
      this.dialog.open(AuthComponent, { data: { authAction: 'signup' } });
    }
  }

  /** Changes the page status when the back button is clicked */
  onReturnFromCheckout(): void {
    this.pageStatus = 0;
  }

  /**
   * First step of creating a new purchase, creates a shopping cart in the backend
   * @param paymentInformation PayU payment information, passed in by child component
   */
  startPurchase(paymentInformation): void {
     
    /** Create the shopping cart data */
    this.ngxSpinner.show();
    const total = this.total.toFixed(2);
    const shoppingCartData = {
      estudiante: paymentInformation.student,
      serviciosMedicos: [],
      serviciosTraduccion: [],
      serviciosVisa: [],
      testsIdioma: [],
      serviciosConsultoria: [],
      valor: total,
    };
    for (const service of this.insuranceServices) {
      shoppingCartData.serviciosMedicos.push({
        servicio: service.id,
        dias: service.numberOfDays,
      });
    }
    for (const service of this.translationServices) {
      shoppingCartData.serviciosTraduccion.push({
        servicio: service.id,
        paginas: service.numberOfPages,
      });
    }
    for (const service of this.visaServices) {
      shoppingCartData.serviciosVisa.push({
        servicio: service.id,
      });
    }
    for (const service of this.consultancyServices) {
      shoppingCartData.serviciosConsultoria.push({
        servicio: service.id,
      });
    }

    for (const test of this.languageTests) {
      shoppingCartData.testsIdioma.push({
        servicio: test.id,
      });
    }
     
     

    // Submit the shopping cart data to the backend
    this.apiService
      .post({ api: this.SHOPPING_CART_API, data: shoppingCartData })
      .subscribe(
        (response) => {
          // retrieve the cart id and add the value and the currency to the payment information data
          paymentInformation['value'] = total;
          paymentInformation['currency'] = 'USD';
          const cartId = response.result._id;
          this.createTransaction({ paymentInformation, cartId });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /**
   * Second step of payment, creates a transaction in the backend
   * @param paymentInformation consolidated payment information
   * @param cartId id of the shopping cart in the backend
   */
  createTransaction({ paymentInformation, cartId }): void {
     
    const total = this.total.toFixed(2);
    const transaction = {
      estudiante: paymentInformation.merchantPayerId,
      valor: total,
      carrito: cartId,
    };
    
    this.apiService
      .post({ api: this.TRANSACTION_API, data: transaction })
      .subscribe(
        (response) => {
          // Get the transaction id and add it to the paymentInformation
          const transactionId = response.result._id;
          paymentInformation['referenceCode'] = transactionId;
          this.createSignature({ paymentInformation, transactionId });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /** Third step of the payment creates a signature for the payment
   * @param paymentInformation consolidated payment information
   * @param transactionId transaction id as it appears in the backend
   */
  createSignature({ paymentInformation, transactionId }): void {
    const total = this.total.toFixed(2);
    const signature = {
      referencePayu: transactionId,
      amount: total,
      currency: 'USD',
    };
     
     


    this.apiService
      .post({ api: this.SIGNATURE_API, data: signature })
      .subscribe(
        (response) => {
          // Get the signature and add it to the payment information
          const signatureId = response.firma;
          paymentInformation['signature'] = signatureId;
          this.makePayment({ paymentInformation });
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }

  /** Fourth and last method of payment, makes the payment
   * @param paymentInformation: Consolidated payment information
   */
  makePayment({ paymentInformation }): void {
     
     

    this.ngxSpinner.show();
    this.apiService
      .post({ api: this.PAYMENT_API, data: paymentInformation })
      .subscribe(
        (response) => {
           
          if (response.success === true) {
            this.alertService.showSuccess({
              msg: this.translate.instant('Shopping-cart.payment-succesfull'),
            });
            this.shoppingCartService.clearShoppingCart();
            this.ngxSpinner.hide();
            this.navigationService.navigateTo({ path: 'purchase-history' });
          } else {
            let mensaje
            if(!response.msg){
              mensaje = 'Hubo un error por favor vuelve a intentarlo'
            }else{
              mensaje = response.msg
            }
            this.alertService.showError({ msg: mensaje });
            this.ngxSpinner.hide();
          }
        },
        (err) => {
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: this.translate.instant('Shopping-cart.error-general'),
          });
        }
      );
  }
}
