import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss']
})
export class StudentDetailComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { student: any },
    private dialogRef: MatDialogRef<StudentDetailComponent>,) { }

  ngOnInit(): void {
  }

  /**
   * Closes self
   */
  public selfClose() {
    this.dialogRef.close();
  }

}
