<div class="free-guides">
  <div class="free-guides-container">
    <div class="card centro">
      <div class="row">
        <div class="col-12"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card cuerpo-card">
            <div class="row">
              <div class="col-12">
                <ng-container *ngIf="showComponents">
                  <ng-container [ngSwitch]="activeComponent">
                    <!-- <app-educationalGoal
                      (nextButtonClick)="nextComponent()"
                      (selectedTarget)="onSelectedTarget($event)"
                      *ngSwitchCase="0"></app-educationalGoal> -->
                    <app-personal-data
                      (personalDataSubmitted)="onPersonalDataSubmitted($event)"
                      (nextButtonClick)="nextComponent()"
                      (previousButtonClick)="previousComponent()"
                      *ngSwitchCase="0"></app-personal-data>
                    <!--<app-universityType
                      (selectedUniversityType)="onSelectedUniversityType($event)"
                      (nextButtonClick)="nextComponent()"
                      (previousButtonClick)="previousComponent()"
                      *ngSwitchCase="2"></app-universityType>
                    <app-skillsDescription
                      (selectedSkill)="onSelectedSkill($event)"
                      (nextButtonClick)="nextComponent()"
                      (previousButtonClick)="previousComponent()"
                      *ngSwitchCase="3"></app-skillsDescription>
                    <app-universityBudget
                      (selectedBudget)="onSelectedBudget($event)"
                      (nextButtonClick)="nextComponent()"
                      (previousButtonClick)="previousComponent()"
                      *ngSwitchCase="4"></app-universityBudget> -->
                    <app-questions
                      (submitToParent)="onSubmit()"
                      (questionsAndAnswers)="handleQuestionsAndAnswers($event)"
                      (skillPointsData)="handleSkillPointsData($event)"
                      (previousButtonClick)="previousComponent()"
                      *ngSwitchCase="1"></app-questions>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
