import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MoreInfoComponent } from './more-info/more-info.component';
import SwiperCore, { Pagination } from 'swiper';
import { AuthService } from '../../services/auth.service';
AuthService;
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-public-home',
  templateUrl: './public-home.component.html',
  styleUrls: ['./public-home.component.scss'],
})
export class PublicHomeComponent implements OnInit {
  /** Constant that holds the path to the blog API */
  readonly CONFIG_API = 'api/config/';
  readonly SKILLS_API = 'api/getSkill';

  /** Variable that contain all configs */
  config: any;

  universitiesLogosList = Array(14)
    .fill(1)
    .map((value, index) => `assets/home/logos/universities/logo-${index + 1}.png`);

  showStudent: boolean = false;
  showUniversity: boolean = false;
  showSchool: boolean = false;
  skills: any;
  constructor(private apiService: ApiService, private dialog: MatDialog, public authService: AuthService) {}

  ngOnInit(): void {
    this.authService.logout();
    this.getConfig();
    this.getSkills();
  }

  getConfig(): void {
    this.apiService.get({ api: this.CONFIG_API }).subscribe((response) => {
      this.config = response[0];
    });
  }
  getSkills(): void {
    this.apiService.get({ api: this.SKILLS_API }).subscribe((response) => {
      this.skills = response;
    });
  }
  openModalInfo() {
    this.dialog.open(MoreInfoComponent);
  }
}
