<div class="row">
  <div class="col-12">
    <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
    <h4 class="titulo-wizard">Hablemos de Presupuesto</h4>
    <p>
      Es importante tener en cuenta el presupuesto con el que cuentas este momento para iniciar tu camino hacia el
      éxito. Selecciona una de las siguientes opciones
    </p>
    <hr class="hrLine" />
  </div>
</div>
<div class="row">
  <div class="col-md-6 cardContent content-img" *ngFor="let preg of questionStack; let imageIndex = index">
    <div class="row">
      <div class="col-md-12">
        <img src="/assets/images_360/pantalla7/{{ imageIndex }}.jpg" class="img-from-divs sizingImage" />
      </div>
      <div class="col-md-12 questionContainer">
        <p class="question">{{ preg.question }}</p>
      </div>
      <div class="col-md-12">
        <div class="optionsContent">
          <div>
            <strong class="labelCheckbox" *ngFor="let indexGroup of ['Si', 'No']">{{ indexGroup }}</strong>
          </div>
          <input
            class="checkBoxEdit"
            type="checkbox"
            [(ngModel)]="preg.selectedYes"
            name="opcion"
            id="checkBox{{ imageIndex }}value1"
            [value]="true"
            (change)="updateSelectedAnswers()" />
          <input
            class="checkBoxEdit"
            type="checkbox"
            [(ngModel)]="preg.selectedNo"
            name="opcion"
            id="checkBox{{ imageIndex }}value2"
            [value]="false"
            (change)="updateSelectedAnswers()" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn-continuar" (click)="emitNextButtonClick()">Continuar</button>
    <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
  </div>
</div>
