<mat-dialog-content>
  <!-- Input phone -->
  <div class="row">
    <div class="col-sm-12" *ngIf="!data?.telefono">
      <label class="auth-form-label" for="user-telephone"> {{ "Sign-up.telephone" | translate }}</label> <br>
      <input id="user-telephone" [(ngModel)]='phone' type="number" [placeholder]="('Sign-up.telephone'|translate )+ ' (ej: +573562158753)'" class="auth-form-input">
    </div>
    <div class="col-sm-12" *ngIf="!data?.procedenciaComoLead || data?.procedenciaComoLead == 'NA'">
      <label class="auth-form-label" for="user-code">{{ "Sign-up.precedence-as-lead" | translate }}</label> <br>
      <div class="d-flex align-items-center justify-content-between">
        <input id="user-code" [(ngModel)]='code' (ngModelChange)="onCodeChange()" type="text" placeholder="Digite el código" class="form-control auth-form-input">
        <button class="auth-form-button ml-2" type="button" (click)="validar(code)" [disabled]="!code">
          Validar
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 d-flex justify-content-center">
      <button id="submit-login" type="submit" [disabled]="(!phone && !data?.telefono) || (code && !isCodeValidated)" class="auth-form-button mt-3" (click)="onSaveNumber()">
        {{ "Academic-history.save-changes" | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
