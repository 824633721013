import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent implements OnInit {
  CONTACT_API = 'sendContactEmail';
  createForm: FormGroup = this.formInformation.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    correo: ['', [Validators.required, Validators.email]],
    rol: ['', [Validators.required]],
    telefono: [
      '',
      [Validators.required, Validators.min(1000000000), Validators.max(9999999999), Validators.pattern('^[0-9]*$')],
    ],
    pregunta: ['', [Validators.required]],
  });


  public displayForm: boolean = false;

  constructor(private formInformation: FormBuilder, private apiService: ApiService) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.createForm.valid) {
      const formData = this.createForm.value;
      const data = {
        api: `api/sendContactEmail`,
        data: formData,
      };
      this.apiService.post(data).subscribe(
        (response) => {         
          Swal.fire('Éxito', '¡La información se ha enviado con éxito!', 'success');
         
        },
        (error) => {
          console.error('Error:', error);
          Swal.fire('Error', '¡La información no se ha enviado con éxito!', 'error');
        }
      );
    } else {

    }
  }

  public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched &&
      this.createForm.controls[field].invalid
    );
  }
}
