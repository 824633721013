<div class="cultural-matches">
  <div class="cultural-matches-header">
    <img (click)="onBackClick()" src="assets/utils/back.png" class="back-button" alt="go back">
    <h3 class="cultural-matches-title">{{ "Cultural-match.cultural-match" | translate }} </h3>
    <div class="cultural-matches-subtitle"> {{ "Cultural-match.these-are-our-reco" | translate }}</div>
    <div class="cultural-matches-subtitle">
      {{ "Cultural-match.recomendations-based" | translate }}
    </div>
  </div>
  <div class="cultural-matches-cards">
    <app-cultural-match-card *ngFor="let match of matches; index as i" [city]="match" [index]="i">
    </app-cultural-match-card>
  </div>
</div>
