<div class="services-overview">
    <div class="row">
        <div routerLink="../marketplace" class="col-sm-4 col-md-4 link-button">
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/tranlations.png" alt="translations" />
                <p class="service-overview-text"> {{ "Home.sworn-translations" | translate }} </p>
            </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'visas'}" class="col-sm-4 col-md-4 link-button">
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/visas.png" alt="visas" />
                <p class="service-overview-text"> {{ "Home.visas" | translate }} </p>
            </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'servicios-medicos'}" class="col-sm-4 col-md-4 link-button">
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/travel-insurance.png" alt="travel insurance" />
                <p class="service-overview-text"> {{ "Home.travel-insurance" | translate }} </p>
            </div>
        </div>
        <div class="col-sm-4 col-md-4 link-button" routerLink="../program/search" [queryParams]="{acreditacion: 'language'}" >
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/language.png" alt="language" />
                <p class="service-overview-text"> {{ "Home.language-pathways" | translate }} </p>
            </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'banco-de-becas'}" class="col-sm-4 col-md-4 link-button">
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/scholarships.png" alt="scholarships" />
                <p class="service-overview-text"> {{ "Home.scholarship-bank" | translate }} </p>
            </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'test-de-idioma'}" class="col-sm-4 col-md-4 link-button">
            <div class="row service-overview-row">
                <img class="service-overview-icon" src="assets/home/icons/test.png" alt="tests"/>
                <p class="service-overview-text"> {{ "Home.language-tests" | translate }} </p>
            </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'consultoria-vip'}" class="col-sm-4 col-md-4 link-button">
          <div class="row service-overview-row">
              <img class="service-overview-icon" src="assets/home/icons/vip-consulting.png" alt="vip consulting"/>
              <p class="service-overview-text"> {{ "Home.vip-consultancy" | translate }} </p>
          </div>
        </div>
        <div routerLink="../marketplace" [queryParams]="{serviceType: 'gallup'}" class="col-sm-4 col-md-4 link-button">
          <div class="row service-overview-row">
              <img class="service-overview-icon" src="assets/home/icons/vocational-test.png" alt="vocational-test" />
              <p class="service-overview-text"> {{ "Home.vocational-tests" | translate }} </p>
          </div>
        </div>
        <div routerLink="../cultural-item" class="col-sm-4 col-md-4 link-button">
          <div class="row service-overview-row">
              <img class="service-overview-icon" src="assets/utils/cultural-match.png" alt="cultural match" />
              <p class="service-overview-text"> {{ "Home.cultural-match" | translate }} </p>
          </div>
        </div>
    </div>
</div>
