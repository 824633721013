import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeHours'
})
export class ChangeHoursPipe implements PipeTransform {

  transform(value): string {
    value = parseInt(value);
    if (value > 12) {
      let rta = value - 12
      return `${rta}:00 pm`
    } else {
      return `${value}:00 am`
    }
  }

}
