import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-radar-chart-component',
  templateUrl: './radar-chart-component.component.html',
  styleUrls: ['./radar-chart-component.component.scss']
})
export class RadarChartComponentComponent implements OnInit {
  @Input() sorts: any;
  radarChartOptions: RadialChartOptions = {};
  radarChartLabels: Label[] = [];
  radarChartData: ChartDataSets[] = [];
  radarChartType: ChartType = 'radar';

  ngOnInit(): void {
    this.radarChartLabels = this.sorts.data.labels;
    this.radarChartOptions = this.sorts.options;
    this.radarChartType = this.sorts.type;
    this.radarChartData = this.sorts.data.datasets;
  }
}
