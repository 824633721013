import { AuthService } from './../services/auth.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Options } from '@m0t0r/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { NavigationService } from '../services/navigation.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';
import { ProgramCardUniService } from '../services/program-card-uni.service';
import { Console, count } from 'console';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
})
export class ProgramComponent implements OnInit, OnDestroy {
  /** API */
  /** Constant that holds the api to retrieve programs */
  readonly PROGRAM_API = 'api/programa/';
  /** Constant that holds the api to retrieve cities */
  readonly CITY_API = 'api/ciudad/';
  /** Constant that holds the api to retrieve regions */
  readonly REGION_API = 'api/provincia/';
  /** Constant that holds the api to retrieve countries */
  readonly COUNTRY_API = 'api/pais/';
  /**ALl Information of universities unlimited*/
  readonly UNIVERSITYUNLIMIT_API = 'api/universidad';
  /**ALl information of universities 10 limit*/
  readonly UNIVERSITY_API = 'api/universidad/';
  /** Constant that holds the api to retrieve universities */
  readonly UNIVERSITIES_API = 'api/universidad/nombres';

  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser';

  /** Constant that holds the api to retrieve the count of  */

  /** CONFIG */
  /** Constant that holds the configuration for the tuition cost slider */
  readonly tuitionSliderOptions: Options = {
    floor: 0,
    ceil: 80000,
  };
  /** Configuration for the dropdown settings of lists that are configured as constants */
  readonly settingsA: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deselecionar todo',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  /** Dropdown that holds the list of options for the discipline filter */
  disciplineDropdownList = [
    {
      value: 'Arts, Design and Architecture',
      name: 'Home.arts-architecture',
      text: 'Arte, diseño y arquitectura',
    },
    {
      value: 'Business and Management',
      name: 'Home.business',
      text: 'Negocios y Administración',
    },
    {
      value: 'Computer Sciences and IT',
      name: 'Home.computer-science',
      text: 'Ciencias de la Computación y Tecnología',
    },
    {
      value: 'Engineering',
      name: 'Home.engineering',
      text: 'Ingeniería',
    },
    {
      value: 'Sciences and Mathematics',
      name: 'Home.sciences-math',
      text: 'Ciencias y Matemáticas',
    },
    {
      value: 'Social Sciences and Humanities',
      name: 'Home.social-sciences',
      text: 'Ciencias Sociales y Humanidades',
    },
    {
      value: 'Education and Sport',
      name: 'Home.education',
      text: 'Educación ó Deporte',
    },
    {
      value: 'Community Services',
      name: 'Home.community',
      text: 'Servicios Comunitarios',
    },
    {
      value: 'Environmental Studies and Earth Sciences',
      name: 'Home.env-studies',
      text: 'Estudios Ambientales y Ciencias de la Tierra',
    },
    {
      value: 'Health',
      name: 'Home.health',
      text: 'Salud',
    },
    {
      value: 'Journalism and Media',
      name: 'Home.journalism',
      text: 'Periodismo y Medios de Comunicación',
    },
    {
      value: 'Law and Politic Sciences',
      name: 'Home.law-politics',
      text: 'Derecho y Ciencias Políticas',
    },
    {
      value: 'Languages',
      name: 'Home.languages',
      text: 'Idiomas',
    },
    {
      value: 'Tourism and Hospitality',
      name: 'Home.tourism-hospitality',
      text: 'Turismo y Hospitalidad',
    },
  ];

  /**
   * Configuration for the dropdown settings that come from the backend
   */
  readonly settingsB = {
    singleSelection: false,
    idField: '_id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deselecionar todo',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };

  /**
   * Variable that holds the list of options for the accreditation filter
   */
  accreditationDropdownList = [
    {
      value: 'Language - Courses & Pathways',
      name: 'Home.Language-Courses-Pathways',
      text: 'Idioma - Cursos y Conexiones',
    },
    {
      value: 'Undergraduate - Preparation & Pathways',
      name: 'Home.Undergraduate-Preparation-Pathways',
      text: 'Pregrado - Preparación y Conexiones',
    },
    {
      value: 'Undergraduate - Certificates & Diplomas',
      name: 'Home.Undergraduate-Certificates-Diplomas',
      text: 'Pregrado - Certificados y Diplomas',
    },
    {
      value: `Undergraduate - Bachelor`,
      name: 'Home.Undergraduate-Bachellors-Degree',
      text: 'Pregrado - Bachellor',
    },
    {
      value: 'Postgraduate - Preparation & Pathways',
      name: 'Home.Postgraduate-Preparation-Pathways',
      text: 'Postgrado - Preparación y Conexiones',
    },
    {
      value: 'Postgraduate - Certificates & Diplomas',
      name: 'Home.Postgraduate-Certificates-Diplomas',
      text: 'Postgrado - Certificados y Diplomas',
    },
    {
      value: 'Postgraduate - Master`s Degree and Doctorates',
      name: 'Home.Postgraduate-Master',
      text: 'Postgrado - Másteres y Doctorados',
    },
  ];

  timeDropdownList = [
    {
      value: 'Less than one Year',
      name: 'Less than 1 Year',
      text: 'Menos de 1 año',
    },
    {
      value: '1 Year',
      name: '1 Year',
      text: '1 año',
    },
    {
      value: '2 Years',
      name: '2 Years',
      text: '2 años',
    },
    {
      value: '3 Years',
      name: '3 Years',
      text: '3 años',
    },
    {
      value: '4 or more Years',
      name: '4 or more Years',
      text: '4 o más años',
    },
  ];

  countriesDropdownList = [
    {
      value: 'Colombia',
      name: 'Colombia',
      text: 'Colombia',
    },
    {
      value: 'Canada',
      name: 'Canada',
      text: 'Canada',
    },
    {
      value: 'Germany',
      name: 'Germany',
      text: 'Alemania',
    },
    {
      value: 'United Kingdom',
      name: 'United Kingdom',
      text: 'Reino Unido',
    },
    {
      value: 'United States',
      name: 'United States',
      text: 'Estados Unidos',
    },
    {
      value: 'Otros Europa',
      name: 'Otros Europa',
      text: 'Otros Europa',
    },
  ];

  /**
   * Variable that holds the list of options for the start time filter
   */
  startTimeDropdownList = [
    {
      value: 1,
      name: 'Programs-list.winter',
      text: 'Invierno',
    },
    {
      value: 2,
      name: 'Programs-list.spring',
      text: 'Primavera',
    },
    {
      value: 3,
      name: 'Programs-list.summer',
      text: 'Verano',
    },
    {
      value: 4,
      name: 'Programs-list.fall',
      text: 'Otoño',
    },
  ];
  /**Active button to show filter programs and universities */
  tabActivo: String = 'Programs_active';
  /**Know actual state of the page */
  ActualPage: Number;
  /** Variable that determines if the current location is the browser or the server */
  isBrowser = false;

  /** FILTER VARIABLES */
  /** Variable that holds the value for the name input */
  name = '';
  cajaName = '';
  /** Variable that holds the value for the country select input */
  country: Array<any> = [];
  /** Variable that holds the value for the city select input */
  city: Array<any> = [];
  /** Variable that holds the value for the region select input */
  region: Array<any> = [];
  /** Variable that holds the value for the university select input */
  university: Array<any> = [];
  /** Variable that holds the value for the accreditation filter */
  accreditation: Array<any> = [];
  /** Variable that holds the value for the discipline filter */
  discipline: Array<any> = [];
  /** Variable that holds the value for the start time filter */
  startTime: Array<any> = [];
  time: Array<any> = [];
  countryFilter: Array<any> = [];
  /** Variable that holds the minimun value for the tuition cost slider */
  minTuitionCost = 0;
  /** Variable that holds the maximum value for the tution cost slider */
  maxTuitionCost = 80000;
  /** Variable that holds the value for the course type filter */
  courseType: string = null;
  /** Variable that holds the value for the Co-op filter */
  coOp = false;
  /** Variable to show or hide the responsive order dropdown menu*/
  dropdownOrderResponsive = false;

  /** DATA VARIABLES */
  /** Variable that holds all the cities */
  allCities: Array<any> = [];
  /** Variable that holds all the regions */
  allRegions: Array<any> = [];
  /** Variable that holds all the universities */
  allUniversities: Array<any> = [];

  /** AUTOCOMPLETE VARIABLES  */
  /** Variable that holds the list of countries that is going to be displayed in the autocomplete filter */
  countries: Array<any> = [];
  /** Variable that holds the list of regions that is going to be displayed in the autocomplete filter */
  regions: Array<any> = [];
  /** Variable that holds the list of cities that is going to be displayed in the autocomplete filter */
  cities: Array<any> = [];
  /** Variabel that holds the list of universities that is going to be displayed in the autocomplete filter */
  universities: Array<any> = [];

  /** QUERY RESULTS */
  /** Variable that holds the list of programs that are going to be displayed */
  programs = [];
  copyProgramans = [];
  //It's to save universities in random object to array
  copyUniversities = [];
  //It's to save universities in random object to array
  copyUniversitiesFilter = [];
  /** Variable tha holds the number of programs that match the query */
  queryNumber = 0;
  queryNumberP = 0;
  queryNumberU = 0;
  //Flag to universities
  bandera: boolean = false;

  /** Varaibles that indicate which filters are active -- Used to hide/show the clear filter pills */
  countryFilterActive = false;
  regionFilterActive = false;
  cityFilterActive = false;
  universityFilterActive = false;
  accreditationFilterActive = false;
  disciplineFilterActive = false;
  courseTypeFilterActive = false;
  startsFilterActive = false;
  timeFilterActive = false;
  countriesFilterActive = false;
  coOpFilterActive = false;
  nameFilterActive = false;

  /** Subscription that checks if the user is logged in */
  logInSubscription: Subscription;
  /** variable that signals if the user is logged in  */
  loggedIn = false;
  /** Variable that holds the list of favourites of the user */
  favourites: string[] = [];
  uniFavourites: string[] = [];
  /** Variable that holds the current ordering criteria
   * 0 --> yearTuition asce
   * 1 --> yearTuition desc
   * 2 --> name A-Z
   * 3 --> name Z-A
   */
  orderCriteria = 0;
  /** Variable that is used to determine if the filter content should be hidden or not */
  filterHidden = true;

  /** Variable that holds the current page of the pagination */
  page = 1;
  /** Variable that shows if the there is a next page */
  nextPage = false;
  /** Variable that shows if there is a previous page */
  previousPage = false;
  /** Variable that holds the role of the current user */
  userRole = '';
  /** Variable that holds the number of documents that are displayed in the page */
  programsPerPage = 10;
  /**To identify if Universities or Programs */
  statePage: 'Programas';

  /** Handles the translation events and updates the data accordingly */
  translationSubcription: Subscription;

  // Declare height and width variables
  scrWidth: any;
  //Array universities filter;
  universitiesFilter: any;
  //Array UniversitiesAll
  universitiesAll: any;
  //Array to filter data
  dataFilter: any;
  // flag filter
  filtrado: boolean = false;
  public idTest: any;
  userTest: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 950) {
      this.navigationService.setIsVisibleSidebar(false);
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private ngxSpinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private programCardUniService: ProgramCardUniService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    this.getScreenSize();
  }

  ngOnDestroy(): void {
    if (this.isBrowser && this.logInSubscription) {
      this.logInSubscription.unsubscribe();
    }
    if (this.translationSubcription) {
      this.translationSubcription.unsubscribe();
    }
    this.navigationService.setIsVisibleSidebar(true);
  }

  async ngOnInit() {
    if (this.isBrowser) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        this.loggedIn = logInStatus;
      });
    }
    if (this.loggedIn && this.isBrowser) {
      this.favourites = JSON.parse(localStorage.getItem('Favoritos'));
      this.uniFavourites = JSON.parse(localStorage.getItem('UniFavoritas'));
    }
    // variable for  populating the countries filter when the initial search was made using the home filter
    const listaPaises = this.activatedRoute.snapshot.queryParamMap.getAll('pais');

    // Setup the autocomplete fields
    this.getCountries({ countryList: listaPaises });
    await this.getCities();
    this.getRegions();
    this.getUniversities();

    // Query according to the url params
    this.activatedRoute.queryParamMap.subscribe((params) => {
      //Always hide the filter after a query so that it does not display in mobile devices
      //
      this.filterHidden = true;
      this.programs = [];
      this.tabActivo = params.get('state') === 'Universities_active' ? 'Universities_active' : 'Programs_active';
      this.programCardUniService.activaCardU = this.tabActivo === 'Universities_active';
      // Prepare the query
      const limit = params.get('limit');
      const name = params.get('name');
      this.name = name;
      this.cajaName = name;
      const city = params.getAll('city');
      let cityFil = city
        ? this.cities.filter((c) => {
            return city.includes(c._id);
          })
        : [];
      this.city = cityFil.map(function (c) {
        return { nombre: c.nombre, _id: c._id };
      });
      const region = params.getAll('region');
      const discipline = params.getAll('discipline');
      const acreditacion = params.getAll('acreditacion');
      const highDemand = params.get('highDemand');
      const country = params.getAll('pais');
      const university = params.getAll('universidad');
      //this.university = university? university.filter(uni=>uni):[];
      const minTuition = params.get('minTuition');
      const maxTuition = params.get('maxTuition');
      const cType = params.get('courseType');
      const starts = params.getAll('starts');
      const time = params.getAll('time');
      const countryFilter = params.getAll('countryFilter');
      const sort = params.get('order');
      const pageParam = params.get('page');
      const coOp = params.get('coOp');
      if (pageParam !== null && pageParam !== '') {
        this.page = Number.parseInt(pageParam);
      } else {
        this.page = 1;
      }
      if (limit !== null) {
        this.programsPerPage = parseInt(limit);
      }
      // If all the parameters are null, and high demand is false, get all the programs
      if (
        region?.length === 0 &&
        city?.length === 0 &&
        discipline?.length === 0 &&
        acreditacion?.length === 0 &&
        (highDemand === null || highDemand === 'false') &&
        country?.length === 0 &&
        countryFilter?.length === 0 &&
        university?.length === 0 &&
        minTuition === null &&
        maxTuition === null &&
        coOp === null
      ) {
        //this.getAllUniversitiesUnlimit();
        this.queryProgram({
          queryProgram: {
            page: this.page,
            limit: this.programsPerPage,
            sort: 0,
          },
        });
        this.queryUniversity({
          queryUniversity: {
            page: this.page,
            limit: this.programsPerPage,
            sort: 0,
          },
        });
      } else {
        // If at least one parameter exists or highDemand is true, build the query request
        const queryProgram = {
          page: this.page,
          limit: this.programsPerPage,
        };
        const queryUniversity = {
          page: this.page,
          limit: this.programsPerPage,
        };
        if (name !== null && name !== '') {
          queryProgram['name'] = name;
          queryUniversity['name'] = name;
          this.nameFilterActive = true;
        } else {
          this.nameFilterActive = false;
        }
        if (region.length > 0) {
          this.regionFilterActive = true;
          queryProgram['provincia'] = region;
          queryUniversity['provincia'] = region;
        } else {
          this.regionFilterActive = false;
        }
        if (city.length > 0) {
          this.cityFilterActive = true;
          queryProgram['ciudad'] = city;
          queryUniversity['ciudad'] = city;
        } else {
          this.cityFilterActive = false;
        }
        if (discipline.length > 0) {
          queryProgram['rama'] = discipline;
          queryUniversity['facultad'] = discipline;
          this.disciplineFilterActive = true;
          this.populateDisciplineFilter(discipline);
        } else {
          this.disciplineFilterActive = false;
        }
        if (acreditacion.length > 0) {
          queryProgram['acreditacion'] = acreditacion;
          this.accreditationFilterActive = true;
          this.populateAccreditationFilter(acreditacion);
        } else {
          this.accreditationFilterActive = false;
        }
        if (highDemand !== 'false') {
          queryProgram['highDemand'] = highDemand;
        }
        if (country.length > 0) {
          queryProgram['pais'] = country;
          this.countryFilterActive = true;
        } else {
          this.countryFilterActive = false;
        }
        if (university.length > 0) {
          queryProgram['universidad'] = university;
          queryUniversity['universidad'] = university;
          this.universityFilterActive = true;
          this.university = university;
        } else {
          this.universityFilterActive = false;
        }
        if (minTuition !== null) {
          queryProgram['programTuitionMin'] = minTuition;
        }
        if (maxTuition !== null) {
          queryProgram['programTuitionMax'] = maxTuition;
        }
        if (cType !== null) {
          queryProgram['courseType'] = cType;
          this.courseTypeFilterActive = true;
        } else {
          this.courseTypeFilterActive = false;
        }
        if (starts.length > 0) {
          this.startsFilterActive = true;
          queryProgram['starts'] = starts;
        } else {
          this.startsFilterActive = false;
        }
        if (time.length > 0) {
          this.timeFilterActive = true;
          queryProgram['time'] = time;
        } else {
          this.timeFilterActive = false;
        }
        if (countryFilter.length > 0) {
          this.countriesFilterActive = true;
          this.populateCountryFilter(countryFilter);
          queryProgram['countryFilter'] = countryFilter;
          queryUniversity['countryFilter'] = countryFilter;
        } else {
          this.countriesFilterActive = false;
        }
        if (coOp === 'true') {
          this.coOpFilterActive = true;
          queryProgram['coOp'] = coOp;
        } else {
          this.coOpFilterActive = false;
        }
        queryProgram['sort'] = sort;
        queryUniversity['sort'] = sort;
        queryUniversity['name'] = name;
        this.orderCriteria = Number(sort);
        this.queryProgram({ queryProgram });
        this.queryUniversity({ queryUniversity });
      }
    });
    this.programsPerPage = 4;
    const currentUrl = window.location.href;
    if (currentUrl.includes('60482c2baea5080017464e85')) {
      this.nameFilterActive = true;
      this.name = 'Paises bajos';
    } else if (currentUrl.includes('60482c3aaea5080017464e86')) {
      this.nameFilterActive = true;
      this.name = 'España';
    }
    const user = JSON.parse(localStorage.getItem('user'));
    this.getTestInternationalization({ userId: user._id });
  }
  /**
   * Retrieves all the programs from the backend
   */
  getAllPrograms(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.PROGRAM_API }).subscribe(
      (response) => {
        // this.programs = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Programs-list.there-problem-querying'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
  /**
   * Query programs in the backend
   * @param query query data
   */
  queryProgram({ queryProgram }: { queryProgram: any }): void {
    this.ngxSpinnerService.show();
    let dataUniversity = [];
    this.apiService.post({ api: this.PROGRAM_API + 'search/', data: queryProgram }).subscribe(
      (response) => {
        this.queryNumberP = response.totalDocs;
        this.nextPage = response.hasNextPage;
        this.previousPage = response.hasPreviousPage;
        const programasValidados = [];
        for (const program of response.docs) {
          if (this.favourites && this.favourites.indexOf(program._id) > -1) {
            program['inFavourites'] = true;
          } else {
            program['inFavourites'] = false;
          }
          if (program.prioridad === null) {
            program['prioridad'] = 5;
          }
          programasValidados.push(program);
        }

        if (this.tabActivo !== 'Universities_active') {
          this.programs = programasValidados;
          this.queryNumber = response.totalDocs;
          this.randomPrograms({ programs: programasValidados });
          this.similarPrograms();
        }
        //To send programs
        // Aqui validar si el filtro es esta activo.. con this.filtrado, si el filtro se ejecuta tienes que correr la el for
        for (let uni of response.docs) {
          //Consulting university ID
          let find = _.find(dataUniversity, ['_id', uni.universidad._id]);
          if (!find) {
            //Not found university result
            dataUniversity.push(uni.universidad);
          } else {
            //Found university result
          }
        }
        //Results universities filter
        this.universitiesFilter = dataUniversity;
        //this.randomUniversitiesFilter({ programs: this.universitiesFilter });
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Programs-list.there-problem-querying'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  queryUniversity({ queryUniversity }: { queryUniversity: any }): void {
    this.ngxSpinnerService.show();
    let dataUniversity = [];
    if (
      (queryUniversity.name !== '' && queryUniversity.name !== undefined) ||
      (queryUniversity.universidad !== undefined &&
        queryUniversity.universidad !== '' &&
        queryUniversity.universidad.length !== 0) ||
      (queryUniversity.provincia !== undefined && queryUniversity.provincia !== '') ||
      (queryUniversity.ciudad !== undefined && queryUniversity.ciudad !== '') ||
      (queryUniversity.countryFilter !== undefined && queryUniversity.countryFilter !== '') ||
      (queryUniversity.facultad !== undefined && queryUniversity.facultad !== '')
    ) {
      this.apiService.post({ api: this.UNIVERSITY_API + 'search/', data: queryUniversity }).subscribe(
        (response) => {
          this.nextPage = response.hasNextPage;
          this.previousPage = response.hasPreviousPage;
          let uValidadas = [];
          for (const u of response.docs) {
            if (this.favourites && this.favourites.indexOf(u._id) > -1) {
              u['inFavourites'] = true;
            } else {
              u['inFavourites'] = false;
            }
            if (u.prioridad === null) {
              u['prioridad'] = 5;
            }
            if (typeof u.about === 'string') {
              uValidadas.push(u);
            }
          }
          this.queryNumberU = response.totalDocs;
          if (this.tabActivo !== 'Programs_active') {
            this.programs = uValidadas;
            this.queryNumber = response.totalDocs;
            this.randomPrograms({ programs: uValidadas });
            this.similarPrograms();
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Programs-list.there-problem-querying'),
          });
        },
        (complete?) => {
          this.ngxSpinnerService.hide();
        }
      );
    } else {
      this.apiService.get({ api: this.UNIVERSITYUNLIMIT_API }).subscribe((response) => {
        for (let uni of response.docs) {
          //Consulting university ID
          // let find = _.find(dataUniversity, ['nombre', uni.nombre]);
          uni['inFavourites'] = this.uniFavourites && this.uniFavourites.indexOf(uni._id) > -1;
          if (!uni.aboutEspaniol) {
            continue;
          } else {
            dataUniversity.push(uni);
          }
        }
        this.allUniversities = dataUniversity;
        this.queryNumberU = dataUniversity.length;
        const nameParam = this.cajaName;
        if (typeof nameParam !== null) {
          this.randomUniversities({ universities: this.allUniversities });
          if (this.tabActivo === 'Universities_active') {
            this.programs = this.copyUniversities;
            this.queryNumber = this.allUniversities.length;
            this.similarPrograms();
          }
        }
      });
    }
  }

  /**
   * Retrieves all the countries from the backend
   * @param coutryList list of countries that were searched using the home filter
   */
  getCountries({ countryList }: { countryList: any[] }): void {
    this.apiService.get({ api: this.COUNTRY_API }).subscribe(
      (response) => {
        this.countries = response;
        // Populate the filter array when countries were filtered in the home filter.
        this.populateCountriesFilter(countryList);
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant(`Programs-list.there-issue-countries${err}`),
        });
      }
    );
  }

  /** Retrieves all the cities from the backend */
  getCities(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.get({ api: this.CITY_API }).subscribe(
        (response) => {
          this.cities = response;
          this.allCities = response;
          this.updateCitiesFilter();
          resolve(true);
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Programs-list.there-issue-cities'),
          });
          reject(false);
        }
      );
    });
  }

  /** Retrieves all the regions from the backend */
  getRegions(): void {
    this.apiService.get({ api: this.REGION_API }).subscribe(
      (response) => {
        this.regions = response;
        this.allRegions = response;
        this.updateRegionsFilter();
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Programs-list.there-issue-cities'),
        });
      }
    );
  }

  updateQueryNumber(queryNumber) {
    //this.queryNumberU = queryNumber;
    //this.queryNumber = queryNumber;
  }

  /** Retrieves all universities in the backend */
  getUniversities(): void {
    this.apiService.get({ api: this.UNIVERSITIES_API }).subscribe(
      (response) => {
        const universities = [];
        for (const university of response) {
          universities.push({
            _id: university._id.nombre,
            nombre: university._id.nombre,
            ciudades: university.ciudades,
            regions: university.provincias,
          });
        }
        this.universities = universities;
        this.allUniversities = universities;
        this.updateUniversitiesFilter();
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Programs-list.there-issue-uni'),
        });
      }
    );
  }

  /** Clears the current values for all filters */
  onClearFilters(): void {
    //Filtrado is to reset the universities filter
    this.filtrado = false;
    // this.bandera = false;
    this.country = [];
    this.countryFilter = [];
    this.region = [];
    this.city = [];
    this.university = [];
    this.accreditation = [];
    this.discipline = [];
    this.startTime = [];
    this.courseType = null;
    this.minTuitionCost = 0;
    this.maxTuitionCost = 80000;
    this.coOp = false;
    this.name = '';
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        highDemand: 'false',
        state: this.tabActivo,
      },
    });
  }

  /**
   * Handles the change event on the radio button
   * @param event radio button change event
   */
  onRadioButtonChange({ event }): void {
    this.courseType = event.value;
  }

  /**
   * Handles the click event on a delete filter pill
   * @param filter name of the filter to be deleted
   */
  onFilterDeleteChange({ filter }: { filter: string }): void {
    switch (filter) {
      case 'country':
        this.countryFilter = [];
        break;
      case 'region':
        this.region = [];
        break;
      case 'city':
        this.city = [];
        break;
      case 'university':
        this.university = [];
        break;
      case 'accreditation':
        this.accreditation = [];
        break;
      case 'courseType':
        this.courseType = null;
        break;
      case 'discipline':
        this.discipline = [];
        break;
      case 'starts':
        this.startTime = [];
        break;
      case 'time':
        this.time = [];
        break;
      case 'countryFilter':
        this.countryFilter = [];
      case 'coOp':
        this.coOp = false;
        break;
      case 'name':
        this.name = '';
        break;
    }
    //To reset the universities state
    if (
      this.countryFilter.length === 0 &&
      this.region.length === 0 &&
      this.city.length === 0 &&
      this.university.length === 0
    ) {
      this.filtrado = false;
      this.onClearFilters();
    }
    this.onSubmitSearch({ page: 1 });
  }

  /**
   * Handles the selection of a element in the multi-select dropdown filters
   * @param item removed element
   * @param filter filter name
   */
  onSelect(item: any, filter: string): void {
    //Flag to activate filter
    this.filtrado = true;
    switch (filter) {
      case 'country':
        // Delete the regions that are selected and DO NOT belong to the country that was selected
        // Get the ids for all the regions that don't belong to this country
        const regionsId = [];
        for (const region of this.regions) {
          if (region.pais.nombre !== this.countryFilter[0].value) {
            regionsId.push(region._id);
          }
        }

        // Delete the regions from the model of the regions multi-select
        const regionsCopy = this.region.slice();
        for (const regionId of regionsId) {
          const i = regionsCopy.map((c) => c._id).indexOf(regionId);
          if (i >= 0) {
            regionsCopy.splice(i, 1);
          }
        }
        this.region = regionsCopy;

        // Delete the cities that are selected and DO NOT belong to the country that was selected
        // Get the ids for all the cities that don't belong to this country
        const citiesId = [];
        for (const city of this.cities) {
          if (city.pais._id !== item._id) {
            citiesId.push(city._id);
          }
        }
        // Delete the cities from the model of the cities multi-select
        const citiesCopy = this.city.slice();
        for (const cityId of citiesId) {
          const i = citiesCopy.map((c) => c._id).indexOf(cityId);
          if (i >= 0) {
            citiesCopy.splice(i, 1);
          }
        }
        this.city = citiesCopy;
        // Delete the universities that are selected and DO NOT belong to the selected countries
        // Get the ids for all the universities that don't belong to this country
        const unisId = [];
        let selectedCountries = this.country.map((c) => c._id);
        let uniCountries;
        for (const uni of this.universities) {
          uniCountries = uni.ciudades?.map((c) => c.pais) || uni.ciudad?.pais?._id;
          for (const country of uniCountries) {
            if (selectedCountries.indexOf(country) < 0) {
              unisId.push(uni._id);
              break;
            }
          }
        }
        // Delete the universities from the model of the universities multi-select
        const universityCopy = this.university.slice();
        for (const uniId of unisId) {
          const i = universityCopy.map((c) => c._id).indexOf(uniId);
          if (i >= 0) {
            universityCopy.splice(i, 1);
          }
        }
        this.university = universityCopy;
        break;

      case 'region':
        // Delete the cities that are selected and DO NOT belong to the region that was selected
        // Get the ids for all the cities that don't belong to this region
        const citiesCopyId = [];
        for (const city of this.cities) {
          if (!city.provincia || city.provincia._id !== item._id) {
            citiesCopyId.push(city._id);
          }
        }
        // Delete the cities from the model of the cities multi-select
        const citiesCopyRegion = this.city.slice();
        for (const cityId of citiesCopyId) {
          const i = citiesCopyRegion.map((c) => c._id).indexOf(cityId);
          if (i >= 0) {
            citiesCopyRegion.splice(i, 1);
          }
        }
        this.city = citiesCopyRegion;
        // Delete the universities that are selected and DO NOT belong to the selected regions
        // Get the ids for all the universities that don't belong to this region
        const unisIdRegion = [];
        let selectedRegions = this.region.map((c) => c._id);
        let uniRegions;
        for (const uni of this.universities) {
          uniRegions = uni.ciudades?.map((c) => c.provincia) || uni.ciudad?.provincia._id;
          for (const region of uniRegions) {
            if (selectedRegions.indexOf(region) < 0) {
              unisIdRegion.push(uni._id);
              break;
            }
          }
        }
        // Delete the universities from the model of the universities multi-select
        const universityCopyRegion = this.university.slice();
        for (const uniId of unisIdRegion) {
          const i = universityCopyRegion.map((c) => c._id).indexOf(uniId);
          if (i >= 0) {
            universityCopyRegion.splice(i, 1);
          }
        }
        this.university = universityCopyRegion;
        break;
      case 'city':
        // Delete the universities that are selected and don't belong to the cities that are selected
        // Get the ids of all the universities that belong to this city
        const uniIds = [];
        let selectedCities = this.city.map((c) => c._id);
        let uniCities;
        for (const uni of this.universities) {
          uniCities = uni.ciudades?.map((c) => c._id) || uni.ciudad?._id;
          for (const city of uniCities) {
            if (selectedCities.indexOf(city) < 0) {
              uniIds.push(uni._id);
              break;
            }
          }
        }
        // Delete the universities from the model of the multi-select
        const uniCopy = this.university.slice();
        for (const id of uniIds) {
          const i = uniCopy.map((u) => u._id).indexOf(id);
          if (i >= 0) {
            uniCopy.splice(i, 1);
          }
        }
        this.university = uniCopy;
        break;
    }
    this.updateCitiesFilter();
    this.updateRegionsFilter();
    this.updateUniversitiesFilter();
  }

  /**
   * Handles the de-selection of a element in the multi-select dropdown filters
   * @param item removed element
   * @param filter filter name
   */
  onDeSelect(item: any, filter: string): void {
    //To SetUp Flag
    // this.bandera = false;
    switch (filter) {
      case 'country':
        // Delete the regions that are selected and belong to the country that is not selected anymore
        // Get the ids for all the regions that belong to this country
        const regionsId = [];
        for (const region of this.regions) {
          if (region.pais._id === item._id) {
            regionsId.push(region._id);
          }
        }
        // Delete the regions from the model of the regions multi-select
        const regionsCountry = this.region.slice();
        for (const regionId of regionsId) {
          const i = regionsCountry.map((c) => c._id).indexOf(regionId);
          if (i >= 0) {
            regionsCountry.splice(i, 1);
          }
        }
        this.region = regionsCountry;
        // Delete the cities that are selected and belong to the country that is not selected anymore
        // Get the ids for all the cities that belong to this country
        const citiesIdCountry = [];
        for (const city of this.cities) {
          if (city.pais._id === item._id) {
            citiesIdCountry.push(city._id);
          }
        }
        // Delete the cities from the model of the cities multi-select
        const citiesCopyCountry = this.city.slice();
        for (const cityId of citiesIdCountry) {
          const i = citiesCopyCountry.map((c) => c._id).indexOf(cityId);
          if (i >= 0) {
            citiesCopyCountry.splice(i, 1);
          }
        }
        this.city = citiesCopyCountry;
        // Delete the universities that are selected and DO NOT belong to the selected countries
        // Get the ids for all the universities that don't belong to this region
        const unisIdCountry = [];
        let selectedCountries = this.country.map((c) => c._id);
        let uniCountries;
        for (const uni of this.universities) {
          uniCountries = uni.ciudades.map((c) => c.pais);
          for (const region of uniCountries) {
            if (selectedCountries.indexOf(region) < 0) {
              unisIdCountry.push(uni._id);
              break;
            }
          }
        }
        // Delete the universities from the model of the universities multi-select
        const universityCopyCountry = this.university.slice();
        for (const uniId of unisIdCountry) {
          const i = universityCopyCountry.map((c) => c._id).indexOf(uniId);
          if (i >= 0) {
            universityCopyCountry.splice(i, 1);
          }
        }
        this.university = universityCopyCountry;
        break;
      case 'region':
        // Delete the cities that are selected and belong to the region that is not selected anymore
        // Get the ids for all the cities that belong to this region
        const citiesId = [];
        for (const city of this.cities) {
          if (!city.provincia || city.provincia._id === item._id) {
            citiesId.push(city._id);
          }
        }
        // Delete the cities from the model of the cities
        const citiesCopy = this.city.slice();
        for (const cityId of citiesId) {
          const i = citiesCopy.map((c) => c._id).indexOf(cityId);
          if (i >= 0) {
            citiesCopy.splice(i, 1);
          }
        }
        this.city = citiesCopy;
        // Delete the universities that are selected and DO NOT belong to the selected countries
        // Get the ids for all the universities that don't belong to this region
        const unisId = [];
        let selectedRegions = this.region.map((c) => c._id);
        let uniRegions;
        for (const uni of this.universities) {
          uniRegions = uni.ciudades.map((c) => c.provincia);
          for (const region of uniRegions) {
            if (selectedRegions.indexOf(region) < 0) {
              unisId.push(uni._id);
              //break;
            }
          }
        }
        // Delete the universities from the model of the universities multi-select
        const universityCopy = this.university.slice();
        for (const uniId of unisId) {
          const i = universityCopy.map((c) => c._id).indexOf(uniId);
          if (i >= 0) {
            universityCopy.splice(i, 1);
          }
        }
        this.university = universityCopy;
        break;
      case 'city':
        // Delete the universities that are selected and don't belong to the cities that are selected
        // Get the ids of all the universities that belong to this city
        const uniIds = [];
        let selectedCities = this.city.map((c) => c._id);
        let uniCities;
        for (const uni of this.universities) {
          uniCities = uni.ciudades.map((c) => c._id);
          for (const city of uniCities) {
            if (selectedCities.indexOf(city) < 0) {
              uniIds.push(uni._id);
              break;
            }
          }
        }
        // Delete the universities from the model of the multi-select
        const uniCopy = this.university.slice();
        for (const id of uniIds) {
          const i = uniCopy.map((u) => u._id).indexOf(id);
          if (i >= 0) {
            uniCopy.splice(i, 1);
          }
        }
        this.university = uniCopy;
        break;
    }
    this.updateCitiesFilter();
    this.updateRegionsFilter();
    this.updateUniversitiesFilter();
  }
  /**
   * Handle the unselection of all elements in the dropdown filters
   * @param items deselect all event
   * @filter filter name
   */
  onDeSelectAll(items: any, filter: string): void {
    //To change the filter state
    this.filtrado = false;
    // this.bandera = false;
    switch (filter) {
      case 'discipline':
        this.discipline = [];
        break;
      case 'university':
        this.university = [];
        break;
      case 'accreditation':
        this.accreditation = [];
        break;
      case 'country':
        this.countryFilter = [];
        break;
      case 'city':
        this.city = [];
        break;
      case 'region':
        this.region = [];
        break;
    }
    this.updateCitiesFilter();
    this.updateRegionsFilter();
    this.updateUniversitiesFilter();
  }

  /**
   * Handles the apply filters click event
   * @param page page;
   */

  onSubmitSearch({ page }: { page: number }): void {
    const regionParam = this.region.map((r) => r._id);
    const cityParam = this.city.map((c) => c._id);
    const countryParam = this.country.map((c) => c._id);
    const universityParam = this.university.map((m) => m._id || m);
    const accreditationParam = this.accreditation.map((a) => a.value);
    const disciplineParam = this.discipline.map((d) => d.value);
    const startsParam = this.startTime.map((s) => s.value);
    const timeParam = this.time.map((s) => s.value);
    const countriesParam = this.countryFilter.map((s) => s.value);
    // const tabActivo = this.tabActivo;
    const coOp = this.coOp;
    this.name = this.name?.trim();

    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        region: regionParam,
        city: cityParam,
        discipline: disciplineParam,
        acreditacion: accreditationParam,
        pais: countryParam,
        universidad: universityParam,
        starts: startsParam,
        time: timeParam,
        countryFilter: countriesParam,
        minTuition: this.minTuitionCost,
        maxTuition: this.maxTuitionCost,
        order: this.orderCriteria,
        page,
        coOp,
        name: this.name,
        limit: this.programsPerPage,
        state: this.tabActivo,
      },
    });
    this.similarPrograms();
  }
  /**
   * Populates the accreditation filter values when the filter of the home was the one used
   * @param list of accreditations that were applied in the home filter
   */
  populateAccreditationFilter(accreditations: any[]): void {
    if (this.accreditation.length < 1) {
      const accreditationsArray = this.accreditationDropdownList.filter((e) => {
        return accreditations.indexOf(e.value) > -1;
      });
      this.accreditation = accreditationsArray;
    }
  }

  /**
   * Populates the accreditation filter values when the filter of the home was the one used
   * @param list of accreditations that were applied in the home filter
   */
  populateDisciplineFilter(disciplines: any[]): void {
    if (this.discipline.length < 1) {
      const disicplinesArray = this.disciplineDropdownList.filter((e) => {
        return disciplines.indexOf(e.value) > -1;
      });
      this.discipline = disicplinesArray;
    }
  }

  /**
   * Populates the country filter values when the filter of the home was the one used
   * @param list of country that were applied in the home filter
   */
  populateCountryFilter(country: any[]): void {
    if (this.countryFilter.length < 1) {
      const countryArray = this.countriesDropdownList.filter((e) => {
        return country.indexOf(e.value) > -1;
      });
      this.countryFilter = countryArray;
    }
    //Añadi esto
    this.updateCitiesFilter();
    this.updateRegionsFilter();
    this.updateUniversitiesFilter();
  }
  /**
   * Populates the countries filter values when the filter of the home was the one used
   * @param list of countries that were applied in the home filter
   */
  populateCountriesFilter(countries: any[]): void {
    if (this.country.length < 1) {
      const countryArray = this.countries.filter((e) => {
        return countries.indexOf(e._id) > -1;
      });
      this.country = countryArray;
    }
    this.updateCitiesFilter();
    this.updateRegionsFilter();
    this.updateUniversitiesFilter();
  }
  /**
   * Changes the order in which elements are organized
   */
  onOrderingChange(): void {
    this.onSubmitSearch({ page: 1 });
  }
  onOrder() {
    this.dropdownOrderResponsive = !this.dropdownOrderResponsive;
  }
  /**
   * Hides or shows the left side filter
   */
  onHideFilterChange(): void {
    this.filterHidden = !this.filterHidden;
  }
  /**
   * Handles page changes in the paginations controls
   */
  onPageChange(event): void {
    this.onSubmitSearch({ page: event });
  }
  /**
   * Handles the change event in the programs per page selector
   * @param event
   */
  onPerPageChange(event): void {
    this.programsPerPage = event;
    this.onSubmitSearch({ page: 1 });
  }
  /**
   * Updates the regions of the dropdown list
   */
  updateRegionsFilter(): void {
    let filtered: any[];
    //  if (this.country.length < 1) {
    if (this.countryFilter.length < 1) {
      filtered = this.allRegions;
    } else {
      filtered = this.allRegions.filter((region) => {
        if (this.countryFilter.map((r) => r.value).indexOf(region.pais.nombre) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.regions = filtered;
  }
  /**
   * Updates the cities of the dropdown list
   */
  updateCitiesFilter(): void {
    let filtered: any[];
    if (this.countryFilter.length < 1 && this.region.length < 1) {
      filtered = this.allCities;
    } else if (this.region.length > 0) {
      filtered = this.allCities.filter((city) => {
        if (city.provincia && this.region.map((c) => c._id).indexOf(city.provincia._id) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    } else if (this.countryFilter.length > 0) {
      filtered = this.allCities.filter((city) => {
        if (this.countryFilter.map((c) => c.value).indexOf(city.pais.nombre) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.cities = filtered;
  }

  /**
   * Updates the universities of the dropdown list
   */
  updateUniversitiesFilter(): void {
    let filtered;
    let countriesA;

    if (this.city.length < 1 && this.countryFilter.length < 1 && this.region.length < 1) {
      filtered = this.allUniversities;
      this.universities = filtered;
      // return filtered;
    }
    if (this.countryFilter.length > 0) {
      filtered = this.allUniversities.filter((university) => {
        let countries = this.countryFilter.map((c) => c.value);
        //Get ID of the countries
        countriesA = countries.map((count) => {
          for (const country of this.countries) {
            if (country.nombre === count) {
              return country._id;
            }
          }
        });
        for (const country of countriesA) {
          if (university.ciudades?.map((c) => c.pais).indexOf(country) >= 0) {
            return true;
          } else if (university.ciudad?.pais?._id.indexOf(country) >= 0) {
            university._id = university.nombre;
            return true;
          }
        }
        return false;
      });
      this.universities = filtered;
    }
    if (this.region.length > 0) {
      filtered = this.allUniversities.filter((university) => {
        let regions = this.region.map((r) => r._id);
        for (const region of regions) {
          if (university.regions?.map((r) => r._id).indexOf(region) >= 0) {
            return true;
          } else if (university.ciudad?.provincia?._id.indexOf(region) >= 0) {
            university._id = university.nombre;
            return true;
          }
        }
        return false;
      });
      this.universities = filtered;
    }
    if (this.city.length > 0) {
      filtered = this.allUniversities.filter((university) => {
        let cities = this.city.map((c) => c._id);
        for (const city of cities) {
          if (university.ciudades?.map((c) => c._id).indexOf(city) >= 0) {
            return true;
          } else if (university.ciudad?._id.indexOf(city) >= 0) {
            university._id = university.nombre;
            return true;
          }
        }
        return false;
      });
      this.universities = filtered;
    }
  }
  /**
   * Sets up  the translations of the dropdowns
   */
  setupTranslations(): void {
    const accreditations = this.accreditationDropdownList.slice();
    for (let i = 0; i < accreditations.length; i++) {
      accreditations[i].text = this.translate.instant(this.accreditationDropdownList[i].name);
    }
    this.accreditationDropdownList = accreditations;

    const disciplines = this.disciplineDropdownList.slice();
    for (let i = 0; i < disciplines.length; i++) {
      disciplines[i].text = this.translate.instant(this.disciplineDropdownList[i].name);
    }
    this.disciplineDropdownList = disciplines;

    const startTimes = this.startTimeDropdownList.slice();
    for (let i = 0; i < startTimes.length; i++) {
      startTimes[i].text = this.translate.instant(this.startTimeDropdownList[i].name);
    }
    this.startTimeDropdownList = startTimes;

    const time = this.timeDropdownList.slice();
    for (let i = 0; i < time.length; i++) {
      time[i].text = this.translate.instant(this.timeDropdownList[i].name);
    }
    this.timeDropdownList = time;

    const countryFilter = this.countriesDropdownList.slice();
    for (let i = 0; i < countryFilter.length; i++) {
      countryFilter[i].text = this.translate.instant(this.countriesDropdownList[i].name);
    }
    this.countriesDropdownList = countryFilter;
  }

  public topProgramSelect({ event }) {
    this.orderCriteria = event ? 0 : this.orderCriteria;
  }

  /**
   * The method is responsible for storing the first three programs in the cookies
   */
  public similarPrograms(): void {
    if (this.programs.length >= 1) {
      let programs: any = [];
      for (let i = 0; i < this.programs.length && i < 3; i++) {
        programs.push(this.programs[i]._id);
      }
      this.cookieService.delete('programs');
      this.cookieService.set('programs', JSON.stringify(programs));
    }
  }

  public randomPrograms({ programs }): void {
    let groups = _.groupBy(programs, 'prioridad');
    let newPrograms: any = [];
    Object.keys(groups).forEach((element) => {
      groups[element] = groups[element].sort(() => Math.random() - 0.5);
      newPrograms.push(...groups[element]);
    });
    this.programs = newPrograms;
    // this.copyProgramans = newPrograms;
  }

  public randomUniversities({ universities }): void {
    let groups = _.groupBy(universities, 'prioridad');

    let newUniversities: any = [];
    Object.keys(groups).forEach((element) => {
      groups[element] = groups[element].sort(() => Math.random() - 0.5);
      newUniversities.push(...groups[element]);
    });
    this.copyUniversities = newUniversities;
  }

  public randomUniversitiesFilter({ programs }): void {
    let groups = _.groupBy(programs, 'prioridad');
    let newUniversitiesF: any = [];
    Object.keys(groups).forEach((element) => {
      groups[element] = groups[element].sort(() => Math.random() - 0.5);
      newUniversitiesF.push(...groups[element]);
    });
    // this.programs = newUniversities;
    this.copyUniversitiesFilter = newUniversitiesF;
  }

  cardActive(tipo) {
    this.tabActivo = tipo;
    this.programCardUniService.activaCardU = this.tabActivo === 'Universities_active';
    this.onSubmitSearch({ page: 1 });
  }

  goBack(): void {
    this.navigationService.navigateTo({ path: 'home' });
  }

  // gets the last user test created
  getTestInternationalization({ userId }) {
    this.apiService.get({ api: `${this.GET_TEST}/${userId}` }).subscribe((response) => {
      this.userTest = response;
    });
  }
}
