<main>
    <div class="container-search">
        <h5 class="title-component">Webinars</h5>
        <div></div>
        <input (ngModelChange)="onSubmitSearch($event)" type="text" placeholder="Busca aquí" [(ngModel)]="search">
    </div>

    <div class="container-cards">
        <div *ngFor="let webi of webinars">
            <app-card-view-webinar (applyWebinar)="onApplyWebinar({ webinar: webi})" [webinar]="webi">
            </app-card-view-webinar>
        </div>

    </div>
</main>