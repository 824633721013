<div class="row backgroundSkillSuggestion">
  <div class="row">
    <div class="col-xl-1">
      <label (click)="returnDashBoard()">
        <img class="back-img" src="/assets/wizard-images/back.png" alt="volver" />
      </label>
    </div>
    <div class="row col-xl-11" *ngIf="selectedSkill">
      <div class="col-xl-12">
        <h3 class="evaluated-profile">Sugerencias de fortalecimieto de {{ contentCompetition.title }}</h3>
      </div>
      <div class="col-xl-12" style="margin-top: 2%">
        <p class="skill-description">
          {{ contentCompetition.suggestionDescription }}
        </p>
      </div>
      <div class="col-xl-12" style="margin-top: 2%">
        <ul>
          <li *ngFor="let suggestion of contentCompetition.suggestions" class="suggestion">
            <p class="skill-description">{{ suggestion }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-1"></div>
  </div>
</div>
