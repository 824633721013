import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/public/services/alert.service';
import { S3Service } from 'src/app/public/services/s3.service';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit, OnChanges {

  @Input() stepData: any;
  @Output() updateUploadedDocument = new EventEmitter<any>();

  /** Variable that holds the list of substeps */
  substeps = [{
    name: `1. ${this.translate.instant("Travel.acquire-tickets")}`,
    status: 'not-started'
  }, {
    name: `2. ${this.translate.instant("Travel.find-acco")}`,
    status: 'not-started'
  }, {
    name: `3. ${this.translate.instant("Travel.pay-acco")}`,
    status: 'not-started'
  }];

  /** Variable that holds the steps data */
  prepareYourTravel = {
    compraTiquetes: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      ticket: '',
    },
    definicionAlojamiento: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      comprobanteAlojamiento: '',
    },
    pagoAlojamiento: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      comprobantePagoAlojamiento: '',
    },
  };

  /** Variable that holds the current selected tab */
  selected = 0;

  /** Variable that holds the application id of the current application */
  applicationId: string;

  constructor(
    public translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private s3Service: S3Service,
  ) { }

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    if (this.stepData !== undefined) {
      // Setup first substep
      this.prepareYourTravel.compraTiquetes.completado = this.stepData.compraTiquetes.completado;
      if (this.prepareYourTravel.compraTiquetes.completado) {
        this.substeps[0].status = 'completed';
      } else {
        this.substeps[0].status = 'not-started';
      }
      if (this.stepData.compraTiquetes.ticket !== undefined) {
        this.prepareYourTravel.compraTiquetes.ticket = this.stepData.compraTiquetes.ticket;
      }

      // Setup second substep
      this.prepareYourTravel.definicionAlojamiento.completado = this.stepData.definicionAlojamiento.completado;
      if (this.prepareYourTravel.definicionAlojamiento.completado) {
        this.substeps[1].status = 'completed';
      } else {
        this.substeps[1].status = 'not-started';
      }
      if (this.stepData.definicionAlojamiento.comprobanteAlojamiento !== undefined) {
        this.prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento = this.stepData.definicionAlojamiento.comprobanteAlojamiento;
      }

      // setup thir substep
      this.prepareYourTravel.pagoAlojamiento.completado = this.stepData.pagoAlojamiento.completado;
      if (this.prepareYourTravel.pagoAlojamiento.completado) {
        this.substeps[2].status = 'completed';
      } else {
        this.substeps[2].status = 'not-started';
      }
      if (this.stepData.pagoAlojamiento.comprobantePagoAlojamiento !== undefined) {
        this.prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento = this.stepData.pagoAlojamiento.comprobantePagoAlojamiento;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  /**
   * Changes the current selected tab
   * @param index index of the new selected tab
   */
  changeSelected({ index }: { index: number }): void {
    this.selected = index;
  }

   /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event, nombreDoc }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant("Academic-history.you-must-upload-1-file"));
    } else {
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const key = `application/${this.applicationId}/travel/${nombreDoc}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinnerService.hide();
          this.alertService.showError({ msg: 'There was an error uploading the document, please try again later or contact support' });
        } else {
          // Assign the value to the corresponding component
          switch (nombreDoc) {
            case 'ticket':
              this.updateUploadedDocument.emit({ docName: 'ticket', url: data.Location });
              break;
            case 'accomodation-proof':
              this.updateUploadedDocument.emit({ docName: 'accomodation-proof', url: data.Location });
              break;
            case 'accomodation-payment-proof':
              this.updateUploadedDocument.emit({ docName: 'accomodation-payment-proof', url: data.Location });
              break;
          }
          this.ngxSpinnerService.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

}
