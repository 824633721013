import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ExperiencesExampleModalComponent } from './exampleModal/experienceExampleModal.component';
import { ExperiencePlannerModal } from '../experiencesPlannner/experiencePlannerModal/experiencePlannerModal.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-experience-examples',
  templateUrl: './experiencesExample.component.html',
  styleUrls: ['./experiencesExample.component.scss'],
})
export class ExperiencesExampleComponent implements OnInit {
  private subscription: Subscription;
  readonly experiencesApi = 'api/getExperience/';
  readonly skillsApi = 'api/getSkill/';
  readonly categoriesApi = 'api/getCategory/';
  experiences: any;
  skills: any;
  selectedExperience: any;
  selectedSkills: string[] = [];
  selectedCategory: string;
  filteredExperiences: any[] = [];
  selectedSkill: string;
  skill: any;
  selectedSkillsOption: any;
  selectedCategoryOption: any;
  categories: any;
  user = JSON.parse(localStorage.getItem('user'));
  searchTerm: string = '';

  constructor(private apiService: ApiService, private dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit() {
    this.getExperiences();
    this.getSkills();
    this.getCategories();
    this.route.queryParams.subscribe((params) => {
      this.skill = params['skill'];
      if (this.skill) {
        this.toggleSkillSelection(this.skill);
      }
    });
  }

  openModal(experienceData: any): void {
    const dialogRef = this.dialog.open(ExperiencesExampleModalComponent, {
      width: '40vw',
      data: { experienceData },
    });
  }

  openExperiencePlanner(experienceData: any): void {
    const experienceName = experienceData.experienceName;
    const dialogRef = this.dialog.open(ExperiencePlannerModal, {
      width: '40vw',
      data: { experienceData },
    });
  }

  isSelected(skill: string): boolean {
    return this.selectedSkills.includes(skill);
  }

  toggleCategorySelection(selectedCategories: string[]) {
    this.selectedCategoryOption = selectedCategories;
    this.filterExperiences();
  }

  toggleSkillSelection(selectedSkills: string[]) {
    this.selectedSkillsOption = selectedSkills;
    this.filterExperiences();
  }

  filterExperiences() {
    const selectedCategoriesLength = this.selectedCategoryOption?.length ?? 0;
    const selectedSkillsLength = this.selectedSkillsOption?.length ?? 0;

    this.filteredExperiences = this.experiences.filter((experience) => {
      const categoryMatch =
        selectedCategoriesLength === 0 ||
        (experience.experienceCategory &&
          experience.experienceCategory.some((category) => this.selectedCategoryOption.includes(category)));
      const skillMatch =
        selectedSkillsLength === 0 ||
        this.selectedSkillsOption.some((skill) => {
          if (typeof experience.requiredSkills === 'string') {
            const skillsArray = experience.requiredSkills.split(',').map((skill) => skill.trim());
            return skillsArray.includes(skill);
          } else if (Array.isArray(experience.requiredSkills)) {
            return experience.requiredSkills.includes(skill);
          } else {
            return false;
          }
        });
      const searchTermMatch =
        !this.searchTerm || experience.experienceName.toLowerCase().includes(this.searchTerm.toLowerCase());

      return categoryMatch && skillMatch && searchTermMatch;
    });

    this.sortExperiencesBySkillsScore();
  }

  sortExperiencesBySkillsScore() {
    this.filteredExperiences = this.filteredExperiences.map((experience) => {
      return {
        ...experience,
        requiredSkills: experience.requiredSkills.join(', '),
      };
    });

    this.filteredExperiences.sort((a, b) => b.skillsScore - a.skillsScore);
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }

  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.experiencesApi }).subscribe(
        (response) => {
          this.experiences = response;
          this.filterExperiences();
          this.sortExperiencesBySkillsScore();
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('Unexpected error:', error);
    }
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsApi }).subscribe(
        (response) => {
          this.skills = response;
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las habilidades',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('Unexpected error:', error);
    }
  }

  getCategories() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.categoriesApi }).subscribe(
        (response) => {
          this.categories = response;
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las categorias',
          });

          console.error('Error fetching categories:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('Unexpected error:', error);
    }
  }

  trimHtmlContent(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.length > 100 ? text.substring(0, 100) + '...' : text;
  }
}
