<div class="row">
  <div class="col-12">
    <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
    <h4 class="titulo-wizard">Elige tu objetivo</h4>
    <p>
      Creemos juntos tu perfil para maximizar tu éxito de admisión a la universidad de tus sueños. Elige el objetivo
    </p>
    <hr class="hrLine" />
  </div>
</div>
<div class="row">
  <div
    class="card element-card"
    [ngClass]="selectedOption === 'Pregrado' ? 'cardActiva' : ''"
    (click)="selectCard('Pregrado')">
    <div class="row no-gutters h-100" (click)="selectCard('Pregrado')">
      <div class="col-md-6 my-auto imgPregrado"></div>
      <div class="col-md-5 my-auto">
        <div class="text-center">
          <h5 class="card-title">Pregrado</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div
    class="card element-card"
    [ngClass]="selectedOption === 'Postgrado' ? 'cardActiva' : ''"
    (click)="selectCard('Postgrado')">
    <div class="row no-gutters h-100" (click)="selectCard('Postgrado')">
      <div class="col-md-6 my-auto imgPost"></div>
      <div class="col-md-5 my-auto">
        <div class="text-center">
          <h5 class="card-title">Postgrado</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 text-center">
  <button class="btn-continuar" (click)="emitNextButtonClick()">Continuar</button>
  <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
</div>
