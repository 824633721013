import { isPlatformBrowser, Location } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  HostListener,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CityComponent } from '../city/city.component';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { SeoService } from '../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss'],
})
export class UniversityComponent implements OnInit {
  private readonly API_UNIVERSITY = 'api/universidad';
  private readonly API_UNIVERSITY_SLUG = 'api/universidad/slug';

  thumbnails = [];
  videos: any;
  photos: any;
  showMore: boolean = false;
  /** University info object */
  public university = {
    _id: '',
    about: '',
    applicationFee: 0,
    logo: '',
    becas: '',
    campus: '',
    direccion: '',
    ciudad: {
      _id: '',
      estado: '',
      nombre: '',
      pais: {
        nombre: '',
        moneda: '',
      },
    },
    estudiantes: '',
    facultades: '',
    fotos: [],
    fundacion: '',
    grupo: '',
    internacionales: '',
    latitud: '',
    link: '',
    linkVideo: '',
    livingCost: 0,
    longitud: '',
    nacional: '',
    nombre: '',
    practicaLaboral: '',
    programas: '',
    programasInternacionales: 0,
    qs: '',
    shangai: '',
    slug: '',
    times: '',
    tipo: '',
    tipoProgramas: '',
    workStudying: '',
    pgwp: '',
    descripcion: 'No description',
    videosResuelve: '',
    titulosResuelve: '',
    costsPerYear: '',
    tuition: '',
    keyLocation: '',
    keyFeatures: '',
    Probabilidad: '0',
    PromAjuste: 0
  };

  /** Language */
  lang;
  /** API to send contact request emails emails */
  readonly CONTACT_API = 'email/send-contact-mail';
  /** Variable that holds the contact request form */
  contactRequestForm: FormGroup;

  isBrowser: Boolean;

  box_dudas = document.getElementById('boxDudas')
  box_videphoto = document.getElementById('boxVideoPhoto')

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fb: FormBuilder,
    private seo: SeoService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private location: Location
  ) {
    this.getLanguage();
    this.setSEO();
  }

  ngOnInit(): void {
    this.showMore = false
    this.contactRequestForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      motivo: ['', [Validators.required]],
    });

    this.activatedRoute.url.subscribe(url => {
      this.getUniversity();
    });

    this.changeColVideoPhoto()
  }

  /**
   * Gets language from local storage
   */
  getLanguage(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      const l = localStorage.getItem('lang');
      if (l) {
        this.lang = l;
      } else {
        this.lang = 'en';
      }
    }
  }

  ngOnDestroy(): void { }

  /** Set the SEO information with SEO Service */
  setSEO(): void {
    this.seo.setSEO({
      title: this.university.nombre || 'University',
      description: this.university.about || 'University on U360',
      url: 'university/' + this.university.slug,
      image: this.university.logo,
      main: false,
    });
  }

  /**
   * Gets university
   */
  getUniversity() {
    try {
      const response = this.activatedRoute.snapshot.data.university;

      this.university._id = response._id;
      this.university.about =
        this.lang === 'en' ? response.about : response.aboutEspaniol;
      this.university.applicationFee = response.applicationFee;
      this.university.becas = response.becas;
      this.university.ciudad = response.ciudad;
      this.university.estudiantes = response.estudiantes;
      this.university.facultades = this.stringOfFaculties(response.facultades);
      this.university.direccion = response.direccion;
      // refactor media files
      // get separated
      const photos = response.fotos.filter((e) => e.tipo === 'foto');
      const videos = response.fotos.filter((e) => e.tipo === 'video');
      this.photos = photos;
      this.videos = videos;
      this.university["videosMedia"] = this.videos;
      this.university["fotosMedia"] = this.photos;
      // add them in order, photos first, videos later
      this.university.fotos = [...photos, ...videos];
      // add only urls to thumbnails
      this.thumbnails = [...photos.map((p) => p.url)];
      // get video previews and push them to thumbnails
      for (const vid of videos) {
        const code = vid.url.split('/embed/');
        this.thumbnails.push(
          'https://img.youtube.com/vi/' + code[1] + '/1.jpg'
        );
      }
      this.university.fundacion = response.fundacion;
      this.university.grupo = response.grupo;
      this.university.internacionales = response.internacionales;
      this.university.latitud = response.latitud;
      this.university.link = response.link;
      this.university.linkVideo = response.linkVideo;
      this.university.livingCost = response.livingCost;
      this.university.logo = response.logo;
      this.university.longitud = response.longitud;
      this.university.nacional = response.nacional;
      this.university.nombre = response.nombre;
      this.university.practicaLaboral = response.practicaLaboral;
      this.university.pgwp = response.pgwp;
      this.university.programas = response.programas;
      this.university.programasInternacionales = response.programasInternacionales;
      this.university.qs = response.qs;
      this.university.shangai = response.shangai;
      this.university.slug = response.slug;
      this.university.times = response.times;
      this.university.tipo = response.tipo;
      this.university.tipoProgramas = response.tipoProgramas;
      this.university.workStudying = response.workStudying;
      this.university.videosResuelve = response.videosResuelve;
      this.university.titulosResuelve = response.titulosResuelve;
      this.university.costsPerYear = response.costsPerYear;
      this.university.tuition = response.tuition;
      this.university.keyFeatures =
        this.lang === 'en'
          ? response.caracteristicasClave
          : response.caracteristicasClaveEspanol;
      this.university.keyLocation =
        this.lang === 'en' ? response.location : response.locationEspanol;
      this.university.campus = response.campus;
    } catch (error) {
      this.alertService.showError({
        msg: this.translate.instant('University.load-error'),
      });
    }
  }

  /**
   * Navigates the previous page in the app
   */
  goBack() {
    this.location.back();
  }

  /** Opens city modal information */
  onClickCity() {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.university.ciudad._id,
      },
    });
  }

  /**
   * Handles the submit click on the request contact form
   */
  onSubmitRequestForm(): void {
    const contactData = this.contactRequestForm.value;
    contactData.email.trim();
    this.apiService
      .post({ api: this.CONTACT_API, data: contactData })
      .subscribe(
        (response) => {
          if (response.success === true) {
            this.contactRequestForm.get('nombre').setValue('');
            this.contactRequestForm.get('email').setValue('');
            this.contactRequestForm.get('telefono').setValue('');
            this.contactRequestForm.get('motivo').setValue('');
            this.alertService.showSuccess({
              msg: this.translate.instant('Home.our-team-has-received'),
            });
          } else {
            this.alertService.showError({
              msg: this.translate.instant('Home.error-alert'),
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Home.error-alert'),
          });
        }
      );
  }

  /**
   * Function to create a Strig with all faculties
   * @param faculties data of faculties
   */
  private stringOfFaculties(faculties) {
    let text = '';
    if (faculties.length == 0) {
      text = '';
    } else if (faculties.length == 1) {
      text = faculties[0];
    } else {
      for (let i = 0; i < faculties.length; i++) {
        const element = faculties[i];
        if (faculties.length - 1 == i) {
          text = text + element;
        } else if (faculties.length - 2 == i) {
          text = `${text} ${element} ${this.translate.instant(
            'University.concat'
          )} `;
        } else {
          text = `${text} ${element}, `;
        }
      }
    }
    return text;
  }

  /**
   * View city modal
   */
  public onViewCity(): void {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.university.ciudad._id,
      },
    });
  }

  changeShowMore() {

    this.showMore = !this.showMore;
  }

  changeColVideoPhoto() {
    if (this.university?.videosResuelve == undefined || this.university?.videosResuelve == null || !this.university.videosResuelve) {
      this.box_videphoto.classList.remove("col-6")
      this.box_videphoto.classList.add("col-12")
    }
  }
}
