export const translationMap = {
  'Arts, Design and Architecture': 'Arte, Diseño y Arquitectura',
  'Business and Management': 'Negocios y Administración',
  'Computer Sciences and IT': 'Ciencias de la Computación e Informática',
  Engineering: 'Ingeniería',
  'Sciences and Mathematics': 'Ciencias y Matemáticas',
  'Social Sciences and Humanities': 'Ciencias Sociales y Humanidades',
  'Education and Sport': 'Educación y Deporte',
  'Community Services': 'Servicios Comunitarios',
  'Environmental Studies and Earth Sciences': 'Estudios Ambientales y Ciencias de la Tierra',
  Health: 'Salud',
  'Journalism and Media': 'Periodismo y Medios de Comunicación',
  'Law and Political Sciences': 'Derecho y Ciencias Políticas',
  Languages: 'Idiomas',
  'Tourism and Hospitality': 'Turismo y Hospitalidad',
  Other: 'Otros',
};