import { RecruitmentModalComponent } from './../../shared/recruitment-modal/recruitment-modal.component';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { AlertService } from '../../services/alert.service';
import { CityComponent } from '../../city/city.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PlatformId } from 'aws-sdk/clients/signer';
import { isPlatformBrowser } from '@angular/common';
import { ViewApplyComponent } from '../../shared/view-apply/view-apply.component';

@Component({
  selector: 'app-carousel-similar-programs',
  templateUrl: './carousel-similar-programs.component.html',
  styleUrls: ['./carousel-similar-programs.component.scss']
})
export class CarouselSimilarProgramsComponent implements OnInit {

  readonly APPLICATION_API = 'api/aplicacion/';
  readonly USER_API = 'api/user/';

  @Input() program: any;
  loggedIn = false;
  loading = false;

  logInSubscription: Subscription;
  @Input() showRequisitesButton: Boolean;

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) { }

  ngOnInit(): void {
     
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
        this.loggedIn = logged;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.logInSubscription) {
      this.logInSubscription.unsubscribe();
    }
  }

  /**
   * Handles the click on the Apply button
   */
  onApplyClick(): void {
    this.dialog.open(ViewApplyComponent, { data: { program: this.program } });
  }

  /**
   * Check sesion
   * Open url program.universidad.brochure
   *
   * @param {*} open URL to open
   * @memberof ProgramCardComponent
   */
  public onViewRequires(open) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user && !this.loggedIn) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      return;
    }
    window.open(open);
  }

  /** Handles multiple clicks */
  onMultipleClicksHandles({ event }: { event: any }): void {
    event.stopPropagation();
    if (event.target.classList.contains('favourite-button')) {
      this.onAddToFavourites();
    } else if (
      event.target.classList.contains('university-anchor') ||
      event.target.classList.contains('university-logo')
    ) {
      this.onClickUniversity();
    } else if (event.target.classList.contains('location-name')) {
      this.onClickCity();
    } else {
      this.onCardClick();
    }
  }

  /** Handles the click on the requisites button */
  onRequisitesClick(): void {
    this.navigationService.navigateTo({
      path: 'program/' + this.program.slug + '#requirements',
    });
  }

  /** Opens city modal information */
  onClickCity(): void {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.program.universidad.ciudad._id,
      },
    });
  }

  /**
   * Handles the click on the university name -> Navigates university detail
   */
  onClickUniversity(): void {
    this.navigationService.navigateTo({
      path: 'university/' + this.program.universidad.slug,
    });
  }

  /**
   * Handles the click on the card --> Navigates to program detail
   */
  onCardClick(): void {
    let slug = this.program.slug;

    slug = slug.replaceAll('\n', '%0D%0A');
    this.navigationService.navigateTo({
      path: 'program/' + slug,
    });
  }
  /**
   * Handles the click on the add to favourites button
   */
  onAddToFavourites(): void {
    let userRole;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null) {
      userRole = user.tipo;
    }
    if (this.loggedIn && userRole && userRole !== 'Recruitment') {
      const user = JSON.parse(localStorage.getItem('user'));
      const data = {
        programa: this.program._id,
        id: user._id,
      };
      if (this.program.inFavourites === true) {
        // If already is in favourites, remove from favourites
        this.apiService.post({ api: this.USER_API + 'remove-favourite', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: this.translate.instant('Alerts.removed-favourite') });
            // Update favourites list in the local storage to keep the information consistent
            let favoritos = JSON.parse(localStorage.getItem('Favoritos'));
            favoritos = favoritos.filter((pId) => pId !== data.programa);
            localStorage.setItem('Favoritos', JSON.stringify(favoritos));
            this.program.inFavourites = false;
          },
          (err) => {
            this.alertService.showError({
              msg: this.translate.instant('Programs-list.could-not-remove'),
            });
          }
        );
      } else {
        // If not, add to favourites
        this.apiService.post({ api: this.USER_API + 'post-favourite', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: this.translate.instant('Alerts.added-favourite') });
            // Update favourites list in the local storage to keep the information consistent
            const favoritos = JSON.parse(localStorage.getItem('Favoritos'));
            favoritos.push(data.programa);
            localStorage.setItem('Favoritos', JSON.stringify(favoritos));
            this.program.inFavourites = true;
          },
          (err) => {
            this.alertService.showError({ msg: this.translate.instant('Programs-list.could-not-add') });
          }
        );
      }
    } else if (this.loggedIn && userRole && userRole === 'Recruitment') {
      this.dialog.open(RecruitmentModalComponent, {
        data: { recruitmentAction: 'favourites', programId: this.program._id },
      });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }

}
