<div class="visa-service-shop-card">
  <div class="data-header">
    <mat-icon class="remove-button" (click)="onRemoveServiceClick()"> close </mat-icon>
  </div>
  <img class="visa-service-shop-card-img" [src]="image" alt="visa">
  <div class="visa-service-shop-card-data">
    <div class="visa-service-shop-title" *ngIf="type === 'visa'">
      <span *ngIf="name">{{name}}</span> <br>
      {{ "Visa-services-shopping-cart.visa-service-for" | translate }} {{serviceName}}
    </div>
    <div class="visa-service-shop-title" *ngIf="type === 'consultancy'">
      <span *ngIf="name">{{name}}</span> <br>
      {{ "Visa-services-shopping-cart.consultancy-service" | translate }} {{serviceName}}
    </div>
    <div class="cost">
      ${{cost | number}} {{currency}}
    </div>
  </div>
</div>
