import { Pipe, PipeTransform } from '@angular/core';
import { stringList } from 'aws-sdk/clients/datapipeline';

@Pipe({
  name: 'hashtag'
})
export class HashtagPipe implements PipeTransform {

  /**
   * Adds the # symbol at the start of the given string
   * @param value string that is going to be transformed into a hashtag
   */
  transform(value: string): string {
    return '#' + value;
  }

}
