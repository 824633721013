<div class="free-guides-resources">
  <div class="free-guides-resources-container">
    <button class="go-back-button" routerLink="/free-guides"><img class="flecha-img" src="/assets/home/images/flecha.png" alt="Flecha volver"> Volver</button>
    <h1 class="guides-category">{{ category }}</h1>
    <p class="guides-description">{{ description }}</p>
    <p class="guides-declaration">Somos expertos en educación en Norteamérica, Europa y Australia.<br>Descarga la guía gratuita para ti:</p>
    <div class="guides-container">
      <div class="guide-card" [ngClass]="{
      'pink': guide.color === 'pink',
      'blue': guide.color === 'blue',
      'green': guide.color === 'green',
      'yellow': guide.color === 'yellow'
      }" *ngFor="let guide of freeGuides"
      [routerLink]="'/free-guide/' + guide._id"
      >
        <img class="guide-image" [src]="guide.imagen" [alt]="guide.nombre + 'imagen'">
        <p class="guide-name">{{ guide.nombre }}</p>
        <p class="guide-base-price"  *ngIf="guide.precioBase">Desde ${{ guide.precioBase }} {{ guide.moneda }}</p>
      </div>
    </div>
  </div>
</div>