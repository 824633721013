<div class="academic-level-explanation">
  <mat-icon class="close-button" (click)="onCloseSelf()">close</mat-icon>
  <div class="explanation">
    <h4> {{"Academic-interests.accreditation-types" | translate}}</h4>
    <ol>
      <li> <strong>{{ "Academic-interests.bachelor" | translate }}</strong> {{ "Academic-interests.bachelor-explanation" | translate }}</li>
      <li> <strong>{{ "Academic-interests.master" | translate }}</strong> {{ "Academic-interests.master-explanation" | translate }}</li>
      <li> <strong>{{ "Academic-interests.certificate" | translate }}</strong> {{ "Academic-interests.certificate-explanation" | translate }}</li>
      <li> <strong>{{ "Academic-interests.diploma" | translate }}</strong> {{ "Academic-interests.diploma-explanation" | translate }}</li>
      <li> <strong>{{ "Academic-interests.pre-master" | translate }}</strong>{{ "Academic-interests.pre-master-explanation" | translate }}</li>
    </ol>
  </div>
</div>
