import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
@Component({
  selector: 'app-free-guides',
  templateUrl: './free-guides.component.html',
  styleUrls: ['./free-guides.component.scss'],
})
export class FreeGuidesComponent implements OnInit {
  availableSections = [];

  constructor(private navService: NavigationService, private apiService: ApiService) {}

  ngOnInit(): void {
    this.getFreeGuides();
  }

  getFreeGuides() {
    this.apiService.get({ api: 'api/guias-gratuitas' }).subscribe((response) => {
      if (response.success) {
        this.availableSections = response.result;
        for (let i = 0; i < this.availableSections.length; i++) {
          if ((i + 1) % 4 === 0) {
            this.availableSections[i]['color'] = 'yellow';
          } else if ((i + 1) % 3 === 0) {
            this.availableSections[i]['color'] = 'green';
          } else if ((i + 1) % 2 === 0) {
            this.availableSections[i]['color'] = 'blue';
          } else {
            this.availableSections[i]['color'] = 'pink';
          }
        }
      }
    });
  }

  goToResources(linkParams) {
    this.navService.navigateTo({ path: 'free-guides/resources', params: { category: linkParams } });
  }
}
