import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-visa-services-shopping-card',
  templateUrl: './visa-services-shopping-card.component.html',
  styleUrls: ['./visa-services-shopping-card.component.scss']
})
export class VisaServicesShoppingCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the service */
  @Input() id: string;
  /** Variable that holds the name of the service */
  @Input()name: string;
  /** Variable that holds the secondary name of the service */
  @Input() serviceName: string;
  /** Variable that holds the numberOfWeeks that the customer will buy */
  @Input() numberOfDays: number;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the currency in which the service is registeres */
  @Input() currency: string;
  /** Variable that holds the total cost of adquiring the service  */
  @Input() cost: number;
  /** Function to delete the service from the cart */
  @Output() removeService = new EventEmitter<string>();
  /** Variable that holds the type of the service, can be either consultancy or visa */
  @Input() type: string;

  constructor() { }

  ngOnInit(): void {
  }

  onRemoveServiceClick(): void {
    this.removeService.emit(this.id);
  }

}
