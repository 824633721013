<div class="academic-interests">
  <div class="academic-interests-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back" />
    <h3 class="academic-interests-title">{{ 'Academic-interests.academic-interests' | translate }}</h3>
  </div>
  <div class="academic-interests-form">
    <form #academicInterestForm (ngSubmit)="this.onSubmit()">
      <div
        class="academic-interest"
        *ngFor="let interest of academicInterests.controls; index as i"
        [formGroup]="interest">
        <div class="form-group">
          <label class="form-label">
            {{ 'Academic-interests.discipline-interest' | translate }} (
            <span style="color: red">*</span>
            )
          </label>
          <select [id]="'discipline' + i" name="discipline' + i" class="form-input" formControlName="disciplina">
            <option value="Arts">{{ 'Academic-interests.arts' | translate }}</option>
            <option value="Architecture">{{ 'Academic-interests.achitecture' | translate }}</option>
            <option value="Business">{{ 'Academic-interests.business' | translate }}</option>
            <option value="Education">{{ 'Academic-interests.education' | translate }}</option>
            <option value="Engineering">{{ 'Home.engineering' | translate }}</option>
            <option value="Health">{{ 'Academic-interests.health' | translate }}</option>
            <option value="Languages">Languages {{ 'Academic-interests.languages' | translate }}</option>
            <option value="Law">{{ 'Academic-interests.law-politics' | translate }}</option>
            <option value="Natural-sciences">{{ 'Academic-interests.natural-sciences' | translate }}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">
            {{ 'Academic-interests.academic-level-interest' | translate }} (
            <span style="color: red">*</span>
            )
          </label>
          <mat-icon (click)="onInfoClick()" class="info-icon">info</mat-icon>
          <select [id]="'academic' + i" name="academic' + i" class="form-input" formControlName="nivelDeEstudios">
            <option value="bachelor">{{ 'Academic-interests.bachelor' | translate }}</option>
            <option value="master">{{ 'Academic-interests.master' | translate }}</option>
            <option value="certificate">{{ 'Academic-interests.certificate' | translate }}</option>
            <option value="diploma">{{ 'Academic-interests.diploma' | translate }}</option>
            <option value="pre-master">{{ 'Academic-interests.pre-master' | translate }}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label">
            {{ 'Academic-interests.country-of-interest' | translate }} (
            <span style="color: red">*</span>
            )
          </label>
          <select [id]="'country' + i" name="country' + i" class="form-input" formControlName="pais">
            <option *ngFor="let country of countries" [value]="country._id">
              {{ country.nombre }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button type="button" (click)="onDeleteAcademicInterest({ index: i })" class="delete-interest">
            {{ 'Academic-interests.delete' | translate }}
          </button>
        </div>
        <div class="separator"></div>
      </div>

      <button type="button" (click)="onAddAcademicInterest()" id="add-interest">
        {{ 'Academic-interests.add-new-interest' | translate }}
      </button>
      <button *ngIf="!loading" type="submit" id="save-changes">
        {{ 'Academic-interests.save-changes' | translate }}
      </button>
      <mat-spinner diameter="40" *ngIf="loading"></mat-spinner>
    </form>
  </div>
</div>
