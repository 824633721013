<div class="travel-substeps">
  <div class="travel-subs" *ngFor="let step of substeps; index as i" [ngStyle]="{'opacity': selected !== i?'0.3':'1'}">
    <app-substep-card (click)="changeSelected({index: i})" [stepName]="step.name" [status]="step.status">
    </app-substep-card>
    <div [ngSwitch]="selected" class="travel-subs-data" [ngClass]="selected === i? 'travel-subs-data-active':''">
      <div *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="substep0"></ng-container>
      </div>
      <div *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="substep1"></ng-container>
      </div>
      <div *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="substep2"></ng-container>
      </div>
    </div>
  </div>
</div>
<div [ngSwitch]="selected" class="travel-content">
  <div *ngSwitchCase="0">
    <ng-container *ngTemplateOutlet="substep0"></ng-container>
  </div>
  <div *ngSwitchCase="1">
    <ng-container *ngTemplateOutlet="substep1"></ng-container>
  </div>
  <div *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="substep2"></ng-container>
  </div>
</div>
<ng-template #substep0>
  <p> {{ 'Travel.remeber-tickets' | translate }}</p>
  <a  class="document-link"
       *ngIf="prepareYourTravel.compraTiquetes.ticket !== ''
       && prepareYourTravel.compraTiquetes.ticket !== null &&
       prepareYourTravel.compraTiquetes.ticket !== undefined ;else notUploadedYet"
       [href]="prepareYourTravel.compraTiquetes.ticket"
       target="_blank">{{'Travel.view-ticket' | translate}}</a> <br>
    <label *ngIf="substeps[0].status !== 'completed'" for="ticket" class="upload-document">{{'Travel.upload-ticket' |translate}}</label>
    <input *ngIf="substeps[0].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'ticket'})" id="ticket" type=file>
</ng-template>
<ng-template #substep1>
  <p> {{ 'Travel.define-acco' | translate }}</p>
  <a  class="document-link"
       *ngIf="prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento !== ''
       && prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento !== null &&
       prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento !== undefined ;else notUploadedYet"
       [href]="prepareYourTravel.definicionAlojamiento.comprobanteAlojamiento"
       target="_blank">{{'Travel.view-aco-contract'|translate}}</a> <br>
    <label *ngIf="substeps[1].status !== 'completed'" for="accomodation-proof" class="upload-document">{{'Travel.upload-aco-contract' | translate}}</label>
    <input *ngIf="substeps[1].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'accomodation-proof'})" id="accomodation-proof" type=file>
    <p *ngIf="substeps[1].status === 'completed'">{{'Travel.done'|translate}}</p>
</ng-template>
<ng-template #substep2>
  <p *ngIf="substeps[2].status !== 'completed'"> {{ 'Travel.pay-acco' | translate }}</p>
  <a  class="document-link"
       *ngIf="prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento !== ''
       && prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento !== null &&
       prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento !== undefined ;else notUploadedYet"
       [href]="prepareYourTravel.pagoAlojamiento.comprobantePagoAlojamiento"
       target="_blank">{{'Travel.view-payment-proof'|translate}}</a> <br>
    <label *ngIf="substeps[1].status !== 'completed'" for="accomodation-payment-proof" class="upload-document">{{'Travel.upload-payment-proof'|translate}}</label>
    <input *ngIf="substeps[1].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'accomodation-payment-proof'})" id="accomodation-payment-proof" type=file>
  <p *ngIf="substeps[2].status === 'completed'">{{'Travel.done'|translate}}</p>
</ng-template>

<!--General templates-->
<ng-template #notUploadedYet>
  <p class="fallback-text">{{'Travel.not-uploaded-yet'|translate}} </p>
</ng-template>
