<div class="modalContainer">
  <div class="modal-header customHeader">
    <h4 class="modalTitle">
      {{
        plannedExperience.isEdit
          ? 'Edita tu experiencia'
          : plannedExperience.isApproval
          ? 'Califica esta experiencia'
          : 'Crea tu experiencia'
      }}
    </h4>
  </div>

  <div class="modal-body customBody">
    <div class="pageOne" *ngIf="currentPage === 1">
      <mat-form-field class="fieldFormContainer">
        <mat-label for="experienceSelect">Experiencia asociada</mat-label>
        <mat-select
          [disabled]="
            plannedExperience.isApproval ||
            (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
          "
          id="experienceSelect"
          name="experienceSelect"
          (ngModelChange)="getExperiences()"
          [(ngModel)]="plannedExperience.experience">
          <mat-option *ngFor="let option of experiences" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fieldFormContainer">
        <mat-label for="experienceNameInput">Nombre de la experiencia:</mat-label>
        <input
          [disabled]="
            plannedExperience.isApproval ||
            (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
          "
          id="experienceNameInput"
          name="experienceNameInput"
          [(ngModel)]="plannedExperience.experienceName"
          matInput />
      </mat-form-field>

      <mat-form-field class="fieldFormContainer">
        <mat-label for="experienceDescriptionTextarea">Descripción de lo que quiero hacer:</mat-label>
        <textarea
          [disabled]="
            plannedExperience.isApproval ||
            (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
          "
          id="experienceDescriptionTextarea"
          name="experienceDescriptionTextarea"
          [(ngModel)]="plannedExperience.experienceDescription"
          matInput></textarea>
      </mat-form-field>

      <div class="d-flex justify-content-between fieldFormContainer skillsWrapper">
        <div class="skillsContainer">
          <mat-form-field>
            <mat-label for="principalSkillSelect">Competencia principal</mat-label>
            <mat-select
              [disabled]="
                plannedExperience.isApproval ||
                (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
              "
              id="principalSkillSelect"
              name="principalSkillSelect"
              (ngModelChange)="onDropdownChange($event)"
              [(ngModel)]="plannedExperience.principalSkill">
              <mat-option *ngFor="let option of skills" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="skillsContainer">
          <mat-form-field>
            <mat-label for="secondarySkillSelect">Competencia secundaria</mat-label>
            <mat-select
              [disabled]="
                plannedExperience.isApproval ||
                (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
              "
              id="secondarySkillSelect"
              name="secondarySkillSelect"
              [(ngModel)]="plannedExperience.secondarySkill">
              <mat-option *ngFor="let option of secondarySkills" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-between fieldFormContainer datesWrapper">
        <div class="datesContainer">
          <mat-form-field>
            <input
              [disabled]="
                plannedExperience.isApproval ||
                (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
              "
              matInput
              [matDatepicker]="picker"
              placeholder="Fecha de inicio"
              id="startDateInput"
              name="startDateInput"
              [(ngModel)]="plannedExperience.startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="datesContainer">
          <mat-form-field>
            <input
              [disabled]="
                plannedExperience.isApproval ||
                (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
              "
              matInput
              [matDatepicker]="secondPicker"
              placeholder="Fecha final"
              id="endDateInput"
              name="endDateInput"
              [(ngModel)]="plannedExperience.endDate" />
            <mat-datepicker-toggle matSuffix [for]="secondPicker"></mat-datepicker-toggle>
            <mat-datepicker #secondPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="datesContainer">
        <mat-form-field>
          <mat-label for="gradeOfExecution">Año escolar de ejecución</mat-label>
          <mat-select
            [disabled]="
              plannedExperience.isApproval ||
              (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
            "
            id="gradeOfExecution"
            name="gradeOfExecution"
            [(ngModel)]="plannedExperience.gradeOfExecution">
            <mat-option *ngFor="let option of grades" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="experiencesConfigContainer fieldFormContainer">
        <div class="activityTypeContainer">
          <mat-label>Tipo de actividad</mat-label>
          <mat-radio-group
            [disabled]="
              plannedExperience.isApproval ||
              (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
            "
            class="d-flex flex-column"
            id="activityTypeRadioGroup"
            name="activityTypeRadioGroup"
            [(ngModel)]="plannedExperience.activityType"
            aria-label="Select an option">
            <mat-radio-button *ngFor="let option of activityType" [value]="option">
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="executionModeContainer">
          <mat-label>Lugar de ejecución</mat-label>
          <mat-radio-group
            [disabled]="
              plannedExperience.isApproval ||
              (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
            "
            class="d-flex flex-column"
            id="locationRadioGroup"
            name="locationRadioGroup"
            aria-label="Select an option"
            [(ngModel)]="plannedExperience.location">
            <mat-radio-button *ngFor="let option of location" [value]="option">
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="executionMethodContainer">
          <mat-label>Forma de Ejecución</mat-label>
          <mat-radio-group
            [disabled]="
              plannedExperience.isApproval ||
              (plannedExperience.isEdit && plannedExperience.status !== 'Mejorar soportes')
            "
            class="d-flex flex-column"
            id="executionMethodRadioGroup"
            name="executionMethodRadioGroup"
            aria-label="Select an option"
            [(ngModel)]="plannedExperience.executionMethod">
            <mat-radio-button *ngFor="let option of executionMethod" [value]="option">
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div *ngIf="currentPage === 2">
      <div class="descriptionDownSide">
        <h1 class="titlePage">Soportes</h1>

        <div
          class="lineSeparator"
          *ngIf="plannedExperience.isEdit && plannedExperience.supportsLinks.linksData.length > 0">
          <label class="sectionTitle">Documentos guardados:</label>
          <li class="uploadedFiles" *ngFor="let support of plannedExperience.supportsLinks.linksData; let i = index">
            <a class="fileName" href="{{ support }}" target="_blank">
              {{ maxCaractersContent(getFileNameFromURL(support)) }}
            </a>
            <button
              *ngIf="!plannedExperience.isApproval && plannedExperience.status === 'Mejorar soportes'"
              class="deleteButton"
              (click)="deleteFileFromS3(support, i)">
              <img class="deleteImage" src="../../../../assets/experiences/delete.png" alt="Eliminar" />
            </button>
          </li>
        </div>

        <div
          *ngIf="plannedExperience.isEdit && plannedExperience.supportsLinks.linksData.length === 0"
          class="d-flex flex-column align-items-center p-3 bg-light">
          <div class="p-4">
            <p class="text-muted">Estudiante sin soportes</p>
          </div>
        </div>

        <div
          *ngIf="
            !plannedExperience.isApproval &&
            (!plannedExperience.isEdit || (plannedExperience.isEdit && plannedExperience.status === 'Mejorar soportes'))
          "
          class="file-list">
          <ul class="listOfUploadedFiles">
            <p>
              <span class="sectionTitle">Documentos sugeridos:</span>
            </p>

            <ul>
              <li *ngFor="let document of documentsWithData">{{ document.document }}: {{ document.documentType }}</li>
            </ul>
            <span class="documentsRequirementMessage">
              Debes cargar la documentación de la manera más completa posible, que permita a un tercero verificar la
              veracidad de la información. En caso de no tenerla, abstente de registrar la actividad como ejecutada.
            </span>
            <p>Sube tus documentos</p>
            <li class="uploadedFiles" *ngFor="let f of files; let i = index">
              <div class="fileLeftColumn">
                <p class="fileName">{{ maxCaractersContent(f.name) }}</p>
                <button class="deleteButton" (click)="onRemove(i)">
                  <img class="deleteImage" src="../../../../assets/experiences/delete.png" alt="Eliminar" />
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div
          *ngIf="
            !plannedExperience.isApproval &&
            (!plannedExperience.isEdit || (plannedExperience.isEdit && plannedExperience.status === 'Mejorar soportes'))
          "
          class="custom-dropzone"
          ngx-dropzone
          (change)="onSelect($event)">
          <ngx-dropzone-label>
            <div>
              <h2 class="dropzonePlaceholder">Arrastra tus archivos aqui o selecciona un archivo</h2>
            </div>
          </ngx-dropzone-label>
        </div>
        <div *ngIf="plannedExperience.recommendation">
          <label class="recommendationMessage">{{ plannedExperience.recommendation }}</label>
        </div>
      </div>
    </div>

    <div *ngIf="currentPage === 3 && plannedExperience.isApproval">
      <h1 class="titlePage">Recomendación</h1>
      <div class="p-4 d-flex flex-column">
        <mat-form-field class="fieldFormContainer">
          <mat-label for="status">Revision de experiencia</mat-label>
          <mat-select id="status" name="status" [(ngModel)]="plannedExperience.status">
            <mat-option *ngFor="let option of statusOptions" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="fieldFormContainer" *ngIf="plannedExperience.status === 'Mejorar soportes'">
          <mat-label for="improveMessage">Deja tu recomendación:</mat-label>
          <textarea
            id="improveMessage"
            name="improveMessage"
            [(ngModel)]="plannedExperience.improveMessage"
            matInput></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer customFooter d-flex justify-content-between">
    <div class="activityTypeFooter" *ngIf="currentPage !== 1">
      <div class="buttonWrapper">
        <button class="nextButton" (click)="prevPage()">Atrás</button>
      </div>
    </div>

    <button
      *ngIf="plannedExperience.isEdit && !plannedExperience.isApproval"
      mat-button
      color="warn"
      (click)="onDelete()">
      Eliminar
    </button>

    <div class="activityTypeFooter" *ngIf="!plannedExperience.isApproval && currentPage !== 2">
      <div class="buttonWrapper">
        <button class="nextButton" (click)="nextPage()">Siguiente</button>
      </div>
    </div>

    <div class="activityTypeFooter" *ngIf="plannedExperience.isApproval && currentPage !== 3">
      <div class="buttonWrapper">
        <button class="nextButton" (click)="nextPage()">Siguiente</button>
      </div>
    </div>

    <div class="activityTypeFooter" *ngIf="currentPage === 2 && !plannedExperience.isApproval">
      <div *ngIf="plannedExperience.status === 'Mejorar soportes' && plannedExperience.isEdit">
        <button class="nextButton" (click)="onSubmit()">Actualizar</button>
      </div>
      <div class="buttonWrapper" *ngIf="!plannedExperience.isEdit">
        <button class="nextButton" (click)="onSubmit()">Registrar</button>
      </div>
    </div>

    <div class="activityTypeFooter" *ngIf="currentPage === 3">
      <div class="buttonWrapper">
        <button class="nextButton" (click)="onSubmit()">Enviar</button>
      </div>
    </div>
  </div>
</div>
