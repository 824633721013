import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-medical-services-shopping-card',
  templateUrl: './medical-services-shopping-card.component.html',
  styleUrls: ['./medical-services-shopping-card.component.scss']
})
export class MedicalServicesShoppingCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the service */
  @Input() id: string;
  /** Variable that holds the name of the service */
  @Input() serviceName: string;
  /** Variable that holds the numberOfWeeks that the customer will buy */
  @Input() numberOfDays: number;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the payment plans of the service */
  @Input() plans: any[];
  /** Currency in which the service will be paid */
  @Input() currency: string;
  /** True if the current component is shopping cart component, falseif current component is purchaseHistory */
  @Input() isShoppingCart: boolean;
  /** Output function to delete service from the shopping cart */
  @Output() deleteFromCart = new EventEmitter<string>();
  /** Output function to emit a change in the number of days */
  @Output() changeNumberOfDays = new EventEmitter<any>();

  /** Cost of adquiring the service */
  cost = 0;

  constructor(private alertService: AlertService,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.calculateCost();
  }

  /** Calculates the plan total cost based on the number of days and the payment plans of the service */
  calculateCost(): void {
    if (this.numberOfDays === 0) {
      this.cost = 0;
    }
    else {
      let fitsInPlan = false;
      for (const plan of this.plans) {
        if (this.numberOfDays >= plan.diasInferior && this.numberOfDays <= plan.diasSuperior) {
          this.cost = this.numberOfDays * plan.precioDiario;
          fitsInPlan = true;
          break;
        }
      }
      if (!fitsInPlan) {
        // this.onRemoveDay();
        // this.alertService.showError({msg: 'You have reached the limit of days that this plan supports'});
      }
    }
  }

  /**
   * Increases the number of days of the service
   */
  onIncreaseNumberOfDays(): void {
    const days = this.numberOfDays + 1;
    let fitsInPlan = false;
    for (const plan of this.plans) {
      if (days >= plan.diasInferior && days <= plan.diasSuperior) {
        fitsInPlan = true;
        break;
      }
    }
    if (!fitsInPlan) {
      this.alertService.showError({msg: 'You have reached the limit of days that this plan supports'});
    } else {
      this.changeNumberOfDays.emit({id: this.id, newQuantity: days});
    }
  }

  /**
   * decreases the number of days of the service
   */
  onDecreaseNumberOfDays(): void {
    const days = this.numberOfDays - 1;
    if (days < 1) {
      return;
    } else {
      let fitsInPlan = false;
      for (const plan of this.plans) {
        if (days >= plan.diasInferior && days <= plan.diasSuperior) {
          fitsInPlan = true;
          break;
        }
      }
      if (!fitsInPlan) {
        this.alertService.showError({msg: 
          this.translate.instant("Medical-service-shopping-cart.limit-error")});
      } else {
        this.changeNumberOfDays.emit({id: this.id, newQuantity: days});
      }
    }
  }

  /**
   * Removes the current service from the shopping cart
   */
  onRemoveServiceClick(): void {
    this.deleteFromCart.emit(this.id);
  }

}
