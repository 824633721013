<nb-card>
  <nb-card-body class="bulkBody">
    <input #fileInputExcel type="file" style="display: none" (change)="onFileChange($event)" />

    <div *ngIf="!duplicates" class="row bulkContainer">
      <div class="col-12 mb-3">Por favor, asegúrese de que el archivo seleccionado sea un archivo Excel válido.</div>
      <div class="col-12 mt-3">
        <div class="alert alert-info" role="alert">
          <h4 class="alert-heading">Requisitos del Archivo Excel:</h4>
          <ul>
            <li>La primera columna debe ser el primer nombre.</li>
            <li>La segunda columna debe ser el segundo nombre.</li>
            <li>La tercera columna debe ser el primer apellido.</li>
            <li>La cuarta columna debe ser el segundo apellido.</li>
            <li>La quinta columna debe ser el grado .</li>
            <li>La sexta columna debe ser el nivel de grado.</li>
            <li>La séptima columna debe ser el correo electrónico.</li>
            <li>La octava columna debe ser la fecha de nacimiento en el formato correcto (yyyy-mm-dd).</li>
            <li>La novena columna debe ser la contraseña.</li>
          </ul>
          <div>
            <button class="btn btn-info" (click)="downloadTemplate()">Descargar Plantilla</button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <button class="btn btn-primary" (click)="onOpenFileInputExcel()">Subir Archivo Excel</button>
      </div>
      <div class="col-12" *ngIf="selectedFileName">
        <div class="mb-3">Archivo seleccionado: {{ selectedFileName }}</div>
        <button class="btn btn-success" (click)="convertToJson()">Guardar estudiantes</button>
      </div>
    </div>
    <div *ngIf="duplicates && duplicateUsers.length > 0" class="col-12 mb-3">
      <button class="btn btn-primary" routerLink="/myStudents">Atrás</button>
      <h4 class="alert-heading">Usuarios duplicados</h4>
      <div class="col-12 mb-3">No se pudieron guardar los siguientes estudiantes</div>
      <ul *ngFor="let user of duplicateUsers">
        <li>
          {{ user.primerNombre }} {{ user.segundoNombre }} {{ user.primerApelldio }} {{ user.segundoApellido }} -
          {{ user.email }}
        </li>
      </ul>
    </div>
  </nb-card-body>
</nb-card>
