<form (ngSubmit)="onSubmit({event: $event.submitter})" [formGroup]="createForm">
    <div class="container-body">
        <div class="container-representative">
            <label>Contacta a {{name}} en Destino</label>
            <p>
                U360 ha seleccionado <span>estudiantes</span> que pueden aportarte información muy valiosa para tomar
                la mejor decisión. Déjanos tus datos para ponerte en contacto.
            </p>
        </div>
        <div class="container-form">
            <div class="container-form-label">
                <label for="name">Nombre</label>
                <input [ngClass]="{'red-border': validField({ field: 'nombre'})}" id="name" formControlName="nombre"
                    class="form-control" type="text" placeholder="Escribe tu nombre">
                <span *ngIf="validField({ field: 'nombre'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
            <div class="container-form-label">
                <label for="lastname">Apellido</label>
                <input [ngClass]="{'red-border': validField({ field: 'apellido'})}" id="lastname"
                    formControlName="apellido" class="form-control" type="text" placeholder="Escribe tu apellido">
                <span *ngIf="validField({ field: 'apellido'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
            <div class="container-form-label">
                <label for="email">Correo</label>
                <input [ngClass]="{'red-border': validField({ field: 'correo'})}" id="email" formControlName="correo"
                    class="form-control" type="email" placeholder="Escribe tu correo eléctronico">
                <span *ngIf="validField({ field: 'correo'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
            <div class="container-form-label">
                <div class="container-year">
                    <div class="year">
                        <label for="year">Año de interés</label>
                        <input [ngClass]="{'red-border': validField({ field: 'anioInteres'})}"
                            formControlName="anioInteres" id="year" class="form-control" min="0" type="number"
                            placeholder="Año">
                        <span *ngIf="validField({ field: 'anioInteres'})" class="invalid-warning">
                            Campo requerido!
                        </span>
                    </div>
                    <div class="year-old">
                        <label for="yearOld">Edad</label>
                        <input [ngClass]="{'red-border': validField({ field: 'edad'})}" id="yearOld"
                            formControlName="edad" class="form-control" min="0" type="number" placeholder="Edad">
                        <span *ngIf="validField({ field: 'edad'})" class="invalid-warning">
                            Campo requerido!
                        </span>
                    </div>
                </div>
            </div>
            <div class="container-form-label">
                <label for="phone">{{ "Sign-up.telephone" | translate }}</label>
                <input [ngClass]="{'red-border': validField({ field: 'telefono'})}" id="phone"
                    formControlName="telefono" class="form-control" min="0" type="number"
                    [placeholder]="'Sign-up.telephone'|translate">
                <span *ngIf="validField({ field: 'telefono'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
            <div class="container-form-label">
                <label for="program">Programa</label>
                <input [ngClass]="{'red-border': validField({ field: 'programa'})}" id="program"
                    formControlName="programa" class="form-control" type="text" placeholder="Programa de interés">
                <span *ngIf="validField({ field: 'programa'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
            <div class="container-form-label">
                <label for="question">Preguntas</label>
                <textarea [ngClass]="{'red-border': validField({ field: 'pregunta'})}" formControlName="pregunta"
                    id="question" class="form-control" type="text"
                    placeholder="Por favor escribe tu pregunta principal aquí"></textarea>
                <span *ngIf="validField({ field: 'pregunta'})" class="invalid-warning">
                    Campo requerido!
                </span>
            </div>
        </div>
    </div>
    <div class="btn-form">
        <button type="submit">Contactar</button>
    </div>
</form>