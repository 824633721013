import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accreditation',
})
export class AccreditationPipe implements PipeTransform {
  /** Dictionary for matching the correct accreditation */
  private dict = {
    bachelor: 'Bachelor degree',
    master: 'Master degree',
    certificate: 'Certificate',
    diploma: 'Diploma',
    preMaster: 'Pre-Master',
    pathway: 'Pathway',
    doctorate: 'Doctorate',
    foundationYear: 'Foundation Year',
    posDiploma: 'Posgraduate Diploma',
    posCertificate: 'Posgraduate Certificate',
    assessment: 'Assessment',
    noData: 'No Data',
  };

  private dictSpanish = {
    bachelor: 'Pregrado',
    master: 'Maestría',
    certificate: 'Certificado',
    diploma: 'Diploma',
    preMaster: 'Pre Maestría',
    pathway: 'Pathway',
    doctorate: 'Doctorado',
    foundationYear: 'Primer año',
    posDiploma: 'Diploma de posgrado',
    posCertificate: 'Certificado de posgrado',
    assessment: 'Evaluación',
    noData: 'No Data',
  };

  /** Receives the accreditation pipe and returns the long name of the  accreditation */
  transform(value: string): string {
      if (value === 'pre-master') {
        return this.dict.preMaster;
      }
      else {
        return this.dict[value];
      }
  }
}
