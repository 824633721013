import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { Location } from '@angular/common';
import { SeoService } from '../../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss'],
})
export class BlogDetailComponent implements OnInit, OnDestroy {
  /** CONSTANTS */
  /** Variable that holds the information of the blog api */
  readonly BLOG_API = 'api/blog/';

  /** Variable that holds the information of the blog api slugs*/
  readonly BLOG_API_SLUG = 'api/blog/slug/';

  /** Variable that holds the blog data that will be displayed */
  blogData = {
    _id: '',
    title: 'Título',
    author: 'Hemingway',
    tags: ['Hashtag', 'Travel', 'Future'],
    date: '2020-11-24T15:08:19.459Z',
    video: '',
    image: '',
    slug: '',
    content: '',
    country: {
      _id: ''
    },
  };

  /** Variable that holds the subscription for url params change */
  urlSubscription: Subscription;

  /** Variable that holds the list of related blogs */
  relatedBlogs = [];

  blog:any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private apiService: ApiService,
    private seo: SeoService,
    private location: Location,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.blog = {};
    if (!this.activatedRoute.snapshot.data.blog) {
      this.getBlogBySlug({ slug: JSON.parse(this.cookieService.get('blogSlug')) });
    }else {
      this.getBlog();
    }
    this.setSEO();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  /** Set the SEO information with SEO Service */
  setSEO(): void {
    this.seo.setSEO({
      title: this.blogData.title || 'Blog Post',
      description: this.blogData.content || 'Blog post on U360',
      url: 'blog/' + this.blogData.slug,
      image: this.blogData.image,
      main: false,
    });
  }

  /**
   * Retrieves the current blog information from the activated route
   */
  getBlog(): void {
    try {
      let response = this.activatedRoute.snapshot.data.blog || this.blog;
      this.blogData._id = response._id;
      this.blogData.title = response.titulo;
      this.blogData.author = response.autor;
      this.blogData.tags = response.tags;
      this.blogData.date = response.createdAt;
      this.blogData.content = response.texto;
      this.blogData.slug = response.slug;
      this.blogData.country = response.pais;
      if (
        response.video !== undefined &&
        response.video !== '' &&
        response.video !== null
      ) {
        this.blogData.video = response.video;
      } else if (
        response.imagen !== undefined &&
        response.imagen !== '' &&
        response.imagen !== null
      ) {
        this.blogData.image = response.imagen;
      }
      this.getRelatedBlogs({ id: this.blogData._id });
    } catch (error) {
      this.alertService.showError({
        msg: this.translate.instant('Blog-detail.error-load'),
      });
    }
  }

  /**
   * Get the related blogs from the backend
   * @param id id of the blos
   */
  getRelatedBlogs({ id }: { id: string }): void {
    this.apiService.get({ api: this.BLOG_API + 'similar/' + id }).subscribe(
      (response) => {
        this.relatedBlogs = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Blog-detail.error-load'),
        });
      }
    );
  }

  /** Returns the user to the previous location */
  onBackClick(): void {
    this.location.back();
  }

  /** Handles the click on the discover button */
  onDiscoverClick(): void {
    this.navigationService.navigateTo({path: 'program/search', params: {
      pais: this.blogData.country._id,
    }});
  }

  getBlogBySlug({ slug }): void {
    slug = slug.replaceAll('?', '%3F');
    this.apiService.get({ api: this.BLOG_API_SLUG + slug }).subscribe(
      (response) => {
       this.blog = response;
       this.getBlog();
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Blog-detail.error-load'),
        });
      }
    );
  }
}
