import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-visa-form',
  templateUrl: './visa-form.component.html',
  styleUrls: ['./visa-form.component.scss'],
})
export class VisaFormComponent implements OnInit {
  /** CONSTANTS */
  readonly APPLICATION_API = 'api/aplicacion/';

  /** VARIABLES */
  /** Form variables */
  mainDataForm: FormGroup;
  workForm: FormGroup;
  parentsForm: FormGroup;
  coupleForm: FormGroup;
  childrenForm: FormGroup;
  financeForm: FormGroup;

  /** Variable that holds the application id */
  applicationId: string;

  /** Variable that holds the application */
  application: any;

  /** Variable that holds the index of the subform that is being displayed */
  currentForm = 0;

  /** Array that holds the options for the marital status question */
  maritalStatusOptions = [
    {
      name: 'Single',
      value: 'Soltero',
    },
    {
      name: 'Married',
      value: 'Casado',
    },
    {
      name: 'Free union',
      value: 'Union Libre',
    },
    {
      name: 'Divorced',
      value: 'Divorciado',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    this.mainDataForm = this.formBuilder.group({
      nombres: '',
      apellidos: '',
      fechaNacimiento: undefined,
      estadoCivil: '',
      numeroPasaporte: '',
      fechaExpedicionPasaporte: undefined,
      fechaVencimientoPasaporte: undefined,
      lugarExpedicionPasaporte: '',
      autoridadExpedidora: '',
      tienePasaportesAnteriores: false,
      pasaportesAnteriores: this.formBuilder.array([]),
      tipoDeDocumento: '',
      numeroDeDocumento: '',
      direccionDeResidencia: '',
      tiempoDeOcupacionDeLaResidencia: '',
      regimenDeTenencia: '',
      esViviendaFamiliar: false,
      telefonoFijo: undefined,
      telefonoCelular: undefined,
      correoElectronico: '',
      fechaDeEntradaAlPais: undefined,
      fechaSalidaDelPais: undefined,
      tipoInstitucionEducativa: '',
      nombreInstitucionEducativa: '',
      facultad: '',
      semestre: undefined,
      tituloProfesional: '',
      haViajadoAlExterior: false,
      viajesAlExterior: this.formBuilder.array([]),
      haTenidoVisa: false,
      visaAnterior: this.formBuilder.group({
        numero: '',
        fechaExpedicion: undefined,
        fechaVencimiento: undefined,
        archivo: '',
      }),
      leHanNegadoVisas: false,
      negaciones: this.formBuilder.array([]),
      visaAmericanaVigente: false,
      examenesDeIngles: this.formBuilder.array([]),
    });
    // Form for work information
    this.workForm = this.formBuilder.group({
      trabaja: false,
      nombreEmpresa: '',
      fechaIngresoTrabajo: undefined,
      nombreCargo: '',
      direccionEmpresa: '',
      correoEmpresarial: '',
      telefonoEmpresarial: '',
      nombreJefe: '',
      salarioMensual: undefined,
      otrasEntradasEconomicas: this.formBuilder.array([]),
      totalDeEntradasEnCuentaBancaria: undefined,
      propiedadesEnCop: undefined,
    });
    // Form for parent's information
    this.parentsForm = this.formBuilder.group({
      // Mother's information
      nombreMadre: '',
      fechaNacimientoMadre: undefined,
      lugarNacimientoMadre: '',
      madreEsFallecido: false,
      fechaFallecimientoMadre: undefined,
      lugarFallecimientoMadre: '',
      celularMadre: '',
      correoMadre: '',
      estadoCivilMadre: '',
      actividadEconomicaMadre: '',
      empresaMadre: '',
      cargoMadre: '',
      // Father's information
      nombrePadre: '',
      fechaNacimientoPadre: undefined,
      lugarNacimientoPadre: '',
      padreEsFallecido: false,
      fechaFallecimientoPadre: undefined,
      lugarFallecimientoPadre: '',
      celularPadre: '',
      correoPadre: '',
      estadoCivilPadre: '',
      actividadEconomicaPadre: '',
      empresaPadre: '',
      cargoPadre: '',
    });
    // Form for applicant's couple information
    this.coupleForm = this.formBuilder.group({
      nombrePareja: '',
      fechaNacimientoPareja: '',
      lugarNacimientoPareja: undefined,
    });

    // Form for children information
    this.childrenForm = this.formBuilder.group({
      tieneHijos: false,
      hijos: this.formBuilder.array([]),
    });

    // Form for the financing person information
    this.financeForm = this.formBuilder.group({
      nombreFinanciante: '',
      relacionConFinanciante: '',
      fechaNacimientoFinanciante: undefined,
      direccionFinanciante: '',
      celularFinanciante: '',
      correoFinanciante: '',
      actividadEconomicaFinanciante: '',
      cargoFinanciante: '',
      fechaInicioActividadEconomicaFinanciante: undefined,
      salarioMensualFinanciante: undefined,
    });

    this.getApplication();
  }

  /**
   * Return the information corresponding to the previous passports array
   */
  get previousPassports(): FormArray {
    return this.mainDataForm.get('pasaportesAnteriores') as FormArray;
  }

  /**
   * Return the information corresponding to the previous travels array
   */
  get previousTravels(): FormArray {
    return this.mainDataForm.get('viajesAlExterior') as FormArray;
  }

  /**
   * Return the information of previous visas that have been denied
   */
  get previousDenials(): FormArray {
    return this.mainDataForm.get('negaciones') as FormArray;
  }
  /**
   * Return the information of the english exams that the student has filled
   */
  get englishExams(): FormArray {
    return this.mainDataForm.get('examenesDeIngles') as FormArray;
  }

  /**
   * Return the information of other sources of income
   */
  get sourcesOfIncome(): FormArray {
    return this.workForm.get('otrasEntradasEconomicas') as FormArray;
  }
  /**
   * Returns the information of the children
   */
  get children(): FormArray {
    return this.childrenForm.get('hijos') as FormArray;
  }

  /**
   * adds a new passport to the previous passports array
   */
  onAddPassport(): void {
    const passport = this.formBuilder.group({
      numero: '',
      fechaExpedicion: undefined,
      fechaVencimiento: undefined,
      lugardeExpedicion: '',
    });
    this.previousPassports.push(passport);
  }
  /**
   * adds a new travel to the previous travels array
   */
  onAddTravel(): void {
    const travel = this.formBuilder.group({
      pais: '',
      fecha: undefined,
      motivo: '',
    });
    this.previousTravels.push(travel);
  }
  /**
   * adds a new denial to the visa denial array
   */
  onAddDenial(): void {
    const denial = this.formBuilder.group({
      pais: '',
      fecha: undefined,
      motivo: '',
    });
    this.previousDenials.push(denial);
  }
  /**
   * Adds a new exam to the english exams array
   */
  onAddExam(): void {
    const exam = this.formBuilder.group({
      nombre: '',
      fecha: undefined,
    });

    this.englishExams.push(exam);
  }
  /**
   * Adds a new source of income to the income sources list
   */
  onAddIncomeSource(): void {
    const source = this.formBuilder.group({
      descripcion: '',
      valor: undefined,
    });
    this.sourcesOfIncome.push(source);
  }
  /**
   * Adds a new child to the list of children
   */
  onAddChildren(): void {
    const child = this.formBuilder.group({
      nombre: '',
      fechaNacimiento: undefined,
      pasaporte: '',
      conQuienVive: '',
      ciudadDeResidencia: '',
      direccionDeResidencia: '',
      correo: '',
    });
    this.children.push(child);
  }
  /**
   * Removes the passport that has the given index from the previous passport list
   * @param index index of the passport in the list
   */
  onDeletePassport({ index }: { index: number }): void {
    this.previousPassports.removeAt(index);
  }
  /**
   * Removes the travel that has the given index from the previous travels list
   * @param index index of the travel in the list
   */
  onDeleteTravel({ index }: { index: number }): void {
    this.previousTravels.removeAt(index);
  }
  /**
   * Removes the denial that has the given index from the previous denials list
   * @param index index of the denial in the list
   */
  onDeleteDenial({ index }: { index: number }): void {
    this.previousDenials.removeAt(index);
  }

  /**
   * Removes the exam that has the given index from the previous exam list
   * @param index index of the exam in the list
   */
  onDeleteExam({ index }: { index: number }): void {
    this.englishExams.removeAt(index);
  }
  /**
   * Removes the income source that has the given index from the other sources of income list
   * @param index index of the income source in the list
   */
  onDeleteIncomeSource({ index }: { index: number }): void {
    this.sourcesOfIncome.removeAt(index);
  }
  /**
   * Removes the hilds that has the given index from the children list
   * @param index index of the child in the list
   */
  onDeleteChildren({ index }: { index: number }): void {
    this.children.removeAt(index);
  }
  /**
   * Changes the section that is going to be displayed
   * @param index index of the form section that is going to be displayed
   */
  onGoToSection({ index }: { index: number }): void {
    this.currentForm = index;
  }

  /**
   * Submits the form value to the backend
   */
  onSaveFormClick(): void {
    const generalInfo = this.mainDataForm.value;
    const workInfo = this.workForm.value;
    const parentsInfo = this.parentsForm.value;
    const coupleInfo = this.coupleForm.value;
    const childrenInfo = this.childrenForm.value;
    const financeInfo = this.financeForm.value;

    const fullForm = {
      ...generalInfo,
      ...workInfo,
      ...parentsInfo,
      ...coupleInfo,
      ...childrenInfo,
      ...financeInfo,
    };
    const application = this.application;
    application.pasos.getYourVisa.formularioVisa.formulario = fullForm;
    this.updateApplication({ application });
  }

  /**
   * Retrieves the application data from the backend
   */
  getApplication(): void {
    this.apiService.get({ api: this.APPLICATION_API + this.applicationId }).subscribe(
      (response) => {
        this.application = response;
        this.setupFormData({ form: response.pasos.getYourVisa.formularioVisa.formulario });
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was an error fetching the application data, please try again later',
        });
      }
    );
  }

  /**
   * Updates the application in the backend via api request
   * @param application application data
   */
  updateApplication({ application }: { application: any }): void {
    this.apiService.put({ api: this.APPLICATION_API + this.applicationId, data: application }).subscribe(
      (response) => {
        this.alertService.showSuccess({ msg: 'The changes have been saved' });
        this.ngOnInit();
      },
      (err) => {
        this.alertService.showError({ msg: 'There was an error saving the form please try again later' });
      }
    );
  }

  /**
   * Creates a date with a format that is recognized by browser date inputs
   * @param date date in string format
   */
  createdFormattedDate({ date }: { date: string }): string {
    if (date !== null) {
      return new Date(date).toISOString().substring(0, 10);
    } else {
      return null;
    }
  }

  /**
   * Setsupt the data of the form
   * @param form form data
   */
  setupFormData({ form }: { form: any }): void {
    // Setup main data form
    if (form.nombres !== undefined && form.nombres !== null) {
      this.mainDataForm.get('nombres').setValue(form.nombres);
    }
    if (form.apellidos !== undefined && form.apellidos !== null) {
      this.mainDataForm.get('apellidos').setValue(form.apellidos);
    }
    if (form.fechaNacimiento !== undefined && form.fechaNacimiento !== null) {
      this.mainDataForm.get('fechaNacimiento').setValue(this.createdFormattedDate({ date: form.fechaNacimiento }));
    }
    if (form.estadoCivil !== null && form.estadoCivil !== undefined) {
      this.mainDataForm.get('estadoCivil').setValue(form.estadoCivil);
    }
    if (form.numeroPasaporte !== null && form.numeroPasaporte !== undefined) {
      this.mainDataForm.get('numeroPasaporte').setValue(form.numeroPasaporte);
    }
    if (form.fechaExpedicionPasaporte !== null && form.fechaExpedicionPasaporte !== undefined) {
      this.mainDataForm
        .get('fechaExpedicionPasaporte')
        .setValue(this.createdFormattedDate({ date: form.fechaExpedicionPasaporte }));
    }
    if (form.fechaVencimientoPasaporte !== null && form.fechaVencimientoPasaporte !== undefined) {
      this.mainDataForm
        .get('fechaVencimientoPasaporte')
        .setValue(this.createdFormattedDate({ date: form.fechaVencimientoPasaporte }));
    }
    if (form.lugarExpedicionPasaporte !== null && form.lugarExpedicionPasaporte !== undefined) {
      this.mainDataForm.get('lugarExpedicionPasaporte').setValue(form.lugarExpedicionPasaporte);
    }
    if (form.autoridadExpedidora !== null && form.autoridadExpedidora !== undefined) {
      this.mainDataForm.get('autoridadExpedidora').setValue(form.autoridadExpedidora);
    }
    if (form.tienePasaportesAnteriores !== null && form.tienePasaportesAnteriores !== undefined) {
      this.mainDataForm.get('tienePasaportesAnteriores').setValue(form.tienePasaportesAnteriores);
    }
    if (form.pasaportesAnteriores !== null && form.pasaportesAnteriores !== undefined) {
      for (const passport of form.pasaportesAnteriores) {
        const formPassport = this.formBuilder.group({
          numero: passport.numero,
          fechaExpedicion: this.createdFormattedDate({ date: passport.fechaExpedicion }),
          fechaVencimiento: this.createdFormattedDate({ date: passport.fechaVencimiento }),
          lugardeExpedicion: passport.lugardeExpedicion,
        });
        this.previousPassports.push(formPassport);
      }
    }
    if (form.tipoDeDocumento !== null && form.tipoDeDocumento !== undefined) {
      this.mainDataForm.get('tipoDeDocumento').setValue(form.tipoDeDocumento);
    }
    if (form.numeroDeDocumento !== null && form.numeroDeDocumento !== undefined) {
      this.mainDataForm.get('numeroDeDocumento').setValue(form.numeroDeDocumento);
    }
    if (form.direccionDeResidencia !== null && form.direccionDeResidencia !== undefined) {
      this.mainDataForm.get('direccionDeResidencia').setValue(form.direccionDeResidencia);
    }
    if (form.tiempoDeOcupacionDeLaResidencia !== null && form.tiempoDeOcupacionDeLaResidencia !== undefined) {
      this.mainDataForm.get('tiempoDeOcupacionDeLaResidencia').setValue(form.tiempoDeOcupacionDeLaResidencia);
    }
    if (form.regimenDeTenencia !== null && form.regimenDeTenencia !== undefined) {
      this.mainDataForm.get('regimenDeTenencia').setValue(form.regimenDeTenencia);
    }
    if (form.esViviendaFamiliar !== null && form.esViviendaFamiliar !== undefined) {
      this.mainDataForm.get('esViviendaFamiliar').setValue(form.esViviendaFamiliar);
    }
    if (form.telefonoFijo !== null && form.telefonoFijo !== undefined) {
      this.mainDataForm.get('telefonoFijo').setValue(form.telefonoFijo);
    }
    if (form.telefonoCelular !== null && form.telefonoCelular !== undefined) {
      this.mainDataForm.get('telefonoCelular').setValue(form.telefonoCelular);
    }
    if (form.correoElectronico !== null && form.correoElectronico !== undefined) {
      this.mainDataForm.get('correoElectronico').setValue(form.correoElectronico);
    }
    if (form.fechaDeEntradaAlPais !== null && form.fechaDeEntradaAlPais !== undefined) {
      this.mainDataForm
        .get('fechaDeEntradaAlPais')
        .setValue(this.createdFormattedDate({ date: form.fechaDeEntradaAlPais }));
    }
    if (form.fechaSalidaDelPais !== null && form.fechaSalidaDelPais !== undefined) {
      this.mainDataForm
        .get('fechaSalidaDelPais')
        .setValue(this.createdFormattedDate({ date: form.fechaSalidaDelPais }));
    }
    if (form.tipoInstitucionEducativa !== null && form.tipoInstitucionEducativa !== undefined) {
      this.mainDataForm.get('tipoInstitucionEducativa').setValue(form.tipoInstitucionEducativa);
    }
    if (form.nombreInstitucionEducativa !== null && form.nombreInstitucionEducativa !== undefined) {
      this.mainDataForm.get('nombreInstitucionEducativa').setValue(form.nombreInstitucionEducativa);
    }
    if (form.facultad !== null && form.facultad !== undefined) {
      this.mainDataForm.get('facultad').setValue(form.facultad);
    }
    if (form.semestre !== null && form.semestre !== undefined) {
      this.mainDataForm.get('semestre').setValue(form.semestre);
    }
    if (form.tituloProfesional !== null && form.tituloProfesional !== undefined) {
      this.mainDataForm.get('tituloProfesional').setValue(form.tituloProfesional);
    }
    if (form.haViajadoAlExterior !== null && form.haViajadoAlExterior !== undefined) {
      this.mainDataForm.get('haViajadoAlExterior').setValue(form.haViajadoAlExterior);
    }
    if (form.viajesAlExterior !== null && form.viajesAlExterior !== undefined) {
      for (const travel of form.viajesAlExterior) {
        const formTravel = this.formBuilder.group({
          pais: travel.pais,
          fecha: this.createdFormattedDate({ date: travel.fecha }),
          motivo: travel.motivo,
        });
        this.previousTravels.push(formTravel);
      }
    }
    if (form.haTenidoVisa !== null && form.haTenidoVisa !== undefined) {
      this.mainDataForm.get('haTenidoVisa').setValue(form.haTenidoVisa);
    }
    if (form.visaAnterior !== null && form.visaAnterior !== undefined) {
      const visaAnteriorForm = this.formBuilder.group({
        numero: form.visaAnterior.numero,
        fechaExpedicion: this.createdFormattedDate({ date: form.visaAnterior.fechaExpedicion }),
        fechaVencimiento: this.createdFormattedDate({ date: form.visaAnterior.fechaVencimiento }),
        archivo: form.visaAnterior.archivo,
      });
      this.mainDataForm.get('visaAnterior').get('numero').setValue(form.visaAnterior.numero);
      this.mainDataForm
        .get('visaAnterior')
        .get('fechaExpedicion')
        .setValue(this.createdFormattedDate({ date: form.visaAnterior.fechaExpedicion }));

      this.mainDataForm
        .get('visaAnterior')
        .get('fechaVencimiento')
        .setValue(this.createdFormattedDate({ date: form.visaAnterior.fechaVencimiento }));

      this.mainDataForm.get('visaAnterior').get('archivo').setValue(form.visaAnterior.archivo);
    }
    if (form.leHanNegadoVisas !== null && form.leHanNegadoVisas !== undefined) {
      this.mainDataForm.get('leHanNegadoVisas').setValue(form.leHanNegadoVisas);
    }
    if (form.negaciones !== null && form.negaciones !== undefined) {
      for (const denial of form.negaciones) {
        const denialForm = this.formBuilder.group({
          pais: denial.pais,
          fecha: this.createdFormattedDate({ date: denial.fecha }),
          motivo: denial.motivo,
        });
        this.previousDenials.push(denialForm);
      }
    }
    if (form.visaAmericanaVigente !== null && form.visaAmericanaVigente !== undefined) {
      this.mainDataForm.get('visaAmericanaVigente').setValue(form.visaAmericanaVigente);
    }
    if (form.examenesDeIngles !== null && form.examenesDeIngles !== undefined) {
      for (const exam of form.examenesDeIngles) {
        const formExam = this.formBuilder.group({
          nombre: exam.nombre,
          fecha: this.createdFormattedDate({ date: exam.fecha }),
        });
        this.englishExams.push(formExam);
      }
    }

    // Setup work data form
    if (form.trabaja !== null && form.trabaja !== undefined) {
      this.workForm.get('trabaja').setValue(form.trabaja);
    }
    if (form.nombreEmpresa !== null && form.nombreEmpresa !== undefined) {
      this.workForm.get('nombreEmpresa').setValue(form.nombreEmpresa);
    }
    if (form.fechaIngresoTrabajo !== null && form.fechaIngresoTrabajo !== undefined) {
      this.workForm.get('fechaIngresoTrabajo').setValue(this.createdFormattedDate({ date: form.fechaIngresoTrabajo }));
    }
    if (form.nombreCargo !== null && form.nombreCargo !== undefined) {
      this.workForm.get('nombreCargo').setValue(form.nombreCargo);
    }
    if (form.direccionEmpresa !== null && form.direccionEmpresa !== undefined) {
      this.workForm.get('direccionEmpresa').setValue(form.direccionEmpresa);
    }
    if (form.correoEmpresarial !== null && form.correoEmpresarial !== undefined) {
      this.workForm.get('correoEmpresarial').setValue(form.correoEmpresarial);
    }
    if (form.telefonoEmpresarial !== null && form.telefonoEmpresarial !== undefined) {
      this.workForm.get('telefonoEmpresarial').setValue(form.telefonoEmpresarial);
    }
    if (form.nombreJefe !== null && form.nombreJefe !== undefined) {
      this.workForm.get('nombreJefe').setValue(form.nombreJefe);
    }
    if (form.salarioMensual !== null && form.salarioMensual !== undefined) {
      this.workForm.get('salarioMensual').setValue(form.salarioMensual);
    }
    if (form.otrasEntradasEconomicas !== null && form.otrasEntradasEconomicas !== undefined) {
      for (const income of form.otrasEntradasEconomicas) {
        const formIncome = this.formBuilder.group({
          descripcion: income.descripcion,
          valor: income.valor,
        });
        this.sourcesOfIncome.push(formIncome);
      }
    }
    if (form.totalDeEntradasEnCuentaBancaria !== null && form.totalDeEntradasEnCuentaBancaria !== undefined) {
      this.workForm.get('totalDeEntradasEnCuentaBancaria').setValue(form.totalDeEntradasEnCuentaBancaria);
    }
    if (form.propiedadesEnCop !== null && form.propiedadesEnCop !== undefined) {
      this.workForm.get('propiedadesEnCop').setValue(form.propiedadesEnCop);
    }

    // Setup parents data form
    if (form.nombreMadre !== undefined && form.nombrePadre !== null) {
      this.parentsForm.get('nombreMadre').setValue(form.nombreMadre);
    }
    if (form.fechaNacimientoMadre !== null && form.fechaNacimientoMadre !== undefined) {
      this.parentsForm
        .get('fechaNacimientoMadre')
        .setValue(this.createdFormattedDate({ date: form.fechaNacimientoMadre }));
    }
    if (form.lugarNacimientoMadre !== null && form.lugarNacimientoMadre !== undefined) {
      this.parentsForm.get('lugarNacimientoMadre').setValue(form.lugarNacimientoMadre);
    }
    if (form.madreEsFallecido !== null && form.madreEsFallecido !== undefined) {
      this.parentsForm.get('madreEsFallecido').setValue(form.madreEsFallecido);
    }
    if (form.fechaFallecimientoMadre !== null && form.fechaFallecimientoMadre !== undefined) {
      this.parentsForm
        .get('fechaFallecimientoMadre')
        .setValue(this.createdFormattedDate({ date: form.fechaFallecimientoMadre }));
    }
    if (form.lugarFallecimientoMadre !== null && form.lugarFallecimientoMadre !== undefined) {
      this.parentsForm.get('lugarFallecimientoMadre').setValue(form.lugarFallecimientoMadre);
    }
    if (form.celularMadre !== null && form.celularMadre !== undefined) {
      this.parentsForm.get('celularMadre').setValue(form.celularMadre);
    }
    if (form.correoMadre !== null && form.correoMadre !== undefined) {
      this.parentsForm.get('correoMadre').setValue(form.correoMadre);
    }
    if (form.estadoCivilMadre !== null && form.estadoCivilMadre !== undefined) {
      this.parentsForm.get('estadoCivilMadre').setValue(form.estadoCivilMadre);
    }
    if (form.actividadEconomicaMadre !== null && form.actividadEconomicaMadre !== undefined) {
      this.parentsForm.get('actividadEconomicaMadre').setValue(form.actividadEconomicaMadre);
    }
    if (form.empresaMadre !== null && form.empresaMadre !== undefined) {
      this.parentsForm.get('empresaMadre').setValue(form.empresaMadre);
    }
    if (form.cargoMadre !== null && form.cargoMadre !== undefined) {
      this.parentsForm.get('cargoMadre').setValue(form.cargoMadre);
    }
    if (form.nombrePadre !== null && form.nombrePadre !== undefined) {
      this.parentsForm.get('nombrePadre').setValue(form.nombrePadre);
    }
    if (form.fechaNacimientoPadre !== null && form.fechaNacimientoPadre !== undefined) {
      this.parentsForm
        .get('fechaNacimientoPadre')
        .setValue(this.createdFormattedDate({ date: form.fechaNacimientoPadre }));
    }
    if (form.lugarNacimientoPadre !== null && form.lugarNacimientoPadre !== undefined) {
      this.parentsForm.get('lugarNacimientoPadre').setValue(form.lugarNacimientoPadre);
    }
    if (form.padreEsFallecido !== null && form.padreEsFallecido !== undefined) {
      this.parentsForm.get('padreEsFallecido').setValue(form.padreEsFallecido);
    }
    if (form.fechaFallecimientoPadre !== null && form.fechaFallecimientoPadre !== undefined) {
      this.parentsForm
        .get('fechaFallecimientoPadre')
        .setValue(this.createdFormattedDate({ date: form.fechaFallecimientoPadre }));
    }
    if (form.lugarFallecimientoPadre !== null && form.lugarFallecimientoPadre !== undefined) {
      this.parentsForm.get('lugarFallecimientoPadre').setValue(form.lugarFallecimientoPadre);
    }
    if (form.celularPadre !== null && form.celularPadre !== undefined) {
      this.parentsForm.get('celularPadre').setValue(form.celularPadre);
    }
    if (form.correoPadre !== null && form.correoPadre !== undefined) {
      this.parentsForm.get('correoPadre').setValue(form.correoPadre);
    }
    if (form.estadoCivilPadre !== null && form.estadoCivilPadre !== undefined) {
      this.parentsForm.get('estadoCivilPadre').setValue(form.estadoCivilPadre);
    }
    if (form.actividadEconomicaPadre !== null && form.actividadEconomicaPadre !== undefined) {
      this.parentsForm.get('actividadEconomicaPadre').setValue(form.actividadEconomicaPadre);
    }
    if (form.empresaPadre !== null && form.empresaPadre !== undefined) {
      this.parentsForm.get('empresaPadre').setValue(form.empresaPadre);
    }
    if (form.cargoPadre !== null && form.cargoPadre !== undefined) {
      this.parentsForm.get('cargoPadre').setValue(form.cargoPadre);
    }

    // setup couple form
    if (form.nombrePareja !== null && form.nombrePareja !== undefined) {
      this.coupleForm.get('nombrePareja').setValue(form.nombrePareja);
    }
    if (form.fechaNacimientoPareja !== null && form.fechaNacimientoPareja !== undefined) {
      this.coupleForm
        .get('fechaNacimientoPareja')
        .setValue(this.createdFormattedDate({ date: form.fechaNacimientoPareja }));
    }
    if (form.lugarNacimientoPareja !== null && form.lugarNacimientoPareja !== undefined) {
      this.coupleForm.get('lugarNacimientoPareja').setValue(form.lugarNacimientoPareja);
    }
    // setup children form
    if (form.tieneHijos !== undefined && form.tieneHijos !== null) {
      this.childrenForm.get('tieneHijos').setValue(form.tieneHijos);
    }
    if (form.hijos !== null && form.hijos !== undefined) {
      for (const child of form.hijos) {
        const formChild = this.formBuilder.group({
          nombre: child.nombre,
          fechaNacimiento: this.createdFormattedDate({ date: child.fechaNacimiento }),
          pasaporte: child.pasaporte,
          conQuienVive: child.conQuienVive,
          ciudadDeResidencia: child.ciudadDeResidencia,
          direccionDeResidencia: child.direccionDeResidencia,
          correo: child.correo,
        });
        this.children.push(formChild);
      }
    }

    // Setup sponsor form
    if (form.nombreFinanciante !== null && form.nombreFinanciante !== undefined) {
      this.financeForm.get('nombreFinanciante').setValue(form.nombreFinanciante);
    }
    if (form.relacionConFinanciante !== null && form.relacionConFinanciante !== undefined) {
      this.financeForm.get('relacionConFinanciante').setValue(form.relacionConFinanciante);
    }
    if (form.fechaNacimientoFinanciante !== null && form.fechaNacimientoFinanciante !== undefined) {
      this.financeForm
        .get('fechaNacimientoFinanciante')
        .setValue(this.createdFormattedDate({ date: form.fechaNacimientoFinanciante }));
    }
    if (form.direccionFinanciante !== null && form.direccionFinanciante !== undefined) {
      this.financeForm.get('direccionFinanciante').setValue(form.direccionFinanciante);
    }
    if (form.celularFinanciante !== null && form.celularFinanciante !== undefined) {
      this.financeForm.get('celularFinanciante').setValue(form.celularFinanciante);
    }
    if (form.correoFinanciante !== null && form.correoFinanciante !== undefined) {
      this.financeForm.get('correoFinanciante').setValue(form.correoFinanciante);
    }
    if (form.actividadEconomicaFinanciante !== null && form.actividadEconomicaFinanciante !== undefined) {
      this.financeForm.get('actividadEconomicaFinanciante').setValue(form.actividadEconomicaFinanciante);
    }
    if (form.cargoFinanciante !== null && form.cargoFinanciante !== undefined) {
      this.financeForm.get('cargoFinanciante').setValue(form.cargoFinanciante);
    }
    if (
      form.fechaInicioActividadEconomicaFinanciante !== null &&
      form.fechaInicioActividadEconomicaFinanciante !== undefined
    ) {
      this.financeForm.get('fechaInicioActividadEconomicaFinanciante').setValue(
        this.createdFormattedDate({
          date: form.fechaInicioActividadEconomicaFinanciante,
        })
      );
    }
    if (form.salarioMensualFinanciante !== null && form.salarioMensualFinanciante !== undefined) {
      this.financeForm.get('salarioMensualFinanciante').setValue(form.salarioMensualFinanciante);
    }
  }
}
