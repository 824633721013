<div class="card card-complement">
  <div class="card-body">
    <div class="row">
      <div class="col-xl-8" style="margin-bottom: 1%;">
        <div class="display-mobile-none">
          <label class="program-title" (click)="onClickProgram()">
            {{programName | ellipsis:42}}
          </label>
        </div>
        <div class="display-mobile">
          <label class="program-title" (click)="onClickProgram()">
            {{programName | ellipsis:80}}
          </label>
        </div>
        <div>
          <img class="country-img" [src]="countryImg" alt="{{country}}">
          <label class="city-country" (click)="onClickCity()">{{city}}, {{country}}</label>
        </div>
      </div>
      <div class="col-xl-4 container-university-logo">
        <img class="app-university-img" [src]="universityLogo" alt="{{university}}">
      </div>
    </div>

    <div class="row">
      <div class="row col-xl-10" *ngIf="!fromSchool">
        <div class="col-xl-4">
          <p class="program-detail">
            <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
            Matricula:
            <span *ngIf="tuitionCost">${{tuitionCost}} {{countryCurrency}}</span>
            <span *ngIf="!tuitionCost">ND</span>
          </p>
        </div>

        <div class="col-xl-6">
          <p class="program-detail">
            <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
            Aplicación:
            <span *ngIf="applicationCost">${{applicationCost}} {{countryCurrency}}</span>
            <span *ngIf="!applicationCost">ND</span>
          </p>
        </div>

        <div class="col-xl-4">
          <p class="current-step-desc">
           <strong class="current-step">Paso actual:</strong> {{currentStep}}
          </p>
        </div>

        <div class="col-xl-6" style="font-size: 14px;">
          <p class="time-text" *ngIf="daysLeft < 0">
            <img src="/assets/utils/clock.png" alt="time" class="img-fee">
            {{ "Application-card.next-step" | translate }} {{daysLeft}} {{ "Application-card.days" | translate }}
          </p>

          <p class="time-text" *ngIf="daysLeft === undefined">
            <img src="/assets/utils/clock.png" alt="time" class="img-fee">
            {{ "Application-card.limit-not-set" | translate }}
          </p>
        </div>
      </div>

      <div class="row col-xl-12 ml-0" *ngIf="fromSchool">
        <div class="d-flex mr-3">
          <p class="program-detail">
            <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
            Matricula:
            <span *ngIf="tuitionCost">${{tuitionCost}} {{countryCurrency}}</span>
            <span *ngIf="!tuitionCost">ND</span>
          </p>
        </div>

        <div class="d-flex mr-3">
          <p class="program-detail">
            <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
            Aplicación:
            <span *ngIf="applicationCost">${{applicationCost}} {{countryCurrency}}</span>
            <span *ngIf="!applicationCost">ND</span>
          </p>
        </div>

        <div class="d-flex mr-3" style="font-size: 14px;">
          <p class="time-text" *ngIf="daysLeft < 0">
            <img src="/assets/utils/clock.png" alt="time" class="img-fee">
            {{ "Application-card.next-step" | translate }} {{daysLeft}} {{ "Application-card.days" | translate }}
          </p>

          <p class="time-text" *ngIf="daysLeft === undefined">
            <img src="/assets/utils/clock.png" alt="time" class="img-fee">
            {{ "Application-card.limit-not-set" | translate }}
          </p>
        </div>

      </div>


      <div class="col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start" *ngIf="fromSchool">
        <p class="current-step-desc-from-school">
         <strong class="current-step">Paso actual:</strong> {{currentStep}}
        </p>
      </div>

      <div class="col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start" *ngIf="fromSchool">
        <label class="value-afinity-probability" style="margin-right: 10px;"><strong class="afinity-probability">Afinidad: </strong>{{afinity}}%</label>
        <label class="value-afinity-probability"><strong class="afinity-probability">Probabilidad: </strong>{{probability}}%</label>
      </div>


    </div>

    <div class="row last-part" *ngIf="!fromSchool">
      <div class="col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start">
        <label class="value-afinity-probability" style="margin-right: 10px;"><strong class="afinity-probability">Afinidad: </strong>{{afinity}}%</label>
        <label class="value-afinity-probability"><strong class="afinity-probability">Probabilidad: </strong>{{probability}}%</label>
      </div>
      <div class="col-xl-6 d-flex flex-lg-row flex-column justify-content-end align-items-center px-0">
        <a (click)="onCancelClick()" class="cancel-app mr-3 display-mobile-none" style="margin-left: 14px;">Cancelar aplicación</a>
        <a [routerLink]="'../application/' + id"><button class="button-action mt-2 mt-xl-0">Ir al paso actual</button></a>
        <a (click)="onCancelClick()" class="cancel-app mr-3 display-mobile mt-2" style="margin-left: 14px;">Cancelar aplicación</a>
      </div>
    </div>
  </div>
</div>
