import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-test-dashboard',
  templateUrl: './currentTestDashboard.component.html',
  styleUrls: ['./currentTestDashboard.component.scss'],
})
export class CurrentTestDashboard implements OnInit {
  readonly getSkill = 'api/getSkill/';
  readonly getData = 'api/skillsTest/';

  public userData = JSON.parse(localStorage.getItem('user'));

  chartOptions: any;
  chartData: any;
  skillsScore: any;
  generalScore: any;
  skillsPointsAverage: any;
  skills: any;
  combinedData: any;
  filteredResponse: any;

  desiredInstitutionAverage: any;
  institutionLevel: any;
  radarChart: any;
  skillsAverage: any;
  averageChartoptions: any;
  options: any;
  secondarySkillsChartOptions: any;
  secondarySkills: any;
  skillsValues: any;
  averageLevelDescription: any;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getSkillData();
  }

  getSkillData(): void {
    this.apiService.get({ api: this.getData + this.userData._id }).subscribe((response) => {
      this.skillsScore = response.skillPoints;

      const averageSkills = { ...this.skillsScore };

      this.generalScore = Object.keys(this.skillsScore)
        .filter((key) => key !== 'Inteligencia Emocional')
        .reduce((sum, key) => sum + this.skillsScore[key], 0);
      const primarySkills = Object.keys(this.skillsScore).filter((skill) => skill !== 'Inteligencia Emocional');

      delete averageSkills['Inteligencia Emocional'];

      this.skillsPointsAverage = (this.generalScore / primarySkills.length).toFixed(2);
      this.skillsScore.Promedio = +this.skillsPointsAverage;
      this.getSkills();
      this.skillsValues = Object.values(averageSkills);
    });
  }

  getSkills() {
    this.apiService.get({ api: this.getSkill }).subscribe((response) => {
      const averageSkill = response.find((skill) => skill.skillName === 'Desempeño Global Competencias');

      if (averageSkill) {
        const averageLevel = this.findLevel(averageSkill.levels, this.skillsScore.Promedio);

        const averageObject = {
          name: 'Desempeño Global Competencias',
          score: this.skillsScore.Promedio,
          level: averageLevel.level,
          levelDescription: averageLevel.description,
        };

        this.averageLevelDescription = averageLevel.description;
        this.skillsAverage = averageObject;
        this.displayAverageChart(this.skillsAverage);

        this.skills = response.filter((skill) => skill.isComplementary === false);
        this.secondarySkills = response.filter((skill) => skill.isComplementary === true);

        const skillsObjects = [];
        const secondarySkillsObjects = [];

        Object.entries(this.skillsScore).forEach(([displayName, skillScore]) => {
          const matchingSkill = this.skills.find((skill) => skill.skillName === displayName);

          if (matchingSkill) {
            const level = this.findLevel(matchingSkill.levels, skillScore);
            const skillObject = {
              name: displayName,
              score: skillScore,
              level: level.level,
              levelDescription: level.description,
            };

            skillsObjects.push(skillObject);
          }
        });

        Object.entries(this.skillsScore).forEach(([displayName, skillScore]) => {
          const matchingSkill = this.secondarySkills.find((skill) => skill.skillName === displayName);

          if (displayName !== 'Desempeño Global Competencias') {
            if (matchingSkill) {
              const level = this.findLevel(matchingSkill.levels, skillScore);
              const secondarySkillsObject = {
                name: displayName,
                score: skillScore,
                level: level.level,
                levelDescription: level.description,
              };

              secondarySkillsObjects.push(secondarySkillsObject);
            }
          }
        });

        this.displayChart(skillsObjects);
        this.displayRadarChart(skillsObjects);
        this.displayZeroWeightChart(secondarySkillsObjects);
      } else {
        console.error("Skill 'Desempeño Global Competencias' not found in the response.");
      }
    });
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }

  displayChart(skillsObjects) {
    const dataToDisplay = {
      categories: skillsObjects.map((skill) => skill.name),
      data: skillsObjects.map((skill) => skill.score),
      levels: skillsObjects.map((skill) => skill.level),
      levelsDescription: skillsObjects.map((skill) => skill.levelDescription),
    };
    this.chartOptions = {
      series: [
        {
          name: 'Porcentaje',
          data: dataToDisplay.data,
        },
      ],
      theme: {
        palette: 'palette1',
      },
      chart: {
        height: 400,
        type: 'bar',
      },

      fill: {
        opacity: 0.8,
      },
      plotOptions: {
        bar: {
          columnWidth: '25%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (y) {
          const index = dataToDisplay.data.indexOf(y);
          const level = dataToDisplay.levels[index];
          return `${y.toFixed(0)}% - ${level}`;
        },
        offsetY: -20,
        style: {
          fontSize: '15px',
          colors: ['#304758'],
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: dataToDisplay.categories,
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y.toFixed(0) + '%';
          },
        },
      },

      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const score = series[seriesIndex][dataPointIndex];
          const index = dataToDisplay.data.indexOf(score);
          const level = dataToDisplay.levels[index];
          const levelDescription = dataToDisplay.levelsDescription[index];
          return (
            '<div class="arrow_box" style="overflow-wrap: break-word; display: flex; flex-direction: column; width: 70vw;">' +
            '<span style="text-align: center; font-weight: bold;">' +
            score.toFixed(0) +
            '%' +
            ' - ' +
            level +
            '</span>' +
            '<div style="overflow-wrap: break-word; word-wrap: break-word; text-align: center; white-space: pre-wrap;">' +
            levelDescription +
            '</div>' +
            '</div>'
          );
        },
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
      },

      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
      labels: {
        style: {
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
          fontSize: '12px',
        },
      },
    };
  }
  displayZeroWeightChart(zeroWeightSkillsObjects) {
    const dataToDisplay = {
      categories: zeroWeightSkillsObjects.map((skill) => skill.name),
      data: zeroWeightSkillsObjects.map((skill) => skill.score),
      levels: zeroWeightSkillsObjects.map((skill) => skill.level),
      levelsDescription: zeroWeightSkillsObjects.map((skill) => skill.levelDescription),
    };
    this.secondarySkillsChartOptions = {
      series: [
        {
          name: 'Porcentaje',
          data: dataToDisplay.data,
        },
      ],
      theme: {
        palette: 'palette1',
      },
      chart: {
        height: 400,
        type: 'bar',
      },

      fill: {
        opacity: 0.8,
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (y) {
          const index = dataToDisplay.data.indexOf(y);
          const level = dataToDisplay.levels[index];
          return `${y.toFixed(0)}% - ${level}`;
        },
        offsetY: -20,
        style: {
          fontSize: '15px',
          colors: ['#304758'],
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: dataToDisplay.categories,
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y.toFixed(0) + '%';
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const score = series[seriesIndex][dataPointIndex];
          const index = dataToDisplay.data.indexOf(score);
          const level = dataToDisplay.levels[index];
          const levelDescription = dataToDisplay.levelsDescription[index];
          return (
            '<div class="arrow_box" style="overflow-wrap: break-word; display: flex; flex-direction: column; width: 70vw;">' +
            '<span style="text-align: center; font-weight: bold;">' +
            score.toFixed(0) +
            '%' +
            ' - ' +
            level +
            '</span>' +
            '<div style="overflow-wrap: break-word; word-wrap: break-word; text-align: center; white-space: pre-wrap;">' +
            levelDescription +
            '</div>' +
            '</div>'
          );
        },
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
      },

      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
      labels: {
        style: {
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
          fontSize: '12px',
        },
      },
    };
  }

  displayAverageChart(averageScore) {
    const averageScore2 = averageScore.score;

    this.averageChartoptions = {
      series: [averageScore2],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
        },
      },
      labels: [`${averageScore.level}`],
    };
  }
  displayRadarChart(skillObjects) {
    const seriesData = skillObjects.map((skill) => skill.score);
    this.radarChart = {
      chart: {
        height: 500,
        width: 700,
        type: 'radar',
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      series: [
        {
          name: 'Skills',
          data: seriesData,
          color: 'rgba(255, 255, 255, 0.5)',
        },
      ],
      plotOptions: {
        radar: {
          size: 150,
          polygons: {
            strokeColors: '#969FFB',
            fill: {
              colors: ['#d7dbff'],
            },
          },
        },
      },
      xaxis: {
        categories: skillObjects.map((skill) => [`${skill.name} ${skill.score}%-${skill.level}`]),
        labels: {
          style: {
            colors: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700'],
          },
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    };
  }
}
