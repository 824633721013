import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor( private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const url = state.url;
      if(url === '/english-test'){
        this.authService.sitioMensaje = 'english_test';
      } else {
        this.authService.sitioMensaje = '';
      };
      if (this.authService.loggedIn.value === true) {
        return true;
      } else {
        this.authService.setIntendedUrl({url});
        this.router.navigate(['login']);
        return false;
      }
  }
}
