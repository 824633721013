<main>
  <!-- VIDEO -->
  <div class="container-video" *ngIf="programCall?.videoAplicar">
    <iframe
      width="900"
      height="500"
      [src]="programCall?.videoAplicar | safe"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
  <!-- TEXT -->
  <div class="inf-universidad">
    {{ programCall?.textoAplicar }}
  </div>
  <div class="container-inf">
    <div class="mas-inf separator" (click)="onWantAnAgency()">
      {{ "Programs-list.suggest-agency" | translate }}
    </div>
    <div class="mas-inf separator" (click)="onWantInformationForm()">
      {{ "Programs-list.want-information" | translate }}
    </div>
    <button class="separator" (click)="onApply()">
      {{ "Programs-list.yes-want-apply" | translate }}
    </button>
  </div>
</main>
