import { Component, OnInit, HostListener, Input, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewAgenciesComponent } from '../../shared/view-agencies/view-agencies.component';

@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent implements OnInit {

  private readonly API_FORM = 'api/formulario';

  /* Child References */
  createForm: FormGroup = this.fb.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    correo: ['', [Validators.required, Validators.email]],
    anioInteres: ['', [Validators.required]],
    edad: ['', [Validators.required]],
    telefono: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
    programa: ['', [Validators.required]],
    pregunta: ['', [Validators.required]],
  });

  // Display form
  public displayForm: boolean = false;

  // Declare width variable
  scrWidth: any;
  isInformation: boolean = false;

  @Input() university: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private dialogRef: MatDialogRef<ViewAgenciesComponent>) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    if (this.data) {
      this.university = this.data;
      this.isInformation = true;
    }
  }

  /**
   * Validate that the fields are correct
   * @param field field to validate
   */
  public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched &&
      this.createForm.controls[field].invalid
    );
  }

  onSubmit({ event }): void {
    if (this.createForm.valid) {
      const form = {
        nombre: this.createForm.controls.nombre.value,
        apellido: this.createForm.controls.apellido.value,
        correo: this.createForm.controls.correo.value,
        anioInteres: this.createForm.controls.anioInteres.value,
        edad: this.createForm.controls.edad.value,
        telefono: this.createForm.controls.telefono.value,
        programa: this.createForm.controls.programa.value,
        pregunta: this.createForm.controls.pregunta.value,
        fuente: "Contacta universidad: "+this.university.nombre,
      };
      this.createFormByContact({ form });
    } else {
      this.createForm.markAllAsTouched();
    }
  }

  /**
   * create a form
   * @param form
   */
  public async createFormByContact({ form }) {
    let resp = await this.apiService
      .post({ api: `${this.API_FORM}`, data: form })
      .toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Formulario creado`,
      });
    }
  }

  public showDetails({ item }) {
    if (item == 'form') {
      this.displayForm = !this.displayForm;
    }
  }

}
