<div class="principal-container">
  <div class="max-width">
    <form (ngSubmit)="onSubmit({ event: $event.submitter })" [formGroup]="createForm">
      <div class="container-body">
        <div class="container-representative">
          <label>
            ¡Si quieres conocer SIN COSTO los siguientes pasos para tu plan de viaje, por favor déjanos tus datos!
          </label>
          <p></p>
        </div>
        <div class="container-form">
          <div class="container-form-label">
            <div class="container-year">
              <div class="year">
                <label for="year">Año de interés</label>
                <input
                  [ngClass]="{ 'red-border': validField({ field: 'anioInteres' }) }"
                  formControlName="anioInteres"
                  id="year"
                  class="form-control"
                  min="0"
                  type="number"
                  placeholder="Año" />
                <span *ngIf="validField({ field: 'anioInteres' })" class="invalid-warning">Campo requerido!</span>
              </div>
              <div class="year-old">
                <label for="yearOld">Edad</label>
                <input
                  [ngClass]="{ 'red-border': validField({ field: 'edad' }) }"
                  id="yearOld"
                  formControlName="edad"
                  class="form-control"
                  min="0"
                  type="number"
                  placeholder="Edad" />
                <span *ngIf="validField({ field: 'edad' })" class="invalid-warning">Campo requerido!</span>
              </div>
            </div>
          </div>

          <div class="container-form-label">
            <label for="program">Programa</label>
            <input
              [ngClass]="{ 'red-border': validField({ field: 'programa' }) }"
              id="program"
              formControlName="programa"
              class="form-control"
              type="text"
              placeholder="Programa de interés" />
            <span *ngIf="validField({ field: 'programa' })" class="invalid-warning">Campo requerido!</span>
          </div>
          <div class="container-form-label">
            <label for="question">Preguntas / Código</label>
            <textarea
              [ngClass]="{ 'red-border': validField({ field: 'pregunta' }) }"
              formControlName="pregunta"
              id="question"
              class="form-control"
              type="text"
              placeholder="Por favor escribe tu pregunta principal aquí"></textarea>
            <span *ngIf="validField({ field: 'pregunta' })" class="invalid-warning">Campo requerido!</span>
          </div>
        </div>
      </div>
      <div class="btn-form">
        <button type="submit">Enviar</button>
      </div>
    </form>
  </div>
</div>
