import Hammer from '@egjs/hammerjs';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from './../services/navigation.service';
import { ApiService } from './../services/api.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { AlertService } from '../services/alert.service';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../services/seo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  /** API path for getting the testimonials */
  readonly TESTIMONIALS_API = 'api/testimonio';
  /** API to send contact request emails emails */
  readonly CONTACT_API = 'email/send-contact-mail';
  /** Constant that holds the path to the blog API */
  readonly BLOG_API = 'api/blog/';
  /** Constant that holds the path to the blog API */
  readonly CONFIG_API = 'api/config/';
  /** Variable that contain all blogs */
  blogs: any = [];
  /** Variable that contain all configs */
  config: any;
  /** Variable that determines which tab is selected */
  selectedTab = 0;
  /** Variable that determines which slide is currently selected in the testimonies section */
  selectedSlide = 0;
  /** Variable that holds the data for the slides */
  slidesData: any[] = [];
  /** Variable that determines if the user is logged in */
  loggedIn = false;
  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;
  /** Variable that holds the contact request form */
  contactRequestForm: FormGroup;
  /** Variable that holds the swipe manager for testimonials */
  @ViewChild('slideshow') slideshow: ElementRef;
  /** Variable that holds the hammer */
  hammer: any;
  /** FILTER VARIABLES */
  /** Variable that holds the value for the name input */
  name = '';
  /** Variable that holds the value for the country select input */
  country: Array<any> = [];
  /** Variable that holds the value for the city select input */
  city: Array<any> = [];
  /** Variable that holds the value for the region select input */
  region: Array<any> = [];
  /** Variable that holds the value for the university select input */
  university: Array<any> = [];
  /** Variable that holds the value for the accreditation filter */
  accreditation: Array<any> = [];
  area: Array<any> = [];
  /** Variable that holds the value for the discipline filter */
  discipline: Array<any> = [];
  /** Variable that holds the value for the start time filter */
  startTime: Array<any> = [];
  /** Variable that holds the minimun value for the tuition cost slider */
  minTuitionCost = 0;
  /** Variable that holds the maximum value for the tution cost slider */
  maxTuitionCost = 80000;
  /** Variable that holds the value for the course type filter */
  courseType: string = null;
  /** Variable that holds the value for the Co-op filter */
  coOp = false;
  /** Variable that holds the current ordering criteria
   * 0 --> yearTuition asce
   * 1 --> yearTuition desc
   * 2 --> name A-Z
   * 3 --> name Z-A
   */
  orderCriteria = 0;
  /** Variable that holds the number of documents that are displayed in the page */
  programsPerPage = 10;

  // Declare height and width variables
  scrWidth: any;

  isBrowser: boolean;
  /** Configuration for the dropdown settings of lists that are configured as constants */
  readonly settingsA: IDropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'text',
    itemsShowLimit: 1,
  };

  /** Dropdown that holds the list of options for the discipline filter */
  disciplineDropdownList = [
    {
      value: 'Arts, Design and Architecture',
      name: 'Home.arts-architecture',
      text: 'Arte, diseño y arquitectura',
    },
    {
      value: 'Business and Management',
      name: 'Home.business',
      text: 'Negocios y Administración',
    },
    {
      value: 'Computer Sciences and IT',
      name: 'Home.computer-science',
      text: 'Ciencias de la Computación y Tecnología',
    },
    {
      value: 'Engineering',
      name: 'Home.engineering',
      text: 'Ingeniería',
    },
    {
      value: 'Sciences and Mathematics',
      name: 'Home.sciences-math',
      text: 'Ciencias y Matemáticas',
    },
    {
      value: 'Social Sciences and Humanities',
      name: 'Home.social-sciences',
      text: 'Ciencias Sociales y Humanidades',
    },
    {
      value: 'Education and Sport',
      name: 'Home.education',
      text: 'Educación ó Deporte',
    },
    {
      value: 'Community Services',
      name: 'Home.community',
      text: 'Servicios Comunitarios',
    },
    {
      value: 'Environmental Studies and Earth Sciences',
      name: 'Home.env-studies',
      text: 'Estudios Ambientales y Ciencias de la Tierra',
    },
    {
      value: 'Health',
      name: 'Home.health',
      text: 'Salud',
    },
    {
      value: 'Journalism and Media',
      name: 'Home.journalism',
      text: 'Periodismo y Medios de Comunicación',
    },
    {
      value: 'Law and Politic Sciences',
      name: 'Home.law-politics',
      text: 'Derecho y Ciencias Políticas',
    },
    {
      value: 'Languages',
      name: 'Home.languages',
      text: 'Idiomas',
    },
    {
      value: 'Tourism and Hospitality',
      name: 'Home.tourism-hospitality',
      text: 'Turismo y Hospitalidad',
    },
  ];

  /**
   * Variable that holds the list of options for the accreditation filter
   */
  accreditationDropdownList = [
    {
      value: 'Language - Courses & Pathways',
      name: 'Home.Language-Courses-Pathways',
      text: 'Idioma - Cursos y Conexiones',
    },
    {
      value: 'Undergraduate - Preparation & Pathways',
      name: 'Home.Undergraduate-Preparation-Pathways',
      text: 'Pregrado - Preparación y Conexiones',
    },
    {
      value: 'Undergraduate - Certificates & Diplomas',
      name: 'Home.Undergraduate-Certificates-Diplomas',
      text: 'Pregrado - Certificados y Diplomas',
    },
    {
      value: `Undergraduate - Bachelor`,
      name: 'Home.Undergraduate-Bachellors-Degree',
      text: 'Pregrado - Bachellor',
    },
    {
      value: 'Postgraduate - Preparation & Pathways',
      name: 'Home.Postgraduate-Preparation-Pathways',
      text: 'Postgrado - Preparación y Conexiones',
    },
    {
      value: 'Postgraduate - Certificates & Diplomas',
      name: 'Home.Postgraduate-Certificates-Diplomas',
      text: 'Postgrado - Certificados y Diplomas',
    },
    {
      value: 'Postgraduate - Master`s Degree and Doctorates',
      name: 'Home.Postgraduate-Master',
      text: 'Postgrado - Másteres y Doctorados',
    },
  ];

  universitiesLogosList = Array(14)
    .fill(1)
    .map((value, index) => `assets/home/logos/universities/logo-${index + 1}.png`);

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private seo: SeoService,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.addPrograms();
    this.getTestimonials();
    this.getBlogs();
    this.setSEO();
    this.getConfig();
    this.contactRequestForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      motivo: ['', [Validators.required]],
    });
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        this.loggedIn = logInStatus;
      });
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    }
  }

  ngOnDestroy(): void {
    if (this.logInSubscription !== null && this.logInSubscription !== undefined) {
      this.logInSubscription.unsubscribe();
    }
  }

  /** Set the SEO information with SEO Service */
  setSEO(): void {
    this.seo.setSEO({
      title: 'U360',
      description:
        'Study anywhere in the world using U360. Apply and study in the university of your dreams and get your desired accreditation. The education of your dreams may be only a few clicks away.',
      image: '/assets/home/logos/white-logo.png',
      keywords: 'study abroad, universities, apply and study',
      main: true,
    });
  }

  /** Changes the tab that is currently selected */
  onChangeSelectedTab({ tabNumber }): void {
    this.selectedTab = tabNumber;
  }

  /** Retrieves the list of testimonies from the backend */
  getTestimonials(): void {
    this.apiService.get({ api: this.TESTIMONIALS_API }).subscribe(
      (response) => {
        this.slidesData = response;
        this.slidesData.forEach((element) => {
          if (element.universidad?.linkVideo) {
            element.universidad.linkVideo = this.domSanitizer.bypassSecurityTrustResourceUrl(
              element.universidad.linkVideo
            );
          }
        });
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Home.there-was-an-error'),
        });
      }
    );
  }

  /** Extrae los programas existentes para mostrarlos en menu desplegable */
  addPrograms(): void {
    this.apiService.get({ api: this.TESTIMONIALS_API }).subscribe((response) => {});
  }

  /** Changes the slide that is currently selected in the testimonials section
   * @param index new slide number
   */
  onChangeSelectedSlide({ index }: { index: number }): void {
    this.selectedSlide = index;
  }

  /** Handles the click on the search based on my profile button */
  onProfileBasedSearchClick(): void {
    this.navigationService.navigateTo({ path: 'visa-thermometer' });
  }
  /**

   * Handles the submit click on the request contact form
   */

  onSubmitRequestForm(): void {
    const contactData = this.contactRequestForm.value;
    contactData.email.trim();
    this.apiService.post({ api: this.CONTACT_API, data: contactData }).subscribe(
      (response) => {
        if (response.success === true) {
          this.contactRequestForm.get('nombre').setValue('');
          this.contactRequestForm.get('email').setValue('');
          this.contactRequestForm.get('telefono').setValue('');
          this.contactRequestForm.get('motivo').setValue('');
          this.alertService.showSuccess({
            msg: this.translate.instant('Home.our-team-has-received'),
          });
        } else {
          this.alertService.showError({
            msg: this.translate.instant('Home.error-alert'),
          });
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Home.error-alert'),
        });
      }
    );
  }

  /**
   * Handle the unselection of all elements in the dropdown filters
   * @param items deselect all event
   * @filter filter name
   */
  onDeSelectAll(items: any, filter: string): void {
    //To change the filter state
    switch (filter) {
      case 'discipline':
        this.discipline = [];
        break;
      case 'university':
        this.university = [];
        break;
      case 'accreditation':
        this.accreditation = [];
        break;
      case 'city':
        this.city = [];
        break;
      case 'region':
        this.region = [];
        break;
    }
  }

  /**
   * Variable that retrieves the blogs data from the backend
   */
  getBlogs(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.BLOG_API + 'limit' }).subscribe(
      (response) => {
        this.blogs = response;
        this.blogs.forEach((element) => {
          if (element.video) {
            element.video = this.domSanitizer.bypassSecurityTrustResourceUrl(element.video);
          }
        });
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Blog.error-load'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Variable that retrieves the config data from the backend
   */
  getConfig(): void {
    this.apiService.get({ api: this.CONFIG_API }).subscribe(
      (response) => {
        //
        this.config = response[0];
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Blog.error-load'),
        });
      }
    );
  }

  /**
   * Handles the apply filters click event
   * @param page page;
   */
  onSubmitSearch({ page }: { page: number }): void {
    const regionParam = this.region.map((r) => r._id);
    const cityParam = this.city.map((c) => c._id);
    const countryParam = this.country.map((c) => c._id);
    const universityParam = this.university.map((m) => m._id);
    const accreditationParam = this.accreditation.map((a) => a.value);
    //
    const disciplineParam = this.discipline.map((d) => d.value);
    //
    const startsParam = this.startTime.map((s) => s.value);
    const coOp = this.coOp;
    this.name = this.name.trim();
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        region: regionParam,
        city: cityParam,
        discipline: disciplineParam,
        acreditacion: accreditationParam,
        pais: countryParam,
        universidad: universityParam,
        starts: startsParam,
        minTuition: this.minTuitionCost,
        maxTuition: this.maxTuitionCost,
        order: this.orderCriteria,
        page,
        coOp,
        name: this.name,
        limit: this.programsPerPage,
      },
    });
  }

  /** Clears the current values for all filters */
  onClearFilters(): void {
    this.country = [];
    this.region = [];
    this.city = [];
    this.university = [];
    this.accreditation = [];
    this.discipline = [];
    this.startTime = [];
    this.courseType = null;
    this.minTuitionCost = 0;
    this.maxTuitionCost = 80000;
    this.coOp = false;
    this.name = '';
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        highDemand: 'false',
      },
    });
  }
  /**Navigate to Universities section */
  navigateUniversitiesSection() {
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        state: 'Universities_active',
      },
    });
  }
}
