import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-unlink-account',
  templateUrl: './unlink-account.component.html',
  styleUrls: ['./unlink-account.component.scss']
})
export class UnlinkAccountComponent implements OnInit {

  private readonly API_FORM = 'api/formulario';
  private readonly API_USER_BY_EMAIL = 'api/user/email';

  /* Child References */
  createForm: FormGroup = this.fb.group({
    correo: ['', [Validators.required, Validators.email]],
    pregunta: ["", [Validators.required]]
  });

  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,) { }

  ngOnInit(): void {
  }

  async onSubmit( { event }) {
    if (this.createForm.valid) {
      if (await this.getUserByEmail({ email: this.createForm.controls.correo.value })) {
        const form = {
          nombre: "ND",
          apellido: "ND",
          correo: this.createForm.controls.correo.value,
          anioInteres: "ND",
          edad: "ND",
          telefono: "ND",
          programa: "ND",
          pregunta: this.createForm.controls.pregunta.value,
          fuente: "Desvincular cuenta: Facebook",
        };
        this.createFormByContact({ form });
      } else {
        this.alertService.showError({
          msg: `El usuario no se encuentra vinculado con Facebook o no existe`,
        });
      }
    } else {
      this.alertService.showError({
        msg: `Llena los campos correctamente`,
      });
    }
  }

  /**
   * create a form
   * @param form
   */
  public async getUserByEmail({ email }) {
    let resp = await this.apiService
      .get({ api: `${this.API_USER_BY_EMAIL}/${email}` })
      .toPromise();
      if (resp) {
        return resp.tipoRedSocial && resp.tipoRedSocial === "Facebook";
      }else {
        return false;
      }
  }

  /**
   * create a form
   * @param form
   */
  public async createFormByContact({ form }) {
    let resp = await this.apiService
      .post({ api: `${this.API_FORM}`, data: form })
      .toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Formulario creado con éxito`,
      });
    }
  }

  /**
   * Validate that the fields are correct
   * @param field field to validate
   */
  public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched
    );
  }

}
