<div class="free-guide-detail">
  <div class="free-guide-detail-container">
    <button class="go-back-button" (click)="goBack()"><img class="flecha-img" src="/assets/home/images/flecha.png" alt="Flecha volver"> Volver</button>
    <h1 class="guide-title">{{ freeGuide.tituloReducido }}</h1>
    <div class="guide-info-container">
      <img class="guide-image" [src]="freeGuide.imagen" [alt]="freeGuide.tituloReducido">
      <div class="guide-info">
        <h2 class="guide-full-title">{{ freeGuide.tituloCompleto }}</h2>
        <p class="guide-description" [innerHTML]="freeGuide.descripcion"></p>
        <button class="guide-download" (click)="goToDownload()">Descargar</button>
      </div>
    </div>
  </div>
</div>