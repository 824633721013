<div class="wrapper">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Selecciona tu tipo de institución favorita.</h4>
      <p>Elige entre las siguientes opciones el tipo de universidad en la que te interesaría aplicar.</p>
      <hr class="hrLine" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3" *ngFor="let item of institutions; index as i">
          <div class="news-card">
            <div class="card-body pad0">
              <div class="headerSection">
                <label class="universityTitle">{{ item.institutionOptionName }}</label>
                <label class="universityDescription">¿Qué es {{ item.institutionOptionName }}?</label>
                <em
                  class="fas fa-solid fa-plus floatR"
                  id="{{ i }}"
                  (click)="openShowMoreComponent(item)"
                  [ngClass]="{
                    'fa-minus': item.showDetails && selectedInstitution === item,
                    'fa-plus': !item.showDetails || selectedInstitution !== item
                  }">
                  <span class="showMessage">
                    {{ item.showDetails && selectedInstitution === item ? 'Ver menos' : 'Ver más' }}
                  </span>
                </em>
              </div>
              <div *ngIf="item.video">
                <iframe [src]="getSafeVideoUrl(item.video)" frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
            <div class="news-card-footer">
              <label>
                <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleInstitution(item)" />
                Me interesa
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedInstitution">
        <div class="col-12">
          <div class="card-content" [hidden]="showDiv == false">
            <div class="first-content row">
              <div *ngIf="selectedInstitution.video" class="detalle-video col-lg-6" id="detalle-video">
                <iframe
                  width="560"
                  height="315"
                  [src]="getSafeVideoUrl(selectedInstitution.video)"
                  frameborder="0"
                  allowfullscreen></iframe>
              </div>
              <div class="card cardinfo col-lg-6" id="detalle-informacion">
                <h5 class="skillName">{{ selectedInstitution.institutionOptionName }}</h5>
                <p>{{ selectedInstitution.description }}</p>
              </div>
              <div class="card cardinfo" id="detalle-requisitos">
                <h5 class="skillName">Requisitos generales admisión:</h5>
                <ul *ngFor="let item of selectedInstitution.requirements">
                  <li>
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn-continuar" (click)="onSubmit()">Guardar</button>
    <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
  </div>
</div>
