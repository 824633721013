<div class="application-fee-payment">
  <img class="back-button" src="assets/utils/back.png" (click)="onBackClick()" alt="go back">
  <h3> {{'Application-fee-payment.pay-fee' | translate}} </h3>
  <div class="application-fee-overview">
    <div class="application-fee-overview-header">
      {{'Application-fee-payment.overview' | translate}}
    </div>
    <div class="application-fee-overview-body">
      {{'Application-fee-payment.program' | translate}} {{payment.programName}} <br>
      {{'Application-fee-payment.university' | translate}} {{payment.university}} <br>
      {{'Application-fee-payment.price' | translate}} {{payment.applicationFee}} {{payment.currency}} <br>
      Total: {{payment.total | number : '1.2-2'}} USD
    </div>
    <div class="application-fee-overview-footer">
      <button [disabled]="!(pageStatus === 0) || (payment.total <= 0)" (click)="onMakePaymentClick()" id="continue-button"> {{'Application-fee-payment.continue' | translate}} </button>
    </div>
  </div>
  <div class="application-fee-payment">
    <app-payment-info (startPurchase)="startPurchase($event)" (returnToPrevious)="onReturnFromCheckout()" *ngIf="pageStatus === 1"></app-payment-info>
  </div>
</div>
