<div class="row">
  <div class="col-12">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        [style.width]="progress"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div>
          <div class="row">
            <div class="col-12">
              <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
              <h5>Pregunta</h5>
              <p>{{ currentQuestion.question }}</p>
              <hr class="hrLine" />
            </div>
          </div>
          <div class="answersWrapper">
            <div class="row answersContainer">
              <div *ngFor="let answer of shuffledAnswers" class="element-card">
                <mat-radio-button class="answers" [value]="answer" (change)="selectedAnswer = answer">
                  {{ answer.answer }}
                </mat-radio-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center">
              <button
                class="btn-continuar"
                *ngIf="currentSkill.skillName !== 'OrientacionResultados' || currentQuestionIndex !== 5"
                (click)="nextQuestion()">
                Continuar
              </button>
              <button
                class="btn-continuar"
                *ngIf="currentSkill.skillName === 'OrientacionResultados' && currentQuestionIndex === 5"
                (click)="onSubmit()">
                Finalizar
              </button>
              <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
