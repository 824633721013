<div class="alerts-container">
  <div class="alerts-header">
    <h3 class="activity-title">{{'Alerts.Activity' | translate}}</h3>
    <div class="student-selector" *ngIf="isRecruitment">
      <select placeholder="Seleccionar estudiante" [(ngModel)]="student" id="studentSelect" name="studentSelect" #studentSelect="ngModel" (ngModelChange)="getAlertsRecruitment({id: $event})" class="student-select">
        <option value="0">{{ "Todos" }} </option>
        <option *ngFor="let s of recruitmentStudents" [value]="s._id" >{{s.nombre}} {{s.apellido}}</option>
      </select>
  </div>
    <mat-checkbox (ngModelChange)="onChangeFavouritesFilter({event: $event})" [(ngModel)]="favouritesFilter" class="radio-mat">{{"Alerts.show-only-new" | translate}}</mat-checkbox>
    <button id="create-ticket" (click)="onCreateTicketClick()">{{'Alerts.create-ticket' | translate}}</button>
  </div>
  <p class="messages-disclaimer">{{'Alerts.check-your-alerts-for' | translate}}</p>
  <div class="alert-tabs">
    <div class="inline-container">
      <button (click)="onChangeSelectedTab({newTab: 0})" class="tabs-button" > <mat-icon class="unread-messages-icon" *ngIf="unreadAlerts">info</mat-icon> {{'Alerts.alerts' | translate}}</button>
      <div [ngClass]="selectedTab === 0?'tab-selected':''"></div>
    </div>
    <div class="inline-container">
      <button (click)="onChangeSelectedTab({newTab: 1})" class="tabs-button"> <mat-icon class="unread-messages-icon" *ngIf="unreadMessages">info</mat-icon>{{'Alerts.messages' | translate}}</button>
      <div [ngClass]="selectedTab === 1?'tab-selected':''"></div>
    </div>
  </div>
  <div *ngIf="selectedTab === 0" class="alerts-subcontainer">
    <div class="sub-header-alerts">
      <p class="messages-disclaimer">{{'Alerts.no-reply' | translate}}</p>
    </div>
    <div class="row">
      <div *ngFor="let alert of alerts" class="col-md-12">
        <app-alert-card [alert]="alert"></app-alert-card>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 1" class="messages-subcontainer">
    <p class="messages-disclaimer">{{'Alerts.students-can-start'|translate}}</p>
    <div class="row">
      <div *ngFor="let ticket of tickets" class="col-md-12">
        <app-ticket-card [ticket]="ticket"></app-ticket-card>
      </div>
    </div>
  </div>
</div>
