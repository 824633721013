import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { StudentDocumentsComponent } from '../student-documents/student-documents.component';

@Component({
  selector: 'app-student-resume',
  templateUrl: './student-resume.component.html',
  styleUrls: ['./student-resume.component.scss']
})
export class StudentResumeComponent implements OnInit {
  /**Api to get student curriculum */
  readonly GET_CURRICULUM="api/hoja-vida/getById/"

  /**Api to get IA skills test (internationalitation) from student*/
  readonly GET_TEST ='api/test_internalizacionUser/'

  /**Api to get cultural match result from student */
  readonly GET_CULTURAL_MATCH = 'api/respuesta-match/estudiante/'

  /** API to get the favourites from student */
  readonly GET_FAVOURITES = 'api/user/favoritos/';

  /** Api to get the applications of an student */
  readonly GET_APPLICATIONS = 'api/aplicacion/estudiante/';

  studentId:any;
  studentName:any;
  profilePhoto:any;
  insignia:any;
  grade:any;
  age:any;
  references=[];
  areas=[]

  /**Variable that contains data from student IA skills test */
  dataIATest:any;
  hojaTest:any;
  generalScore=0;

  /**Variable that contains data from student cultural match */
  dataCulturalMatch=[];
  countryCulturalMatch=[];

  /**Variable that contains student program favourites  */
  favourites=[];
  dataTableFav:any;
  /**Colums to wishlist table */
  displayedColumns: string[] = ['icono', 'programaUniversidad', 'afinidad', 'probabilidad'];

  /**Variable that contains applications from student */
  applications=[]

  sortsChart:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private apiService: ApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {

      this.studentId=params.get("userId");
      this.studentName=params.get("nombre");
      this.insignia=params.get("insignia");
      this.profilePhoto=params.get("fotoPerfil");
      this.grade=params.get("grado");

      //GET ALL DATA FOR VIEW
      this.getCurriculum({studentId:this.studentId});
      this.getIaTest({studentId:this.studentId});
      this.getCulturalMatchTest({studentId:this.studentId});
    })
  }

  backToStudentList(){
    this.navigationService.navigateTo({path:"studentsList"});
  }

  getCurriculum({studentId}){
    this.apiService.get({api:this.GET_CURRICULUM+studentId}).subscribe(
      (response)=>{
        if(response){
          if(response.referencias && response.referencias.length>0){
            this.references = response.referencias
          }
          if(response.areaInteres && response.areaInteres.length>0){
            for (let area of response.areaInteres) {
              if(area.nombre=="Negocios"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/economia.png"
                })
              }else if(area.nombre=="Ciencias de la computación e informática"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/desarrollo.png"
                })
              }
              else if(area.nombre=="Ingenierías"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/piece.png"
                })
              }
              else if(area.nombre=="Artes, diseño y arquitectura"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/idiomas-rosado.png"
                })
              }
              else if(area.nombre=="Ciencias y matematicas"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/ciencia.png"
                })
              }
              else if(area.nombre=="Servicios comunitarios"){
                this.areas.push({
                  nombre:area.nombre,
                  imagen:"/assets/curriculum/salud.png"
                })
              }
            }
          }
        }
      }
    )
  }

  getIaTest({studentId}){
    this.apiService.get({api:this.GET_TEST+studentId}).subscribe(
      (response)=>{

        if(response){
          this.dataIATest=response;

          this.hojaTest=this.dataIATest.hojaTest
          this.age=this.dataIATest.edad;
          this.generalScore=this.dataIATest.puntaje_general
          this.dataIATest.liderazgo = parseFloat(this.dataIATest.liderazgo),
          this.dataIATest.gobal_mind = parseFloat(this.dataIATest.gobal_mind),
          this.dataIATest.logro_accion = parseFloat(this.dataIATest.logro_accion),
          this.dataIATest.impacto_social = parseFloat(this.dataIATest.impacto_social),
          this.dataIATest.competencia_academica = parseFloat(this.dataIATest.competencia_academica)
          this.sortsChart = {
            type: 'radar',
            data: {
              labels: [
                [`Liderazgo`,  `${Math.round(this.dataIATest.liderazgo)}%`],
                [`Global`, ` Mind`, `  ${Math.round(this.dataIATest.gobal_mind)}%`],
                [`Logro y`, ` acción`, `   ${Math.round(this.dataIATest.logro_accion)}%`],
                [`Impacto`, `social  `, `${Math.round(this.dataIATest.impacto_social)}%   `],
                [`Competencia`, `Académica  `, `${Math.round(this.dataIATest.competencia_academica)}%       `]
              ],
              datasets: [
                {
                  responsive: true,
                  label: 'Puntuación',
                  data: [
                    this.dataIATest.liderazgo,
                    this.dataIATest.gobal_mind,
                    this.dataIATest.logro_accion,
                    this.dataIATest.impacto_social,
                    this.dataIATest.competencia_academica
                  ],
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderColor: '#969FFB',
                  borderWidth: 0,
                  pointBackgroundColor: [
                    '#86388F',
                    '#E3B458',
                    '#5C9B9B',
                    '#DE748F',
                    '#3B61DD'
                  ]
                },
                {
                  label: 'Máximo',
                  data: [
                    100,
                    100,
                    100,
                    100,
                    100
                  ],
                  backgroundColor: 'rgba(176, 183, 255, 0.5)',
                  borderColor: '#969FFB',
                  pointRadius: 0,
                  borderWidth: 0,
                },
              ]
            },
            // plugins:[this.plugin],
            options: {
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                customCanvasBackgroundColor: {
                  color: '#B0B7FF',
                },
              },
              tooltips: {
                enabled: false
              },
              legend: {
                display: false,
              },
              scale: {
                gridLines: {
                  color: '#969FFB',
                  lineWidth: '1'
                },
                ticks: {
                  display: false,
                  min: 0,
                  max: 100,
                },
                pointLabels: {
                  fontColor: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F'],
                  fontSize: 8,
                  fontStyle: 'bold'
                }
              },
            },
          };
        };

        //Favoritos
        this.apiService.get({api:this.GET_FAVOURITES+studentId}).subscribe(
          (responseFav)=>{
            if(responseFav && responseFav.length>0){
              this.favourites=responseFav


              let formatData=[];
              for (const item of this.favourites) {
                let afinity;
                let probability;
                if( response && (response.puntaje_general!="0" && response.puntaje_general!=0)){
                  afinity=(Math.abs(response.puntaje_general - item.universidad.indiceCompetencias)-1).toFixed(0);
                  probability= (item.universidad?.indiceCompetencias * 100).toFixed(0)
                }else{
                  afinity="?"
                  probability="?"
                }
                let data = {
                  programa: item.nombre,
                  universidad:item.universidad.nombre,
                  afinidad:afinity,
                  probabilidad: probability
                }

                formatData.push(data);
              }
              this.dataTableFav=new MatTableDataSource(formatData);
            }
          }
        );

        this.getApplications({studentId:studentId,test:response})
      }
    )
  }

  getCulturalMatchTest({studentId}){
    this.apiService.get({api:this.GET_CULTURAL_MATCH+studentId}).subscribe(
      (response)=>{
        if(response){
          if(response.length>0){
            let lastMatch
            lastMatch=response[response.length-1]
            //

            for (const item of lastMatch.matches) {
              this.countryCulturalMatch.push(item.ciudad.pais.nombre);
            }
            this.countryCulturalMatch=[...new Set(this.countryCulturalMatch)];

          }
        }
      }
    )
  }


  getApplications({ studentId, test }) {
    this.apiService.get({ api: this.GET_APPLICATIONS + studentId }).subscribe(
      (response) => {
        if (response && response.length > 0) {
          // this.applications=response
          //

          const applicationsFormatted = [];

          const today = new Date();
          for (const application of response) {
            let daysLeft;
            if (
              application.fechaProximoPaso !== null &&
              application.fechaProximoPaso !== undefined
            ) {
              // Calculate the difference between the 2 dates
              const nextStepDate = new Date(application.fechaProximoPaso);
              const difference = nextStepDate.valueOf() - today.valueOf();
              // Convert milliseconds to hours
              daysLeft = Math.round(difference / (24 * 60 * 60 * 1000));
            }
            if (application.programa) {
              //
              let afinity;
              let probability;
              if (test && (test.puntaje_general!="0" && test.puntaje_general!=0)) {
                afinity = (Math.abs(test.puntaje_general - application.programa.universidad.indiceCompetencias) - 1).toFixed(2);
                probability=(application.programa.universidad?.indiceCompetencias*100)
              } else {
                afinity='?'
                probability='?'
              }

              //
              const app = {
                id: application._id,
                programName: application.programa.nombre,
                certification: application.programa.acreditacion,
                tuitionCost: application.programa.yearTuition || application.programa.programTuition || application.programa.semesterTution,
                applicationCost: application.programa.universidad?.applicationFee,
                university: application.programa.universidad?.nombre,
                universityLogo: application.programa.universidad?.logo,
                idCity: application.programa.universidad?.ciudad._id,
                city: application.programa.universidad?.ciudad.nombre,
                country: application.programa.universidad?.ciudad.pais.nombre,
                countryCurrency:
                  application.programa.universidad?.ciudad.pais.moneda,
                countryImg: application.programa.universidad?.ciudad.pais.icono,
                daysLeft,
                currentStep: application.pasoActual,
                afinity: afinity,
                probability: probability,
                slug: application.programa?.slug
              };
              applicationsFormatted.push(app);
            };
          };
          this.applications = applicationsFormatted
        }
      }
    )
  }

  goToCv(){
    this.navigationService.navigateTo({path:"studentProfile/"+this.studentId});
  };

  goToDashboard(){
    this.navigationService.navigateTo({path:"viewDashboardStudent/"+this.studentId});
  }

  goToDocuments(){
    this.dialog.open(StudentDocumentsComponent,{
      data:{
        student:this.studentId
      }
    })
  }
}
