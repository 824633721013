<div class="pse-response">
    <div class="pse-message" *ngIf="estadoTransaccion === 'PENDING'">
      <img class="pse-img" src="assets/home/logos/U-logo.png" alt="u360 logo">
      <p> {{"pse-response.we-are-processing" | translate}} </p>
      <button id="go-home-button" routerLink="../home" >{{"pse-response.go-home" | translate}}</button>
    </div>
    <div class="pse-message" *ngIf="estadoTransaccion === 'APPROVED' || estadoTransaccion === 'DECLINED'">
      <div class="contenedor">
        <button class="return" type="button" routerLink="../my-applications">< Volver</button>
        <div class="purchase-summary">
          <h1 class="title">Resumen compra</h1>
          <div class="purchase-summary-information">
            <h2 class="information-title"><b>Estado de la transaccion: {{ informacionEstado }}</b></h2>
            <p><b>Fecha:</b> {{ informacionFecha }}</p>
            <p><b>Nombre de quien paga:</b> {{ informacionNombre }}</p>
            <p><b>Medio de pago:</b> {{ metodoPago }} - {{ informacionBanco }}</p>
            <p><b>ID transaccion:</b> {{ informacionIDTransaccion }}</p>
            <p class="last-p"><b>Detalle de pago:</b> {{ descripcionPago }}</p>
            <button id="go-home-button" type="button" routerLink="../home" class="return-home">Ir al home</button>
          </div>
        </div>
      </div>
    </div>
    <div class="pse-message medio" *ngIf="estadoTransaccion === 'LOADING'">
      <div>CARGANDO...</div>
    </div>
</div>
