import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent implements OnInit {

  /** CONSTANTS */
  readonly ALERT_API = 'api/alerta/'

  /** VARIABLES */
  /** Input that contains the alert */
  @Input() alert: any;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  /** Adds the alert to the benchmark */
  onAddToBenchmark(): void {
    const data = {
      destacado: true
    };
    this.apiService.put({api: this.ALERT_API + this.alert._id, data}).subscribe((response) => {
      this.alert.destacado = true;
      this.alertService.showSuccess({msg: 'Added to benchmarks'});
    }, err => {
      this.alertService.showError({msg: 'There was an error, please try again later or contact support'});
    });
  }
  /** Removes the alert from the benchmark section*/
  onRemoveFromBenchMark(): void {
    const data = {
      destacado: false
    };
    this.apiService.put({api: this.ALERT_API + this.alert._id, data}).subscribe((response)=> {
      this.alert.destacado = false;
      this.alertService.showSuccess({msg: 'Removed from benchmarks'});
    }, err => {
      this.alertService.showError({msg: 'There was an error, please try again later or contact support'});
    });
  }

  /**
   * Method that displays the complete information of the alert on a material dialog
   */
  onDisplayFullAlert(): void {
    this.dialog.open(AlertModalComponent, {data: {
      id: this.alert._id,
      message: this.alert.mensaje,
      spanishMessage: this.alert.mensajeEspaniol,
      application: this.alert.aplicacion,
      read: this.alert.leido,
    }});
  }


}
