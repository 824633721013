import { NavigationService } from './../../services/navigation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-match',
  templateUrl: './main-match.component.html',
  styleUrls: ['./main-match.component.scss']
})
export class MainMatchComponent implements OnInit {

  /** CONSTANTS */

  /** Variable that holds the data that is displayed in the dialog */
  matchData = {
    countryImage: '',
    countryName: '',
    cityName: '',
    cityId: '',
    userId: '',
  };

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private navigationService: NavigationService,
              private dialogRef: MatDialogRef<MainMatchComponent>) { }

  ngOnInit(): void {
    this.matchData.cityName = this.data.match.ciudad.nombre;
    this.matchData.countryName = this.data.match.ciudad.pais.nombre;
    this.matchData.cityId = this.data.match.ciudad._id;
    this.matchData.countryImage = this.data.match.ciudad.pais.icono;
    this.matchData.userId = this.data.estudiante;
  }

  /** redirects the user to the search view of the city in the match */
  onViewProgramsClick(): void {
    this.navigationService.navigateTo({path: 'program/search', params: {
      city: this.matchData.cityId
    }});
    this.dialogRef.close();
  }
  /** Redirects the user to the component that show his cultural matches */
  onViewOtherOptions(): void {
    this.navigationService.navigateTo({path: 'cultural-match'});
    this.dialogRef.close();
  }

}
