import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-page-four',
  templateUrl: './pageFour.component.html',
  styleUrls: ['./pageFour.component.scss'],
})
export class PageFourComponent {
  @Input() form: FormGroup;

  academicRating = [
    { value: 'excellent', label: 'Superior ó Excelente (Rangos de  4.6 a  5.0   ó  9.0 a 10.0)' },
    { value: 'very_good', label: 'Alto ó Muy Bueno:  (Rangos  de 4.1 a  4.5   ó    8.0 a 8.9)' },
    { value: 'good', label: 'Básico  ó Bueno:    (Rangos de  3.6 a 4.0   ó   7.0  a 7.9)' },
    { value: 'acceptable', label: 'Aceptable o Satisfactorio.:   (Rangos de  3.0 a  3.5   ó 	6 a 6.9)' },
    { value: 'regular', label: 'Bajo ó Regular:.   (Rangos de  2.0. a 2.9   ó 	5 a 5.9)' },
    { value: 'insufficient', label: 'Muy Bajo ó Insuficiente:    (Rangos de  1.0 a  1.9    ó 	0.0 a 4.9)' },
    { value: 'not_available', label: 'No las tengo disponibles' },
  ];

  englishLevels = [
    { label: 'Principiante (A1),', value: 'A1' },
    { label: 'Básico ó Elemental (A2)', value: 'A2' },
    { label: 'Intermedio (B1)', value: 'B1' },
    { label: 'Intermedio Alto (B2)', value: 'B2' },
    { label: 'Alto (C1)', value: 'C1' },
    { label: 'Maestría (C2),', value: 'C2' },
  ];
}
