import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-register-student',
  templateUrl: './register-student.component.html',
  styleUrls: ['./register-student.component.scss']
})
export class RegisterStudentComponent implements OnInit {

  /** Constant that holds the API route for register */
  readonly REGISTER_API = 'users/registrar-estudiante';

  /** Variable that holds the register student form */
  registerForm: FormGroup;

  /** Variable that signals if the button is loading */
  loading = false;

  constructor(private fb: FormBuilder,
              private apiService: ApiService,
              private alertService: AlertService,
              private dialogRef: MatDialogRef<RegisterStudentComponent>) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      telefono: ['', Validators.required],
      procedenciaComoLead: '',
    });
  }

  /**
   * Returns the email form control
   */
   get email(): AbstractControl {
    return this.registerForm.get('email');
  }
  /**
   * Returns the password form control
   */
  get password(): AbstractControl {
    return this.registerForm.get('password');
  }
  /**
   * Returns the name form control
   */
  get name(): AbstractControl {
    return this.registerForm.get('nombre');
  }
  /**
   * Returns the form control for last name
   */
  get lastName(): AbstractControl {
    return this.registerForm.get('apellido');
  }

  /**
   * Returns the abstract control for telephone
   */
  get telephone(): AbstractControl {
    return this.registerForm.get('telefono');
  }

  /**
   * Returns the abstract control for precedence as lead
   */
  get precedenceAsLead(): AbstractControl {
    return this.registerForm.get('procedenciaComoLead');
  }

  /**
   * Handles the register action of a student
   */
  onRegister(): void {
    const user = this.registerForm.value;
    user['comercial'] = JSON.parse(localStorage.getItem('user'))._id;
    user['tipo'] = 'Estudiante';
    this.loading = true;
    this.apiService.post({api: this.REGISTER_API, data: user}).subscribe((response) => {
      if (response.success === true) {
        this.alertService.showSuccess({msg: 'Student registered succesfully'});
        this.dialogRef.close();
      } else {
        this.alertService.showError({msg: response.msg});
      }
    }, err => {
      this.loading = false;
      this.alertService.showError({msg: 'There was an error registering the student, please try again later or contact support'});
    }, (complete?) => {
      this.loading = false;
    })
  }

}
