import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit {
  /**Boolean for correct verification */
  public isVerified = false;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.tokenVerification();
  }

  /**
   * Retrieves token from url and verifies email
   */
  public tokenVerification() {
    let token: any;
    let email: any;
    this.route.paramMap.subscribe((params) => {
      token = params.get('token');
      email = params.get('email');
    });
    this.apiService
      .get({ api: `api/verification-token/token/${token}` })
      .subscribe(
        (res) => {
          if (res._id) {
            if (res.email === email) {
              this.apiService
                .put({
                  api: `api/user/email/${email}`,
                  data: { confirmado: true },
                })
                .subscribe((res) => {
                  this.isVerified = true;
                  setTimeout(()=>{
                    this.navigationService.navigateTo({
                      path: 'login',
                    });
                  }, 2000);
                });
            }
          }
        },
        (err) => {
           
        }
      );
  }
}
