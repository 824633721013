import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { skillsArray } from '../../../../skillsObject/skills.js';

@Component({
  selector: 'app-skills-details',
  templateUrl: './skillsDetails.component.html',
  styleUrls: ['../testDashboard.component.scss'],
})
export class SkillDetailsComponent implements OnInit {
  public userId = JSON.parse(localStorage.getItem('user'));
  readonly getData = 'api/skillsTest/';
  description: string;
  desiredScore: number;
  skillScore: number;
  proficiencyLevel: string;
  proficiencyDescription: string;
  contentCompetition: any = {};
  skillsTest: any;
  skillsScore: any;
  skillName: string;
  route: any;
  actualPath: any;
  skillsArray = skillsArray;
  skillID: any;
  selectedSkill: any;
  score: any;
  dynamicDescription: any;
  questions: any;
  filteredQuestions: any[] = [];
  desirableScore: any;
  selectedOptionUniversity: any;
  universityType = {
    'Top Tier': {
      Liderazgo: 85,
      GlobalMind: 90,
      ImpactoResponsabilidad: 85,
      OrientacionResultados: 95,
      AprendizajePermanente: 95,
      Average: 90,
    },
    'Experiential University': {
      Liderazgo: 70,
      GlobalMind: 65,
      ImpactoResponsabilidad: 50,
      OrientacionResultados: 65,
      AprendizajePermanente: 70,
      Average: 64,
    },
    'Practical Learning': {
      Liderazgo: 50,
      GlobalMind: 50,
      ImpactoResponsabilidad: 30,
      OrientacionResultados: 65,
      AprendizajePermanente: 60,
      Average: 51,
    },
    'Vocational / Passion': {
      Liderazgo: 70,
      GlobalMind: 65,
      ImpactoResponsabilidad: 65,
      OrientacionResultados: 75,
      AprendizajePermanente: 70,
      Average: 69,
    },
  };

  constructor(private apiService: ApiService, private router: Router, private location: Location) {}

  ngOnInit(): void {
    this.route = this.location.path();

    const segments = this.route.split('/');

    const encodedDetails = segments[segments.length - 1];
    const skillName = segments[segments.length - 2];

    const detailsArray = encodedDetails.split(';');

    const detailsObject: any = {};
    detailsArray.forEach((detail) => {
      const [key, value] = detail.split('=');
      detailsObject[key] = decodeURIComponent(value);
    });

    this.skillName = detailsObject.name;
    this.description = detailsObject.description;
    this.desiredScore = parseFloat(detailsObject.desiredScore);
    this.skillScore = parseFloat(detailsObject.skillScore);
    this.proficiencyLevel = detailsObject.proficiencyLevel;
    this.proficiencyDescription = detailsObject.proficiencyDescription;
    this.getSkillsTest({ userId: this.userId._id, skillName: detailsArray[0] });
    this.showCompetitionInformation({ skillName: detailsArray[0] });
  }

  getSkillsTest({ userId, skillName }) {
    this.apiService.get({ api: this.getData + userId }).subscribe((response) => {
      this.skillsTest = response;

      this.skillsScore = response.skillPoints;
      this.questions = response.questions;

      this.score = this.skillsScore[this.skillName];

      this.selectedOptionUniversity = response.selectedOptionUniversity;
      let dynamicDescription = '';
      if (
        this.universityType[this.selectedOptionUniversity] &&
        this.universityType[this.selectedOptionUniversity][this.skillName]
      ) {
        this.desirableScore = this.universityType[this.selectedOptionUniversity][this.skillName];
      } else {
        this.desirableScore = 0;
      }

      switch (true) {
        case this.score >= 66.01:
          dynamicDescription = this.skillsArray.find((item) => item.id === skillName)?.descriptions.alto || '';
          break;
        case this.score >= 33.01:
          dynamicDescription = this.skillsArray.find((item) => item.id === skillName)?.descriptions.medio || '';
          break;
        default:
          dynamicDescription = this.skillsArray.find((item) => item.id === skillName)?.descriptions.bajo || '';
      }

      this.contentCompetition.dynamicDescription = dynamicDescription;
      this.filteredQuestions = [];
      for (const questionKey in this.questions) {
        if (this.questions.hasOwnProperty(questionKey)) {
          const question = this.questions[questionKey];
          if (question.skill === this.skillName) {
            this.filteredQuestions.push(question);
          }
        }
      }
    });
  }

  returnDashBoard() {
    this.router.navigate(['/skill-dashboard']);
  }

  showCompetitionInformation({ skillName }) {
    this.selectedSkill = this.skillsArray.find((skill) => skill.id === skillName);
    if (this.selectedSkill) {
      this.contentCompetition = {
        title: this.selectedSkill.name,
        description: this.selectedSkill.descriptions.general,
        suggestions: this.skillsTest,
      };
    }
  }
}
