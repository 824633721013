<div class="modalContainer">
  <div class="modal-header customHeader">
    <h4 class="modalTitle">Requisitos de la experiencia</h4>
  </div>
  <div class="modal-body customBody">
    <p>
      <span class="sectionTitle">Nombre de la experiencia:</span>
      {{ experienceData.experienceName }}
    </p>
    <p>
      <span class="sectionTitle">Competencias Afines:</span>
    </p>
    <ul>
      <li *ngFor="let skill of experienceData.requiredSkills">{{ skill }}</li>
    </ul>
    <p *ngIf="experienceData.skillsScore">
      <span class="sectionTitle">Puntaje porcentual otorgado:</span>
      {{ experienceData.skillsScore }}
    </p>
    <p>
      <span class="sectionTitle">Horas requeridas:</span>
      {{ experienceData.totalHours.range1 }} - {{ experienceData.totalHours.range2 }} horas requeridas
    </p>
    <p>
      <span class="sectionTitle">Documentos requeridos:</span>
    </p>

    <ul>
      <li *ngFor="let document of documentsWithData">{{ document.document }}: {{ document.documentType }}</li>
    </ul>
    <p>
      <span class="sectionTitle">Requisitos para aprobar:</span>
      <li *ngFor="let requirement of experienceData.requirements">{{ requirement }}</li>
    </p>
    <span class="documentsRequirementMessage">
      Debes cargar la documentación de la manera más completa posible, que permita a un tercero verificar la veracidad
      de la información. En caso de no tenerla, abstente de registrar la actividad como ejecutada.
    </span>
  </div>
</div>
