<div class="ticket-card">
  <div class="ticket-card-header">
    <div class="ticket-date">
      {{ticket.createdAt | date}}
    </div>
  </div>
  <div (click)="onGoToDetail()" class="ticket-card-content">
    <div class="row">
      <div class="col-sm-1 col-md-1 col-lg-1">
        <div class="outer-frame">
          <div class="frame">
            <img class="ticket-image" src="assets/notifications/ticket.png" alt="ticket">
          </div>
        </div>
      </div>
      <div class="col-sm-1 col-md-1 col-lg-11 ticket-card-content">
        <h3 class="ticket-title"> <mat-icon *ngIf="ticket.ultimoMensajeLeidoPorEstudiante === false" class="not-read-message-icon">info</mat-icon> {{ticket.aplicacion.programa.nombre}} </h3>
        <div class="ticket-program">
          {{'Alerts.title' | translate}}: {{ticket.titulo}}
        </div>
        <div class="ticket-program">
          {{'Alerts.priority' | translate}}: {{ticket.prioridad}}
        </div>
        <div class="ticket-message-preview">
          {{ticket.razon | ellipsis:95}}
        </div>
      </div>
    </div>
  </div>
</div>
