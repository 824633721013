import { MainMatchComponent } from './main-match/main-match.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from './../services/api.service';
import { AlertService } from './../services/alert.service';
import { NavigationService } from './../services/navigation.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthComponent } from '../auth/auth.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-cultural',
  templateUrl: './item-cultural.component.html',
  styleUrls: ['./item-cultural.component.scss'],
})
export class ItemCulturalComponent implements OnInit, OnDestroy {
  /** CONSTANTS */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api for getting  the students by recruitment */
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** Constant that holds the api for cultural item related operations */
  readonly CULTURAL_API = 'api/item-cultural/';
  /** Constant that holds the api for submitting test response */
  readonly CULTURAL_RESPONSES_API = 'api/respuesta-match';

  /** VARIABLES */
  /** Variable that holds the list of questions */
  questions: any[] = [];
  /** Variable that determines if the user is logged in */
  loggedIn = false;
  /** Selected student if is recruitment advisor */
  student;
  /** Current recruiter students */
  students;
  /** If is a recruitment partner */
  isRecruitment = false;

  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;

  constructor(
    private location: Location,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private apiService: ApiService,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.navigationService.setIsVisibleSidebar(false);
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        this.loggedIn = logInStatus;
      });
    }
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.tipo === this.TIPO_RECRUITMENT) {
      this.getRecruitmentStudents({ id: user._id }).subscribe((response) => {
        this.isRecruitment = true;
        this.students = response as any[];
      });
    } else {
      this.isRecruitment = false;
    }
    this.getQuestions();
  }

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
    if (this.logInSubscription !== null && this.logInSubscription !== undefined) {
      this.logInSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves recruitment partner students
   * @returns Observable with response from backend
   */
  getRecruitmentStudents({ id }): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + id });
  }

  /** retrieves the cultural match questions from the backend */
  getQuestions(): void {
    this.apiService.get({ api: this.CULTURAL_API }).subscribe(
      (response) => {
        const questions = [];
        for (const question of response) {
          question.selected = false;
          questions.push(question);
        }
        this.questions = questions;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Item-cultural.error-load'),
        });
      }
    );
  }

  /**
   * Handles the click on the go back button
   */
  onBackClick(): void {
    this.location.back();
  }

  /** Handles the selection of one of the questions */
  onSelectClick({ index }: { index: number }): void {
    this.questions[index].selected = !this.questions[index].selected;
  }

  /** Handles the click on the clear selections button */
  onClearSelections(): void {
    for (const question of this.questions) {
      question.selected = false;
    }
  }
  /** Handles the click on the submit test button */
  onSubmitTest(): void {

    if (this.loggedIn === false) {
      this.authService.sitioMensaje = '';
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      const answers = [];
      for (const question of this.questions) {
        let yesOrNo = 'No';
        if (question.selected) {
          yesOrNo = 'Si';
        }
        const answer = {
          item: question._id,
          respuesta: yesOrNo,
        };
        answers.push(answer);
      }


      const user = JSON.parse(localStorage.getItem('user'));
      let userId = '';
      if (user.tipo === this.TIPO_RECRUITMENT) {
        userId = this.student;
      } else {
        userId = user._id;
      }
      const data = {
        estudiante: userId,
        itemsCulturales: answers,
      };


      this.apiService.post({ api: this.CULTURAL_RESPONSES_API, data }).subscribe(
        (response) => {
          if (user.tipo === this.TIPO_RECRUITMENT) {
            localStorage.setItem('cultural_student', userId);
          }
          this.navigationService.navigateTo({ path: 'cultural-match' });
        },
        (err) => {
          console.error('Error submitting the test:', err);
          this.alertService.showError({
            msg: 'There was an error submitting the test, please try again later',
          });
        }
      );
    }
  }

  /** Redirects the user to the component that show his cultural matches */
  onViewOtherOptions(): void {
    this.navigationService.navigateTo({ path: 'cultural-match' });
  }
}
