import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  /** CONSTANTS */
  /** Localstoarge key services */
  readonly TRANSLATION_SERVICES_KEY = 'u360-cart-translation-services';
  readonly VISA_SERVICES_KEY = 'u360-cart-visa-services';
  readonly MEDICAL_SERVICES_KEY = 'u360-cart-medical-services';
  readonly LANGUAGE_TESTS_KEY = 'u360-cart-language-tests';
  readonly CONSULTANCY_SERVICES_KEY = 'u360-cart-consultancy-services';

  /** List of the translations services that are currently in the cart */
  public serviciosTraduccion = new BehaviorSubject([]);
  /** List of the visa services that are currently in the cart */
  public serviciosVisa = new BehaviorSubject([]);
  /** List of the medical services that are currently in the cart  */
  public serviciosMedicos = new BehaviorSubject([]);
  /** List of the language tests that are currently in the cart */
  public languageTests = new BehaviorSubject([]);
  /** List of counsultancy services */
  public consultancyServices = new BehaviorSubject([]);

  constructor(private alertService: AlertService,
              @Inject(PLATFORM_ID) private platformId) {
                this.initShoppingCart();
               }

  /** Initializes the shopping cart according to the data that is saved in the local storage */
  initShoppingCart(): void {
    if (isPlatformBrowser(this.platformId)) {
      let tServices = JSON.parse(localStorage.getItem(this.TRANSLATION_SERVICES_KEY));
      if (tServices === undefined || tServices === null) {
        tServices = [];
      }
      let vServices = JSON.parse(localStorage.getItem(this.VISA_SERVICES_KEY));
      if (vServices === undefined || vServices === null) {
        vServices = [];
      }
      let mServices = JSON.parse(localStorage.getItem(this.MEDICAL_SERVICES_KEY));
      if (mServices === undefined || mServices === null) {
        mServices = [];
      }
      let lTests = JSON.parse(localStorage.getItem(this.LANGUAGE_TESTS_KEY));
      if (lTests === undefined || lTests === null) {
        lTests = [];
      }
      let cServices = JSON.parse(localStorage.getItem(this.CONSULTANCY_SERVICES_KEY));
      if (cServices === undefined || cServices === null) {
        cServices = [];
      }
      this.serviciosTraduccion.next(tServices);
      this.serviciosVisa.next(vServices);
      this.serviciosMedicos.next(mServices);
      this.languageTests.next(lTests);
      this.consultancyServices.next(cServices);
    }
  }

  /** Adds a new translation service to the cart
   * @param id: id of the service
   * @param provider: company that provides the service
   * @param docType: Document that is going to be translated i.e grades certificate
   * @param cost: price per page
   * @param numberOfPages: Number of pages that are going to be translated
   * @param img: image path
   */
  addTranslationService({id, numberOfPages}:
    {id: string, numberOfPages: number}): void {
    // Check if the product is already added to the list
    const tServices = this.serviciosTraduccion.value;
    const i = tServices.map((e) => e.id).indexOf(id);
    // If the product is in the list add the new number of pages to the old
    if (i > -1) {
      tServices[i].numberOfPages += numberOfPages;
      this.serviciosTraduccion.next(tServices);
    } else {
      // If the product is not in the list add it to the list
      tServices.push({
        id,
        numberOfPages,
      });
      this.serviciosTraduccion.next(tServices);
      localStorage.setItem(this.TRANSLATION_SERVICES_KEY, JSON.stringify(tServices));
    }

    this.alertService.showSuccess({msg: 'Added to the cart'});
  }

  /** Adds a new visa service to the cart
   * @param id: id of the service
   * @param country country in which the service applies
   * @param img image path
   * @param cost cost of adquiring the service
   */
  addVisaService({id}: {id: string}): void {
     // Check if the product is already added to the list
     const vServices = this.serviciosVisa.value;
     const i = vServices.map((e) => e.id).indexOf(id);
     // If the product is in the list tell the user that it can only be bought once
     if (i > -1) {
       this.alertService.showError({msg: 'Service is already in the shopping cart'});
     } else {
       // If the product is not in the list add it to the list
       vServices.push({
         id,
       });
       this.serviciosVisa.next(vServices);
       localStorage.setItem(this.VISA_SERVICES_KEY, JSON.stringify(vServices));
       this.alertService.showSuccess({msg: 'Added to the cart'});
     }
  }

  addConsultancyServices({id}: {id: string}): void {
    // Check if the product is already added to the list
    const cServices = this.consultancyServices.value;
    const i = cServices.map((e) => e.id).indexOf(id);
    // If the product is in the list tell the user that it can only be bought once
    if (i > -1) {
      this.alertService.showError({msg: 'Service is already in the shopping cart'});
    } else {
      // If the product is not in the list add it to the list
      cServices.push({
        id,
      });
      this.consultancyServices.next(cServices);
      localStorage.setItem(this.CONSULTANCY_SERVICES_KEY, JSON.stringify(cServices));
      this.alertService.showSuccess({msg: 'Added to the cart'});
    }

  }

  /** Adds a new medicals service to the cart
   * @param id id of the service
   * @param provider company that provides the service
   * @param serviceName name of the service
   * @param numberOfDays number of days that will be acquired
   * @param pricePerDay price per day
   */
  addMedicalService({id, numberOfDays}:
    {id: string, numberOfDays: number}): void {
    // Check if the product is already added to the list
    const mServices = this.serviciosMedicos.value;
    const i = mServices.map((e) => e.id).indexOf(id);
    // If the product is in the list add the new number of pages to the old
    if (i > -1) {
      mServices[i].numberOfDays += numberOfDays;
      this.serviciosMedicos.next(mServices);
    } else {
      // If the product is not in the list add it to the list
      mServices.push({
        id,
        numberOfDays,
      });
      this.serviciosMedicos.next(mServices);
      localStorage.setItem(this.MEDICAL_SERVICES_KEY, JSON.stringify(mServices));
    }
    this.alertService.showSuccess({msg: 'Added to the cart'});
  }

  /** Adds a new language test to the list of language tests  */
  addLanguageTest({id}): void {
    // Check if the product is already added to the list
    const lTests = this.languageTests.value;
    const i = lTests.map((e) => e.id).indexOf(id);
    // If the product is in the list add the new number of pages to the old
    if (i > -1) {
      this.alertService.showError({msg: 'This test is already in the shopping cart'});
    } else {
      // If the product is not in the list add it to the list
      lTests.push({
        id
      });
      this.languageTests.next(lTests);
      localStorage.setItem(this.LANGUAGE_TESTS_KEY, JSON.stringify(lTests));
    }
    this.alertService.showSuccess({msg: 'Added to the cart'});
  }

  /** Clears all the productos from the shopping cart */
  clearShoppingCart(): void {
    //  
    //  
    //  
    //  
    //  
    this.serviciosTraduccion.next([]);
    this.serviciosMedicos.next([]);
    this.serviciosVisa.next([]);
    this.languageTests.next([]);
    this.consultancyServices.next([])
    localStorage.setItem(this.TRANSLATION_SERVICES_KEY, JSON.stringify([]));
    localStorage.setItem(this.MEDICAL_SERVICES_KEY, JSON.stringify([]));
    localStorage.setItem(this.VISA_SERVICES_KEY, JSON.stringify([]));
    localStorage.setItem(this.LANGUAGE_TESTS_KEY, JSON.stringify([]));
    localStorage.setItem(this.CONSULTANCY_SERVICES_KEY, JSON.stringify([]));
    //  
    //  
    //  
    //  
    //  
  }

  /**
   * Removes a medical service from the shopping cart list
   * @param id id of the service that is to be removed
   */
  removeMedicalService({id}: {id: string}): void {
    let medicalServices = this.serviciosMedicos.value;
    medicalServices = medicalServices.filter((element) => {
      return element.id !== id;
    });
    localStorage.setItem(this.MEDICAL_SERVICES_KEY, JSON.stringify(medicalServices));
    this.serviciosMedicos.next(medicalServices);
  }

  /**
   * Removes a visa service from the shopping cart list
   * @param id id of the service that is to be removed
   */
  removeVisaService({id}: {id: string}): void {
    let visaServices = this.serviciosVisa.value;
    visaServices = visaServices.filter((element) => {
      return element.id !== id;
    });
    localStorage.setItem(this.VISA_SERVICES_KEY, JSON.stringify(visaServices));
    this.serviciosVisa.next(visaServices);
  }

  removeConsultancyService({id}: {id: string}): void {
    let cServices = this.consultancyServices.value;
    cServices = cServices.filter((element) => {
      return element.id !== id;
    });
    localStorage.setItem(this.CONSULTANCY_SERVICES_KEY, JSON.stringify(cServices));
    this.consultancyServices.next(cServices);
  }

  /**
   * Removes a transaltion service from the shopping cart list
   * @param id id of the service that is to be removed
   */
  removeTranslationService({id}: {id: string}): void {
    let translationServices = this.serviciosTraduccion.value;
    translationServices = translationServices.filter((element) => {
      return element.id !== id;
    });
    localStorage.setItem(this.TRANSLATION_SERVICES_KEY, JSON.stringify(translationServices));
    this.serviciosTraduccion.next(translationServices);
  }

  /**
   * Removes a language test from the shopping cart list
   * @param id id of the service that is to be removed
   */
  removeLanguageTest({id}: {id: string}): void {
    let languageTests = this.languageTests.value;
    languageTests = languageTests.filter((element) => {
      return element.id !== id;
    });
    localStorage.setItem(this.LANGUAGE_TESTS_KEY, JSON.stringify(languageTests));
    this.languageTests.next(languageTests);
  }

  /**
   * Changes the quantity of pages of a translation service
   * @param id: id of the translation service
   * @param quantity: new quantity of services
   */
  changeTranslationServiceQuantity({id, quantity}: {id: string, quantity: number}): void {
    const translationServices = this.serviciosTraduccion.value;
    const index = translationServices.findIndex((element) => {
      return element.id === id;
    });
    translationServices[index].numberOfPages = quantity;
    localStorage.setItem(this.TRANSLATION_SERVICES_KEY, JSON.stringify(translationServices));
    this.serviciosTraduccion.next(translationServices);
  }

  /**
   * Changes the quantity of pages of a medical/insurance service
   * @param id: id of the translation service
   * @param quantity: new quantity of services
   */
  changeInsuranceServiceQuantity({id, quantity}: {id: string, quantity: number}): void {
    const medicalServices = this.serviciosMedicos.value;
    const index = medicalServices.findIndex((element) => {
      return element.id === id;
    });
    medicalServices[index].numberOfDays = quantity;
    localStorage.setItem(this.MEDICAL_SERVICES_KEY, JSON.stringify(medicalServices));
    this.serviciosMedicos.next(medicalServices);
  }
}
