import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { NavigationService } from 'src/app/public/services/navigation.service';

@Component({
  selector: 'app-program-wishlist-card',
  templateUrl: './program-wishlist-card.component.html',
  styleUrls: ['./program-wishlist-card.component.scss'],
})
export class ProgramWishlistCardComponent implements OnInit {
  /** Variable que contiene el programa */
  @Input() program: any;
  @Output('reloadWishlist') reloadWishlist: EventEmitter<any> = new EventEmitter();

  user = JSON.parse(localStorage.getItem('user'));

  /** Constant that holds the api for the user */
  readonly USER_API = 'api/user/';
  /** Constant that holds the api for favorites */
  readonly FAVORITE_API_POST = 'api/favorito/post-favoritos';
  readonly FAVORITE_API_DELETE = 'api/favorito/remove-favoritos/';

  /** Costant that holds the api for applications */
  readonly APPLICATION_API = 'api/aplicacion/';
  readonly APPLICATION_API_INFORMATION = 'api/informacion-programa/';

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  /**Funcion para añadir o quitar los favoritos */
  addFavourite() {
    const data = {
      programa: this.program._id,
      id: this.user._id,
    };

    //Data para favoritos
    let startDate = '';
    for (const start of this.program.starts) {
      switch (start) {
        case 1:
          startDate += 'Winter, ';
          break;
        case 2:
          startDate += 'Spring, ';
          break;
        case 3:
          startDate += 'Summer, ';
          break;
        case 4:
          startDate += 'Fall, ';
          break;
      }
    }

    let tuitionByPeriod;
    let period;
    if (this.program.yearTuition !== undefined && this.program.yearTuition !== null) {
      tuitionByPeriod = this.program.yearTuition;
      period = 'Year';
    } else {
      tuitionByPeriod = this.program.programTuition;
      period = 'Full program';
    }
    const dataTableFavorite = {
      userId: this.user._id,
      university: this.program.universidad.nombre,
      city: this.program.nombreCiudad,
      programa: this.program.nombre,
      programType: this.program.acreditacionShown,
      length: this.program.length,
      start: startDate,
      applicationFee: this.program.universidad.applicationFee,
      tuition: tuitionByPeriod + ' ' + this.program.universidad.ciudad.pais.moneda + '/' + period,
      slug: this.program.slug,
      programId: this.program._id,
      comments: ' ',
    };

    if (this.program.inFavourites === true) {
      // If already is in favourites, remove from favourites
      this.apiService.post({ api: this.USER_API + 'remove-favourite', data }).subscribe(
        (response) => {
          this.alertService.showSuccess({ msg: 'Favorito removido exitosamente' });
          // Update favourites list in the local storage to keep the information consistent
          let favoritos = JSON.parse(localStorage.getItem('Favoritos'));
          favoritos = favoritos.filter((pId) => pId !== data.programa);
          localStorage.setItem('Favoritos', JSON.stringify(favoritos));
          this.program.inFavourites = false;
          this.reloadWishlist.emit();
        },
        (err) => {
          this.alertService.showError({
            msg: 'Error: El favorito no pudo ser removido',
          });
        }
      );

      this.apiService.delete({ api: this.FAVORITE_API_DELETE + this.program._id }).subscribe((response) => {});
    } else {
      // If not, add to favourites
      this.apiService.post({ api: this.USER_API + 'post-favourite', data }).subscribe(
        (response) => {
          this.alertService.showSuccess({ msg: 'Favorito añadido con exito' });
          // Update favourites list in the local storage to keep the information consistent
          const favoritos = JSON.parse(localStorage.getItem('Favoritos'));
          favoritos.push(data.programa);
          localStorage.setItem('Favoritos', JSON.stringify(favoritos));
          this.program.inFavourites = true;
        },
        (err) => {
          this.alertService.showError({ msg: 'Error: No se pudo añadir a favorito' });
        }
      );

      this.apiService.post({ api: this.FAVORITE_API_POST, data: dataTableFavorite }).subscribe((response) => {});
    }
  }

  /**
   * Funcion para redirigiar a la seccion de la universidad
   */
  onClickUniversity(): void {
    this.navigationService.navigateTo({
      path: 'university/' + this.program.universidad.slug,
    });
  }

  /**
   * Funcion para redirigir a la seccion del programa
   */
  onClickProgram(): void {
    let slug = this.program.slug;

    slug = slug.replaceAll('\n', '%0D%0A');
    this.navigationService.navigateTo({
      path: 'program/' + slug,
    });
  }

  public onApply() {
    let userRole;
    if (this.user !== null) {
      userRole = this.user.tipo;
    }
    if (userRole && userRole !== 'Recruitment') {
      const today = new Date();
      const data = {
        programa: this.program._id,
        estudiante: this.user._id,
        fechaAplicacion: today,
      };
      this.apiService.post({ api: this.APPLICATION_API, data }).subscribe(
        (response) => {
          if (response.success === true) {
            const applicationId = response.result._id;
            this.alertService.showSuccess({ msg: 'Aplicacion realizada con exito' });
            this.navigationService.navigateTo({
              path: 'application/' + applicationId,
            });
          } else {
            this.alertService.showError({ msg: response.msg });
            this.navigationService.navigateTo({
              path: 'application/' + response.appId,
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'Error al aplicar',
          });
        }
      );
    }
  }
}
