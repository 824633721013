export const skillsArray = [
  {
    id: 'Liderazgo',
    name: 'Liderazgo',
    selected: false,
    descriptions: {
      general: `
      Es la habilidad necesaria para orientar la acción de los grupos humanos en una dirección determinada, inspirando valores de acción y anticipando escenarios de desarrollo de la acción de ese grupo. La habilidad para fijar objetivos, el seguimiento de dichos objetivos y la capacidad de dar feedback, integrando las opiniones de los otros.
      `,
      alto: `
      Sus respuestas en la prueba evidencian un nivel alto en inteligencia emocional. Tiene la capacidad de reconocer y
      comprender las emociones personales y saber cómo éstas influyen en las emociones de otras personas.
      Demuestra autoconciencia, autogestión, empatía y capacidad social. Es capaz de influenciar socialmente en los demás,
      puede estudiar profesiones que involucren la influencia y el trabajo en equipo. Puede ser exitoso en cualquier área
        de desempeño usando su inteligencia emocional.

      `,
      medio: `

      Sus respuestas en la prueba evidencian un nivel medio en inteligencia emocional.
      Tiene la capacidad de reconocer las emociones personales y las emociones de otras personas.
      Demuestra autoconciencia, autogestión y empatía. Puede desarrollar la capacidad de influenciar
      éticamente en los demás a través de sus habilidades sociales.
      `,
      bajo: `
      Sus respuestas en la prueba evidencian un nivel principiante en Inteligencia emocional.
      Tiene la capacidad de reconocer las emociones personales involucrando la autoconciencia.
      Puede desarrollar la autogestión, la empatía y la capacidad de influenciar éticamente en los demás a través de sus habilidades sociales.
      `,
    },
    suggestion: {
    global: "Recuerda que ser un mejor líder es un proceso continuo. Adaptar estos consejos a tu estilo y contexto específico te ayudará a crecer y desarrollar habilidades de liderazgo sólidas. Estas estrategias se basan en teorías de liderazgo y se pueden adaptar según las necesidades y la cultura de tu equipo u organización.",
    suggestions: [
      '    Participa en sesiones de mentoría: Conecta líderes experimentados con miembros del equipo para fomentar el crecimiento personal y profesional. Dirige proyectos que desafíen a los miembros del equipo a superar sus límites y desarrollar nuevas habilidades.',

      'Simula situaciones de liderazgo para practicar diferentes enfoques de manejo de equipos como juego de roles. Observando las interacciones del equipo y brindando comentarios constructivos para mejorar el rendimiento.',

      'Cambia las responsabilidades de liderazgo entre los miembros del equipo según la tarea y la situación. Adapta diferentes estilos de liderazgo con el fin de ajustar tu enfoque según la madurez y las habilidades individuales de los miembros del equipo Acepta el cambio como una oportunidad para el crecimiento.',

      'Participa en talleres de inteligencia emocional con el fin de participar en actividades para desarrollar la autoconciencia, la autogestión y la empatía en los líderes, la escucha activa, fomentando la práctica de escuchar cuidadosamente a los demás y responder con empatía.',

      'Realiza charlas inspiradoras, organizando discursos que motiven e inspiren a los miembros del equipo, desarrollando una marca personal, para comunicar tu visión y valores de manera auténtica y convincente.',

      'Participa en proyectos de servicio comunitario, fomentando la participación en actividades benéficas para desarrollar la conciencia social y la empatía. Ayuda a los miembros del equipo a identificar sus metas y brinda recursos para alcanzarlas.',

      'Reflexiona sobre tus valores, fortalezas y debilidades como líder. Reconoce y trabaja en áreas donde puedas mejorar. Anima a los líderes a examinar sus valores y motivaciones personales para liderar con autenticidad, realizando retroalimentación de 360 grados para proporcionar comentarios de colegas, subordinados y supervisores para mejorar la autoconciencia y el crecimiento.',

      'Establece metas claras, definiendo objetivos específicos y recompensas por el logro, fomentando la motivación del equipo. Celebra los logros individuales y del equipo de manera tangible y pública. Expresa gratitud por el arduo trabajo y los logros del equipo. Celebra los hitos y éxitos de manera regular.',

      'Evalúa cuidadosamente las opciones y toma decisiones basadas en datos y valores. Asume la responsabilidad de tus decisiones, tanto las positivas como las negativas.',

      'Asigna tareas y responsabilidades según las habilidades y capacidades individuales. Confía en tu equipo para realizar sus tareas de manera independiente.',

      'Aborda los conflictos de manera imparcial y respetuosa. Facilita la comunicación abierta para encontrar soluciones mutuamente beneficiosas.',

      'Ofrece orientación y apoyo a los miembros del equipo en su crecimiento profesional. Identifica y nutre el potencial de liderazgo en otros. Organiza tus tareas y establece prioridades para maximizar la eficiencia. Ayuda al equipo a administrar su tiempo de manera efectiva.',
    ]
  }
  },
  {
    id: 'OrientacionResultados',
    name: 'Orientación a resultados',
    selected: false,
    descriptions: {
      general: `Es la capacidad de lograr objetivos y metas, incluso en condiciones difíciles. Es una habilidad esencial para el desarrollo de las personas, ya que permite a los líderes y gerentes tomar decisiones importantes de manera rápida y eficiente, incluso cuando hay presión.`,

      alto: `
      Crea un ambiente que estimula la mejora continua y la orientación a la eficiencia.
      Desarrolla o modifica estrategias eficientes. Se caracteriza por trabajar constantemente en el logro de las metas propuestas. Traza planes detallados, prevé posibles obstáculos en el camino y se prepara para afrontarlos desde diferentes ángulos. Tiene rutinas establecidas y se mantiene en ellas.
      Puede tener éxito desempeñándose en carreras relacionadas con el desarrollo de proyectos de alto impacto y trabajando en cargos de nivel directivo.

      `,
      medio: `
      Actúa siempre dando lo mejor de sí, fijándose para sí y/o para los demás, metas altas. Trabaja con objetivos claramente establecidos, realistas y desafiantes. Compara su rendimiento actual con otros pasados. Se recomienda integrar otros puntos de vista y perspectivas para afrontar desafíos. Se sugiere escuchar los comentarios de las personas a su alrededor y utilizar esa información para hacer cambios.
      `,
      bajo: `

      No está satisfecho con su desempeño y hace cambios específicos para conseguir mejoras.
      Está abierto a nuevas ideas y está dispuesto a cambiar sus métodos para lograr objetivos. Se recomienda trabajar constantemente en el logro de las metas propuestas, aprender a desarrollar planes detallados, prever posibles obstáculos en el camino y prepararse para afrontarlos desde diferentes ángulos.
      `,
    },
    suggestion: {
      global:
        'Recuerda que el desarrollo de la inteligencia emocional es un proceso continuo. Al incorporar estas estrategias en tu vida diaria, podrás mejorar tu capacidad para reconocer, comprender y gestionar tus emociones, así como establecer relaciones más efectivas con los demás.',
      suggestions: [
        'Define objetivos específicos, medibles, alcanzables, relevantes y con un plazo definido. Esta metodología ayuda a dirigir tus esfuerzos hacia resultados concretos.',
        'Divide tus objetivos en tareas más pequeñas y manejables. Esto te permite concentrarte en pasos concretos para alcanzar el resultado final.',
        'Prioriza tus tareas. Identifica las tareas que contribuyen directamente a tus objetivos y enfócate en las actividades que generan los mayores resultados.',
        'Utiliza la técnica de gestión del tiempo (pomodoro). Divide tu tiempo en intervalos de trabajo concentrado (pomodoros) seguidos de cortos descansos. Esta técnica te ayuda a mantener el enfoque y aumentar la productividad.',
        'Establece indicadores clave de desempeño para medir tu avance. Evalúa periódicamente tu progreso y realiza ajustes según los resultados.',
        'Reconoce pensamientos negativos o distractores que puedan desviar tu atención. Cámbialos por enfoques más realistas y positivos.',
        'Crea un entorno de trabajo libre de distracciones, como notificaciones de dispositivos o redes sociales. Mantén tu enfoque en las tareas que te acercan a los resultados deseados.',
        'Establece límites y tiempos de trabajo. Define un horario y límites claros para tus tareas y descansos. Evita el agotamiento y asegúrate de que tus esfuerzos se centren en la calidad.',
        'Aprende de tus errores. No te desanimes por los errores; en su lugar, analiza lo que puedes aprender de ellos. La reflexión te ayudará a mejorar tus enfoques futuros.',
        'Reconoce y celebra tus logros a lo largo del camino. Esto refuerza tu motivación y te ayuda a mantener el impulso.',
        'Mantén una mentalidad positiva y persevera a pesar de los obstáculos. La persistencia es clave para mantener el enfoque en los resultados.',
        'Imagina y visualiza el logro de tus objetivos. Esto puede aumentar tu motivación y enfoque en el resultado deseado.',
      ],
    },
  },
  {
    id: 'AprendizajePermanente',
    name: 'Aprendizaje continuo',
    selected: false,
    descriptions: {
      general: `
      Se refiere a la habilidad para buscar constantemente los nuevos desarrollos y tecnologías en su campo de competencia, para la resolución de problemáticas de la vida diaria capitalizando la propia experiencia y la de otros, compartiéndola promoviendo la gestión de conocimiento y una cultura de aprendizaje.      `,
      alto: `

      Sus respuestas en la prueba evidencian un nivel alto en la competencia aprendizaje permanente.

      Se le facilita compartir sus conocimientos y experiencias actuando como agente de cambio y propagador de nuevas ideas y tecnologías. Le motiva adquirir nuevos conocimientos y ponerlos en práctica. Sus comportamientos se guían por la curiosidad. Es reconocido como un referente en los contextos en los que se desempeña.
      Tiene la potencialidad de ser reconocido como un experto en los temas de interés no solo a nivel local sino internacional.
      `,
      medio: `

      Sus respuestas en la prueba evidencian un nivel medio en la competencia aprendizaje permanente.
      Ofrece su experiencia y conocimientos para resolver problemas de otras áreas. Está en capacidad de analizar datos e información que comparte con su comunidad para el logro de metas y objetivos. Se recomienda idear estrategias que le permitan proyectarse en comunidades más amplías convirtiéndose en un referente nacional e internacional.
      `,
      bajo: `

      Sus respuestas en la prueba evidencian un nivel principiante en la competencia aprendizaje permanente.
      Realiza un gran esfuerzo por adquirir nuevas habilidades y conocimientos. Busca y analiza proactivamente información pertinente para planificar un curso de acción.
      Se recomienda desarrollar actividades que le aporten a su campo de interés, así como el desarrollo de estrategias que le permitan proyectarse en comunidades más amplías convirtiéndose en un referente nacional e internacional.

      `,
    },
    suggestion: {
      global:
        'Recuerda que la creatividad y la innovación son habilidades que se pueden cultivar con el tiempo y la práctica constante. Al adoptar estos pasos en tu vida diaria, podrás desarrollar una mentalidad creativa y generar ideas innovadoras en diversos aspectos de tu vida.',
      suggestions: [
        'Toma el hábito de realizar sesiones periódicas de retroalimentación y reflexión para revisar proyectos y actividades en los que se fomente la discusión abierta sobre lo que funcionó bien y las áreas que pueden mejorarse.',
        'Crea comunidades de práctica en las que los miembros compartan conocimientos y mejores prácticas en áreas específicas para facilitar el intercambio de información y la colaboración en un entorno enfocado.',
        'Fomenta el aprendizaje en equipo y la colaboración en la resolución de problemas y la toma de decisiones en ambientes donde todos aporten ideas y conocimientos.',
        'Después de cada proyecto, anima a los equipos a documentar lo que funcionó y lo que se podría mejorar. Almacena esta información para futuras referencias y aprendizajes.',
        'Busca programas de mentoría o talleres en los que personas más experimentadas compartan su conocimiento con aquellos menos experimentados.',
        'Crea una Biblioteca de Recursos. Reúne documentos, tutoriales y otros recursos valiosos en una base de conocimientos compartida que sea de fácil acceso a información relevante para todos los miembros del equipo.',
        'Anima a la experimentación y la innovación. Promueve la búsqueda de nuevas soluciones y enfoques, probando ideas creativas para aprender de los resultados.',
        'Regularmente, revisa los procesos y enfoques utilizados en proyectos. Identifica áreas de mejora y ajusta las estrategias según los resultados.',
        'Fomenta una cultura en la que la retroalimentación sea valorada y entregada de manera constructiva. Promueve el aprendizaje a través de la retroalimentación y la mejora constante.',
        'Utiliza herramientas en línea para crear espacios de colaboración. Facilita la comunicación y el intercambio de información en cualquier momento.',
        'Reconoce y celebra los logros individuales y del equipo en la mejora continua. Destaca los casos de éxito y comparte las lecciones aprendidas.',
        'Establece metas de aprendizaje y desarrollo personal. Proporciona apoyo para alcanzar esas metas a través de recursos y orientación.',
      ],
    },
  },
  {
    id: 'ImpactoResponsabilidad',
    name: 'Impacto',
    selected: false,
    descriptions: {
      general: `
      Es la habilidad necesaria para orientar la acción de los grupos humanos en una dirección determinada, inspirando valores de acción y anticipando escenarios de desarrollo de la acción de ese grupo. La habilidad para fijar objetivos, el seguimiento de dichos objetivos y la capacidad de dar feedback, integrando las opiniones de los otros.
      `,
      alto: `
      Sus respuestas evidenciaron un nivel alto en la competencia de responsabilidad social, demostrando habilidad en las cuatro facetas que la sustentan.
      Contribuye a la comunidad y cuida el medio ambiente, resuelve problemas de manera pacífica, valora la diversidad, y construye relaciones. Puede trabajar con éxito en proyectos de alto impacto social o ambiental.

      `,
      medio: `

      Contribuye a la comunidad y cuida el medio ambiente y resuelve problemas de manera pacífica. Puede desarrollar la capacidad de valorar la diversidad y construir relaciones.

      `,
      bajo: `
      Contribuye a la comunidad y cuida el medio ambiente. Puede desarrollar la capacidad de resolver problemas de forma pacífica, valorar la diversidad y construir relaciones.
      `,
    },
    suggestion: {
      global:
        'Recuerda que la responsabilidad social es un compromiso continuo. A medida que te involucres más y aprendas más sobre los problemas, podrás hacer una diferencia positiva en tu comunidad y más allá. Estas estrategias pueden adaptarse según tus intereses, habilidades y contexto. El desarrollo de habilidades de responsabilidad social implica un compromiso continuo y la voluntad de contribuir al bienestar de la sociedad.',
      suggestions: [
        'Participa en proyectos de voluntariado en tu comunidad, como limpieza, tutorías o apoyo a personas mayores. Aplica principios de servicio a los demás y compromiso cívico. Reflexiona sobre los problemas sociales que te preocupan y alinean con tus valores. Selecciona las áreas en las que te gustaría hacer una diferencia.',
        'Asiste a charlas y talleres sobre temas sociales, como igualdad de género, diversidad e inclusión, y sostenibilidad. Aprende sobre desafíos globales y cómo puedes contribuir a solucionarlos.',
        'Colabora con ONGs y organizaciones benéficas que trabajan en áreas de tu interés, como educación, salud o medio ambiente. Contribuye con tu tiempo y habilidades para causas que te importan.',
        'Comparte información relevante sobre problemas sociales en tus plataformas de redes sociales. Utiliza tus cuentas para difundir mensajes positivos y educativos.',
        'Únete a campañas de concienciación sobre temas como la violencia doméstica, el acoso escolar o la pobreza. Contribuye a educar a otros y promover el cambio social. Apoya y compra productos de empresas que se preocupan por el impacto social y ambiental. Colabora en proyectos conjuntos para promover la responsabilidad social.',
        'Participa en proyectos que promuevan prácticas sostenibles, como la reducción de residuos y la conservación de recursos. Fomenta el compromiso con el medio ambiente y la responsabilidad ecológica. Adopta prácticas sostenibles en tu vida diaria, como reducir el consumo de plástico y ahorrar energía. Participa en proyectos de conservación y limpieza en tu área.',
        'Brinda mentoría o tutorías a jóvenes estudiantes o a personas que buscan mejorar sus habilidades. Ayuda a otros a alcanzar sus metas y desarrollar su potencial.',
        'Corre en carreras benéficas, participa en recaudaciones de fondos o eventos solidarios. Contribuye a apoyar organizaciones y causas importantes. Dona a organizaciones sin fines de lucro que trabajen en áreas que te importan. Contribuye económicamente para apoyar programas y proyectos.',
        'Lidera proyectos comunitarios que aborden problemas sociales locales. Desarrolla habilidades de planificación, organización y coordinación.',
        'Investiga el impacto social de empresas y organizaciones en sus comunidades. Promueve la responsabilidad empresarial y la ética en la toma de decisiones.',
        'Únete a grupos que luchan por la justicia social, los derechos humanos y la igualdad. Aboga por el cambio a nivel institucional y político.',
        'Organiza charlas y talleres en escuelas, bibliotecas o centros comunitarios para informar sobre problemas sociales. Fomenta la empatía y la comprensión entre diferentes grupos. Regularmente, reflexiona sobre tus actividades y cómo han contribuido. Ajusta tus enfoques según los resultados y aprendizajes obtenidos.',
      ],
    },
  },
  {
    id: 'GlobalMind',
    name: 'Global mind',
    selected: false,
    descriptions: {
      general: `
      Incluye cosmopolitismo y adaptabilidad. Implica la habilidad para adaptarse rápidamente y funcionar con eficacia en cualquier contexto extranjero.
      `,
      alto: `
      Sus respuestas evidenciaron un nivel alto en la competencia pensamiento global, adaptabilidad y
      cosmopolitismo. Demuestra que se adapta rápidamente y funciona con eficacia en cualquier contexto nuevo.
      Se caracteriza por ser capaz de disfrutar cuando viaja y conoce otros lugares, manejar adecuadamente el estrés
      ocasionado por el cambio, comprender diferentes culturas y establecer relaciones interpersonales duraderas.
      Puede desempeñarse con éxito en carreras de índole internacional, lenguas, culturas, historia, etc.

      `,
      medio: `

      Sus respuestas evidenciaron un nivel medio en la competencia pensamiento global,
      adaptabilidad y cosmopolitismo. Tiene buena y rápida adaptación en diferentes medios
      geográficos; funciona eficazmente en cualquier contexto nuevo. Posee buena resistencia
      en viajes y se adapta a culturas diversas. Puede desarrollar aún más esta competencia
      enfocándose en la comprensión de diferentes culturas y en la capacidad de establecer relaciones
      interpersonales duraderas.
      `,
      bajo: `

      Sus respuestas evidenciaron un nivel principiante en la competencia pensamiento global,
      adaptabilidad y cosmopolitismo. Se adapta a través del tiempo a contextos nuevos.
      Puede desarrollar aún más esta competencia manteniéndose abierto al cambio, con actitud
        positiva y flexible hacia las nuevas experiencias, apoyándose en los que le rodean enfocándose
      en la comprensión de las diferentes culturas y entornos, y haciendo nuevos amigos para mejorar su capacidad de establecer relaciones interpersonales duraderas.
      `,
    },
    suggestion: {
      global: 'Recuerda que el desarrollo de habilidades de pensamiento global requiere tiempo y esfuerzo constante. Al implementar estas estrategias, podrás ampliar tus horizontes y convertirte en un pensador más consciente y global. Desarrollar una mentalidad global es un proceso continuo que requiere compromiso y curiosidad. Cada paso que tomes te acercará a comprender y apreciar la diversidad de nuestro mundo.',
      suggestions: [
      'Explora diferentes culturas, costumbres y valores para desarrollar la sensibilidad intercultural. Participa en eventos multiculturales como festivales, conferencias y exposiciones para aprender sobre diversas perspectivas.',

      'Crea diagramas que representen relaciones y conexiones entre conceptos globales. Analiza problemas globales, estudiando temas complejos como cambio climático o migración desde una perspectiva de sistemas interconectados.',

      'Participa en debates internacionales para practicar la expresión clara de ideas y el respeto por diferentes puntos de vista. Interactúa con personas de todo el mundo en plataformas en línea para mejorar tus habilidades de comunicación global. Lee libros, artículos y noticias de diferentes partes del mundo. Sigue a personas de diferentes culturas en redes sociales para obtener perspectivas variadas.',

      'Trabaja en equipos virtuales con miembros de diferentes países en proyectos comunes. Participa en programas de intercambio cultural o académicos para colaborar con personas de diferentes entornos.',

      'Examina diferentes fuentes de noticias y evalúa la objetividad y la cobertura de temas globales. Discute cuestiones éticas desde una perspectiva global, considerando diversas creencias y valores.',

      'Participación en proyectos de sostenibilidad: Colabora en iniciativas locales o globales que aborden desafíos ambientales y sociales. Estudio de prácticas sostenibles: Investiga cómo diferentes comunidades enfrentan y resuelven problemas de sostenibilidad. Contribuye a la conservación del medio ambiente y al bienestar social en diferentes comunidades.',

      'Realiza viajes internacionales para experimentar con diferentes culturas de primera mano, interactuando con personas locales y explorando sus modos de vida. Sumérgete en la cultura local, prueba alimentos tradicionales y habla con los habitantes locales. Realiza voluntariados internacionales, contribuyendo a proyectos de desarrollo comunitario en países extranjeros para obtener una comprensión más profunda de las necesidades globales.',

      'Toma clases de idiomas, aprende un nuevo idioma para facilitar la comunicación y comprensión intercultural. Practica tus habilidades lingüísticas y obtén una visión más auténtica de la cultura participando en conversaciones con hablantes nativos.',

      'Realiza cursos y lecturas sobre política global, aprende sobre la dinámica política y las relaciones entre diferentes países y regiones. Participación en grupos de debate internacional: Analiza y debate asuntos globales con un enfoque en relaciones internacionales. Aprende sobre las dinámicas entre diferentes países y las relaciones internacionales. Comprende cómo los eventos globales impactan en diferentes regiones.',

      'Trabaja en equipos multiculturales, colabora en proyectos con colegas de diferentes orígenes para practicar la comunicación y la colaboración efectiva. Participa en seminarios de diversidad e inclusión, en capacitaciones que promuevan la comprensión y aceptación de diversas perspectivas.',

      'Únete a grupos, ONGs o asociaciones que trabajen en temas globales. Colabora en proyectos que promuevan la paz, la igualdad y la justicia a nivel mundial.',

      'Estás dispuesto a cuestionar tus propias creencias y prejuicios. Acepta que existen múltiples formas válidas de ver el mundo. Practica la escucha activa y la empatía al interactuar con personas de diferentes culturas. Aprende a adaptar tu estilo de comunicación según las necesidades de cada contexto cultural.',
    ]
  }
  },
  {
    id: 'CreatividadInovacion',
    name: 'Creatividad e Innovación',
    selected: false,
    descriptions: {
      general: `
      Se refiere a la capacidad de idear soluciones nuevas y diferentes para problemas o situaciones requeridas en un contexto altamente cambiante y que responde a los nuevos paradigmas, demostrando iniciativa y creatividad, desarrollando nuevas ideas y enfoques y tomando iniciativas, cuando es requerido.      `,
      alto: `

      Sus respuestas en la prueba evidencian un nivel alto en la competencia de creatividad e innovación.
      Demostró capacidad para presentar soluciones, productos, ideas novedosas y originales, nuevas combinaciones del conocimiento existente y/o creación de conocimiento nuevo, que otros no habían presentado antes. Se puede desempeñar en áreas de corte artístico y/o tecnológico.
      `,
      medio: `

      Sus respuestas en la prueba evidencian un nivel medio en la competencia de creatividad e innovación.
      Demuestra habilidad para aplicar y recomendar soluciones e ideas que resuelven problemas o situaciones utilizando su experiencia. Ofrece soluciones incorporando conocimientos previos o de otros. Se recomienda explorar permanentemente las prácticas exitosas para compararlas con las propias y generar mejoras al implementarlas. Igualmente, colaborar con otros, explorar otras culturas y mantener a mano un cuaderno o diarios de ideas para fomentar la creatividad e innovación y así aplicarla en los diferentes ámbitos de la vida para llegar a estar en capacidad de modificar las cosas aun partiendo de formas no pensadas anteriormente.
      `,
      bajo: `

      Sus respuestas en la prueba evidencian un nivel principiante en la competencia de creatividad e innovación.
      Demuestra habilidad para enfrentar distintas situaciones, aplicando o recomendando soluciones tradicionales. Se recomienda explorar permanentemente las prácticas exitosas para compararlas con las propias y generar mejoras al implementarlas. Igualmente, colaborar con otros, explorar otras culturas y mantener a mano un cuaderno o diarios de ideas para fomentar la creatividad e innovación y así aplicarla en los diferentes ámbitos de la vida para llegar a estar en capacidad de modificar las cosas aun partiendo de formas no pensadas anteriormente.
      `,
    },
    suggestion: {
      global:
        'Recuerda que la creatividad y la innovación son habilidades que se pueden cultivar con el tiempo y la práctica constante. Al adoptar estos pasos en tu vida diaria, podrás desarrollar una mentalidad creativa y generar ideas innovadoras en diversos aspectos de tu vida.',
      suggestions: [
        'Mantén una mente abierta. Abraza nuevas ideas y perspectivas sin prejuicios, y evita descartar conceptos por ser diferentes o no convencionales.',
        'Aplica las técnicas de sustitución, combinación, adaptación, modificación, eliminación y reordenamiento para generar nuevas ideas a partir de conceptos existentes. (Método SCAMPER)',
        'Organiza sesiones de lluvia de ideas donde los participantes generen una amplia gama de ideas sin críticas iniciales. Fomenta la creatividad a través de la colaboración y la libre expresión.',
        'No tengas miedo de cometer errores. Aprende de tus fracasos y utilízalos como trampolín para la innovación.',
        'Mantén un diario de ideas. Lleva un registro de tus pensamientos creativos y observaciones. Revisa tu diario para buscar patrones y desarrollar ideas.',
        'Rompe las rutinas. Cambia tus hábitos y la forma en que haces las cosas. La variación puede estimular la mente y generar nuevas perspectivas.',
        'Escucha las experiencias y perspectivas de otras personas. El intercambio de conocimientos puede inspirar nuevas ideas.',
        'Cuestiona lo que sabes y busca nuevos enfoques. La actitud de aprendizaje continuo es fundamental para la innovación.',
        'Cuestiona las convenciones y las reglas establecidas para explorar nuevas posibilidades. No temas probar enfoques no convencionales.',
        'Crea mapas mentales para organizar y explorar ideas de manera no lineal. Utiliza la visualización para representar conceptos de manera más intuitiva.',
        'Aplica conceptos de otras áreas a tu problema actual para encontrar nuevas ideas. Usa metáforas para visualizar problemas desde una perspectiva diferente.',
        'Diseña un espacio inspirador que estimule la creatividad. Agrega elementos visuales, colores y objetos que despierten tu imaginación.',
      ],
    },
  },
  {
    id: 'InteligenciaEmocional',
    name: 'Inteligencia Emocional',
    selected: false,
    descriptions: {
      general: `

      La inteligencia emocional es la capacidad de reconocer y comprender las emociones personales y saber cómo éstas influyen en las emociones de otras personas.
      La autoconciencia implica comprender en profundidad las emociones, los puntos fuertes, las debilidades, las necesidades y los impulsos de uno mismo. La gente con una gran autoconciencia no es ni demasiado crítica ni excesivamente optimista, sino sincera consigo misma y con los demás. El individuo que posee un alto grado de autoconciencia reconoce cómo afectan sus sentimientos a él mismo, a los demás y a su rendimiento laboral.
      La autoconciencia abarca la concepción que tiene la persona de sus valores y sus objetivos.


      Es la tendencia a reflexionar y a meditar. También implica sentirse cómodo ante la ambigüedad y el cambio, así como demostrar integridad, es decir, tener la capacidad de reprimir deseos impulsivos.
      Involucra la motivación, una variante de la autogestión que consiste en movilizar las emociones positivas para avanzar hacia los objetivos. Los líderes motivados sienten el impulso de obtener resultados más allá de las expectativas: las suyas propias y las de todos los demás. El concepto clave es el de obtener resultados. Ser positivo y optimista.
      Es la capacidad para sintonizar emocionalmente con los demás. La empatía implica considerar detenidamente los sentimientos de los subordinados, junto con otros factores, en el proceso de toma de decisiones inteligentes.
      Es la capacidad de hacer avanzar a los demás en la dirección que deseamos, se trate del consenso sobre una nueva estrategia de marketing o del entusiasmo ante un nuevo producto.
      Es la habilidad para relacionarse adecuadamente con las emociones ajenas.

      `,
      alto: `

      Sus respuestas en la prueba evidencian un nivel alto en inteligencia emocional. Tiene la capacidad de reconocer y comprender las emociones personales y saber cómo éstas influyen en las emociones de otras personas.
      Demuestra autoconciencia, autogestión, empatía y capacidad social. Es capaz de influenciar socialmente en los demás, puede estudiar profesiones que involucren la influencia y el trabajo en equipo. Puede ser exitoso en cualquier área de desempeño usando su inteligencia emocional.
      `,
      medio: `
      Sus respuestas en la prueba evidencian un nivel medio en inteligencia emocional. Tiene la capacidad de reconocer las emociones personales y las emociones de otras personas. Demuestra autoconciencia, autogestión y empatía. Puede desarrollar la capacidad de influenciar éticamente en los demás a través de sus habilidades sociales.
      `,
      bajo: `
      Sus respuestas en la prueba evidencian un nivel principiante en Inteligencia emocional. Tiene la capacidad de reconocer las emociones personales involucrando la autoconciencia. Puede desarrollar la autogestión, la empatía y la capacidad de influenciar éticamente en los demás a través de sus habilidades sociales.
      `,
    },
    suggestion: {
      global:
        'Recuerda que el desarrollo de la inteligencia emocional es un proceso continuo. Al incorporar estas estrategias en tu vida diaria, podrás mejorar tu capacidad para reconocer, comprender y gestionar tus emociones, así como establecer relaciones más efectivas con los demás.',
      suggestions: [
        'Presta atención a tus emociones y reconoce cómo te sientes en diferentes situaciones. Mantén un diario emocional para registrar tus pensamientos y sentimientos.',
        'Practica la autocontrol emocional al enfrentar desafíos o situaciones estresantes. Desarrolla la capacidad de pausar antes de reaccionar y elegir respuestas más adecuadas.',
        'Escucha activamente a los demás sin juzgar y trata de comprender sus perspectivas. Pregunta sobre los sentimientos y experiencias de las personas para desarrollar una comprensión más profunda.',
        'Establece metas inspiradoras y conectadas con tus valores personales. Encuentra formas de mantener tu motivación incluso en momentos difíciles.',
        'Practica la escucha activa y haz preguntas abiertas para fomentar la comunicación efectiva. Aprende a leer el lenguaje corporal y las señales no verbales de las personas.',
        'Cultiva relaciones saludables y significativas con amigos, familiares y colegas. Establece límites y comunica tus expectativas de manera clara y respetuosa.',
        'Desarrolla habilidades para manejar conflictos de manera constructiva y sin agresión. Busca soluciones que satisfagan a todas las partes involucradas.',
        'Lee libros o ve películas que aborden diferentes perspectivas y culturas que ayuden a desarrollar tu sentido de la empatía. Participa en experiencias que te expongan a diversas realidades.',
        'Proporciona retroalimentación de manera positiva y constructiva. Aprende a recibir retroalimentación abiertamente y utiliza los comentarios para crecer.',
        'Practica el liderazgo con empatía y comprensión hacia las necesidades de tu equipo. Fomenta un ambiente de trabajo donde se valore la inteligencia emocional.',
        'Dedica tiempo al autocuidado y busca actividades que te ayuden a manejar el estrés y las emociones negativas. Mantén un equilibrio entre el estudio/trabajo y la vida personal.',
      ],
    },
  },
];
