<div class="footer">
  <div class="contact">
    <div class="display-container">
      <div class="contact-container">
        <div>
          <p>
            <strong>¿Quieres explorar más?</strong>
            <br />
            Inscríbete a nuestro newsletter
          </p>
        </div>
        <div class="email-container">
          <form class="m-0 p-0" (ngSubmit)="onSubmit({ event: $event.submitter })" [formGroup]="createForm">
            <input
              type="text"
              placeholder="Escribe tu correo electrónico"
              type="email"
              id="email"
              formControlName="correo" />
            <button id="create" [disabled]="">Suscribirse</button>
          </form>
        </div>
      </div>
      <div class="topics-container">
        <div class="topic">
          <div class="label-container">
            <label>Sobre Nosotros</label>
            <img
              (click)="showDetails({ item: 'aboutUs' })"
              *ngIf="scrWidth < 951"
              src="assets/utils/detail-arrow.png"
              [class.hide-details]="displayAboutUs"
              alt="dropdown arrow" />
          </div>
          <ng-container *ngIf="scrWidth > 950 || displayAboutUs">
            <a routerLink="/manifest" target="_blank"><span>Cómo funciona U360</span></a>
            <a
              href="https://api.whatsapp.com/send?phone=573156676627&text=Hola%2C%20Quiero%20información%20sobre%20U360"
              target="_blank"
              rel="noopener">
              <span>Contacto</span>
            </a>

            <a target="_blank" routerLink="/privacy-policy"><span>Políticas de privacidad</span></a>
            <a target="_blank" routerLink="/terms-and-conditions"><span>T&C / Garantía de soporte</span></a>
          </ng-container>
        </div>
      </div>
      <div class="social-networks">
        <div class="social-icons">
          <a href="https://web.facebook.com/U360education" target="_blank">
            <img class="social-network-button" src="assets/footer/footer-facebook.png" alt="facebook" />
          </a>
          <a href="https://www.instagram.com/u360education/?hl=en" target="_blank">
            <img class="social-network-button" src="assets/footer/footer-instagram.png" alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/heclatam/about/" target="_blank">
            <img class="social-network-button" src="assets/footer/footer-linkedin.png" alt="linkedin" />
          </a>
          <a href="https://youtube.com/channel/UCK1YJmkFvLPqfFPNZ0n_4Jg" target="_blank">
            <img class="social-network-button" src="assets/footer/footer-youtube.png" alt="youtube" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="topic-section">
    <div class="logos">
      <div class="footer-logo">
        <img id="footer-logo-img" src="assets/navbar/logo.png" alt="u360 logo" />
        <p>
          {{ 'Footer.data-protection-policy' | translate }}
          <br />
          {{ 'Footer.copyright' | translate }}
        </p>
      </div>

      <div class="logos-supported">
        <span>Supported by:</span>
        <img id="u360-logo" src="assets/navbar/logo.png" alt="u360 logo" />
        <img id="hec-logo" src="assets/footer/HEC.png" alt="HEC logo" />
        <img id="teducamos-logo" src="assets/footer/TEDUCAMOS.png" alt="teducamos logo" />
        <img id="years-logo" src="assets/footer/experiencia-azul.png" alt="years of experience" />
        <img id="years-logo" src="../../../../assets/footer/extudia.jpeg" alt="years of experience" />
      </div>
    </div>
    <div class="links">
      <a routerLink="/terms-and-conditions">{{ 'Footer.terms-of-use' | translate }}</a>
      <a routerLink="/privacy-policy">{{ 'Footer.privacy-policy' | translate }}</a>
    </div>
  </div>
</div>
