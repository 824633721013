<div class="visa-service-card">
  <div class="visa-service-card-header">
    <mat-icon (click)="onAdditionalInfoClick()" class="info-button"> info
    </mat-icon>
  </div>
  <div class="visa-service-info">
    <div class="container-img-beca">
      <img [src]="image" class="visa-service-img" alt="visa service">
    </div>
    <div class="visa-service-data">
      <span *ngIf="name !== undefined">{{name}}</span>
      {{country}} <br>
      <span class="type"> {{ type }}</span> <br>
      <span class="visa-service-cost">${{cost | number}} {{currency}}</span>
    </div>
  </div>
  <button (click)="onAddToCart()" class="add-to-cart"> {{ "Visa-service-card.buy" | translate }} </button>
</div>
