import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ubication',
  templateUrl: './ubication.component.html',
  styleUrls: ['./ubication.component.scss']
})
export class UbicationComponent implements OnInit {

  @Input() university:any;

  constructor() { }

  ngOnInit(): void {
  }

}
