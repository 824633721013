<div class="terms">
    <h3><strong>TÉRMINOS Y CONDICIONES DE LA PRESTACIÓN DEL SERVICIO/CONSULTORÍA HIGHER EDUCATION CONSULTING</strong></h3>
  <ol>
    <li>
      Higher Education Consulting S.A.S. sociedad debidamente constituida y
      existente de conformidad con las leyes de la Rep&uacute;blica de Colombia
      identificada con NIT 901.255.403-8 en adelante LA CONSULTORA tiene por
      objeto asesorar y/o dar consultor&iacute;a al ESTUDIANTE/CANDIDATO durante
      el proceso de los diferentes planes de estudio en el exterior, para lo
      cual pone a disposici&oacute;n del ESTUDIANTE/CANDIDATO toda su fuerza de
      trabajo para apoyar, guiar y responder solicitudes de acuerdo a los
      par&aacute;metros de servicio establecidos y
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="2">
    <li>
      LA CONSULTORA y el ESTUDIANTE/CANDIDATO entienden que los servicios (de
      educaci&oacute;n, visas, hospedajes, transportes, seguros, servicios
      financieros y cualquier otro que se presente en lo concerniente al viaje)
      son prestados por terceros (instituciones, embajadas, gobiernos,
      aerol&iacute;neas, aseguradoras, entidades financieras, entre otros ), los
      cuales pueden definir, redefinir, cambiar, anular, cancelar planes,
      pol&iacute;ticas y definiciones en cualquier momento sin previa
      comunicaci&oacute;n. Entendiendo que las posibles afectaciones que se
      presenten con ocasi&oacute;n a estas decisiones no son responsabilidad de
      LA CONSULTORA, bajo el car&aacute;cter de independencia que tienen cada
      una de las entidades mencionadas.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="3">
    <li>
      Los terceros involucrados durante el proceso (instituciones, embajadas,
      gobiernos, aerol&iacute;neas, aseguradoras, entidades financieras, etc)
      podr&aacute;n requerir al ESTUDIANTE/CANDIDATO informaci&oacute;n o
      documentos adicionales que no aparezcan en el check list (visible al
      p&uacute;blico) sin previa comunicaci&oacute;n.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="4">
    <li>
      Los terceros involucrados (instituciones, embajadas, gobiernos,
      aerolineas, aseguradoras, entidades financieras, etc) se reservan el
      derecho a negar o no admitir a cualquier ESTUDIANTE/CANDIDATO en sus
      procesos, por los motivos que consideren y no est&aacute;n obligados a
      comunicar las razones de sus decisiones, por tal motivo LA CONSULTORA no
      puede hacerse responsable de dichas
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="5">
    <li>
      El ESTUDIANTE/CANDIDATO es responsable de hacer los pagos en los tiempos
      estipulados por las instituciones o cualquier otro tercero involucrado en
      el programa de viaje; se aclara que el No hacerlo, es motivo de
      cancelaci&oacute;n por parte de los terceros y los montos pagados pueden o
      no ser reembolsados seg&uacute;n pol&iacute;ticas internas de cada
      instituci&oacute;n.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="6">
    <li>
      LA CONSULTORA act&uacute;a como intermediario entre el estudiante y la/las
      instituciones en el exterior. Las cancelaciones o incumplimiento alguno
      por parte de ESTUDIANTE/CANDIDATO a los terceros involucrados en los
      procesos son causales de pago de penalidades determinadas en las
      pol&iacute;ticas y condiciones de cada Ser&aacute; responsabilidad de cada
      estudiante conocerlas, consultarlas y pagarlas.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="7">
    <li>
      Para acceder a servicios de vivienda a trav&eacute;s de Homestay (casas de
      familia) u otros, se recomienda hacer la reservaci&oacute;n con al menos
      con 8 semanas de anticipaci&oacute;n: el ESTUDIANTE/CANDIDATO conoce que
      todos los servicios est&aacute;n sujetos a disponibilidad y que los
      precios pueden variar dependiendo de la anticipaci&oacute;n, la temporada
      y el cambio de la moneda. Los servicios de hospedaje v&iacute;a Homestay
      por lo regular empiezan los d&iacute;as s&aacute;bados y en algunos casos
      domingos. Si los ESTUDIANTES llegan en d&iacute;as diferentes deben pagar
      por los d&iacute;as/semanas adicionales y el servicio est&aacute; sujeto a
      disponibilidad. Algunos hospedajes tienen horarios espec&iacute;ficos de
      llegada o generan cobros
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="8">
    <li>
      Cuando el ESTUDIANTE/CANDIDATO cancela o desiste de cualquiera de los
      servicios (educaci&oacute;n en instituciones, visas, inscripciones,
      hospedajes, transportes, seguros, servicios financieros y cualquier otro
      concerniente al viaje), entiende que se encuentra sujeto a las
      pol&iacute;ticas de no devoluci&oacute;n de dineros por parte de las
      universidades e inclusive hacer efectiva la cl&aacute;usula penal en
      algunos casos. El estudiante debe conocer las pol&iacute;ticas de
      devoluci&oacute;n de cada
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="9">
    <li>
      Los precios enviados de Educaci&oacute;n Superior son estimados, por lo
      tanto, el valor final a pagar ser&aacute; confirmado por cada
      instituci&oacute;n de acuerdo a la TRM del d&iacute;a. Para los pagos se
      debe tener en cuenta que son en moneda destino y se transan con base a la
      TRM de la fecha de pago adicional al valor de los gastos financieros de
      env&iacute;o de dineros de cada
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="10">
    <li>
      El fee (tarifa) administrativo / fee de registro no ser&aacute;
      reembolsable por parte de HEC en los siguientes casos: Cuando el
      estudiante cancele o desista del tr&aacute;mite o por la negaci&oacute;n
      de la visa. El fee administrativo es v&aacute;lido por un s&oacute;lo
      acompa&ntilde;amiento en el proceso, HEC se reserva el derecho de cobrar
      un nuevo registro en caso de que los procesos sean postergados un
      a&ntilde;o o m&aacute;s o que las condiciones iniciales cambien.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p>
    <strong
      >PO&Iacute;TICAS DE PROTECCI&Oacute;N DE DATOS PERSONALES &nbsp;HIGHER
      EDUCATION CONSULTING</strong
    >
  </p>
  <p>
    <strong
      >T&Eacute;RMINOS Y CONSIDERACIONES DE SERVICIO/CONSULTOR&Iacute;A HIGHER
      EDUCATION
    </strong>
  </p>
  <ol start="11">
    <li>
      LA CONSULTORA de acuerdo con las disposiciones contenidas en la Ley
      Estatutaria 1581 de 2012, el Decreto 1377 de 2013 y el Decreto 886 de 2014
      (hoy incorporados en el Decreto &uacute;nico 1074 de 2015), ser&aacute; la
      Responsable del Tratamiento de los Datos Personales previa
      autorizaci&oacute;n de los titulares.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="12">
    <li>
      Para los efectos de la presente en virtud de lo dispuesto en los
      art&iacute;culos 3, 14 y 15 de la Ley Estatutaria 1581 de 2012, y en
      virtud del art&iacute;culo 3 del Decreto 1377 de 2013. Se dar&aacute;
      definici&oacute;n a los siguientes t&eacute;rminos:
    </li>
  </ol>
  <ul>
    <li>
      AUTORIZACI&Oacute;N: Consentimiento previo, expreso e informado del
      Titular para llevar a cabo el Tratamiento de datos personales;
    </li>
    <li>
      BASE DE DATOS: Conjunto organizado de datos personales que sea objeto de
      Tratamiento;
    </li>
    <li>
      DATO PERSONAL Cualquier INFORMACI&Oacute;N vinculada o que pueda asociarse
      a personas naturales. Para considerarlo como dato personal debe responder
      afirmativamente a la siguientepregunta:
    </li>
  </ul>
  <p>
    &iquest;Con el dato puedo identificar directa o indirectamente a una persona
    natural?
  </p>
  <ul>
    <li>
      TRATAMIENTO RECOLECCI&Oacute;N, ALMACENAMIENTO, USO, CIRCULACI&Oacute;N o
      SUPRESI&Oacute;N de datos personales.
    </li>
    <li>
      TITULAR DEL DATO PERSONAL PERSONA NATURAL cuyos datos personales son
      objeto de tratamiento.
    </li>
    <li>
      RESPONSABLE DECIDE sobre la base de datos y/o el tratamiento de datos.
    </li>
    <li>
      ENCARGADO Realiza el tratamiento de datos personales POR CUENTA DEL
      RESPONSABLE. (Todos los terceros fuera de LA COMPA&Ntilde;IA)
    </li>
    <li>
      BASE DE DATOS CONJUNTO ORGANIZADO DE DATOS personales. (Estos pueden ser
      f&iacute;sicos o digitales).
    </li>
    <li>
      DATO P&Uacute;BLICO Dato que no es semiprivado, privado o sensible (Ej.
      datos relativos al estado civil de las personas, su profesi&oacute;n u
      oficio, su calidad de comerciante o servidor p&uacute;blico).
      Tambi&eacute;n lo es el dato que es calificado como p&uacute;blico en la
      Ley.
    </li>
    <li>
      DATO SEMIPRIVADO Dato que no tiene naturaleza &iacute;ntima, reservada, ni
      p&uacute;blica y cuyo conocimiento interesa al titular y a cierto sector o
      grupo de personas o a la sociedad en general.
    </li>
    <li>
      DATO PRIVADO Dato que solo es relevante para su titular (Ej.
      fotograf&iacute;as, videos, datos relacionados con su estilo de vida.)
    </li>
    <li>
      TRANSFERENCIA: La transferencia de datos tiene lugar cuando el Responsable
      y/o Encargado del Tratamiento de datos personales, ubicado en Colombia,
      env&iacute;a la informaci&oacute;n o los datos personales a un receptor,
      que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera
      del pa&iacute;s.
    </li>
    <li>
      TRANSMISI&Oacute;N: Tratamiento de datos personales que implica la
      comunicaci&oacute;n de los mismos dentro o fuera del territorio de la
      Rep&uacute;blica de Colombia cuando tenga por objeto la realizaci&oacute;n
      de un Tratamiento por el Encargado por cuenta del Responsable.
    </li>
  </ul>
  <ol start="13">
    <li>
      La recolecci&oacute;n, almacenamiento, uso, transmisi&oacute;n,
      transferencia, circulaci&oacute;n y/o supresi&oacute;n de Datos Personales
      por parte del Responsable, requiere del consentimiento libre, previo,
      expreso e informado de los titulares de los mismos. En ese sentido, LA
      CONSULTORA, en su condici&oacute;n de Responsable del tratamiento, ha
      dispuesto los mecanismos necesarios para obtener la autorizaci&oacute;n
      previa y expresa de los titulares de los Datos Personales
      ESTUDIANTE/CANDIDATO o persona que interviene en el proceso.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="14">
    <li>
      Los Datos Personales tales como los nombres, n&uacute;mero de
      c&eacute;dula, correos electr&oacute;nicos, tel&eacute;fonos fijos o
      celulares, entre otros, son recolectados, almacenados, organizados,
      usados, circulados, transmitidos, transferidos, actualizados,
      rectificados, suprimidos, eliminados y gestionados por el Responsable,
      para las siguientes finalidades: gesti&oacute;n de clientes,
      gesti&oacute;n administrativa, prospecci&oacute;n comercial,
      fidelizaci&oacute;n de clientes, marketing, publicidad propia, el
      env&iacute;o de comunicaciones comerciales sobre productos y
      campa&ntilde;as de actualizaci&oacute;n de datos e informaci&oacute;n de
      cambios en el tratamiento de datos personales; asimismo, para compartir
      dicha informaci&oacute;n con aliados estrat&eacute;gicos y para dar
      cumplimiento, cuando aplique, a las obligaciones establecidas en los
      contratos que HEC suscriba con terceros, sean estos contratos de
      car&aacute;cter laboral, mercantil o civil, as&iacute; como para dar
      cumplimiento a la legislaci&oacute;n laboral aplicable, al reglamento
      interno de trabajo y a cualquier otra disposici&oacute;n normativa, sea de
      car&aacute;cter laboral, tributaria, civil, penal o cualquier otra.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="15">
    <li>
      De conformidad con lo establecido en el art&iacute;culo 8 de la Ley 1581
      de 2012 y el cap&iacute;tulo 25 del Decreto 1074 de 2015, el titular de
      los Datos Personales tiene los siguientes derechos:
    </li>
    <li>
      Acceder de forma gratuita a los datos proporcionados que hayan sido objeto
      de tratamiento;
    </li>
    <li>
      Conocer, actualizar y rectificar su informaci&oacute;n frente a datos
      parciales, inexactos, incompletos, fraccionados, que induzcan a error, o
      aquellos cuyo tratamiento est&eacute; prohibido o no haya sido autorizado;
    </li>
    <li>Solicitar prueba de la autorizaci&oacute;n otorgada;</li>
    <li>
      Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas
      por infracciones a lo dispuesto en la normatividad vigente;
    </li>
    <li>
      Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato,
      siempre que no exista un deber legal o contractual que impida eliminarlos;
      y
    </li>
    <li>
      Abstenerse de responder las preguntas sobre datos sensibles. Tendr&aacute;
      car&aacute;cter facultativo las respuestas que versen sobre datos
      sensibles o sobre datos de las ni&ntilde;as y ni&ntilde;os y adolescentes
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="16">
    <li>
      El titular de los Datos Personales, o cualquiera de las personas
      autorizadas conforme con lo establecido en este documento, podr&aacute;n
      consultar la informaci&oacute;n que repose en las Bases de Datos de HEC,
      as&iacute; como solicitar la correcci&oacute;n, actualizaci&oacute;n o
      supresi&oacute;n, o cuando adviertan el presunto incumplimiento de
      cualquiera de los deberes, mediante solicitud enviada de lunes a viernes
      en horario de 8:00 a.m. a 5:00 p.m. al correo electr&oacute;nico
      natalia@hec-latam.com donde se indicar&aacute;, entre otras cosas lo
      siguiente:
    </li>
    <li>
      Se deber&aacute; indicar los datos solicitados, previa
      identificaci&oacute;n de la identidad del solicitante;
    </li>
    <li>
      Para evitar que terceros no autorizados accedan a la informaci&oacute;n
      personal del titular de los Datos Personales, ser&aacute; necesario
      establecer la identificaci&oacute;n del Titular. En aquellos casos en los
      que la solicitud sea formulada por una persona distinta a su titular y no
      se acredite la representaci&oacute;n, se tendr&aacute; por no presentada
      la solicitud;
    </li>
    <li>
      Si se trata de un reclamo y este resulta incompleto, HEC requerir&aacute;
      al solicitante dentro de los cinco (5) d&iacute;as h&aacute;biles
      siguientes a la recepci&oacute;n de la solicitud para que subsane sus
      fallas.
    </li>
    <li>
      El t&eacute;rmino m&aacute;ximo para responder una petici&oacute;n, queja
      o reclamo ser&aacute; de quince (15) d&iacute;as h&aacute;biles contados a
      partir del d&iacute;a siguiente a la fecha de su recibo. . Cuando no fuere
      posible atender el reclamo dentro de dicho t&eacute;rmino, se
      informar&aacute; al interesado los motivos de la demora y la fecha en que
      se atender&aacute; su reclamo, la cual en ning&uacute;n caso podr&aacute;
      superar los ocho (8) d&iacute;as h&aacute;biles siguientes al vencimiento
      del primer t&eacute;rmino.
    </li>
  </ol>
  <p>&nbsp;</p>
  <ol start="17">
    <li>
      El titular de los Datos Personales tiene el derecho, en todo momento, de
      solicitarle al Responsable del tratamiento de los Datos Personales la
      revocatoria y/o supresi&oacute;n (eliminaci&oacute;n) de sus datos
      personales en los t&eacute;rminos establecidos en la Ley y siempre y
      cuando no lo impida o restrinja una disposici&oacute;n legal.
    </li>
    <li>
      El responsable del Tratamiento de los Datos Personales podr&aacute;
      modificar la presente Pol&iacute;tica en cualquier momento, y siempre
      subir&aacute; la versi&oacute;n actualizada en la p&aacute;gina web e
      informar&aacute; de dicha actualizaci&oacute;n a los titulares de los
      Datos Personales mediante notificaci&oacute;n enviada al correo
      electr&oacute;nico de cada uno de ellos.
    </li>
    <li>
      La Pol&iacute;tica rige a partir de su aceptaci&oacute;n. Los Datos
      Personales que sean almacenados, utilizados o transmitidos
      permanecer&aacute;n en nuestra Base de Datos, con base en el criterio de
      temporalidad y necesidad, durante el tiempo que sea necesario para las
      finalidades mencionadas en esta Pol&iacute;tica, para las cuales fueron
      recolectados.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p>
    El &aacute;rea de cumplimiento de LA CONSULTORA ser&aacute; el &aacute;rea
    responsable de la atenci&oacute;n de peticiones, quejas y reclamos, o para
    el ejercicio de los derechos de los titulares de los Datos Personales objeto
    de tratamiento por parte de HEC.
  </p>
</div>
