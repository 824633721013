import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { title } from 'process';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-skillsDescription',
  templateUrl: './skillsDescription.component.html',
  styleUrls: ['./skillsDescription.component.scss'],
})
export class SkillDescriptionComponent implements OnInit {
  @Output() notificacionTab4 = new EventEmitter<string>();
  @Output() nextButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedSkill: EventEmitter<string> = new EventEmitter<string>();
  selectedSkillsOption: any;
  constructor() {}

  ngOnInit(): void {
    let icon1 = document.getElementById('1');
    icon1.classList.remove('fa-plus');
    icon1.classList.add('fa-minus');
    let titleDesc = document.getElementById('title-desc');
    let introDesc = document.getElementById('intro-desc');
    let questionDesc = document.getElementById('question-desc');
    let contentDesc = document.getElementById('content-desc');

    titleDesc.innerHTML = 'Liderazgo';
    introDesc.innerHTML =
      'El perfil de líder es muy demandado a nivel internacional y es una de las competencias que más buscan las universidades para aceptar sus candidatos.';
    questionDesc.innerHTML = '¿Qué es el liderazgo internacional y por qué es importante?';
    contentDesc.innerHTML = 'Los lideres, influencian, saben trabajar en equipo, integran y desarrollan ideas. ';
    contentDesc.innerHTML +=
      'Es una cualidad que da garantías de una persona con iniciativa, responsable, capaz de proponerse objetivos, aparte de ser generalmente muy optimistas. Los líderes tienen una alta influencia en sí mismos y en otros, lo que lleva a mejores y más rápidos resultados. Sin embargo, un perfil de líder no se crea de la noche a la mañana.';
  }

  emitNextButtonClick() {
    if (this.selectedSkillsOption) {
      this.nextButtonClick.emit();
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes seleccionar una habilidad antes de continuar.',
        icon: 'error',
      });
    }
  }

  emitPreviousButtonClick() {
    this.previousButtonClick.emit();
  }

  verdetalle(valor) {
    let titleDesc = document.getElementById('title-desc');
    let introDesc = document.getElementById('intro-desc');
    let questionDesc = document.getElementById('question-desc');
    let contentDesc = document.getElementById('content-desc');

    let icon1 = document.getElementById('1');
    let icon2 = document.getElementById('2');
    let icon3 = document.getElementById('3');
    let icon4 = document.getElementById('4');
    let icon5 = document.getElementById('5');

    let card1 = document.getElementById('card1');
    let card2 = document.getElementById('card2');
    let card3 = document.getElementById('card3');
    let card4 = document.getElementById('card4');
    let card5 = document.getElementById('card5');

    if (valor == 1) {
      icon1.classList.remove('fa-plus');
      icon1.classList.add('fa-minus');
      card1.classList.add('colorCardBody');

      if (icon2.classList.contains('fa-minus')) {
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
        card2.classList.remove('colorCardBody');
      }
      if (icon3.classList.contains('fa-minus')) {
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
        card3.classList.remove('colorCardBody');
      }
      if (icon4.classList.contains('fa-minus')) {
        icon4.classList.remove('fa-minus');
        icon4.classList.add('fa-plus');
        card4.classList.remove('colorCardBody');
      }
      if (icon5.classList.contains('fa-minus')) {
        icon5.classList.remove('fa-minus');
        icon5.classList.add('fa-plus');
        card5.classList.remove('colorCardBody');
      }
      titleDesc.innerHTML = 'Liderazgo';
      introDesc.innerHTML =
        'El perfil de líder es muy demandado a nivel internacional y es una de las competencias que más buscan las universidades para aceptar sus candidatos.';
      questionDesc.innerHTML = '¿Qué es el liderazgo internacional y por qué es importante?';
      contentDesc.innerHTML = 'Los lideres, influencian, saben trabajar en equipo, integran y desarrollan ideas.';
      contentDesc.innerHTML +=
        'Es una cualidad que da garantías de una persona con iniciativa, responsable, capaz de proponerse objetivos, aparte de ser generalmente muy optimistas. Los líderes tienen una alta influencia en sí mismos y en otros, lo que lleva a mejores y más rápidos resultados. Sin embargo, un perfil de líder nos se crea de la noche a la mañana.';
    } else if (valor == 2) {
      icon2.classList.remove('fa-plus');
      icon2.classList.add('fa-minus');
      card2.classList.add('colorCardBody');

      if (icon1.classList.contains('fa-minus')) {
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
        card1.classList.remove('colorCardBody');
      }
      if (icon3.classList.contains('fa-minus')) {
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
        card3.classList.remove('colorCardBody');
      }
      if (icon4.classList.contains('fa-minus')) {
        icon4.classList.remove('fa-minus');
        icon4.classList.add('fa-plus');
        card4.classList.remove('colorCardBody');
      }
      if (icon5.classList.contains('fa-minus')) {
        icon5.classList.remove('fa-minus');
        icon5.classList.add('fa-plus');
        card5.classList.remove('colorCardBody');
      }
      titleDesc.innerHTML = 'Logro y Acción';
      introDesc.innerHTML =
        'Recurrentemente las personas suelen hablar más de lo que ejecutan. Un perfil que tiene esta competencia tiene el poder de llevar sus planes a la realidad.';
      questionDesc.innerHTML = '¿Qué significa trabajo y acción y por qué es importante?';
      contentDesc.innerHTML =
        'Si eres el tipo de persona se suma a iniciativas y ve en el esfuerzo y la dedicación valores indispensables para lograr sus metas, seguramente esta competencia es la tuya.';
      contentDesc.innerHTML +=
        ' La parte académica es importante, pero no siempre es un indicativo de éxito.  Para una universidad es muy importante contar con personas comprometidas que no abandonen sus objetivos.';
    } else if (valor == 3) {
      icon3.classList.remove('fa-plus');
      icon3.classList.add('fa-minus');
      card3.classList.add('colorCardBody');

      if (icon1.classList.contains('fa-minus')) {
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
        card1.classList.remove('colorCardBody');
      }
      if (icon2.classList.contains('fa-minus')) {
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
        card2.classList.remove('colorCardBody');
      }
      if (icon4.classList.contains('fa-minus')) {
        icon4.classList.remove('fa-minus');
        icon4.classList.add('fa-plus');
        card4.classList.remove('colorCardBody');
      }
      if (icon5.classList.contains('fa-minus')) {
        icon5.classList.remove('fa-minus');
        icon5.classList.add('fa-plus');
        card5.classList.remove('colorCardBody');
      }
      titleDesc.innerHTML = 'Impacto Social';
      introDesc.innerHTML =
        'Ser ciudadano del mundo implica también tener interés por lo que ocurre en tu entorno; ser ajeno a tu realidad es imposible para alguien que busca tener impacto social.';
      questionDesc.innerHTML = '¿Qué significa Impacto Social y Ambiental? ¿Por qué es importante?';
      contentDesc.innerHTML =
        'La consciencia social es la base para una sociedad más justa y libre y para contribuir a la construcción de un mejor futuro; pero no basta con conocer las problemáticas:  hay personas que cuentan con iniciativas para cambiar realidades.';
      contentDesc.innerHTML +=
        ' El impacto social es una competencia que buscan las universidades para formar líderes íntegros.';
    } else if (valor == 4) {
      icon4.classList.remove('fa-plus');
      icon4.classList.add('fa-minus');
      card4.classList.add('colorCardBody');

      if (icon3.classList.contains('fa-minus')) {
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
        card3.classList.remove('colorCardBody');
      }
      if (icon2.classList.contains('fa-minus')) {
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
        card2.classList.remove('colorCardBody');
      }
      if (icon1.classList.contains('fa-minus')) {
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
        card1.classList.remove('colorCardBody');
      }
      if (icon5.classList.contains('fa-minus')) {
        icon5.classList.remove('fa-minus');
        icon5.classList.add('fa-plus');
        card5.classList.remove('colorCardBody');
      }
      titleDesc.innerHTML = 'Competencia Académica';
      introDesc.innerHTML =
        'A nivel internacional la competencia académica permite a las universidades del mundo conocer tu capacidad de generar resultados de manera consistente. Si no los tienes no te preocupes, no solo lo académico es importante para un desarrollo académico exitoso.';
      questionDesc.innerHTML = '¿Qué significa Competencia Académica?';
      contentDesc.innerHTML =
        'Los hábitos nos definen, eso que hacemos constantemente muestra quienes somos:  si escribes todos los días eres escritor, si haces obras de arte eres artista, si obtienes conocimiento de manera estructurada  cada día, tienes competencia académica. Pero ten cuidado: no se trata de ir al colegio o universidad, sino de esforzarse extracurricularmente para ser muy bueno.';
    } else if (valor == 5) {
      icon5.classList.remove('fa-plus');
      icon5.classList.add('fa-minus');
      card5.classList.add('colorCardBody');

      if (icon1.classList.contains('fa-minus')) {
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
        card1.classList.remove('colorCardBody');
      }
      if (icon2.classList.contains('fa-minus')) {
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
        card2.classList.remove('colorCardBody');
      }
      if (icon3.classList.contains('fa-minus')) {
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
        card3.classList.remove('colorCardBody');
      }
      if (icon4.classList.contains('fa-minus')) {
        icon4.classList.remove('fa-minus');
        icon4.classList.add('fa-plus');
        card4.classList.remove('colorCardBody');
      }

      titleDesc.innerHTML = 'Global Mind';
      introDesc.innerHTML =
        '¿Estás listo para impactar al mundo? Nuestros más profundos arraigos nos generan creencias limitantes que normalizamos, pero… ¿Queremos un mundo realmente mejor?';
      questionDesc.innerHTML = '¿Qué significa Global Mind y por qué es importante?';
      contentDesc.innerHTML =
        'Las mentes globales se adecuan para convivir en cualquier parte del mundo, incluso en su propio país. La disposición, el conocimiento y la puesta en práctica de la interacción con otras culturas son las características más importantes de un ciudadano con global mind. Si adicionalmente entiendes los aspectos más relevantes de tu campo de acción o negocio de manera integral, y cómo puedes impactar al mundo se maximizan las probabilidades de éxito global.';
    }
    if (valor >= 1 && valor <= 5) {
      const options = ['Liderazgo', 'Logro y Acción', 'Impacto Social', 'Competencia Académica', 'Global Mind'];
      const skill = options[valor - 1];
      this.selectedSkillsOption = skill;
      this.selectedSkill.emit(skill);
    }
  }
}
