import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.scss'],
})
export class MyApplicationsComponent implements OnInit {
  /** CONSTANTS */
  /** Api path for getting the applications of an estudent by id  */
  readonly APPLICATIONS_BY_STUDENT_API = 'api/aplicacion/estudiante/';
  /** API path for retrieving students by recruitment partner */
  readonly STUDENT_BY_RECRUITMENT_API = 'api/user/recruitment/';
  /** API path for student get test*/
  readonly GET_TEST ='api/test_internalizacionUser/'

  /** VARIABLES */
  /** Variable that holds the list of applications */
  applications: any[] = [];

  /** Variable that signals if the current user is a recruitment partner */
  isRecruitment = false;
  /** Variable that holds the list of students of the recruitment partner */
  students = [];
  /** Variable that holds the id of the currently selcted student */
  selectedStudent = '';

  userTest:any;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId,
    private ngxSpinnerService: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user !== null && user.tipo === 'Recruitment') {
        this.isRecruitment = true;
        this.getStudents();
      } else {
        const user = JSON.parse(localStorage.getItem('user'));
        const id = user._id;
        this.getTestInternationalization({userId:id})
        this.getApplications({id});
      }

    }
  }

  /** Retrieve the list of students of the recruitment partner */
  getStudents(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const id = user._id;
    this.apiService.get({api: this.STUDENT_BY_RECRUITMENT_API + id}).subscribe((response) => {
      this.students = response;
    }, err => {
      this.alertService.showError({msg: 'There was an error loading the information, please try again later or contact support'});
    }, (complete?) => {

    });
  }

  /** Retrieves the list of applications of the student from the backend
   * @id id of the student
   */
  getApplications({id}: {id: string}): void {
    this.ngxSpinnerService.show();
    this.apiService
      .get({ api: this.APPLICATIONS_BY_STUDENT_API + id })
      .subscribe(
        (response) => {
          //  
          const applicationsFormatted = [];
          const today = new Date();
          for (const application of response) {
            let daysLeft;
            if (
              application.fechaProximoPaso !== null &&
              application.fechaProximoPaso !== undefined
            ) {
              // Calculate the difference between the 2 dates
              const nextStepDate = new Date(application.fechaProximoPaso);
              const difference = nextStepDate.valueOf() - today.valueOf();
              // Convert milliseconds to hours
              daysLeft = Math.round(difference / (24 * 60 * 60 * 1000));
            }
            if(application.programa){
               
              let afinity;
              let probability;
              if(this.userTest && (this.userTest.puntaje_general!="0" && this.userTest.puntaje_general!=0)){
                afinity= (Math.abs(this.userTest.puntaje_general-application.programa.universidad.indiceCompetencias)-1).toFixed(2);
                probability=(application.programa.universidad?.indiceCompetencias*100)
              }else{
                afinity='?'
                probability='?'
              }
              
              //  
              const app = {
                id: application._id,
                programName: application.programa.nombre,
                certification: application.programa.acreditacion,
                tuitionCost: application.programa.yearTuition || application.programa.programTuition || application.programa.semesterTution,
                applicationCost: application.programa.universidad?.applicationFee,
                university: application.programa.universidad?.nombre,
                universityLogo: application.programa.universidad?.logo,
                idCity:application.programa.universidad?.ciudad._id,
                city: application.programa.universidad?.ciudad.nombre,
                country: application.programa.universidad?.ciudad.pais.nombre,
                countryCurrency:
                  application.programa.universidad?.ciudad.pais.moneda,
                countryImg: application.programa.universidad?.ciudad.pais.icono,
                daysLeft,
                currentStep: application.pasoActual,
                afinity: afinity,
                probability:probability ,
                slug:application.programa?.slug
              };
              applicationsFormatted.push(app);
            }
          }
          this.applications = applicationsFormatted;
          //  
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('My-applications.error-load'),
          });
        },
        (complete?) => {
          this.ngxSpinnerService.hide();
        }
      );
  }

  /**
   * Handles changes of the student selector when the user is a recruitment partner
   */
  onChangeStudent({event}: {event: string}): void {
    this.getApplications({id: event});
  }

  getTestInternationalization({userId}){
    this.apiService.get({api:this.GET_TEST+userId}).subscribe(
      (response)=>{
        this.userTest=response
      }
    )
  }
}
