import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-page-one',
  templateUrl: './pageOne.component.html',
  styleUrls: ['./pageOne.component.scss'],
})
export class PageOneComponent implements OnInit {
  @Input() form: FormGroup;
  age: any;

  ngOnInit(): void {
    const birthDateform = this.form.get('birthDate');

    this.age = this.calculateAge(birthDateform.value);
  }

  calculateAge(dateString: string): number | null {
    if (!dateString) return null;

    const birthDate = new Date(dateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
