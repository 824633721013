<div class="page-container">
  <p class="pageTitle">Datos de contacto</p>
  <p>
    Sus datos de contacto pueden ayudarnos a enviarle información sobre beneficios ofrecidos por las universidades
    aliadas.
  </p>

  <hr class="titleSeparator" />

  <app-form-input [control]="form.get('schoolEmail')" placeholder="Correo de tu colegio" type="email"></app-form-input>
  <div *ngIf="form.get('schoolEmail')?.hasError('invalidEmail') && form.get('schoolEmail')?.touched"></div>

  <app-form-input [control]="form.get('personalEmail')" placeholder="Tu correo" type="email"></app-form-input>
  <div *ngIf="form.get('personalEmail')?.hasError('invalidEmail') && form.get('personalEmail')?.touched"></div>

  <div class="form-group">
    <div class="phone-input-container">
      <label for="phone">Número de teléfono</label>
      <input
        type="number"
        id="phone"
        name="phone"
        placeholder="+57 300 1234567"
        pattern="\+?[0-9\s\-]+"
        [formControl]="form.get('verifiedPhone')"
        required />
    </div>
    <label>
      <input type="checkbox" (change)="onPhoneCheckboxChange($event)" />
      Aún no tengo
    </label>
  </div>

  <div class="form-group">
    <div class="phone-input-container">
      <label for="phone">Tu número de WhatsApp</label>
      <input
        type="number"
        id="phone"
        name="phone"
        placeholder="+57 300 1234567"
        pattern="\+?[0-9\s\-]+"
        [formControl]="form.get('verifiedWhatsApp')"
        required />
    </div>
    <label>
      <input type="checkbox" (change)="onWhatsAppCheckboxChange($event)" />
      Aún no tengo
    </label>
  </div>

  <app-form-input
    [control]="form.get('instagramProfile')"
    placeholder="Perfil de Instagram"
    type="text"></app-form-input>

  <app-form-input [control]="form.get('facebookProfile')" placeholder="Perfil de Facebook" type="text"></app-form-input>

  <app-form-input [control]="form.get('linkedin')" placeholder="Perfil de Linkedin" type="text"></app-form-input>

  <app-form-input [control]="form.get('xProfile')" placeholder="Perfil de X (Twitter)" type="text"></app-form-input>

  <app-form-input [control]="form.get('tiktokProfile')" placeholder="Perfil de Tiktok" type="text"></app-form-input>

  <div class="checkoxConsent">
    <app-form-checkbox class="checkbox" [control]="form.get('consent')"></app-form-checkbox>
    <p>
      Al marcar esta casilla verifico que el número telefónico y demás datos proporcionados son correctos, Doy mi
      consentimiento para ser contactado por universidades para recibir beneficios e información sobre mi carrera para
      tomar una mejor decisión.
    </p>
  </div>
</div>
