import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  // styleUrls: ['./autocomplete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() options: string[] = [];

  filteredOptions$: Observable<string[]>;

  ngOnInit(): void {
    this.filteredOptions$ = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map((value) => this.filterOptions(value))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
