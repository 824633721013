import { AuthService } from './../../services/auth.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, EventEmitter } from '@angular/core';
import { RecruitmentModalComponent } from './../../shared/recruitment-modal/recruitment-modal.component';
import { Options } from '@m0t0r/ngx-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ViewApplyComponent } from '../../shared/view-apply/view-apply.component';
import { CityComponent } from '../../city/city.component';
import { AuthComponent } from '../../auth/auth.component';
@Component({
  selector: 'app-program-card-universities',
  templateUrl: './program-card-universities.component.html',
  styleUrls: ['./program-card-universities.component.scss'],
})
export class ProgramCardUniversitiesComponent implements OnInit {
  /** CONSTANTS */
  /** Constant that holds the api for the user */
  readonly USER_API = 'api/user/';
  /** VARIABLES */
  /** Variable that holds the program data that is going to be displayed in the card */
  @Input() specialSearch: boolean;
  @Input() university: any;
  @Input() filtrado: boolean;
  @Input() programs: any;
  @Input() queryNumber: number;
  @Output() queryNumberModified: EventEmitter<any> = new EventEmitter();
  /** Variable that shows if the user is logged in */
  loggedIn = false;
  /** Variable an apply action was executed and requires loading */
  loading = false;
  /** Variable that holds the is logged in subscription */
  logInSubscription: Subscription;
  /** Variable that shows if the requisites button should be displayed or not */
  /** Constant that holds the api to retrieve programs */
  readonly PROGRAM_UNI_API = 'api/programa/universidad/';
  readonly PROGRAM_API = 'api/programa/';
  /** Variable that shows if the requisites button should be displayed or not */
  progrmansUniversities: any;
  @Input() showRequisitesButton: Boolean;
  // Number or programs of the university
  numPrograms: number = 0;

  @Input() userTest: any;
  classificationAfinity: any;
  afinity: any;
  probability: any;

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private ngxSpinnerService: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
        this.loggedIn = logged;
      });
    }
    this.getPrograms();
    this.addProbabilityAfinity();
  }
  /**
   * Handles the click on the Apply button
   */
  onApplyClick(): void {
    this.dialog.open(ViewApplyComponent, { data: { program: this.university } });
  }
  /**
   * Check sesion
   * Open url program.universidad.brochure
   *
   * @param {*} open URL to open
   * @memberof ProgramCardComponent
   */
  public onViewRequires(open) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user && !this.loggedIn) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      return;
    }
    window.open(open);
  }

  /** Handles multiple clicks */
  onMultipleClicksHandles({ event }: { event: any }): void {
    event.stopPropagation();
    if (event.target.classList.contains('favourite-button')) {
      this.onAddToFavourites();
    } else if (
      event.target.classList.contains('university-anchor') ||
      event.target.classList.contains('university-logo')
    ) {
      this.onClickUniversity();
    } else if (event.target.classList.contains('program-subtitle')) {
      this.onClickCity();
    } else {
      this.onCardClick();
    }
  }
  /**
   * Handles the click on the university name -> Navigates university detail
   */
  onClickUniversity(): void {
    this.navigationService.navigateTo({
      path: 'university/' + this.university.slug,
    });
  }
  /** Opens city modal information */
  onClickCity(): void {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.university.ciudad._id,
      },
    });
  }
  /**
   * Handles the click on the card --> Navigates to program detail
   */
  onCardClick(): void {
    let slug = this.university.slug;

    slug = slug.replaceAll('\n', '%0D%0A');
    this.navigationService.navigateTo({
      path: 'university/' + slug,
    });
  }

  goUniversities(id) {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.PROGRAM_UNI_API + id }).subscribe(
      (response) => {
        this.progrmansUniversities = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Programs-list.there-problem-querying'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  goProgramsU() {
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        universidad: this.university.nombre,
        minTuition: 0,
        maxTuition: 80000,
        order: 0,
        page: 1,
        coOp: false,
        name: '',
        limit: 10,
        state: 'Programs_active',
      },
    });
  }

  onAddToFavourites(): void {
    let userRole;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null) {
      userRole = user.tipo;
    }
    if (this.loggedIn && userRole && userRole !== 'Recruitment') {
      const user = JSON.parse(localStorage.getItem('user'));
      const data = {
        universidad: this.university._id,
        id: user._id,
      };
      if (this.university.inFavourites === true) {
        // If already is in favourites, remove from favourites
        this.apiService.post({ api: this.USER_API + 'remove-favourite-universities', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: 'Removed from favourites' });
            // Update favourites list in the local storage to keep the information consistent
            let favoritos = JSON.parse(localStorage.getItem('UniFavoritas'));
            favoritos = favoritos.filter((pId) => pId !== data.universidad);
            localStorage.setItem('UniFavoritas', JSON.stringify(favoritos));
            this.university.inFavourites = false;
          },
          (err) => {
            this.alertService.showError({
              msg: this.translate.instant('Programs-list.could-not-remove'),
            });
          }
        );
      } else {
        // If not, add to favourites
        this.apiService.post({ api: this.USER_API + 'post-favourite-universities', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: 'Added to favourites' });
            // Update favourites list in the local storage to keep the information consistent
            let favoritos = JSON.parse(localStorage.getItem('UniFavoritas'));
            favoritos.push(data.universidad);
            localStorage.setItem('UniFavoritas', JSON.stringify(favoritos));
            this.university.inFavourites = true;
          },
          (err) => {
            this.alertService.showError({ msg: this.translate.instant('Programs-list.could-not-add') });
          }
        );
      }
    } else if (this.loggedIn && userRole && userRole === 'Recruitment') {
      this.dialog.open(RecruitmentModalComponent, {
        data: { recruitmentAction: 'favourites', programId: this.university._id },
      });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }

  // Gets the number of programs from the university
  getPrograms() {
    // Query to send to the backend
    let query = {
      page: 1,
      limit: 100000,
      universidad: this.university.nombre,
    };
    // Sends the query to the backend
    this.apiService.post({ api: this.PROGRAM_API + 'search/', data: query }).subscribe(
      (response) => {
        // Updates number of programs
        if (response.totalDocs === 0) {
          // eliminates the universities that doesn't match any program
          let programaBusqueda = this.programs.find((program) => {
            return program._id === this.university._id;
          });
          let programaPosicion = this.programs.indexOf(programaBusqueda);
          this.programs.splice(programaPosicion, 1);
          this.programs.push(programaBusqueda);
          this.queryNumber = this.programs.length;
          this.queryNumberModified.emit(this.queryNumber);
        } else {
          this.numPrograms = response.totalDocs;
        }
      },
      (err) => {}
    );
  }

  addProbabilityAfinity() {
    if (this.university && this.userTest) {
      // puntaje general is comming as a number not a percentage i.e: 80 instead of 0.8
      // promedio ajuste does come as percentage i.e 0.8, so we need to divide puntajeGeneral / 100
      const puntajeGeneral = parseFloat(this.userTest.puntaje_general) / 100;
      const promedioAjuste = parseFloat(this.university?.PromAjuste);

      if (puntajeGeneral > 0) {
        this.afinity = (
          Math.abs(1 - (Math.max(puntajeGeneral, promedioAjuste) - Math.min(puntajeGeneral, promedioAjuste))) * 100
        ).toFixed(2);

        switch (true) {
          case this.afinity >= 0 && this.afinity <= 70:
            this.classificationAfinity = 'Muy baja';
            break;
          case this.afinity > 70 && this.afinity <= 80:
            this.classificationAfinity = 'Baja';
            break;
          case this.afinity > 80 && this.afinity <= 90:
            this.classificationAfinity = 'Media';
            break;
          case this.afinity > 90 && this.afinity <= 95:
            this.classificationAfinity = 'Alta';
            break;
          case this.afinity > 95 && this.afinity <= 100:
            this.classificationAfinity = 'Muy alta';
            break;
          default:
            this.classificationAfinity = 'No definida';
        }

        if (puntajeGeneral !== 0) {
          this.classificationAfinity += ' (' + this.afinity + ')%';
        }
      } else {
        this.classificationAfinity = 'No definida';
      }
    }
  }
}
