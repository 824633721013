<div class="page-container">
  <p class="pageTitle">Elección de carrera</p>
  <p>
    Esta información nos sirve para que puedas recibir actualizaciones sobre temas de interés asociados a tu posible
    elección de carrera, así cómo becas, y beneficios únicos para ti.
  </p>
  <hr class="titleSeparator" />
  <p class="pageSubtitle">Está interesado en estudiar principalmente en:</p>
  <mat-radio-group (change)="onStudyLocationChange($event.value)" [formControl]="form.get('studyInterest')">
    <mat-radio-button value="El exterior">Estudiar en el exterior</mat-radio-button>
    <mat-radio-button value="Mi pais">Estudiar en mi país</mat-radio-button>
    <mat-radio-button value="Aun no lo se">Aún no lo sé</mat-radio-button>
  </mat-radio-group>

  <div *ngIf="showDropdown1">
    <div class="universities-container">
      <p class="pageSubtitle">Elige máximo 5 universidades en Colombia dónde estudiarías</p>
      <div *ngFor="let control of colombianUniversities.controls">
        <app-autocomplete
          [control]="control"
          [placeholder]="'Universidades colombianas'"
          [options]="universitiesOfColombia"></app-autocomplete>
      </div>
    </div>
    <div class="universities-container">
      <div>
        <p class="pageSubtitle">Ciudad principal donde te gustaría estudiar:</p>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-select [formControl]="form.get('primaryCity')">
            <mat-option *ngFor="let item of citiesOfColombia" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <p class="pageSubtitle">Ciudad secundaria donde te gustaría estudiar:</p>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-select [formControl]="form.get('secondaryCity')">
            <mat-option *ngFor="let item of citiesOfColombia" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="showDropdown2">
    <p class="pageSubtitle">Elige hasta 3 países dónde estudiarías</p>
    <div *ngFor="let control of abroadCountries.controls">
      <app-autocomplete [control]="control" [placeholder]="'Países'" [options]="abroadUniversities"></app-autocomplete>
    </div>
  </div>

  <div class="form-group whatToStudy">
    <p class="pageSubtitle">¿Qué te gustaría estudiar?</p>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-select
        [formControl]="form.get('careerInterest')"
        multiple
        (selectionChange)="onCareerInterestChange($event)">
        <mat-option *ngFor="let item of disciplinesList" [value]="item.value">
          {{ item.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <p class="pageSubtitle">Fecha estimada de ingreso a universidad</p>
  <app-date-input
    [control]="form.get('estimatedStartDate')"
    label="Fecha estimada de ingreso a universidad"></app-date-input>
</div>
