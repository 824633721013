import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";


@Injectable({ providedIn: 'root' })
export class ProgramResolver implements Resolve<any> {

    //Ruta del API del detalle del programa
    readonly PROGRAM_API_SLUG = 'api/programa/slug/';

    constructor(private service: ApiService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.service.get({ api: this.PROGRAM_API_SLUG + route.paramMap.get('slug') });
    }
}