<div class="search-bar">
  <div class="row">
      <div class="col dropdown-container">
          <ng-multiselect-dropdown id="location" [settings]="settingsA" [data]="countriesDropdownList" [(ngModel)]="location"
              [placeholder]="'Home.where-to-study'|translate">
          </ng-multiselect-dropdown>
      </div>
      <div class="col dropdown-container">
          <ng-multiselect-dropdown id="discipline" [settings]="settingsA" [data]="disciplineDropdownList"
              [(ngModel)]="discipline" [placeholder]="'Home.what-to-study'|translate">
          </ng-multiselect-dropdown>
      </div>
      <div class="col dropdown-container">
          <mat-icon (click)="onInfoClick()" class="info-icon">info</mat-icon>
          <ng-multiselect-dropdown id="accreditation" [settings]="settingsA" [data]="accreditationDropdownList"
              [(ngModel)]="degreeLevel" [placeholder]="'Home.degree-level'|translate">
          </ng-multiselect-dropdown>
      </div>
      <div class="search-container">
          <button id="search-program" (click)="onSubmitSearch()">
              <img src="assets/utils/second-search-icon.png" alt="search-icon" alt="search">
          </button>
      </div>
  </div>
</div>
