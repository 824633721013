<div class="students">
  <div class="students-cotainer">
    <!-- <h5 class="student-title">Habla con estudiantes</h5> -->
    <div class="row">
      <div class="col-6">
        <img style="width: 64%;
        margin-left: 36%;" src="assets/hec/logos hec-02.png"/>
      </div>

      <div class="col-6">
        <p style="font-weight: bold;font-family:Montserrat ;font-size: 30px;margin-top: 9%;">
          Especialistas en educación<br>
          superior internacional
        </p>
        
        <!-- <p style="font-weight: bold;font-family:Montserrat ;font-size: 30px;margin-top: 9%;">
          superior internacional
        </p> -->
      </div>
      
    </div>
   
    <!-- <p style="font-weight: bold;">
      A través de U360 puedes agendar a nuestros estudiantes para entender todo
      su proceso y evitar todos sus errores
    </p> -->
   
    <div class="title-section">
      <label
        (click)="filterStudentsByType({ flag: 1 })"
        id="separator"
        [ngStyle]="{ color: flag === 1 ? '#545BA8' : '#434142' }"
        >Especialistas</label>
      <label
        (click)="filterStudentsByType({ flag: 2 })"
        id="separator"
        [ngStyle]="{ color: flag === 2 ? '#545BA8' : '#434142' }"
        >Couches</label>

        <label
        (click)="filterStudentsByType({ flag: 3 })"
        [ngStyle]="{ color: flag === 3 ? '#545BA8' : '#434142' }"
        >Estudiantes</label>
    </div>
    <div class="container-results" *ngIf="students">
      <div
        *ngFor="
          let student of students
            | paginate
              : {
                  itemsPerPage: programsPerPage,
                  currentPage: page,
                  totalItems: queryNumber
                }
        "
        class="container-card"
      >
        <app-card-student [student]="student"></app-card-student>
      </div>
    </div>
    <div class="row" *ngIf="queryNumber > 0">
      <div class="col-lg-12 container-pagination">
        <pagination-controls
          previousLabel="{{ 'Programs-list.previous-page' | translate }}"
          nextLabel="{{ 'Programs-list.next-page' | translate }}"
          [responsive]="true"
          (pageChange)="onPageChange($event)"
        ></pagination-controls>
        <div class="document-number-selector">
          {{ "Programs-list.per-page" | translate }}:
          <select
            id="pagiantor-select"
            [ngModel]="programsPerPage"
            (ngModelChange)="onPerPageChange($event)"
          >
            <option [value]="3">3</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
            <option [value]="50">50</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="students.length==0">
      <div class="col-lg-12">
        <img
          src="assets/utils/empty-state.png"
          class="empty-query-image"
          alt="not found"
        />
        <p class="empty-query-text">No results found</p>
      </div>
    </div>
  </div>
</div>

<app-test-section></app-test-section>
