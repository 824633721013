<div class="table-container">
  <div class="filters">
    <div class="search-bar">
      <div class="input-container">
        <input
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchTextChanged($event)"
          placeholder="Buscar estudiante"
          class="input-with-icon" />
        <button class="btn btn-primary bulkStudents" routerLink="/bulk-students">Agregar estudiantes</button>
        <button class="btn btn-primary bulkStudents" routerLink="/create-student">Agregar estudiante</button>
      </div>
    </div>
    <div class="filter-menu" (click)="closeFiltersOnOutsideClick($event)">
      <div class="buttonsFilter">
        <button (click)="toggleFilters()" class="filter-button">
          <img class="filterIcons" src="../../../assets/school-dashboard/sort.png" alt="filter icon" />
          <span class="button-text">Filtro</span>
          <img class="filterIcons" src="../../../assets/school-dashboard/expand.png" alt="filter icon" />
        </button>
        <span class="separationBar">|</span>
        <button (click)="toggleFiltersSort()" class="filter-button">
          <span class="button-icon"><i class="fas fa-sort"></i></span>
          <span class="button-text">Ordenar por</span>
          <img class="filterIcons" src="../../../assets/school-dashboard/expand.png" alt="filter icon" />
        </button>
        <span class="separationBar">|</span>
        <button (click)="resetFilters()" class="filter-button">
          <span class="button-icon"><i class="fas fa-sync-alt"></i></span>
          <span class="button-text">Limpiar Filtros</span>
        </button>
      </div>
      <div class="filter-dropdown" [ngClass]="{ open: showFilters }">
        <ul class="custom-dropdown">
          <li>
            <span>Grado:</span>
            <ul class="checkbox-list">
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['Bachillerato 9no']"
                    (change)="onGradeFilterChange()" />
                  9
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['Bachillerato 10mo']"
                    (change)="onGradeFilterChange()" />
                  10
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['Bachillerato 11vo']"
                    (change)="onGradeFilterChange()" />
                  11
                </label>
              </li>
            </ul>
          </li>
          <li class="separator"></li>
          <li>
            <span>Nivel de grado:</span>
            <ul class="checkbox-list">
              <li *ngFor="let grade of gradesAndLevels">
                <label>
                  <input type="checkbox" (change)="onGradeLevelFilterChange(grade.value)" />
                  {{ grade.value }}
                </label>
              </li>
            </ul>
          </li>
          <li class="separator"></li>
          <li>
            <span>Presentó test:</span>
            <ul class="checkbox-list">
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedTestPresented['Si']" (change)="onTestPresentedFilte()" />
                  Si
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedTestPresented['No']" (change)="onTestPresentedFilte()" />
                  No
                </label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="filter-dropdown" [ngClass]="{ open: showFilterSort }">
        <ul class="custom-dropdown">
          <ng-container *ngFor="let skill of skillOptions">
            <ul class="checkbox-list">
              <li>
                <label>
                  <input
                    type="checkbox"
                    [ngModel]="selectedSkill === skill.id"
                    (change)="onSkillFilterChange(skill.id)" />
                  {{ skill.name }}
                </label>
              </li>
            </ul>
          </ng-container>
          <li class="separator"></li>
          <div class="custom-dropdown">
            <ul class="checkbox-list">
              <span class="title-sort">Orden:</span>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedOrder['Ascendente']" (change)="onSortOrderChange()" />
                  Ascendente
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedOrder['Descendente']" (change)="onSortOrderChange()" />
                  Descendente
                </label>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <table class="table">
    <thead class="tableHeader">
      <tr>
        <th>Nombre</th>
        <th>Grado</th>
        <th>Nivel de grado</th>
        <th>Presentó Test</th>
        <th>Promedio General</th>
        <th>Liderazgo</th>
        <th>Orientación a resultados</th>
        <th>Aprendizaje continuo</th>
        <th>Impacto</th>
        <th>Global Mind</th>
        <th>Creatividad e Innovación</th>
        <th>Nivel Inglés</th>
        <th>Link a HV</th>
      </tr>
    </thead>
    <tbody class="tableBody">
      <tr *ngFor="let student of filteredStudents">
        <td>{{ addEllipsisToName(student.name, 20) }}</td>
        <td>{{ student.formatedGrade }}°</td>
        <td>{{ student.gradeLevel }}</td>
        <td>
          <span class="tag" [ngClass]="{ green: student.presentTest === 'Si', red: student.presentTest === 'No' }">
            {{ student.presentTest }}
          </span>
        </td>
        <td>{{ student.promedioGeneral }}%</td>
        <td>{{ student.Liderazgo }}%</td>
        <td>{{ student.OrientacionResultados }}%</td>
        <td>{{ student.AprendizajePermanente }}%</td>
        <td>{{ student.ImpactoResponsabilidad }}%</td>
        <td>{{ student.GlobalMind }}%</td>
        <td>{{ student.CreatividadInovacion }}%</td>
        <td>{{ student.englishLevel }}</td>
        <td>
          <ng-container *ngIf="(student.linkName && student.linkName.length === 0) || student.linkHv === null">
            <i class="fa fa-exclamation-triangle text-warning icon-exclamation" (click)="showAlert()"></i>
          </ng-container>
          <ng-container *ngIf="student.linkName && student.linkName.length > 0 && student.linkHv != null">
            <a class="button" href="{{ student.linkHv }}" target="_blank">Ver HV</a>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="horizontal-pagination">
    <span class="dubblearrow" (click)="previousPageHorizontal()" [class.disabled]="currentPageHorizontal === 0">
      <i class="fa fa-chevron-left"></i>
    </span>
    <ng-container *ngFor="let page of pageNumbers; let i = index">
      <ng-container *ngIf="pageNumbers.length <= 5">
        <span class="indexNumber" (click)="goToPageHorizontal(page)" [class.selected]="currentPageHorizontal === page">
          {{ page }}
        </span>
      </ng-container>
      <ng-container *ngIf="pageNumbers.length > 5">
        <ng-container
          *ngIf="
            i === 0 ||
            i === pageNumbers.length - 1 ||
            i === pageNumbers.indexOf(currentPageHorizontal) ||
            (i >= pageNumbers.indexOf(currentPageHorizontal) - 1 && i <= pageNumbers.indexOf(currentPageHorizontal) + 1)
          ">
          <span
            class="indexNumber"
            (click)="goToPageHorizontal(page)"
            [class.selected]="currentPageHorizontal === page">
            {{ page }}
          </span>
        </ng-container>
        <ng-container *ngIf="pageNumbers.length < 5 && i === pageNumbers.length - 1">
          <span class="indexNumber">...</span>
        </ng-container>
        <ng-container *ngIf="i === 3 && pageNumbers.length > 5">
          <span class="indexNumber">...</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <span class="dubblearrow" (click)="nextPageHorizontal()" [class.disabled]="isNextButtonDisabled()">
      <i class="fa fa-chevron-right"></i>
    </span>
  </div>
  <div class="pagination-footer">
    <p>Resultados: {{ calculateStartResult() }} - {{ calculateEndResult() }} de {{ totalStudents }}</p>
  </div>
</div>
