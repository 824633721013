import { AlertService } from './../../services/alert.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  /** CONSTANTS */
  /** Api path that handles the password changing */
  readonly CHANGE_PASSWORD_API = 'users/change_pass/';
  /** Variable that holds the change password form */
  changePasswordForm: FormGroup;
  hideOld :boolean = true;
  hideNew :boolean = true;
  hideConfirm :boolean = true;
  currentpassword:any;
  validpass:boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {userId: string, userEmail: string},
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<PasswordRecoveryComponent>,
              private apiService: ApiService,
              private alertService: AlertService,
              private translate: TranslateService,
              public authService : AuthService
              ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
    password: ['', Validators.required],
    passwordNueva: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    }, { validator: this.validateSamePasswords});
  }

  /**
   * Validates that the passwords are the same
   * @param fg form group that contains the passwords
   */
  validateSamePasswords(fg: FormGroup): ({invalid: boolean}) {
    const passwordNueva = fg.get('passwordNueva').value;
    const confirmPassword = fg.get('confirmPassword').value;
    return passwordNueva === confirmPassword ? null : {invalid: true};
  }

  /**
   * Submits the change password request to the backend
   */
  onChangePassword(): void {
    const data = this.changePasswordForm.value;
    data['email'] = this.data.userEmail;
    this.apiService.post({api: this.CHANGE_PASSWORD_API, data}).subscribe((response) => {
      if (response.success === false) {
        this.alertService.showError({msg:
          this.translate.instant("Password-recovery.change-error")});
      } else {
        this.alertService.showSuccess({msg:  this.translate.instant("Password-recovery.change-success")});
        this.dialogRef.close();
      }
    }, err => {
      this.alertService.showError({msg:  this.translate.instant("Password-recovery.change-error")});
    });
  }

  showPassword({source}){
    switch (source) {
      case 'old-password':
        this.hideOld=!this.hideOld
        let old = document.getElementById(source)
        if(!this.hideOld){
          old.removeAttribute('type')
        }else{
          old.setAttribute('type','password')
        }
        break;

      case 'new-password':
        this.hideNew=!this.hideNew
        let news = document.getElementById(source)
        if(!this.hideNew){
          news.removeAttribute('type')
        }else{
          news.setAttribute('type','password')
        }
      break;

      case 'confirm-password':
        this.hideConfirm=!this.hideConfirm
        let confirm = document.getElementById(source)
        if(!this.hideConfirm){
          confirm.removeAttribute('type')
        }else{
          confirm.setAttribute('type','password')
        }
      break;
    
      default:
        break;
    }
  }

  validatePassword(){
    let status = this.authService.authenticateUser({
      email: this.data.userEmail,
      password: this.currentpassword
    })
    status.subscribe((response)=>{
      if(response.success){
        this.validpass=true
      }else{
        this.validpass=false
      }
    })    
  }
}
