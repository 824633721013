import { Component, OnInit, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { ProgramCardUniService } from '../../services/program-card-uni.service';
@Component({
  selector: 'app-view-agencies',
  templateUrl: './view-agencies.component.html',
  styleUrls: ['./view-agencies.component.scss']
})
export class ViewAgenciesComponent implements OnInit {

  /** API for the user data */
  readonly USER_API = 'api/user/agencia/';
  readonly AGENCY_API = 'api/agencia/'
  //Program variable
  public programa: any = {};

  //user id
  public id: any = {};

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private dialogRef: MatDialogRef<ViewAgenciesComponent>,
    @Inject(PLATFORM_ID) private plaformId,
    private apiService: ApiService,
    private alertService: AlertService,
    private programCardUniService: ProgramCardUniService ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.plaformId)) {
      //The program information that reaches the modal
      this.programa = this.data;
      // Check the window
      if(this.programCardUniService.activaCardU){
        let agencias = this.programa.agencias;
        let agenciasEncontradas = [];
        if(typeof agencias[0] === 'string'){
          for(const agencia of agencias){
            this.apiService.get({ api: this.AGENCY_API + agencia}).subscribe((response) => {
              agenciasEncontradas.push(response);
              this.programa.agencias = agenciasEncontradas;
              if(agencias.length === this.programa.agencias.length){
                let agencies: string = '';
                for (let i = 0; i < this.programa.agencias.length; i++) {
                  agencies = agencies + `${i + 1}. ${this.programa.agencias[i].nombre}, `;
                }
                agencies = agencies + `/ ${this.programa.nombre}, / ${this.programa.nombre}`;
                 
                this.updatedUser({ data: { agencies }})
              }
            })
          }
        }

        // agencias.forEach(agencia => {
        //   this.apiService.get({ api: this.AGENCY_API + agencia}).subscribe((response) => {
        //     agenciasEncontradas.push(response);
        //     this.programa.agencias = agenciasEncontradas;
        //   })
        // })
      } else {
        const user = JSON.parse(localStorage.getItem('user'));
        this.id = user._id;
        if (this.programa.agencias) {
          //Agency, university and program information is filled in.
          let agencias: string = '';
          for (let i = 0; i < this.programa.agencias.length; i++) {
            agencias = agencias + `${i + 1}. ${this.programa.agencias[i].nombre}, `;
          }
          agencias = agencias + `/ ${this.programa.nombre}, / ${this.programa.nombre}`;
          this.updatedUser({ data: { agencias }})
        }
      }
    }
  }

  /**
   * Method that takes care of updating a user's agencies.
   * @param data
   */
  public updatedUser({ data }) {
    this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
      (response) => {
        if (response) {
          this.alertService.showSuccess({
            msg: "Proceso exitoso",
          });
        } else {
          this.alertService.showError({
            msg: "Error al actualizar las agencias del usuario",
          });
        }
      },
      (err) => {
        this.alertService.showError({
          msg: 'Error en el proceso',
        });
      },
    );
  }

  /**
   * The method is responsible for closing the modal
   */
  public closeModal() {
    this.dialogRef.close();
  }
}
