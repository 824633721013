<h1 class="titlePage">Planeador de experiencias.</h1>

<div class="row introductionContainer">
  <div class="col-12">
    <h4 class="titulo-wizard">Desarrolla tu plan de experiencias</h4>
    <p>
      Elabora tu plan de experiencias para el próximo año, seleccionando aquellas que te interesen y se alineen con tu
      perfil individual de habilidades y talentos. Asegúrate de completarlas con éxito para enriquecer tu desarrollo
      personal y profesional.
    </p>
    <hr class="hrLine" />
  </div>
</div>

<div class="search-container">
  <mat-form-field appearance="fill">
    <mat-label>Buscar experiencias</mat-label>
    <input
      matInput
      [(ngModel)]="searchTerm"
      (ngModelChange)="filterExperiences()"
      placeholder="Buscar experiencias por nombre" />
  </mat-form-field>
</div>

<div class="skills-container">
  <mat-form-field>
    <mat-label for="filterByCategories">Categoria de experiencia</mat-label>
    <mat-select
      id="filterByCategories"
      name="filterByCategories"
      (ngModelChange)="toggleCategorySelection($event)"
      multiple
      [(ngModel)]="selectedCategoryOption">
      <mat-option *ngFor="let option of categories" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label for="filterBySkills">Competencia principal</mat-label>
    <mat-select
      id="filterBySkills"
      name="filterBySkills"
      (ngModelChange)="toggleSkillSelection($event)"
      multiple
      [(ngModel)]="selectedSkillsOption">
      <mat-option *ngFor="let option of skills" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="experienceCardContainer">
  <div class="experienceCard">
    <div class="cardComponent" *ngIf="filteredExperiences && filteredExperiences.length > 0">
      <div class="card" *ngFor="let experience of filteredExperiences; let i = index">
        <div class="cardTitle">
          <h5>{{ experience.experience }}</h5>
        </div>
        <div class="cardBody">
          <div [innerHTML]="decodeEntities(trimHtmlContent(experience.experienceDescription))"></div>
          <p class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="" />
            {{ experience.principalSkill }} (Puntaje: {{ experience.skillsScore }})
            <span
              [ngClass]="{
                'low-impact': experience.skillsScore < 10,
                'intermediate-impact': experience.skillsScore >= 10 && experience.skillsScore <= 15,
                'high-impact': experience.skillsScore > 15
              }">
              {{
                experience.skillsScore < 10
                  ? 'Bajo impacto'
                  : experience.skillsScore <= 15
                  ? 'Impacto intermedio'
                  : 'Alto impacto'
              }}
            </span>
          </p>
          <p class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="" />
            {{ experience.secondarySkill }} (Puntaje: {{ experience.skillsScore / 2 }})
          </p>
          <div class="bottomSide">
            <p class="date">
              <img src="../../../assets/experiences/calendario.png" alt="" />
              Fecha de realización : {{ experience.formattedStartDate }} - {{ experience.formattedEndDate }}
            </p>
            <div class="editButton" (click)="editExperiencePlanner(experience)">Editar</div>
            <div class="experienceExample" (click)="openExperienceRegister(experience)">Registrar</div>
          </div>
        </div>
      </div>
    </div>
    <div class="experienceCard" *ngIf="experiences && experiences.length === 0">
      <p>Parece que aún no tienes experiencias planeadas. ¡Planea una!</p>
    </div>
  </div>

  <div>
    <button (click)="openExperiencePlanner()" class="addAnotherButton">
      <img src="../../../assets/experiences/more.png" alt="" />
      Planear experiencia
    </button>
  </div>
</div>
