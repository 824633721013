import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteApplicationComponent } from '../delete-application/delete-application.component';
import { NavigationService } from '../../services/navigation.service';
import { CityComponent } from '../../city/city.component';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  /** Variable that holds the application id */
  @Input() id: string;
  /** Variable that holds the program name */
  @Input() programName: string;
  /** Variable that holds the certification */
  @Input() certification: string;
  /** Variable that holds the country name */
  @Input() country: string;
  /** Variable that holds the image of the country */
  @Input() countryImg: string;
  /** Variable that holds the university name */
  @Input() university: string;
  /** Variable that holds the university logo */
  @Input() universityLogo: string;
  /** Variable that holds the country currency */
  @Input() countryCurrency: string;
  /** Variable that holds the city name */
  @Input() city: string;
  /** Variable that holds the tuition cost */
  @Input() tuitionCost: number;
  /** Variable that holds the application cost */
  @Input() applicationCost: number;
  /** Variable that holds the number of days until the application expires */
  @Input() daysLeft: number;
  /** Variable that holds the current step name */
  @Input() currentStep: string;
  /** Variable that holds the current afinity */
  @Input() afinity:any;
  /** Variable that holds the current probability */
  @Input() probability:any;
  /** Variable that holds the current slug */
  @Input() slug:any;

  @Input() idCity:any;

  @Input() fromSchool:boolean;

  constructor(
    private dialog: MatDialog,
    private navigationService: NavigationService,
    ) { }

  ngOnInit(): void {
     
  }

  onCancelClick(): void {
    this.dialog.open(DeleteApplicationComponent);
  }

  /**
   * Funcion para redirigiar a la seccion de la universidad
   */
  // onClickUniversity(): void {
  //   this.navigationService.navigateTo({
  //     path: 'university/' + this.program.universidad.slug,
  //   });
  // }

  /**
   * Funcion para redirigir a la seccion del programa
   */
  onClickProgram(): void {
    let slug = this.slug;

    slug = slug.replaceAll('\n', '%0D%0A');
    this.navigationService.navigateTo({
      path: 'program/' + slug,
    });
  }

  /** Opens city modal information */
  onClickCity(): void {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.idCity
      },
    });
  }

}
