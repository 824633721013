<main>
    <h1 class="title">Aparta tu cupo en una webinar</h1>
    <h4 class="description">Escribe tus datos para poder enviarte la información</h4>

    <div class="container-inputs">
        <div class="container-input">
            <label for="">Nombre</label> <br>
            <input type="text" placeholder="Escribe aquí tu nombre" [(ngModel)]="user.nombre">
        </div>
        <div class="container-input">
            <label for="">Correo</label> <br>
            <input type="text" placeholder="Escribe aquí tu correo" [(ngModel)]="user.email">
        </div>
    </div>

    <div class="container-btns">
        <div class="cancel" (click)="onCloseMdl()">Cancelar</div>
        <button class="add" (click)="onApplyWebinar()">Apartar cupo</button>
    </div>
</main>