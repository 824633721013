import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, Optional, PLATFORM_ID, Input } from '@angular/core';
import { RecruitmentModalComponent } from './../../shared/recruitment-modal/recruitment-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { ViewAgenciesComponent } from '../view-agencies/view-agencies.component';
import { FormContactComponent } from '../../university/form-contact/form-contact.component';
import { ProgramCardUniService } from '../../services/program-card-uni.service';
@Component({
  selector: 'app-view-apply',
  templateUrl: './view-apply.component.html',
  styleUrls: ['./view-apply.component.scss'],
})
export class ViewApplyComponent implements OnInit {
  /** CONSTANTS */
  /** Costant that holds the api for applications */
  readonly APPLICATION_API = 'api/aplicacion/';
  readonly APPLICATION_API_INFORMATION = 'api/informacion-programa/';
  public program: any = [];
  /** Variable that holds the is logged in subscription */
  logInSubscription: Subscription;
  /** Variable that shows if the user is logged in */
  loggedIn = false;
  /** Variable an apply action was executed and requires loading */
  loading = false;
  /**It's value safe the program or university of program component*/
  programCall: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private programCardUniService: ProgramCardUniService,
    @Optional() private dialogRef: MatDialogRef<ViewApplyComponent>
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.program) {
        this.program = this.data.program;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
        this.loggedIn = logged;
      });
    }
    this.programCall = this.programCardUniService.activaCardU ? this.program : this.program.universidad;
  }

  public onApply() {
    this.authService.sitioMensaje = "Login.apply-not-logged";
    let userRole;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null) {
      userRole = user.tipo;
    }
    if (this.loggedIn && userRole && userRole !== 'Recruitment') {
      this.loading = true;
      const user = JSON.parse(localStorage.getItem('user'));
      const today = new Date();
      const data = {
        programa: this.program._id,
        estudiante: user._id,
        fechaAplicacion: today,
      };
      this.apiService.post({ api: this.APPLICATION_API, data }).subscribe(
        (response) => {
          if (response.success === true) {
            const applicationId = response.result._id;
            this.alertService.showSuccess({ msg: this.translate.instant('Global.the-change-been')});
            this.navigationService.navigateTo({
              path: 'application/' + applicationId,
            });
          } else {
            this.alertService.showError({ msg: response.msg });
            this.navigationService.navigateTo({
              path: 'application/' + response.appId,
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Programs-list.there-already-have'),
          });
        },
        (complete?) => {
          this.loading = false;
          this.dialogRef.close();
        }
      );
    } else if (this.loggedIn && userRole && userRole === 'Recruitment') {
      this.dialog.open(RecruitmentModalComponent, {
        data: { recruitmentAction: 'apply', programId: this.program._id },
      });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }

  /**
   * Send more information of the current user
   *
   * @memberof ViewApplyComponent
   */
  // public async onWantInformation() {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   // CHECK SESION
  //   if (!user && !this.loggedIn) {
  //     this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
  //     return;
  //   }
  //   try {
  //     let createInformation = {
  //       user: user._id,
  //       universidad: this.program.universidad._id,
  //       programa: this.program._id,
  //     };
  //     let responCreate: any = await this.apiService.postPromise({
  //       api: this.APPLICATION_API_INFORMATION,
  //       data: createInformation,
  //     });
  //     this.dialogRef.close();
  //     if (responCreate && responCreate.success) {
  //       this.alertService.showSuccess({
  //         msg: this.translate.instant(
  //           'Programs-list.success-create-information'
  //         ),
  //       });
  //     } else {
  //       this.alertService.showError({
  //         msg: this.translate.instant(
  //           'Programs-list.success-create-information-err'
  //         ),
  //       });
  //     }
  //   } catch (err) {
  //     console.error(`Error al crear la petición de información ${err}`);
  //     this.alertService.showError({
  //       msg: this.translate.instant(
  //         'Programs-list.success-create-information-err'
  //       ),
  //     });
  //     this.dialogRef.close();
  //   }
  // }

  public onWantInformationForm() {
    this.dialogRef.close();
    if (this.programCall) {
      this.dialog.open(FormContactComponent, { data: this.programCall });
    }
  }

  public onWantAnAgency() {
    const user = JSON.parse(localStorage.getItem('user'));
    // CHECK SESION
    if (!user && !this.loggedIn) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      return;
    }
    this.dialogRef.close();
    if (this.programCall?.agencias) {
      this.dialog.open(ViewAgenciesComponent, { data: this.programCall });
    } else {
      this.alertService.showError({ msg: this.translate.instant('Programs-list.no-agencies') });
    }
  }
}
