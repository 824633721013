<div class="cultural-match">
  <div class="cultural-match-header">
    <img
      (click)="onBackClick()"
      class="back-button"
      src="assets/utils/back.png"
      alt="go back"
    />
    <h3 class="cultural-match-title">
      {{ "Cultural-match.cultural-match" | translate }}
    </h3>
    <p class="cultural-match-subtitle">
      {{ "Item-cultural.select-all-the-item" | translate }}
    </p>
  </div>
  <div class="student-selector" *ngIf="isRecruitment">
    <p class="cultural-match-subtitle">
      {{ "Selecciona un estudiante" }}
    </p>
    <select [(ngModel)]="student" class="student-select">
      <option value="0" disabled selected>
        {{ "Seleccionar estudiante" }}
      </option>
      <option *ngFor="let s of students" [value]="s._id">
        {{ s.nombre }} {{ s.apellido }}
      </option>
    </select>
  </div>
  <div
    class="cultural-match-questions"
    *ngIf="(isRecruitment && student) || !isRecruitment"
  >
    <div
      (click)="onSelectClick({ index: i })"
      class="questions"
      *ngFor="let question of questions; index as i"
      [ngClass]="question.selected ? 'question-selected' : ''"
    >
      <img
        *ngIf="question.selected"
        class="checked-image"
        src="assets/utils/white-success.png"
        alt="success"
      />
      <!--<img
        class="cultural-item-image"
        [src]="
          question.selected === false ? question.imagen : question.imagenActivo
        "
        alt="question selected"
      />-->
      <img
      *ngIf="!question.selected"
        class="cultural-item-image"
        [src]="question.imagen"
        alt="question selected"
      />
      <p class="question-text">
        {{ question.nombre }}
      </p>
    </div>
  </div>
  <div class="cultural-match-buttons">
    <button (click)="onClearSelections()" id="clear-selection-button">
      {{ "Item-cultural.clear-selections" | translate }}
    </button>
    <button (click)="onSubmitTest()" id="submit-button">
      {{ "Item-cultural.submit-test" | translate }}
    </button>
  </div>
</div>
