<div class="match-card">
  <img class="position-image" src="assets/utils/medalla-oro.png" *ngIf="index === 0" alt="gold">
  <img class="position-image" src="assets/utils/medalla-plata.png" *ngIf="index === 1" alt="silver">
  <img class="position-image" src="assets/utils/medalla-bronce.png" *ngIf="index === 2" alt="bronze">
  <div class="match-card-header">
    <h4 class="match-card-title"> {{ "Cultural-match-card.recommendation" | translate }} </h4>
  </div>
  <div class="destiny-data">
    <h5 class="match-card-subtitle"> {{ "Cultural-match-card.destiny" | translate }} </h5>
    <div class="country">
      <img class="country-image" [src]="matchData.countryImage" alt="{{matchData.countryName}}">
      {{matchData.countryName}}
    </div>
  </div>
  <div class="city-data">
    <h5 class="match-card-subtitle">{{ "Cultural-match.cultural-match" | translate }} </h5>
    <div class="city">
      <a (click)="showCity()">{{matchData.cityName}}</a>
    </div>
  </div>
  <div class="match-card-actions">
    <button (click)="onViewProgramsClick()" id="view-programs"> {{ "Cultural-match-card.view-programs" | translate }}
    </button>
  </div>
</div>
