import { ChangeDetectorRef, DoCheck, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationTermsConditionsComponent } from '../../../application-terms-conditions/application-terms-conditions.component';

@Component({
  selector: 'app-elegibility',
  templateUrl: './elegibility.component.html',
  styleUrls: ['./elegibility.component.scss'],
})
export class ElegibilityComponent implements OnInit, OnChanges {
  @Input() stepData: any;

  @Output() updateApplication = new EventEmitter<any>();

  /** Variable that holds the consolidated data of the steps */
  elibigility = {
    completado: false,
    fechaMaxima: '',
    fechaCompletado: '',
    terminosAceptados: false,
  };

  /** Variable that holds if user can edit */
  cantEdit = false;

  constructor(private cd: ChangeDetectorRef, private dialog: MatDialog, private ngxSpinnerService: NgxSpinnerService) {}

  ngOnInit(): void {
    if (this.stepData !== undefined) {
      this.elibigility.completado = this.stepData.completado;
      this.elibigility.terminosAceptados = this.stepData.terminosAceptados || false;
    }
  }

  ngOnChanges(newValue): void {
    if (this.elibigility.completado !== newValue.stepData.currentValue.completado) {
      this.elibigility.completado = newValue.stepData.currentValue.completado;
    }

    if (newValue.stepData.currentValue.terminosAceptados) {
      this.cantEdit = true;
    } else {
      this.cantEdit = false;
    }

    if (this.elibigility.terminosAceptados !== newValue.stepData.currentValue.terminosAceptados) {
      this.elibigility.terminosAceptados = newValue.stepData.currentValue.terminosAceptados;
    }
  }

  /**
   * Opens modal terms
   */
  openTerms(): void {
    this.dialog.open(ApplicationTermsConditionsComponent, {
      maxHeight: '500px',
    });
  }

  /**
   * Detects when user changes checkbox value
   */
  onChangeCheckbox(event): void {
    if (this.cantEdit === false) {
      this.ngxSpinnerService.show();
      this.elibigility.terminosAceptados === true ? (this.cantEdit = true) : (this.cantEdit = false);
      this.updateApplication.emit({ data: this.elibigility });
      this.ngxSpinnerService.hide();
    }
  }
}
