<div class="modal-container">
    <div class="allAgencies">
        <div class="agency-container" *ngFor="let agencia of programa.agencias">
            <img class="agency-logo" src="{{agencia.logo}}" alt="agencia-logo">
            <span>{{agencia.nombre}}</span>
        </div>
    </div>
    <div class="contact">
        <p class="contact__text">Gracias por tu interés en encontrar una agencia avalada por la universidad; te contactaremos para informarte las formas en que podemos acompañar tu proceso.</p>
    </div>
    <div class="buttons">
        <button (click)="closeModal()">Cerrar</button>
    </div>
</div>
