import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ExperienceRecordModalComponent } from './experienceRecordModal/experiencesRecordModal.component';
import { SimulationChartComponentViewModel } from '../simulationChartViewModel/simulationChartViewModel.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-experience-planner',
  templateUrl: './experiencesRecord.component.html',
  styleUrls: ['./experiencesRecord.component.scss'],
})
export class ExperienceRecordComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  private subscription: Subscription;
  readonly experiencesApi = 'api/getExperience/';
  readonly skillsApi = 'api/getSkill/';
  readonly plannedExperiences = 'api/experiences/';
  readonly categoriesApi = 'api/getCategory/';
  experiences: any;
  skills: any;
  selectedExperience: any;
  selectedSkills: string[] = [];
  filteredExperiences: any[] = [];
  selectedSkill: string;
  skill: any;
  formatedExperiences: any;
  getSupports: any;
  supportsArray = [];
  incomingSupports: any;
  selectedFileTypes: any[] = [];
  files: File[] = [];
  experienceSupports: any[];
  supports: {};
  linksData: any;
  typeSupport: any[] = [];
  filteredExperiencesByUser: any;
  selectedCategory: string;
  selectedSkillsOption: any;
  selectedCategoryOption: any;
  categories: any;
  studentId: any;
  searchTerm: string = '';

  constructor(private apiService: ApiService, private dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.studentId = params.get('id');
    });
    this.getSkills();
    this.getRecordedExperiences();
    this.getCategories();
    this.route.queryParams.subscribe((params) => {
      this.skill = params['skill'];
      if (this.skill) {
        this.toggleSkillSelection(this.skill);
      }
    });
  }

  getRecordedExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.plannedExperiences }).subscribe(
        (response) => {
          const userId = this.user?._id || this.studentId;
          this.filteredExperiencesByUser = response.filter((experience) => experience.user === userId);

          if (this.filteredExperiencesByUser) {
            this.experiences = this.filteredExperiencesByUser.map((experience) => {
              return {
                ...experience,
                formattedStartDate: formatDate(experience.startDate, 'dd/MM/yyyy', 'en-US'),
                formattedEndDate: formatDate(experience.endDate, 'dd/MM/yyyy', 'en-US'),
              };
            });

            this.filterExperiences();
            this.sortExperiencesBySkillsScore();
            Swal.close();
          }
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias registradas ',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  sortExperiencesBySkillsScore() {
    this.filteredExperiences.sort((a, b) => {
      return b.skillsScore - a.skillsScore;
    });
  }

  getCategories() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.categoriesApi }).subscribe(
        (response) => {
          this.categories = response.map((item) => item.categoryName);
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  async editExperienceRecorded(experienceData: any): Promise<void> {
    const supportsLinks = await this.getSupportsLinks(experienceData._id);
    experienceData.supportsLinks = supportsLinks;
    experienceData.isEdit = true;
    experienceData.isApproval = false;
    const dialogRef = this.dialog.open(ExperienceRecordModalComponent, {
      width: '50vw',
      height: '95vh',
      data: { experienceData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getRecordedExperiences();
    });
  }
  async openSimulationChart(simulationChartData: any): Promise<void> {
    const dialogRef = this.dialog.open(SimulationChartComponentViewModel, {
      width: '50vw',
      height: '95vh',
      data: { experienceData: simulationChartData },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getRecordedExperiences();
    });
  }

  getSupportsLinks(experienceID): Promise<any> {
    return new Promise((resolve, reject) => {
      const linkData = {
        api: `api/experienceSupports/${experienceID}`,
      };

      this.apiService.get(linkData).subscribe(
        (response) => {
          const linksData = [];

          for (const item of response) {
            linksData.push(item.experienceSupports);
          }

          resolve({ linksData });
        },
        (error) => {
          console.error('Error retrieving the data:', error);
          reject(error);
        }
      );
    });
  }

  recordExperience(): void {
    const dialogRef = this.dialog.open(ExperienceRecordModalComponent, {
      width: '50vw',
      height: '95vh',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getRecordedExperiences();
    });
  }

  toggleCategorySelection(selectedCategories: string[]) {
    this.selectedCategoryOption = selectedCategories;

    if (selectedCategories.length === 0) {
      this.filteredExperiences = this.experiences;
    } else {
      this.filteredExperiences = this.experiences.filter((experience) => {
        if (experience.experienceCategory && experience.experienceCategory.length > 0) {
          return experience.experienceCategory.some((category) => selectedCategories.includes(category));
        } else {
          return false;
        }
      });
    }

    this.filterExperiences();
  }

  toggleSkillSelection(selectedSkills: string[]) {
    this.selectedSkillsOption = selectedSkills;

    this.filterExperiences();
  }

  filterExperiences() {
    const selectedCategoriesLength = this.selectedCategoryOption?.length ?? 0;
    const selectedSkillsLength = this.selectedSkillsOption?.length ?? 0;
    const searchTerm = this.searchTerm.toLowerCase();

    if (selectedCategoriesLength === 0 && selectedSkillsLength === 0 && !searchTerm) {
      this.filteredExperiences = this.experiences;
    } else {
      this.filteredExperiences = this.experiences.filter((experience) => {
        const categoryMatch =
          selectedCategoriesLength === 0 ||
          (experience.experienceCategory &&
            experience.experienceCategory.some((category) => this.selectedCategoryOption.includes(category)));

        const skillMatch =
          selectedSkillsLength === 0 ||
          this.selectedSkillsOption.some((skill) => {
            const skillsArray = [experience.principalSkill, experience.secondarySkill];
            return skillsArray.includes(skill);
          });

        const searchTermMatch = !searchTerm || experience.experienceName.toLowerCase().includes(searchTerm);

        return categoryMatch && skillMatch && searchTermMatch;
      });
    }
    this.sortExperiencesBySkillsScore();
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsApi }).subscribe(
        (response) => {
          this.skills = response.map((item) => item.skillName);
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  trimHtmlContent(content: string): string {
    const maxLength = 270;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '...';
    }
  }
}
