import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/public/services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ShowMoreComponent } from 'src/app/public/showMoreComponent/showMoreComponent.component';

@Component({
  selector: 'app-universityType',
  templateUrl: './universityType.component.html',
  styleUrls: ['./universityType.component.scss'],
})
export class UniversityTypeComponent implements OnInit {
  showMessage: string;
  public showDiv: boolean = false;
  selectedInstitution: any;
  selectedInstitutionType: string = '';
  user = JSON.parse(localStorage.getItem('user'));
  readonly USER_API = 'api/user/';
  readonly getUser = 'api/oneUser/';
  readonly getTest03 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest03 = 'api/get_internalizacionUser/';
  readonly institutionsApi = 'api/getInstitutionOptions/';
  institutions: any;
  checkboxVisibility: boolean[] = [];
  institutionVideo: 'https://www.youtube.com/embed/AFa1sIcfWh8';
  constructor(
    public domSanitizer: DomSanitizer,
    private apiService: ApiService,
    private router: Router,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getInstitutions();
  }

  getInstitutions() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.institutionsApi }).subscribe(
        (response) => {
          this.institutions = response;
          const selectedInstitutionType = response.filter(
            (institution) => institution.institutionOptionName === this.user.institutionType
          );
          if (this.user.institutionType) {
            this.toggleInstitution(selectedInstitutionType[0]);
          }

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error al cargar las competencias!',
          });

          console.error('Error fetching institutions:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  toggleInstitution(item: any) {
    item.selected = true;
    if (item.selected) {
      this.institutions.forEach((i) => (i.selected = false));
      item.selected = true;
      this.selectedInstitutionType = item.institutionOptionName;
    } else {
      item.selected = false;
      this.selectedInstitutionType = '';
    }
  }

  openShowMoreComponent(experienceData: any): void {
    experienceData.name = experienceData.institutionOptionName;
    const modifiedExperienceData = experienceData;

    const dialogRef = this.dialog.open(ShowMoreComponent, {
      width: '50vw',
      height: '95vh',
      data: { modifiedExperienceData },
    });
  }
  selectSkill(skill: any) {
    this.router.navigate(['/experience-example'], { queryParams: { skill: skill } });
  }

  showDetail(index: number) {
    const currentSkill = this.institutions[index];

    if (this.selectedInstitution === currentSkill) {
      this.closeSkill();
    } else {
      if (this.selectedInstitution) {
        this.closeSkill();
      }

      currentSkill.showDetails = true;
      this.selectedInstitution = currentSkill;
      this.showDiv = true;
    }
  }

  extractVideoId(url: string): string {
    const regex = /[?&]v=([^?&]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('No se pudo extraer el ID del video de YouTube desde la URL:', url);
      return '';
    }
  }

  getSafeVideoUrl(videoUrl: string): SafeResourceUrl {
    const videoId = this.extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return this.domSanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  onSubmit() {
    const data = {
      institutionType: this.selectedInstitutionType,
    };

    this.apiService.put({ api: this.USER_API + this.user._id, data }).subscribe(
      (response) => {
        this.alertService.showSuccess({
          msg: 'Se han actualizado los datos correctamente',
        });
        this.router.navigate(['/skill-dashboard']);
      },
      (err) => {
        this.alertService.showError({
          msg: 'Hubo error actualizando los datos',
        });
      }
    );
  }

  closeSkill() {
    this.selectedInstitution.showDetails = false;
    this.selectedInstitution = null;
    this.showDiv = false;
  }
}
