<div class="row backgroundSkillDetail">
  <div class="col-xl-1">
    <label (click)="returnDashBoard()">
      <img class="back-img" src="/assets/wizard-images/back.png" alt="volver" />
    </label>
  </div>
  <div class="row col-xl-11" *ngIf="selectedSkill">
    <div class="col-xl-12">
      <h3 class="evaluated-profile">{{ contentCompetition.title }}</h3>
    </div>
    <div class="col-xl-12" [ngClass]="{ 'config-progress': score < desirableScore }">
      <span class="reached">Alcanzado {{ score }}%</span>
      <progress
        class="progressBar"
        [value]="score"
        [max]="desirableScore"
        style="vertical-align: revert !important"></progress>
      <span class="desired">{{ desirableScore }}% Deseable</span>
    </div>
    <div class="col-xl-12" style="margin-top: 2%">
      <p class="skill-description">
        {{ description }}
      </p>
    </div>
    <div class="col-xl-12" style="margin-top: 2%">
      <p class="skill-description">
        {{ proficiencyDescription }}
      </p>
    </div>

    <div class="col-xl-12" *ngIf="filteredQuestions.length > 0">
      <div class="section-divider">
        <h4 class="titleAnalysis">Hallazgos y Análisis</h4>
      </div>
      <div class="table-responsive">
        <table class="table custom-table2">
          <thead>
            <tr>
              <th class="center-text">Preguntas</th>
              <th class="center-text">Respuestas</th>
              <th class="center-text">Análisis</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let question of filteredQuestions">
              <td>{{ question.question }}</td>
              <td>{{ question.answer }}</td>
              <td>{{ question.analysis }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-1"></div>
    </div>
  </div>
</div>
