import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(@Inject(PLATFORM_ID) private platformId, private notificationService: NotificationsService) {}

  /**
   * Displays error message if the app is being executed in the browser
   * @param msg: Alert message
   */
  showError({ msg }: { msg: string }): void {
    if (isPlatformBrowser(this.platformId)) {
      this.notificationService.error('Error', msg, { clickToClose: true, timeOut: 5000 });
    }
  }

  /**
   * Displays success message if the app is being executed in the browser
   * @param msg: Success message
   */
  showSuccess({ msg }: { msg: string }): void {
    let title = window.navigator.language.toLocaleLowerCase().includes('es') ? 'Éxito' : 'Success';
    if (isPlatformBrowser(this.platformId)) {
      this.notificationService.success(title, msg, { clickToClose: true, timeOut: 5000 });
    }
  }

  /**
   * Displays warning message if the app is being executed in the browser
   * @param msg: Warning message
   */
  showWarning({ msg }: { msg: string }): void {
    let title = window.navigator.language.toLocaleLowerCase().includes('es') ? 'Advertencia' : 'Warning';
    if (isPlatformBrowser(this.platformId)) {
      this.notificationService.success(title, msg, { clickToClose: true, timeOut: 5000 });
    }
  }
}
