<div class="container pt-4">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Simulación de Progreso Total y por Competencia:</h4>
      <p>
        En esta sección verás reflejado tu avance real general y por competencias teniendo en cuenta experiencias
        certificadas y aprobadas.
      </p>
      <hr class="hrLine" />
    </div>
  </div>
  <div id="legend-container">
    <div class="legend-item principiante">
      <span class="legend-color"></span>
      Principiante
    </div>
    <div class="legend-item intermedio">
      <span class="legend-color"></span>
      Intermedio
    </div>
    <div class="legend-item avanzado">
      <span class="legend-color"></span>
      Avanzado
    </div>
    <div class="legend-item maestro">
      <span class="legend-color"></span>
      Maestro
    </div>
    <div class="legend-item visionario">
      <span class="legend-color"></span>
      Visionario
    </div>
  </div>
  <h4 class="titulo-wizard">Promedio General</h4>
  <app-simulation-chart
    passedSkill="Desempeño Global Competencias"
    [passedGrade]="simulationChartData.experienceData.gradeOfExecution"
    [experienceId]="simulationChartData.experienceData._id"></app-simulation-chart>
  <h4 class="titulo-wizard">{{ simulationChartData.experienceData.principalSkill }}</h4>
  <app-simulation-chart
    [passedSkill]="simulationChartData.experienceData.principalSkill"
    [passedGrade]="simulationChartData.experienceData.gradeOfExecution"
    [experienceId]="simulationChartData.experienceData._id"></app-simulation-chart>
  <h4 class="titulo-wizard">{{ simulationChartData.experienceData.secondarySkill }}</h4>
  <app-simulation-chart
    [passedSkill]="simulationChartData.experienceData.secondarySkill"
    [passedGrade]="simulationChartData.experienceData.gradeOfExecution"
    [experienceId]="simulationChartData.experienceData._id"></app-simulation-chart>
</div>
