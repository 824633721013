import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-slider-modal',
  templateUrl: './image-slider-modal.component.html',
  styleUrls: ['./image-slider-modal.component.scss'],
})
export class ImageSliderModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pictures, currentIndex },
    private dialogRef: MatDialogRef<ImageSliderModalComponent>
  ) {
     
  }

  ngOnInit(): void {
  }

  /**
   * Closes self
   */
  public selfClose(): void {
    this.dialogRef.close();
  }
}
