<div class="my-applications">
  <h3 class="my-applications-header">{{ "My-applications.my-applications" | translate }} </h3>
  <select *ngIf="isRecruitment" class="select-student-input" [(ngModel)]="selectedStudent" (ngModelChange)="onChangeStudent({event: $event})">
    <option disabled value="">{{'My-applications.select-student'|translate}}</option>
    <option *ngFor="let student of students" [value]="student._id">{{student.nombre}} {{student.apellido}}</option>
  </select>
  <div class="prices-disclaimer"> {{"My-applications.prices-may-be-modifed" | translate }}</div>
  <div class="row application-card-container">
    <div class="col-12 cards" *ngFor="let application of applications">
      <app-application-card [id]="application.id" [programName]="application.programName"
        [certification]="application.certification" [country]="application.country" [city]="application.city"
        [university]="application.university" [universityLogo]="application.universityLogo"
        [countryCurrency]="application.countryCurrency" [applicationCost]="application.applicationCost"
        [tuitionCost]="application.tuitionCost" [daysLeft]="application.daysLeft"
        [currentStep]="application.currentStep" [countryImg]="application.countryImg" [afinity]="application.afinity"
        [probability]="application.probability" [slug]="application.slug" [idCity]="application.idCity"></app-application-card>
    </div>
  </div>
</div>
