<ng-template #content let-modal>
  <div class="modal-header customHeader">
    <h4 class="modalTitle">Tu experiencia</h4>
    <button *ngIf="student" type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="form">
    <div class="modal-body customBody">
      <div class="activityTypePage" *ngIf="currentStep === 1">
        <div class="experienceHeader">
          <p for="name" class="sectionTitle">Nombre de la experiencia:</p>
          <input
            class="experienceInput"
            type="text"
            id="name"
            name="experienceName"
            formControlName="experienceName"
            [disabled]="!student"
            [ngClass]="{ 'disabled-input': !student }"
            required
            [class.is-invalid]="form.controls['experienceName'].invalid && form.controls['experienceName'].touched" />
          <div
            class="invalid-feedback"
            *ngIf="form.controls['experienceName'].invalid && form.controls['experienceName'].touched">
            Nombre requerido
          </div>
        </div>
        <div class="descriptionTopSide">
          <p class="sectionTitle descriptionTitle">Describe tu experiencia:</p>
          <div>
            <div>{{ characterCount }} / {{ maxCharacters }}</div>
            <textarea
              [ngClass]="{ 'disabled-input': !student }"
              [disabled]="!student || !isInputEnabled"
              class="descriptionText"
              formControlName="experienceDescription"
              id="htmlContent"
              [config]="config"
              (input)="onTextChange($event.target.value)"></textarea>
          </div>
          <div
            class="invalid-feedback"
            *ngIf="form.controls['experienceDescription'].invalid && form.controls['experienceDescription'].touched">
            Descripción requerida.
          </div>
        </div>
        <div class="experienceInfo">
          <div class="topRowCheckboxes">
            <div class="experienceCheckBox activityTypeCheckbox">
              <p class="experienceLabel checkBoxLabel">Tipo de actividad</p>
              <mat-radio-group
                formControlName="selectedActivity"
                name="selectedActivityType"
                class="custom-mat-radio-group">
                <ng-container *ngFor="let item of activityType">
                  <mat-radio-button
                    class="radioButton"
                    [disabled]="!student"
                    [ngClass]="{ 'disabled-input': !student }"
                    [value]="item.id">
                    {{ item }}
                  </mat-radio-button>
                </ng-container>
              </mat-radio-group>
              <p
                class="error-text"
                *ngIf="form.controls['selectedActivity'].invalid && form.controls['selectedActivity'].touched">
                Tipo de actividad requerido
              </p>
            </div>
            <div class="experienceCheckBox activityTypeCheckbox">
              <p class="experienceLabel checkBoxLabel">Lugar de ejecución</p>
              <mat-radio-group
                formControlName="selectedLocation"
                name="selectedLocation"
                class="custom-mat-radio-group">
                <ng-container *ngFor="let item of location">
                  <mat-radio-button
                    class="radioButton"
                    [disabled]="!student"
                    [ngClass]="{ 'disabled-input': !student }"
                    [value]="item.id">
                    {{ item }}
                  </mat-radio-button>
                </ng-container>
              </mat-radio-group>
              <p
                class="error-text"
                *ngIf="form.controls['selectedLocation'].invalid && form.controls['selectedLocation'].touched">
                Lugar de ejecución requerido
              </p>
            </div>
            <div class="experienceCheckBox activityTypeCheckbox">
              <p class="experienceLabel checkBoxLabel">Estatus de la actividad</p>
              <mat-radio-group
                formControlName="selectedStatus"
                name="selectedStatusType"
                class="custom-mat-radio-group">
                <ng-container *ngFor="let item of status">
                  <mat-radio-button
                    class="radioButton"
                    [value]="item.id"
                    [ngClass]="{ 'disabled-input': !student }"
                    [disabled]="!student">
                    {{ item }}
                  </mat-radio-button>
                </ng-container>
              </mat-radio-group>
              <p
                class="error-text"
                *ngIf="form.controls['selectedStatus'].invalid && form.controls['selectedStatus'].touched">
                Tipo de acción requerido
              </p>
            </div>
            <div class="experienceCheckBox activityTypeCheckbox">
              <p class="experienceLabel checkBoxLabel">Forma de Ejecución</p>
              <mat-radio-group
                formControlName="selectedMethod"
                name="selectedMethodType"
                class="custom-mat-radio-group">
                <ng-container *ngFor="let item of executionMethod">
                  <mat-radio-button
                    class="radioButton"
                    [disabled]="!student"
                    [ngClass]="{ 'disabled-input': !student }"
                    [value]="item.id">
                    {{ item }}
                  </mat-radio-button>
                </ng-container>
              </mat-radio-group>
              <p
                class="error-text"
                *ngIf="form.controls['selectedMethod'].invalid && form.controls['selectedMethod'].touched">
                Tipo de actividad requerido
              </p>
            </div>
          </div>
          <div class="middleRowCheckboxes">
            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Habilidad principal</p>
              <ng-select
                [items]="skills"
                formControlName="selectedSkills"
                name="selectedSkills"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                bindLabel="name"
                bindValue="name"
                class="custom-ng-select"
                (change)="updateSecondarySkillsOptions()"
                [appendTo]="'body'">
                <ng-template ng-multi-label-tmp let-items="items">
                  <div class="ng-value itemNameWrapper">
                    <span class="itemName" [ngClass]="{ 'itemDisable': !student }">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p
                class="error-text"
                *ngIf="form.controls['selectedSkills'].invalid && form.controls['selectedSkills'].touched">
                Habilidad requerida
              </p>
            </div>
            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Habilidad secundaria</p>
              <ng-select
                [items]="availableSecondarySkills"
                formControlName="secondarySkills"
                name="secondarySkills"
                bindLabel="name"
                bindValue="name"
                class="custom-ng-select"
                [appendTo]="'body'"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                (change)="calculateTotalPoints()">
                <ng-option *ngFor="let skill of availableSecondarySkills">
                  <span [ngClass]="{ 'itemDisable': !student }">{{ skill }}</span>
                </ng-option>
              </ng-select>
              <p
                class="error-text"
                *ngIf="form.controls['secondarySkills'].invalid && form.controls['secondarySkills'].touched">
                Habilidad requerida.
              </p>
            </div>
          </div>
          <div class="bottomRowCheckboxes">
            <div class="experienceCheckBox experienceTypeCheckbox">
              <p class="experienceLabel checkBoxLabel">Tipo de experiencia</p>
              <ng-select
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                [items]="experienceType"
                formControlName="selectedExperienceType"
                name="selectedExperienceType"
                bindLabel="name"
                bindValue="id"
                class="custom-ng-select"
                [appendTo]="'body'"
                [value]="experienceType.id"
                (change)="onExperienceTypeSelected(form.value.selectedExperienceType)">
                <ng-template ng-multi-label-tmp let-items="items">
                  <div class="ng-value .ng-value .ng-star-inserted itemNameWrapper">
                    <span class="itemName" [ngClass]="{ 'itemDisable': !student }">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>

              <p
                class="error-text"
                *ngIf="form.controls['selectedExperienceType'].invalid && form.controls['selectedExperienceType'].touched">
                Tipo de experiencia requerido
              </p>
            </div>
            <div class="requirementSection" *ngIf="form.controls['selectedExperienceType'].value">
              <div class="experienceTypeMessageContainer">
                <p class="experienceTypeMessage">
                  <span class="requirementWord">Requisitos:</span>
                  {{ getSelectedExperienceTypeMessage() }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="dateSectionWrapper">
          <div class="dateSection">
            <div class="col-xs-12 col-12 col-md-4 form-group">
              <p class="experienceLabel">Fecha de inicio</p>
              <div class="dateInput" [ngClass]="{'disabled-input': form.get('initDate')?.disabled}">
                <img class="dateIcon" src="../../../assets/experiences/dateIcon.png" alt="dateIcon" />
                <input
                  name="selectedDate"
                  type="text"
                  placeholder="dd/mm/aaaa"
                  class="form-control my-datepicker"
                  bsDatepicker
                  [bsConfig]="datepickerConfig"
                  placement="top"
                  id="initDate"
                  formControlName="initDate"
                  [ngClass]="{'is-invalid': form.controls['initDate'].invalid && form.controls['initDate'].touched, 'disabled-input': !student}" />
              </div>
            </div>
            <div class="col-xs-12 col-12 col-md-4 form-group">
              <p class="experienceLabel">Fecha final</p>
              <div class="dateInput" [ngClass]="{'disabled-input': form.get('endDate')?.disabled}">
                <img class="dateIcon" src="../../../assets/experiences/dateIcon.png" alt="dateIcon" />
                <input
                  name="endDate"
                  type="text"
                  placeholder="dd/mm/aaaa"
                  class="form-control my-datepicker"
                  bsDatepicker
                  [bsConfig]="datepickerConfig"
                  id="endDate"
                  placement="top"
                  formControlName="endDate"
                  [ngClass]="{'disabled-input': form.get('endDate')?.disabled, 'is-invalid': form.controls['endDate'].invalid && form.controls['endDate'].touched}" />
              </div>
            </div>
            <div class="form-check">
              <input
                formControlName="isOngoing"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                name="onGoing"
                (change)="toggleEndDate()"
                [checked]="form.value.isOngoing"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }" />
              <label class="form-check-label ongoingCheckbox" for="flexCheckDefault">En curso</label>
            </div>
          </div>
        </div>
      </div>
      <div class="descriptionSection" *ngIf="currentStep === 2">
        <div class="descriptionDownSide">
          <p class="sectionTitle descriptionTitle">Sube tus documentos:</p>
          <div class="file-list">
            <ul class="listOfUploadedFiles">
              <li class="uploadedFiles" *ngFor="let f of files">
                <div class="fileLeftColumn">
                  <p class="fileName">{{ maxCaractersContent(f.name) }}</p>
                  <button *ngIf="student" class="deleteButton" (click)="onRemove(f)">
                    <img class="deleteImage" src="../../../assets/experiences/delete.png" alt="Eliminar" />
                  </button>
                </div>
                <div class="fileRightColumn">
                  <select class="fileOptions" (change)="onSelectFileType($event.target.value)">
                    <option value="" selected>Seleccionar tipo de archivo...</option>
                    <option *ngFor="let type of typesOfFile" [value]="type.id">{{ type.name }}</option>
                  </select>
                </div>
              </li>
              <ul class="listOfUploadedFiles">
                <li class="uploadedFiles" *ngFor="let support of incomingSupports; let i = index">
                  <div class="fileLeftColumn">
                    <a class="fileName" href="{{ support.links }}" target="_blank">
                      {{ maxCaractersContent(getFileNameFromURL(support.links)) }}
                    </a>
                    <button *ngIf="isEditMode" class="deleteButton" (click)="onRemove(support)">
                      <img class="deleteImage" src="../../../assets/experiences/delete.png" alt="Eliminar" />
                    </button>
                  </div>
                  <ul class="customList">
                    <li class="typeOfSupports">
                      <a target="_blank">{{ support.types }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
          <div
            [disabled]="!student"
            [ngClass]="{ 'disabled-input': !student }"
            class="custom-dropzone"
            ngx-dropzone
            (change)="onSelect($event)">
            <ngx-dropzone-label [disabled]="!student" [ngClass]="{ 'disabled-input': !student }">
              <div>
                <h2 class="dropzonePlaceholder">Arrastra tus archivos aqui o selecciona un archivo</h2>
              </div>
            </ngx-dropzone-label>
          </div>
        </div>
        <div class="supervisorContainer">
          <p class="sectionTitle">Informacion del supervisor:</p>
          <div class="supervisorInfo">
            <div class="supervisorDetailsContainer">
              <p for="supervisorName" class="supervisorLabels">Nombre:</p>
              <input
                class="supervisorInput"
                type="text"
                id="supervisorName"
                name="supervisorName"
                formControlName="supervisorName"
                required
                [class.is-invalid]="form.controls['supervisorName'].invalid && form.controls['supervisorName'].touched"
                [ngClass]="{ 'disabled-input': !student }" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['supervisorName'].invalid && form.controls['supervisorName'].touched">
                Nombre del supervisor requerido.
              </div>
            </div>

            <div class="supervisorDetailsContainer">
              <p for="supervisorLastName" class="supervisorLabels">Apellido:</p>
              <input
                class="supervisorInput"
                type="text"
                id="supervisorLastName"
                name="supervisorLastName"
                formControlName="supervisorLastName"
                required
                [class.is-invalid]="form.controls['supervisorLastName'].invalid && form.controls['supervisorLastName'].touched"
                [ngClass]="{ 'disabled-input': !student }" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['supervisorLastName'].invalid && form.controls['supervisorLastName'].touched">
                Apellido requerido.
              </div>
            </div>
          </div>
          <div class="supervisorInfo">
            <div class="supervisorDetailsContainer">
              <p for="supervisorEmail" class="supervisorLabels">Correo Electrónico:</p>
              <input
                formControlName="supervisorEmail"
                class="supervisorInput"
                type="text"
                id="supervisorEmail"
                name="supervisorEmail"
                required
                [class.is-invalid]="form.controls['supervisorEmail'].invalid && form.controls['supervisorEmail'].touched"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['supervisorEmail'].invalid && form.controls['supervisorEmail'].touched">
                Correo inválido.
              </div>
            </div>
            <div class="supervisorDetailsContainer">
              <p for="supervisorPhoneNumber" class="supervisorLabels">Número telefónico:</p>
              <input
                class="supervisorInput supervisorPhoneNumber"
                type="number"
                id="supervisorPhoneNumber"
                name="supervisorPhoneNumber"
                formControlName="supervisorPhoneNumber"
                required
                [class.is-invalid]="form.controls['supervisorPhoneNumber'].invalid && form.controls['supervisorPhoneNumber'].touched"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['supervisorPhoneNumber'].invalid && form.controls['supervisorPhoneNumber'].touched">
                Número requerido.
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isEditMode && formListData.approve === experienceReviewsStatus.pending">
          <div class="form-group">
            <label class="experienceLabel checkBoxLabel" for="recommendation">Recomendación</label>
            <textarea id="recommendation" formControlName="recommendation" class="form-control"></textarea>
            <div class="custom-checkbox">
              <input
                type="checkbox"
                id="requestApproval"
                class="form-check-input"
                formControlName="requestApproval"
                title="Seleccione para solicitar nueva revisión" />
              <label class="form-check-label" for="requestApproval">Seleccione para solicitar nueva revisión</label>
            </div>
          </div>
        </div>
      </div>
      <div class="recommendationSection" *ngIf="currentStep === 3 && !student">
        <div class="recommendationContainer">
          <div>
            <p class="sectionTitle descriptionTitle">Deja tu recomendación:</p>
            <textarea
              class="recommendation"
              id="recommendationTextarea"
              cols="30"
              rows="10"
              formControlName="recommendation"></textarea>
          </div>
          <div>
            <mat-radio-group formControlName="approve" name="approve" class="experienceReviewsButtons">
              <ng-container *ngFor="let item of experienceReviews">
                <mat-radio-button class="radioButton" [value]="item.id">{{ item.name }}</mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer customFooter">
      <div class="requirementMessageContainer" *ngIf="currentStep === 1 && !student">
        <p class="requirementMessage" (click)="nextStep()">Por favor, revisa toda la información de la experiencia.</p>
      </div>
      <div class="activityTypeFooter" *ngIf="currentStep === 1 && student">
        <div class="buttonWrapper">
          <button class="nextButton" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 1 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="nextStep()">
          Ver soportes
        </button>
      </div>

      <div class="activityTypeFooter" *ngIf="currentStep === 2 && student">
        <div class="buttonWrapper">
          <button class="nextButton" (click)="previousStep()">Atrás</button>
          <button type="submit" class="nextButton" (click)="onSubmit()">Finalizar</button>
        </div>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 2 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="previousStep()">Volver</button>
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="nextStep()">
          Dar recomendación
        </button>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 3 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="previousStep()">Volver</button>
        <button
          type="submit"
          class="nextButton"
          [ngClass]="{ 'approvalButtons': !student }"
          (click)="updateApprovalExperience()">
          Finalizar revisión
        </button>
      </div>
    </div>
  </form>
</ng-template>
