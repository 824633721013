<div class="header" [@fadeInOut]="isLoading ? 'out' : 'in'">
  <h1 class="titlePage" *ngIf="formList.length > 0 && loggedUserId !== null">Mis Experiencias.</h1>
  <h1 class="titlePage"
   *ngIf="loggedUserId === null && formList.length > 0">Experiencias del estudiante.</h1>
  <p *ngIf="formList.length > 0 && loggedUserId !== null" class="scoreTableDescription">
    Mis Experiencias se destacan en las siguientes habilidades:
    <br />
  </p>
  <p *ngIf="loggedUserId === null && formList.length > 0" class="scoreTableDescription">
    Lax Experiencias del estudiante se destacan en las siguientes habilidades:
    <br />
  </p>
  <div *ngIf="formList.length > 0" class="skills-container">
    <ng-container *ngFor="let skillItem of mySkills">
      <div class="skill-box">
        <div class="skill-item">{{ skillItem.skill }}</div>
        <div class="skill-item">
          <p class="skillScore">
            {{
              skillItem.score >= 90
                ? 'Master Pro'
                : skillItem.score > 80
                ? 'Master'
                : skillItem.score > 70
                ? 'Professional'
                : skillItem.score >= 60
                ? 'High'
                : skillItem.score >= 30
                ? 'Medium'
                : skillItem.score >= 0
                ? 'Low'
                : ''
            }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="centerContainer" *ngIf="loggedUserId === null && formList.length === 0">
    <div class="addExperienceBox">
      <h1 class="title">Este estudiante no tiene experiencias aún</h1>
    </div>
  </div>
  <div class="centerContainer" *ngIf="formList.length === 0 && loggedUserId !== null">
    <div class="addExperienceBox">
      <h1 class="title">No tienes</h1>
      <br />
      <h1 class="title">Experiencias</h1>
      <button (click)="openModal(content)" class="addButton">
        <img src="../../../assets/experiences/more.png" alt="" class="icon-image" />
        Crear Experiencia
      </button>
    </div>
  </div>
</div>
<div>
  <div class="experienceCard" *ngIf="formList.length > 0 && loggedUserId !== null">
    <button (click)="openModal(content)" class="addAnotherButton">
      <img src="../../../assets/experiences/more.png" alt="" />
      Crear Experiencia
    </button>
  </div>
</div>
<app-experience-modal></app-experience-modal>
<app-experience-cards [formList]="formList" [urlId]="id" *ngIf="!isLoading"></app-experience-cards>
