<main>
    <div class="col-xl-12" [innerHtml]="destinatario">
        
    </div>

    <div class="col-xl-12">
        <h2>Carta de intencion academica</h2>
    </div>
    
    <div class="col-xl-12" [innerHtml]="introduccion">
        
    </div>

    <div class="col-xl-12" [innerHtml]="antecendente">
        
    </div>

    <div class="col-xl-12" [innerHtml]="justificacion">
        
    </div>

    <div class="col-xl-12" [innerHtml]="meta">
        
    </div>

    <div class="col-xl-12" [innerHtml]="conclusion">
        
    </div>

    <div class="col-xl-12" [innerHtml]="datoPersonal">
        
    </div>
</main>
