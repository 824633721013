<div class="marketplace">
  <h3 class="marketplace-header"> {{ "Marketplace.marketplace" | translate }}</h3>
  <div class="mark-country-selector">
    <label id="country-label"> {{ "Marketplace.country" | translate }} </label>
    <select [(ngModel)]="country" (ngModelChange)="onCountryChange()"
      [disabled]="currentServiceType === 0 || currentServiceType === 4" class="marketplace-select">
      <option value="" disabled selected>{{ "Marketplace.select-country" | translate }} </option>
      <option *ngFor="let country of countries" [value]="country._id">{{country.nombre}}</option>
    </select>
  </div>
  <div class="tabs-container">
    <label class="mainTabs marginLeft0">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[0].value,
        country: this.country
      }">{{"Marketplace.translation-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 0' />
    </span>
    </label>
    <label class="mainTabs ">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[1].value,
        country: this.country
      }">{{"Marketplace.visas-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 1'/>
    </span>
    </label>
    <label class="mainTabs">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[2].value,
        country: this.country
      }">{{"Marketplace.travel-insurance-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 2'/>
    </span>
    </label>
    <!--
      <label class="mainTabs">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[3].value,
        country: this.country
      }">{{"Marketplace.scholarship-bank-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 3'/>
    </span>
    </label>
    -->
    <label class="mainTabs">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[4].value,
        country: this.country
      }">{{"Marketplace.language-test-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 4'/>
    </span>
    </label>
    <label class="mainTabs">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[5].value,
        country: this.country
      }">{{"Marketplace.vip-consultancy-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 5'/>
    </span>
    </label>
     <!--
      <label class="mainTabs">
      <span routerLink="/marketplace" [queryParams]="{
        serviceType: services[6].value,
        country: this.country
      }">{{"Marketplace.vocational-tests-name" | translate}}
      <hr class="hrTabs" *ngIf='currentServiceType === 6'/>
    </span>
    </label>
    -->
  </div>
  <div class="tabs-container" hidden>
    <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[0].value,
        country: this.country
      }">{{"Marketplace.translation-name" | translate}}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 0 ? 'border-active':''"> </div>
    </div>
    <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[1].value,
        country: this.country
      }">{{"Marketplace.visas-name" | translate}}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 1 ? 'border-active':''"> </div>
    </div>
    <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[2].value,
        country: this.country
      }">{{"Marketplace.travel-insurance-name" | translate}}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 2 ? 'border-active':''"> </div>
    </div>
    <!-- <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[3].value,
        country: this.country
      }">{{"Marketplace.scholarship-bank-name" | translate}}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 3 ? 'border-active':''"> </div>
    </div> -->
    <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[4].value,
        country: this.country
      }">{{"Marketplace.language-test-name" | translate}}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 4 ? 'border-active':''"> </div>
    </div><div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[5].value,
        country: this.country
      }">{{"Marketplace.vip-consultancy-name" | translate }}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 5 ? 'border-active':''"> </div>
    </div>
    <!-- <div class="service-btn">
      <button class="service-button" routerLink="/marketplace" [queryParams]="{
        serviceType: services[6].value,
        country: this.country
      }">{{"Marketplace.vocational-tests-name" | translate }}</button>
      <div class="tab-button-border" [ngClass]="currentServiceType === 6 ? 'border-active':''"> </div>
    </div> -->
  </div>
  <div *ngIf="currentServiceType === 0" class="translation-services-container">

    <app-translation-service-card
      *ngFor="let service of servicesData | paginate: { itemsPerPage: SERVICES_PER_PAGE, currentPage: page, totalItems: servicesNumber }"
      [id]="service._id" [provider]="service.proveedor" [docType]="service.tipoDocumento"
      [pricePerPage]="service.precioPagina" [image]="service.imagen">
    </app-translation-service-card>

  </div>
  <div *ngIf="currentServiceType === 1 || currentServiceType === 5">

    <div class="tags-container" *ngIf="tags && tags.length > 0">
      <div *ngFor="let tag of tags ; index as i" (click)="onSelectTag({tag: tag, index: i})"
        [ngClass]="tag.selected === true?'tag-pill-selected':''" class="tag-pill">
        {{ tag.name }}
      </div>
    </div>
    <div class="visa-services-container">
      <app-visa-service-card
        *ngFor="let service of servicesData | paginate: { itemsPerPage: SERVICES_PER_PAGE, currentPage: page, totalItems: servicesNumber }"
        [id]="service._id" [country]="service.pais.nombre" [cost]="service.precio" [currency]="service.pais.moneda" [name]="service.nombre"
        [image]="service.imagen" [description]="service.descripcion" [type]="service.tipo">
      </app-visa-service-card>
    </div>
  </div>
  <div *ngIf="currentServiceType === 2" class="travel-insurance-container">
    <app-travel-service-card class="marginCards"
      *ngFor="let service of servicesData | paginate: { itemsPerPage: SERVICES_PER_PAGE, currentPage: page, totalItems: servicesNumber }"
      [id]="service._id" [provider]="service.proveedor" [serviceName]="service.nombre" [paymentPlans]="service.planes"
      [currency]="service.moneda" [image]="service.imagen" [countries]="service.pais"
      [description]="service.descripcion" [file]="service.archivo"></app-travel-service-card>
  </div>
  <!-- <div *ngIf="currentServiceType === 3" class="scholarship-container">
    <app-scholarship-card
      *ngFor="let scholarship of servicesData | paginate: { itemsPerPage: SERVICES_PER_PAGE, currentPage: page, totalItems: servicesNumber }"
      [university]="scholarship.universidad.nombre" [percentage]="scholarship.porcentaje"
      [image]="scholarship.universidad.ciudad.pais.icono" [description]="scholarship.descripcion" [scholarship]="scholarship"
      [countries]="countries"></app-scholarship-card>
  </div> -->

  <div *ngIf="currentServiceType === 4 || currentServiceType === 10">
    <div class="tags-container" *ngIf="tags && tags.length > 0">
      <div *ngFor="let tag of tags ; index as i" (click)="onSelectTag({tag: tag, index: i})"
        [ngClass]="tag.selected === true?'tag-pill-selected':''" class="tag-pill">
        {{ tag.name }}
      </div>
    </div>

    <div class="language-test-container">
      <app-language-test-card
        *ngFor="let service of filterServicesData | paginate: { itemsPerPage: SERVICES_PER_PAGE, currentPage: page, totalItems: servicesNumber }"
        [id]="service._id" [name]="service.nombre" [language]="service.idioma" [image]="service.icono"
        [currency]="service.moneda" [cost]="service.precio" [provider]="service.proveedor" [type]="service.tipo">
      </app-language-test-card>

    </div>
  </div>

  <!-- <div>
    <pagination-controls [previousLabel]="'Previuos page'" [nextLabel]="'Next page'" [responsive]="true"
      (pageChange)="onPageChange($event)"></pagination-controls>
  </div> -->
  <button routerLink="../shopping-cart" id="to-shopping-cart-button" hidden> 
    <span id="shopping-cart-button-text">{{'Marketplace.go-to-shopping'|translate}}</span>
    <mat-icon id="shopping-cart-icon">shopping_cart</mat-icon>
  </button>
</div>

<div class="example-button-container">
  <button class="float-button" mat-fab color="primary" aria-label="Example icon button with a home icon">
    <mat-icon>
      <img class="svg-cart" src="assets/utils/carrito-de-compras.svg" alt="cart" routerLink="/shopping-cart">
    </mat-icon>
  </button>
</div>
