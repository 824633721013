<div class="medical-service-shop-card">
  <img class="medical-service-shop-card-img" [src]="image" alt="shopping cart">
  <div class="medical-service-shop-card-data">
    <div class="data-header" *ngIf="isShoppingCart">
      <mat-icon class="remove-button" (click)="onRemoveServiceClick()"> close </mat-icon>
    </div>
    <div class="medical-service-shop-title">
      {{serviceName}}
    </div>
    <div class="medical-service-shop-weeks-title">
      {{ "Medical-service-shopping-cart.number-days" | translate }}
    </div>
    <div class="page-counter">
      <img [ngStyle]=" {'opacity': isShoppingCart? '1': '0' }" (click)="onDecreaseNumberOfDays()"
        src="assets/utils/menos.png" class="minus-button" alt="decrease">
      <div class="counter">
        <div class="counter-cell">
          {{numberOfDays}}
        </div>
      </div>
      <img [ngStyle]=" {'opacity': isShoppingCart? '1': '0' }" (click)="onIncreaseNumberOfDays()"
        src="assets/utils/mas.png" class="plus-button" alt="add">
    </div>
    <div class="cost">
      ${{cost | number}} {{currency}}
    </div>
  </div>
</div>
