import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Input() toggle;

  /** True if the user is logged in, false if it isn't */
  isLoggedIn = false;

  /** subscription that will monitor the logIn status and monitor the variable accordingly */
  logInSubscription: Subscription;

  readonly menu = [
    {
      name: 'Sidebar.home',
      icon: 'assets/sidebar/home.png',
      link: '/home',
      alt: 'home',
    },
    {
      name: 'Profile.profile',
      icon: 'assets/sidebar/profile.png',
      link: '/profile',
      alt: 'profile',
    },
    {
      name: 'Home.search',
      icon: 'assets/sidebar/search.png',
      link: '/program/search',
      alt: 'search',
    },
    {
      name: 'My-applications.applications',
      icon: 'assets/sidebar/applications.png',
      link: '/my-applications',
      alt: 'applications',
    },
    {
      name: 'Alerts.alerts',
      icon: 'assets/sidebar/alerts.png',
      link: 'notifications',
      alt: 'alerts',
    },
    {
      name: 'Home.blog',
      icon: 'assets/sidebar/blog.png',
      link: '/blog',
      alt: 'blog',
    },
    {
      name: 'Marketplace.marketplace',
      icon: 'assets/sidebar/marketplace.png',
      link: '/marketplace',
      alt: 'marketplace',
    },
    {
      name: 'Shopping-cart.shopping-cart',
      icon: 'assets/sidebar/cart.png',
      link: '/shopping-cart',
      alt: 'shopping cart',
    },
    {
      name: 'Profile.purchase-history',
      icon: 'assets/sidebar/purchase-history.png',
      link: '/purchase-history/',
      alt: 'purchase history',
    },
  ];

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        this.isLoggedIn = logInStatus;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.logInSubscription !== null && this.logInSubscription !== undefined) {
      this.logInSubscription.unsubscribe();
    }
  }

  /**
   * Navigates to profile section
   */
  navigateToProfile(): void {
    if (this.isLoggedIn) {
      const user = localStorage.getItem('user');
      const id = JSON.parse(user)._id;
      this.navigationService.navigateTo({ path: '/profile/' + id });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }

  /** Navigates to the purchase history section */
  navigateToPurchaseHistory(): void {
    if (this.isLoggedIn) {
      const user = localStorage.getItem('user');
      const id = JSON.parse(user)._id;
      this.navigationService.navigateTo({ path: '/purchase-history/' + id });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }
}
