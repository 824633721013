<!-- <div class="row">
  <div class="col-12">
    <carousel id="carousel-2" cellsToShow="2" style="height: 295px;">
      <div class="carousel-cell">
        <div class="col-12 program-card" *ngFor="let single of program">
          <div class="col-12">
            <a class="row clickable-section anchor-nostyle" (click)="onMultipleClicksHandles({ event: $event })">

              <div class="row card-program-header">
                <div class="col-9 header-program-name">
                  <div [matTooltip]="single.nombre" matTooltipPosition="above" class="program-title">
                    {{ single.nombre }}
                  </div>
                  <div class="card-university">
                    <a class="anchor-nostyle university-anchor program-name-color"
                      (click)="onMultipleClicksHandles({ event: $event })">
                      At {{ single.universidad?.nombre }}
                    </a>
                  </div>
                </div>
                <div class="col-3">
                  <button (click)="onMultipleClicksHandles({ event: $event })"
                    aria-label="add or remove from favourites" class="favourite-button" [ngClass]="
                                  single.inFavourites === true
                                    ? 'favourite-button-active'
                                    : 'favourite-button-inactive'
                                "></button>
                </div>


              </div>

              <div class="col-12">
                <div class="col-12 card-label">
                  <div class="col-6 card-location" (click)="onMultipleClicksHandles({ event: $event })">
                    <img class="card-country-logo" [src]="single.universidad.ciudad.pais.icono" alt="country logo" />
                    <p (click)="onMultipleClicksHandles({ event: $event })" class="location-name">
                      {{ single.ciudad.nombre }},
                      {{ single.provinciaUniversidad }}
                    </p>
                  </div>
                  <div class="col-6 card-location">
                    <img class="card-country-logo" src="assets/utils/file.png" alt="duration" />
                    <p>{{ single.acreditacionShown }}: {{ single.stdLength }}</p>
                  </div>
                </div>
                <div class="col-12 card-label">
                  <div class="col-6 card-location">
                    <img class="card-country-logo" src="assets/utils/dollar.png" alt="fee" />
                    <div class="location-name">
                      <div class="program-subtitle size-names">
                        {{ "Programs-list.tuition-cost-card" | translate }}:
                      </div>
                      <div class="program-subtitle font-text"
                        *ngIf="single.yearTuition !== undefined &&single.yearTuition !== null">
                        {{ single.yearTuition | number }}
                        {{ single.universidad.ciudad.pais.moneda }} /
                        {{ "Programs-list.year" | translate }}
                      </div>
                      <div class="program-subtitle font-text"
                        *ngIf="(single.yearTuition === undefined || single.yearTuition === null) && single.programTuition !== undefined && single.programTuition !== null">
                        {{ single.programTuition | number }}
                        {{ single.universidad.ciudad.pais.moneda }} /
                        {{ "Programs-list.complete" | translate }}
                      </div>
                      <div class="program-subtitle font-text" *ngIf="
                                              (single.yearTuition === undefined ||
                                                single.yearTuition === null) &&
                                              (single.programTuition === undefined ||
                                                single.programTuition === null) &&
                                              single.semesterTution !== undefined &&
                                              single.semesterTution !== null">
                        {{ single.semesterTution | number }}
                        {{ single.universidad.ciudad.pais.moneda }} /
                        {{ "Programs-list.semester" | translate }}
                      </div>
                      <div class="program-subtitle font-text" *ngIf="
                                              (single.yearTuition === undefined ||
                                                single.yearTuition === null) &&
                                              (single.programTuition === undefined ||
                                                single.programTuition === null) &&
                                              (single.semesterTution === undefined ||
                                                single.semesterTution === null)
                                            ">
                        ND
                      </div>
                    </div>
                  </div>
                  <div class="col-6 card-location">
                    <img class="card-country-logo" src="assets/utils/money.png" alt="application" />
                    <div class="program-subtitle size-names">
                      {{ "Programs-list.application-cost-card" | translate }}:
                    </div>
                    <div class="program-subtitle font-text" *ngIf="single.universidad.applicationFee">
                      {{ single.universidad.applicationFee | number }}
                      {{ single.universidad.ciudad.pais.moneda }}
                    </div>
                    <div class="program-subtitle font-text" *ngIf="!single.universidad.applicationFee">
                      ND
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12">
            <div class="container-buttons">
              <div class="text-center">
                <button *ngIf="!loading" (click)="onApplyClick()" id="apply-button">
                  {{ "Programs-list.step-by-step" | translate }}
                </button>
                <mat-progress-spinner *ngIf="loading" class="apply-spinner" mode="indeterminate" diameter="35">
                </mat-progress-spinner>
              </div>
              <div class="text-center">
                <a *ngIf="showRequisitesButton && !single.universidad?.brochure" [routerLink]="'../' + single.slug"
                  fragment="requirements-accordion">

                  <button class="requisites-button">
                    {{ "Programs-list.requisites-card" | translate }}
                  </button>
                </a>

                <button *ngIf="showRequisitesButton && single.universidad?.brochure" class="requisites-button"
                  (click)="onViewRequires(single.universidad.brochure)">
                  {{ "Programs-list.brochure" | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </carousel>
  </div>
</div> -->

<ngb-carousel *ngIf="program">
  <ng-template ngbSlide *ngFor="let single of program">
    <div class="card cardBox" style="padding: 3%;background: #80808000;" >
      <div class="card-body contentCard" style="width: 80%; background: white; align-self: center; border-radius: 50px;">
        <h5 class="card-title programTitle">{{ single.nombre }}</h5>
        <h6 class="card-subtitle mb-2 text-muted programSubtitle">At {{ single.universidad?.nombre }}</h6>
  
        <div class="row">
          <div class="col-6 card-location" (click)="onMultipleClicksHandles({ event: $event })">
            <img class="card-country-logo" width="20%" [src]="single.universidad.ciudad.pais.icono" alt="country logo" />
            <p (click)="onMultipleClicksHandles({ event: $event })" class="location-name">
              {{ single.ciudad.nombre }},
              {{ single.provinciaUniversidad }}
            </p>
          </div>
  
          <div class="col-6 card-location">
            <img class="card-country-logo" width="20%" src="assets/utils/file.png" alt="duration" />
            <p>{{ single.acreditacionShown }}: {{ single.stdLength }}</p>
          </div>
        </div>
  
        <div class="row">
          <div class="col-6 card-location">
            <img class="card-country-logo" width="20%" src="assets/utils/dollar.png" alt="fee" />
            <div class="location-name">
              <div class="program-subtitle size-names">
                {{ "Programs-list.tuition-cost-card" | translate }}:
              </div>
              <div class="program-subtitle font-text" *ngIf="single.yearTuition !== undefined &&single.yearTuition !== null">
                {{ single.yearTuition | number }}
                {{ single.universidad.ciudad.pais.moneda }} /
                {{ "Programs-list.year" | translate }}
              </div>
              <div class="program-subtitle font-text" *ngIf="(single.yearTuition === undefined || single.yearTuition === null) && single.programTuition !== undefined && single.programTuition !== null">
                {{ single.programTuition | number }}
                {{ single.universidad.ciudad.pais.moneda }} /
                {{ "Programs-list.complete" | translate }}
              </div>
              <div class="program-subtitle font-text" 
                *ngIf=" (single.yearTuition === undefined ||
                single.yearTuition === null) && (single.programTuition === undefined ||
                single.programTuition === null) && single.semesterTution !== undefined && single.semesterTution !== null">
                  {{ single.semesterTution | number }}
                  {{ single.universidad.ciudad.pais.moneda }} /
                  {{ "Programs-list.semester" | translate }}
                </div>
                <div class="program-subtitle font-text"
                *ngIf="(single.yearTuition === undefined ||single.yearTuition === null)
                && (single.programTuition === undefined || single.programTuition === null)
                && (single.semesterTution === undefined || single.semesterTution === null)">
  
                ND
  
              </div>
            </div>
          </div>
  
          <div class="col-6 card-location">
            <img class="card-country-logo" width="20%" src="assets/utils/money.png" alt="application" />
            <div class="program-subtitle size-names">
              {{ "Programs-list.application-cost-card" | translate }}:
            </div>
            <div class="program-subtitle font-text" *ngIf="single.universidad.applicationFee">
              {{ single.universidad.applicationFee | number }}
              {{ single.universidad.ciudad.pais.moneda }}
            </div>
            <div class="program-subtitle font-text" *ngIf="!single.universidad.applicationFee">
              ND
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-6">
            <div class="text-center">
              <button *ngIf="!loading" (click)="onApplyClick()" id="apply-button">
                 {{ "Programs-list.step-by-step" | translate }}
               </button> 
               <mat-progress-spinner
                 *ngIf="loading"
                 class="apply-spinner"
                 mode="indeterminate"
                 diameter="35"
               >
               </mat-progress-spinner>
             </div>
          </div>
  
          <div class="col-6">
            <div class="text-center">
              <a
                *ngIf="showRequisitesButton && !program.universidad?.brochure"
                [routerLink]="'../' + program.slug"
                fragment="requirements-accordion"
              >
              
                <button class="requisites-button">
                  {{ "Programs-list.requisites-card" | translate }}
                </button> 
              </a>
              
              <button
                *ngIf="showRequisitesButton && program.universidad?.brochure"
                class="requisites-button"
                (click)="onViewRequires(program.universidad.brochure)"
              >
                {{ "Programs-list.brochure" | translate }}
              </button> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-caption">
      <h3>10 seconds between slides...</h3>
      <p>This carousel uses customized default values.</p>
    </div>
  </ng-template>
</ngb-carousel>

<!-- <div *ngFor="let single of program">
  <div class="card cardBox" style="padding: 3%;background: #80808000;" >
    <div class="card-body contentCard" style="width: 80%; background: white; align-self: center; border-radius: 50px;">
      <h5 class="card-title programTitle">{{ single.nombre }}</h5>
      <h6 class="card-subtitle mb-2 text-muted programSubtitle">At {{ single.universidad?.nombre }}</h6>

      <div class="row">
        <div class="col-6 card-location" (click)="onMultipleClicksHandles({ event: $event })">
          <img class="card-country-logo" width="20%" [src]="single.universidad.ciudad.pais.icono" alt="country logo" />
          <p (click)="onMultipleClicksHandles({ event: $event })" class="location-name">
            {{ single.ciudad.nombre }},
            {{ single.provinciaUniversidad }}
          </p>
        </div>

        <div class="col-6 card-location">
          <img class="card-country-logo" width="20%" src="assets/utils/file.png" alt="duration" />
          <p>{{ single.acreditacionShown }}: {{ single.stdLength }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6 card-location">
          <img class="card-country-logo" width="20%" src="assets/utils/dollar.png" alt="fee" />
          <div class="location-name">
            <div class="program-subtitle size-names">
              {{ "Programs-list.tuition-cost-card" | translate }}:
            </div>
            <div class="program-subtitle font-text" *ngIf="single.yearTuition !== undefined &&single.yearTuition !== null">
              {{ single.yearTuition | number }}
              {{ single.universidad.ciudad.pais.moneda }} /
              {{ "Programs-list.year" | translate }}
            </div>
            <div class="program-subtitle font-text" *ngIf="(single.yearTuition === undefined || single.yearTuition === null) && single.programTuition !== undefined && single.programTuition !== null">
              {{ single.programTuition | number }}
              {{ single.universidad.ciudad.pais.moneda }} /
              {{ "Programs-list.complete" | translate }}
            </div>
            <div class="program-subtitle font-text" 
              *ngIf=" (single.yearTuition === undefined ||
              single.yearTuition === null) && (single.programTuition === undefined ||
              single.programTuition === null) && single.semesterTution !== undefined && single.semesterTution !== null">
                {{ single.semesterTution | number }}
                {{ single.universidad.ciudad.pais.moneda }} /
                {{ "Programs-list.semester" | translate }}
              </div>
              <div class="program-subtitle font-text"
              *ngIf="(single.yearTuition === undefined ||single.yearTuition === null)
              && (single.programTuition === undefined || single.programTuition === null)
              && (single.semesterTution === undefined || single.semesterTution === null)">

              ND

            </div>
          </div>
        </div>

        <div class="col-6 card-location">
          <img class="card-country-logo" width="20%" src="assets/utils/money.png" alt="application" />
          <div class="program-subtitle size-names">
            {{ "Programs-list.application-cost-card" | translate }}:
          </div>
          <div class="program-subtitle font-text" *ngIf="single.universidad.applicationFee">
            {{ single.universidad.applicationFee | number }}
            {{ single.universidad.ciudad.pais.moneda }}
          </div>
          <div class="program-subtitle font-text" *ngIf="!single.universidad.applicationFee">
            ND
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="text-center">
            <button *ngIf="!loading" (click)="onApplyClick()" id="apply-button">
               {{ "Programs-list.step-by-step" | translate }}
             </button> 
             <mat-progress-spinner
               *ngIf="loading"
               class="apply-spinner"
               mode="indeterminate"
               diameter="35"
             >
             </mat-progress-spinner>
           </div>
        </div>

        <div class="col-6">
          <div class="text-center">
            <a
              *ngIf="showRequisitesButton && !program.universidad?.brochure"
              [routerLink]="'../' + program.slug"
              fragment="requirements-accordion"
            >
            
              <button class="requisites-button">
                {{ "Programs-list.requisites-card" | translate }}
              </button> 
            </a>
            
            <button
              *ngIf="showRequisitesButton && program.universidad?.brochure"
              class="requisites-button"
              (click)="onViewRequires(program.universidad.brochure)"
            >
              {{ "Programs-list.brochure" | translate }}
            </button> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
      
    
  
