import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { NavigationService } from 'src/app/public/services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import jsPDF, { jsPDFAPI } from 'jspdf';

@Component({
  selector: 'app-letter-ready',
  templateUrl: './letter-ready.component.html',
  styleUrls: ['./letter-ready.component.scss'],
})
export class LetterReadyComponent implements OnInit, OnDestroy {
  readonly API_GET_LETTER = 'api/carta-intencion/getByUser/';

  userId: any;
  letter: any;

  destinatario: any;
  introduccion: any;
  antecendente: any;
  justificacion: any;
  meta: any;
  conclusion: any;
  datoPersonal: any;

  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
  currentDate: Date = new Date();

  constructor(
    private apiService: ApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService
  ) {}

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user._id;
    this.navigationService.setIsVisibleSidebar(false);
    this.getLetter();
  }

  getLetter() {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.API_GET_LETTER + this.userId }).subscribe((response) => {
      if (response) {
        this.letter = response;
        this.destinatario = this.letter.destinatario;
        this.introduccion = this.letter.introduccion;
        this.antecendente = this.letter.antecendente;
        this.justificacion = this.letter.justificacion;
        this.meta = this.letter.meta;
        this.conclusion = this.letter.conclusion;
        this.datoPersonal = this.letter.datoPersonal;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
      }
    });
  }

  toPdf() {
    const doc = new jsPDF();
    doc.html(this.dataToExport.nativeElement, {
      callback(doc) {
        doc.save('Carta-de-intencion.pdf');
      },
      margin: [10, 15, 10, 15],
      x: 0,
      y: 0,
      width: 180,
      windowWidth: 800,
    });
  }

  backToCurriculum() {
    this.navigationService.navigateTo({ path: 'cover-letter' });
  }
}
