import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageSliderModalComponent } from '../../shared/image-slider-modal/image-slider-modal.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-videos-photos',
  templateUrl: './videos-photos.component.html',
  styleUrls: ['./videos-photos.component.scss']
})
export class VideosPhotosComponent implements OnInit {

  @Input() university: any;
  isBrowser: boolean;

  constructor(private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId
    ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
     

  }

  /** Opens city modal information */
  onPhotoClick(photoId): void {
    this.dialog.open(ImageSliderModalComponent, {
      data: {
        currentIndex: photoId,
        pictures: this.university.fotos,
      },
    });
  }
}
