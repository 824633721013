import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../services/auth.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
import { AuthComponent } from '../auth/auth.component';
import { TranslateService } from '@ngx-translate/core';
import { ContactFormComponent } from './contact-form/contact-form.component';

@Component({
  selector: 'app-visa-thermometer',
  templateUrl: './visa-thermometer.component.html',
  styleUrls: ['./visa-thermometer.component.scss'],
})
export class VisaThermometerComponent implements OnInit, OnDestroy {
  /** Constants */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api for getting  the students by recruitment */
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** Api for getting the termometro-visa questions */
  readonly TERMOMETRO_VISA_API = 'api/termometro-visa';
  /** Api for submitting the answers */
  readonly ANSWERS_VISA_API = 'api/respuesta-termometro';

  /** Handles the current status of the page
   * -1 -> Student selection: Displays select student for recruitment partner
   * 0 -> Start: Displays the select-input to select a country
   * 1 -> Questionare: Display the list of questions
   * 2 -> Results: -> Displays the results of the questionare
   */
  currentStatus = 0;
  /** Variable that holds the list of countries, its questions and the posible answers */
  countries: any[] = [];
  /** Variable that holds the list of questions */
  questions: any[] = [];
  /** Variable that holds the current question */
  currentQuestion = 0;
  /** Variable that holds the current progress of the user */
  progress = 0;
  /** Variable that holds the current country */
  currentCountry = '';
  currentCountryInformation: any;
  /** Variable that determines if the user is logged in */
  loggedIn = false;

  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;

  /** Variable that holds the visa probability
   * the numerical is a number from 0  to 10
   * the description can be low - medium - high
   */
  visaProbability = {
    numerical: 0,
    descriptive: 'low',
  };

  /** Selected student if is recruitment advisor */
  student;

  /** Current recruiter students */
  students;

  /** If is a recruitment partner */
  isRecruitment = false;

  colors = ['#3A5FD9', '#5C9B9B', '#FD5675', '#F2AF49', '#545BA8'];

  constructor(
    private location: Location,
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService,
    @Inject(PLATFORM_ID) private platformId,
    public authService: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.navigationService.setIsVisibleSidebar(false);
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        this.loggedIn = logInStatus;
        if (!this.loggedIn) {
          this.isRecruitment = false;
          this.getCountriesAndQuestions();
        } else {
          const user = JSON.parse(localStorage.getItem('user'));
          if (user.tipo === this.TIPO_RECRUITMENT) {
            this.getRecruitmentStudents({ id: user._id }).subscribe((response) => {
              this.students = response as any[];
              this.currentStatus = -1;
              this.isRecruitment = true;
            });
          } else {
            this.isRecruitment = false;
          }
          this.getCountriesAndQuestions();
        }
      });
    }
  }

  /** Goes back to student selection */
  backSelectStudent(): void {
    if (this.isRecruitment) {
      this.currentStatus = -1;
    }
  }

  /**
   * Handles on student change when is a recruiter
   * @param object with student id property
   */
  onStudentChange({ id }): void {
    this.currentStatus = 0;
  }

  /**
   * Retrieves recruitment partner students
   * @returns Observable with response from backend
   */
  getRecruitmentStudents({ id }): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + id });
  }

  /** Retrieves the countries and questions from the backend */
  getCountriesAndQuestions(): void {
    this.apiService.get({ api: this.TERMOMETRO_VISA_API }).subscribe(
      (response) => {
        let countries = [];
        for (const country of response) {
          if (country.activo) {
            countries.push(country);
          }
        }
        this.countries = countries;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Visa-thermometer.error-load-countries'),
        });
      }
    );
  }

  /**
   * Handles the click on the back button
   */
  onBackClick(): void {
    this.location.back();
  }

  /**
   * Changes the status of the page
   * @param newStatus number of the new status according to the docs
   */
  onChangeStatus({ newStatus }: { newStatus: number }): void {
    if (newStatus === 1) {
      this.currentQuestion = 0;
      if (this.questions.length > 0) {
        this.currentStatus = newStatus;
        this.updateProgress();
      } else {
        alert(this.translate.instant('Visa-thermometer.must-select-country'));
      }
    } else if (newStatus === 0) {
      this.currentCountry = '';
      this.questions = [];
      this.currentStatus = newStatus;
    } else {
      this.currentStatus = newStatus;
    }
  }

  /** Advances the user to the next question */
  onNextQuestion(): void {
    this.currentQuestion++;
    this.updateProgress();
  }

  /** Returns the user to the previous question */
  onPreviousQuestion(): void {
    if (this.currentStatus === 2) {
      this.onBackClick();
    } else {
      if (this.currentQuestion > 0) {
        this.currentQuestion--;
      } else {
        this.onChangeStatus({ newStatus: 0 });
      }
    }
  }

  /**
   * Changes the value of an answer
   * @param newValue new value of the answer, can be true or false
   * @param index index of the question in the questions array
   */
  onChangeAnswer({ newValue, index }: { newValue: boolean; index: number }): void {
    this.questions[index].selected = newValue;
  }

  /** Updates the value of the progress bar */
  updateProgress(): void {
    this.progress = ((this.currentQuestion + 1) / this.questions.length) * 100;
  }

  /**
   * Handles the click on the try again button
   */
  onTryAgain(): void {
    this.isRecruitment ? this.onChangeStatus({ newStatus: -1 }) : this.onChangeStatus({ newStatus: 0 });
  }

  /**
   * Submits the answer to the backend and displays the response to the user
   */
  onSubmitResponse(): void {
    if (this.loggedIn === false) {
      this.authService.sitioMensaje = '';
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      const answers = [];
      let score = 0;
      for (const question of this.questions) {
        let r = 'No';
        if (question.selected) {
          r = 'Si';
          score += question.peso;
        }
        const qData = {
          numero: question.numero,
          respuesta: r,
        };
        answers.push(qData);
      }
      const user = JSON.parse(localStorage.getItem('user'));
      let userId = '';
      if (user.tipo === this.TIPO_RECRUITMENT) {
        userId = this.student;
      } else {
        userId = user._id;
      }
      const data = {
        estudiante: userId,
        pais: this.currentCountry,
        respuestas: answers,
        puntaje: score,
      };
      this.apiService.post({ api: this.ANSWERS_VISA_API, data }).subscribe(
        (response) => {
          if (response.success === true) {
            const puntaje = response.result.puntaje;
            this.visaProbability.numerical = puntaje;
            if (puntaje >= 85) {
              this.visaProbability.descriptive = 'High';
            } else if (puntaje < 85 && puntaje >= 25) {
              this.visaProbability.descriptive = 'Medium';
            } else {
              this.visaProbability.descriptive = 'Low';
            }
            this.onChangeStatus({ newStatus: 2 });
          } else {
            this.alertService.showError({
              msg: this.translate.instant('Visa-thermometer.error-save'),
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Visa-thermometer.error-save'),
          });
        }
      );
    }
  }

  /**
   * Handles the click on the view programs button
   */
  onViewPrograms(): void {
    this.navigationService.navigateTo({
      path: 'program/search',
      params: {
        pais: this.currentCountry,
      },
    });
  }

  /**
   * Handles the click on a country in the first screen
   * @param index index of the country in the countries list
   */
  onCountryClick({ index }: { index: number }): void {
    this.currentCountry = this.countries[index].pais._id;
    this.currentCountryInformation = this.countries[index].pais;
    const questions: any[] = this.countries[index].preguntas;
    for (const question of questions) {
      question.selected = false;
    }
    this.questions = questions;
    this.onChangeStatus({ newStatus: 1 });
  }

  onContactInformationClick(): void {
    this.dialog.open(ContactFormComponent);
  }
}
