import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/public/services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ShowMoreComponent } from '../showMoreComponent/showMoreComponent.component';

@Component({
  selector: 'app-explore-skills',
  templateUrl: './exploreSkills.component.html',
  styleUrls: ['./exploreSkills.component.scss'],
})
export class ExploreSkillsComponent implements OnInit {
  @ViewChild('experienceExamples', { static: true }) experienceExamples: any;
  showMessage: string;
  public showDiv: boolean = false;
  selectedSkill: any;
  user = JSON.parse(localStorage.getItem('user'));
  readonly skillsApi = 'api/getSkill/';
  skills: any;
  constructor(
    public domSanitizer: DomSanitizer,
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.getExperiences();
  }

  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsApi }).subscribe(
        (response) => {
          this.skills = response;
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error al cargar las experiencias!',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  selectSkill(skill: any) {
    this.router.navigate(['/experience-example'], { queryParams: { skill: skill } });
  }

  showDetail(index: number) {
    const currentSkill = this.skills[index];

    if (this.selectedSkill === currentSkill) {
      this.closeSkill();
    } else {
      if (this.selectedSkill) {
        this.closeSkill();
      }

      currentSkill.showDetails = true;
      this.selectedSkill = currentSkill;
      this.showDiv = true;
    }
  }

  openShowMoreComponent(skillData: any): void {
    skillData.name = skillData.skillName;
    skillData.description = skillData.skillDescription;
    const modifiedExperienceData = skillData;

    const dialogRef = this.dialog.open(ShowMoreComponent, {
      width: '50vw',
      height: '95vh',
      data: { modifiedExperienceData },
    });
  }

  closeSkill() {
    this.selectedSkill.showDetails = false;
    this.selectedSkill = null;
    this.showDiv = false;
  }

  extractVideoId(url: string): string {
    const regex = /[?&]v=([^?&]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('No se pudo extraer el ID del video de YouTube desde la URL:', url);
      return '';
    }
  }

  getSafeVideoUrl(videoUrl: string): SafeResourceUrl {
    const videoId = this.extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
  ngAfterViewInit(): void {
    const iframeElement: HTMLIFrameElement | null = this.elementRef.nativeElement.querySelector('iframe');

    if (iframeElement) {
      Swal.fire({
        title: 'Loading video...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      } as any);
      iframeElement.addEventListener('load', () => {
        Swal.close();
      });
    }
  }
}
