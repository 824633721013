import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StudentDetailComponent } from '../student-detail/student-detail.component';
import { FormStudentComponent } from '../form-student/form-student.component';

@Component({
  selector: 'app-card-student',
  templateUrl: './card-student.component.html',
  styleUrls: ['./card-student.component.scss']
})
export class CardStudentComponent implements OnInit {
  showmore : boolean = false
  @Input() student;
  readonly paisPorDefecto = 'https://u360-recursos-new.s3.amazonaws.com/pais/XfIEzZysch8SE2c'
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {


  }

  /** Opens student modal information */
  onClickStudent(): void {
    this.dialog.open(StudentDetailComponent, {
      data: {
        student: this.student,
      },
    });
  }

  /** Opens form modal */
  onClickForm(): void {
    this.dialog.open(FormStudentComponent, {
      data: {
        student: this.student,
      },
    });
  }

  changeViewMore(){
    this.showmore = !this.showmore;
  }

}
