<div class="not-found-page d-flex align-items-center">
  <div class="not-found-notice">
    <div class="row h-100 p-5">
      <div class="col-lg-6 d-flex justify-content-center align-items-center">
        <img class="not-found-image" src="assets/utils/empty-state.png" alt="not found" />
      </div>
      <div class="col-lg-6 d-flex flex-column justify-content-center align-items-center">
        <h1 class="not-found-title">{{ 'Not-found.not-found' | translate }}</h1>
        <p class="not-found-text">
          {{ 'Not-found.we-are-sorry' | translate }}
          <a href="public-home">{{ 'Not-found.here' | translate }}</a>
          {{ 'Not-found.you-will-return' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
