<div class="page-container">
  <p class="pageTitle">Tus notas escolares</p>
  <p>
    Tus notas hacen parte de los requisitos de admisión de las universidades: Por favor procura indicar tu promedio de
    los últimos 3 años.
  </p>
  <hr class="titleSeparator" />

  <p class="pageSubtitle">
    Digita el rango promedio de notas, minimo de tus últimos tres años que puedas demostrar de acuerdo a tu escala:
  </p>
  <div class="dropdowns-container">
    <p class="pageSubtitle">Rango de notas por grado escolar:</p>
    <app-form-dropdown
      [control]="form.get('academicRatings7')"
      label="7º"
      [options]="academicRating"></app-form-dropdown>
    <app-form-dropdown
      [control]="form.get('academicRatings8')"
      label="8º"
      [options]="academicRating"></app-form-dropdown>
    <app-form-dropdown
      [control]="form.get('academicRatings9')"
      label="9º"
      [options]="academicRating"></app-form-dropdown>
    <app-form-dropdown
      [control]="form.get('academicRatings10')"
      label="10º"
      [options]="academicRating"></app-form-dropdown>
    <app-form-dropdown
      [control]="form.get('academicRatings11')"
      label="11º"
      [options]="academicRating"></app-form-dropdown>
    <app-form-dropdown
      [control]="form.get('academicRatings12')"
      label="12º"
      [options]="academicRating"></app-form-dropdown>
  </div>

  <p class="pageSubtitle">Nivel de ingles:</p>
  <app-form-dropdown
    [control]="form.get('englishLevel')"
    label="Nivel de ingles"
    [options]="englishLevels"></app-form-dropdown>
</div>
