<!-- Test section -->
<div class="careers">
  <div class="max-width-container">
    <div class="careers-header-container" style="text-align: left">
      <img class="careers-logo" src="assets/home/images/birrete-azul.png" alt="u360 carreras populares" />
      <br />
      <label id="title-section">U360 Test</label>
      <p id="text-section" class="text-center">
        <strong>{{ 'Test.lets-go' | translate }}</strong>
      </p>
      <p id="text-section" class="font-20" style="position: relative; right: -1%; width: 100%">
        {{ 'Test.tools' | translate }}
      </p>
    </div>
    <div class="test-principal-container">
      <div class="test-container">
        <div class="test-img-container">
          <div class="img-container">
            <img
              class="cultural-test-img"
              routerLink="/cultural-item"
              src="assets/home/images/cultura.png"
              alt="cultural match" />
          </div>
          <label id="cultural-match" routerLink="/cultural-item">
            Cultura
            <br />
            Match
          </label>
        </div>
        <div class="test-img-container">
          <div class="img-container">
            <img
              class="visa-test-img"
              routerLink="/visa-thermometer"
              src="assets/home/images/visa.png"
              alt="visa test" />
          </div>
          <label id="visa-test" routerLink="/visa-thermometer">
            Visa
            <br />
            Test
          </label>
        </div>
        <div class="test-img-container">
          <div class="img-container">
            <img
              class="english-test-img"
              routerLink="/english-test"
              src="assets/home/images/english.png"
              alt="english test" />
          </div>
          <label id="english-test" routerLink="/english-test">
            English
            <br />
            Test
          </label>
        </div>
      </div>
      <div *ngIf="false" class="all-careers-container">
        <button routerLink="/marketplace">Ver todos</button>
      </div>
    </div>
  </div>
</div>
