<div class="tuition-content">
  <p class="tuition-text" *ngIf="!tuitionPayment.completado">{{'Tuition.remember-to'|translate}}</p>
  <a class="document-link"
       *ngIf="tuitionPayment.paymentProof !== '' && tuitionPayment.paymentProof !== null && tuitionPayment.paymentProof !== undefined"
       [href]="tuitionPayment.paymentProof"
       target="_blank">{{'Tuition.view-payment-proof'|translate}}</a> <br>
    <label *ngIf="!tuitionPayment.completado" for="tuition-payment-proof" class="upload-document">{{'Tuition.upload-payment-proof'|translate}}</label>
    <input *ngIf="!tuitionPayment.completado" (change)="onUploadChange({event: $event, nombreDoc: 'tuition-payment-proof'})" id="tuition-payment-proof" type=file>
  <p class="tuition-text" *ngIf="tuitionPayment.completado">{{'Tuition.done'|translate}}</p>
</div>
