import { ChangeDetectorRef, Component, HostListener, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthService } from './public/services/auth.service';
import { UserService } from './public/services/user.service';
import { TimeService } from './public/services/time.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { NavigationService } from './public/services/navigation.service';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    private timeService: TimeService,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        //
        this.isLoggedIn = this.authService.loggedIn.value;

        if (this.isLoggedIn) {
          this.ocultaFooter = true;
        }

        if (event.url == '/leaderTest') {
          this.ocultaFooter = true;
          this.ocultaHeader = false;
        } else if (event.url == '/wizard') {
          this.ocultaHeader = false;
          this.ocultaFooter = true;
        } else if (event.url == '/english-test') {
          this.isEnglishTestUrl = true;
        } else {
          this.ocultaFooter = false;
          this.ocultaHeader = false;
        }
      }
    });
  }
  title = 'u360-web';
  public ocultaFooter: boolean = false;
  public ocultaHeader: boolean = false;

  public toggle = false;
  isLoggedIn: any;
  scrWidth: number;
  isPremiumUser: boolean;
  isActiveMenuMobile: boolean;
  isActiveSidebar: boolean;
  isColegio: boolean;
  isEstudiante: boolean;
  isRecruitment: boolean;
  userSubscription: Subscription;
  isEnglishTestUrl: boolean = false;

  user: any;
  @HostListener('window:beforeunload')
  @HostBinding('class')
  className: string;

  EndLoggedInTime() {
    this.timeService.endNotLoggedInTime();
  }

  public toggleNav(): void {
    this.toggle = !this.toggle;
  }

  onActiveMenu(isActive: boolean): void {
    this.isActiveMenuMobile = isActive;
  }

  ngOnInit(): void {
    this.authService.isPremiumUser$.subscribe((result) => (this.isPremiumUser = result));
    this.subscribeToUserChanges();
    this.getScreenSize();
    this.navigationService.getIsVisibleSidebar().subscribe((value) => {
      this.isActiveSidebar = value;
      this.ref.detectChanges();
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.updateHeightBasedOnUrl();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.scrWidth = window.innerWidth;
  }
  updateHeightBasedOnUrl() {
    const targetUrl = '/english-test';
    this.isEnglishTestUrl = this.router.url === targetUrl;
  }
  checkProfileType(user): void {
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isEstudiante = user?.tipo === 'Estudiante';
  }

  private subscribeToUserChanges(): void {
    this.userSubscription = this.userService.currentUser$.subscribe((userData) => {
      if (userData) {
        this.user = userData;
        this.checkProfileType(userData);
      }
    });
  }
}
