import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss']
})
export class ManifestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
