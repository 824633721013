import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss'],
})
export class PurchaseHistoryComponent implements OnInit {
  /** CONSTANTS */
  /** Api to get the shopping carts by student */
  readonly CART_BY_STUDENT_API = 'api/carrito/estudiante/';

  /** VARIABLES */
  /** Variable that holds the list of shopping carts of the student */
  shoppingCarts: any[] = [];

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId,
    private ngxSpinnerService: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      const id = user._id;
      this.getPurchaseHistory({ id });
    }
  }

  /**
   * Retrieves the purchase history of the user that has the given id
   * @param id: user id
   */
  getPurchaseHistory({ id }: { id: string }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.CART_BY_STUDENT_API + id }).subscribe(
      (response) => {
        this.shoppingCarts = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Purchase-history.load-error'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
}
