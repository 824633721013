<div class="terms">
  <h3>Tratamiento de datos personales</h3>
  <p
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: normal;
      font-size: 15px;

      text-align: justify;
      text-indent: 0.5in;
    "
  >
    <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
      >La protecci&oacute;n de su informaci&oacute;n privada es nuestra
      prioridad. La presente Pol&iacute;tica de Protecci&oacute;n y Tratamiento
      de Datos Personales (en adelante la &ldquo;<u>Pol&iacute;tica</u>&rdquo;),
      regula la recolecci&oacute;n, almacenamiento, uso, transmisi&oacute;n,
      transferencia, circulaci&oacute;n y/o supresi&oacute;n de Datos
      Personales, realizado por
      <strong>Higher Education Consulting S.A.S.&nbsp;</strong>sociedad
      &nbsp;debidamente constituida y existente de conformidad con las leyes de
      la Rep&uacute;blica de Colombia e identificada con NIT 901.255.403-8 y
      cualquiera de sus afiliados (en adelante &ldquo;<u>HEC</u>&rdquo;), de
      acuerdo con las disposiciones contenidas en la Ley Estatutaria 1581 de
      2012, el Decreto 1377 de 2013 y el Decreto 886 de 2014 (hoy incorporados
      en el Decreto &uacute;nico 1074 de 2015).</span
    >
  </p>
  <div
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 15px;
    "
  >
    <ol
      style="
        margin-bottom: 0in;
        list-style-type: upper-roman;
      "
    >
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Informaci&oacute;n del Responsable del tratamiento de los Datos
            Personales</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >La persona Responsable del Tratamiento de los Datos Personales
            es:</span
          >
        </p>
        <ul style="list-style-type: undefined">
          <li>
            <strong
              ><span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Nombre</span
              ></strong
            ><span style="font-family: 'Helvetica', sans-serif"
              >: <strong>Higher Education Consulting S.A.S.</strong></span
            >
          </li>
          <li>
            <strong
              ><span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Domicilio</span
              ></strong
            ><span style="font-family: 'Helvetica', sans-serif"
              >: Bogot&aacute; D.C., Colombia</span
            >
          </li>
          <li>
            <strong
              ><span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Direcci&oacute;n</span
              ></strong
            ><span style="font-family: 'Helvetica', sans-serif"
              >: Calle 125 # 20 - 59</span
            >
          </li>
          <li>
            <strong
              ><span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Correo Electr&oacute;nico</span
              ></strong
            ><span style="font-family: 'Helvetica', sans-serif">:&nbsp;</span
            ><a href="mailto:natalia@hec-latam.com"
              ><span style="font-family: 'Helvetica', sans-serif"
                >natalia@hec-latam.com</span
              ></a
            ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
              >&nbsp;</span
            >
          </li>
          <li>
            <strong
              ><span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Tel&eacute;fono</span
              ></strong
            ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
              >: 321 323 06 60&nbsp;</span
            >
          </li>
        </ul>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Autorizaci&oacute;n para el tratamiento de los Datos
            Personales</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >La recolecci&oacute;n, almacenamiento, uso, transmisi&oacute;n,
            transferencia, circulaci&oacute;n y/o supresi&oacute;n de Datos
            Personales por parte de <strong>HEC</strong>, requiere del
            consentimiento libre, previo, expreso e informado de los titulares
            de los mismos. En ese sentido, <strong>HEC,</strong> en su
            condici&oacute;n de Responsable del tratamiento, ha dispuesto los
            mecanismos necesarios para obtener la autorizaci&oacute;n previa y
            expresa de los titulares de los Datos Personales.&nbsp;</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Tratamiento al cual ser&aacute;n sometidos los Datos
            Personales&nbsp;</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >Los Datos Personales tales como sus nombres, n&uacute;mero de
            c&eacute;dula, correos electr&oacute;nicos, tel&eacute;fonos fijos o
            celulares, entre otros, son recolectados, almacenados, organizados,
            usados, circulados, transmitidos, transferidos, actualizados,
            rectificados, suprimidos, eliminados y gestionados por
            <strong>HEC</strong>, para las siguientes finalidades:
            gesti&oacute;n de clientes, gesti&oacute;n administrativa,
            prospecci&oacute;n comercial, fidelizaci&oacute;n de clientes,
            marketing, publicidad propia, el env&iacute;o de comunicaciones
            comerciales sobre productos y campa&ntilde;as de
            actualizaci&oacute;n de datos e informaci&oacute;n de cambios en el
            tratamiento de datos personales; asimismo, para compartir dicha
            informaci&oacute;n con aliados estrat&eacute;gicos y para dar
            cumplimiento, cuando aplique, a las obligaciones establecidas en los
            contratos que <strong>HEC</strong> suscriba con terceros, sean estos
            contratos de car&aacute;cter laboral, mercantil o civil, as&iacute;
            como para dar cumplimiento a la legislaci&oacute;n laboral
            aplicable, al reglamento interno de trabajo y a cualquier otra
            disposici&oacute;n normativa, sea de car&aacute;cter laboral,
            tributaria, civil, penal o cualquier otra.&nbsp;</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Derechos de los&nbsp;</span
          ></strong
        ><strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >titulares</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >De conformidad con lo establecido en el art&iacute;culo 8 de la Ley
            1581 de 2012 y el cap&iacute;tulo 25 del Decreto 1074 de 2015, el
            titular de los Datos Personales tiene los siguientes derechos:</span
          >
        </p>

        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="1"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Acceder de forma gratuita a los datos proporcionados que hayan
                sido objeto de tratamiento;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="2"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Conocer, actualizar y rectificar su informaci&oacute;n frente a
                datos parciales, inexactos, incompletos, fraccionados, que
                induzcan a error, o aquellos cuyo tratamiento est&eacute;
                prohibido o no haya sido autorizado;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="3"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Solicitar prueba de la autorizaci&oacute;n otorgada;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="4"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Presentar ante la Superintendencia de Industria y Comercio
                (SIC) quejas por infracciones a lo dispuesto en la normatividad
                vigente;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="5"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Revocar la autorizaci&oacute;n y/o solicitar la
                supresi&oacute;n del dato, siempre que no exista un deber legal
                o contractual que impida eliminarlos; y</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
          "
        >
          <ol
            start="6"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Abstenerse de responder las preguntas sobre datos sensibles.
                Tendr&aacute; car&aacute;cter facultativo las respuestas que
                versen sobre datos sensibles o sobre datos de las ni&ntilde;as y
                ni&ntilde;os y adolescentes</span
              >
            </li>
          </ol>
        </div>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >&Aacute;rea responsable de la Atenci&oacute;n de PQRs</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >El &aacute;rea de cumplimiento de <strong>HEC</strong> ser&aacute;
            el &aacute;rea responsable de la atenci&oacute;n de peticiones,
            quejas y reclamos, o para el ejercicio de los derechos de los
            titulares de los Datos Personales objeto de tratamiento por parte de
            <strong>HEC</strong>.</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Procedimiento para ejercer que el titular ejerza sus derechos</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >El titular de los Datos Personales, o cualquiera de las personas
            autorizadas conforme con lo establecido en este documento,
            podr&aacute;n consultar la informaci&oacute;n que repose en las
            Bases de Datos de <strong>HEC</strong>, as&iacute; como solicitar la
            correcci&oacute;n, actualizaci&oacute;n o supresi&oacute;n, o cuando
            adviertan el presunto incumplimiento de cualquiera de los deberes,
            mediante solicitud enviada de lunes a viernes en horario de 8:00
            a.m. a 5:00 p.m. al correo electr&oacute;nico&nbsp;</span
          ><a href="mailto:natalia@hec-latam.com"
            ><span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
              >natalia@hec-latam.com</span
            ></a
          ><span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;donde se indicar&aacute;, entre otras cosas lo
            siguiente:</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
          "
        >
          <ol
            start="1"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
                font-family: 'Verdana', sans-serif;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Se deber&aacute; indicar los datos solicitados, previa
                identificaci&oacute;n de la identidad del solicitante;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
          "
        >
          <ol
            start="2"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
                font-family: 'Verdana', sans-serif;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Para evitar que terceros no autorizados accedan a la
                informaci&oacute;n personal del titular de los Datos Personales,
                ser&aacute; necesario establecer la identificaci&oacute;n del
                Titular. En aquellos casos en los que la solicitud sea formulada
                por una persona distinta a su titular y no se acredite la
                representaci&oacute;n, se tendr&aacute; por no presentada la
                solicitud;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
          "
        >
          <ol
            start="3"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
                font-family: 'Verdana', sans-serif;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >Si se trata de un reclamo y este resulta incompleto,
                <strong>HEC</strong> requerir&aacute; al solicitante dentro de
                los cinco (5) d&iacute;as h&aacute;biles siguientes a la
                recepci&oacute;n de la solicitud para que subsane sus
                fallas.&nbsp;</span
              >
            </li>
          </ol>
        </div>
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            line-height: normal;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
            text-align: justify;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >&nbsp;</span
          >
        </p>
        <div
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 15px;
            font-family: 'Verdana', sans-serif;
          "
        >
          <ol
            start="4"
            style="
              margin-bottom: 0in;
              list-style-type: lower-alpha;
            "
          >
            <li
              style="
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 10pt;
                margin-left: 0in;
                line-height: 115%;
                font-size: 15px;
                font-family: 'Verdana', sans-serif;
              "
            >
              <span
                style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
                >El t&eacute;rmino m&aacute;ximo para responder una
                petici&oacute;n, queja o reclamo ser&aacute; de quince (15)
                d&iacute;as h&aacute;biles contados a partir del d&iacute;a
                siguiente a la fecha de su recibo. . Cuando no fuere posible
                atender el reclamo dentro de dicho t&eacute;rmino, se
                informar&aacute; al interesado los motivos de la demora y la
                fecha en que se atender&aacute; su reclamo, la cual en
                ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as
                h&aacute;biles siguientes al vencimiento del primer
                t&eacute;rmino.</span
              >
            </li>
          </ol>
        </div>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Revocatoria y supresi&oacute;n de la autorizaci&oacute;n</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >El titular de los Datos Personales tiene el derecho, en todo
            momento, de solicitarle al Responsable del tratamiento de los Datos
            Personales la revocatoria y/o supresi&oacute;n (eliminaci&oacute;n)
            de sus datos personales en los t&eacute;rminos establecidos en la
            Ley y siempre y cuando no lo impida o restrinja una
            disposici&oacute;n legal.&nbsp;</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Cambios en la Pol&iacute;tica</span
          ></strong
        >
        <p
          style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 10pt;
            margin-left: 0in;
            line-height: normal;
            font-size: 15px;

            text-align: justify;
            text-indent: 0.5in;
          "
        >
          <span style="font-size: 16px; font-family: 'Helvetica', sans-serif"
            >El responsable del Tratamiento de los Datos Personales podr&aacute;
            modificar la presente Pol&iacute;tica en cualquier momento, y
            siempre subir&aacute; la versi&oacute;n actualizada en la
            p&aacute;gina web e informar&aacute; de dicha actualizaci&oacute;n a
            los titulares de los Datos Personales mediante notificaci&oacute;n
            enviada al correo electr&oacute;nico de cada uno de ellos.</span
          >
        </p>
      </li>
      <li
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 10pt;
          margin-left: 0in;
          line-height: 115%;
          font-size: 15px;
        "
      >
        <strong
          ><span style="font-family: 'Helvetica', sans-serif; font-size: 12pt"
            >Vigencia de la Pol&iacute;tica&nbsp;</span
          ></strong
        >
        <p>
          <span
            style="
              font-size: 16px;
              line-height: 115%;
              font-family: 'Helvetica', sans-serif;
            "
            >La Pol&iacute;tica rige a partir de su aceptaci&oacute;n. Los Datos
            Personales que sean almacenados, utilizados o transmitidos
            permanecer&aacute;n en nuestra Base de Datos, con base en el
            criterio de temporalidad y necesidad, durante el tiempo que sea
            necesario para las finalidades mencionadas en esta Pol&iacute;tica,
            para las cuales fueron recolectados</span
          >
        </p>
      </li>
    </ol>
  </div>
</div>
