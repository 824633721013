<div class="change">
  <div class="change-password-title">
    {{ "Password-recovery.change-password" | translate }}
  </div>
  <div class="change-password-form">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
      <div class="form-group">
        <label class="form-label" for="old-password"> {{ "Password-recovery.old-password" | translate }} </label> <br>
        <div class="row">
          <div class="col-xl-10">
            <input class="form-input" type="password" id="old-password" formControlName="password"
              placeholder="Old password" (keyup)="validatePassword()" [(ngModel)]="currentpassword"
              [ngClass]="{ 'red-border': !validpass }">
          </div>
          <div class="col-xl-2">
            <mat-icon matSuffix (click)="showPassword({source:'old-password'})">{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="new-password"> {{ "Password-recovery.new-password" | translate }} </label> <br>
        <div class="row">
          <div class="col-xl-10">
            <input class="form-input" type="password" id="new-password" formControlName="passwordNueva"
              placeholder="New password">
          </div>

          <div class="col-xl-2">
            <mat-icon matSuffix (click)="showPassword({source:'new-password'})">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="confirm-password"> {{ "Password-recovery.confirm-password" | translate }}</label>
        <div class="row">
          <div class="col-xl-10">
            <input class="form-input" type="password" id="confirm-password" formControlName="confirmPassword"
              placeholder="Confirm new password">
          </div>

          <div class="col-xl-2">
            <mat-icon matSuffix (click)="showPassword({source:'confirm-password'})">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
          </div>
        </div>
      </div>
      <p *ngIf="changePasswordForm.invalid && changePasswordForm.touched" class="error-msg">{{ "Password-recovery.match-alert" | translate }} </p>
      <button [disabled]="changePasswordForm.invalid || validpass==false" type="submit" id="change-password-button">{{
        "Password-recovery.change-password" | translate }} </button>
    </form>
  </div>
</div>
