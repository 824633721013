import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  /** CONSTANTS */
  /** Api path for alert operations */
  readonly ALERT_API = 'api/alerta/'

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  private dialogRef: MatDialogRef<AlertModalComponent>,
  private apiService: ApiService) { }

  ngOnInit(): void {
    if (this.data.read === false) {
      this.changeAlertToRead();
    }
  }

  /**
   * Closes self
   */
  public selfClose() {
    this.dialogRef.close();
  }

  /**
   * Update the not read messages status to read status in the backend
   * ->> Method is async, because user should not receive feedback about this process
   * @param param0 list of messages
   */
  async changeAlertToRead() {
    const data = {
      leido: true,
    }
    this.apiService.put({api: this.ALERT_API + this.data.id, data}).subscribe((response) => {
      // If response is correct don't do anything, this process is obscure to the user
    }, err => {
      // If response is error, don't do anything, this process is obscure to the user
      // if it fails the strategy is to try again during the next connection
    });
  }
}
