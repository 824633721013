import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-list-videos-qa',
  templateUrl: './list-videos-qa.component.html',
  styleUrls: ['./list-videos-qa.component.scss']
})
export class ListVideosQaComponent implements OnInit, OnChanges {

  @Input() university: any;
  videosSinPrimero: any[] = [];
  primerVideo: string;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.university.currentValue.videosResuelve){
      const arrayEliminar = changes.university.currentValue.videosResuelve;
      this.primerVideo = arrayEliminar.shift();
      this.videosSinPrimero = arrayEliminar ? arrayEliminar : [];
       
       
    }

  }

}
