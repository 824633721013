<main class="container-letter">
    <div class="row">
        <div class="col-xl-1 d-flex justify-content-between align-items-center align-items-xl-start">
            <img class="back-img" src="/assets/wizard-images/back.png" alt="volver" (click)="backToCurriculum()">
            <button class="download-button display-mobile" (click)="toPdf()">
              <img class="download-icon" src="assets/utils/download-blue.png" alt="download">
            </button>
        </div>

        <div class="col-xl-8 letter-container">
          <div #dataToExport>

            <div class="col-xl-12">
              <p class="text-right">
                {{ currentDate | date:'longDate' }}
              </p>
            </div>

            <br>

            <div class="col-xl-12" [innerHtml]="destinatario">
            </div>

            <br>
            <br>

            <div class="col-xl-12">
              <h2 class="text-center title-letter">Carta de intención académica</h2>
            </div>

            <br>
            <br>

            <div class="col-xl-12" [innerHtml]="introduccion">
            </div>

            <div class="col-xl-12" [innerHtml]="antecendente">
            </div>

            <br>

            <div class="col-xl-12" [innerHtml]="justificacion">
            </div>

            <div class="col-xl-12" [innerHtml]="meta">
            </div>

            <div class="col-xl-12" [innerHtml]="conclusion">
            </div>

            <br>
            <br>

            <div class="col-xl-12" [innerHtml]="datoPersonal">

            </div>
          </div>
        </div>

        <div class="col-xl-3 d-flex justify-content-center display-mobile-none">
            <button class="download-button" (click)="toPdf()">
                Descargar
            </button>

        </div>
    </div>

</main>
