<div id="application-detail">
  <div class="application-detail-header">
    <img routerLink="../../my-applications" class="back-button" src="assets/utils/back.png" alt="go back">
    <div class="header-text">{{"Application-list.application-to" | translate}} <br>  {{programName}} - {{universityName}}</div>
  </div>
    <div class="row">
        <div class="col-md-6 col-lg-5">
            <ng-select (change)="onStepSelectChange({event: $event})" [ngModel]="selected" id="mobile-step-selector" placeholder="Step">
                <ng-option *ngFor="let step of steps; index as i" [value]="i">{{(i + 1) + '. '}}{{step.name | translate}}</ng-option>
            </ng-select>
            <div class="application-steps">
                <div *ngFor="let step of steps; let i = index" class="row">
                    <div class="col-sm-2">
                      <div [ngClass]="step.completado === 'in-progress'?'progress-number':''" *ngIf="step.completado !== 'true'" class="step-number">{{i + 1}}</div>
                      <img *ngIf="step.completado === 'true'" class="completed-image" src="assets/utils/steps/success.png" alt="success"/>
                    </div>
                    <div class="col-sm-10 card-column">
                        <div (click)="onStepSelectChange({event: i})" class="step-card" [ngClass]="selected === i?'card-selected':''">
                            <img class="step-image" [src]="step.img" alt="step">
                            <div class="step-info">
                                <h4>{{step.name | translate}}</h4>
                                <p class="paused-application"
                                  *ngIf="
                                    status === 'En pausa'"
                                >
                                  Aplicación pausada
                                </p>
                                <h5>{{step.date | translate}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-7">
            <div [ngSwitch]="selected">
                <app-elegibility
                (updateApplication)="updateElegibilidad($event)"
                *ngSwitchCase="0"
                [stepData]="steps[0].data"></app-elegibility>
                <app-platform-payment
                *ngSwitchCase="1"
                [userId]="studentId"></app-platform-payment>
                <app-apply
                (updateUploadedDocument)="updateDocument($event)"
                (updateAdditionalDocuments)="updateAdditionalDocuments($event)"
                (updateGeneralInformationForm)="updateGeneralInformationForm($event)"
                (updateTermsAccepted)="onThirdPartyTermsAccepted()"
                [currency]="steps[2].currency"
                [applicationFee]="steps[2].applicationFee"
                [applicationFeeConfirmed]="steps[2].applicationFeeConfirmado"
                [stepData]="steps[2].data"
                *ngSwitchCase="2"></app-apply>
                <app-be-accepted *ngSwitchCase="3" [stepData]="steps[3].data"
                (updateUploadedDocument)="updateDocument($event)"
                (updateLOARead)="onMarkLOAAsRead()"></app-be-accepted>
                <app-visa *ngSwitchCase="4" [stepData]="steps[4].data"></app-visa>
                <app-travel
                *ngSwitchCase="5"
                [stepData]="steps[5].data"
                (updateUploadedDocument)="updateDocument($event)"></app-travel>
                <app-tuition *ngSwitchCase="6" [stepData]="steps[6].data" (updateUploadedDocument)="updateDocument($event)"></app-tuition>
                <app-enrolment *ngSwitchCase="7" [stepData]="steps[7].data"></app-enrolment>
            </div>
        </div>
    </div>
</div>

