import { NgxSpinnerService } from 'ngx-spinner';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss'],
})
export class TicketDetailComponent implements OnInit {
  /** CONSTANTS */
  /** Ticket api backend path*/
  readonly TICKET_API = 'api/ticket/';

  /** VARIABLES */
  /** Variable that holds the ticket id */
  ticketId = '';
  /** Variable that holds the current ticket */
  ticket = {
    titulo: '',
    aplicacion: {
      _id: '',
      programa: {
        nombre: '',
      },
    },
    prioridad: '',
    estado: '',
    numeroSeguimiento: '',
    razon: '',
    mensajes: [],
    ultimoMensajeLeidoPorEstudiante: true,
  };
  /** Variable that holds the message of the send message input*/
  message = '';
  /** variable that holds the current user id  */
  userId = '';

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.ticketId = this.activatedRoute.snapshot.params.id;
    this.getTicketData();
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.userId = user._id;
    }
  }

  /** Handles the click on the back button */
  onBackClick(): void {
    this.navigationService.navigateTo({ path: 'notifications' });
  }

  /** Retrieves ticket data from the backend */
  getTicketData(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.TICKET_API + this.ticketId }).subscribe(
      (response) => {
        this.ticket = response;
        if (this.ticket.ultimoMensajeLeidoPorEstudiante === false) {
          this.changeMessageToRead();
        }
      },
      (err) => {
        this.alertService.showError({ msg: 'There was an error, please try again later or contact support' });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
  /** Handles the sending of a message */
  onSendMessage(): void {
    if (this.message !== '') {
      const userId = this.userId;

      const today = new Date();
      const messageData = {
        remitente: userId,
        mensaje: this.message,
        fechaEnvio: today,
      };
      const messageList = this.ticket.mensajes;
      messageList.push(messageData);
      const ticketData = {
        mensajes: messageList,
        ultimoMensajeLeidoPorAdvisor: false,
        fechaUltimoMensaje: today,
      };
      this.apiService.put({ api: this.TICKET_API + this.ticketId, data: ticketData }).subscribe(
        (response) => {
          if (response.success === true) {
            this.alertService.showSuccess({ msg: 'Message sent' });
            this.message = '';
            this.ngOnInit();
          } else {
            this.alertService.showError({
              msg: 'There was an error sending the message, please try again later or contact support.',
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'There was an error sending the message, please try again later or contact support.',
          });
        }
      );
    }
  }

  /**
   * Update the not read messages status to read status in the backend
   * ->> Method is async, because user should not receive feedback about this process
   * @param param0 list of messages
   */
  async changeMessageToRead() {
    const data = {
      ultimoMensajeLeidoPorEstudiante: true,
    };
    this.apiService.put({ api: this.TICKET_API + this.ticketId, data }).subscribe(
      (response) => {
        // If response is correct don't do anything, this process is obscure to the user
      },
      (err) => {
        // If response is error, don't do anything, this process is obscure to the user
        // if it fails the strategy is to try again during the next connection
      }
    );
  }
}
