<div class="row">
  <div class="col-12">
    <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
    <h4 class="titulo-wizard">Conoce las competencias de un estudiante exitoso del mundo</h4>
    <p>
      Sabías que las instituciones internacionales valoran ciertas características en los estudiantes, que les
      permitirán a los postulados desarrollarse de manera exitosa en un ámbito académico y laboral internacional.
    </p>
    <hr class="hrLine" />
  </div>
  <div class="col-12">
    <p class="colorVerde">Dependiendo del tipo de institución necesitarás unas más que otras. ¡Conócelas todas!</p>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="card">
      <div class="card-body" id="card1">
        <div class="row">
          <div class="col-6">
            <div class="imgPre imgPre1"></div>
          </div>
          <div class="col-6">
            <h5 class="text-left">Liderazgo</h5>
            <em class="fas fa-solid fa-plus floatR" id="1" (click)="verdetalle(1)"></em>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" id="card2">
        <div class="row">
          <div class="col-6">
            <div class="imgPre imgPre2"></div>
          </div>
          <div class="col-6">
            <h5 class="text-left">Logro y Acción</h5>
            <em class="fas fa-solid fa-plus floatR" id="2" (click)="verdetalle(2)"></em>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" id="card3">
        <div class="row">
          <div class="col-6">
            <div class="imgPre imgPre3"></div>
          </div>
          <div class="col-6">
            <h5 class="text-left">Impacto Social</h5>
            <em class="fas fa-solid fa-plus floatR" id="3" (click)="verdetalle(3)"></em>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" id="card4">
        <div class="row">
          <div class="col-6">
            <div class="imgPre imgPre4"></div>
          </div>
          <div class="col-6">
            <h5 class="text-left">Competencia Académica</h5>
            <em class="fas fa-solid fa-plus floatR" id="4" (click)="verdetalle(4)"></em>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" id="card5">
        <div class="row">
          <div class="col-6">
            <div class="imgPre imgPre5"></div>
          </div>
          <div class="col-6">
            <h5 class="text-left">Global Mind</h5>
            <em class="fas fa-solid fa-plus floatR" id="5" (click)="verdetalle(5)"></em>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6 left-side">
    <h5 class="title-desc colorVerde" id="title-desc"></h5>

    <p class="intro-desc" id="intro-desc"></p>
    <h5 class="question-desc" id="question-desc"></h5>
    <p class="content-desc" id="content-desc"></p>
    <div id="video-desc"></div>
  </div>
</div>

<div class="row marginBottom">
  <div class="col-sm-6 flip-box"></div>
  <div class="col-sm-6 flip-box"></div>
</div>
<div class="row marginBottom">
  <div class="col-sm-6 flip-box"></div>
  <div class="col-sm-6 flip-box"></div>
</div>
<div class="row marginBottom">
  <div class="col-sm-6 flip-box"></div>
</div>
<div class="row">
  <div class="col-12">
    <p>¡Evaluemos tu nivel en cada una de estas áreas para definir la mejor ruta para tu futuro!</p>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn-continuar" (click)="emitNextButtonClick()">Continuar</button>
    <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" />
  </div>
</div>
