<div class="travel-history">
  <div class="travel-history-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back">
    <h3 class="travel-history-title"> {{ "Travel-history.travel-history" | translate }} </h3>
  </div>
  <div class="travel-history-form">
    <form [formGroup]="travelHistoryForm" (ngSubmit)="updatetUserInfo()">
      <div class="form-group">
        <label for="passport" class="form-label"> {{ "Travel-history.passport-number" | translate }} </label>
        <input class="form-input" name="passport" id="passport" type="text" [placeholder]="'Travel-history.passport-number'|translate"
          formControlName="numeroPasaporte">
      </div>
      <div class="form-group">
        <label for="country" class="form-label"> {{ "Travel-history.passport-country" | translate }} </label>
        <input class="form-input" name="country" id="country" type="text" [placeholder]="'Travel-history.passport-country'| translate"
          formControlName="paisPasaporte">
      </div>
      <div class="form-group">
        <label for="exp-date" class="form-label"> {{ "Travel-history.passport-exp-date" | translate }} </label>
        <input class="form-input" name="exp-date-" id="exp-date" type="date" placeholder="dd/mm/yyyy"
          formControlName="vencimientoPasaporte">
      </div>
      <label for="upload-passport-input" id="upload-passport">
        <img *ngIf="!uploading" class="upload-image" src="assets/utils/camera.png" alt="camera">
        <span *ngIf="!uploading"> {{ "Travel-history.upload-passport" | translate }} </span>
        <input (change)="onUploadChange({event: $event})" id="upload-passport-input" type="file">
        <mat-spinner class="uploading-spinner" *ngIf="uploading" diameter="20"></mat-spinner>
      </label>
      <div class="passport-value" *ngIf="passport.value !== ''">
        <a [href]="passport.value" target="_blank"> {{ "Travel-history.view-passport" | translate }} </a>
      </div>
      <div class="form-group">
        <label for="travel-last-3" class="form-label"> {{ "Travel-history.travel-last-3" | translate }} </label>
        <input class="form-input" name="travel-last-3" id="travel-last-3" type="text" placeholder="Brasil, USA, Perú, etc..."
          formControlName="viajesUltimos3Anios">
      </div>
      <div class="form-group">
        <label for="travel-last-5" class="form-label"> {{ "Travel-history.travel-last-5" | translate }} </label>
        <input class="form-input" name="travel-last-5" id="travel-last-5" type="text" placeholder="Brasil, USA, Perú, etc..."
          formControlName="viajes3A5Anios">
      </div>
      <div class="form-group">
        <div class="form-title"> {{ "Travel-history.have-you-had" | translate }} </div>
        <div class="radio-group">
          <input [value]="true" formControlName="leHanNegadoVisas" type="radio" class="form-radio"
            id="rejected-visa-yes">
          <label for="rejected-visa-yes" class="form-label"> {{ "Travel-history.yes" | translate }} </label>
        </div>
        <div class="radio-group">
          <input [value]="false" formControlName="leHanNegadoVisas" class="form-radio" type="radio"
            id="rejected-visa-no">
          <label for="rejected-visa-no" class="form-label"> {{ "Travel-history.no" | translate }} </label>
        </div>
      </div>
      <div class="sub-form-list" formArrayName="negaciones">
        <div class="form-title"> {{ "Travel-history.if-yes-please-list" | translate }}</div>
        <div class="sub-form" *ngFor="let denial of denials.controls; index as i" [formGroupName]="i">
          <div class="form-group">
            <label [for]="'country' + i" class="form-label"> {{ "City.country" | translate }} </label>
            <input formControlName="pais" class="form-input" [id]="'country' + i" type="text" [placeholder]="'City.country'|translate">
          </div>
          <div class="form-group">
            <label [for]="'reason' + i" class="form-label"> {{ "Travel-history.denial-reason" | translate }} </label>
            <input formControlName="motivo" class="form-input" [id]="'reason' + i" type="text"
              [placeholder]="'Travel-history.denial-reason'|translate">
          </div>
          <div class="wrapper">
            <div class="form-group">
              <label [for]="'date' + i" class="form-label"> {{ "Travel-history.denial-date" | translate }} </label>
              <input formControlName="fecha" class="form-input" [id]="'date' + i" type="text" placeholder="dd/mm/yyyy">
            </div>
            <button type="button" (click)="onRemoveVisaDenial({index: i})" class="delete-button"> {{
              "Travel-history.delete" | translate }} </button>
          </div>
        </div>
        <button type="button" (click)="onAddVisaDenial()" id="add-denial"> {{ "Travel-history.add-denial" | translate }}
        </button>
      </div>
      <button *ngIf="!loading" id="save-changes-button" type="submit"> {{ "Travel-history.save-changes" | translate }}
      </button>
      <mat-spinner *ngIf="loading" diameter="40"></mat-spinner>
    </form>
  </div>
</div>
