import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss']
})
export class TicketCardComponent implements OnInit {

  /** CONSTANTS */
  readonly ticket_API = 'api/ticket/'

  /** VARIABLES */
  /** Input that contains the ticket */
  @Input() ticket: any;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  /** Adds the ticket to the benchmark */
  onAddToBenchmark(): void {
    const data = {
      destacado: true
    };
    this.apiService.put({api: this.ticket_API + this.ticket._id, data}).subscribe((response) => {
      this.ticket.destacado = true;
      this.alertService.showSuccess({msg: 'Added to benchmarks'});
    }, err => {
      this.alertService.showError({msg: 'There was an error, please try again later or contact support'});
    });
  }
  /** Removes the ticket from the benchmark section*/
  onRemoveFromBenchMark(): void {
    const data = {
      destacado: false
    };
    this.apiService.put({api: this.ticket_API + this.ticket._id, data}).subscribe((response)=> {
      this.ticket.destacado = false;
      this.alertService.showSuccess({msg: 'Removed from benchmarks'});
    }, err => {
      this.alertService.showError({msg: 'There was an error, please try again later or contact support'});
    });
  }

  /** Sends the user to the ticket detail page */
  onGoToDetail(): void {
    this.navigationService.navigateTo({path: 'ticket/' + this.ticket._id});
  }

}
