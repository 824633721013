<div class="language-test-shop-card">
  <img class="language-test-shop-card-img" [src]="image" alt="language test">
  <div class="language-test-shop-card-data">
    <div class="data-header">
      <mat-icon class="remove-button" (click)="onRemoveServiceClick()"> close </mat-icon>
    </div>
    <div class="language-test-shop-title">
      <span class="test-name">{{name}}</span> <span *ngIf="type !== 'vocacional'">: {{language}}</span>
    </div>
    <div class="cost">
      ${{cost | number}} {{currency}}
    </div>
  </div>
</div>
