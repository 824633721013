import { NgxSpinnerService } from 'ngx-spinner';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { S3Service } from '../../../services/s3.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/public/services/navigation.service';
import { FormArray } from '@angular/forms';


@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit, OnChanges {
  // this.translate.instant("Application-first.")
  @Input() stepData: any;
  @Output() updateUploadedDocument = new EventEmitter<any>();
  @Output() updateAdditionalDocuments = new EventEmitter<any>();
  @Output() updateGeneralInformationForm = new EventEmitter<any>();
  @Output() updateTermsAccepted = new EventEmitter<any>();

  /** Variable that holds the value of the application fee */
  @Input() applicationFee;
  /** Variable that holds the currency of the payment */
  @Input() currency;
  /** Variable that signals if the applictaion fee has benn confirmed  */
  @Input() applicationFeeConfirmed;

  /** Variable that holds the list of substeps */
  subSteps = [
    {
      name: `1. ${this.translate.instant("Application-first.fill-the-application-form")}`,
      status: 'not-started',
    }, {
      name: `2. ${this.translate.instant("Application-second.upload-documents-title")}`,
      status: 'not-started',
    }, {
      name: `3. ${this.translate.instant("Application-third.pay-application-fee-title")}`,
      status: 'not-started',
    }, {
      name: `4. ${this.translate.instant("Application-fourth.apply-to-the-university-title")}`,
      status: 'not-started',
    }];
  /** Variable that consolidated data of the substeps */
  apply = {
    formularioInformacion: {
      formulario: {
        nombre: '',
        apellidos: '',
        fechaNacimiento: undefined,
        sexo: '',
        ciudadania: '',
        ciudadDeResidencia: '',
        paisDeResidencia: '',
        direccion: '',
        telefono: '',
        email: '',
        estudiosRealizados: '',
        idiomas: [],
      },
      fechaMaxima: '',
      completado: false,
      fechaCompletado: '',
    },
    documentos: {
      pasaporte: '',
      pasaporteAprobado: '',
      motivoRechazoPasaporte: '',
      diploma: '',
      diplomaAprobado: '',
      motivoRechazoDiploma: '',
      notas: '',
      notasAprobado: '',
      motivoRechazoNotas: '',
      diplomaPregrado: '',
      diplomaPregradoAprobado: '',
      motivoRechazoDiplomaPregrado: '',
      notasPregrado: '',
      notasPregradoAprobado: '',
      motivoRechazoNotasPregrado: '',
      diplomaTraducido: '',
      diplomaAprobadoTraducido: '',
      motivoRechazoDiplomaTraducido: '',
      notasTraducido: '',
      notasAprobadoTraducido: '',
      motivoRechazoNotasTraducido: '',
      diplomaPregradoTraducido: '',
      diplomaPregradoAprobadoTraducido: '',
      motivoRechazoDiplomaPregradoTraducido: '',
      notasPregradoTraducido: '',
      notasPregradoAprobadoTraducido: '',
      motivoRechazoNotasPregradoTraducido: '',
      documentosAdicionales: [],
      fechaMaxima: '',
      completado: false,
      fechaCompletado: '',
    },
    feePagado: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
      comprobantePago: '',
      aceptacionTerminosTerceros: false,
    },
    aplicacionUniversidad: {
      completado: false,
      fechaMaxima: '',
      fechaCompletado: '',
    }
  };

  /** Variable that signals which substep is currently selected */
  selected = 0;

  /** Variable that holds the application id of the current application */
  applicationId: string;

  /** Variable that signals if the component is currently loading */
  loading = false;

  constructor(
    private s3Service: S3Service,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    this.setupData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupData();
  }

  /**
   * Setups the data of the component
   */
  setupData(): void {
    if (this.stepData !== undefined) {
      // Setup the first substep
      this.apply.formularioInformacion.completado = this.stepData.formularioInformacion.completado;
      if (this.apply.formularioInformacion.completado === true) {
        this.subSteps[0].status = 'completed';
      } else {
        if (this.stepData.formularioInformacion.formulario !== undefined && this.stepData.formularioInformacion.formulario !== null) {
          if ((this.stepData.formularioInformacion.formulario.nombre && this.stepData.formularioInformacion.formulario.nombre !== '')
            || (this.stepData.formularioInformacion.formulario.apellidos && this.stepData.formularioInformacion.formulario.apellidos !== '')
            || (this.stepData.formularioInformacion.formulario.fechaNacimiento && this.stepData.formularioInformacion.formulario.fechaNacimiento !== '')
            || (this.stepData.formularioInformacion.formulario.sexo && this.stepData.formularioInformacion.formulario.sexo !== '')
            || (this.stepData.formularioInformacion.formulario.ciudadania && this.stepData.formularioInformacion.formulario.ciudadania !== '')
            || (this.stepData.formularioInformacion.formulario.paisDeResidencia && this.stepData.formularioInformacion.formulario.paisDeResidencia !== '')
            || (this.stepData.formularioInformacion.formulario.direccion && this.stepData.formularioInformacion.formulario.direccion !== '')
            || (this.stepData.formularioInformacion.formulario.email && this.stepData.formularioInformacion.formulario.email !== '')
            || (this.stepData.formularioInformacion.formulario.estudiosRealizados && this.stepData.formularioInformacion.formulario.estudiosRealizados !== '')
            || (this.stepData.formularioInformacion.formulario.idiomas.length > 0)) {
              this.subSteps[0].status = 'progress';
            }
           else {
            this.subSteps[0].status = 'not-started';
           }
        } else {
          this.subSteps[0].status = 'not-started';
        }
      }
      if (this.stepData.formularioInformacion.formulario !== undefined) {
        if (this.stepData.formularioInformacion.formulario.nombre !== undefined) {
          this.apply.formularioInformacion.formulario.nombre = this.stepData.formularioInformacion.formulario.nombre;
        }
        if (this.stepData.formularioInformacion.formulario.apellidos !== undefined) {
          this.apply.formularioInformacion.formulario.apellidos = this.stepData.formularioInformacion.formulario.apellidos;
        }
        if (this.stepData.formularioInformacion.formulario.fechaNacimiento !== undefined) {
          this.apply.formularioInformacion.formulario.fechaNacimiento =
            new Date(this.stepData.formularioInformacion.formulario.fechaNacimiento).toISOString().substring(0, 10);
        }
        if (this.stepData.formularioInformacion.formulario.sexo !== undefined) {
          this.apply.formularioInformacion.formulario.sexo = this.stepData.formularioInformacion.formulario.sexo;
        }
        if (this.stepData.formularioInformacion.formulario.ciudadania !== undefined) {
          this.apply.formularioInformacion.formulario.ciudadania = this.stepData.formularioInformacion.formulario.ciudadania;
        }
        if (this.stepData.formularioInformacion.formulario.ciudadDeResidencia !== undefined) {
          this.apply.formularioInformacion.formulario.ciudadDeResidencia = this.stepData.formularioInformacion.formulario.ciudadDeResidencia;
        }
        if (this.stepData.formularioInformacion.formulario.paisDeResidencia !== undefined) {
          this.apply.formularioInformacion.formulario.paisDeResidencia = this.stepData.formularioInformacion.formulario.paisDeResidencia;
        }
        if (this.stepData.formularioInformacion.formulario.direccion !== undefined) {
          this.apply.formularioInformacion.formulario.direccion = this.stepData.formularioInformacion.formulario.direccion;
        }
        if (this.stepData.formularioInformacion.formulario.telefono !== undefined) {
          this.apply.formularioInformacion.formulario.telefono = this.stepData.formularioInformacion.formulario.telefono;
        }
        if (this.stepData.formularioInformacion.formulario.email !== undefined) {
          this.apply.formularioInformacion.formulario.email = this.stepData.formularioInformacion.formulario.email;
        }
        if (this.stepData.formularioInformacion.formulario.estudiosRealizados !== undefined) {
          this.apply.formularioInformacion.formulario.estudiosRealizados = this.stepData.formularioInformacion.formulario.estudiosRealizados;
        }
        if (this.stepData.formularioInformacion.formulario.idiomas !== undefined) {
          this.apply.formularioInformacion.formulario.idiomas = this.stepData.formularioInformacion.formulario.idiomas;
        }
      }
      // Setup second substep
      this.apply.documentos.completado = this.stepData.documentos.completado;
      this.apply.documentos.pasaporte = this.stepData.documentos.pasaporte;
      this.apply.documentos.pasaporteAprobado = this.stepData.documentos.pasaporteAprobado;
      this.apply.documentos.motivoRechazoPasaporte = this.stepData.documentos.motivoRechazoPasaporte;
      this.apply.documentos.diploma = this.stepData.documentos.diploma;
      this.apply.documentos.diplomaAprobado = this.stepData.documentos.diplomaAprobado;
      this.apply.documentos.motivoRechazoDiploma = this.stepData.documentos.motivoRechazoDiploma;
      this.apply.documentos.notas = this.stepData.documentos.notas;
      this.apply.documentos.notasAprobado = this.stepData.documentos.notasAprobado;
      this.apply.documentos.motivoRechazoNotas = this.stepData.documentos.motivoRechazoNotas;
      this.apply.documentos.diplomaPregrado = this.stepData.documentos.diplomaPregrado;
      this.apply.documentos.diplomaPregradoAprobado = this.stepData.documentos.diplomaPregradoAprobado;
      this.apply.documentos.motivoRechazoDiplomaPregrado = this.stepData.documentos.motivoRechazoDiplomaPregrado;
      this.apply.documentos.notasPregrado = this.stepData.documentos.notasPregrado;
      this.apply.documentos.notasPregradoAprobado = this.stepData.documentos.notasPregradoAprobado;
      this.apply.documentos.motivoRechazoNotasPregrado = this.stepData.documentos.motivoRechazoNotasPregrado;

      this.apply.documentos.diplomaTraducido = this.stepData.documentos.diplomaTraducido;
      this.apply.documentos.diplomaAprobadoTraducido = this.stepData.documentos.diplomaAprobadoTraducido;
      this.apply.documentos.motivoRechazoDiplomaTraducido = this.stepData.documentos.motivoRechazoDiplomaTraducido;
      this.apply.documentos.notasTraducido = this.stepData.documentos.notasTraducido;
      this.apply.documentos.notasAprobadoTraducido = this.stepData.documentos.notasAprobadoTraducido;
      this.apply.documentos.motivoRechazoNotasTraducido = this.stepData.documentos.motivoRechazoNotasTraducido;
      this.apply.documentos.diplomaPregradoTraducido = this.stepData.documentos.diplomaPregradoTraducido;
      this.apply.documentos.diplomaPregradoAprobadoTraducido = this.stepData.documentos.diplomaPregradoAprobadoTraducido;
      this.apply.documentos.motivoRechazoDiplomaPregradoTraducido = this.stepData.documentos.motivoRechazoDiplomaPregradoTraducido;
      this.apply.documentos.notasPregradoTraducido = this.stepData.documentos.notasPregradoTraducido;
      this.apply.documentos.notasPregradoAprobadoTraducido = this.stepData.documentos.notasPregradoAprobadoTraducido;
      this.apply.documentos.motivoRechazoNotasPregradoTraducido = this.stepData.documentos.motivoRechazoNotasPregradoTraducido;

      this.apply.documentos.documentosAdicionales = this.stepData.documentos.documentosAdicionales;
      if (this.apply.documentos.completado) {
        this.subSteps[1].status = 'completed';
      } else if (this.apply.documentos.pasaporte || this.apply.documentos.diploma || this.apply.documentos.notas
        || this.apply.documentos.diplomaPregrado || this.apply.documentos.notasPregrado || this.apply.documentos.diplomaTraducido
        || this.apply.documentos.notasTraducido || this.apply.documentos.diplomaPregradoTraducido || this.apply.documentos.notasPregradoTraducido) {
        this.subSteps[1].status = 'progress';
      } else {
        this.subSteps[1].status = 'not-started';
      }

      // Setup the third
      this.apply.feePagado.completado = this.stepData.feePagado.completado;
      this.apply.feePagado.comprobantePago = this.stepData.feePagado.comprobantePago;
      if (this.apply.feePagado.completado) {
        this.subSteps[2].status = 'completed';
      } else if (this.stepData.feePagado.comprobantePago && !this.apply.feePagado.completado) {
        this.subSteps[2].status = 'progress';
      }
      else {
        this.subSteps[2].status = 'not-started';
      }
      if( this.stepData.feePagado.aceptacionTerminosTerceros !== undefined) {
        this.apply.feePagado.aceptacionTerminosTerceros = this.stepData.feePagado.aceptacionTerminosTerceros;
      }

      // Setup fourth substep
      this.apply.aplicacionUniversidad.completado = this.stepData.aplicacionUniversidad.completado;
      if (this.apply.aplicacionUniversidad.completado) {
        this.subSteps[3].status = 'completed';
      } else {
        this.subSteps[3].status = 'not-started';
      }

    }
  }

  /**
   * Changes the substep that is being displayed
   * @param index index of the new substep
   */
  onSubstepChange({ index }: { index: number }): void {
    this.selected = index;
  }

  /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event, nombreDoc }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant("Academic-history.you-must-upload-1-file"));
    } else {
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const key = `application/${this.applicationId}/documents/${nombreDoc}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinnerService.hide();
          this.alertService.showError({ msg: this.translate.instant("Academic-history.load-error") });
          this.loading = false;
        } else {
          // Assign the value to the corresponding component
          this.loading = false;
          switch (nombreDoc) {
            case 'passport':
              this.updateUploadedDocument.emit({ docName: 'passport', url: data.Location });
              break;
            case 'school-diploma':
              this.updateUploadedDocument.emit({ docName: 'school-diploma', url: data.Location });
              break;
            case 'school-grades':
              this.updateUploadedDocument.emit({ docName: 'school-grades', url: data.Location });
              break;
            case 'undergraduate-diploma':
              this.updateUploadedDocument.emit({ docName: 'undergraduate-diploma', url: data.Location });
              break;
            case 'undergraduate-grades':
              this.updateUploadedDocument.emit({ docName: 'undergraduate-grades', url: data.Location });
              break;
            case 'payment-proof':
              this.updateUploadedDocument.emit({ docName: 'payment-proof', url: data.Location });
              break;
            case 'school-diploma-translated':
              this.updateUploadedDocument.emit({ docName: 'school-diploma-translated', url: data.Location });
              break;
            case 'school-grades-translated':
              this.updateUploadedDocument.emit({ docName: 'school-grades-translated', url: data.Location });
              break;
            case 'undergraduate-diploma-translated':
              this.updateUploadedDocument.emit({ docName: 'undergraduate-diploma-translated', url: data.Location });
              break;
            case 'undergraduate-grades-translated':
              this.updateUploadedDocument.emit({ docName: 'undergraduate-grades-translated', url: data.Location });
              break;
          }
          this.ngxSpinnerService.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

  /**
   * Manages upload events in the file inputs
   * @param param0 event: Upload event, index: input type file input
   */
  onAdditionalDocumentsUploadChange({ event, index }): void {
    const documentList = this.apply.documentos.documentosAdicionales.slice();
    if (event.target.files.length > 1) {
      this.alertService.showError({ msg: this.translate.instant("Academic-history.you-must-upload-1-file") });
    } else {
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const randomkey = this.s3Service.generateKey();
      const completeKey = `application/${this.applicationId}/documents/${randomkey}`;
      documentList[index].awsKey = completeKey;
      const callback = (err, data) => {
        if (err) {
          this.ngxSpinnerService.hide();
          alert(this.translate.instant("Academic-history.load-error"));
          this.loading = false;
        } else {
          this.ngxSpinnerService.hide();
          documentList[index].archivo = data.Location;
          this.loading = false;
        }
        this.updateAdditionalDocuments.emit({ documentList });
      };
      this.loading = true;
      this.s3Service.uploadFile({ file, key: completeKey, callback });
    }
  }

  /**
   * Adds a language to the list of languages of the formularioInformacion form
   */
  onAddLanguageClick(): void {
    const language = {
      nombre: '',
      nivel: '',
      certificados: [],
    };
    this.apply.formularioInformacion.formulario.idiomas.push(language);
  }

  /**
   * Adds a certificate to the list of certificates of a specified language
   * @param index index of the language in the array of language
   */
  onAddCertificate({ index }: { index: number }): void {
    const certificate = {
      nombre: '',
      calificacion: '',
      archivo: '',
    };
    this.apply.formularioInformacion.formulario.idiomas[index].certificados.push(certificate);
  }

  /**
   * Removes a language from the list of languages
   * @param index index of the language
   */
  onRemoveLanguage({ index: i }): void {
    this.apply.formularioInformacion.formulario.idiomas.splice(i, 1);
  }

  /**
   * Removes a certificate from the list of certificates of the specified language
   * @param langIndex index of the language in the languages array
   * @param certIndex index of the certificate in the certificates array
   */
  onRemoveCertificate({ langIndex, certIndex }): void {
    this.apply.formularioInformacion.formulario.idiomas[langIndex].certificados.splice(certIndex, 1);
  }

  /**
   * Submits the formularioInformacion form to the backend
   */
  onSaveFormClick(): void {
    const formValue = this.apply.formularioInformacion.formulario;
    this.updateGeneralInformationForm.emit({ formValue });
  }

  /**
   * Handles the click on the accept terms checkbox
   */
  onTermsAccepted(): void {
    this.updateTermsAccepted.emit();
  }

  /** Handles the click on the make payment button of the application fee */
  onMakePayment(): void {
    this.navigationService.navigateTo({path: 'application-fee/' + this.applicationId});
  }

  /**
   * Uploads a new file to S3
   * @param event upload event emmited by the input type file
   * @param langIndex index of the language in the languages array
   * @param certIndex index of the certificate in the certificates array of the language
   */
  onUploadLanguageCertificate({
    event,
    langIndex,
    certIndex,
  }: {
    event: any;
    langIndex: number;
    certIndex: number;
  }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('My-languages.you-must-upload-1-file'));
    } else {
      this.ngxSpinnerService.show();
      const subKey = this.s3Service.generateKey();
      const languageName = this.apply.formularioInformacion.formulario.idiomas[langIndex].nombre;
      const file = event.target.files[0];
      const key = `user/${this.applicationId}/languages/${languageName}/certificates/${subKey}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinnerService.hide();
          this.alertService.showError({
            msg: this.translate.instant(
              'My-languages.upload-certificate-error'
            ),
          });
        } else {
          // Assign the value to the corresponding component
          const certs = this.apply.formularioInformacion.formulario.idiomas[langIndex].certificados;
          certs[certIndex].archivo = data.Location;
          this.ngxSpinnerService.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

}
