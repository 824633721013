import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { S3Service } from 'src/app/public/services/s3.service';
import { NavigationService } from 'src/app/public/services/navigation.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  readonly apiPost = 'api/hoja-vida/create';
  readonly apiGet = 'api/hoja-vida/getById/';
  readonly apiPut = 'api/hoja-vida/update/';
  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser/';
  readonly apiCountries = 'api/pais';
  readonly GET_LOGOS_UNIVERSITIES = 'api/universidades/logos';

  /** Variable that holds the login form */
  editar: FormGroup;
  discipline: Array<any> = [];
  /** Variable that holds the id of the current user */
  id: string;
  idHoja: string;
  readonly settingsA: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    itemsShowLimit: 3,
  };

  areas = [
    {
      nombre: 'Arte, diseño y arquitectura',
      selected: false,
    },
    {
      nombre: 'Negocios y Administración',
      selected: false,
    },
    {
      nombre: 'Ciencias de la Computación y Tecnología',
      selected: false,
    },
    {
      nombre: 'Ingeniería',
      selected: false,
    },
    {
      nombre: 'Ciencias y Matemáticas',
      selected: false,
    },
    {
      nombre: 'Ciencias Sociales y Humanidades',
      selected: false,
    },
    {
      nombre: 'Educación ó Deporte',
      selected: false,
    },
    {
      nombre: 'Servicios Comunitarios',
      selected: false,
    },
    {
      nombre: 'Estudios Ambientales y Ciencias de la Tierra',
      selected: false,
    },
    {
      nombre: 'Salud',
      selected: false,
    },
    {
      nombre: 'Periodismo y Medios de Comunicación',
      selected: false,
    },
    {
      nombre: 'Derecho y Ciencias Políticas',
      selected: false,
    },
    {
      nombre: 'Idiomas',
      selected: false,
    },
    {
      nombre: 'Turismo y Hospitalidad',
      selected: false,
    },
  ];
  paises: any;

  dataUniversidades: any;
  universitiesSelected = [];
  readonly optionsUniversities: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };

  userTest: any;
  user = JSON.parse(localStorage.getItem('user'));
  paisesFiltrados = [];
  selectedCountries = [];
  areasFiltradas = [];
  linked: any;
  fotoBaner: any;
  fotoPerfil: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí  (máx. 125 palabras)',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '3',
    fonts: [{ class: 'arial', name: 'Arial' }],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript', 'heading', 'fontName'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode',
      ],
    ],
  };
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private formBuilderService: FormBuilder,
    @Inject(PLATFORM_ID) private plaformId,
    private ngxSpinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private ngxSpinner: NgxSpinnerService,
    private s3Service: S3Service,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.editar = this.formBuilderService.group({
      nombre: ['', [Validators.required]],
      colegio: ['', [Validators.required]],
      proyecto: ['', [Validators.required]],
      palabrasClave: ['', [Validators.required]],
      individualProfile: ['', [Validators.required]],
      achievements: ['', [Validators.required]],
      academicRecord: ['', [Validators.required]],
      employmentHistory: ['', [Validators.required]],
      relevantSkills: ['', [Validators.required]],
      aditionalFormation: ['', [Validators.required]],
      sobreMi: ['', [Validators.required]],
      idioma1: ['', [Validators.required]],
      idioma1Nivel: ['', [Validators.required]],
      idioma2: ['', [Validators.required]],
      idioma2Nivel: ['', [Validators.required]],
      idioma3: ['', [Validators.required]],
      idioma3Nivel: ['', [Validators.required]],
      idioma4: ['', [Validators.required]],
      idioma4Nivel: ['', [Validators.required]],
      logros: this.formBuilderService.array([]),
      referencias: this.formBuilderService.array([]),
      universities: this.formBuilderService.array([]),
    });
    this.getCountries();
    this.getUserinfo();
    this.getUniversitiesLogos();
  }

  getUserinfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.apiGet + this.user._id }).subscribe(
      (response) => {
        if (response) {
          this.idHoja = response._id;
          const userName = this.user.nombre ? this.user.nombre : this.user.primerNombre;
          const lastName = this.user.apellido ? this.user.apellido : this.user.primerApellido;
          this.editar.get('nombre').setValue(userName + ' ' + lastName);
          this.editar.get('colegio').setValue(response.colegio);
          this.editar.get('proyecto').setValue(response.proyecto);
          this.editar.get('palabrasClave').setValue(response.palabrasClave);
          this.editar.get('sobreMi').setValue(response.sobreMi);
          this.editar.get('individualProfile').setValue(response.individualProfile);
          this.editar.get('achievements').setValue(response.achievements);
          this.editar.get('academicRecord').setValue(response.academicRecord);
          this.editar.get('employmentHistory').setValue(response.employmentHistory);
          this.editar.get('relevantSkills').setValue(response.relevantSkills);
          this.editar.get('aditionalFormation').setValue(response.aditionalFormation);
          this.linked = response.link ? response.link : null;
          this.universitiesSelected = response.universidadInteres;
          this.selectedCountries = response.paisInteres;
          for (const [index, idioma] of response.idioma.entries()) {
            if (index == 0) {
              this.editar.get('idioma1').setValue(idioma.nombre);
              this.editar.get('idioma1Nivel').setValue(idioma.nivel);
            }
            if (index == 1) {
              this.editar.get('idioma2').setValue(idioma.nombre);
              this.editar.get('idioma2Nivel').setValue(idioma.nivel);
            }
            if (index == 2) {
              this.editar.get('idioma3').setValue(idioma.nombre);
              this.editar.get('idioma3Nivel').setValue(idioma.nivel);
            }
            if (index == 3) {
              this.editar.get('idioma4').setValue(idioma.nombre);
              this.editar.get('idioma4Nivel').setValue(idioma.nivel);
            }
          }

          for (const area of response.areaInteres) {
            for (const areaSelect of this.areas) {
              if (area.nombre == areaSelect.nombre) {
                areaSelect.selected = true;
                this.areasFiltradas.push(areaSelect);
              }
            }
          }

          for (const logro of response.logros) {
            const logroForm = this.formBuilderService.group({
              descripcion: logro.descripcion,
            });
            this.logros.push(logroForm);
          }

          for (const referencia of response.referencias) {
            const referenciaForm = this.formBuilderService.group({
              nombre: referencia.nombre,
              cargo: referencia.cargo,
              nota: referencia.nota,
            });
            this.referencias.push(referenciaForm);
          }

          if (response.fotoBaner) {
            this.fotoBaner = response.fotoBaner;
          }

          if (response.fotoPerfil) {
            this.fotoPerfil = response.fotoPerfil;
          }

          this.ngxSpinnerService.hide();
        } else {
          this.editar.get('nombre').setValue(this.user.nombre + ' ' + this.user.apellido);
          this.ngxSpinnerService.hide();
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        });
      }
    );
  }

  /**
   * Handles login form submission
   */
  onSubmit(): void {
    this.save();
  }

  eliminarReferencia1(): void {
    this.editar = this.formBuilderService.group({
      referencia1Nombre: ['', [Validators.required]],
      referencia1Cargo: ['', [Validators.required]],
      referencia1Nota: ['', [Validators.required]],
    });
  }

  eliminarReferencia2(): void {
    this.editar = this.formBuilderService.group({
      referencia2Nombre: ['', [Validators.required]],
      referencia2Cargo: ['', [Validators.required]],
      referencia2Nota: ['', [Validators.required]],
    });
  }

  save() {
    this.ngxSpinnerService.show();
    let dataIdiomas = [];

    if (this.editar.get('idioma1').value && this.editar.get('idioma1Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma1').value,
        nivel: this.editar.get('idioma1Nivel').value,
      });
    }
    if (this.editar.get('idioma2').value && this.editar.get('idioma2Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma2').value,
        nivel: this.editar.get('idioma2Nivel').value,
      });
    }
    if (this.editar.get('idioma3').value && this.editar.get('idioma3Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma3').value,
        nivel: this.editar.get('idioma3Nivel').value,
      });
    }
    if (this.editar.get('idioma4').value && this.editar.get('idioma4Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma4').value,
        nivel: this.editar.get('idioma4Nivel').value,
      });
    }

    let data: any = {
      colegio: this.editar.get('colegio').value,
      proyecto: this.editar.get('proyecto').value,
      palabrasClave: this.editar.get('palabrasClave').value,
      sobreMi: this.editar.get('sobreMi').value,
      individualProfile: this.editar.get('individualProfile').value,
      achievements: this.editar.get('achievements').value,
      academicRecord: this.editar.get('academicRecord').value,
      employmentHistory: this.editar.get('employmentHistory').value,
      relevantSkills: this.editar.get('relevantSkills').value,
      aditionalFormation: this.editar.get('aditionalFormation').value,
      idioma: dataIdiomas,
      areaInteres: this.areasFiltradas,
      paisInteres: this.selectedCountries.map((pais) => ({ nombre: pais })),

      logros: this.editar.get('logros').value,
      referencias: this.editar.get('referencias').value,
      universidadInteres: this.universitiesSelected,
      link: this.linked,
    };

    if (!this.idHoja) {
      data.usuario = this.user._id;
      this.apiService.post({ api: this.apiPost, data }).subscribe(
        (response) => {
          if (response.success) {
            this.alertService.showSuccess({
              msg: 'Se ha creado la hoja de vida de manera exitosa',
            });
          } else {
            this.alertService.showError({
              msg: 'Ha ocurrido un error.',
            });
          }

          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        },
        (err) => {
          this.alertService.showError({ msg: 'Ha ocurrido un error.' });
        }
      );
    } else {
      this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe(
        (response) => {
          this.alertService.showSuccess({
            msg: 'Se han actualizado los datos con exito',
          });
          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        },
        (err) => {
          this.alertService.showError({ msg: 'Ha ocurrido un error.' });
          this.ngxSpinnerService.hide();
        }
      );
    }
  }

  backToCurriculum() {
    this.navigationService.navigateTo({ path: 'curriculum' });
  }

  paisAreaInteres(type, value, index) {
    if (type == 'area') {
      this.areas[index].selected = !this.areas[index].selected;

      if (this.areas[index].selected) {
        this.areasFiltradas.push(this.areas[index]);
      } else {
        let filter = this.areasFiltradas.filter((e) => {
          return this.areas[index].nombre != e.nombre;
        });
        this.areasFiltradas = filter;
      }
    }
  }

  get logros(): FormArray {
    return this.editar.get('logros') as FormArray;
  }
  addLogros() {
    const logrosAgregar = this.formBuilderService.group({
      descripcion: '',
    });
    this.logros.push(logrosAgregar);
  }

  removeLogros({ index }) {
    this.logros.removeAt(index);
  }

  get referencias(): FormArray {
    return this.editar.get('referencias') as FormArray;
  }

  addReferencia() {
    const referencia = this.formBuilderService.group({
      nombre: '',
      cargo: '',
      nota: '',
    });
    this.referencias.push(referencia);
  }

  removeReferencia({ index }) {
    this.referencias.removeAt(index);
  }

  /**
   * Uploads an image into S3
   * @param event image upload event
   */
  onChangeProfileImage({ event }: { event: any }): void {
    if (event.target.files.length > 1) {
      alert('Por favor sube una unica imagen');
    } else {
      this.ngxSpinner.show();
      const validImageTypes = ['image/jpeg', 'image/png'];
      const file = event.target.files[0];
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        alert('Por favor sube una imagen en formato jpg o png');
        this.ngxSpinner.hide();
      } else {
        const key = `user/${this.user._id}/profile-curriculum-image/`;

        const callback = (err, data) => {
          if (err) {
            this.ngxSpinner.hide();
            this.alertService.showError({
              msg: 'Hubo un error subiendo la imagen de perfil',
            });
          } else {
            let updateData = {
              fotoPerfil: data.Location,
            };

            this.updateHojaVida({ data: updateData });
            this.ngxSpinner.hide();
            this.backToCurriculum();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }

  uploadBannerImage({ event }) {
    if (event.target.files.length > 1) {
      alert('Por favor sube una unica imagen');
    } else {
      this.ngxSpinnerService.show();
      const validImageTypes = ['image/jpeg', 'image/png'];
      const file = event.target.files[0];
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        alert('Por favor sube una imagen en formato jpg o png');
        this.ngxSpinnerService.hide();
      } else {
        const key = `user/${this.user._id}/banner-image/`;

        const callback = (err, data) => {
          if (err) {
            this.ngxSpinnerService.hide();
            this.alertService.showError({
              msg: 'Hubo un error subiendo la imagen del baner',
            });
          } else {
            let updateData = {
              fotoBaner: data.Location,
            };
            this.updateHojaVida({ data: updateData });
            this.ngxSpinnerService.hide();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }

  /**
   * Updates the user information in the backend
   */
  updateProfilePhoto({ data }: { data: any }): void {
    this.ngxSpinner.show();
    this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe(
      (response) => {
        if (response.success === false) {
          this.alertService.showError({
            msg: this.translate.instant('Profile.upload-image-error'),
          });
        } else {
          this.ngOnInit();
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Profile.upload-image-error'),
        });
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  updateHojaVida({ data }) {
    this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe((response) => {
      this.backToCurriculum();
    });
  }

  getTestInternationalization({ userId }) {
    this.apiService.get({ api: this.GET_TEST + userId }).subscribe((response) => {
      this.userTest = response;
    });
  }
  getCountries() {
    this.apiService.get({ api: this.apiCountries }).subscribe((response) => {
      this.paises = response.map((country) => country.nombre);
    });
  }
  getUniversitiesLogos() {
    this.apiService.get({ api: this.GET_LOGOS_UNIVERSITIES }).subscribe((response) => {
      this.dataUniversidades = response;
    });
  }
  onChangeCountry(event) {
    for (const item of this.paises) {
      if (item === event) {
        const countryExists = this.selectedCountries.some((country) => country === event);
        if (!countryExists) {
          this.selectedCountries.push(item);
        }
      }
    }
  }
  onDeSelectCountry(event) {
    this.selectedCountries = this.selectedCountries.filter((country) => country !== event);
  }
  onSelect(event) {
    for (const item of this.dataUniversidades) {
      if (item.nombre == event.nombre) {
        this.universitiesSelected.push(item);
      }
    }
  }

  onDeSelect(event) {
    this.universitiesSelected = this.universitiesSelected.filter((uni) => uni.nombre !== event.nombre);
  }
}
