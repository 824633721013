<main class="component-container">
    <div class="row">
        <div class="col-lg-1 text-right display-mobile-none">
            <img class="back-button" src="/assets/curriculum/back.png" alt="back" (click)="backToStudentList()">
        </div>

        <div class="row col-12 col-lg-11 pr-0">

            <!-- Descripcion + datos varios -->
            <div class="col-lg-12 pr-0 d-flex align-items-center">
                <img class="profile-photo" [src]="profilePhoto" alt="Foto de perfil">
                <div class="row px-0 box-features">
                    <div class="col-xl-12 px-0 d-flex align-items-center">
                        <label class="student-name mb-0">{{studentName}}</label>
                        <img class="insignia" [src]="insignia" alt="Insignia">
                    </div>
                    <div class="col-xl-12 px-0">
                        <label class="features mb-0" *ngIf="grade"><strong>Grado: </strong>{{grade}}</label>
                        <label class="features mb-0" *ngIf="!grade"><strong>Grado: </strong>?</label>

                        <label class="features" *ngIf="age"><strong>Edad: </strong>{{age}} años</label>
                        <label class="features" *ngIf="!age"><strong>Edad: </strong>?</label>

                        <label class="features display-mobile-none"><strong>Areas de interes: </strong></label>
                        <ng-container *ngIf="areas.length>0 ; else noresultAreas">
                            <img class="area-icon" *ngFor="let area of areas" [src]="area.imagen" [alt]="area.nombre">
                        </ng-container>

                        <ng-template #noresultAreas>
                            <label>No hay resultados...</label>
                        </ng-template>
                    </div>
                    <div class="d-flex flex-wrap features-container display-mobile-none mt-2">
                        <div class="cv-container" (click)="goToCv()">
                            <img class="icon-button" src="/assets/student-resume/cv-azul.png" alt="cv">
                            <label class="feature-title">Hoja de vida</label>
                        </div>

                        <div class="dashboard-container" (click)="goToDashboard()">
                            <img class="icon-button" src="/assets/student-resume/dashboard-azul.png" alt="dashboard">
                            <label class="feature-title">Dashboard de competencias</label>
                        </div>

                        <div class="documents-container" (click)="goToDocuments()">
                            <img class="icon-button" src="/assets/student-resume/documentos-2-azul.png" alt="document">
                            <label class="feature-title">Documentos</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 pr-0 d-flex align-items-center display-mobile">
                <div class="d-flex flex-wrap features-container">
                  <div class="documents-container mt-2" (click)="goToDocuments()">
                    <img class="icon-button" src="/assets/student-resume/documentos-2-azul.png" alt="document">
                    <label class="feature-title">Documentos</label>
                  </div>
                  <div class="cv-container mt-2" (click)="goToCv()">
                    <img class="icon-button" src="/assets/student-resume/cv-azul.png" alt="cv">
                    <label class="feature-title">Hoja de vida</label>
                  </div>
                  <div class="dashboard-container mt-2" (click)="goToDashboard()">
                    <img class="icon-button" src="/assets/student-resume/dashboard-azul.png" alt="dashboard">
                    <label class="feature-title">Dashboard</label>
                  </div>
                </div>
            </div>


            <!-- Notas de referencia -->
            <div class="row col-xl-12 pr-0 section-spacing">
                <div class="col-xl-12">
                    <label class="section-title">Notas del mentor</label>
                </div>
                <ng-container *ngIf="references.length>0; else noresultReferences">
                    <div class="col-xl-12" *ngFor="let item of references" >
                        <p class="mb-0">
                            {{item.nota}}
                        </p>
                    </div>
                </ng-container>

                <ng-template #noresultReferences>
                    <div class="col-xl-12">
                        <label>No hay resultados...</label>
                    </div>
                </ng-template>
            </div>

            <!-- Test -->
            <div class="row col-12 pr-0 section-spacing">
                <div class="row col-12 col-lg-7 pr-0">
                    <div class="col-12 pr-0">
                        <label class="section-title mb-3 mb-lg-0">Puntaje global de competencias</label>
                    </div>
                    <ng-container *ngIf="hojaTest>=18 ; else noresultTestIA">
                        <div class="col-12 chart-container">
                            <app-radar-chart-component class="chart" [sorts]="sortsChart" *ngIf="sortsChart"></app-radar-chart-component>
                        </div>
                    </ng-container>

                    <ng-template #noresultTestIA>
                        <div class="col-xl-12">
                            <label>No hay resultados...</label>
                        </div>
                    </ng-template>
                </div>

                <div class="row col-12 col-lg-5 pr-0 mt-4 mt-lg-0">
                    <div class="col-lg-12 pr-0">
                        <label class="section-title mb-3 mb-lg-0">Resultados Test</label>
                    </div>

                    <!-- IA SKILLS -->
                    <div class="col-3 col-sm-2 col-md-1 col-xl-2 pr-0">
                        <img class="test-icon" src="/assets/pre-test/world.png" alt="">
                    </div>

                    <ng-container *ngIf="hojaTest>=18; else noresultIAProgress">
                        <div class="col-9 col-sm-10 col-md-11 col-xl-10 pr-0">
                            <div class="col-lg-12 px-0">
                                <label class="mb-0"><strong>IA Skills</strong></label>
                            </div>
    
                            <div class="col-lg-12 px-0 config-progress">
                                <progress [value]="generalScore" max="100"></progress>
                                <label class="pl-xl-4">{{generalScore}}%</label>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #noresultIAProgress>
                        <div class="col-9 col-sm-10 col-md-11 col-xl-10 pr-0">
                            <div class="col-lg-12 px-0">
                                <label class="mb-0"><strong>IA Skills</strong></label>
                            </div>

                            <div class="col-lg-12 px-0">
                                <label>No hay resultados...</label>
                            </div>
                        </div>
                    </ng-template>

                    <!-- CULTURAL MATCH -->
                    <div class="col-3 col-sm-2 col-md-1 col-xl-2">
                        <img class="test-icon" src="/assets/pre-test/world.png" alt="">
                    </div>
                    <ng-container *ngIf="countryCulturalMatch.length>0 ; else noresultCultural">
                        <div class="col-9 col-sm-10 col-md-11 col-xl-10">
                            <p class="cultural-match mb-0"><strong>Cultura Match</strong></p>
                            <p *ngFor="let item of countryCulturalMatch | slice:0:3" class="countries">
                                {{item}}
                            </p>
                        </div>
                    </ng-container>
                    
                    <ng-template #noresultCultural>
                        <div class="col-9 col-sm-10 col-md-11 col-xl-10">
                            <p class="cultural-match mb-0"><strong>Cultura Match</strong></p>
                            <label>No hay resultados...</label>
                        </div>
                    </ng-template>
                </div>

            </div>


            <!-- Wishlist -->
            <div class="row col-lg-12 pr-0 section-spacing pl-4 pl-lg-0">
                <div class="col-xl-12 px-0">
                    <label class="section-title">Wishlist de universidades</label>
                </div>

                <ng-container *ngIf="favourites.length>0 ; else noresultWishlist">
                    <div class="col-xl-12 wishlist-container">
                        <table mat-table [dataSource]="dataTableFav">
    
                            <ng-container matColumnDef="icono">
                                <th mat-header-cell *matHeaderCellDef class="table-columns " style="width: 12%;"></th>
                                <td mat-cell *matCellDef="let element; index as i" class="data-table text-center box-fav-icon">
                                    <img class="favourite-icon" src="/assets/utils/corazon-rojo.png" alt="favorito">
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="programaUniversidad">
                                <th mat-header-cell *matHeaderCellDef class="table-columns "> Programa y
                                    universidad </th>
                                <td mat-cell *matCellDef="let element" class="data-table py-2">
                                    <p class="mb-0">{{element.programa}}</p>
                                    <p class="mb-0">At {{element.universidad}}</p>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="afinidad">
                                <th mat-header-cell *matHeaderCellDef class="table-columns text-center"> Afinidad </th>
                                <td mat-cell *matCellDef="let element" class="data-table text-center">
                                    <label>{{element.afinidad}}%</label>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="probabilidad">
                                <th mat-header-cell *matHeaderCellDef class="table-columns text-center"> Probabilidad </th>
                                <td mat-cell *matCellDef="let element" class="data-table text-center">
                                    <label>{{element.probabilidad}}%</label>
                                </td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                        </table>
                    </div>
                </ng-container>
                <ng-template #noresultWishlist>
                    <div class="col-xl-12 wishlist-container">
                        <label>No hay resultados...</label>
                    </div>
                </ng-template>
            </div>


            <!-- Aplicaciones -->
            <div class="row col-lg-12 pr-0 section-spacing foot-application pl-4 pl-lg-0">
                <div class="col-xl-12 px-0">
                    <label class="section-title">Aplicaciones</label>
                </div>

                <ng-container *ngIf="applications.length>0 ; else noresultAppli">
                    <div class="col-xl-12 px-0 cards" *ngFor="let application of applications">
                        <app-application-card [id]="application.id" [programName]="application.programName"
                            [certification]="application.certification" [country]="application.country"
                            [city]="application.city" [university]="application.university"
                            [universityLogo]="application.universityLogo" [countryCurrency]="application.countryCurrency"
                            [applicationCost]="application.applicationCost" [tuitionCost]="application.tuitionCost"
                            [daysLeft]="application.daysLeft" [currentStep]="application.currentStep"
                            [countryImg]="application.countryImg" [afinity]="application.afinity"
                            [probability]="application.probability" [slug]="application.slug" [idCity]="application.idCity"
                            [fromSchool]="true">
    
                        </app-application-card>
                    </div>
                </ng-container>

                <ng-template #noresultAppli>
                    <div class="col-xl-12 px-0">
                        <label>No hay resultados...</label>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</main>
