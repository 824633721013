import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-letter-tips',
  templateUrl: './letter-tips.component.html',
  styleUrls: ['./letter-tips.component.scss']
})
export class LetterTipsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
