import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import { ExperiencePlannerModal } from './experiencePlannerModal/experiencePlannerModal.component';
import { ExperienceRecordModalComponent } from '../experiencesRecord/experienceRecordModal/experiencesRecordModal.component';

@Component({
  selector: 'app-experience-planner',
  templateUrl: './experiencePlanner.component.html',
  styleUrls: ['./experiencePlanner.component.scss'],
})
export class ExperiencePlannerComponent implements OnInit {
  private subscription: Subscription;
  readonly experiencesApi = 'api/getExperience/';
  readonly skillsApi = 'api/getSkill/';
  readonly plannedExperiences = 'api/getExperiencePlan/';
  readonly categoriesApi = 'api/getCategory/';
  experiences: any;
  skills: any;
  selectedExperience: any;
  selectedSkills: string[] = [];
  filteredExperiences: any[] = [];
  selectedSkill: string;
  skill: any;
  selectedCategory: string;
  categories: any;
  selectedSkillsOption: any;
  selectedCategoryOption: any;
  user = JSON.parse(localStorage.getItem('user'));
  searchTerm: string = '';

  constructor(private apiService: ApiService, private dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit() {
    this.getSkills();
    this.getExperiencePlan();
    this.getCategories();
    this.route.queryParams.subscribe((params) => {
      this.skill = params['skill'];
      if (this.skill) {
        this.toggleSkillSelection(this.skill);
      }
    });
  }

  getExperiencePlan() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.plannedExperiences }).subscribe(
        (response) => {
          const experiences = response.filter((plannedExperience) => plannedExperience.userId === this.user._id);

          this.experiences = experiences.map((experience) => ({
            ...experience,
            formattedStartDate: formatDate(experience.startDate, 'dd/MM/yyyy', 'en-US'),
            formattedEndDate: formatDate(experience.endDate, 'dd/MM/yyyy', 'en-US'),
          }));

          this.filterExperiences();
          this.sortExperiencesBySkillsScore();
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias planeadas ',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getCategories() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.categoriesApi }).subscribe(
        (response) => {
          this.categories = response.map((item) => item.categoryName);
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsApi }).subscribe(
        (response) => {
          this.skills = response.map((item) => item.skillName);
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  editExperiencePlanner(experienceData: any): void {
    experienceData.isEdit = true;
    const dialogRef = this.dialog.open(ExperiencePlannerModal, {
      width: '40vw',
      data: { experienceData },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getExperiencePlan();
    });
  }

  openExperiencePlanner(): void {
    const dialogRef = this.dialog.open(ExperiencePlannerModal, {
      width: '40vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getExperiencePlan();
    });
  }

  openExperienceRegister(experienceData: any): void {
    const modifiedExperienceData = { ...experienceData };
    modifiedExperienceData.experiencePlannedId = modifiedExperienceData._id;
    delete modifiedExperienceData._id;

    const dialogRef = this.dialog.open(ExperienceRecordModalComponent, {
      width: '50vw',
      height: '95vh',
      data: { experienceData: modifiedExperienceData },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getExperiencePlan();
    });
  }

  toggleCategorySelection(selectedCategories: string[]) {
    this.selectedCategoryOption = selectedCategories;

    if (selectedCategories.length === 0) {
      this.filteredExperiences = this.experiences;
    } else {
      this.filteredExperiences = this.experiences.filter((experience) => {
        if (experience.experienceCategory && experience.experienceCategory.length > 0) {
          return experience.experienceCategory.some((category) => selectedCategories.includes(category));
        } else {
          return false;
        }
      });
    }

    this.filterExperiences();
  }

  toggleSkillSelection(selectedSkills: string[]) {
    this.selectedSkillsOption = selectedSkills;

    this.filterExperiences();
  }

  filterExperiences() {
    const selectedCategoriesLength = this.selectedCategoryOption?.length ?? 0;
    const selectedSkillsLength = this.selectedSkillsOption?.length ?? 0;
    const searchTerm = this.searchTerm.toLowerCase();

    if (selectedCategoriesLength === 0 && selectedSkillsLength === 0 && !searchTerm) {
      this.filteredExperiences = this.experiences;
    } else {
      this.filteredExperiences = this.experiences.filter((experience) => {
        const categoryMatch =
          selectedCategoriesLength === 0 ||
          (experience.experienceCategory &&
            experience.experienceCategory.some((category) => this.selectedCategoryOption.includes(category)));

        const skillMatch =
          selectedSkillsLength === 0 ||
          this.selectedSkillsOption.some((skill) => {
            const skillsArray = [experience.principalSkill, experience.secondarySkill];
            return skillsArray.includes(skill);
          });

        const searchTermMatch = !searchTerm || experience.experience.toLowerCase().includes(searchTerm);

        return categoryMatch && skillMatch && searchTermMatch;
      });
    }
    this.sortExperiencesBySkillsScore();
  }

  sortExperiencesBySkillsScore() {
    this.filteredExperiences.sort((a, b) => {
      return b.skillsScore - a.skillsScore;
    });
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }

  trimHtmlContent(content: string): string {
    const maxLength = 270;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '...';
    }
  }
}
