import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  /** API to send contact request emails emails */
  readonly CONTACT_API = 'email/send-contact-mail';

  /** Variable that holds the contact request form */
  contactRequestForm: FormGroup;

  constructor(private fb: FormBuilder,
              private apiService: ApiService,
              private alertService: AlertService,
              public dialogRef: MatDialogRef<ContactFormComponent>) { }

  ngOnInit(): void {
    this.contactRequestForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
    });
  }

  /**
   * Handles the submit click on the request contact form
   */
  onSubmitRequestForm(): void {
    const contactData = this.contactRequestForm.value;
    contactData.email.trim();
    contactData.email = contactData.email.toLowerCase();
    this.apiService
      .post({ api: this.CONTACT_API, data: contactData })
      .subscribe(
        (response) => {
          if (response.success === true) {
            this.contactRequestForm.get('nombre').setValue('');
            this.contactRequestForm.get('email').setValue('');
            this.contactRequestForm.get('telefono').setValue('');
            this.alertService.showSuccess({
              msg: 'We have received your contact information and will contact you as soon as possible',
            });
            this.dialogRef.close();
          } else {
            this.alertService.showError({
              msg: 'There was an error, please try again later',
            });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'There was an error, please try again later or contact support',
          });
        }
      );
  }

  /** Handles the click on the close button */
  onCloseClick(): void {
    this.dialogRef.close();
  }

}
