import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-substep-card',
  templateUrl: './substep-card.component.html',
  styleUrls: ['./substep-card.component.scss']
})
export class SubstepCardComponent implements OnInit {

  @Input() stepName: string;
  @Input() status: string;
  constructor() { }

  ngOnInit(): void {
  }

}
