import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { AuthComponent } from '../auth/auth.component';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  interval: any;
  notLoggedInTime: number;
  constructor(
    private cookieService: CookieService,
    private apiService: ApiService,
    private dialog: MatDialog
  ) { }

  startNotLoggedInTime(){
    this.notLoggedInTime = Number(this.cookieService.get('notLoggedInTime')) || 0;
    this.notLoggedInTime = this.notLoggedInTime * 1000

    this.apiService.get({ api: 'api/config' }).subscribe(response => {
      const maxTimeWithoutLoginIn = response[0].maxTimeWithoutLogin * 1000;
      let timeRemaining = maxTimeWithoutLoginIn - this.notLoggedInTime;
      if(timeRemaining > 0){
        /** Increments the time that has passed */
        this.interval = setInterval(() => {
          this.notLoggedInTime += 1000;
        }, 1000);
        /** When reaches the time, open the log in dialog and updates the cookies */
        setTimeout(() => {
          this.endNotLoggedInTime()
          this.dialog.open(AuthComponent, {
            data: { authAction: 'login' },
            disableClose: false,
          });
        }, timeRemaining);
      }

    })
  }

  endNotLoggedInTime(){
    clearInterval(this.interval);
    this.cookieService.set("notLoggedInTime", (this.notLoggedInTime).toString());
  }
}
