import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-language-test-shopping-cart',
  templateUrl: './language-test-shopping-cart.component.html',
  styleUrls: ['./language-test-shopping-cart.component.scss']
})
export class LanguageTestShoppingCartComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the service */
  @Input() id: string;
  /** Variable that holds the language of the exam */
  @Input() language: string;
  /** Variable that holds the name of the service */
  @Input() name: string;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the currency in which the service is registeres */
  @Input() currency: string;
  /** Variable that holds the total cost of adquiring the service  */
  @Input() cost: number;
  /** Variable that holds the type of the service */
  @Input() type: string;
  /** Function to delete the service from the cart */
  @Output() removeService = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onRemoveServiceClick(): void {
    this.removeService.emit(this.id);
  }

}
