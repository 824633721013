import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUniversity',
  pure: false,
})
export class FilterUniversityPipe implements PipeTransform {

  /** Filters of the universities that belong to the cities of an array
   * If a country and a city are not provided returns all universities
   * @param universities list of universities
   * @param city array of cities ids
   * @param country name of the country
   */
  transform(universities: any[], city: any[], country: any[]): any[] {
    let filtered;
    if (city.length  < 1 && country.length < 1 ) {
      filtered = universities;
      return filtered;
    } else if (city.length > 0) {
      filtered = universities.filter((university) => {
        let cities = city.map(c => c._id);
        for (const city of cities) {
          if (university.ciudades.map(c => c._id).indexOf(city) >= 0) {
            return true;
          }
        }
        return false;
      });
      return filtered;
    } else if (country.length > 0) {
      filtered = universities.filter((university) => {
        let countries = country.map(c => c._id);
        for (const country of countries) {
          if (university.ciudades.map(c => c.pais).indexOf(country) >= 0) {
            return true;
          }
        }
        return false;
      });
      return filtered;
    }
  }

}
