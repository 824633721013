<div class="contact-form" [formGroup]="contactRequestForm">
  <mat-icon (click)="onCloseClick()" class="close-button">close</mat-icon>
  <P class="form-text"> {{'Contact-form.please-leave-your'|translate}} </P>
  <label class="modal-label" for="contact-name"> {{'Contact-form.name'|translate}} </label> <br>
  <input type="text" id="contact-name" [placeholder]="'Contact-form.name'|translate" formControlName="nombre"> <br>
  <label class="modal-label" for="contact-email"> {{'Contact-form.email'|translate}} </label> <br>
  <input type="email" id="contact-email" [placeholder]="'Contact-form.email'|translate" formControlName="email"> <br>
  <label class="modal-label" for="contact-phone"> {{'Contact-form.phone-number'|translate}} </label> <br>
  <input type="tel" id="contact-phone" [placeholder]="'Contact-form.phone-number'|translate" formControlName="telefono"> <br>

  <button id="send-button" (click)="onSubmitRequestForm()" [disabled]="contactRequestForm.invalid" type="submit"> {{'Contact-form.send'|translate}} </button>
</div>
