<div class="visa-form-container">
  <h2>Visa Form</h2>
  <button (click)="onSaveFormClick()" id="save-button" class="form-button">Save form</button>
  <div [ngSwitch]="currentForm" class="form-content">
    <div *ngSwitchCase="0">
      <ng-container *ngTemplateOutlet="mainData"></ng-container>
    </div>
    <div *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="workData"></ng-container>
    </div>
    <div *ngSwitchCase="2">
      <ng-container *ngTemplateOutlet="parentsData"></ng-container>
    </div>
    <div *ngSwitchCase="3">
      <ng-container *ngTemplateOutlet="coupleData"></ng-container>
    </div>
    <div *ngSwitchCase="4">
      <ng-container *ngTemplateOutlet="childrenData"></ng-container>
    </div>
    <div *ngSwitchCase="5">
      <ng-container *ngTemplateOutlet="financeData"></ng-container>
    </div>
  </div>
</div>

<ng-template #mainData>
  <form [formGroup]="mainDataForm">
    <div class="form-container">
      <div class="full-width">
        <h3>Personal information</h3>
      </div>
      <div class="form-group">
        <label class="form-label" for="name">First name</label>
        <input class="form-input" type="text" id="name" name="name" formControlName="nombres" placeholder="Name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="last-name">Last name</label>
        <input
          class="form-input"
          type="text"
          id="last-name"
          name="last-name"
          formControlName="apellidos"
          placeholder="Last name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="birth-date">Birth date</label>
        <input
          class="form-input"
          type="date"
          id="birth-date"
          name="birth-date"
          formControlName="fechaNacimiento"
          placeholder="Birth date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="marital-status">Marital status</label>
        <select class="form-input" id="marital-status" name="marital-status" formControlName="estadoCivil">
          <option *ngFor="let option of maritalStatusOptions" [value]="option.value">{{ option.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="passport">Passport number</label>
        <input
          class="form-input"
          type="text"
          id="passport"
          name="passport"
          formControlName="numeroPasaporte"
          placeholder="Passport number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="passport-start-date">Passport expedition date</label>
        <input
          class="form-input"
          type="date"
          id="passport-start-date"
          name="passport-start-date"
          formControlName="fechaExpedicionPasaporte"
          placeholder="Passport expedition date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="passport-expiration">Passport expiration date</label>
        <input
          class="form-input"
          type="date"
          id="passport-expiration"
          name="passport-expiration"
          formControlName="fechaVencimientoPasaporte"
          placeholder="Passport expiration date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="passport-place">Passport expedition place</label>
        <input
          class="form-input"
          type="text"
          id="passport-place"
          name="passport-place"
          formControlName="lugarExpedicionPasaporte"
          placeholder="Passport expedition place" />
      </div>
      <div class="form-group">
        <label class="form-label" for="passport-authority">Passport expedition authority</label>
        <input
          class="form-input"
          type="text"
          id="passport-authority"
          name="passport-authority"
          formControlName="autoridadExpedidora"
          placeholder="Passport expedition authority" />
      </div>
      <div class="form-group full-width">
        <p>Have you had a passport before?</p>
        <div id="previous-passport">
          <label>
            <input type="radio" [value]="true" formControlName="tienePasaportesAnteriores" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="tienePasaportesAnteriores" />
            No
          </label>
        </div>
      </div>
      <div class="previous-passports" formArrayName="pasaportesAnteriores">
        <p>If your answer was yes, please fill the information of your previous passports</p>
        <div
          class="passport-information"
          [formGroupName]="i"
          *ngFor="let passport of previousPassports.controls; index as i">
          <div class="form-sub-group">
            <label class="subform-label" [for]="'previous-passport-number' + i">Passport number</label>
            <input
              class="subform-input"
              type="text"
              [id]="'previous-passport-number' + i"
              formControlName="numero"
              placeholder="Passport number" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" [for]="'previous-passport-expe' + i">Expedition date</label>
            <input
              class="subform-input"
              type="date"
              [id]="'previous-passport-expe' + i"
              formControlName="fechaExpedicion"
              placeholder="Expedition date" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" [for]="'previous-passport-expi' + i">Expiration date</label>
            <input
              class="subform-input"
              type="date"
              [id]="'previous-passport-expi' + i"
              formControlName="fechaVencimiento"
              placeholder="Expiration date" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" [for]="'previous-passport-place' + i">Expedition place</label>
            <input
              class="subform-input"
              type="text"
              [id]="'previous-passport-place' + i"
              formControlName="lugardeExpedicion"
              placeholder="Expedition place" />
          </div>
          <button (click)="onDeletePassport({ index: i })" class="delete-button">Delete passport</button>
        </div>
      </div>
      <button (click)="onAddPassport()" class="form-button">Add passport</button>
      <div class="form-group"></div>
      <div class="form-group">
        <label class="form-label" for="document-type">ID type</label>
        <input
          class="form-input"
          type="text"
          id="document-type"
          name="document-type"
          formControlName="tipoDeDocumento"
          placeholder="ID type" />
      </div>
      <div class="form-group">
        <label class="form-label" for="document-number">ID number</label>
        <input
          class="form-input"
          type="text"
          id="document-number"
          name="document-number"
          formControlName="numeroDeDocumento"
          placeholder="ID number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="address">Address</label>
        <input
          class="form-input"
          type="text"
          id="address"
          name="address"
          formControlName="direccionDeResidencia"
          placeholder="Address" />
      </div>
      <div class="form-group">
        <label class="form-label" for="occupation-time">Residence occupation time</label>
        <input
          class="form-input"
          type="text"
          id="occupation-time"
          name="occupation-time"
          formControlName="tiempoDeOcupacionDeLaResidencia"
          placeholder="Occupation time" />
      </div>
      <div class="form-group">
        <label class="form-label" for="property-type">Property type</label>
        <select
          class="form-input"
          type="text"
          id="property-type"
          name="property-type"
          formControlName="regimenDeTenencia">
          <option value="Propia">Ownership</option>
          <option value="Arriendo">Lease</option>
        </select>
      </div>
      <div class="form-group">
        <p>Do you live in a family house?</p>
        <div id="family-house">
          <label>
            <input type="radio" [value]="true" formControlName="esViviendaFamiliar" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="esViviendaFamiliar" />
            No
          </label>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="telephone">Telephone number</label>
        <input
          class="form-input"
          type="text"
          id="telephone"
          name="telephone"
          formControlName="telefonoFijo"
          placeholder="Telephone number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="cellphone">Cellphone number</label>
        <input
          class="form-input"
          type="text"
          id="cellphone"
          name="cellphone"
          formControlName="telefonoCelular"
          placeholder="Cellphone number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="email">Email</label>
        <input
          class="form-input"
          type="email"
          id="email"
          name="email"
          formControlName="correoElectronico"
          placeholder="Email" />
      </div>
      <div class="form-group">
        <label class="form-label" for="entry-date">Date of entry (into the country)</label>
        <input
          class="form-input"
          type="date"
          id="entry-date"
          name="entry-date"
          formControlName="fechaDeEntradaAlPais"
          placeholder="Date of entry" />
      </div>
      <div class="form-group">
        <label class="form-label" for="exit-date">Date of exit (of the country)</label>
        <input
          class="form-input"
          type="date"
          id="exit-date"
          name="exit-date"
          formControlName="fechaSalidaDelPais"
          placeholder="Date of exit" />
      </div>
      <div class="form-group">
        <label class="form-label" for="institution-type">Educational institution type</label>
        <input
          class="form-input"
          type="text"
          id="institution-type"
          name="institution-type"
          formControlName="tipoInstitucionEducativa"
          placeholder="Educational institution type" />
      </div>
      <div class="form-group">
        <label class="form-label" for="institution-name">Educational institution name</label>
        <input
          class="form-input"
          type="text"
          id="institution-name"
          name="institution-name"
          formControlName="nombreInstitucionEducativa"
          placeholder="Educational institution name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="faculty">Faculty</label>
        <input
          class="form-input"
          type="text"
          id="faculty"
          name="faculty"
          formControlName="facultad"
          placeholder="Faculty" />
      </div>
      <div class="form-group">
        <label class="form-label" for="semester">Semester</label>
        <input
          class="form-input"
          type="number"
          id="semester"
          name="semester"
          formControlName="semestre"
          placeholder="Semester" />
      </div>
      <div class="form-group">
        <label class="form-label" for="job-title">Job title</label>
        <input
          class="form-input"
          type="text"
          id="job-title"
          name="job-title"
          formControlName="tituloProfesional"
          placeholder="Job title" />
      </div>
      <div class="form-group full-width">
        <p>Have you traveled abroad?</p>
        <div id="traveled-abroad">
          <label>
            <input type="radio" [value]="true" formControlName="haViajadoAlExterior" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="haViajadoAlExterior" />
            No
          </label>
        </div>
      </div>
      <div class="previous-travels" formArrayName="viajesAlExterior">
        <p>If your answer was yes, please list the travels</p>
        <div class="travel-information" [formGroupName]="i" *ngFor="let travel of previousTravels.controls; index as i">
          <div class="form-sub-group">
            <label class="subform-label" [for]="'travel-country' + i">Country</label>
            <input
              class="subform-input"
              type="text"
              [id]="'travel-country' + i"
              formControlName="pais"
              placeholder="Country" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" [for]="'travel-date' + i">Travel date</label>
            <input
              class="subform-input"
              type="date"
              [id]="'travel-date' + i"
              formControlName="fecha"
              placeholder="Date" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" [for]="'travel-reason' + i">Reason</label>
            <input
              class="subform-input"
              type="text"
              [id]="'travel-reason' + i"
              formControlName="motivo"
              placeholder="Reason" />
          </div>
          <button (click)="onDeleteTravel({ index: i })" class="delete-button">Delete travel</button>
        </div>
      </div>
      <button (click)="onAddTravel()" class="form-button">Add travel</button>
      <div class="form-group"></div>
      <div class="form-group full-width">
        <p>Have you had a visa before?</p>
        <div id="previous-visa">
          <label>
            <input type="radio" [value]="true" formControlName="haTenidoVisa" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="haTenidoVisa" />
            No
          </label>
        </div>
      </div>
      <div class="form-group full-width">
        <p>If your answer was yes, please enter the information of your previous Visa</p>
        <div class="visa-information" formGroupName="visaAnterior">
          <div class="form-sub-group">
            <label class="subform-label" for="previous-visa-number">Number</label>
            <input
              class="subform-input"
              type="text"
              id="previous-visa-number"
              name="previous-visa-number"
              formControlName="numero"
              placeholder="Number" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" for="previous-visa-exp">Expedition date</label>
            <input
              class="subform-input"
              type="date"
              id="previous-visa-exp"
              name="previous-visa-exp"
              formControlName="fechaExpedicion"
              placeholder="Expedition date" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" for="previous-visa-expi">Expiration date</label>
            <input
              class="subform-input"
              type="date"
              id="previous-visa-expi"
              name="previous-visa-expi"
              formControlName="fechaVencimiento"
              placeholder="Expiration date" />
          </div>
          <div class="form-sub-group">
            <label class="subform-label" for="previous-visa-file">File</label>
            <input
              class="subform-input"
              type="text"
              id="previous-visa-file"
              name="previous-visa-file"
              formControlName="archivo"
              placeholder="File" />
          </div>
        </div>
      </div>
      <div class="form-group full-width">
        <p>Have you had a visa denied before?</p>
        <div id="previous-denial">
          <label>
            <input type="radio" [value]="true" formControlName="leHanNegadoVisas" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="leHanNegadoVisas" />
            No
          </label>
        </div>
      </div>
      <div class="form-group full-width">
        <div class="previous-travels" formArrayName="negaciones">
          <p>If your answer was yes, please list the visa denials</p>
          <div
            class="denial-information"
            [formGroupName]="i"
            *ngFor="let denial of previousDenials.controls; index as i">
            <div class="form-sub-group">
              <label class="subform-label" [for]="'denial-country' + i">Country</label>
              <input
                class="subform-input"
                type="text"
                [id]="'denial-country' + i"
                formControlName="pais"
                placeholder="Country" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'denial-date' + i">Expedition date</label>
              <input
                class="subform-input"
                type="date"
                [id]="'denial-date' + i"
                formControlName="fecha"
                placeholder="Date" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'denial-reason' + i">Reason</label>
              <input
                class="subform-input"
                type="text"
                [id]="'denial-reason' + i"
                formControlName="motivo"
                placeholder="Reason" />
            </div>
            <button (click)="onDeleteDenial({ index: i })" class="delete-button">Delete denial</button>
          </div>
        </div>
      </div>
      <button (click)="onAddDenial()" class="form-button">Add denial</button>
      <div class="form-group"></div>
      <div class="form-group full-width">
        <p>Do you have a valid United States visa?</p>
        <div id="previous-visa">
          <label>
            <input type="radio" [value]="true" formControlName="visaAmericanaVigente" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="visaAmericanaVigente" />
            No
          </label>
        </div>
      </div>
      <div class="form-group full-width">
        <div class="previous-travels" formArrayName="examenesDeIngles">
          <p>If you have presented any english exam please fill the following information</p>
          <div class="denial-information" [formGroupName]="i" *ngFor="let exam of englishExams.controls; index as i">
            <div class="form-sub-group">
              <label class="subform-label" [for]="'exam-name' + i">Name</label>
              <input
                class="subform-input"
                type="text"
                [id]="'exam-name' + i"
                formControlName="nombre"
                placeholder="Name" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'exam-date' + i">Presentation date</label>
              <input
                class="subform-input"
                type="date"
                [id]="'exam-date' + i"
                formControlName="fecha"
                placeholder="Presentation date" />
            </div>
            <button (click)="onDeleteExam({ index: i })" class="delete-button">Delete exam</button>
          </div>
        </div>
      </div>
      <button type="button" (click)="onAddExam()" class="form-button">Add exam</button>
    </div>
    <div class="form-controls">
      <button
        type="button"
        (click)="onGoToSection({ index: 1 })"
        [disabled]="mainDataForm.invalid"
        class="form-button next-button">
        Next
      </button>
    </div>
  </form>
</ng-template>
<ng-template #workData>
  <form [formGroup]="workForm">
    <div class="form-container">
      <div class="full-width">
        <h3>Work information</h3>
      </div>
      <div class="form-group full-width">
        <p>Do you work?</p>
        <div id="work">
          <label>
            <input type="radio" [value]="true" formControlName="trabaja" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="trabaja" />
            No
          </label>
        </div>
      </div>
      <div class="full-width">
        <p>If your previous answer was yes, please fill in the information about your job</p>
      </div>
      <div class="form-group">
        <label class="form-label" for="company">Company name</label>
        <input
          class="form-input"
          type="text"
          id="company"
          name="company"
          formControlName="nombreEmpresa"
          placeholder="Company name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-date">When did you started working there?</label>
        <input
          class="form-input"
          type="date"
          id="company-date"
          name="company-date"
          formControlName="fechaIngresoTrabajo"
          placeholder="Start date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-position">Position</label>
        <input
          class="form-input"
          type="text"
          id="company-position"
          name="company-position"
          formControlName="nombreCargo"
          placeholder="Position" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-address">Company address</label>
        <input
          class="form-input"
          type="text"
          id="company-address"
          name="company-address"
          formControlName="direccionEmpresa"
          placeholder="Company address" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-email">Company email</label>
        <input
          class="form-input"
          type="text"
          id="company-email"
          name="company-email"
          formControlName="correoEmpresarial"
          placeholder="Company email" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-telephone">Business phone</label>
        <input
          class="form-input"
          type="tel"
          id="company-telephone"
          name="company-telephone"
          formControlName="telefonoEmpresarial"
          placeholder="Business phone" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-boss">Superior's name</label>
        <input
          class="form-input"
          type="text"
          id="company-boss"
          name="company-boss"
          formControlName="nombreJefe"
          placeholder="Superior's name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="company-wage">Monthly wage</label>
        <input
          class="form-input"
          type="number"
          id="company-wage"
          name="company-wage"
          formControlName="salarioMensual"
          placeholder="Monthly wage" />
      </div>
      <div class="full-width">
        <p>If you have any additional sources of income, please list them below</p>
      </div>
      <div class="form-group full-width">
        <div class="previous-travels" formArrayName="otrasEntradasEconomicas">
          <div
            class="denial-information"
            [formGroupName]="i"
            *ngFor="let source of sourcesOfIncome.controls; index as i">
            <div class="form-sub-group">
              <label class="subform-label" [for]="'income-description' + i">Description</label>
              <input
                class="subform-input"
                type="text"
                [id]="'income-description' + i"
                formControlName="descripcion"
                placeholder="Description" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'income-value' + i">Amount</label>
              <input
                class="subform-input"
                type="text"
                [id]="'income-value' + i"
                formControlName="valor"
                placeholder="Amount" />
            </div>
            <button (click)="onDeleteIncomeSource({ index: i })" class="delete-button">Delete source of income</button>
          </div>
        </div>
      </div>
      <button type="button" (click)="onAddIncomeSource()" class="form-button">Add source of income</button>
      <div class="full-width"></div>
      <div class="form-group">
        <label class="form-label" for="income">Total income</label>
        <input
          class="form-input"
          type="number"
          id="income"
          name="income"
          formControlName="totalDeEntradasEnCuentaBancaria"
          placeholder="Total income" />
      </div>
      <div class="form-group">
        <label class="form-label" for="property-value">Total value of your properties in COP</label>
        <input
          class="form-input"
          type="number"
          id="property-value"
          name="property-value"
          formControlName="propiedadesEnCop"
          placeholder="Properties in COP" />
      </div>
    </div>
    <div class="form-controls">
      <button type="button" (click)="onGoToSection({ index: 0 })" class="form-button previous-button">Previous</button>
      <button
        type="button"
        (click)="onGoToSection({ index: 2 })"
        [disabled]="workForm.invalid"
        class="form-button next-button">
        Next
      </button>
    </div>
  </form>
</ng-template>
<ng-template #parentsData>
  <form [formGroup]="parentsForm">
    <div class="form-container">
      <div class="full-width">
        <h3>Mother information</h3>
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-name">Name</label>
        <input
          class="form-input"
          type="text"
          id="mother-name"
          name="mother-name"
          formControlName="nombreMadre"
          placeholder="Name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-birthdate">Birth date</label>
        <input
          class="form-input"
          type="date"
          id="mother-birthdate"
          name="mother-birthdate"
          formControlName="fechaNacimientoMadre"
          placeholder="Birth date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-birthplace">Birth place</label>
        <input
          class="form-input"
          type="text"
          id="mother-birthplace"
          name="mother-birthplace"
          formControlName="lugarNacimientoMadre"
          placeholder="Birth place" />
      </div>
      <div class="form-group full-width">
        <p>Is she deceased?</p>
        <div id="mother-deceased">
          <label>
            <input type="radio" [value]="true" formControlName="madreEsFallecido" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="madreEsFallecido" />
            No
          </label>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-decease">Decease date</label>
        <input
          class="form-input"
          type="date"
          id="mother-decease"
          name="mother-decease"
          formControlName="fechaFallecimientoMadre"
          placeholder="Decease date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-deathplace">Place of death</label>
        <input
          class="form-input"
          type="text"
          id="mother-deathplace"
          name="mother-deathplace"
          formControlName="lugarFallecimientoMadre"
          placeholder="Place of death" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-cellphone">Cellphone number</label>
        <input
          class="form-input"
          type="tel"
          id="mother-cellphone"
          name="mother-cellphone"
          formControlName="celularMadre"
          placeholder="Cellphone number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-email">Email</label>
        <input
          class="form-input"
          type="email"
          id="mother-email"
          name="mother-email"
          formControlName="correoMadre"
          placeholder="Email" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-marital">Marital status</label>
        <select
          class="form-input"
          type="email"
          id="mother-marital"
          name="mother-marital"
          formControlName="estadoCivilMadre">
          <option *ngFor="let option of maritalStatusOptions" [value]="option.value">{{ option.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-activity">Economic activty</label>
        <input
          class="form-input"
          type="text"
          id="mother-activity"
          name="mother-activity"
          formControlName="actividadEconomicaMadre"
          placeholder="Economic activity" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-company">Company name</label>
        <input
          class="form-input"
          type="text"
          id="mother-company"
          name="mother-company"
          formControlName="empresaMadre"
          placeholder="Company name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="mother-job">Job title</label>
        <input
          class="form-input"
          type="text"
          id="mother-job"
          name="mother-job"
          formControlName="cargoMadre"
          placeholder="Job title" />
      </div>
      <div class="full-width">
        <h3>Father information</h3>
      </div>
      <div class="form-group">
        <label class="form-label" for="father-name">Name</label>
        <input
          class="form-input"
          type="text"
          id="father-name"
          name="father-name"
          formControlName="nombrePadre"
          placeholder="Name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-birthdate">Birth date</label>
        <input
          class="form-input"
          type="date"
          id="father-birthdate"
          name="father-birthdate"
          formControlName="fechaNacimientoPadre"
          placeholder="Birth date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-birthplace">Birth place</label>
        <input
          class="form-input"
          type="text"
          id="father-birthplace"
          name="father-birthplace"
          formControlName="lugarNacimientoPadre"
          placeholder="Birth place" />
      </div>
      <div class="form-group full-width">
        <p>Is he deceased?</p>
        <div id="mother-deceased">
          <label>
            <input type="radio" [value]="true" formControlName="padreEsFallecido" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="padreEsFallecido" />
            No
          </label>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="father-decease">Decease date</label>
        <input
          class="form-input"
          type="date"
          id="father-decease"
          name="father-decease"
          formControlName="fechaFallecimientoPadre"
          placeholder="Decease date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-deathplace">Place of death</label>
        <input
          class="form-input"
          type="text"
          id="father-deathplace"
          name="father-deathplace"
          formControlName="lugarFallecimientoPadre"
          placeholder="Place of death" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-cellphone">Cellphone number</label>
        <input
          class="form-input"
          type="tel"
          id="father-cellphone"
          name="father-cellphone"
          formControlName="celularPadre"
          placeholder="Cellphone number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-email">Email</label>
        <input
          class="form-input"
          type="email"
          id="father-email"
          name="father-email"
          formControlName="correoPadre"
          placeholder="Email" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-marital">Marital status</label>
        <select
          class="form-input"
          type="email"
          id="father-marital"
          name="father-marital"
          formControlName="estadoCivilPadre">
          <option *ngFor="let option of maritalStatusOptions" [value]="option.value">{{ option.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="father-activity">Economic activty</label>
        <input
          class="form-input"
          type="text"
          id="father-activity"
          name="father-activity"
          formControlName="actividadEconomicaPadre"
          placeholder="Economic activity" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-company">Company name</label>
        <input
          class="form-input"
          type="text"
          id="father-company"
          name="father-company"
          formControlName="empresaPadre"
          placeholder="Company name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="father-job">Job title</label>
        <input
          class="form-input"
          type="text"
          id="father-job"
          name="father-job"
          formControlName="cargoPadre"
          placeholder="Job title" />
      </div>
    </div>
    <div class="form-controls">
      <button type="button" (click)="onGoToSection({ index: 1 })" class="form-button previous-button">Previous</button>
      <button
        type="button"
        (click)="onGoToSection({ index: 3 })"
        [disabled]="workForm.invalid"
        class="form-button next-button">
        Next
      </button>
    </div>
  </form>
</ng-template>
<ng-template #coupleData>
  <form [formGroup]="coupleForm">
    <div class="form-container">
      <div class="full-width">
        <p>If your're married or living in any kind of union, please fill this information about your partner.</p>
      </div>
      <div class="form-group">
        <label class="form-label" for="partner-name">Name</label>
        <input
          class="form-input"
          type="text"
          id="partner-name"
          name="partner-name"
          formControlName="nombrePareja"
          placeholder="Name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="partner-birthdate">Birth date</label>
        <input
          class="form-input"
          type="date"
          id="partner-birthdate"
          name="partner-birthdate"
          formControlName="fechaNacimientoPareja"
          placeholder="Birth date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="partner-birthplace">Birth place</label>
        <input
          class="form-input"
          type="text"
          id="partner-birthplace"
          name="partner-birthdate"
          formControlName="lugarNacimientoPareja"
          placeholder="Birth place" />
      </div>
    </div>
    <div class="form-controls">
      <button type="button" (click)="onGoToSection({ index: 2 })" class="form-button previous-button">Previous</button>
      <button
        type="button"
        (click)="onGoToSection({ index: 4 })"
        [disabled]="workForm.invalid"
        class="form-button next-button">
        Next
      </button>
    </div>
  </form>
</ng-template>
<ng-template #childrenData>
  <form [formGroup]="childrenForm">
    <div class="form-container">
      <div class="full-width">
        <h3>Children information</h3>
      </div>
      <div class="form-group full-width">
        <p>Do you have children?</p>
        <div id="have-children">
          <label>
            <input type="radio" [value]="true" formControlName="tieneHijos" />
            Yes
          </label>
          <label>
            <input type="radio" [value]="false" formControlName="tieneHijos" />
            No
          </label>
        </div>
      </div>
      <div class="full-width">
        <p>If your answer was yes, please fill the information about your children</p>
      </div>
      <div class="form-group full-width">
        <div class="previous-travels" formArrayName="hijos">
          <div class="denial-information" [formGroupName]="i" *ngFor="let child of children.controls; index as i">
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-name' + i">Name</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-name' + i"
                formControlName="nombre"
                placeholder="Name" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-birthdate' + i">Birth date</label>
              <input
                class="subform-input"
                type="date"
                [id]="'child-birthdate' + i"
                formControlName="fechaNacimiento"
                placeholder="Birth date" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-passport' + i">Passport number</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-passport' + i"
                formControlName="pasaporte"
                placeholder="Passport number" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-who' + i">Who does he/she lives with?</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-who' + i"
                formControlName="conQuienVive"
                placeholder="I.e Lives with his aunt" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-city' + i">City of residence</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-city' + i"
                formControlName="ciudadDeResidencia"
                placeholder="City of residence" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-address' + i">Address</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-address' + i"
                formControlName="direccionDeResidencia"
                placeholder="Address" />
            </div>
            <div class="form-sub-group">
              <label class="subform-label" [for]="'child-email' + i">Email</label>
              <input
                class="subform-input"
                type="text"
                [id]="'child-email' + i"
                formControlName="correo"
                placeholder="Email" />
            </div>
            <button (click)="onDeleteChildren({ index: i })" class="delete-button">Delete child</button>
          </div>
        </div>
      </div>
      <button type="button" (click)="onAddChildren()" class="form-button">Add child</button>
    </div>
    <div class="form-controls">
      <button type="button" (click)="onGoToSection({ index: 3 })" class="form-button previous-button">Previous</button>
      <button
        type="button"
        (click)="onGoToSection({ index: 5 })"
        [disabled]="workForm.invalid"
        class="form-button next-button">
        Next
      </button>
    </div>
  </form>
</ng-template>
<ng-template #financeData>
  <form [formGroup]="financeForm">
    <div class="form-container">
      <div class="full-width">
        <h3>Sponsor information</h3>
        <p>If someone else is sponsoring the visit to the country please fill fill the following information.</p>
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-name">Name</label>
        <input
          class="form-input"
          type="text"
          id="sponsor-name"
          name="sponsor-name"
          formControlName="nombreFinanciante"
          placeholder="Name" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-relation">What is your relation with this person?</label>
        <input
          class="form-input"
          type="text"
          id="sponsor-relation"
          name="sponsor-relation"
          formControlName="relacionConFinanciante"
          placeholder="Relation" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-birthdate">Birth date</label>
        <input
          class="form-input"
          type="date"
          id="sponsor-birthdate"
          name="sponsor-birthdate"
          formControlName="fechaNacimientoFinanciante"
          placeholder="Birth date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-address">Address</label>
        <input
          class="form-input"
          type="text"
          id="sponsor-address"
          name="sponsor-address"
          formControlName="direccionFinanciante"
          placeholder="Address" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-cellphone">Cellphone number</label>
        <input
          class="form-input"
          type="tel"
          id="sponsor-cellphone"
          name="sponsor-cellphone"
          formControlName="celularFinanciante"
          placeholder="Cellphone number" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-email">Email</label>
        <input
          class="form-input"
          type="email"
          id="sponsor-email"
          name="sponsor-email"
          formControlName="correoFinanciante"
          placeholder="Email" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-activity">Economic activity</label>
        <input
          class="form-input"
          type="text"
          id="sponsor-activity"
          name="sponsor-activity"
          formControlName="actividadEconomicaFinanciante"
          placeholder="Economic activity" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-job">Job position</label>
        <input
          class="form-input"
          type="text"
          id="sponsor-job"
          name="sponsor-job"
          formControlName="cargoFinanciante"
          placeholder="Job position" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-date">When did he/she started working that job?</label>
        <input
          class="form-input"
          type="date"
          id="sponsor-date"
          name="sponsor-date"
          formControlName="fechaInicioActividadEconomicaFinanciante"
          placeholder="Start date" />
      </div>
      <div class="form-group">
        <label class="form-label" for="sponsor-salary">Monthly wage</label>
        <input
          class="form-input"
          type="number"
          id="sponsor-salary"
          name="sponsor-salary"
          formControlName="salarioMensualFinanciante"
          placeholder="Monthly wage" />
      </div>
    </div>
    <div class="form-controls">
      <button type="button" (click)="onGoToSection({ index: 4 })" class="form-button previous-button">Previous</button>
    </div>
  </form>
</ng-template>
