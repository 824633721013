import { NgxSpinnerService } from 'ngx-spinner';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/public/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { S3Service } from 'src/app/public/services/s3.service';

@Component({
  selector: 'app-tuition',
  templateUrl: './tuition.component.html',
  styleUrls: ['./tuition.component.scss']
})
export class TuitionComponent implements OnInit {

  @Input() stepData: any;
  @Output() updateUploadedDocument = new EventEmitter<any>();

  /** Variable that holds the application id of the current application */
  applicationId: string;

  tuitionPayment = {
    completado: false,
    fechaMaxima: '',
    fechaCompletado: '',
    paymentProof: '',
  };

  constructor(public translate: TranslateService,
              private ngxSpinnerService: NgxSpinnerService,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private s3Service: S3Service) { }

  ngOnInit(): void {
    this.applicationId = this.activatedRoute.snapshot.params.id;
    this.tuitionPayment.completado = this.stepData.completado;
    this.tuitionPayment.paymentProof = this.stepData.paymentProof;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

   /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event, nombreDoc }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant("Academic-history.you-must-upload-1-file"));
    } else {
      this.ngxSpinnerService.show();
      const file = event.target.files[0];
      const key = `application/${this.applicationId}/tuition/${nombreDoc}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinnerService.hide();
          this.alertService.showError({ msg: 'There was an error uploading the document, please try again later or contact support' });
        } else {
          // Assign the value to the corresponding component
          switch (nombreDoc) {
            case 'tuition-payment-proof':
              this.updateUploadedDocument.emit({ docName: 'tuition-payment-proof', url: data.Location });
          }
          this.ngxSpinnerService.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

}
