<div class="experienceCardContainer">
  <div class="experienceCard" *ngIf="formList.length > 0" (click)="openEditModal(i)">
    <div class="cardComponent">
      <div
        class="card"
        *ngFor="let formData of formList; let i = index"
        [class.hidden]="!shouldShowCard(formData)"
        (click)="openEditModal(i)">
        <div class="cardTitle">
          <h5>{{ formData.experienceName }}</h5>
          <p class="approvalStatus" *ngIf="formData.approve === experienceReviews.approved">Aprobada</p>
          <p class="approvalStatus" *ngIf="formData.approve === experienceReviews.rejected">Aprobación negada</p>
          <p class="approvalStatus" *ngIf="formData.approve === experienceReviews.pending">Pendiente por ajustes</p>
          <p class="approvalStatus" *ngIf="formData.approve === experienceReviews.nonCheck">Pendiente por revisión</p>
        </div>
        <div class="cardBody" *ngIf="shouldShowCard(formData)">
          <div [innerHTML]="decodeEntities(trimHtmlContent(formData.experienceDescription))"></div>
          <p class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="" />
            {{ formData.selectedSkills }}
          </p>
          <p class="date">
            <img src="../../../assets/experiences/calendario.png" alt="" />
            {{ formatDate(formData.initDate) }} - {{ formData.endDate ? formatDate(formData.endDate) : ''
            }}{{ formData.isOngoing ? 'En curso' : '' }}
          </p>
          <div *ngIf="urlId === loggedUserId" class="editButton" (click)="openEditModal(i); $event.stopPropagation()">
            Editar
          </div>
          <div
            *ngIf="urlId === loggedUserId"
            class="deleteButton"
            (click)="showDeleteConfirmation(formData, i); $event.stopPropagation()">
            Borrar
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filterWrapper" *ngIf="formList.length > 0 && urlId === loggedUserId">
    <div class="FiltersExperinceContainer">
      <div class="row filterContainer">
        <div class="col-xl-12">
          <p class="filterName">Filtros por competencias</p>
        </div>
        <div
          *ngFor="let skill of skillsArray; index as i"
          (click)="toggleSkillSelection(skill.name)"
          [ngClass]="{
            tagFilterSelected: isSelectedSkill(skill.name),
            'display-mobile-none': !showCompetenciesFilters
          }"
          class="tagFilters">
          {{ skill.name }}
        </div>
      </div>
    </div>
  </div>
</div>

<app-experience-modal [formListData]="formListData"></app-experience-modal>
