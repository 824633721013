<div *ngIf="currentStatus === 0 || currentStatus === -1" class="visa-thermometer">
  <div class="visa-img-container">
    <img src="assets/utils/banner-testvisa.jpg" alt="banner test visa">
  </div>
  <div class="container-width">
    <div class="principal-container">
      <div style="z-index: 1;" class="info-container">
        <img id="birrete-icon" src="assets/utils/birrete-rosa.png" alt=" pink line">
        <label>¿Dudas? Empieza a encontrar TU LUGAR EN EL MUNDO</label>
        <p>
          Responde nuestro test y conoce las probabilidades de un permiso de estudio por país.
        </p>
      </div>
      <div class="video-container">
        <!-- <label>Why USF - Part 1 | University of South Florida</label>
        <div class="video">
          <iframe src="" frameborder="0"></iframe>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentStatus === 1 || currentStatus === 2" class="card-banner">
  <div class="container-width">
    <div class="back-header" (click)="onPreviousQuestion()">
      <img class="back-button" src="assets/utils/detail-arrow.png" alt="go back" />
      <h2 class="back-title"> Volver </h2>
    </div>
    <div class="container-card-info">
      <div class="card-information-header">
        <label>Test de visa</label>
        <div class="current-country">
          <img [src]="currentCountryInformation.icono" alt="{{currentCountryInformation.nombre}}">
          <label [ngStyle] ="{'color': colors[2]}">{{currentCountryInformation.nombre}}</label>
        </div>
      </div>
      <div *ngIf="currentStatus === 1 || currentStatus === 2">
        <div class="questions-section">
          <div class="questionaire" *ngIf="currentStatus === 1">
            <div class="progress-text">
              Cuestionario {{this.currentQuestion + 1}}/
              {{questions.length + 1}}
            </div>
            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
            <div class="questionaire-container" *ngFor="let question of questions; index as i"
              [ngStyle]="{'display': currentQuestion === i ? 'block':'none'}">
              <div class="questionaire-text" [innerHTML]="question.pregunta"></div>
              <div class="questionaire-option">
                <div class="option">
                  <input (click)="onChangeAnswer({newValue: true, index: i})" id="option-a" name="answer" type="radio" class="checkbox-round"
                    [ngStyle]="{'border': question.selected ? '2px solid #545BA8': '2px solid #434142'}" />
                    Si, siempre lo ha sido
                </div>
                <div class="option">
                  <input (click)="onChangeAnswer({newValue: false, index: i})" id="option-b" name="answer" type="radio" class="checkbox-round"
                    [ngStyle]="{'border': !question.selected ? '2px solid #434142': '2px solid #545BA8'}"/>
                    No, a veces lo dudo
                </div>
              </div>
              <div class="questionaire action">
                <button (click)="onPreviousQuestion()" class="previous-button-responsive"> Anterior </button>
                <button (click)=" !(loggedIn && i === (questions.length-1)) ? onNextQuestion():onSubmitResponse()" class="advance-button"> {{
                  "Visa-thermometer.next" | translate }} </button>
              </div>
            </div>
            <div class="questionaire-container" [ngStyle]="{'display': currentQuestion + 1 === 11 ? 'block':'none'}">
              <div class="questionaire-text text-alig-center">
                <img src="assets/utils/test-finalizado.png" alt="end of the test">
                <p>
                  <span>Tenemos una respuesta lista para ti.</span>
                  Regístrate para recibir en tu correo toda la información necesaria para tu viaje.
                </p>
              </div>
              <div class="result-container">
                <button (click)="onSubmitResponse()"
                  class="advance-button">Ver Resultados</button>
              </div>
            </div>
          </div>
          <div *ngIf="currentStatus === 2">
            <div  class="answer-section">
              <img *ngIf="visaProbability.numerical >= 85" class="thermometer-image"
                src="assets/utils/test-resultado-verde.png" alt="green thermometer">
              <img *ngIf="visaProbability.numerical >= 50 && visaProbability.numerical < 85" class="thermometer-image"
                src="assets/utils/test-resultado-amarillo.png" alt="yellow thermometer">
              <img *ngIf="visaProbability.numerical < 50" class="thermometer-image"
                src="assets/utils/test-resultado-rojo.png" alt="red thermometer">
              <div *ngIf="visaProbability.numerical >= 85" class="answer-disclaimer">
                <label>¡Felicitaciones!<br>Cumples con los requisitos para hacer realidad tu sueño de estudios en el exterior.</label>
                <p>Contactanos con el CÓDIGO “SINEXCUSAS” para darte un 100% de descuento en tu consultoría profesional inicial.</p>
              </div>
              <div *ngIf="visaProbability.numerical >= 50 && visaProbability.numerical < 85" class="answer-disclaimer">
                <label>¡Cumples con la mayoría de los requisitos!</label>
                <p>Es importante que pongas tu perfil a punto para maximizar tus probabilidades de visa.</p>
              </div>
              <div *ngIf="visaProbability.numerical < 50" class="answer-disclaimer">
                <label>Muchos puntos por mejorar </label>
                <p>Repasa las preguntas para saber cómo poner tu perfil a punto y maximisar tus probabilidades de visa a nivel internacional.</p>
              </div>
            </div>
            <div class="questionaire button-result">
              <button class="advance-button" (click)="onViewPrograms()">Ver Programas</button>
              <button class="previous-button-responsive asesoria-button" routerLink="/marketplace" [queryParams]="{serviceType: 'consultoria-vip' }">Asesoría</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Visa section-->
<div *ngIf="currentStatus === 0 || currentStatus === -1" class="visa">
  <div class="container-width">
    <div class="visa-principal" style="z-index: 1;">
      <strong>Selecciona un destino de interés :</strong>
      <span>* Las preguntas y resultados presentados a continuación no representan una consultoría oficial;
        son indicativos con base en la experiencia de expertos en la industria y una guía fácil para comenzar
         a identificar los puntos del perfil para cada país de forma individual y preparar una mejor candidatura al destino ideal.</span>
    </div>
    <div *ngIf="currentStatus === 0" class="container-all-buttons" style="z-index: 1;">
      <div class="container-first-buttons">
        <div *ngFor="let country of countries; index as i" (click)="onCountryClick({index: i})">
          <button class="button-estandar" [ngStyle] ="{'border-color': colors[i%5]}">
            <img class="country-image" [src]="countries[i]?.pais.icono" alt="{{countries[i]?.pais.nombre}}">
            <div class="country-name" [ngStyle] ="{'color': colors[i%5]}">
              {{countries[i]?.pais.nombre}}
            </div>
          </button>
        </div>
      </div>
      <button *ngIf="isRecruitment" (click)="backSelectStudent()" id="atras"> {{ "Visa-thermometer.back" | translate }}
      </button>
    </div>
    <div *ngIf="currentStatus === -1" class="country-selection">
      <div class="start-disclaimer">
        {{"Visa-thermometer.select-student" | translate}}
      </div>
      <div class="question">
        <div class="countries-container">
          <div class="row-center">
            <div class="student-selector" *ngIf="isRecruitment">
              <select placeholder="Seleccionar estudiante" [(ngModel)]="student"
                (ngModelChange)="onStudentChange({id: $event})" class="student-select">
                <option value="0" disabled selected>{{ "Seleccionar estudiante" }} </option>
                <option *ngFor="let s of students" [value]="s._id">{{s.nombre}} {{s.apellido}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
