

<main>
    <mat-spinner diameter="40" class="loasingSpinner" *ngIf="loading"></mat-spinner>
    <ng-container *ngIf="!loading && documents.length>0">
        <div class="col-xl-12">
            <label class="title-section">Documentos de {{studentName}}</label>
        </div>
        <div class="col-xl-12" *ngFor="let item of documents">
            <a [href]="item.archivo" target="_blank">
                <label class="button-document">
                    Ver
                </label>
            </a>
            <label>{{item.text}}</label>
        </div>
    </ng-container>

    <ng-template>
        <div class="col-xl-12">
            <label>El estudiante no ha subido documentos...</label>
        </div>
    </ng-template>
    
</main>
