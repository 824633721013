import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2';
import { ExperienceFormData } from '../experienceModalComponent';

export function handlePutRequest(apiService: ApiService, experienceID: string, formData: ExperienceFormData) {
  const putData = {
    api: `api/experiences/${experienceID}`,
    data: formData,
  };

  apiService.put(putData).subscribe(
    (response) => {
      this.formDataSubmitted.emit();
      this.experienceCreated.emit();
      this.closeModal();
    },
    (error) => {
      console.error('Error en el PUT:', error);
    }
  );
}

export function handlePutFromSupervisorRequest(
  apiService: ApiService,
  experienceID: string,
  formData: ExperienceFormData
) {
  const putData = {
    api: `api/experiencesFromSupervisor/${experienceID}`,
    data: formData,
  };
  apiService.put(putData).subscribe(
    (response) => {
      this.formDataSubmitted.emit();
      this.experienceCreated.emit();
      this.closeModal();
    },
    (error) => {
      console.error('Error in the PUT request:', error);
    }
  );
}

export function handlePostRequest(apiService: ApiService, formData: ExperienceFormData) {
  return new Promise<string>((resolve, reject) => {
    const postData = {
      api: 'api/experiences',
      data: formData,
    };
    apiService.post(postData).subscribe(
      (response) => {
          Swal.fire({
            title: 'Creando tu  Experiencia...',
            html: 'Enviaremos un correo a tu supervisor para que la valide',
            timerProgressBar: true,
            timer: 3000,
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: {
              container: 'custom-swal',
              timer: 'custom-timer',
            } as any,
          });

        resolve(response.experienceId);
        this.formDataSubmitted.emit();
        this.experienceCreated.emit();
        this.closeModal();
      },
      (error) => {
        console.error('Error en el POST:', error);
        reject(error);
      }
    );
  });
}
