import { Component, ViewChild, OnInit, Output, Input, TemplateRef, EventEmitter, Renderer2 } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../public/services/api.service';
import Swal from 'sweetalert2';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { handlePutRequest, handlePostRequest, handlePutFromSupervisorRequest } from './helpers/experienceRequests';
import { S3Service } from '../services/s3.service';
import { skillsArray } from '../skillsObject/skills.js';
import { log } from 'util';
import { Key } from 'protractor';

export interface ExperienceFormData {
  user: string;
  experienceName: string;
  supervisorName: string;
  supervisorLastName: string;
  supervisorEmail: string;
  supervisorPhoneNumber: number;
  initDate: Date;
  endDate: Date;
  isOngoing: boolean;
  selectedActivity: string;
  selectedMethod: string;
  selectedLocation: string;
  selectedStatus: string;
  selectedSkills: string[];
  secondarySkills: string[];
  selectedExperienceType: string;
  experienceDescription: string;
  skillPoints?: number;
  approve: string;
  recommendation: string;
  skillsScore: object;
  secondarySkillsScore: object;
  requestApproval: boolean;
  supports: {};
}
export interface supports {
  link: string;
  type: string;
}
@Component({
  selector: 'app-experience-modal',
  templateUrl: './experienceModalComponent.html',
  styleUrls: ['./experienceModalComponent.scss'],
})
export class ExperienceModalComponent implements OnInit {
  executionMethodApi: 'api/getExecutionMethod/';
  actitvityStatusApi: 'api/getActivityStatus/;';
  executionModeApi: ' api/getExecutionMode;';
  executionTypeApi: 'api/getExecutionType/;';
  user = JSON.parse(localStorage.getItem('user'));
  @ViewChild('editor') editor: any;
  @ViewChild('dp') datepicker: any;
  @ViewChild('content', { static: true })
  private contentTemplateRef: TemplateRef<any>;
  @Output() formDataSubmitted: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() experienceCreated: EventEmitter<any> = new EventEmitter();
  @Input() editModeData: ExperienceFormData | null = null;
  @Input() parentID: string;
  @Input() experienceID: string;
  @Input() supportResponse: any[] = [];
  @Input() userId: any;
  activityType: any;
  location: any;
  status: any;
  executionMethod: any;
  skills: any;
  experienceType = [
    {
      id: 'Taller o Bootcamp Internacional',
      name: 'Taller o Bootcamp Internacional',
      selected: false,
      message: 'Certificado de Asistencia a Taller de al menos 20 horas.',
      skillsPoints: 60,
      secondarySkillsPoints: 45,
    },
    {
      id: 'Taller o Bootcamps Nacional',
      name: 'Taller o Bootcamps Nacional',
      selected: false,
      message: 'Certificado de Asistencia a Taller de al menos 20 horas',
      skillsPoints: 20,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Concurso / Competencia',
      name: 'Concurso / Competencia',
      selected: false,
      message: 'Participación en concursos competencias certificadas.',
      skillsPoints: 10,
      secondarySkillsPoints: 7,
    },
    {
      id: 'Concurso / Competencia Ganada',
      name: 'Concurso / Competencia Ganada',
      selected: false,
      message: 'Participación en concursos competencias certificadas, tres primeros puestos.',
      skillsPoints: 20,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Práctica Cultural',
      name: 'Práctica Cultural',
      selected: false,
      message: 'Al menos 8 meses o 130 horas anuales con certificado. I o G dependiendo del liderazgo',
      skillsPoints: 40,
      secondarySkillsPoints: 30,
    },
    {
      id: 'Notas Académicas Colegio.',
      name: 'Notas Académicas Colegio.',
      selected: false,
      message: 'Promedios Superiores a 78%',
      skillsPoints: 30,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Portafolio (Artistas)',
      name: 'Portafolio (Artistas)',
      selected: false,
      message:
        'Portafolio de al menos 10 páginas, videos o piezas artisticas producidos individualmente. A criterio menos si es muy relevante.',
      skillsPoints: 24,
      secondarySkillsPoints: 18,
    },
    {
      id: 'Idioma desde b2',
      name: 'Idioma desde b2 ',
      selected: false,
      message: 'Certificado Colegio o Examen Externo (IELTS, TOEFEL, DUOLINGO, CAMBRIDGE, OXFORD)',
      skillsPoints: 24,
      secondarySkillsPoints: 18,
    },
    {
      id: 'Plan de Negocio',
      name: 'Plan de Negocio',
      selected: false,
      message:
        'Plan de Negocio Estructurado con Definición de Producto o Servicio, Recursos y Personas Necesarias en el tiempo, Análisis de Mercado, Modelo de Negocio, Plan Financiero, Análisis de Riesgos o DOFA, Plan de Implementación.',
      skillsPoints: 24,
      secondarySkillsPoints: 18,
    },
    {
      id: 'Investigación',
      name: 'Investigación',
      selected: false,
      message:
        'Artículo Científico de investigación individual, método científico para resolver problema o pregunta. La estructura fundamental de un artículo es título, autores, institución, resumen, palabras claves, introducción, materiales y métodos, resultados, discusión, conclusión y referencias bibliográficas.',
      skillsPoints: 20,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Actividad Deportiva Extracurricular Competitiva',
      name: 'Actividad Deportiva Extracurricular Competitiva',
      selected: false,
      message:
        'Al menos 8 meses - 130 horas anuales con certificado, I o G dependiendo del deporte y el liderazgo, evidencia fotográfica robusta en caso de practica individual',
      skillsPoints: 40,
      secondarySkillsPoints: 30,
    },
    {
      id: 'Curso Académico',
      name: 'Curso Académico',
      selected: false,
      message: 'Curso al menos 20 horas con certificado.',
      skillsPoints: 20,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Voluntariado',
      name: 'Voluntariado',
      selected: false,
      message: 'Al menos 25 horas con certificado fundación o Fotografías de Iniciativa.',
      skillsPoints: 20,
      secondarySkillsPoints: 15,
    },
    {
      id: 'Actividad Extracurricular o Acción',
      name: 'Actividad Extracurricular o Acción',
      selected: false,
      message:
        'Al menos 10 horas con soporte fotográfico, prueba y validador. Se debe subir soporte de evidencia: Ej: Codigo WEB.',
      skillsPoints: 16,
      secondarySkillsPoints: 12,
    },
    {
      id: 'Idioma desde C1',
      name: 'Idioma desde C1',
      selected: false,
      message: 'Certificado Colegio o Examen Externo (IELTS, TOEFEL, DUOLINGO, CAMBRIDGE, OXFORD)',
      skillsPoints: 36,
      secondarySkillsPoints: 27,
    },
  ];
  typesOfFile = [
    { id: 'Fotos', name: 'Fotos' },
    { id: 'Certificados', name: 'Certificados' },
    { id: 'Escritos', name: 'Escritos' },
    { id: 'Videos', name: 'Videos' },
    { id: 'Audios', name: 'Audios' },
    { id: 'Portafolio', name: 'Portafolio' },
    { id: 'Articulo', name: 'Articulo' },
    { id: 'Investigación', name: 'Investigación' },
    { id: 'Pagina WEB', name: 'Pagina WEB' },
    { id: 'Plan de Negocio', name: 'Plan de Negocio' },
    { id: 'Notas', name: 'Notas' },
    { id: 'Codigo', name: 'Codigo' },
    { id: 'Otros', name: 'Otros' },
  ];
  experienceReviews = [
    { id: 'Aprobado', name: 'Aprobado', selected: false },
    { id: 'Rechazado', name: 'Rechazado', selected: false },
    { id: 'Pendiente por ajustes', name: 'Pendiente por ajustes', selected: false },
  ];
  experienceReviewsStatus = {
    approved: 'Aprobado',
    rejected: 'Rechazado',
    pending: 'Pendiente por ajustes',
  };
  getSupports: any;
  supportsArray = [];
  deleteSupportsArray = [];
  isInputEnabled: boolean = true;
  maxCharacters: number = 600;
  characterCount: number = 0;
  isModalOpenedFromApproval: boolean = false;
  selectedSkills: string = '';
  isEndDateDisabled: boolean = false;
  selectedActivityType: string = '';
  selectedLocation: string = '';
  selectedMethodType: string = '';
  selectedStatusType: string = '';
  selectedExperienceType: string = '';
  secondarySkills: string = '';
  selectedExperienceTypeMessage: string = '';
  form: FormGroup;
  formList: ExperienceFormData[] = [];
  files: File[] = [];
  availableSkills = [];
  availableSecondarySkills = [];
  name = 'Angular 6';
  link: string;
  type: string;
  editorContent: string;
  datepickerConfig: Partial<BsDatepickerConfig>;
  currentStep: number = 1;
  isModalOpen = false;
  modalRef: NgbModalRef;
  isEditMode: boolean = false;
  student: boolean = true;
  experienceNameInvalid: boolean = false;
  supervisorNameInvalid: boolean = false;
  supervisorLastNameInvalid: boolean = false;
  supervisorEmailInvalid: boolean = false;
  initDateInvalid: boolean = false;
  endDateInvalid: boolean = false;
  selectedActivityInvalid: boolean = false;
  selectedSkillsInvalid: boolean = false;
  selectedExperienceTypeInvalid: boolean = false;
  selectedStatusInvalid: boolean = false;
  htmlContentInvalid: boolean = false;
  selectedSkillsPointsMap: { [skill: string]: number } = {};
  skillsScore: object;
  secondarySkillsScore: object;
  formListData: any[] = [];
  experienceSupports: any[];
  selectedFileType: any[];
  linksData: any[] = [];
  typeSupport: any[] = [];
  incomingSupports: any;
  supports: {} = {
    link: '',
    type: '',
  };
  selectedSupports: any[] = [];
  selectedFileTypes: any[] = [];

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private renderer: Renderer2,
    private s3Service: S3Service
  ) {
    this.experienceSupports = [];
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };

    this.form = new FormGroup({
      experienceName: new FormControl('', Validators.required),
      supervisorName: new FormControl('', Validators.required),
      supervisorLastName: new FormControl('', Validators.required),
      supervisorEmail: new FormControl('', [Validators.required, Validators.email]),
      supervisorPhoneNumber: new FormControl('', Validators.required),
      initDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      isOngoing: new FormControl(''),
      selectedActivity: new FormControl(null, Validators.required),
      selectedMethod: new FormControl(null, Validators.required),
      selectedLocation: new FormControl(null, Validators.required),
      selectedStatus: new FormControl(null, Validators.required),
      selectedSkills: new FormControl(null, Validators.required),
      secondarySkills: new FormControl(null, Validators.required),
      selectedExperienceType: new FormControl(null, Validators.required),
      selectedSkillsPoints: new FormControl(0, Validators.required),
      secondarySkillsPoints: new FormControl(0, Validators.required),
      experienceDescription: new FormControl('', Validators.required),
      descriptionPreview: new FormControl(''),
      approve: new FormControl(''),
      requestApproval: new FormControl(''),
      recommendation: new FormControl(''),
      skillsScore: new FormControl(''),
      secondarySkillsScore: new FormControl(''),
    });
  }
  ngOnInit() {
    this.getExperiences();
    this.getExecutionMode();
    this.getActivityStatus();
    this.getExecutionMethod();
    this.getSkills();
    if (this.editModeData) {
      this.setFormListData(this.editModeData);
    }
  }
  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionType' }).subscribe((response) => {
        this.activityType = response.map((item) => item.executionType);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getExecutionMode() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMode' }).subscribe((response) => {
        this.location = response.map((item) => item.executionMode);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getActivityStatus() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getActivityStatus' }).subscribe((response) => {
        this.status = response.map((item) => item.activityStatus);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getExecutionMethod() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMethod' }).subscribe((response) => {
        this.executionMethod = response.map((item) => item.executionMethod);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getSkill' }).subscribe((response) => {
        this.skills = response.map((item) => item.skillName);
        this.availableSkills = this.skills.map((skill) => skill);
        this.availableSecondarySkills = this.skills.map((skill) => skill);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  setStudentValue(value: boolean) {
    this.student = value;
    if (!this.student) {
      this.form.controls['experienceName'].disable();
      this.form.controls['supervisorName'].disable();
      this.form.controls['supervisorLastName'].disable();
      this.form.controls['supervisorEmail'].disable();
      this.form.controls['supervisorPhoneNumber '].disable();
      this.form.controls['initDate'].disable();
      this.form.controls['endDate'].disable();
      this.form.controls['isOngoing'].disable();
      this.form.controls['selectedActivity'].disable();
      this.form.controls['selectedMethod'].disable();
      this.form.controls['selectedLocation'].disable();
      this.form.controls['selectedSkills'].disable();
      this.form.controls['secondarySkills'].disable();
      this.form.controls['selectedExperienceType'].disable();
      this.form.controls['selectedStatus'].disable();
      this.form.controls['experienceDescription'].disable();
      this.form.controls['descriptionPreview'].disable();
    } else {
      this.form.controls['experienceName'].disable();
      this.form.controls['supervisorName'].disable();
      this.form.controls['supervisorLastName'].disable();
      this.form.controls['supervisorEmail'].disable();
      this.form.controls['supervisorPhoneNumber '].disable();
      this.form.controls['initDate'].disable();
      this.form.controls['endDate'].disable();
      this.form.controls['isOngoing'].disable();
      this.form.controls['selectedActivity'].disable();
      this.form.controls['selectedMethod'].disable();
      this.form.controls['selectedLocation'].disable();
      this.form.controls['selectedSkills'].disable();
      this.form.controls['secondarySkills'].disable();
      this.form.controls['selectedExperienceType'].disable();
      this.form.controls['selectedStatus'].disable();
      this.form.controls['experienceDescription'].disable();
      this.form.controls['descriptionPreview'].disable();
    }
  }

  getSelectedExperienceTypeMessage(): string {
    const selectedType = this.form.controls['selectedExperienceType'].value;
    const experience = this.experienceType.find((item) => item.id === selectedType);
    return experience ? experience.message : '';
  }

  onExperienceTypeSelected(experienceTypeId: string) {
    this.experienceType.forEach((type) => {
      type.selected = type.id === experienceTypeId;
    });
    this.calculateTotalPoints();
  }

  calculateTotalPoints() {
    const selectedExperienceType = this.experienceType.find((type) => type.selected);
    if (selectedExperienceType) {
      const selectedSkills = this.form.value.selectedSkills;
      const selectedSkillsPoints = selectedExperienceType.skillsPoints;
      this.skillsScore = {
        [selectedSkills]: selectedSkillsPoints,
      };
      const secondarySkills = this.form.value.secondarySkills;
      const secondarySkillsPoints = selectedExperienceType.secondarySkillsPoints;
      this.secondarySkillsScore = {
        [secondarySkills]: secondarySkillsPoints,
      };
    }
  }

  updateSecondarySkillsOptions() {
    const selectedSkills = this.form.value.selectedSkills || '';
    this.availableSecondarySkills = this.skills
      .filter((skill) => !selectedSkills.includes(skill))
      .map((skill) => skill);
    this.selectedSkills = selectedSkills;
    this.secondarySkills = this.form.value.secondarySkills || '';
    this.calculateTotalPoints();
  }

  approvalResponse() {
    this.selectedSupports = this.supportResponse;
    this.setFormListData(this.formListData);
  }

  setFormListData(formListData) {
    this.formListData = formListData;
    this.selectedSupports = this.supportResponse;
    if (this.experienceID) {
      const linkData = {
        api: `api/experienceSupports/${this.experienceID}`,
      };
      this.apiService.get(linkData).subscribe(
        (response) => {
          this.getSupports = response;
          this.linksData = [];
          this.typeSupport = [];
          this.incomingSupports = [];
          for (const item of response) {
            this.linksData.push(item.experienceSupports);
            this.typeSupport.push(item.typeOfSupport);
            this.incomingSupports.push({
              _id: item._id,
              links: item.experienceSupports,
              types: item.typeOfSupport,
            });
          }
        },
        (error) => {
          console.error('Error retrieving the data:', error);
        }
      );
    } else {
      for (const item of this.selectedSupports) {
        this.linksData.push(item.experienceSupports);
        this.typeSupport.push(item.typeOfSupport);
      }
    }
    if ((this.formListData, 'formListData', this.linksData)) {
      this.form.patchValue({
        experienceName: formListData.experienceName || '',
        supervisorName: formListData.supervisorName || '',
        supervisorLastName: formListData.supervisorLastName || '',
        supervisorEmail: formListData.supervisorEmail || '',
        supervisorPhoneNumber: formListData.supervisorPhoneNumber || '',
        initDate: formListData.initDate ? new Date(formListData.initDate) : null,
        endDate: formListData.endDate ? new Date(formListData.endDate) : null,
        isOngoing: formListData.isOngoing || false,
        selectedActivity: formListData.selectedActivity || [],
        selectedMethod: formListData.selectedMethod || [],
        selectedLocation: formListData.selectedLocation || [],
        selectedSkills: formListData.selectedSkills || [],
        selectedExperienceType: formListData.selectedExperienceType || [],
        secondarySkills: formListData.secondarySkills || [],
        selectedStatus: formListData.selectedStatus || [],
        experienceDescription: formListData.experienceDescription || '',
        recommendation: formListData.recommendation || '',
      });

      (this.selectedActivityType = this.form.value.selectedActivity || []),
        (this.selectedMethodType = this.form.value.selectedMethod || []),
        (this.selectedLocation = this.form.value.selectedLocation || []),
        (this.selectedSkills = this.form.value.selectedSkills || []),
        (this.selectedExperienceType = this.form.value.selectedExperienceType || []),
        (this.secondarySkills = this.form.value.secondarySkills || []),
        (this.selectedStatusType = this.form.value.selectedStatus || []);
    } else {
      this.form.reset();
      this.selectedActivityType = '';
      this.selectedMethodType = '';
      this.selectedLocation = '';
      this.selectedSkills = '';
      this.selectedExperienceType = '';
      this.secondarySkills = '';
      this.selectedStatusType = '';
    }
  }

  onTextChange(value: string) {
    if (value.length >= this.maxCharacters) {
      this.characterCount = this.maxCharacters;
      this.isInputEnabled = false;
      const textArea = document.getElementById('htmlContent') as HTMLTextAreaElement;
      if (textArea) {
        textArea.value = textArea.value.substring(0, this.maxCharacters);
      }
    } else {
      this.characterCount = value.length;
      this.isInputEnabled = true;
    }
  }

  getFileNameFromURL(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  maxCaractersContent(content: string): string {
    const maxLength = 40;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '.....';
    }
  }

  setEditMode(isEditMode: boolean) {
    this.isEditMode = isEditMode;

    this.toggleEndDate();
  }

  openModal(content: any) {
    this.modalRef = this.modalService.open(content);
    this.isModalOpen = true;
    this.resetValidation();
  }
  getContent(): TemplateRef<any> {
    return this.contentTemplateRef;
  }

  closeModal() {
    this.modalRef.close();
    if (this.modalRef) {
      this.files = [];
      this.linksData = [];
      this.selectedFileTypes = [];
      this.selectedFileType = [];
    }
  }

  resetValidation() {
    this.experienceNameInvalid = false;
    this.supervisorNameInvalid = false;
    this.supervisorLastNameInvalid = false;
    this.supervisorEmailInvalid = false;
    this.initDateInvalid = false;
    this.endDateInvalid = false;
    this.selectedActivityInvalid = false;
    this.selectedSkillsInvalid = false;
    this.selectedStatusInvalid = false;
    this.selectedExperienceTypeInvalid = false;
    this.htmlContentInvalid = false;
  }
  setModalRef(modalRef: NgbModalRef) {
    this.modalRef = modalRef;
    this.resetValidation();
  }

  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    this.currentStep--;
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    const supportToRemove = event._id;
    for (const support of this.getSupports) {
      if (support._id === supportToRemove) {
        this.supportsArray.push({
          _id: support._id,
          links: support.experienceSupports,
          types: support.typeOfSupport,
        });
      }
    }
    this.incomingSupports = this.incomingSupports.filter((item) => item._id !== supportToRemove);
    this.deleteFileFromS3(event);
  }

  async deleteFileFromS3(key) {
    return new Promise((resolve, reject) => {
      this.s3Service.deleteFileS3({
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error deleting file from S3:', err);
            reject(err);
          } else {
            if (data) {
            } else {
            }
            resolve(key);
          }
        },
      });
    });
  }

  onSelectFileType(selectedValue: any) {
    if (!this.selectedFileTypes.includes(selectedValue)) {
      this.selectedFileTypes.push(selectedValue);
    }
  }

  onInput(event: any) {
    const input = event.target.value;
    if (!this.validateLetters(input)) {
      event.preventDefault();
    }
  }

  validateLetters(input: string): boolean {
    const lettersRegex = /^[A-Za-z]+$/;
    return lettersRegex.test(input);
  }

  closeTab() {
    const windowObj: any = window;
    if (windowObj) {
      windowObj.close();
    }
  }

  toggleEndDate() {
    if (this.form.value.isOngoing) {
      this.form.get('endDate')?.disable();
    } else {
      this.form.get('endDate')?.enable();
    }
  }

  async uploadFileToS3(file, experienceId, name) {
    return new Promise((resolve, reject) => {
      const key = `experience/${experienceId}/${name}`;

      this.s3Service.uploadFile({
        file: file,
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error uploading file to S3:', err);
            reject(err);
          } else {
            const link = `https://${this.s3Service.S3_BUCKET}.s3.amazonaws.com/${key}`;
            resolve(link);
          }
        },
      });
    });
  }

  buildFormData(form: FormGroup, user: any): ExperienceFormData {
    return {
      user: user,
      experienceName: form.value.experienceName,
      supervisorName: form.value.supervisorName,
      supervisorLastName: form.value.supervisorLastName,
      supervisorEmail: form.value.supervisorEmail,
      supervisorPhoneNumber: form.value.supervisorPhoneNumber,
      initDate: form.value.initDate,
      endDate: form.value.endDate,
      isOngoing: form.value.isOngoing,
      selectedActivity: form.value.selectedActivity,
      selectedMethod: form.value.selectedMethod,
      selectedLocation: form.value.selectedLocation,
      selectedSkills: form.value.selectedSkills,
      selectedStatus: form.value.selectedStatus,
      selectedExperienceType: form.value.selectedExperienceType,
      secondarySkills: form.value.secondarySkills,
      experienceDescription: form.value.experienceDescription,
      approve: form.value.approve,
      requestApproval: form.value.requestApproval,
      recommendation: form.value.recommendation,
      skillsScore: this.skillsScore,
      secondarySkillsScore: this.secondarySkillsScore,
      supports: this.supports,
    };
  }

  async onSubmit() {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const formData: ExperienceFormData = this.buildFormData(this.form, user);
      let experienceId: any;

      if (!this.isEditMode) {
        experienceId = await handlePostRequest(this.apiService, formData);
        this.formList.push(formData);
        this.form.reset();
        this.currentStep = 1;
      }
      formData.requestApproval = this.form.get('requestApproval').value;

      const uploadPromises = this.files.map((file, index) =>
        this.uploadFileToS3(file, this.experienceID ?? experienceId, file.name)
          .then((link) => {
            return link;
          })
          .catch((error) => {
            console.error(`Error uploading ${file.name}:`, error);
            throw error;
          })
      );
      this.experienceSupports = await Promise.all(uploadPromises);

      if (this.experienceSupports.length !== this.selectedFileTypes.length) {
        throw new Error('Number of supports and types do not match');
      }
      for (let i = 0; i < this.experienceSupports.length; i++) {
        this.supportsArray.push({
          links: this.experienceSupports[i],
          types: this.selectedFileTypes[i],
        });
      }
      formData.supports = this.supportsArray;
      if (this.student) {
        this.closeModal();
      }

      handlePutRequest(this.apiService, this.experienceID ?? experienceId, formData);
      this.supportsArray = [];
      if (this.isEditMode) {
        Swal.fire({
          title: 'Actualizando Experiencia...',
          timerProgressBar: true,
          timer: 2500,
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          customClass: {
            container: 'custom-swal',
            timer: 'custom-timer',
          } as any,
        });
      }

      if (!this.form.valid) {
        this.experienceNameInvalid = this.form.controls['experienceName'].invalid;
        this.supervisorNameInvalid = this.form.controls['supervisorName'].invalid;
        this.supervisorLastNameInvalid = this.form.controls['supervisorLastName'].invalid;
        this.supervisorEmailInvalid = this.form.controls['supervisorEmail'].invalid;
        this.htmlContentInvalid = this.form.controls['experienceDescription'].invalid;
        return;
      }

      this.files = [];
      this.linksData = [];
      this.selectedFileTypes = [];
      this.selectedFileType = [];
      this.form.reset();
    } catch (error) {
      console.error('An error occurred:', error);
      Swal.fire({
        text: 'Recuerda seleccionar el tipo de documento',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  updateApprovalExperience() {
    try {
      if (this.form.valid) {
        const formData: ExperienceFormData = this.buildFormData(this.form, this.userId);
        this.closeModal();
        handlePutFromSupervisorRequest(this.apiService, this.parentID, formData);
        Swal.fire({
          icon: 'success',
          title: 'Gracias por dejar tu recomendación!',
          text: 'Ahora puedes cerrar esta pestaña',
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: '<i class="fas fa-check"></i>',
        }).then((result) => {
          if (result.isConfirmed) {
            this.closeTab();
          }
        });
      }
    } catch (error) {
      console.error('Error al actualizar la experiencia:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al actualizar la experiencia.',
        confirmButtonText: 'Aceptar',
      });
    }
  }
}
