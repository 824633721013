import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../public/services/api.service';
import { ExperienceModalComponent } from '../experienceModalComponent/experienceModalComponent';
import Swal from 'sweetalert2';
import { skillsArray } from '../skillsObject/skills.js';
@Component({
  selector: 'app-experience-cards',
  templateUrl: './experiencesCards.component.html',
  styleUrls: ['./experienceCards.component.scss'],
})
export class ExperienceCardsComponent implements OnInit {
  @ViewChild(ExperienceModalComponent, { static: false })
  private experienceModalComponent: ExperienceModalComponent;
  @Input() formList: any[] = [];
  @Input() urlId: any;
  formData: any = {
    experienceName: '',
    supervisorName: '',
    supervisorLastName: '',
    supervisorEmail: '',
    supervisorNumber: Number,
    initDate: Date,
    endDate: Date,
    isOngoing: Boolean,
    selectedActivity: [],
    selectedType: [],
    selectedApproaches: [],
    selectedSkills: [],
    experienceDescription: '',
    approve: '',
    supports: [],
  };
  formListData: any;
  selectedExperienceIndex: number | undefined;
  selectedExperience: any;
  showConfirmation = false;
  user = JSON.parse(localStorage.getItem('user'));
  skillsArray = skillsArray.slice(0, skillsArray.length - 1);
  isModalOpen = false;
  isEditMode: boolean = false;
  modalRef: NgbModalRef;
  loggedUserId: any;
  selectedSkills: string[] = [];
  private id: string;
  incomingSupports: any;
  experienceReviews = {
    approved: 'Aprobado',
    rejected: 'Rechazado',
    pending: 'Pendiente por ajustes',
    nonCheck: '',
  };
  constructor(private modalService: NgbModal, private apiService: ApiService) {}

  ngOnInit() {
    this.loggedUserId = this.user._id;
    this.sortByEndDate();
    for (const formItem of this.formList) {
      const id = formItem._id;
      this.getSupports(id);
    }
    this.experienceModalComponent.experienceCreated.subscribe(() => {
      this.formList = this.experienceModalComponent.formList;
    });
  }

  openModalEditMode() {
    const content = this.experienceModalComponent.getContent();
    this.modalRef = this.modalService.open(content);
    this.experienceModalComponent.experienceID = this.id;
    this.experienceModalComponent.setFormListData(this.formListData);
    this.experienceModalComponent.setEditMode(true);
    this.experienceModalComponent.setModalRef(this.modalRef);
    this.isModalOpen = true;
  }

  openEditModal(index: number) {
    if (this.formList.length > 0) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.formList[index];
      this.id = this.selectedExperience._id;
      if (this.urlId === this.loggedUserId) {
        this.apiService.get({ api: `api/experiences/${this.id}` }).subscribe(
          (response) => {
            this.formListData = response;
            this.openModalEditMode();
          },
          (error) => {
            console.error('Error en el GET:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error al obtener los datos',
              text: 'Ha ocurrido un error al obtener los datos. Por favor, inténtalo de nuevo más tarde.',
            });
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'No tienes permiso',
          text: 'No tienes permiso para editar este formulario.',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Índice no válido',
        text: 'El índice no es válido o la lista de formularios está vacía. Por favor, selecciona un índice válido.',
      });
    }
  }

  sortByEndDate() {
    this.formList.sort((a, b) => {
      if (!a.endDate) {
        return -1;
      }
      if (!b.endDate) {
        return 1;
      }

      const endDateA = new Date(a.endDate);
      const endDateB = new Date(b.endDate);
      const currentDate = new Date();

      const diffA = Math.abs(endDateA.getTime() - currentDate.getTime());
      const diffB = Math.abs(endDateB.getTime() - currentDate.getTime());

      return diffA - diffB;
    });
  }

  updateFormData(formData: any[]) {
    this.formList = formData;
    this.sortByEndDate();
  }

  toggleSkillSelection(skill: string) {
    const index = this.selectedSkills.indexOf(skill);
    if (index > -1) {
      this.selectedSkills.splice(index, 1);
    } else {
      this.selectedSkills.push(skill);
    }
  }

  isSelectedSkill(skill: string): boolean {
    return this.selectedSkills.includes(skill);
  }

  resetExperience() {
    this.formData = {};
    this.skillsArray.forEach((skill) => (skill.selected = false));
    this.selectedSkills = [];
  }

  shouldShowCard(card: any) {
    if (this.selectedSkills.length === 0) {
      return true;
    }

    for (const skill of this.selectedSkills) {
      if (!card.selectedSkills.includes(skill)) {
        return false;
      }
    }

    return true;
  }

  formatDate(date: string): string {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  showDeleteConfirmation(experience: any, index: number) {
    Swal.fire({
      title: 'Confirmar Eliminación',
      text: '¿Estás seguro de que deseas eliminar esta experiencia?',
      icon: 'warning',
      customClass: {
        title: 'my-title-class',
        icon: 'my-icon-class',
        confirmButton: 'my-confirm-button-class',
        cancelButton: 'my-cancel-button-class',
      },
      confirmButtonColor: '#545ba8',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedExperience = this.formList[index];
        if (this.selectedExperience) {
          const experienceId = this.selectedExperience._id;
          const deleteData = {
            api: `api/experiences/${experienceId}`,
          };

          this.apiService.delete(deleteData).subscribe(
            (response) => {
              if (index >= 0 && index < this.formList.length) {
                this.formList.splice(index, 1);
              }
            },
            (error) => {
              console.error('Error al eliminar la experiencia:', error);
            }
          );

          this.selectedExperience = null;
          Swal.fire('Eliminada!', 'Tu experiencia ha sido eliminada.', 'success');
        }
      }
    });
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }
  getSupports(id) {
    const linkData = {
      api: `api/experienceSupports/${id}`,
    };
    this.apiService.get(linkData).subscribe(
      (response) => {
        this.getSupports = response;
        for (const formItem of this.formList) {
          if (formItem._id === id) {
            formItem.supports = []; 
            for (const item of response) {
              formItem.supports.push({
                _id: item._id,
                links: item.experienceSupports,
                types: item.typeOfSupport,
              });
            }
          }
        }
      },
      (error) => {
        console.error('Error retrieving the data:', error);
      }
    );
  }

  trimHtmlContent(content: string): string {
    const maxLength = 270;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '...';
    }
  }
}
