import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { CreditCardValidators } from 'angular-cc-library';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent implements OnInit {
  /** CONSTANTS */
  /** API to get saved credit cards */
  readonly CREDIT_CARD_HISTORY_API = 'api/tarjeta/usuario/';
  /** API to post credit cards */
  readonly CREDIT_CARD_API = 'api/payu-token';
  /** API path for retrieving students by recruitment partner */
  readonly STUDENT_BY_RECRUITMENT_API = 'api/user/recruitment/';

  /** VARIABLES */
  @Input() refCode: string;
  @Input() subtotal;
  /** EventEmitter to start the purchase process after the credit card token is retrived */
  @Output() startPurchase: EventEmitter<any> = new EventEmitter<any>();
  /** Event emmiter to return to previous page */
  @Output() returnToPrevious: EventEmitter<any> = new EventEmitter<any>();
  /** variable that holds the payment information form */
  paymentInformationForm: FormGroup;
  /** Array that holds the previous credit cards */
  formerCreditCards: any[] = [];
  /** Variable that holds the currently selected credit card
   * If the value is -1 the user is adding a new credit card
   */
  selectedCreditCard = -1;
  /** Reference to credit card franchise element */
  @ViewChild('creditCardFranchise') franchiseElements: ElementRef;
  /** Payment type */
  payment_type: string = '';
  /** Banks PSE */
  banksPse: any = [];
  /** Variable that holds the id of the current user */
  userId = '';
  /** Variable that holds the id of the student for which the payment applies */
  studentId = '';
  /** Variable that signals if the current user is a recruitment partner */
  isRecruitment = false;
  /** Array that holds the list of students of the recruitment partner */
  students = [];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId,
    private ngxSpinner: NgxSpinnerService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    //  
    this.paymentInformationForm = this.fb.group({
      informacionDeEnvio: this.fb.group({
        direccion: ['', Validators.required],
        ciudad: ['', Validators.required],
        estado: ['', Validators.required],
        pais: ['', Validators.required],
        codigoPostal: ['', Validators.required],
        telefono: ['', Validators.required],
      }),
      informacionPersonal: this.fb.group({
        nombreCompleto: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telefono: ['', Validators.required],
        numeroDeIdentificacion: ['', Validators.required],
      }),
    });

    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.userId = user._id;
      if (user.tipo === 'Recruitment') {
        this.isRecruitment = true;
        this.studentId = '';
        this.getStudents();
      } else {
        this.isRecruitment = false;
        this.studentId = this.userId;
      }
      this.getSavedPaymentMethods();
    }
  }

  /**
   * Deletes the credit card information form control from the form
   * @param index: index of the credit card to be used
   */
  onUseSavedCreditCard({ index }: { index: number }): void {
    if (this.paymentInformationForm.get('informacionTarjeta') !== null) {
      this.paymentInformationForm.removeControl('informacionTarjeta');
    }
    this.selectedCreditCard = index;
  }

  /**
   * Adds a new form control to insert credit card information
   */
  onAddCreditCard(): void {
    if (this.paymentInformationForm.get('informacionTarjeta') === null) {
      const informacionTarjeta = this.fb.group({
        fullCardName: ['', Validators.required],
        idNumber: ['', Validators.required],
        cardNumber: [
          '',
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expDate: [
          '',
          [Validators.required, CreditCardValidators.validateExpDate],
        ],
      });
      this.paymentInformationForm.addControl(
        'informacionTarjeta',
        informacionTarjeta
      );
    }
    this.selectedCreditCard = -1;
  }

  /**
   * Retrieves the saved payment methods from the backend;
   */
  getSavedPaymentMethods(): void {
    this.apiService.get({ api: this.CREDIT_CARD_HISTORY_API + this.userId }).subscribe(
      (response) => {
        this.formerCreditCards = response;
        if (this.formerCreditCards.length < 1) {
          this.onAddCreditCard();
        } else {
          this.selectedCreditCard = 0;
        }
      },
      (err) => {
        this.alertService.showError({
          msg:
            this.translate.instant("Payment-info.load-error")
        });
      }
    );
  }

  /**
   * Handles the click on the payment form subsmission
   */
  onSubmitClick(): void {
    const id = this.userId;
    const formValue = this.paymentInformationForm.value;
    // If selected card is non existent create the new card
    if (this.selectedCreditCard === -1) {
      // Format the expiration date correctly
      let expDate = formValue.informacionTarjeta.expDate;
      const franchise = this.franchiseElements.nativeElement.innerText;
      expDate = expDate.replace(/\s+/g, '');
      let expDateFormmated = '';
      const expDateArray = expDate.split('/');
      expDateFormmated = expDateArray[1] + '/' + expDateArray[0];
      // Format the creditCard correctly
      let cc = formValue.informacionTarjeta.cardNumber;
      cc = cc.replace(/\s+/g, '');
      // Form the payload
      const creditCardBody = {
        payerId: id,
        name: formValue.informacionTarjeta.fullCardName,
        identificationNumber: formValue.informacionTarjeta.idNumber,
        paymentMethod: franchise,
        number: cc,
        expirationDate: expDateFormmated,
      };
      this.ngxSpinner.show();
      this.apiService
        .post({ api: this.CREDIT_CARD_API, data: creditCardBody })
        .subscribe(
          (response) => {
             
            const token = response.token;
            const paymentInfo = {
              student: this.studentId,
              description: 'Pago de prueba',
              creditCardTokenId: token,
              paymentMethod: creditCardBody.paymentMethod,
              merchantBuyerId: '2',
              merchantPayerId: id,
              usuario: {
                fullName: formValue.informacionPersonal.nombreCompleto,
                emailAddress: formValue.informacionPersonal.email,
                contactPhone: formValue.informacionPersonal.telefono,
                dniNumber: formValue.informacionPersonal.numeroDeIdentificacion,
              },
              direccion: {
                street1: formValue.informacionDeEnvio.direccion,
                street2: '',
                city: formValue.informacionDeEnvio.ciudad,
                state: formValue.informacionDeEnvio.estado,
                country: 'CO',
                postalCode: formValue.informacionDeEnvio.codigoPostal,
                phone: formValue.informacionDeEnvio.telefono,
              },
            };
            this.ngxSpinner.hide();
            this.startPurchase.emit(paymentInfo);
          },
          (err) => {
            this.ngxSpinner.hide();
            this.alertService.showError({
              msg:
                this.translate.instant("Payment-info.payment-error")
            });
          }
        );
    } else {
      // If a created card already exists start the process for paying
      const creditCard = this.formerCreditCards[this.selectedCreditCard];
      const paymentInfo = {
        student: this.studentId,
        description: 'Pago de prueba',
        creditCardTokenId: creditCard.token,
        paymentMethod: creditCard.franquicia,
        merchantBuyerId: '2',
        merchantPayerId: id,
        usuario: {
          fullName: formValue.informacionPersonal.nombreCompleto,
          emailAddress: formValue.informacionPersonal.email,
          contactPhone: formValue.informacionPersonal.telefono,
          dniNumber: formValue.informacionPersonal.numeroDeIdentificacion,
        },
        direccion: {
          street1: formValue.informacionDeEnvio.direccion,
          street2: '',
          city: formValue.informacionDeEnvio.ciudad,
          state: formValue.informacionDeEnvio.estado,
          country: 'CO',
          postalCode: formValue.informacionDeEnvio.codigoPostal,
          phone: formValue.informacionDeEnvio.telefono,
        },
      };
      this.startPurchase.emit(paymentInfo);
    }
  }
  /**
   * Método para traer bancos PSE
   */
  getBanks(): void {
    this.apiService.get({ api: 'api/pse-banks' }).subscribe(
      (res) => {
        if (res.success) {
          this.payment_type = 'PSE';
          this.banksPse = res.banks;
        } else {
          alert(this.translate.instant("Payment-info.pse-not-available"));
        }
      },
      (err) => {
        alert(this.translate.instant("Payment-info.pse-not-available"));
      }
    );
  }

  handleBackClick(): void {
    if (this.payment_type !== '') {
      this.payment_type = '';
    } else {
      this.returnToPrevious.emit();
    }
  }

  /** Retrieve the list of students of the recruitment partner */
  getStudents(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const id = user._id;
    this.apiService.get({ api: this.STUDENT_BY_RECRUITMENT_API + id }).subscribe((response) => {
      this.students = response;
    }, err => {
      this.alertService.showError({ msg: 'There was an error loading the information, please try again later or contact support' });
    }, (complete?) => {

    });
  }
}
