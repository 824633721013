<div *ngIf="sorts">
  <canvas
    baseChart
    [datasets]="radarChartData"
    [options]="radarChartOptions"
    [labels]="radarChartLabels"
    [chartType]="radarChartType"
  >
  </canvas>
</div>
