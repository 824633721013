<div class="row">
  <div class="col-12">
    <h4 class="titulo-wizard">Carta de Intención</h4>
    <p>
      A través de tu carta de intención los departamentos de admisión de las universidades te conocerán y decidirán si
      eres o no un buen candidato, en ella resume tus competencias trabajadas,tu propósito y cómo has trabajado para
      lograrlo.
    </p>
    <hr class="hrLine" />
  </div>
</div>
<div class="border-card">
  <div class="m-auto">
    <div class="w-100">
      <div class="d-flex justify-content-center">
        <label class="txt-style about-me-title title-letter">Carta de intención</label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="txt-style txt-letter">{{ project }}</label>
      </div>
    </div>
    <div class="d-flex justify-content-around mt-2">
      <div class="d-flex justify-content-around w-70">
        <div class="w-55">
          <button class="button-letter w-100 h-100" (click)="goToEdit()">Editar</button>
        </div>
        <div class="w-55">
          <button class="button-letter w-100 h-100" (click)="goToLetterReady()">Ver</button>
        </div>
      </div>
    </div>
  </div>
</div>
