import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-mdl-join-webinar',
  templateUrl: './mdl-join-webinar.component.html',
  styleUrls: ['./mdl-join-webinar.component.scss']
})
export class MdlJoinWebinarComponent implements OnInit {
  /** CONSTANTS */
  readonly CUPO_WEBINAR_API = 'api/cupo-webinar/';

  @Output() close = new EventEmitter<any>();

  public user: any;
  constructor(
    @Optional() private dialogRef: MatDialogRef<MdlJoinWebinarComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private apiService: ApiService,
    private alertService: AlertService,



  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
  }



  /**
   * Close current mdl
   * @memberof MdlJoinWebinarComponent
   */
  public onCloseMdl() {
    this.dialogRef.close();
  }

  public async onApplyWebinar() {
    try {
      let webinarId = this.data.webinar._id;

      // Check if exits
      let checkQuota = await this.checkUserQuota({ webinarId: webinarId, userId: this.user._id });
      if (checkQuota && checkQuota.length > 0) {
        this.alertService.showWarning({ msg: 'Ya tenias un cupo en este webinar' });
        this.dialogRef.close();
        return
      }
      // Create quota
      let obj = {
        webinar: webinarId,
        user: this.user._id
      }
      let repsonCupo: any = await this.apiService.postPromise({ api: this.CUPO_WEBINAR_API, data: obj });
      if (repsonCupo && repsonCupo.success) {
        this.alertService.showSuccess({ msg: 'Estas registrado' });
        this.dialogRef.close();
      }

    } catch (err) {
      console.error(`Error al aplicar al webinar ${err}`);
    }
  }

  /**
   * Get quota per user and webinar
   * @param param0 
   * @returns A []
   */
  public async checkUserQuota({ webinarId, userId }) {
    return this.apiService.getAsPromise({ api: `${this.CUPO_WEBINAR_API}user/${userId}/webinar/${webinarId}` });
  }

}
