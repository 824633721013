<div class="match-card">
  <div class="match-card-header">
    <img class="smile-img" src="assets/utils/smile.png" alt="smile">
    <h4 class="match-card-title">{{'Cultural-match-card.your-response-is'|translate}}</h4>
    <img class="smile-img" src="assets/utils/smile.png" alt="smile">
  </div>
  <h5 class="match-card-disclaimer">{{'Cultural-match-card.however-there-are'|translate}}</h5>
  <div class="destiny-data">
    <div class="left-align">
      <h5 class="match-card-subtitle">{{'Cultural-match-card.destiny'|translate}}</h5>
      <div class="country">
        <img class="country-image" [src]="matchData.countryImage" alt="country">
        {{matchData.countryName}}
      </div>
    </div>
  </div>
  <div class="city-data">
    <div class="right-align">
      <h5 class="match-card-subtitle">{{'Cultural-match-card.cultural-match'|translate}}</h5>
      <div class="city">
        {{matchData.cityName}}
      </div>
    </div>
  </div>
  <div class="match-card-actions">
    <button (click)="onViewOtherOptions()" id="other-options">{{'Cultural-match-card.see-other-options'|translate}}</button>
    <button (click)="onViewProgramsClick()" id="view-programs">{{'Cultural-match-card.view-programs'|translate}}</button>
  </div>
</div>
