<div class="blog-parent">
  <div class="blog-container">
    <div class="second-header">
      <h2 class="blog-title">{{ 'Home.blog' | translate }}</h2>
      <div class="d-flex w-100">
        <div class="search-bar">
          <img
            (click)="onSearchByName()"
            class="search-image display-mobile-none"
            src="assets/utils/search.png"
            alt="search" />
          <input
            (keyup.enter)="onSearchByName()"
            [(ngModel)]="search"
            class="search-input"
            type="text"
            placeholder="Busca por palabras" />
          <button class="search-button display-mobile-none" (click)="onSearchByName()">
            {{ 'Home.search' | translate }}
          </button>
        </div>
        <button class="search-button display-mobile" (click)="onSearchByName()">
          <img (click)="onSearchByName()" class="search-image" src="assets/utils/ri_search-line.png" alt="search" />
        </button>
      </div>
    </div>
    <div class="countries-container">
      <div class="country-btn" *ngFor="let country of countries; index as i">
        <button class="country-button" (click)="onSelectCountry({ index: i })">
          {{ country.nombre | translate }}
        </button>
        <div class="country-button-border" [ngClass]="country.selected ? 'border-active' : ''"></div>
      </div>
    </div>
    <div class="tags-blogs-container row m-0 p-0">
      <div class="row tags-container col-xl-5 col-12 m-0 p-0 pl-lg-2">
        <div class="col-xl-12">
          <p>
            Filtros por competencias
            <img
              (click)="showCompetenciesFilters = !showCompetenciesFilters"
              class="collapse-img display-mobile"
              src="assets/utils/arrow-down.png"
              alt="arrow" />
          </p>
        </div>
        <div
          *ngFor="let skill of skills; index as i"
          (click)="onSelectSkills({ index: i })"
          [ngClass]="{ 'tag-pill-selected': skill.selected === true, 'display-mobile-none': !showCompetenciesFilters }"
          class="tag-pill">
          {{ skill.tag }}
        </div>

        <div class="col-xl-12 general-filters">
          <p>
            Filtros generales
            <img
              (click)="showGeneralFilters = !showGeneralFilters"
              class="collapse-img display-mobile"
              src="assets/utils/arrow-down.png"
              alt="arrow" />
          </p>
        </div>
        <div
          *ngFor="let tag of tags; index as i"
          (click)="onSelectTag({ index: i })"
          [ngClass]="{ 'tag-pill-selected': tag.selected === true, 'display-mobile-none': !showGeneralFilters }"
          class="tag-pill">
          {{ tag.tag | translate }}
        </div>
      </div>
      <div class="overflow-container col-xl-7 col-12 m-0 p-0 pr-lg-2">
        <div class="blogs-container">
          <p class="m-auto text-center" *ngIf="blogs && !blogs?.length">No hay resultados de búsqueda</p>
          <app-blog-card
            [blog]="blog"
            *ngFor="
              let blog of blogs
                | paginate
                  : {
                      itemsPerPage: BLOGS_PER_PAGE,
                      currentPage: page,
                      totalItems: numberOfBlogs
                    }
            "></app-blog-card>
        </div>
        <div class="pagination-control">
          <pagination-controls
            [previousLabel]="''"
            [nextLabel]="''"
            [responsive]="false"
            (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
