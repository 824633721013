import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LabelType, Options } from '@m0t0r/ngx-slider';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-budget',
  templateUrl: './my-budget.component.html',
  styleUrls: ['./my-budget.component.scss'],
})
export class MyBudgetComponent implements OnInit, OnDestroy {
  readonly settingsA: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  readonly USER_API = 'api/user/';
  readonly USER_ONE_API = 'api/oneUser/';
  readonly budgetSliderOptions: Options = {
    floor: 4000,
    ceil: 70000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.currencyPipe.transform(value, 'USD', 'code');
        case LabelType.High:
          return this.currencyPipe.transform(value, 'USD', 'code');
        default:
          return this.currencyPipe.transform(value, 'USD', 'code').split('.00')[0];
      }
    },
  };
  /** Array that holds all the possible financing options */
  financingOptionsDropdownList = [
    {
      value: 'Bank loan',
      name: 'My-budget.bank-loan',
      text: '',
    },
    {
      value: 'Own financing',
      name: 'My-budget.own-financing',
      text: '',
    },
    {
      value: 'Scholarship',
      name: 'My-budget.scholarship',
      text: '',
    },
    {
      value: 'Student loan',
      name: 'My-budget.student-loan',
      text: '',
    },
    {
      value: 'Other',
      name: 'My-budget.otro',
      text: '',
    },
  ];

  /** VARIABLES */
  /** Variable that holds the id of the current user */
  id: string;
  /** Variable that determines if the code is being executed in the browser or in the server */
  isBrowser = false;
  /** Variable that holds the minimun budget of the user */
  minBudget = 4000;
  /** Variable that holds the maximum budget of the user */
  maxBudget = 70000;
  /** Variable that holds the financing type */
  financingType: any[] = [];
  /** Variable that shows if the save option is loading */
  loading = false;
  selectedFinancingMethods: any[] = []; // Arreglo para almacenar todas las opciones seleccionadas
  financialData: any;
  /** Handles the subscription events and updates the data accordingly */
  translationSubcription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private currencyPipe: CurrencyPipe,
    private translateOther: TranslateService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    if (isPlatformBrowser(platformId)) {
      this.isBrowser = true;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.translationSubcription = this.translateOther.onLangChange.subscribe((event) => {
        this.setupTranslations();
      });
      this.setupTranslations();
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getBudgetInfo();
    }
  }

  ngOnDestroy(): void {
    if (this.translationSubcription) {
      this.translationSubcription.unsubscribe();
    }
  }

  setupTranslations(): void {
    const financeOptions = this.financingOptionsDropdownList.slice();
    for (let i = 0; i < this.financingOptionsDropdownList.length; i++) {
      financeOptions[i].text = this.translateOther.instant(this.financingOptionsDropdownList[i].name);
    }
    this.financingOptionsDropdownList = financeOptions;
  }
  /**
   * Retrieves the budget information from the backend
   */
  getBudgetInfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.USER_ONE_API + this.id }).subscribe(
      (response) => {
        if (response.presupuestoMinimo !== undefined) {
          this.minBudget = response.presupuestoMinimo;
        }
        if (response.presupuestoMaximo !== undefined) {
          this.maxBudget = response.presupuestoMaximo;
        }
        this.financialData = response.tipoFinanciacion;
      },
      (err) => {
        this.alertService.showError({ msg: this.translateOther.instant('My-budget.load-error') });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  onFinancingMethodSelect(selectedItems: any | any[]) {
    if (Array.isArray(selectedItems)) {
      this.financingType = selectedItems.map((item) => item.value);
    } else if (selectedItems) {
      const selectedValue = selectedItems.text;
      const index = this.financingType.indexOf(selectedValue);
      if (index === -1) {
        this.financingType.push(selectedValue);
      } else {
        this.financingType.splice(index, 1);
      }
    } else {
      console.error('El evento onSelect no proporcionó un array o un elemento seleccionado:', selectedItems);
    }
  }

  /** Handles the click on the save changes button by updating the information in the backend */
  onSaveChanges(): void {
    this.loading = true;

    const data = {
      presupuestoMaximo: this.maxBudget,
      presupuestoMinimo: this.minBudget,
      tipoFinanciacion: this.financialData,
    };
    this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
      (response) => {
        if (response.success === false) {
          this.alertService.showError({ msg: this.translateOther.instant('My-budget.submit-error') });
          this.loading = false;
        } else {
          this.alertService.showSuccess({ msg: this.translateOther.instant('My-budget.submit-success') });
          this.loading = false;
          this.navigationService.navigateTo({ path: 'profile' });
        }
      },
      (err) => {
        this.alertService.showError({ msg: this.translateOther.instant('My-budget.submit-error') });
        this.loading = false;
      }
    );
  }
}
