import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private isVisibleSidebar$ = new BehaviorSubject<boolean>(true);

  constructor(private router: Router) { }

  navigateTo({path, params}: {path: string, params?: any}) {
    return this.router.navigate([path], {queryParams: params});
  }

  getIsVisibleSidebar(): Observable<boolean> {
    return this.isVisibleSidebar$.asObservable();
  }

  setIsVisibleSidebar(value: boolean): void {
    this.isVisibleSidebar$.next(value);
  }
}
