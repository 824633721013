<input type="checkbox" id="check" [checked]="toggle" hidden>
<div class="sidebar">
  <div class="header">
    <img id="sidebar-logo" class="logo" src="assets/home/logos/blue-logo.png" alt="u360 logo">
    <mat-icon class="close" (click)="toggle = !toggle">close</mat-icon>
  </div>
  <a (click)="toggle = !toggle" class="sidebar-link" [routerLink]="menuItem.link" *ngFor="let menuItem of menu">
    <img  *ngIf="menuItem.icon" [matTooltip]="menuItem.name | translate" matTooltipPosition="right" [src]="menuItem.icon" class="sidebar-item" alt="menuItem.alt">
    <label class="sidebar-label">{{menuItem.name | translate}}</label>
  </a>
</div>
