<div class="ticket-detail">
  <div class="ticket-detail-header">
    <img (click)="onBackClick()" src="assets/utils/back.png" class="back-button" alt="go back">
    <h3 class="ticket-detail-header"> {{'Alerts.ticket-for' | translate}}: {{ticket.aplicacion.programa.nombre}} </h3>
  </div>
  <div class="ticket-detail-content">
    <div class="ticket-general-data">
      {{'Alerts.title' | translate}}: {{ticket.titulo}} <br>
      {{'Alerts.tracking-number' | translate}}: {{ticket.numeroSeguimiento}} <br>
      {{'Alerts.priority' | translate}}: {{ticket.prioridad}} <br>
      {{'Alerts.reason' | translate}}: {{ticket.razon}} <br>
      <a [routerLink]="'../../application/' + ticket.aplicacion._id">{{'Alerts.go-to-application' | translate}}</a>
    </div>
    <div class="ticket-messages">
      <div class="messages">
        <div class="full-width" *ngFor="let message of ticket.mensajes">
          <div [ngClass]="message.remitente._id === userId?'self-message':'advisor-message'" class="ticket-message">
            {{message.mensaje}}
            <div class="message-date">
              {{message.fechaEnvio | date}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-input" *ngIf="ticket.estado != 'Cerrado'">
      <input (keyup.enter)="onSendMessage()" type="text" id="message-bar" [(ngModel)]="message" [placeholder]="'Alerts.write-your-message' | translate">
      <button (click)="onSendMessage()" id="send-button">{{'Alerts.send' | translate}}</button>
    </div>
  </div>
</div>
