<div class="principal-container">
  <div class="max-width">
    <form >
      <div class="container-body">
        <div class="container-representative">
          <label>¡Gracias por realizar nuestro test de ingles!</label>
          <div class="resultContainer">
            <img src="../../../../assets/u360tips/certificado.png" />
            <p>
              Tu resultado fue
              <span>{{ englishLevel }}</span>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
