<div class="translation-service-shop-card">
  <img class="translation-service-shop-card-img" [src]="image" alt="shopping cart">
  <div class="translation-service-shop-card-data">
    <div class="data-header" *ngIf="isShoppingCart">
      <mat-icon class="remove-button" (click)="onRemoveServiceClick()"> close </mat-icon>
    </div>
    <div class="translation-service-shop-title">
      {{serviceName}}
    </div>
    <div class="translation-service-pages-title">
      {{ "Translation-services-shopping-cart.number-pages" | translate }}
    </div>
    <div class="page-counter">
      <img [ngStyle]=" {'opacity': isShoppingCart? '1': '0' }" (click)="onDecreaseNumberOfPages()"
        src="assets/utils/menos.png" class="minus-button" alt="deacrease">
      <div class="counter">
        <div class="counter-cell">
          {{numberOfPages}}
        </div>
      </div>
      <img [ngStyle]=" {'opacity': isShoppingCart? '1': '0' }" (click)="onAddNumberOfPages()"
        src="assets/utils/mas.png" class="plus-button" alt="add">
    </div>
    <div class="cost">
      ${{cost|number}} {{currency}}
    </div>
  </div>
</div>
