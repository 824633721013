<div class="free-guides">
  <div class="free-guides-container">
    <div class="free-guides-right">
      <h1 class="free-guides-title">Estrategias de Estudios Internacionales</h1>
      <h2 class="free-guides-subtitle">hechas por expertos autorizados.</h2>
      <div class="buttons-container">
        <button class="button" [ngClass]="{
          'pink': section.color === 'pink',
          'blue': section.color === 'blue',
          'green': section.color === 'green',
          'yellow': section.color === 'yellow'
          }"
        *ngFor="let section of availableSections; index as i" (click)="goToResources(section.palabraClave)">{{ section.categoria }}</button>
      </div>
    </div>
  </div>
</div>
