import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { NavigationService } from '../../../services/navigation.service';

interface Answer {
  answer: string;
  points: number;
  analysis: string;
}

interface Question {
  question: string;
  answers: { [key: string]: Answer };
}

interface Skill {
  skillName: string;
  questionsAndAnswers: {
    questions: Question[];
  }[];
}

interface SkillResponse {
  skill: string;
  totalPoints: number;
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  @Output() submitToParent = new EventEmitter<void>();
  @Output() questionsAndAnswers = new EventEmitter<any[]>();
  @Output() skillPointsData = new EventEmitter<{ [key: string]: number }>();

  questionary: Skill[] = [];
  readonly skillsApi = 'api/getSkill/';
  currentSkillIndex: number = 0;
  currentQuestionIndex: number = 0;
  selectedAnswer: Answer | null = null;
  responses: any[] = [];
  skillPercentages: { [key: string]: number } = {};
  shuffledAnswers: Answer[] = [];
  globalPercentage: any;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private navigationService: NavigationService
  ) {}

  get currentSkill(): Skill {
    return this.questionary[this.currentSkillIndex];
  }

  get currentQuestion(): Question {
    return this.currentSkill.questionsAndAnswers[0].questions[this.currentQuestionIndex];
  }

  get progress(): string {
    const totalQuestions = this.currentSkill.questionsAndAnswers[0].questions.length;
    return (this.currentQuestionIndex / totalQuestions) * 100 + '%';
  }

  get progressValue(): number {
    const totalQuestions = this.currentSkill.questionsAndAnswers[0].questions.length;
    return (this.currentQuestionIndex / totalQuestions) * 100;
  }

  ngOnInit(): void {
    this.getSkills();
  }

  nextQuestion(): void {
    if (this.selectedAnswer) {
      this.responses.push({
        skill: this.currentSkill.skillName,
        question: this.currentQuestion.question,
        answerSelected: this.selectedAnswer,
      });

      this.selectedAnswer = null;

      if (this.currentQuestionIndex < this.currentSkill.questionsAndAnswers[0].questions.length - 1) {
        this.currentQuestionIndex++;
      } else if (this.currentSkillIndex < this.questionary.length - 1) {
        this.currentSkillIndex++;
        this.currentQuestionIndex = 0;
      } else {
        this.onSubmit();
      }

      this.shuffleAnswers();
    }
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsApi }).subscribe(
        (response) => {
          this.globalPercentage = response.find((skill) => skill.skillName === 'Desempeño Global Competencias');

          this.questionary = response.filter((skill: any) => skill.skillName !== 'Desempeño Global Competencias');
          this.shuffleAnswers();
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las habilidades',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('Unexpected error:', error);
    }
  }

  calculateScoreAndPercentage(skillName: string): number {
    const skillQuestionsAndAnswers = this.responses.filter((item) => item.skill === skillName);

    let totalPoints = 0;
    const maxPointsPerQuestion = 3;
    const totalPossiblePoints = maxPointsPerQuestion * skillQuestionsAndAnswers.length;
    for (const questionAndAnswer of skillQuestionsAndAnswers) {
      const answerSelected = questionAndAnswer.answerSelected;
      if (answerSelected) {
        totalPoints += parseFloat(answerSelected.points);
      }
    }
    const skillPercentage = (totalPoints / totalPossiblePoints) * this.globalPercentage.skillWeight;
    return parseFloat(skillPercentage.toFixed(2));
  }

  onSubmit(): void {
    this.skillPercentages = this.questionary.reduce((acc, skill) => {
      acc[skill.skillName] = this.calculateScoreAndPercentage(skill.skillName);
      return acc;
    }, {});

    this.questionsAndAnswers.emit(this.responses);
    this.skillPointsData.emit(this.skillPercentages);
    this.submitToParent.emit();
  }

  shuffleAnswers(): void {
    const answersArray = Object.values(this.currentQuestion.answers);
    for (let i = answersArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answersArray[i], answersArray[j]] = [answersArray[j], answersArray[i]];
    }
    this.shuffledAnswers = answersArray;
  }
}
