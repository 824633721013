import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeResource'
})
export class ActiveResourcePipe implements PipeTransform {

  /**
   * If a resource is active, append the -active string to the url
   * @param url resource url
   * @param active the resource is active?
   */
  transform(url: string, active: boolean[]): string {
    if (active) {
      const position = url.lastIndexOf('.');
      const newUrl = url.slice(0, position) + '-active' + url.slice(position);
      return newUrl;
    } else {
      return url;
    }
  }

}
