import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { skillsArray } from '../../../../skillsObject/skills.js';
@Component({
  selector: 'app-info-sugerencias',
  templateUrl: './skillsSuggestions.component.html',
  styleUrls: ['../testDashboard.component.scss'],
})
export class SkillsSuggestionsComponent implements OnInit {
  @Input() sentData: any;
  contentCompetition: any = {};
  skillsTest: any;
  skillName: string;
  route: any;
  skillsArray = skillsArray;
  selectedSkill: any;
  constructor(private apiService: ApiService, private router: Router, private location: Location) {}

  ngOnInit(): void {
    this.route = this.location.path();
    const segments = this.route.split('/');
    this.skillName = segments[segments.length - 1];
    this.showCompetitionInformation({ skillName: this.skillName });
  }

  returnDashBoard() {
    this.router.navigate(['/skill-dashboard']);
  }

  showCompetitionInformation({ skillName }) {
    this.selectedSkill = this.skillsArray.find((skill) => skill.id === skillName);
    if (this.selectedSkill) {
      this.contentCompetition = {
        title: this.selectedSkill.name,
        description: this.selectedSkill.descriptions.general,
        suggestions: this.selectedSkill.suggestion.suggestions,
        suggestionDescription: this.selectedSkill.suggestion.global
      };
    }
  }
}
