import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-validate-code',
  templateUrl: './validate-code.component.html',
  styleUrls: ['./validate-code.component.scss'],
})
export class ValidateCodeComponent implements OnInit {
  public code: any;
  readonly GETCODE = 'api/codigoTestCode/';
  user: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { usuario: string; additionalFav: any; isEditing: boolean },
    private dialogRef: MatDialogRef<ValidateCodeComponent>,
    private apiService: ApiService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    //  

  }

  ngOnInit(): void {}
  /**
   * Método para buscar código ingresado
   * @param code Codigo ingresado
   */
  public validar(code) {
    this.apiService.get({ api: this.GETCODE + code }).subscribe(
      (response) => {
        //  
        if ( response.length > 0) {
          //  
          let emailCode
          if(response[0]){
            emailCode = response[0].emailUsuario;
          }
          if(emailCode === this.user.email){
            this.dialogRef.close(response);
          }else{
            Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El código ingresado no esta asignado a tu email',
          });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El código ingresado no existe',
          });
        }
      },
      (err) => {
        this.dialogRef.close(false);
      }
    );
  }
  public closeModal(code) {
    this.dialogRef.close(code);
  }
}
