import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './showMoreComponent.component.html',
  styleUrls: ['./showMoreComponent.component.scss'],
})
export class ShowMoreComponent implements OnInit {
  @Input() showMoreData: any;
  readonly documentsApi = 'api/getDocumentConfig/';
  readonly skillsApi = 'api/getSkill/';
  documents: any;
  documentsWithData: any[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    public domSanitizer: DomSanitizer
  ) {
    this.showMoreData = data.modifiedExperienceData;
  }

  ngOnInit() {}
  getSafeVideoUrl(videoUrl: string): SafeResourceUrl {
    const videoId = this.extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
  extractVideoId(url: string): string {
    const regex = /[?&]v=([^?&]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });
      console.error('No se pudo extraer el ID del video de YouTube desde la URL:', url);
      return '';
    }
  }
}
