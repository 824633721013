<div class="program-card">
  <a class="clickable-section anchor-nostyle" (click)="onMultipleClicksHandles({ event: $event })">
    <div class="card-program-header">
      <div class="header-program-name">
        <div [matTooltip]="program.nombre" matTooltipPosition="above" class="program-title">
          {{ program.nombre }}
        </div>
        <div class="card-university">
          <a class="anchor-nostyle university-anchor program-name-color"
            (click)="onMultipleClicksHandles({ event: $event })">
            At {{ program.universidad?.nombre }}
          </a>
        </div>
      </div>
      <button (click)="onMultipleClicksHandles({ event: $event })" aria-label="add or remove from favourites"
        class="favourite-button" [ngClass]="
        program.inFavourites === true
          ? 'favourite-button-active'
          : 'favourite-button-inactive'
      "></button>

    </div>

    <div>
      <div class="card-label">
        <div class="card-location" (click)="onMultipleClicksHandles({ event: $event })">
          <img class="card-country-logo" [src]="program.universidad.ciudad.pais.icono" alt="country logo" />
          <p (click)="onMultipleClicksHandles({ event: $event })" class="location-name">
            {{ program.nombreCiudad }},
            {{ program.provinciaUniversidad }}
          </p>
        </div>
        <div class="card-location">
          <img class="card-country-logo" src="assets/utils/file.png" alt="duration" />
          <p>{{ program.acreditacionShown }}: {{ program.stdLength }}</p>
        </div>
      </div>
      <div class="card-label">
        <div class="card-location">
          <img class="card-country-logo" src="assets/utils/dollar.png" alt="fee" />
          <div class="location-name">
            <div class="program-subtitle size-names">
              {{ "Programs-list.tuition-cost-card" | translate }}:
            </div>
            <div class="program-subtitle font-text" *ngIf="
                program.yearTuition !== undefined &&
                program.yearTuition !== null
              ">
              {{ program.yearTuition | number }}
              {{ program.universidad.ciudad.pais.moneda }} /
              {{ "Programs-list.year" | translate }}
            </div>
            <div class="program-subtitle font-text" *ngIf="
                (program.yearTuition === undefined ||
                  program.yearTuition === null) &&
                program.programTuition !== undefined &&
                program.programTuition !== null
              ">
              {{ program.programTuition | number }}
              {{ program.universidad.ciudad.pais.moneda }} /
              {{ "Programs-list.complete" | translate }}
            </div>
            <div class="program-subtitle font-text" *ngIf="
                (program.yearTuition === undefined ||
                  program.yearTuition === null) &&
                (program.programTuition === undefined ||
                  program.programTuition === null) &&
                program.semesterTution !== undefined &&
                program.semesterTution !== null
              ">
              {{ program.semesterTution | number }}
              {{ program.universidad.ciudad.pais.moneda }} /
              {{ "Programs-list.semester" | translate }}
            </div>
            <div class="program-subtitle font-text" *ngIf="
                (program.yearTuition === undefined ||
                  program.yearTuition === null) &&
                (program.programTuition === undefined ||
                  program.programTuition === null) &&
                (program.semesterTution === undefined ||
                  program.semesterTution === null)
              ">
              ND
            </div>
          </div>
        </div>
        <div class="card-location">
          <img class="card-country-logo" src="assets/utils/money.png" alt="application" />
          <div class="program-subtitle size-names">
            {{ "Programs-list.application-cost-card" | translate }}:
          </div>
          <div class="program-subtitle font-text" *ngIf="program.universidad.applicationFee">
            {{ program.universidad.applicationFee | number }}
            {{ program.universidad.ciudad.pais.moneda }}
          </div>
          <div class="program-subtitle font-text" *ngIf="!program.universidad.applicationFee">
            ND
          </div>
        </div>
      </div>
    </div>
  </a>
  <div class="row">
    <div class="col-xl-7">
      <div class="container-buttons">
        <div class="text-center">
          <button *ngIf="!loading" (click)="onApplyClick()" id="apply-button">
            {{ "Programs-list.step-by-step" | translate }}
          </button>
          <mat-progress-spinner *ngIf="loading" class="apply-spinner" mode="indeterminate" diameter="35">
          </mat-progress-spinner>
        </div>
        <div class="text-center">
          <a *ngIf="showRequisitesButton && !program.universidad?.brochure" [routerLink]="'../' + program.slug"
            fragment="requirements-accordion">
            <!--Requisitos-->
            <button class="requisites-button">
              {{ "Programs-list.requisites-card" | translate }}
            </button>
          </a>
          <!--Folleto-->
          <button *ngIf="showRequisitesButton && program.universidad?.brochure" class="requisites-button"
            (click)="onViewRequires(program.universidad.brochure)">
            {{ "Programs-list.brochure" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="col-xl-5 afinity-probability-container">
      <label class="value-afinity-probability" style="margin-right: 20.54px;">
        <strong class="afinity-probability">Afinidad: </strong>{{classificationAfinity || 'No definida'}}</label>
      <label class="value-afinity-probability"><strong class="afinity-probability">Probabilidad:
        </strong>{{program.universidad.Probabilidad}}</label>
    </div>
  </div>

</div>
