import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../public/services/api.service';
import { ExperienceModalComponent } from '../experienceModalComponent/experienceModalComponent';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ExperienceComponent } from '../experiences/experience.component';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExperienceRecordModalComponent } from '../experiencesRecord/experienceRecordModal/experiencesRecordModal.component';

@Component({
  selector: 'app-experience-approval',
  templateUrl: './experienceApprovalComponent.html',
  styleUrls: ['./experienceApprovalComponet.scss'],
})
export class ExperienceApprovalComponent implements OnInit, AfterViewInit {
  @ViewChild(ExperienceModalComponent)
  private experienceModalComponent: ExperienceModalComponent;
  @ViewChild(ExperienceComponent, { static: true })
  private experienceComponent: ExperienceComponent;
  formList: any[] = [];
  formListData: any[] = [];
  linksData: any[] = [];
  approve: boolean = false;
  isModalOpen = true;
  supportResponse: any[] = [];
  userId: any;
  modalRef: NgbModalRef;
  private id: string;
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}

  closeTab() {
    const windowObj: any = window;
    if (windowObj) {
      windowObj.close();
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      this.apiService.get({ api: `api/experiences/${this.id}` }).subscribe(
        (response) => {
          this.formListData = response;
          this.editExperienceRecorded(this.formListData);
        },
        (error) => {
          console.error('Error al recuperar los datos:', error);
          Swal.fire({
            icon: 'error',
            title: '¡Oops!',
            text: 'Ocurrió un error al recuperar la información. Por favor, inténtalo nuevamente más tarde.',
          }).then(() => {
            this.closeTab();
          });
        }
      );
      this.apiService.get({ api: `api/experienceSupports/${this.id}` }).subscribe(
        (supportResponse) => {
          this.supportResponse = supportResponse;
        },
        (error) => {
          console.error('Error al recuperar los respaldos de experiencia:', error);
          Swal.fire({
            icon: 'error',
            title: '¡Oops!',
            text: 'Ocurrió un error al recuperar los respaldos de experiencia. Por favor, inténtalo nuevamente más tarde.',
          }).then(() => {
            location.reload();
          });
        }
      );
    });
  }
  async editExperienceRecorded(experienceData: any): Promise<void> {
    const supportsLinks = await this.getSupportsLinks(experienceData._id);
    experienceData.supportsLinks = supportsLinks;
    experienceData.isEdit = true;
    experienceData.isApproval = true;
    const dialogRef = this.dialog.open(ExperienceRecordModalComponent, {
      width: '50vw',
      height: '95vh',
      disableClose: true,
      data: { experienceData },
    });
  }

  getSupportsLinks(experienceID): Promise<any> {
    return new Promise((resolve, reject) => {
      const linkData = {
        api: `api/experienceSupports/${experienceID}`,
      };

      this.apiService.get(linkData).subscribe(
        (response) => {
          const linksData = [];

          for (const item of response) {
            linksData.push(item.experienceSupports);
          }

          resolve({ linksData });
        },
        (error) => {
          console.error('Error retrieving the data:', error);
          reject(error);
        }
      );
    });
  }
  ngAfterViewInit() {
    Swal.fire({
      title: 'Cargando...',
      timerProgressBar: true,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      customClass: {
        container: 'custom-swal',
      },
    });
    setTimeout(() => {
      Swal.close();
      this.openModal();
      this.experienceModalComponent.parentID = this.id;
      this.experienceModalComponent.supportResponse = this.supportResponse;
    }, 4000);
  }

  openModal() {
    const content = this.experienceModalComponent.getContent();
    this.modalRef = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    this.experienceModalComponent.parentID = this.id;
    this.experienceModalComponent.supportResponse = this.supportResponse;
    this.experienceModalComponent.approvalResponse();
    this.experienceModalComponent.setModalRef(this.modalRef);
    this.experienceModalComponent.setStudentValue(false);
    this.isModalOpen = true;
  }
}
