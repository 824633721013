import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  /** Subtotal value  */
  @Input() subtotal: number;
  /** TAX value */
  @Input() tax: number;
  /** Total */
  @Input() total: number;
  /** Function to emit the checkout event  */
  @Output() toCheckout = new EventEmitter<void>();
  /** Holds the value of the current page status */
  @Input() pageStatus: number;
  constructor(
    public AuthService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Handles the click on the continue button
   */
  onContinueClick(): void {
    this.AuthService.sitioMensaje = '';
    this.toCheckout.emit();
  }

}
