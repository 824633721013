import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from './modal.service';
import { NavigationService } from '../../services/navigation.service';
import { concatMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { from } from 'rxjs';

@Component({
  selector: 'ngx-bulk-students',
  templateUrl: './bulkStudents.component.html',
  styleUrls: ['./bulkStudents.component.scss'],
})
export class BulkStudents implements OnInit {
  @ViewChild('fileInputExcel', { static: false }) fileInputExcel: ElementRef;
  readonly API_REGISTER_USER = 'users/registrar-estudiante';
  readonly collectionCodigos = 'api/saveListCode/';
  readonly GENERATEST = 'api/crearTestUser';
  readonly getStudents = 'api/allUsers';
  readonly getSchoolsData = 'api/colegio';
  file: File;
  allColegios: any;
  schoolsList: any;
  dataList: any;
  listCode: any;
  matchingSchool: any;
  studentsToCompare: any;
  data: any;
  loading: boolean;
  selectedFileName: string;
  duplicates: boolean;
  duplicateUsers: any;
  isModalOpen = false;
  user = JSON.parse(localStorage.getItem('user'));
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private navigationService: NavigationService
  ) {
    this.modalService.openModal$.subscribe(() => {
      this.isModalOpen = true;
    });
  }

  ngOnInit(): void {
    this.fetchColegiosData();
    this.getAllStudents();
  }
  getAllStudents() {
    this.apiService.get({ api: this.getStudents }).subscribe((response) => {
      if (response && !response.errors) {
        this.studentsToCompare = response;
      }
    });
  }
  fetchColegiosData(): void {
    this.apiService.get({ api: this.getSchoolsData }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.matchingSchool = response.allSchoolData.find((school) => school.idAdmin === this.user._id);
        } else {
          this.displayErrorAlert();
        }
      },
      (err) => {
        this.displayErrorAlert();
      }
    );
  }

  onFileChange(event: any): void {
    this.file = event.target.files[0];
    this.selectedFileName = this.file.name;
  }

  onOpenFileInputExcel() {
    let event = new MouseEvent('click', { bubbles: false });
    this.fileInputExcel.nativeElement.dispatchEvent(event);
    this.fileInputExcel.nativeElement.value = '';
  }

  convertToJson(): void {
    if (this.file) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El código ingresado no se encuentra o no esta asignado al email especificado',
      });
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const excelSerialToDate = (serial: number): Date => {
          const utcDays = Math.floor(serial - 25569);
          const utcValue = utcDays * 86400;
          const date = new Date(utcValue * 1000);
          return date;
        };

        const students = XLSX.utils
          .sheet_to_json(ws, {
            header: 1,
            range: 1,
          })
          .map((row) => {
            const birthDate = excelSerialToDate(row[7]);
            return {
              primerNombre: row[0],
              segundoNombre: row[1],
              primerApellido: row[2],
              segundoApellido: row[3],
              grade: row[4].toString(),
              gradeLevel: row[5],
              email: row[6],
              birthDay: birthDate,
              password: row[8],
              school: this.matchingSchool._id,
              procedenciaComoLead: this.generateSchoolUniqueCode(),
              tipo: 'Estudiante',
            };
          });
        this.saveUser(students);
        const duplicateStudents = students.filter((student) =>
          this.studentsToCompare.some((compareStudent) => compareStudent.email === student.email)
        );
        Swal.close();

        if (duplicateStudents.length > 0) {
        } else {
        }
      };

      reader.onerror = (error) => {
        console.error('Error al leer el archivo:', error);
        Swal.close();
      };

      reader.readAsBinaryString(this.file);
    } else {
      console.error('Seleccione un archivo antes de intentar convertirlo.');
    }
  }

  saveUser(users: any[]): void {
    if (!users || users.length === 0) {
      console.error('No hay usuarios para guardar.');
      return;
    }

    const uniqueUsers = users.filter(
      (user) => !this.studentsToCompare.some((compareStudent) => compareStudent.email === user.email)
    );

    this.duplicateUsers = users.filter((user) =>
      this.studentsToCompare.some((compareStudent) => compareStudent.email === user.email)
    );

    const uniqueUsers$ = from(uniqueUsers);

    uniqueUsers$
      .pipe(
        concatMap((user) => {
          return this.apiService.post({ api: this.API_REGISTER_USER, data: user }).pipe(
            catchError((error) => {
              console.error('Error in POST request:', error);
              Swal.fire({
                title: 'Error!',
                text: 'Error guardando los estudiantes.',
                icon: 'error',
              });
              return throwError(error);
            })
          );
        })
      )
      .subscribe(
        (userResponse) => {
          const userId = userResponse.user._id;
          const schoolId = userResponse.user.school;
          const birthDay = userResponse.user.birthDay;
          const grade = userResponse.user.grade;

          this.data = {
            usuario: userId,
            colegio: schoolId,
            birthDay: birthDay,
            grade: grade,
            semestreActivo: '',
            nombreColegio: '',
            nivelAcademico: '',
            disciplina: '',
            hojaTest: 0,
            codigo: 0,
            liderazgo: 0,
            gobal_mind: 0,
            impacto_social: 0,
            logro_accion: 0,
            puntaje_general: 0,
            competencia_academica: 0,
            isFirstTest: true,
            test: [],
          };

          this.apiService
            .post({
              api: this.GENERATEST,
              data: this.data,
            })
            .subscribe(
              (response) => {
                this.alertService.showSuccess({ msg: 'Estudiante guardado correctamente' });
              },
              (error) => {
                console.error('Error en la solicitud POST:', error);
                this.alertService.showError({
                  msg: 'Hubo un problema guardande el estudiante',
                });
              }
            );

          const uniqueCodeData = {
            emailUsuario: userResponse.user.email,
            idColegio: userResponse.user.school,
            codigo: userResponse.user.procedenciaComoLead,
          };
          this.apiService
            .post({
              api: this.collectionCodigos,
              data: uniqueCodeData,
            })
            .subscribe(
              (response) => {
                this.alertService.showSuccess({ msg: 'Codigo creado' });
              },
              (error) => {
                console.error('API Error:', error);
              }
            );

          Swal.fire({
            title: 'Cambios guardados!',
            text: 'Se guardaron los estudiantes correctamente',
            icon: 'success',
          }).then(() => {
            this.navigationService.navigateTo({ path: '/myStudents' });
          });
        },
        (error) => {
          console.error('Error in saveUser:', error);
          Swal.fire({
            title: 'Error!',
            text: 'Error guardando los estudiantes.',
            icon: 'error',
          });
        },
        () => {
          this.duplicates = true;
          this.loading = false;
        }
      );
  }

  generateSchoolUniqueCode(): string {
    if (!this.user._id) {
    }

    if (this.matchingSchool) {
      const codigo = this.matchingSchool.primerNombre + '-' + this.generateUniqueCode(5);
      this.listCode = {
        idColegio: this.user._id,
        codigo: codigo,
      };
      return codigo;
    } else {
    }
  }
  downloadTemplate(): void {
    const header = [
      'Primer Nombre',
      'Segundo Nombre',
      'Primer Apellido',
      'Segundo Apellido',
      'Grado',
      'Nivel',
      'Email',
      'Fecha de Nacimiento (YYYY-MM-DD)',
      'Contraseña',
    ];

    const data = [['', '', '', '', '', '', '', '', '']];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Plantilla');

    const fileName = 'template.xlsx';
    XLSX.writeFile(wb, fileName);
  }

  generateUniqueCode(num: number): string {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split('');
    let result = '';
    if (num > characters.length) return '';
    for (let i = 0; i < num; i++) {
      let randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.splice(randomIndex, 1)[0];
    }
    return result;
  }

  displayErrorAlert(): void {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Hubo un problema al recuperar los colegios. Por favor, inténtelo de nuevo más tarde.',
    });
  }
}
