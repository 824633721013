import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ServiceDetailModalComponent } from '../../marketplace/service-detail-modal/service-detail-modal.component';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-recruitment-modal',
  templateUrl: './recruitment-modal.component.html',
  styleUrls: ['./recruitment-modal.component.scss']
})
export class RecruitmentModalComponent implements OnInit {

  /** CONSTANTS */
  /** API path for retrieving students by recruitment partner */
  readonly STUDENT_BY_RECRUITMENT_API = 'api/user/recruitment/';
  /** API path for applying to a program */
  readonly APPLICATION_API = 'api/aplicacion/';
  /** Constant that holds the api for the user */
  readonly USER_API = 'api/user/';
  /** Constant that holds the api for favorites */
  readonly FAVORITE_API_POST = 'api/favorito/post-favoritos';
  readonly FAVORITE_API_DELETE = 'api/favorito/remove-favoritos/';

  /** Variable that holds the student for which the action is going to be executed */
  selectedStudent = '';
  /** Variable that holds the list of students of the recruitment partners */
  students = [];
  /** variable that indicates if the button is loading */
  loading = false;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ServiceDetailModalComponent>,
              private navigationService: NavigationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.getStudents();
  }
  /** Retrieves the list of students of the recruitment partner from the backend */
  getStudents(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const id = user._id;
    this.apiService.get({api: this.STUDENT_BY_RECRUITMENT_API + id}).subscribe((response) => {
      this.students = response;
    }, err => {
      this.alertService.showError({msg: 'There was an error loading the information, please try again later or contact support'});
    }, (complete?) => {

    });
  }

  /**
   * Closes self
   */
   public onClose() {
    this.dialogRef.close();
  }

  /**
   * Executes the action for which the modal was displayed
   */
  onExecuteAction() {
    switch(this.data.recruitmentAction) {
      case 'apply':
        this.applyToProgram();
        break;
      case 'favourites':
        this.addToFavourites();
        break;
    }
  }

  /** Applies to a program in the backend */
  applyToProgram() {
      this.loading = true;
      const today = new Date();
      const data = {
        programa: this.data.programId,
        estudiante: this.selectedStudent,
        fechaAplicacion: today,
      };
      this.apiService.post({api: this.APPLICATION_API, data}).subscribe((response) => {
        if (response.success === true) {
          const applicationId = response.result._id;
          this.navigationService.navigateTo({path: 'application/' + applicationId});
          this.onClose();
        } else {
          this.alertService.showError({msg: response.msg});
        }
      }, err => {
        this.alertService.showError({msg:
          this.translate.instant("Home.there-already-have")});
      }, (complete?) => {
        this.loading = false;
      });
  }

  /**
   * Adds favourites to the student in the backend
   */
  addToFavourites(): void {
    const indexToModify = this.students.findIndex((student) => {
      return student._id === this.selectedStudent;
    })
    const studentToModify = this.students[indexToModify];
    const studentFavourites = studentToModify.favoritos.map((p) => p._id);
    const programIndex = studentFavourites.indexOf(this.data.programId);
    if (programIndex < 0) {
      // If the program is not in the favourites list, add it to the list
      studentFavourites.push(this.data.programId);
      let data = {
        favoritos: studentFavourites,
      }
      this.updateStudent({data, action: 'Added to favourites', id: this.selectedStudent, allProgram:this.data.allProgram});
      
      //Se ingresa a la tabla de favoritos
      this.data.allProgram.userId=this.selectedStudent
       
      this.apiService.post({ api: this.FAVORITE_API_POST, data:this.data.allProgram }).subscribe(
        (response) => {
           
      })

    } else {
      // Delete it from the list otherwise
      studentFavourites.splice(programIndex, 1);
      let data = {
        favoritos: studentFavourites,
      }
      this.updateStudent({data , action: 'Remove from favourites', id: this.selectedStudent, allProgram:this.data.allProgram});
      // Se elimina de la tabla de favoritos
      this.data.allProgram.userId=this.selectedStudent
      this.apiService.delete({ api: this.FAVORITE_API_DELETE + this.data.allProgram.programId }).subscribe(
        (response) => {
          //  
        });
    }
  }

  /**
   * Updates the data of a student in the backend
   * @param action executed action
   * @data data to update the student
   * @id student id
   */
  updateStudent({action, data, id, allProgram}: {action: string, data: any, id: string, allProgram:any}): void {
     
    this.apiService.put({api: this.USER_API + id, data}).subscribe((response) => {
      this.alertService.showSuccess({msg: action});
      this.onClose();
    }, err => {
       
      this.alertService.showError({msg: this.translate.instant("Home.could-not-add")})
    });
  }

}
