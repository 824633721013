<div class="unlink">
    <div class="max-width">
        <div class="header">
            <label>¿Quieres desvincular la cuenta de Facebook de u360? Todos tus datos serán eliminados.</label>
        </div>
        <div class="info-container">
            <form (ngSubmit)="onSubmit({event: $event.submitter})" [formGroup]="createForm">
                <div class="container-form-label">
                    <label for="email">Correo</label>
                    <input [ngClass]="{'red-border': validField({ field: 'correo'})}" class="form-control" type="text"
                        placeholder="Correo electrónico" type="email" id="email" formControlName="correo">
                    <span *ngIf="validField({ field: 'correo'})" class="invalid-warning">
                        Campo requerido!
                    </span>
                </div>
                <div class="container-form-label">
                    <label for="razon">Razón</label>
                    <textarea [ngClass]="{'red-border': validField({ field: 'pregunta'})}" class="form-control" type="text"
                        placeholder="Razón para desvincular la cuenta" type="razon" id="razon" formControlName="pregunta"></textarea>
                    <span *ngIf="validField({ field: 'pregunta'})" class="invalid-warning">
                        Campo requerido!
                    </span>
                </div>
                <button id="create-form">Enviar</button>
            </form>
        </div>
    </div>
</div>