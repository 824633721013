import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-letter-preview',
  templateUrl: './letter-preview.component.html',
  styleUrls: ['./letter-preview.component.scss']
})
export class LetterPreviewComponent implements OnInit {
  destinatario:any;
  introduccion:any;
  antecendente:any;
  justificacion:any;
  meta:any;
  conclusion:any;
  datoPersonal:any;

  constructor(
    @Inject (MAT_DIALOG_DATA) public letter
  ) { }

  ngOnInit(): void {
    this.setData();
    
  }

  setData(){
    if(this.letter){
      this.destinatario=this.letter.destinatario;
      this.introduccion=this.letter.introduccion;
      this.antecendente=this.letter.antecendente;
      this.justificacion=this.letter.justificacion;
      this.meta=this.letter.meta;
      this.conclusion=this.letter.conclusion;
      this.datoPersonal=this.letter.datoPersonal;
    }
  }
}
