<div class="page-container">
  <p class="pageTitle">Bienvenido a U360</p>
  <p>Por favor complete su perfil para ser contactado por las universidades de acuerdo a su perfil individual.</p>
  <hr class="titleSeparator" />
  <app-form-input [control]="form.get('school')" type="text" placeholder="Colegio"></app-form-input>
  <app-form-input [control]="form.get('schoolCity')" type="text" placeholder="Ciudad de tu colegio"></app-form-input>
  <app-form-input [control]="form.get('currentGrade')" type="text" placeholder="Grado"></app-form-input>

  <app-form-input [control]="form.get('gradeLevel')" type="text" placeholder="Nivel de grado"></app-form-input>

  <app-form-input [control]="form.get('name')" type="text" placeholder="Escribe tu nombre"></app-form-input>

  <app-form-input
    [control]="form.get('secondName')"
    type="text"
    placeholder="Escribe tu segundo nombre"></app-form-input>

  <app-form-input [control]="form.get('lastName')" type="text" placeholder="Escribe tu apellido"></app-form-input>

  <app-form-input
    [control]="form.get('secondLastName')"
    type="text"
    placeholder="Escribe tu segundo apellido"></app-form-input>

  <app-date-input [control]="form.get('birthDate')" label="Fecha de nacimiento"></app-date-input>

  <app-form-dropdown
    [control]="form.get('gender')"
    label="Genero"
    [options]="[
      { value: 'male', label: 'Masculino' },

      { value: 'female', label: 'Femenino' },
      { value: 'male', label: 'Otro' }
    ]"></app-form-dropdown>
</div>
