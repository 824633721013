import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss']
})
export class ViewTransactionComponent implements OnInit {

  estadoTransaccion: string = '';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private dialogRef: MatDialogRef<ViewTransactionComponent>
  ) {}

  ngOnInit(): void {
    if(this.data){
       
      this.estadoTransaccion = this.data.estadoTransaccion;
    }
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
