import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-travel-service-card',
  templateUrl: './travel-service-card.component.html',
  styleUrls: ['./travel-service-card.component.scss']
})
export class TravelServiceCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Id of the service */
  @Input() id: string;
  /** Company that provides the service */
  @Input() provider: string;
  /** Name of the service that is provided */
  @Input() serviceName: string;
  /** Payment plans of the service that is provided */
  @Input() paymentPlans: any[] = [];
  /** Currency in which the service is charged */
  @Input() currency: string;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the description of the service */
  @Input() description: string;
  /** Variable that holds the list of countries of the service */
  @Input() countries: any[];
  /** Variable that holds the file with additional information of the service (can be undefined) */
  @Input() file: string;

  /** Variable that signals if the user is editing a card */
  editing = false;
  /** Variable that holds the input value for editing the number of days*/
  newNumber = 0;

  /** Variable that contains the number of days that the user will purchase */
  numberOfDays = 0;
  /** Variable that contains the total cost of the plan */
  cost = 0;

  constructor(private alertService: AlertService,
              private shoppingCartService: ShoppingCartService,
              private dialog: MatDialog,
              private translate: TranslateService

              ) { }

  ngOnInit(): void {
  }

  /** Decreases the number of days by one if the current number of days is greater than 0 */
  onRemoveDay(): void {
    if (this.numberOfDays > 0) {
      this.numberOfDays --;
    }
    this.calculateCost();
  }

  /** Increases the number of days by one */
  onAddDay(): void {
    this.numberOfDays ++;
    this.calculateCost();
  }

  /** Calculates the plan total cost based on the number of days and the payment plans of the service */
  calculateCost(): void {
    if (this.numberOfDays === 0 && !this.numberOfDays) {
      this.cost = 0;
    } else {
      let fitsInPlan = false;
      for (const plan of this.paymentPlans) {
        if (this.numberOfDays >= plan.diasInferior && this.numberOfDays <= plan.diasSuperior) {
          this.cost = this.numberOfDays * plan.precioDiario;
          fitsInPlan = true;
          break;
        }
      }
      if (!fitsInPlan) {
        this.onRemoveDay();
        this.alertService.showError({msg: this.translate.instant("Travel-service-card.limit-error")});
      }
    }
  }

  /** Adds the travel service to the shopping cart */
  onAddToCart(): void {
    if (this.numberOfDays === 0) {
      this.alertService.showError({msg: this.translate.instant("Travel-service-card.add-cart-error")});
    } else {
      this.shoppingCartService.addMedicalService({
        id: this.id,
        numberOfDays: this.numberOfDays
      });
    }
  }

  /** Handles the click on the additional information icon */
  onAdditionalInfoClick(): void {
    this.dialog.open(ServiceDetailModalComponent, {
      data: {
        service: 'medical',
        name: this.serviceName,
        cost: this.cost,
        currency: this.currency,
        overview: this.description,
        provider: this.provider,
        image: this.image,
        countries: this.countries,
        file: this.file
      }
    });
  }

  /**
  * Selected of the input. Seleted numbers the days.
  * event. Numbers of the days
  */
  onChangeNumberDays(e) {
    let number = e;
    this.numberOfDays = number;
    if( this.numberOfDays &&  this.numberOfDays > 0) {
      this.calculateCost();
    } else {
        this.cost = 0;
    }
  }

  /**
   * Handles the click on the edit button
   */
  onChangeEditing(): void {
    if (this.editing === false) {
      this.editing = true;
    } else {
      this.onSaveNumberOfDays();
    }
  }

  /**
   * Handles the saving of number of pages
   */
  onSaveNumberOfDays(): void {
    if (this.newNumber === 0 && !this.newNumber) {
      this.cost = 0;
      this.numberOfDays = 0;
      this.editing = false;
    } else {
      let fitsInPlan = false;
      for (const plan of this.paymentPlans) {
        if (this.newNumber >= plan.diasInferior && this.newNumber <= plan.diasSuperior) {
          this.cost = this.newNumber * plan.precioDiario;
          this.numberOfDays = this.newNumber;
          fitsInPlan = true;
          break;
        }
      }
      if (!fitsInPlan) {
        this.alertService.showError({msg: this.translate.instant("Travel-service-card.limit-error")});
      } else {
        this.editing = false;
      }
    }
  }

}
