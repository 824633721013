<main class="tyc-main">
  <div class="tyc-container">
    <h1><strong>ACUERDOS DE SERVICIO HEC 2022</strong></h1>
    <p>¡NADIE TE ENTIENDE COMO NOSOTROS!</p>
    <p>¡Sabemos que comenzamos un camino largo y no queremos que nada salga mal!</p>
    <p>En HEC contamos con un GRAN equipo detrás de tu proyecto para llevar con excelencia y mucho amor el proceso de principio a fin.</p>
    <p>¿Quieres conocer el detalle de lo que podemos y no podemos hacer durante este camino? </p>
    <p>Nos encantaría apoyarte en cada detalle del proceso. Sin embargo, no somos expertos en todo. A continuación, encuentras el detalle de acompañamiento y responsabilidades de las dos partes.</p>
    <h2><strong>1. MODELO DE OPERACIÓN: PERSONALIZADO</strong></h2>
    <ul>
      <li>Momento cero / llamada inicial</li>
      <li>Sesión bienvenida</li>
      <li>Estrateguia-guía de visa</li>
      <li>Sesión pre-viaje, post visa</li>
      <li>Sesión despedida</li>
    </ul>
    <p><b>1.1	Momento Cero/llamada inicial:</b> se refiere a la primera semana de operación, luego de ser registrado en HEC y tiene como objetivo darle a conocer cómo será el contacto durante todo el proceso y apertura de chat grupal por whatsapp.</p>
    <p><b>1.2	Sesión bienvenida:</b> tiene como objetivo alinear expectativas, presentar el modelo de servicio y presentar los integrantes de cada equipo.</p>
    <ul>
      <li><b>Presentación grupal:</b> es el momento en que se conocen a los integrantes del equipo que estarán a cargo de su proceso y trayectoria en HEC.</li>
      <li><b>Presentación del servicio:</b> se enseñan los lineamientos de servicio y valor agregado que encontraran en su trayectoria durante el proceso.</li>
      <li><b>Creación paso a paso:</b> se crea el documento base por las dos partes, línea de tiempo del estudiante con fechas durante cada una de las etapas del proceso.</li>
    </ul>
    <p><b>1.3	Estrategia-guía de visa: </b>Este paso importante para tu preparación a la embajada, contamos con un equipo de gran apoyo a los estudiantes guiándolos en requerimientos y documentos que deberán ser recolectados por el estudiante, asesoramiento en formularios y citas.</p>
    <p><b>1.4	Sesión previaje/post visa: </b>Esta sesión se da por medio de una reunión virtual, se indican todos los pasos a seguir una vez se tiene resultado de visa, ejemplo: compra de tiquetes, compra de seguro, alojamiento, registro final de institución.</p>
    <p><b>1.5	Sesión despedida: </b>Este último paso se realiza por medio de reunión virtual junto con el equipo que estuvo acompañado durante todo el proceso, se explica los documentos de salida del país, reglamentos COVID, tips y proceso de llegada a destino.</p>
    <h2><strong>2. COMUNICACION</strong></h2>
    <p><b>2.1. Canales</b></p>
    <p>Nuestra comunicación principal y centralizada se dará por medio del chat grupal. <b>Evitar conversaciones por chat privado para mayor efectividad y respuesta oportuna a las inquietudes.</b></p>
    <p><u>Las llamadas telefónicas</u> también es uno de nuestros canales para requerimientos inmediatos y urgencias, si no se contesta, por favor dejar mensaje <b>#URGENTE</b> en el chat grupal, y se dará respuesta cuánto antes.</p>
    <p><u>Los correos electrónicos</u> serán la fuente principal para documentos junto con el drive personal de cada uno de los estudiantes.</p>
    <p><u>Usamos la plataforma MEET</u> para reuniones importantes como contacto con el cliente</p>
    <p><u>Grupo whats app cerrado informativo</u> donde estarás actualizado de primera mano con noticias, tips y más del destino, relevantes para tu proceso en general.</p>
    <p><b>NOTA:</b><i>sabemos la inmediatez que exige WhatsApp. Sin embargo, debemos tener presente los tiempos de respuesta para no colapsar los procesos y poder asegurarnos de que tu proyecto saldrá de manera exitosa</i></p>
    <p><b>2.1.1. Up-dates semanales:</b> La comunicación con el equipo será de forma constante, sin embargo, continuamente se estará actualizando con un resumen del proceso según la etapa en la que se encuentre por medio del chat grupal. </p>
    <p><b>2.1.2 Reuniones: </b>Las reuniones por meet estarán establecidas para cada una de las etapas importantes del cliente en HEC y de ser necesario por el cliente podrá agendar adicional por medio del calendly del equipo de procesos.</p>
    <p><b>2.1.3 Llamadas de servicio/encuesta: </b>para HEC el servicio es de los puntos más importantes, por esta razón nuestro principal objetivo es prestar una excelente experiencia en cada una de las etapas, el equipo de servicio al cliente estará contactando a los estudiantes para retroalimentación por medio de una encuesta corta al finalizar cada etapa durante el proceso y llamada de seguimiento en general.</p>
    <p><b>2.1.4 Tiempos de respuesta: </b>en HEC contamos con tiempos establecidos de respuesta, recuerda que detrás de cada proceso existe un equipo que está trabajando para llevar con éxito tu proyecto de vida. Sin embargo, no tardaremos más de 12 horas sin dar respuesta a una solicitud.</p>
    <p><b>2.3 Horarios </b></p>
    <p>Nuestro horario de atención es durante días hábiles de:</p>
    <p>lunes a viernes 8 am - 6 pm </p>
    <p>sábados: 9:00 am - 12 m *Únicamente para respuesta de procesos</p>
    <h2><strong>3. RESPONSABILIDADES DEL ESTUDIANTE Y HEC</strong></h2>
    <p><b>3.1 Por el estudiante:</b></p>
    <ul>
      <li>Preparación de documentos personales </li>
      <li>Compromiso y responsabilidad en cada etapa del proceso </li>
      <li>Compra de tiquetes aéreos </li>
      <li>Trámites especiales de transporte y migración con animales </li>
      <li>Búsqueda de alojamiento independiente a las instituciones </li>
      <li>Trámites locales de destino, tales como: licencia de conducir, renta permanente de vivienda, búsqueda de trabajo, entre otros... </li>
      <li>Registro a guarderías o colegios para hijos de estudiantes HEC </li>
      <li>Trámites netamente migratorios </li>
      <li>Elaboración de CVs </li>
      <li>Elaboración de portafolio </li>
      <li>Elaboración de carta de motivación a la institución </li>
      <li>Transporte a la llegada en destino </li>
      <li>Trámites de financiación </li>
      <li>Trámite de Icetex y Colfuturo</li>
    </ul>
    <p><b>3.2 Servicios HEC</b></p>
    <ul>
      <li>Asesor experto para cada uno de los procesos</li>
      <li>Representación directa de la Institución  </li>
      <li>Gestionamos trámites de traducciones con nuestro proveedor </li>
      <li>Actualización de información general </li>
      <li>Trámite de registro e inscripción a instituciones </li>
      <li>Te acompañamos y te enseñamos en vivo a realizar pagos a instituciones o terceros </li>
      <li>Acompañamiento en la compra de seguros médicos aliados </li>
      <li>Asesoría en alojamiento directo con instituciones y casas de familia con nuestros aliados (Airbnb y demás alojamientos no aplica) </li>
      <li>Guía especializada en visas </li>
      <li>Asesoría al momento de salir del país (documentos, requisitos etc..)</li>
      <li>Guía en procesos de llegada al destino</li>
    </ul>
    <span><i>Si necesitas un servicio fuera de nuestro alcance, te podremos guiar para que puedas tener todo lo que requiere tu proyecto personal *cada proyecto es único y requiere servicios específicos</i></span>
    <p><b>3.3 Tiempos de servicios adicionales:</b> los tiempos establecidos por el estudiante y HEC serán de responsabilidad mutua llevarlos a cumplimiento. Para el caso de tramites externos o con terceros, los tiempos establecidos son tentativos y estimados de acuerdo con información y experiencia, pero no dependerán 100% de HEC.</p>
    <p><b>3.4 Imprevistos fuera de alcance y urgencias:</b></p>
    <p>Se considera que existe un impasse cuando:</p>
    <ul>
      <li>Incumplimiento en gestión de documentos del estudiante que entorpecen el proceso  </li>
      <li>Incumplimiento en tiempos por ambas partes </li>
      <li>Visas negadas </li>
      <li>Decisiones negativas de instituciones </li>
      <li>Incumplimiento de servicio acordado por parte del equipo HEC</li>
    </ul>
    <span><i>*Tenemos una tasa del 98% de éxito en procesos.</i></span>
    <h2><strong>4. Conoce al equipo procesos HEC</strong></h2>
    <figure>
      <img src="assets/utils/hec-team.jpg" alt="Organigrama del equipo de procesos HEC">
    </figure>
  </div>
</main>
