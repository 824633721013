import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SchoolStudentsListComponent } from '../school-students-list/school-students-list.component';
import { ApiService } from '../../public/services/api.service';
@Component({
  selector: 'app-my-students',
  templateUrl: './myStudents.component.html',
  styleUrls: ['./myStudents.component.scss'],
})
export class MyStudentsComponent implements OnInit {
  @ViewChild(SchoolStudentsListComponent, { static: false })
  private schoolStudentsListComponent: SchoolStudentsListComponent;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}
}
