<div id="boxBaner" class="profile-section d-flex flex-column">
  <img [src]="fotoBaner" alt="Foto de baner" class="banner-image" for="bannerImage" />
  <div class="div-principal">
    <div id="boxPerfil" class="d-flex justify-content-start div-img-profile">
      <img [src]="fotoPerfil" alt="Foto de perfil" class="profile-photo" />
      <div class="d-flex align-items-center justify-content-center div-profile-photo">
        <label for="fotoImagen" class="mb-0">
          <input
            class="input-file"
            style="display: none"
            type="file"
            id="fotoImagen"
            (change)="onChangeProfileImage({ event: $event })" />
          <img src="../../../../../assets/curriculum/agregar-foto.png" alt="" class="img-edit-profile" />
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center h-100 text-center change-banner">
      <div class="w-100 change-banner-option">
        <label class="title-banner display-mobile-none">Cambiar foto de portada</label>
        <div class="d-flex justify-content-center">
          <label for="bannerImage">
            <input
              class="input-file"
              style="display: none"
              type="file"
              id="bannerImage"
              (change)="uploadBannerImage({ event: $event })" />
            <img src="../../../../../assets/curriculum/agregar-foto.png" alt="" class="img-edit-banner" />
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="link-container">
    <label class="txt mr-2 mb-0" for="copyLink"><b>Link:</b></label>
    <input type="text" id="copyLink" class="blue-border" [(ngModel)]="linked" />
  </div>
</div>
<form [formGroup]="editar" class="edit-form">
  <div class="w-100">
    <div class="mt-5">
      <label class="title">Mi nombre</label>
      <div>
        <input type="text" class="name blue-border" formControlName="nombre" disabled />
      </div>
    </div>

    <div class="mt-3 mt-lx-5">
      <label class="title">Mi información</label>
      <div class="">
        <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
          <label class="txt w-15 mb-0"><b>Colegio:</b></label>
          <input type="text" class="blue-border" formControlName="colegio" />
        </div>
        <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
          <label class="txt w-15 mb-0"><b>Proyecto:</b></label>
          <input type="text" class="blue-border" formControlName="proyecto" />
        </div>
        <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
          <label class="txt w-15 mb-0"><b>Palabras clave:</b></label>
          <input type="text" class="blue-border" formControlName="palabrasClave" />
        </div>
      </div>
    </div>

    <div class="mt-3 mt-lx-5">
      <label class="title">Sobre mi</label>
      <div>
        <textarea class="blue-border txt" cols="90" rows="7" formControlName="sobreMi"></textarea>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">
          Resumen o Perfil Individual: (1 o 2 Párrafos de máximo 120 palabras totales, 600 caracteres)
        </label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Destaca tu proyecto individual y cómo piensas impactar positivamente al mundo.
          </li>
          <li class="txt-style txt-info black">
            Destaca tus habilidades, experiencias relevantes y metas profesionales.
          </li>
          <li class="txt-style txt-info black">
            Usa verbos accionantes: (ej. Liderar, demostrar, analizar, contribuir, diseñar, etc. Usar los tiempos
            verbales.)
          </li>
          <li class="txt-style txt-info black">
            Utiliza un lenguaje claro y conciso para comunicar tu propuesta de valor como candidato a la universidad o a
            un empleador.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="individualProfile"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">
          Enfatizar Logros y Responsabilidades: (Las 5 o máximo 8 más destacadas con fechas, usar bullets.)
        </label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Logros Destacados: Destaca logros cuantificables y resultados positivos que hayas alcanzado en trabajos,
            estudios o extracurriculares anteriores.
          </li>
          <li class="txt-style txt-info black">
            Responsabilidades Relevantes: Asegúrate de que las responsabilidades mencionadas estén alineadas con los
            requisitos de la carrera que estás buscando aplicar.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="achievements"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">Historial Académico: (De más antiguo a más nuevo, usar bullets.)</label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Enumera tus títulos académicos, instituciones educativas y fechas de graduación. Incluye también cualquier
            distinción académica relevante.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="academicRecord"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">Historial Laboral: (si la tienes) (De más antiguo a más nuevo, usar bullets)</label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Enumera tus experiencias laborales anteriores más destacadas, comenzando con la más reciente. Incluye el
            nombre de la empresa o trabajo, tu título, las fechas de empleo y una breve descripción de responsabilidades
            y logros.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="employmentHistory"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">Competencias Relevantes:</label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Destaca las habilidades clave relacionadas con el tipo de institución y carrera que estás buscando. Incluye
            tanto habilidades técnicas como habilidades blandas. Apoyate en la sección de competencias de U360.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="relevantSkills"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>

    <div class="mt-3 mt-lg-5">
      <div class="border-bottom-div">
        <label class="title">
          Certificaciones y Formación Adicional: (Asegurate de poner solamente las más relevantes, incluye la fecha de
          ejecución al final de cada una)
        </label>
      </div>
      <div class="div-list">
        <ul>
          <li class="txt-style txt-info black">
            Certificaciones Relevantes: Enumera las certificaciones profesionales o extracurriculares que poseas y que
            sean pertinentes para el objetivo.
          </li>
          <li class="txt-style txt-info black">
            Cursos Adicionales: Incluye formación adicional o cursos que hayas completado y que sean relevantes para tu
            carrera.
          </li>
        </ul>
      </div>

      <div class="div-angular-editor">
        <angular-editor
          placeholder="Escribe aquí (máx. 125 palabras)"
          formControlName="aditionalFormation"
          [config]="editorConfig"></angular-editor>
      </div>
    </div>
    <div class="mt-3 mt-lx-5">
      <label class="title">Idiomas</label>
      <div>
        <label class="txt">Agrega máximo 4 Idiomas:</label>
      </div>

      <div class="d-flex flex-column flex-lg-row">
        <div class="d-flex justify-content-between w-40">
          <div class="w-45">
            <label class="txt"><b>Idioma</b></label>
            <div class="mb-2">
              <input class="label-border w-100" formControlName="idioma1" type="text" />
            </div>
            <div class="mb-2">
              <input type="text" class="label-border w-100" formControlName="idioma2" />
            </div>
          </div>
          <div class="w-45">
            <label class="txt"><b>Nivel</b></label>
            <div class="mb-2">
              <select class="label-border w-100 h" formControlName="idioma1Nivel">
                <option value="Seleccionar">Seleccionar</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="c1">c1</option>
                <option value="Nativo">Nativo</option>
              </select>
            </div>
            <div class="mb-2">
              <select class="label-border w-100" formControlName="idioma2Nivel">
                <option value="Seleccionar">Seleccionar</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="c1">c1</option>
                <option value="Nativo">Nativo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between w-40 ml-xl-5">
          <div class="w-45">
            <label class="txt"><b>Idioma</b></label>
            <div class="mb-2">
              <input class="label-border w-100" type="text" formControlName="idioma3" />
            </div>
            <div class="mb-2">
              <input class="label-border w-100" type="text" formControlName="idioma4" />
            </div>
          </div>
          <div class="w-45">
            <label class="txt"><b>Nivel</b></label>
            <div class="mb-2">
              <select class="label-border w-100" formControlName="idioma3Nivel">
                <option value="Seleccionar">Seleccionar</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="c1">c1</option>
                <option value="Nativo">Nativo</option>
              </select>
            </div>
            <div class="mb-2">
              <select class="label-border w-100" formControlName="idioma4Nivel">
                <option value="Seleccionar">Seleccionar</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="c1">c1</option>
                <option value="Nativo">Nativo</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mt-lx-5">
      <label class="title">Áreas de interés:</label>
      <div>
        <label class="txt">Seleccione máximo 5 intereses:</label>
      </div>
      <div class="d-flex flex-wrap">
        <label
          class="label-border"
          *ngFor="let area of areas; index as i"
          (click)="paisAreaInteres('area', area.nombre, i)"
          [ngClass]="{ 'btn-background': area.selected }">
          {{ area.nombre }}
        </label>
      </div>
    </div>

    <div class="mt-3 mt-lx-5">
      <label class="title">Países de interés:</label>
      <div>
        <label class="txt">Seleccione máximo 5 países:</label>
      </div>
      <ng-multiselect-dropdown
        class="col-lg-6 col-5 pl-0 pr-0"
        placeholder="Programa"
        [settings]="optionsUniversities"
        [data]="paises"
        (onSelect)="onChangeCountry($event)"
        (onDeSelect)="onDeSelectCountry($event)"
        [(ngModel)]="selectedCountries"
        [ngModelOptions]="{ standalone: true }"></ng-multiselect-dropdown>
    </div>

    <div class="mt-3 mt-lx-5" formArrayName="universities">
      <label class="title">Universidades de interes:</label>
      <div>
        <label class="txt">Seleccione máximo 5 universidades:</label>
      </div>
      <div>
        <ng-multiselect-dropdown
          class="advanced-filter-select"
          placeholder="Seleccionar universidad"
          [settings]="optionsUniversities"
          [data]="dataUniversidades"
          [(ngModel)]="universitiesSelected"
          (onSelect)="onSelect($event)"
          (onDeSelect)="onDeSelect($event)"
          [ngModelOptions]="{ standalone: true }"></ng-multiselect-dropdown>
      </div>
    </div>

    <div class="mt-3 mt-lx-5" formArrayName="logros">
      <label class="title">Logros en intereses:</label>
      <div
        class="d-flex justify-content-center align-items-start align-items-lg-center flex-column flex-lg-row mb-3"
        *ngFor="let logro of logros.controls; index as i"
        [formGroupName]="i">
        <label class="txt w-15 mb-1 mb-lg-0 mr-lg-2"><b>Descripcion:</b></label>
        <input type="text" class="blue-border" formControlName="descripcion" [id]="'desc' + i" />
        <button type="button" (click)="removeLogros({ index: i })" class="delete-button">Borrar logro</button>
      </div>
      <button type="button" (click)="addLogros()" class="add-logro">Agregar logro</button>
    </div>

    <div class="mt-3 mt-lx-5" formArrayName="referencias">
      <label class="title">Referencias:</label>
      <div
        class="justify-content-center mb-3"
        *ngFor="let referencia of referencias.controls; index as i"
        [formGroupName]="i">
        <div>
          <label class="txt w-15"><b>Nombre:</b></label>
          <input type="text" class="blue-border" formControlName="nombre" [id]="'nombre' + i" />
        </div>
        <div>
          <label class="txt w-15 mt-2"><b>Cargo:</b></label>
          <input type="text" class="blue-border" formControlName="cargo" [id]="'cargo' + i" />
        </div>
        <div>
          <label class="txt w-15 mt-2"><b>Nota:</b></label>
          <input type="text" class="blue-border" formControlName="nota" [id]="'nota' + i" />
        </div>
        <div>
          <button type="button" (click)="removeReferencia({ index: i })" class="delete-button mt-3">
            Borrar Referencia
          </button>
        </div>
      </div>
      <button type="button" (click)="addReferencia()" class="add-logro">Agregar referencia</button>
    </div>

    <div class="d-flex justify-content-center mt-2 mb-4">
      <div>
        <button class="button-save h-30" (click)="save()">Guardar cambios</button>
      </div>
      <div>
        <button class="button-cancel h-30" (click)="backToCurriculum()">Cancelar</button>
      </div>
    </div>
  </div>
</form>
