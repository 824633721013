import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-translation-service-card',
  templateUrl: './translation-service-card.component.html',
  styleUrls: ['./translation-service-card.component.scss']
})
export class TranslationServiceCardComponent implements OnInit {

  /** INPUT VARIABLES */
  @Input() id: string;
  /** Company that provides the service */
  @Input() provider: string;
  /** Document type that is translated */
  @Input() docType: string;
  /** Price per page */
  @Input() pricePerPage: number;
  /** Image of the service */
  @Input() image: string;

  /** Currency of the service */
  currency = 'COP';
  /** Variable that signals if the user is editing the number of pages */
  editingPages = false;


  /** Variable that holds the number of pages that the user will translate */
  numberOfPages = 0;
  /** Variable that holds the cost of the translation */
  cost = 0;

  /** Variable that handles the new number input */
  newNumber = 0;

  constructor(private shoppingCartService: ShoppingCartService,
              private alertService: AlertService,
              private translate: TranslateService) { }

  ngOnInit(): void {
  }

  /**
   * Increases the number of pages counter by one
   */
  onAddPage(): void {
    this.numberOfPages ++;
    this.cost = this.numberOfPages * this.pricePerPage;
  }

  /**
   * If the counter of pages is greater than 0 decreases the number of pages by one
   */
  onRemovePage(): void {
    if (this.numberOfPages > 0) {
      this.numberOfPages --;
      this.cost = this.numberOfPages * this.pricePerPage;
    }
  }

  onAddToCart(): void {
    if (this.numberOfPages > 0) {
      this.shoppingCartService.addTranslationService({
        id: this.id,
        numberOfPages: this.numberOfPages,
      });
      this.numberOfPages = 0;
      this.cost = 0;
      this.newNumber = 0;
    } else {
      this.alertService.showError({msg: this.translate.instant("Translation-service-card.add-shop-error")});
    }
  }

  /**
  * Selected of the input.. Seleted number the pages.
  * event. Numbers of the pages
  */
  onChangeNumberPages(event) {
    let number = event;
    if(number && number > 0 ) {
      this.numberOfPages = number
      this.cost = this.numberOfPages * this.pricePerPage;
    } else {
      this.cost = 0
    }
  }

  /**
   * Makes that the input is editable
   */
  onChangeToEditable(): void {
    if (this.editingPages === true) {
      this.onSaveNumberOfPages();
    } else {
      this.editingPages = true;
    }
  }
  /**
   * Saves the number of
   */
  onSaveNumberOfPages(): void {
    if( this.newNumber >= 0 ) {
      this.numberOfPages = this.newNumber;
      this.cost = this.numberOfPages * this.pricePerPage;
      this.editingPages = false;
    } else {
      this.alertService.showError({msg: this.translate.instant("Translation-service-card.limit-error")});
    }
  }


}
