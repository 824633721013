<div class="enrolment-substeps">
  <div class="enrolment-substep" *ngFor="let step of substeps; index as i"
    [ngStyle]="{'opacity': selected !== i? '0.3':'1'}">
    <app-substep-card (click)="onChangeTab({index: i})" [stepName]="step.name" [status]="step.status">
    </app-substep-card>
    <div [ngSwitch]="selected" class="enrolment-data" [ngClass]="selected === i? 'enrolment-data-active':''">
      <div *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="substep0"></ng-container>
      </div>
      <div *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="substep1"></ng-container>
      </div>
      <div *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="substep2"></ng-container>
      </div>
    </div>
  </div>
</div>
<div [ngSwitch]="selected" class="enrolment-content">
  <div *ngSwitchCase="0">
    <ng-container *ngTemplateOutlet="substep0"></ng-container>
  </div>
  <div *ngSwitchCase="1">
    <ng-container *ngTemplateOutlet="substep1"></ng-container>
  </div>
  <div *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="substep2"></ng-container>
  </div>
</div>
<ng-template #substep0>
  <p *ngIf="substeps[0].status !=='completed'">{{ 'Enrolment.your-farewell' | translate }} {{enrolment.sesionDespedida.fechaMaxima | date}}</p>
  <p *ngIf="substeps[0].status ==='completed'">Done</p>
</ng-template>
<ng-template #substep1>
  <p *ngIf="substeps[1].status !=='completed'">{{ 'Enrolment.your-travel' | translate }} {{enrolment.diaViaje.fechaMaxima | date}}</p>
  <p *ngIf="substeps[1].status ==='completed'">Done</p>
</ng-template>
<ng-template #substep2>
  <p *ngIf="substeps[2].status !=='completed'">{{ 'Enrolment.your-course' | translate }} {{enrolment.fechaInicioCurso.fechaMaxima | date}}</p>
  <p *ngIf="substeps[2].status ==='completed'">Done</p>
</ng-template>
