<div class="register-student">
  <h3>Register student </h3>
  <div class="register-form">
    <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
      <div class="row">
        <div *ngIf="registerForm.contains('email')" class="col-sm-6">
            <label class="auth-form-label" for="email"> {{ "Sign-up.email" | translate }} </label> <br>
            <input id="email" formControlName="email" type="email" [placeholder]="'Login.type-email'|translate"
                class="auth-form-input">
            <p class="invalid" *ngIf="email.touched && email.invalid"> {{ "Login.valid-email" | translate }} </p>
        </div>
        <div *ngIf="registerForm.contains('password')" class="col-sm-6">
            <label class="auth-form-label" for="password"> {{ "Sign-up.password" | translate }} </label> <br>
            <input id="password" formControlName="password" type="password" [placeholder]="'Login.password'|translate"
                class="auth-form-input">
            <p class="invalid" *ngIf="password.touched && password.invalid"> {{ "Login.valid-password" | translate
                }} </p>
        </div>
        <div *ngIf="registerForm.contains('nombre')" class="col-sm-6">
            <label class="auth-form-label" for="user-name"> {{ "Sign-up.first-name" | translate }} </label> <br>
            <input id="user-name" formControlName="nombre" type="text" [placeholder]="'Sign-up.first-name'|translate"
                class="auth-form-input">
            <p class="invalid" *ngIf="name.touched && name.invalid"> {{ "Sign-up.invalid-first-name" | translate }}
            </p>
        </div>
        <div *ngIf="registerForm.contains('apellido')" class="col-sm-6">
            <label class="auth-form-label" for="user-last-name"> {{ "Sign-up.last-name" | translate }}</label> <br>
            <input id="user-last-name" formControlName="apellido" type="text" [placeholder]="'Sign-up.last-name'|translate"
                class="auth-form-input">
            <p class="invalid" *ngIf="lastName.touched && lastName.invalid"> {{ "Sign-up.invalid-last-name" |
                translate }} </p>
        </div>
        <div *ngIf="registerForm.contains('telefono')" class="col-sm-6">
            <label class="auth-form-label" for="user-telephone"> {{ "Sign-up.telephone" | translate }}</label> <br>
            <input id="user-telephone" formControlName="telefono" type="text" [placeholder]="'Sign-up.telephone'|translate"
                class="auth-form-input">
            <p class="invalid" *ngIf="telephone.touched && telephone.invalid"> {{ "Sign-up.invalid-telephone" |
                translate }} </p>
        </div>
        <div *ngIf="registerForm.contains('procedenciaComoLead')" class="col-sm-6">
            <label class="auth-form-label" for="user-lead"> {{ "Sign-up.precedence-as-lead" | translate }}</label>
            <br>
            <input id="user-lead" formControlName="procedenciaComoLead" type="text" [placeholder]="'Sign-up.enter-code'|translate"
                class="auth-form-input">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <button [disabled]="registerForm.invalid" id="register-button" type="submit">{{'Register-student.register'|translate}}</button>
          <mat-progress-spinner *ngIf="loading" id="auth-spinner" mode="indeterminate" diameter="40">
          </mat-progress-spinner>
        </div>
      </div>
    </form>
  </div>
</div>
