import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.scss'],
})
export class PrincipalMenuComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService
  ) {}

  actived: string;
  isRecruitment: boolean;
  isPremiumUser: boolean;
  isActiveMenuMobile: boolean;
  isColegio: boolean;
  isStudent: boolean;
  showMisExperiencias: boolean = false;
  userId: any;
  isConoceteVisible: boolean = false;
  showDashboard: boolean = false;
  showExplore: boolean = false;
  showMatch: boolean = false;

  @Input() displayMenu = false;
  @Input() escapeMenu = false;
  @Output() newEventEmiter = new EventEmitter<boolean>();
  @Output() isActiveMenuMobileEmitter = new EventEmitter<boolean>();
  @ViewChild('boxSidebarMenu') boxSidebarMenu: ElementRef;

  menuOptions: any = {
    match: false,
    dashboard: false,
    cv: false,
    search: false,
    favourites: false,
    applications: false,
    documents: false,
    blog: false,
    marketplace: false,
    testIA: false,
    englishTest: false,
    institutionType: false,
    coverLetter: false,
    experiencesExample: false,
    exploreSkills: false,
    experiencesPlanner: false,
    initalResults: false,
    progressionChart: false,
  };

  ngOnInit(): void {
    this.userService.currentUser$.subscribe((user) => {
      if (user) {
        this.checkProfileType(user);
      }
    });
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user._id;
    this.checkUrl();
    this.authService.isPremiumUser$.subscribe((result) => (this.isPremiumUser = result));
    this.activatedRoute.url.subscribe((url) => this.checkUrl(url[0].path));
    this.activeOption('documents');
    this.toggleConocete();
  }

  activeOption(option: string): void {
    for (const key in this.menuOptions) {
      this.menuOptions[key] = key === option;
    }

    if (option === 'experiences') {
      this.menuOptions.cv = !this.showMisExperiencias;
    } else if (option === 'cv') {
      this.menuOptions.experiences = false;
    }

    if (
      option !== 'cv' &&
      this.showMisExperiencias &&
      option !== 'experiences' &&
      option !== 'coverLetter' &&
      option !== 'experiencesPlanner'
    ) {
      this.showMisExperiencias = false;
    }
    if (option !== 'documents' && option !== 'testIA' && option !== 'englishTest') {
      this.isConoceteVisible = false;
    }
    if (option !== 'dashboard' && option !== 'initalResults' && option !== 'progressionChart') {
      this.showDashboard = false;
    }
    if (option !== 'institutionType' && option !== 'experiencesExample' && option !== 'exploreSkills') {
      this.showExplore = false;
    }
    if (option !== 'search' && option !== 'favourites') {
      this.showMatch = false;
    }
    this.actived = this.menuOptions[option] ? option : '';
  }

  @HostListener('click')
  clickout(): void {
    this.displayMenu = false;
  }

  onGoToProfile(): void {
    this.navigationService.navigateTo({ path: 'profile' });
  }

  checkUrl(urlActived?: string): void {
    const url = urlActived || document.location.pathname;

    if (url === '/web/home' || url === '/web/program/search') {
      this.actived = 'search';
    } else if (url === '/web/preTest') {
      this.actived = 'match';
    } else if (url === '/web/wishlist') {
      this.actived = 'favourites';
    } else if (url === '/web/skill-dashboard') {
      this.actived = 'dashboard';
    } else if (url === '/web/profile') {
      this.actived = 'documents';
    } else if (url === '/web/my-applications') {
      this.actived = 'applications';
    } else if (url === '/web/blog') {
      this.actived = 'blog';
    } else if (url === '/web/curriculum' || url === '/web/letter') {
      this.actived = 'cv';
    } else if (url === '/web/dashboard-school') {
      this.actived = 'dashboard-school';
    } else if (url === '/web/students') {
      this.actived = 'students';
    } else if (url === `/web/experience-record/${this.userId}`) {
      this.actived = 'experiences';
    } else if (url === '/web/leaderTest') {
      this.actived = 'testIA';
    } else if (url === 'web/english-test') {
      this.actived = 'englishTest';
    } else if (url === 'web/universityType') {
      this.actived = 'institutionType';
    } else if (url === 'web/explore-skills') {
      this.actived = 'exploreSkills';
    } else if (url === 'web/coverLetter') {
      this.actived = 'coverLetter';
    } else if (url === 'web/experience-example') {
      this.actived = 'experiencesExample';
    } else if (url === 'web/experience-planner') {
      this.actived = 'experiencesPlanner';
    } else if (url === 'web/current-test') {
      this.actived = 'initalResults';
    } else if (url === 'web/progression') {
      this.actived = 'progressionChart';
    }
  }

  checkProfileType(user): void {
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isStudent = user?.tipo === 'Estudiante';
  }

  onToogleMenuMobile(): void {
    this.isActiveMenuMobile = !this.isActiveMenuMobile;
    this.isActiveMenuMobileEmitter.emit(this.isActiveMenuMobile);
  }

  toggleConocete(): void {
    this.isConoceteVisible = !this.isConoceteVisible;
  }
  toggleDashboard(): void {
    this.showDashboard = !this.showDashboard;
  }
  toggleExplore(): void {
    this.showExplore = !this.showExplore;
  }
  toggleMisExperiencias(): void {
    this.showMisExperiencias = !this.showMisExperiencias;
  }
  toggleMatch(): void {
    this.showMatch = !this.showMatch;
  }
}
