import { RecruitmentModalComponent } from './../../shared/recruitment-modal/recruitment-modal.component';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { AlertService } from '../../services/alert.service';
import { CityComponent } from '../../city/city.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PlatformId } from 'aws-sdk/clients/signer';
import { isPlatformBrowser } from '@angular/common';
import { ViewApplyComponent } from '../../shared/view-apply/view-apply.component';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
})
export class ProgramCardComponent implements OnInit, OnDestroy {
  /** CONSTANTS */
  /** Costant that holds the api for applications */
  readonly APPLICATION_API = 'api/aplicacion/';
  /** Constant that holds the api for the user */
  readonly USER_API = 'api/user/';
  /** Constant that holds the api for favorites */
  readonly FAVORITE_API_POST = 'api/favorito/post-favoritos';
  readonly FAVORITE_API_DELETE = 'api/favorito/remove-favoritos/';

  /** VARIABLES */
  /** Variable that holds the program data that is going to be displayed in the card */
  @Input() program: any;
  /** Variable that shows if the user is logged in */
  loggedIn = false;
  /** Variable an apply action was executed and requires loading */
  loading = false;
  /** Variable that holds the is logged in subscription */
  logInSubscription: Subscription;
  /** Variable that shows if the requisites button should be displayed or not */
  @Input() showRequisitesButton: Boolean;

  @Input() userTest: any;

  classificationAfinity: any;
  afinity: any;
  probability: any;

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.logInSubscription = this.authService.loggedIn.subscribe((logged) => {
        this.loggedIn = logged;
      });
    }
    this.addProbabilityAfinity();
  }

  ngOnDestroy(): void {
    if (this.logInSubscription) {
      this.logInSubscription.unsubscribe();
    }
  }
  /**
   * Handles the click on the Apply button
   */
  onApplyClick(): void {
    this.dialog.open(ViewApplyComponent, { data: { program: this.program } });
  }
  /**
   * Check sesion
   * Open url program.universidad.brochure
   *
   * @param {*} open URL to open
   * @memberof ProgramCardComponent
   */
  public onViewRequires(open) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user && !this.loggedIn) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
      return;
    }
    window.open(open);
  }
  /** Handles multiple clicks */
  onMultipleClicksHandles({ event }: { event: any }): void {
    event.stopPropagation();
    if (event.target.classList.contains('favourite-button')) {
      this.onAddToFavourites();
    } else if (
      event.target.classList.contains('university-anchor') ||
      event.target.classList.contains('university-logo')
    ) {
      this.onClickUniversity();
    } else if (event.target.classList.contains('location-name')) {
      this.onClickCity();
    } else {
      this.onCardClick();
    }
  }
  /** Handles the click on the requisites button */
  onRequisitesClick(): void {
    this.navigationService.navigateTo({
      path: 'program/' + this.program.slug + '#requirements',
    });
  }
  /** Opens city modal information */
  onClickCity(): void {
    this.dialog.open(CityComponent, {
      data: {
        cityId: this.program.universidad.ciudad._id,
      },
    });
  }
  /**
   * Handles the click on the university name -> Navigates university detail
   */
  onClickUniversity(): void {
    this.navigationService.navigateTo({
      path: 'university/' + this.program.universidad.slug,
    });
  }
  /**
   * Handles the click on the card --> Navigates to program detail
   */
  onCardClick(): void {
    let slug = this.program.slug;
    slug = slug.replaceAll('\n', '%0D%0A');
    this.navigationService.navigateTo({
      path: 'program/' + slug,
    });
  }
  /**
   * Handles the click on the add to favourites button
   */
  onAddToFavourites(): void {
    let userRole;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null) {
      userRole = user.tipo;
    }
    if (this.loggedIn && userRole && userRole !== 'Recruitment') {
      const user = JSON.parse(localStorage.getItem('user'));
      const data = {
        programa: this.program._id,
        id: user._id,
      };
      //Data para favoritos
      let startDate = '';
      for (const start of this.program.starts) {
        switch (start) {
          case 1:
            startDate += 'Winter, ';
            break;
          case 2:
            startDate += 'Spring, ';
            break;
          case 3:
            startDate += 'Summer, ';
            break;
          case 4:
            startDate += 'Fall, ';
            break;
        }
      }
      let tuitionByPeriod;
      let period;
      if (this.program.yearTuition !== undefined && this.program.yearTuition !== null) {
        tuitionByPeriod = this.program.yearTuition;
        period = 'Year';
      } else {
        tuitionByPeriod = this.program.programTuition;
        period = 'Full program';
      }
      const dataTableFavorite = {
        userId: user._id,
        university: this.program.universidad.nombre,
        city: this.program.nombreCiudad,
        programa: this.program.nombre,
        programType: this.program.acreditacionShown,
        length: this.program.length,
        start: startDate,
        applicationFee: this.program.universidad.applicationFee,
        tuition: tuitionByPeriod + ' ' + this.program.universidad.ciudad.pais.moneda + '/' + period,
        slug: this.program.slug,
        programId: this.program._id,
        comments: ' ',
      };
      if (this.program.inFavourites === true) {
        // If already is in favourites, remove from favourites
        this.apiService.post({ api: this.USER_API + 'remove-favourite', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: this.translate.instant('Alerts.removed-favourite') });
            // Update favourites list in the local storage to keep the information consistent
            let favoritos = JSON.parse(localStorage.getItem('Favoritos'));
            favoritos = favoritos.filter((pId) => pId !== data.programa);
            localStorage.setItem('Favoritos', JSON.stringify(favoritos));
            this.program.inFavourites = false;
          },
          (err) => {
            this.alertService.showError({
              msg: this.translate.instant('Programs-list.could-not-remove'),
            });
          }
        );
        // Se elimina de la tabla de favoritos
        this.apiService.delete({ api: this.FAVORITE_API_DELETE + this.program.id }).subscribe((response) => {});
      } else {
        // If not, add to favourites
        this.apiService.post({ api: this.USER_API + 'post-favourite', data }).subscribe(
          (response) => {
            this.alertService.showSuccess({ msg: this.translate.instant('Alerts.added-favourite') });
            // Update favourites list in the local storage to keep the information consistent
            const favoritos = JSON.parse(localStorage.getItem('Favoritos'));
            favoritos.push(data.programa);
            localStorage.setItem('Favoritos', JSON.stringify(favoritos));
            this.program.inFavourites = true;
          },
          (err) => {
            this.alertService.showError({ msg: this.translate.instant('Programs-list.could-not-add') });
          }
        );
        //Se ingresa a la tabla de favoritos
        this.apiService.post({ api: this.FAVORITE_API_POST, data: dataTableFavorite }).subscribe((response) => {});
      }
    } else if (this.loggedIn && userRole && userRole === 'Recruitment') {
      let startDate = '';
      for (const start of this.program.starts) {
        switch (start) {
          case 0:
            startDate += 'Winter, ';
            break;
          case 1:
            startDate += 'Spring, ';
            break;
          case 2:
            startDate += 'Summer, ';
            break;
          case 3:
            startDate += 'Fall, ';
            break;
        }
      }
      //Data para favoritos
      let tuitionByPeriod;
      let period;
      if (this.program.yearTuition !== undefined && this.program.yearTuition !== null) {
        tuitionByPeriod = this.program.yearTuition;
        period = 'Year';
      } else {
        tuitionByPeriod = this.program.programTuition;
        period = 'Full program';
      }
      const dataTableFavorite = {
        userId: '',
        university: this.program.universidad.nombre,
        city: this.program.nombreCiudad,
        programa: this.program.nombre,
        programType: this.program.acreditacionShown,
        length: this.program.length,
        start: startDate,
        applicationFee: this.program.universidad.applicationFee,
        tuition: tuitionByPeriod + ' ' + this.program.universidad.ciudad.pais.moneda + '/' + period,
        slug: this.program.slug,
        programId: this.program._id,
        comments: '',
      };
      this.dialog.open(RecruitmentModalComponent, {
        data: { recruitmentAction: 'favourites', programId: this.program._id, allProgram: dataTableFavorite },
      });
    } else {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    }
  }
  addProbabilityAfinity() {
    if (this.program && this.userTest) {
      // puntaje general is comming as a number not a percentage i.e: 80 instead of 0.8
      // promedio ajuste does come as percentage i.e 0.8, so we need to divide puntajeGeneral / 100
      const puntajeGeneral = parseFloat(this.userTest.puntaje_general) / 100;
      const promedioAjuste = parseFloat(this.program.universidad?.PromAjuste);

      if (puntajeGeneral > 0) {
        this.afinity = (
          Math.abs(1 - (Math.max(puntajeGeneral, promedioAjuste) - Math.min(puntajeGeneral, promedioAjuste))) * 100
        ).toFixed(2);

        switch (true) {
          case this.afinity >= 0 && this.afinity <= 70:
            this.classificationAfinity = 'Muy baja';
            break;
          case this.afinity > 70 && this.afinity <= 80:
            this.classificationAfinity = 'Baja';
            break;
          case this.afinity > 80 && this.afinity <= 90:
            this.classificationAfinity = 'Media';
            break;
          case this.afinity > 90 && this.afinity <= 95:
            this.classificationAfinity = 'Alta';
            break;
          case this.afinity > 95 && this.afinity <= 100:
            this.classificationAfinity = 'Muy alta';
            break;
          default:
            this.classificationAfinity = 'No definida';
        }

        if (puntajeGeneral !== 0) {
          this.classificationAfinity += ' (' + this.afinity + ')%';
        }
      } else {
        this.classificationAfinity = 'No definida';
      }
    }
  }
}
