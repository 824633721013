import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { NavigationService } from '../services/navigation.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'ngx-school-type',
  templateUrl: './coverLetter.component.html',
  styleUrls: ['./coverLetter.component.scss'],
})
export class CoverLetterComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  api = 'api/hoja-vida/getById/';
  project: string;
  constructor(
    private navigationService: NavigationService,
    private alertService: AlertService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.getUserCv({ data: this.user._id });
  }
  goToLetterReady() {
    this.navigationService.navigateTo({ path: 'letter-ready' });
  }

  getUserCv({ data }): void {
    this.apiService.get({ api: this.api + data }).subscribe((response) => {
      this.project = response.proyecto;
    });
  }
  goToEdit() {
    this.navigationService.navigateTo({ path: 'letter' });
  }
}
