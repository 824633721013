<div class="program-detail">
  <div class="general-container">
    <div class="program-header">
      <img class="back-button" src="assets/utils/detail-arrow.png" (click)="onBackButtonClick()" />
      <h2 class="university-title">Volver</h2>
    </div>

    <!-- grid one -->
    <div class="grid">
      <!-- INFORMACION PROGRAMA -->
      <div class="card container-card-inf-program">
        <div class="container-name-program">
          <div class="program-info-header">
            <h2 class="program-name-h2">{{ program.nombre | titlecase }}</h2>
            <img
              class="logo-university"
              *ngIf="program?.universidad?.logo"
              [src]="program.universidad.logo"
              [alt]="'Logo de ' + program.universidad.nombre" />
          </div>
          <div class="program-info">
            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/campus-college.png" alt="College name" />
                <div (click)="onClickUniversity()" id="university-name" class="name-city">
                  {{ program?.universidad.nombre }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/campus.png" alt="Campus name" />
                <div class="name-campus">
                  {{ program?.universidad?.campus }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/certificado.png" alt="program's duration and type" />
                <div class="name-campus">{{ program.acreditacionShown }} - {{ program.stdLength }}</div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img [src]="program.universidad?.ciudad?.pais?.icono" alt="country and city" />
                <div class="name-city">
                  {{ program.universidad.ciudad?.nombre }},
                  {{ program.provinciaUniversidad }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/tipo-programa.png" alt="program's duration and type" />
                <div class="name-campus">
                  Tipo: {{ (program.universidad.tipo | lowercase) == 'public' ? 'Pública' : 'Privada' }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/programa-blanco.png" alt="program graduation work permit" />
                <div class="name-campus">Post-Graduation Work Permit: {{ program.universidad.ciudad?.nombre }}</div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/international.png" alt="program graduation work permit" />
                <div class="name-campus">
                  Programas para internacionales: {{ program.universidad.internacionales || 0 }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/matricula.png" alt="program graduation work permit" />
                <div class="name-campus">
                  Matrícula anual promedio:
                  {{
                    (program.yearTuition | number) + ' ' + program.universidad.ciudad.pais.moneda + '/Año' ||
                      'No definida'
                  }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/temporada.png" alt="program graduation work permit" />
                <div class="name-campus">
                  <span>Temporada:</span>
                  <span *ngFor="let st of availableStarts; index as i">
                    {{ index < availableStarts.length ? st + ', ' : st }}
                  </span>
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/trabajar-mientras-estudia.png" alt="program graduation work permit" />
                <div class="name-campus">
                  Trabajar mientras estudia; {{ program.universidad.workStudying ? 'Sí' : 'No' }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/costo-vida.png" alt="program graduation work permit" />
                <div class="name-campus">
                  Costo de vida
                  {{
                    (program.universidad?.livingCost | number) +
                      ' ' +
                      program.universidad?.ciudad?.pais?.moneda +
                      '/Mes' || 'No definido'
                  }}
                </div>
              </div>
            </div>

            <div class="program-field">
              <div class="container-places">
                <img src="assets/utils/costo-aplicacion.png" alt="program graduation work permit" />
                <div class="name-campus">
                  Costo de aplicación:
                  {{
                    (program.universidad.applicationFee | number) + ' ' + program.universidad.ciudad.pais.moneda ||
                      'No definido'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-buttons">
          <button class="btn-view-prog" (click)="onApplyClick()">Paso a paso</button>
          <button
            class="btn-view-webi"
            *ngIf="program?.universidad?.brochure"
            (click)="onViewRequires(program.universidad.brochure)">
            Folleto
          </button>
        </div>
      </div>

      <div class="card container-card-inf-program-dudas" *ngIf="program.universidad?.videosResuelve?.length > 0">
        <app-list-videos-qa [university]="program.universidad"></app-list-videos-qa>
      </div>
      <div
        *ngIf="(program.overview || program.overviewIngles) && showMore"
        class="card container-card-inf-program-about">
        <div class="container-about">
          <h3 class="program-name-h3">Acerca del programa</h3>
          <p
            class="about-reduced"
            *ngIf="(program.overview || program.overviewIngles) && !showMore"
            [innerHTML]="program.overview || program.overviewIngles | truncate : [438]"></p>
          <p class="about-big" [innerHTML]="program.overview || program.overviewIngles"></p>
        </div>
        <button
          class="view-more"
          (click)="changeShowMore()"
          *ngIf="(program.overview || program.overviewIngles) && !showMore">
          Ver más
        </button>
        <button
          class="view-more"
          (click)="changeShowMore()"
          *ngIf="(program.overview || program.overviewIngles) && showMore">
          Ver menos
        </button>
      </div>

      <div class="card container-card-inf-program-videosFotos">
        <app-videos-photos [university]="program.universidad"></app-videos-photos>
      </div>
    </div>
  </div>

  <!-- ADMISION INF -->
  <div class="requirements-container" id="requirements-accordion">
    <div class="title-information">
      <img src="assets/home/images/birrete-rojo.png" alt="red line" />
      <h3>Requisitos de admisión</h3>
    </div>
    <div class="requirements-info">
      <div class="requirements">
        <h6 *ngIf="requirements">Requerimientos generales</h6>
        <div [innerHTML]="requirements" *ngIf="requirements"></div>
        <br *ngIf="requirements" />
        <h6 *ngIf="languageRequirements">Requerimientos de idioma</h6>
        <div [innerHTML]="languageRequirements" *ngIf="languageRequirements"></div>
        <br *ngIf="languageRequirements" />
        <h6 *ngIf="gradeRequirements">Requerimientos de notas</h6>
        <div [innerHTML]="gradeRequirements" *ngIf="gradeRequirements"></div>
        <br *ngIf="gradeRequirements" />
      </div>
      <!-- <div class="container-sections">
        <div class="img-text">
          <div class="grid-list">
            <p
              *ngIf="program.requirements"
              [innerHTML]="program.requirements"
            ></p>
            <p
              *ngIf="program.gradeRequirements"
              [innerHTML]="program.gradeRequirements"
            ></p>
            <p
              *ngIf="program.languageRequirements"
              [innerHTML]="program.languageRequirements"
            ></p>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="general-container">
    <div class="grid">
      <div id="card student-section-ref">
        <!-- FORM CARD -->
        <div class="container-form-card">
          <form (ngSubmit)="onSubmit({ event: $event.submitter })" [formGroup]="createForm">
            <div class="container-representative">
              <h3>Contacta un representante de la institución</h3>
              <p>
                <br />
                <span>¿Quieres contactarte con {{ program.universidad.nombre | titlecase }}?</span>
                Déjanos tus datos y un representante de u360 te contactará para agendar una cita siempre que cumplas los
                requisitos.
              </p>
            </div>
            <iframe
              loading="lazy"
              src="https://share.hsforms.com/1gFaF8f84Q52-hmEkF1fowQch3zo"
              style="border: 0px #ffffff none"
              frameborder="1"
              marginheight="0px"
              marginwidth="0px"
              height="800px"
              width="100%"
              allowfullscreen></iframe>
          </form>
        </div>
      </div>
      <div>
        <div class="card container-card-inf-program-about">
          <app-ubication [university]="program.universidad"></app-ubication>
        </div>
      </div>
    </div>
  </div>

  <!-- More information
  <app-more-information
    [university]="program.universidad"
  ></app-more-information> -->

  <!-- Programs -->
  <app-similar-programs [program]="program"></app-similar-programs>

  <!-- Test section -->
  <app-test-section></app-test-section>
</div>
