<div >
  <div class="university-container">
    <div class="container-map">
      <h3 class="card-title">Ubicación</h3>
      <div class="container-text-ubi">
        <label>{{ university.nombre | titlecase }}</label>
        <div class="container-info">
          <img src="assets/utils/ubicacion.png" alt="direction">
          <span>{{ university.direccion || "ND" }}</span>
        </div>
        <div class="container-info">
          <img src="assets/utils/web.png" alt="university page">
          <span>{{ university?.link || "ND" }}</span>
        </div>
      </div>
      <br>
      <div class="container-view-map">
        <div class="map">
          <agm-map [latitude]="university.latitud" [longitude]="university.longitud" [zoom]="14">
            <agm-marker [latitude]="university.latitud" [longitude]="university.longitud" [visible]="true">
            </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
</div>
