<div class="one-time-fee-container">
  <div class="one-time-fee-content"  *ngIf="pageStatus === 0 && subtotal > 0">
    <img class="back-button" src="assets/utils/back.png" (click)="onBackClick()"  alt="go back">
    <h3 class="one-time-fee-header">{{'Payment-to-u360.payment-to-u360' | translate}}</h3>
    <div class="row">
      <div class="col-lg-8">
        <div class="payment-content">
          <div class="payment-content-header">
            <strong>{{'Payment-to-u360.full-access' | translate}}</strong>
          </div>
          <div class="payment-content-body">
            <strong> {{'Payment-to-u360.overview' | translate}} </strong> {{'Payment-to-u360.by-purchasing' | translate}}
          </div>
          <div class="payment-content-body">
            <strong> {{'Payment-to-u360.subtotal' | translate }} </strong> {{subtotal}} COP
          </div>
          <div class="payment-content-body">
            <strong> {{'Payment-to-u360.discount' | translate}} </strong> {{discount}}%
          </div>
          <div class="payment-content-footer">
            <strong>Total:</strong> {{price | number:'1.0-0'}} COP
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="pageStatus === 0 && subtotal > 0" class="make-payment">
      <button (click)="onMakePaymentClick()" [disabled]="price <= 0" id="make-payment-button">{{'Payment-to-u360.make-payment' | translate}}</button>
    </div>
  </div>
  <div class="free-message" *ngIf="pageStatus === 0 && subtotal === 0">
    <img class="back-button" src="assets/utils/back.png" (click)="onBackClick()" alt="go back">
    No debes realizar este pago. Puedes continuar con el flujo de tu aplicación.
  </div>
  <app-payment-info [refCode]="refCode" [subtotal]="subtotal" (startPurchase)="startPurchase($event)" (returnToPrevious)="onReturnFromCheckout()" *ngIf="pageStatus === 1"></app-payment-info>
</div>
