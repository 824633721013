import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgramCardUniService {
  //Lookk if Card Active U is true 
  public activaCardU: boolean = false;

  constructor() { }
}
