import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-translation-services-shopping-card',
  templateUrl: './translation-services-shopping-card.component.html',
  styleUrls: ['./translation-services-shopping-card.component.scss']
})
export class TranslationServicesShoppingCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the service */
  @Input() id: string;
  /** Variable that holds the name of the service */
  @Input() serviceName: string;
  /** Variable that holds the numberOfWeeks that the customer will buy */
  @Input() numberOfPages: number;
  /** variable that holds the image of the service */
  @Input() image: number;
  /** Variable that holds the price per page */
  @Input() pricePerPage: number;
  /** Currency in which translation services are charged */
  @Input() currency: string;
  /** True if the current component is shopping cart component, falseif current component is purchaseHistory */
  @Input() isShoppingCart: boolean;
  /** Function to remove the service from the shopping cart */
  @Output() removeService = new EventEmitter<string>();
  /** Function to emit a change in the number of pages value */
  @Output() changeNumberOfPages = new EventEmitter<any>();
  /** Variable that holds the total cost of adquiring the service  */
  cost = 0;

  constructor() { }

  ngOnInit(): void {
    this.calculateCost();
  }

  /**
   * Calculates the cost of the service
   */
  calculateCost(): void {
    this.cost = this.pricePerPage * this.numberOfPages;
  }

  /** Removes the current service from the shopping cart */
  onRemoveServiceClick(): void {
    this.removeService.emit(this.id);
  }


  /**
   * Increases the number of pages by one
   */
  onAddNumberOfPages(): void {
    this.changeNumberOfPages.emit({id: this.id, newQuantity: this.numberOfPages + 1});
  }

  /** Decreases the number of pages by one */
  onDecreaseNumberOfPages(): void {
    if (this.numberOfPages > 1) {
      this.changeNumberOfPages.emit({id: this.id, newQuantity: this.numberOfPages - 1});
    }
  }

}
