import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';
import { AlertService } from '../../services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { IoTThingsGraph } from 'aws-sdk';
import { MatDialog } from '@angular/material/dialog';
import { AcademicLevelExplanationComponent } from './academic-level-explanation/academic-level-explanation.component';

@Component({
  selector: 'app-academic-interests',
  templateUrl: './academic-interests.component.html',
  styleUrls: ['./academic-interests.component.scss'],
})
export class AcademicInterestsComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the path for countries */
  readonly COUNTRY_API = 'api/pais/';
  /** Variable that holds the path for users */
  readonly USER_API = 'api/user/';
  readonly ONE_USER_API = 'api/oneUser/';

  /** Variable that will hold the academic interest form data */
  academicInterests: FormArray;

  /** Variable that will hold the list of countries that will be displayed in the countries select */
  countries: Array<any> = [];

  /** Variable that holds tyhe id of the current user */
  id: string;

  /** Variable that signals if the submit button is loading */
  loading = false;

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.academicInterests = this.fb.array([]);
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getCountries();
      this.getUserInfo();
    }
  }

  /**
   * Adds a new academic interest into the the academic interests array
   */
  onAddAcademicInterest(): void {
    const interest = this.fb.group({
      disciplina: '',
      nivelDeEstudios: '',
      pais: '',
    });
    this.academicInterests.push(interest);
  }
  /** Deletes the academic interest of the given index from the academic interests list
   * @param index index of the item to delete
   */
  onDeleteAcademicInterest({ index }: { index: number }): void {
    this.academicInterests.removeAt(index);
  }

  /**
   * Retrieves the list of countries from the backend
   */
  getCountries(): void {
    this.apiService.get({ api: this.COUNTRY_API }).subscribe(
      (response) => {
        this.countries = response;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Academic-interests.country-error'),
        });
      }
    );
  }

  /** Retrieves the user information from the backend */
  getUserInfo(): void {
    this.apiService.get({ api: this.ONE_USER_API + this.id }).subscribe(
      (response) => {
        for (const interest of response.interesesAcademicos) {
          const interestForm = this.fb.group({
            disciplina: interest.disciplina,
            pais: interest.pais,
            nivelDeEstudios: interest.nivelDeEstudios,
          });
          this.academicInterests.push(interestForm);
        }
        // If the control array length is less than 1 push an empty form control to encourage the user into filling the form
        if (this.academicInterests.length < 1) {
          const interestForm = this.fb.group({
            disciplina: '',
            pais: '',
            nivelDeEstudios: '',
          });
          this.academicInterests.push(interestForm);
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Academic-interests.user-error'),
        });
      }
    );
  }

  /**
   * Submits the value of the form to the backend and updates the user data
   */
  onSubmit(): void {
    this.loading = true;
    const data = {
      interesesAcademicos: this.academicInterests.value,
    };
    let errorForm;
    for (const register of data.interesesAcademicos) {
      if (register.disciplina.length < 1 || register.nivelDeEstudios.length < 1 || register.pais.length < 1) {
        errorForm = true;
      } else {
        errorForm = false;
      }
    }
    if (errorForm == true) {
      this.alertService.showError({
        msg: 'Por favor llena todos los campos obligatorios',
      });
      this.loading = false;
    } else {
      this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
        (response) => {
          if (response.success === false) {
            this.alertService.showError({
              msg: 'Hubo un error actualizando los datos',
            });
            this.loading = false;
          } else {
            this.alertService.showSuccess({ msg: 'Se han actualizado los datos correctamente' });
            this.loading = false;
            this.navigationService.navigateTo({ path: 'profile' });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: this.translate.instant('Hubo un error actualizando los datos'),
          });
          this.loading = false;
        }
      );
    }
  }

  /**
   * Handles the click on the info icon
   */
  onInfoClick(): void {
    this.dialog.open(AcademicLevelExplanationComponent);
  }
}
