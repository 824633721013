<div class="change">
  <div class="title">
    {{ 'Password-recovery.recover-password' | translate }}
  </div>
  <div class="description">
    {{ 'Password-recovery.type-your-data' | translate }}
  </div>
  <div>
    <div class="email-title">{{ 'Password-recovery.email' | translate }}</div>
    <div class="container-email">
      <input placeholder="Escribe aquí tu correo" type="email" [(ngModel)]="email" />
      <button (click)="onRecuperar()">{{ 'Password-recovery.recover' | translate }}</button>
    </div>
  </div>
</div>
