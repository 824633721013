<div class="apply-sub-steps">
   <!--Step 0-->
   <div class="apply-substep"
   [ngStyle]="{'opacity': selected !== 0?'0.3':'1'}">
      <app-substep-card
      (click)="onSubstepChange({index: 0})"
      [stepName]="subSteps[0].name"
      [status]="subSteps[0].status">
      </app-substep-card>
      <div [ngSwitch]="selected" class="apply-substep-data" [ngClass]="selected === 0?'apply-substep-data-active':''">
         <h4 class="substep-title">{{ "Application-first.application-form-title" | translate }}</h4>
         <!--Form for mobiles-->
         <form>
            <div class="application-form-container">
               <div class="form-group">
                  <label class="form-label" for="app-name-input-mobile">{{ "Application-first.name-form" | translate }}</label>
                  <input
                  id="app-name-input-mobile"
                  name="app-name-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.nombre"
                  placeholder="{{ 'Application-first.type-name-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-lastname-input-mobile">{{ 'Application-first.last-name-form' | translate }}</label>
                  <input
                  id="app-lastname-input-mobile"
                  name="app-lastname-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.apellidos"
                  placeholder="{{ 'Application-first.type-last-name-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-birthdate-input-mobile">{{ 'Application-first.birth-date-form' | translate }}</label>
                  <input
                  id="app-birthdate-input-mobile"
                  name="app-birthdate-input-mobile"
                  class="form-input"
                  type="date"
                  [(ngModel)]="apply.formularioInformacion.formulario.fechaNacimiento"
                  placeholder="{{ 'Application-first.type-birth-date-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-gender-input-mobile">{{ 'Application-first.gender-form' | translate }}</label>
                  <select
                  id="app-gender-input-mobile"
                  name="app-gender-input-mobile"
                  class="form-input"
                  [(ngModel)]="apply.formularioInformacion.formulario.sexo">
                     <option [disabled]="true" [ngValue]="''" [hidden]="true">{{ 'Application-first.gender-form' | translate }}</option>
                     <option value="Femenino">{{ 'Application-first.gender-female-option' | translate }}</option>
                     <option value="Masculino">{{ 'Application-first.gender-male-option' | translate }}</option>
                  </select>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-citizenship-input-mobile">{{ 'Application-first.citizenship-form' | translate }}</label>
                  <input
                  id="app-citizenship-input-mobile"
                  name="app-citizenship-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.ciudadania"
                  placeholder="{{ 'Application-first.type-citizenship-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-city-input-mobile">{{ 'Application-first.city-of-residence-form' | translate }}</label>
                  <input
                  id="app-city-input-mobile"
                  name="app-city-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.ciudadDeResidencia"
                  placeholder="{{ 'Application-first.type-city-of-residence-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-country-input-mobile">{{ 'Application-first.country-of-residence-form' | translate }}</label>
                  <input
                  id="app-country-input-mobile"
                  name="app-country-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.paisDeResidencia"
                  placeholder="{{ 'Application-first.type-country-of-residence-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-address-input-mobile">{{ 'Application-first.address-form' | translate }}</label>
                  <input
                  id="app-address-input-mobile"
                  name="app-address-input-mobile"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.direccion"
                  placeholder="{{ 'Application-first.type-address-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-telephone-input-mobile">{{ 'Application-first.telephone-form' | translate }}</label>
                  <input
                  id="app-telephone-input-mobile"
                  name="app-telephone-input-mobile"
                  class="form-input"
                  type="tel"
                  [(ngModel)]="apply.formularioInformacion.formulario.telefono"
                  placeholder="{{ 'Application-first.type-telephone-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-mail-input-mobile">{{ 'Application-first.email-form' | translate }}</label>
                  <input
                  id="app-mail-input-mobile"
                  name="app-mail-input-mobile"
                  class="form-input"
                  type="email"
                  [(ngModel)]="apply.formularioInformacion.formulario.email"
                  placeholder="{{ 'Application-first.type-email-form' | translate }}"/>
               </div>
               <div class="form-group">
                  <label class="form-label" for="app-degree-input-mobile">{{ 'Application-first.last-degree-form' | translate }}</label>
                  <input
                  id="app-degree-input-mobile"
                  name="app-degree-input"
                  class="form-input"
                  type="text"
                  [(ngModel)]="apply.formularioInformacion.formulario.estudiosRealizados"
                  placeholder="{{ 'Application-first.ej-last-degree-form' | translate }}"/>
               </div>
            </div>
            <div class="languages">
               <h5>{{ 'Application-first.languages-form' | translate }}</h5>
               <p *ngIf="apply.formularioInformacion.formulario.idiomas.length === 0">{{ 'Application-first.there-arent-any-languages' | translate }}</p>
               <div class="language" *ngFor="let language of apply.formularioInformacion.formulario.idiomas; index as i">
                  <h6>{{ 'Application-first.add-language-button' | translate }}</h6>
                  <input class="language-input" [name]="'language-name' + i" [(ngModel)]="language.nombre" type="text" placeholder="{{ 'Application-first.ej-language-name' | translate }}"/>
                  <input class="language-input" [name]="'language-level' + i" [(ngModel)]="language.nivel" type="text" placeholder="{{ 'Application-first.ej-language-level' | translate }}">
                  <mat-icon (click)="onRemoveLanguage({index: i})" class="delete-icon delete-language">{{ 'Application-first.delete-language-button' | translate }}</mat-icon>
                  <h6>{{ 'Application-first.certificates-language' | translate }}</h6>
                  <div class="language-certificate" *ngFor="let certificate of language.certificados; index as j">
                     <input [(ngModel)]="certificate.nombre" class="certificate-input" [name]="'certificate-name' + j" type="text" placeholder="{{ 'Application-first.ej-certificate-name' | translate }}">
                     <input [(ngModel)]="certificate.calificacion" class="certificate-input" [name]="'certificate-grade'+ j" type="text" placeholder="{{ 'Application-first.ej-certificate-grade' | translate }}">
                     <input class="certificate-input" [name]="'certificate-file' + j" type="text" placeholder="{{ 'Application-first.certificate-file' | translate }}">
                     <mat-icon (click)="onRemoveCertificate({langIndex: i, certIndex: j})" class="delete-icon">{{ 'Application-first.delete-language-button' | translate }}</mat-icon>
                  </div>
                  <button id="add-certificate-mobile" (click)="onAddCertificate({index: i})">{{ 'Application-first.add-certificate-button' | translate }}</button>
               </div>
               <button id="add-language-mobile" (click)="onAddLanguageClick()">{{ 'Application-first.add-language-button' | translate }}</button>
            </div>
            <button id="save-form-mobile" (click)="onSaveFormClick()">{{ 'Application-first.save-changes-button' | translate }}</button>
         </form>
      </div>
   </div>
   <!--Step 1-->
   <div class="apply-substep"
   [ngStyle]="{'opacity': selected !== 1?'0.3':'1'}">
      <app-substep-card
      (click)="onSubstepChange({index: 1})"
      [stepName]="subSteps[1].name"
      [status]="subSteps[1].status">
      </app-substep-card>
      <div [ngSwitch]="selected" class="apply-substep-data" [ngClass]="selected === 1?'apply-substep-data-active':''">
         <ng-container *ngTemplateOutlet="substep1"></ng-container>
      </div>
   </div>
   <!-- Step 2-->
   <div class="apply-substep"
   [ngStyle]="{'opacity': selected !== 2?'0.3':'1'}">
      <app-substep-card
      (click)="onSubstepChange({index: 2})"
      [stepName]="subSteps[2].name"
      [status]="subSteps[2].status">
      </app-substep-card>
      <div [ngSwitch]="selected" class="apply-substep-data" [ngClass]="selected === 2?'apply-substep-data-active':''">
         <ng-container *ngTemplateOutlet="substep2"></ng-container>
      </div>
   </div>
   <!-- Step 3-->
   <div class="apply-substep"
   [ngStyle]="{'opacity': selected !== 3?'0.3':'1'}">
      <app-substep-card
      (click)="onSubstepChange({index: 3})"
      [stepName]="subSteps[3].name"
      [status]="subSteps[3].status">
      </app-substep-card>
      <div [ngSwitch]="selected" class="apply-substep-data" [ngClass]="selected === 3?'apply-substep-data-active':''">
         <ng-container *ngTemplateOutlet="substep2"></ng-container>
      </div>
   </div>
</div>

<!--Content-->
<div [ngSwitch]="selected" class="apply-content">
   <div *ngSwitchCase="0">
      <ng-container *ngTemplateOutlet="substep0"></ng-container>
   </div>
   <div *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="substep1"></ng-container>
   </div>
   <div *ngSwitchCase="2">
      <ng-container *ngTemplateOutlet="substep2"></ng-container>
   </div>
   <div *ngSwitchCase="3">
      <ng-container *ngTemplateOutlet="substep3"></ng-container>
   </div>
</div>

<!--Substep templates-->
<!-- Template for step 0-->
<ng-template #substep0>
   <!--Form for desktop-->
   <h4 class="substep-title">{{ "Application-first.application-form-title" | translate }}</h4>
   <p>Por favor diligencia el formulario para comenzar tu proceso interno:<br><br>
      - <strong>Asegúrate que todos los datos estén correctos ya que de esto dependerá continuar correctamente con el proceso.</strong><br>
      - <strong>No podremos continuar si no tenemos todos los datos.</strong><br><br>
      <strong>Recuerda:</strong> la información que nos brindes es la información que se usará durante todo el proceso.
   </p>
   <form #generalInfoForm="ngForm">
      <div class="application-form-container">
         <div class="form-group">
            <label class="form-label" for="app-name-input">{{ "Application-first.name-form" | translate }}</label>
            <input
            id="app-name-input"
            name="app-name-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.nombre"
            placeholder="{{ 'Application-first.type-name-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-lastname-input">{{ 'Application-first.last-name-form' | translate }}</label>
            <input
            id="app-lastname-input"
            name="app-lastname-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.apellidos"
            placeholder="{{ 'Application-first.type-last-name-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-birthdate-input">{{ 'Application-first.birth-date-form' | translate }}</label>
            <input
            id="app-birthdate-input"
            name="app-birthdate-input"
            class="form-input"
            type="date"
            [(ngModel)]="apply.formularioInformacion.formulario.fechaNacimiento"
            placeholder="{{ 'Application-first.type-birth-date-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-gender-input">{{ 'Application-first.gender-form' | translate }}</label>
            <select
            id="app-gender-input"
            name="app-gender-input"
            class="form-input"
            [(ngModel)]="apply.formularioInformacion.formulario.sexo">
               <option [disabled]="true" [ngValue]="''" [hidden]="true">{{ 'Application-first.gender-form' | translate }}</option>
               <option value="Femenino">{{ 'Application-first.gender-female-option' | translate }}</option>
               <option value="Masculino">{{ 'Application-first.gender-male-option' | translate }}</option>
            </select>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-citizenship-input">{{ 'Application-first.citizenship-form' | translate }}</label>
            <input
            id="app-citizenship-input"
            name="app-citizenship-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.ciudadania"
            placeholder="{{ 'Application-first.type-citizenship-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-city-input">{{ 'Application-first.city-of-residence-form' | translate }}</label>
            <input
            id="app-city-input"
            name="app-city-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.ciudadDeResidencia"
            placeholder="{{ 'Application-first.type-city-of-residence-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-country-input">{{ 'Application-first.country-of-residence-form' | translate }}</label>
            <input
            id="app-country-input"
            name="app-country-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.paisDeResidencia"
            placeholder="{{ 'Application-first.type-country-of-residence-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-address-input">{{ 'Application-first.address-form' | translate }}</label>
            <input
            id="app-address-input"
            name="app-address-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.direccion"
            placeholder="{{ 'Application-first.type-address-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-telephone-input">{{ 'Application-first.telephone-form' | translate }}</label>
            <input
            id="app-telephone-input"
            name="app-telephone-input"
            class="form-input"
            type="tel"
            [(ngModel)]="apply.formularioInformacion.formulario.telefono"
            placeholder="{{ 'Application-first.type-telephone-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-mail-input">{{ 'Application-first.email-form' | translate }}</label>
            <input
            id="app-mail-input"
            name="app-mail-input"
            class="form-input"
            type="email"
            [(ngModel)]="apply.formularioInformacion.formulario.email"
            placeholder="{{ 'Application-first.type-email-form' | translate }}"/>
         </div>
         <div class="form-group">
            <label class="form-label" for="app-degree-input">{{ 'Application-first.last-degree-form' | translate }}</label>
            <input
            id="app-degree-input"
            name="app-degree-input"
            class="form-input"
            type="text"
            [(ngModel)]="apply.formularioInformacion.formulario.estudiosRealizados"
            placeholder="{{ 'Application-first.ej-last-degree-form' | translate }}"/>
         </div>
      </div>
      <div class="languages">
         <h5>{{ 'Application-first.languages-form' | translate }}</h5>
         <p *ngIf="apply.formularioInformacion.formulario.idiomas.length === 0">{{ 'Application-first.there-arent-any-languages' | translate }}</p>
         <div class="language" *ngFor="let language of apply.formularioInformacion.formulario.idiomas; index as i">
            <h6>{{ 'Application-first.add-language-button' | translate }}</h6>
            <input class="language-input" [name]="'language-name' + i" [(ngModel)]="language.nombre" type="text" placeholder="{{ 'Application-first.ej-language-name' | translate }}"/>
            <input class="language-input" [name]="'language-level' + i" [(ngModel)]="language.nivel" type="text" placeholder="{{ 'Application-first.ej-language-level' | translate }}">
            <mat-icon (click)="onRemoveLanguage({index: i})" class="delete-icon delete-language">{{ 'Application-first.delete-language-button' | translate }}</mat-icon>
            <h6>{{ 'Application-first.certificates-language' | translate }}</h6>
            <div class="language-certificate" *ngFor="let certificate of language.certificados; index as j">
               <input [(ngModel)]="certificate.nombre" class="certificate-input" [name]="'certificate-name' + j" type="text" placeholder="{{ 'Application-first.ej-certificate-name' | translate }}">
               <input [(ngModel)]="certificate.calificacion" class="certificate-input" [name]="'certificate-grade'+ j" type="text" placeholder="{{ 'Application-first.ej-certificate-grade' | translate }}">
               <label class="certificate-input-label" [for]="'certificate-file' + i + j"> {{ 'Application-first.certificate-file' | translate }} </label>
               <input (change)="onUploadLanguageCertificate({event: $event, langIndex: i, certIndex: j})" class="certificate-file-input" [id]="'certificate-file' + i + j" [name]="'certificate-file' + i + j" type="file">
               <a target="_blank" [href]="certificate.archivo" *ngIf="certificate.archivo !== '' && certificate.archivo !== undefined">{{ 'Application-first.view-certificate' | translate }}</a>
               <mat-icon (click)="onRemoveCertificate({langIndex: i, certIndex: j})" class="delete-icon">{{ 'Application-first.delete-language-button' | translate }}</mat-icon>
            </div>
            <button id="add-certificate" (click)="onAddCertificate({index: i})">{{ 'Application-first.add-certificate-button' | translate }}</button>
         </div>
         <button id="add-language" (click)="onAddLanguageClick()">{{ 'Application-first.add-language-button' | translate }}</button>
      </div>
      <button id="save-form" type="submit" (click)="onSaveFormClick()">{{ 'Application-first.save-changes-button' | translate }}</button>
   </form>
</ng-template>

<!--Template for step 1-->
<ng-template #substep1>
   <!--Document upload elements-->
   <p class="documents-text">{{ 'Application-second.upload-documents-must-be-label' | translate }}</p>
   <h6>{{'Application-first.original-documents' | translate}}</h6>
   <div class="main-documents">
      <div class="doc">
         <div class="document-header">
            <h5 class="document-title">{{ 'Application-second.passport-title' | translate }}</h5>
            <div [ngSwitch]="apply.documentos.pasaporteAprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="apply.documentos.motivoRechazoPasaporte" matTooltipPosition="above" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="apply.documentos.pasaporte !== '' && apply.documentos.pasaporte !== null && apply.documentos.pasaporte !== undefined;else notUploadedYet"
         [href]="apply.documentos.pasaporte"
         target="_blank">{{ 'Application-second.view-passport-title' | translate }}</a>
         <mat-icon
         *ngIf="apply.documentos.pasaporte !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label for="passport-input" class="upload-document">{{ 'Application-second.upload-passport-button' | translate }}</label>
         <input (change)="onUploadChange({event: $event, nombreDoc: 'passport'})" id="passport-input" type="file"/>
      </div>
      <div class="doc">
         <div class="document-header">
            <h5 class="document-title">{{ 'Application-second.school-diploma-title' | translate }}</h5>
            <div [ngSwitch]="apply.documentos.diplomaAprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="apply.documentos.motivoRechazoDiploma" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="apply.documentos.diploma !== '' && apply.documentos.diploma !== null && apply.documentos.diploma !== undefined ;else notUploadedYet"
         [href]="apply.documentos.diploma"
         target="_blank">{{ 'Application-second.view-school-diploma-title' | translate }}</a>
         <mat-icon
         *ngIf="apply.documentos.diploma !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label for="school-diploma-input" class="upload-document">{{ 'Application-second.upload-school-diploma-button' | translate }}</label>
         <input (change)="onUploadChange({event: $event, nombreDoc: 'school-diploma'})" id="school-diploma-input" type=file>
      </div>
      <div class="doc">
         <div class="document-header">
            <h5 class="document-title">{{ 'Application-second.school-grades-certificate' | translate }}</h5>
            <div [ngSwitch]="apply.documentos.notasAprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="apply.documentos.motivoRechazoNotas" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="apply.documentos.notas !== '' && apply.documentos.notas !== null && apply.documentos.notas !== undefined;else notUploadedYet"
         [href]="apply.documentos.notas"
         target="_blank">{{ 'Application-second.view-school-grades-certificate' | translate }}</a>
         <mat-icon
         *ngIf="apply.documentos.notas !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label for="school-grades-input" class="upload-document">{{ 'Application-second.upload-school-grades-button' | translate }}</label>
         <input (change)="onUploadChange({event: $event, nombreDoc: 'school-grades'})" id="school-grades-input" type=file>
      </div>
      <div class="doc">
         <div class="document-header">
            <h5 class="document-title">{{ 'Application-second.undergraduate-diploma-title' | translate }}</h5>
            <div [ngSwitch]="apply.documentos.diplomaPregradoAprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="apply.documentos.motivoRechazoDiplomaPregrado" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="apply.documentos.diplomaPregrado !== '' &&
         apply.documentos.diplomaPregrado !== null &&
         apply.documentos.diplomaPregrado !== undefined;else notUploadedYet"
         [href]="apply.documentos.diplomaPregrado"
         target="_blank">{{ 'Application-second.view-undergraduate-diploma-title' | translate }}</a>
         <mat-icon
         *ngIf="apply.documentos.diplomaPregrado !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label for="undergraduate-diploma-input" class="upload-document">{{ 'Application-second.upload-undergraduate-diploma-button' | translate }}</label>
         <input (change)="onUploadChange({event: $event, nombreDoc: 'undergraduate-diploma'})" id="undergraduate-diploma-input" type=file>
      </div>
      <div class="doc">
         <div class="document-header">
            <h5 class="document-title">{{ 'Application-second.undergraduate-grades-certificate-title' | translate }}</h5>
            <div [ngSwitch]="apply.documentos.notasPregradoAprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="this.apply.documentos.motivoRechazoNotasPregrado" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="apply.documentos.notasPregrado !== ''
         && apply.documentos.notasPregrado !== undefined
         && apply.documentos.notasPregrado !== null;else notUploadedYet"
         [href]="apply.documentos.notasPregrado"
         target="_blank">{{ 'Application-second.view-undergraduate-grades-certificate-title' | translate }}</a>
         <mat-icon
         *ngIf="apply.documentos.notasPregrado !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label for="undergraduate-grades-input" class="upload-document">{{ 'Application-second.upload-undergraduate-grades-certificate-button' | translate }}</label>
         <input (change)="onUploadChange({event: $event, nombreDoc: 'undergraduate-grades'})" id="undergraduate-grades-input" type=file>
      </div>
   </div>
   <h6>{{'Application-first.translated-documents' | translate}}</h6>
   <div class="translated-documents">
      <!-- Translated documents-->
      <div class="doc">
        <div class="document-header">
          <h5 class="document-title">{{'Application-first.translated-diploma'}}</h5>
          <div [ngSwitch]="apply.documentos.diplomaAprobadoTraducido" class="status-logo">
              <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
              <mat-icon [matTooltip]="apply.documentos.motivoRechazoDiplomaTraducido" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
          </div>
        </div>
        <a class="document-link"
        *ngIf="apply.documentos.diplomaTraducido !== '' && apply.documentos.diplomaTraducido !== null && apply.documentos.diplomaTraducido !== undefined ;else notUploadedYet"
        [href]="apply.documentos.diplomaTraducido"
        target="_blank">{{ 'Application-second.view-school-diploma-title' | translate }}</a>
        <mat-icon
        *ngIf="apply.documentos.diplomaTraducido !== ''"
        class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
        <br>
        <label for="translated-school-diploma-input" class="upload-document">{{ 'Application-second.upload-school-diploma-button' | translate }}</label>
        <input (change)="onUploadChange({event: $event, nombreDoc: 'school-diploma-translated'})" id="translated-school-diploma-input" type=file>
    </div>
    <div class="doc">
        <div class="document-header">
          <h5 class="document-title">{{'Application-first.translated-grades' | translate}}</h5>
          <div [ngSwitch]="apply.documentos.notasAprobadoTraducido" class="status-logo">
              <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
              <mat-icon [matTooltip]="apply.documentos.motivoRechazoNotasTraducido" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
          </div>
        </div>
        <a class="document-link"
        *ngIf="apply.documentos.notasTraducido !== '' && apply.documentos.notasTraducido !== null && apply.documentos.notasTraducido !== undefined;else notUploadedYet"
        [href]="apply.documentos.notasTraducido"
        target="_blank">{{ 'Application-second.view-school-grades-certificate' | translate }}</a>
        <mat-icon
        *ngIf="apply.documentos.notasTraducido !== ''"
        class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
        <br>
        <label for="translated-school-grades-input" class="upload-document">{{ 'Application-second.upload-school-grades-button' | translate }}</label>
        <input (change)="onUploadChange({event: $event, nombreDoc: 'school-grades-translated'})" id="translated-school-grades-input" type=file>
    </div>
    <div class="doc">
        <div class="document-header">
          <h5 class="document-title">{{'Application-first.translated-undergraduate-diploma' | translate}}</h5>
          <div [ngSwitch]="apply.documentos.diplomaPregradoAprobadoTraducido" class="status-logo">
              <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
              <mat-icon [matTooltip]="apply.documentos.motivoRechazoNotasPregradoTraducido" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
          </div>
        </div>
        <a class="document-link"
        *ngIf="apply.documentos.diplomaPregradoTraducido !== '' &&
        apply.documentos.diplomaPregradoTraducido !== null &&
        apply.documentos.diplomaPregradoTraducido !== undefined;else notUploadedYet"
        [href]="apply.documentos.diplomaPregradoTraducido"
        target="_blank">{{ 'Application-second.view-undergraduate-diploma-title' | translate }}</a>
        <mat-icon
        *ngIf="apply.documentos.diplomaPregradoTraducido !== ''"
        class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
        <br>
        <label for="translated-undergraduate-diploma-input" class="upload-document">{{ 'Application-second.upload-undergraduate-diploma-button' | translate }}</label>
        <input (change)="onUploadChange({event: $event, nombreDoc: 'undergraduate-diploma-translated'})" id="translated-undergraduate-diploma-input" type=file>
    </div>
    <div class="doc">
        <div class="document-header">
          <h5 class="document-title">{{'Application-first.translated-undergraduate-grades' | translate}}</h5>
          <div [ngSwitch]="apply.documentos.notasPregradoAprobadoTraducido" class="status-logo">
              <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
              <mat-icon [matTooltip]="this.apply.documentos.motivoRechazoNotasPregradoTraducido" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
          </div>
        </div>
        <a class="document-link"
        *ngIf="apply.documentos.notasPregradoTraducido !== ''
        && apply.documentos.notasPregradoTraducido !== undefined
        && apply.documentos.notasPregradoTraducido !== null;else notUploadedYet"
        [href]="apply.documentos.notasPregradoTraducido"
        target="_blank">{{ 'Application-second.view-undergraduate-grades-certificate-title' | translate }}</a>
        <mat-icon
        *ngIf="apply.documentos.notasPregradoTraducido !== ''"
        class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
        <br>
        <label for="translated-undergraduate-grades-input" class="upload-document">{{ 'Application-second.upload-undergraduate-grades-certificate-button' | translate }}</label>
        <input (change)="onUploadChange({event: $event, nombreDoc: 'undergraduate-grades-translated'})" id="translated-undergraduate-grades-input" type=file>
    </div>
   </div>
   <div class="additional-documents">
      <h5 class="document-title">{{ 'Application-second.other-documents-title' | translate }}</h5>
      <div class="additional-doc" *ngFor="let doc of apply.documentos.documentosAdicionales; index as i">
         <div class="document-header">
            <h5 class="document-title">{{doc.nombre}}</h5>
            <div [ngSwitch]="doc.aprobado" class="status-logo">
               <mat-icon class="check-icon" *ngSwitchCase="'Aprobado'">check</mat-icon>
               <mat-icon [matTooltip]="doc.motivoRechazo" [matTooltipPosition]="'above'" class="error-icon" *ngSwitchCase="'Rechazado'">error</mat-icon>
            </div>
         </div>
         <a class="document-link"
         *ngIf="doc.archivo !== ''
         && doc.archivo !== undefined
         && doc.archivo !== null;else notUploadedYet"
         [href]="doc.archivo"
         target="_blank">{{ 'Application-second.view-dinamyc' | translate }} {{doc.nombre}}</a>
         <mat-icon
         *ngIf="doc.archivo !== ''"
         class="delete-icon">{{ 'Application-second.label-delete' | translate }}</mat-icon>
         <br>
         <label [for]="'nombreDoc' + i" class="upload-document">{{ 'Application-second.upload-document-button' | translate }}</label>
         <input (change)="onAdditionalDocumentsUploadChange({event: $event, index: i})" class="document-input" [id]="'nombreDoc' + i" type=file>
      </div>
   </div>
   <div class="translations-ad">
     <p class="translations-ad-text"> {{'Application-second.marketplace-text' | translate}}
       <button routerLink="../../marketplace" class="translations-ad-button"> {{'Application-second.marketplace-button' | translate}}</button>
      </p>
   </div>
</ng-template>
<!--Template for step 2-->
<ng-template #substep2>
  <div *ngIf="apply.feePagado.completado === false">
    <h5 class="fee-payment-title">{{ 'Application-third.application-fee-payment-label' | translate }}</h5>
    <ol>
      <li>{{'Application-third.you-can-contact' | translate}}</li>
      <li>{{'Application-third.this-is-the' | translate}}</li>
    </ol>

    <div *ngIf="applicationFeeConfirmed === true">
      <p class="payment-text"> {{'Application-third.your-application-fee-has' | translate }} {{applicationFee}} {{currency}}: </p>
      <input type="checkbox" [(ngModel)]="apply.feePagado.aceptacionTerminosTerceros" [disabled]="apply.feePagado.aceptacionTerminosTerceros" (ngModelChange)="onTermsAccepted()">
      <span class="payment-checkbox"> {{'Application-third.by-checking' | translate}}</span><br>
      <button (click)="onMakePayment()" id="application-fee-payment-button" [disabled]="!apply.feePagado.aceptacionTerminosTerceros">{{ 'Application-third.make-payment-button' | translate }}</button> {{'Application-third.or' | translate }}
      <label id="payment-proof-label"  for="application-fee-payment-proof" class="upload-document">{{'Application-third.upload-proof-of-payment' | translate}}</label>
      <input  [disabled]="!apply.feePagado.aceptacionTerminosTerceros" (change)="onUploadChange({event: $event, nombreDoc: 'payment-proof'})" id="application-fee-payment-proof" type=file>
      <a id="payment-proof-link" class="document-link"
         *ngIf="apply.feePagado.comprobantePago !== ''
          && apply.feePagado.comprobantePago !== null
          && apply.feePagado.comprobantePago !== undefined;else notUploadedYet"
         [href]="apply.feePagado.comprobantePago"
         target="_blank">{{'Application-third.view-payment-proof' | translate }}</a>
    </div>
    <div *ngIf="applicationFeeConfirmed !== true">
      <p class="not-confirmed-text">{{'Application-third.not-confirmed' | translate}} </p>
    </div>

   <p class="fee-payment-disclaimer"> <strong>{{'Application-third.disclaimer' | translate}}</strong> {{'Application-third.some-institutions' | translate}}</p>
  </div>
  <div *ngIf="apply.feePagado.completado === true">
    <div class="info-text">
      Done
    </div>
    <a id="payment-proof-link" class="document-link"
         *ngIf="apply.feePagado.comprobantePago !== ''
          && apply.feePagado.comprobantePago !== null
          && apply.feePagado.comprobantePago !== undefined"
         [href]="apply.feePagado.comprobantePago"
         target="_blank">{{'Application-third.view-payment-proof' | translate }}</a>
  </div>
</ng-template>
<!-- Template for step 3-->
<ng-template #substep3>
    <p class="info-text" *ngIf="subSteps[3].status !== 'completed'">
      Hasta que no estén los 3 primeros pasos completos, no se podrá aplicar a la institución.<br>
      Una vez hayamos aplicado al programa, debes esperar en los tiempos indicados por tu advisor.<br><br>
      <strong>NOTAS:</strong><br>
      Te alertaremos si la institución requiere algún documento adicional durante la aplicación.<br>
      Algunas instituciones envían información directamente al estudiante. Por reenviar todos los correos a <strong>admisiones@hec-latam.com.</strong><br><br>
      Esperamos tener próximamente noticias positivas.
    </p>
   <p class="info-text" *ngIf="subSteps[3].status === 'completed'">{{ 'Application-fourth.application-sent-label' | translate }}</p>
</ng-template>

<!--General templates-->
<ng-template #notUploadedYet>
   <p class="fallback-text">{{ 'Application-second.document-not-uploaded-text' | translate }}</p>
</ng-template>
