import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  /** Constant that holds the path to the blog API */
  readonly BLOG_API = 'api/blog/';
  /** Constant that handles the number of blogs that are displayed per page */
  readonly BLOGS_PER_PAGE = 6;

  /** List that contains the countries that will be used to filter the blogs */
  countries = [
    {
      nombre: "Blog-detail.all",
      value: 'All',
      selected: true,
    }, {
      nombre: "Blog-detail.canada",
      value: 'Canada',
      selected: false,
    }, {
      nombre: "Blog-detail.united-states",
      value: 'United states',
      selected: false,
    }, {
      nombre: "Blog-detail.united-kingdom",
      value: 'UK',
      selected: false,
    }, {
      nombre: "Blog-detail.germany",
      value: 'Alemania',
      selected: false,
    }, {
      nombre: "Blog-detail.netherlands",
      value: 'Países bajos',
      selected: false,

    }, {
      nombre: this.translate.instant("Blog-detail.spain"),
      value: 'España',
      selected: false,
    }, {
      nombre: this.translate.instant("Blog-detail.others"),
      value: 'Otros',
      selected: false,
    }
  ];

  /** List that contains the list of tags that will be used to filter the blogs */
  tags = [
    {
      tag: "Blog-detail.all-tag",
      value: "All",
      selected: false,
    }, {
      tag: "Blog-detail.high-demand-tag",
      value: "High demand carreers",
      selected: false,
    }, {
      tag: "Blog-detail.lifestyle-tag",
      value: "Lifestyle",
      selected: false,
    }, {
      tag: "Blog-detail.strong-local-tag",
      value: "Strong local industries",
      selected: false,
    }, {
      tag: "Blog-detail.student-processes-tag",
      value: "Student processes",
      selected: false,
    }, {
      tag: "Blog-detail.education-system-tag",
      value: "Education system",
      selected: false,
    }, {
      tag: "Blog-detail.visas-migration-tag",
      value: "Visas and migration",
      selected: false,
    }, {
      tag: "Blog-detail.work-tag",
      value: "Work",
      selected: false,
    }
  ];

  /** List that contains the list of skills that will be used to filter the blogs */
  skills = [
    {
      tag: "Liderazgo",
      value: "liderazgo",
      selected: false,
    },
    {
      tag: "Trabajo y acción",
      value: "trabajo-accion",
      selected: false,
    },
    {
      tag: "Aprendizaje",
      value: "aprendizaje",
      selected: false,
    },
    {
      tag: "Impacto",
      value: "impacto",
      selected: false,
    },
    {
      tag: "Global mind",
      value: "global-mind",
      selected: false,
    }
  ]

  /** Variable tha holds the list of the blogs that are being displayed */
  blogs: any[];

  /** Variable that holds the value of the searchbar  */
  search = '';

  /** Observable that will check the url params to update the page accordingly */
  urlSusbcription: Subscription;

  /** Total number of blogs */
  numberOfBlogs = 0;
  /** Current page number */
  page = 1;

  showGeneralFilters: boolean;
  showCompetenciesFilters: boolean;

  constructor(private apiService: ApiService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.urlSusbcription = this.activatedRoute.queryParamMap.subscribe((urlParams) => {
      const country = urlParams.getAll('pais');
      const tags = urlParams.getAll('tags');
      const skills = urlParams.getAll('competencias')
      const blog = urlParams.get('blog');
      const page = urlParams.get('page');
      if (page !== null && page !== undefined && page !== '') {
        this.page = Number.parseInt(page);
      } else {
        this.page = 1;
      }

      const query = {
        page: this.page,
        limit: this.BLOGS_PER_PAGE,
      };
      if (country !== null && country !== undefined && country.length > 0) {
        query['pais'] = country;
      } else {
        this.countries[0].selected = true;
        query['pais'] = this.countries[0].value;
      }
      if (tags !== null && tags !== undefined && tags.length > 0) {
        query['tags'] = tags;
      } else {
        for (const tag of this.tags) {
          tag.selected = false;
        }
      }
      if (skills !== null && skills !== undefined && skills.length > 0) {
        for (const skill of this.skills) {
          if (skills.includes(skill.value)) {
            skill.selected = true;
          } else {
            skill.selected = false;
          }
        }
      } else {
        for (const skill of this.skills) {
          skill.selected = false;
        }
      }
      if (skills !== null && skills !== undefined && skills.length > 0) {
        query['competencias'] = skills;
      } else {
        for (const skill of this.skills) {
          skill.selected = false;
        }
      }
      if (blog !== null && blog !== undefined) {
        query['titulo'] = blog;
      }
      this.getBlogs({ query });
    });
  }

  onSelectTag({ index }: { index: number }): void {
    const countries = [];
    for (const country of this.countries) {
      if (country.selected) {
        countries.push(country.value);
      }
    }
    this.tags[index].selected = !this.tags[index].selected;
    const queryTags = [];
    for (const tag of this.tags) {
      if (tag.selected) {
        queryTags.push(tag.value);
      }
    }
    this.navigationService.navigateTo({
      path: '/blog',
      params: {
        pais: countries,
        tags: queryTags,
        page: 1
      }
    });
  }

  onSelectSkills({ index }: { index: number }): void {
    const countries = [];
    for (const country of this.countries) {
      if (country.selected) {
        countries.push(country.value);
      }
    }

    this.skills[index].selected = !this.skills[index].selected;
    const querySkills = []
    for (const skill of this.skills) {
      if (skill.selected) {
        querySkills.push(skill.value)
      }
    }

    this.navigationService.navigateTo({
      path: '/blog',
      params: {
        pais: countries,
        competencias: querySkills,
        page: 1
      }
    });


  }

  onSearchByName(): void {
    if (this.search !== '') {
      const countries = [];
      for (const country of this.countries) {
        if (country.selected) {
          countries.push(country.value);
        }
      }
      this.navigationService.navigateTo({
        path: '/blog',
        params: {
          pais: countries,
          blog: this.search,
          page: 1
        }
      });
    }
  }

  /**
   * Variable that retrieves the blogs data from the backend
   * @param query query that is going to be executed in the backend
   */
  getBlogs({ query }: { query: any }): void {
    this.apiService.post({ api: this.BLOG_API + 'search/', data: query }).subscribe((response) => {
      this.numberOfBlogs = response.totalDocs;
      this.blogs = response.docs;
    }, err => {
      this.alertService.showError({ msg: this.translate.instant("Blog-detail.error-load") });
    });
  }

  /** Handles the click on the clear filters button */
  onClearFilters(): void {
    this.navigationService.navigateTo({
      path: '/blog',
      params: {
        page: 1,
      }
    });
  }

  /** Handles page changes on the pagination controls */
  onPageChange(event): void {
    const countries = [];
    for (const country of this.countries) {
      if (country.selected) {
        countries.push(country.value);
      }
    }
    const queryTags = [];
    for (const tag of this.tags) {
      if (tag.selected) {
        queryTags.push(tag.value);
      }
    }
    this.navigationService.navigateTo({
      path: '/blog',
      params: {
        pais: countries,
        tags: queryTags,
        page: event
      }
    });
  }

  /** Handles the selection of a country in the tab bar */
  onSelectCountry({ index }: { index: number }): void {
    this.countries[index].selected = !this.countries[index].selected;
    const countries = [];
    for (const country of this.countries) {
      if (country.selected) {
        countries.push(country.value);
      }
    }

    const queryTags = [];
    for (const tag of this.tags) {
      if (tag.selected) {
        queryTags.push(tag.value);
      }
    }

    this.navigationService.navigateTo({
      path: '/blog',
      params: {
        pais: countries,
        tags: queryTags,
        page: 1
      }
    });
  }
}
