<form
  [ngClass]="{
    'form-signup': authAction === 'signup',
    'form-login': authAction === 'login'
  }"
  id="auth-form"
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-sm-12 tamano" *ngIf="authService.sitioMensaje === 'english_test'">
      <h1 class="welcome-title">Bienvenido al test gratuito de Inglés</h1>
      <p class="parte_1">
        ¿Quieres saber cuántas semanas necesitas para obtener un B2 ó C1 (niveles requeridos para pregrados/posgrados en
        el exterior)?
      </p>
      <p class="parte_1">
        U360 tiene para ti el © Cambridge University Press & Assessment 2021 English Test de manera gratuita.
      </p>
      <p class="negrilla">Importante:</p>
      <p>
        <span class="negrilla">
          <i class="fa fa-check checked-green" aria-hidden="true"></i>
        </span>
        El test es diseñado y avalado por Cambridge.
        <br />
        <span class="negrilla">
          <i class="fa fa-check checked-green" aria-hidden="true"></i>
        </span>
        Podrás conocer tu resultado entre 9 niveles, desde elemental hasta avanzado.
        <br />
        <span class="negrilla">
          <i class="fa fa-check checked-green" aria-hidden="true"></i>
        </span>
        Los resultados son aproximados al resultado de una prueba oficial o nivel de inglés.
        <br />
        <span class="negrilla">
          <i class="fa fa-check checked-green" aria-hidden="true"></i>
        </span>
        Dejando un correo de contacto al final del formulario, conocerás las semanas necesarias aproximadas de curso
        para ingresar a educación superior internacional.
      </p>
    </div>
    <div class="col-sm-12 pt-2">
      <h3 *ngIf="authAction === 'login'">{{ 'Login.login' | translate }}</h3>
      <h3 *ngIf="authAction === 'signup'">
        {{ 'Sign-up.sign-up' | translate }}
      </h3>
    </div>
    <div class="col-sm-12 pt-2">
      <h5 *ngIf="authService.sitioMensaje !== '' && authService.sitioMensaje !== 'english_test'">
        {{ authService.sitioMensaje | translate }}
      </h5>
      <h4 *ngIf="authAction === 'login' && authService.sitioMensaje === ''">
        {{ 'Login.type-credentials' | translate }}
      </h4>
      <h4 *ngIf="authAction === 'signup' && authService.sitioMensaje === ''">
        {{ 'Sign-up.become-part-of-u360' | translate }}
      </h4>
    </div>
  </div>
  <div class="row">
    <div *ngIf="loginForm.contains('nombre')" class="col-sm-6">
      <label class="auth-form-label" for="user-name">
        {{ 'Sign-up.first-name' | translate }}
      </label>
      <br />
      <input
        id="user-name"
        formControlName="nombre"
        type="text"
        [placeholder]="'Sign-up.first-name' | translate"
        class="auth-form-input" />
      <p class="invalid" *ngIf="name.touched && name.invalid">
        {{ 'Sign-up.invalid-first-name' | translate }}
      </p>
    </div>
    <div *ngIf="loginForm.contains('apellido')" class="col-sm-6">
      <label class="auth-form-label" for="user-last-name">
        {{ 'Sign-up.last-name' | translate }}
      </label>
      <br />
      <input
        id="user-last-name"
        formControlName="apellido"
        type="text"
        [placeholder]="'Sign-up.last-name' | translate"
        class="auth-form-input" />
      <p class="invalid" *ngIf="lastName.touched && lastName.invalid">
        {{ 'Sign-up.invalid-last-name' | translate }}
      </p>
    </div>
    <div
      *ngIf="loginForm.contains('email')"
      [ngClass]="{
        'col-sm-6': authAction === 'signup',
        'login-label': authAction === 'login'
      }">
      <label class="auth-form-label" for="email">
        {{ 'Sign-up.email' | translate }}
      </label>
      <br />
      <input
        id="email"
        formControlName="email"
        type="email"
        [placeholder]="'Login.type-email' | translate"
        class="auth-form-input" />
      <p class="invalid" *ngIf="email.touched && email.invalid">
        {{ 'Login.valid-email' | translate }}
      </p>
    </div>
    <div
      *ngIf="loginForm.contains('password')"
      [ngClass]="{
        'col-sm-6': authAction === 'signup',
        'login-label': authAction === 'login'
      }">
      <label class="auth-form-label" for="password">
        {{ 'Sign-up.password' | translate }}
      </label>
      <br />
      <input
        id="password"
        formControlName="password"
        autocomplete="new-password"
        [type]="newConfirmType"
        [placeholder]="'Login.password' | translate"
        class="auth-form-input" />
      <img
        [src]="newConfirmType === 'text' ? eye : eyeSlash"
        class="eye-icon"
        (click)="invertPassType()"
        alt="{{ newConfirmType === 'text' ? 'hide password' : 'show password' }}" />
      <p class="invalid" *ngIf="password.touched && password.invalid">
        {{ 'Login.valid-password' | translate }}.
        <br />
        Por favor ingresa una contraseña con los siguientes formatos:
        <br />
        Minimo 6 caracteres
        <br />
        Minimo 1 carcater en mayuscula
        <br />
        Minimo 1 numero
      </p>
    </div>
    <div *ngIf="loginForm.contains('telefono')" class="col-sm-6">
      <label class="auth-form-label" for="user-telephone">{{ 'Sign-up.telephone' | translate }}</label>
      <br />
      <div class="d-flex flex-row justify-content-around">
        <input
          type="text"
          placeholder="+57"
          aria-label="Number"
          class="auth-form-select"
          matInput
          formControlName="cod"
          [matAutocomplete]="auto" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let country of filteredCountries | async"
            [value]="country.idd?.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')">
            <img src="{{ country.flags.png }}" alt="country flag" />
            {{ country.idd.root ? country.idd.root : '' }}{{ country.idd.suffixes ? country.idd.suffixes[0] : '' }}
            {{ country.name.common ? country.name.common : '' }}
          </mat-option>
        </mat-autocomplete>
        <input
          id="user-telephone"
          formControlName="telefono"
          type="number"
          [placeholder]="'Sign-up.telephone' | translate"
          class="auth-form-input short" />
      </div>
      <p class="invalid" *ngIf="code.touched && code.invalid">
        {{ 'Indicativo requerido' }}
      </p>
      <p class="invalid" *ngIf="telephone.touched && telephone.invalid">
        {{ 'Sign-up.invalid-telephone' | translate }}
      </p>
    </div>
    <div *ngIf="loginForm.contains('procedenciaComoLead')" class="col-sm-6">
      <label class="auth-form-label" for="user-lead">
        {{ 'Sign-up.precedence-as-lead' | translate }}
      </label>
      <br />
      <input
        id="user-lead"
        formControlName="procedenciaComoLead"
        type="text"
        [placeholder]="'Sign-up.enter-code' | translate"
        class="auth-form-input" />
    </div>
    <div class="row mx-0 terms" *ngIf="authAction === 'signup'">
      <div class="col-sm-12">
        <p>
          {{ 'Sign-up.by-registering-im-accepting' | translate }}
          <a class="terms-links" (click)="openTermsModal()">
            {{ 'Sign-up.terms-and-conditions' | translate }}
          </a>
          {{ 'Sign-up.and' | translate }}
          <a class="terms-links" (click)="openPrivacyModal()">
            {{ 'Sign-up.privacy-policy' | translate }}
          </a>
        </p>
      </div>
    </div>
    <div class="login-label center-container">
      <button *ngIf="!loading && authAction === 'login'" id="submit-login" type="submit" class="auth-form-button">
        {{ 'Login.login' | translate }}
      </button>
      <button *ngIf="!loading && authAction === 'signup'" id="submit-login" type="submit" class="auth-form-button">
        {{ 'Login.sign-up' | translate }}
      </button>
      <mat-progress-spinner *ngIf="loading" id="auth-spinner" mode="indeterminate" diameter="40"></mat-progress-spinner>
    </div>
    <div class="login-label forgot">
      <p *ngIf="authAction === 'login'" class="change-form-link text-center" (click)="onForgotPassword()">
        {{ 'Login.forgot-password' | translate }}
      </p>
    </div>
    <div class="separator">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</form>
