import { NavigationService } from './../services/navigation.service';
import { AlertService } from './../services/alert.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cultural-matches',
  templateUrl: './cultural-matches.component.html',
  styleUrls: ['./cultural-matches.component.scss'],
})
export class CulturalMatchesComponent implements OnInit {
  /** CONSTANTS */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api path for retrieving the last match of a student */
  readonly CULTURAL_MATCH_API = 'api/respuesta-match/estudiante/last/';
  /** Variable that holds the list of cultural matches  */
  matches: any[] = [];

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private location: Location,
    private navigationService: NavigationService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      let id = '';
      if (user.tipo === this.TIPO_RECRUITMENT) {
        id = localStorage.getItem('cultural_student');
      } else {
        id = user._id;
      }
      this.getCulturalMatches({ id });
    }
  }

  /**
   * Retrieves the list of cultural matches from the backend
   * @param id student id
   */
  getCulturalMatches({ id }: { id: string }): void {
    this.apiService.get({ api: this.CULTURAL_MATCH_API + id }).subscribe(
      (response) => {
        if (response !== null) {
          const matches = [];
          let cont = 0;
          while (cont < 4 && cont < response.matches.length) {
            matches.push(response.matches[cont].ciudad);
            cont++;
          }
          this.matches = matches;
        } else {
          this.navigationService.navigateTo({ path: 'cultural-item' });
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('My-applications.error-load'),
        });
      }
    );
  }

  /** Handles the click on the go back button */
  onBackClick(): void {
    this.location.back();
  }
}
