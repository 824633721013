import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';

@Component({
  selector: 'app-visa-service-card',
  templateUrl: './visa-service-card.component.html',
  styleUrls: ['./visa-service-card.component.scss']
})
export class VisaServiceCardComponent implements OnInit {

  /** INPUT VARIABLES */
  /** Variable that holds the id of the country */
  @Input() id: string;
  /** Variable that holds the name of the service */
  @Input() name: string;
  /** Variable that holds the name of the country of the service */
  @Input() country: string;
  /** Variable that holds the price of the service */
  @Input() cost: number;
  /** Variable that holds the currency of the service */
  @Input() currency: string;
  /** Variable that holds the image of the service */
  @Input() image: string;
  /** Variable that holds the description of the service */
  @Input() description: string;
  /** Variable that holds the type of the service */
  @Input() type: string;

  constructor(private shoppingCartService: ShoppingCartService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  /**
   * Handles the click on the add to cart button
   */
  onAddToCart(): void {
    if (this.type === 'estudiantil' || this.type === 'migracion') {
      this.shoppingCartService.addConsultancyServices({
        id: this.id
      })
    } else {
      this.shoppingCartService.addVisaService({
        id: this.id
      });
    }
  }

  /**
   * Handles the click on the additional info button
   */
  onAdditionalInfoClick(): void {
    this.dialog.open(ServiceDetailModalComponent, {
      data: {
        service: 'visa',
        country: this.country,
        cost: this.cost,
        currency: this.currency,
        overview: this.description,
        image: this.image,
        type: this.type
      }
    });
  }

}
