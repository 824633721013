import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewTransactionComponent } from '../shared/view-transaction/view-transaction.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pse-response',
  templateUrl: './pse-response.component.html',
  styleUrls: ['./pse-response.component.scss']
})
export class PseResponseComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
    ) { }
  idOrden: string;
  metodoPago: string;
  descripcionPago: string;
  codigoReferencia: string;
  estadoTransaccion: string = "LOADING";
  informacionEstado: string = '';
  informacionFecha: string;
  informacionNombre: string;
  informacionBanco: string;
  informacionIDTransaccion: any;
  esPrueba: boolean;
  ngOnInit(): void {
    alert("hola")
    this.spinner.show();
     
    this.idOrden = this.activatedRoute.snapshot.queryParams["reference_pol"];
    this.codigoReferencia = this.activatedRoute.snapshot.queryParams["referenceCode"];
    this.descripcionPago = this.activatedRoute.snapshot.queryParams["description"]
    if(this.activatedRoute.snapshot.queryParams["transactionState"])
    setTimeout(() => {
      let data="";
      this.apiService.post({ api: "api/pse-search/" + this.idOrden, data }).subscribe(respuesta => {
         
        if(respuesta.error === null){
           
          this.spinner.hide();
          let transacciones = respuesta.result.payload.transactions
          for(let transaccion of transacciones){
            if(transaccion.transactionResponse.state === "APPROVED"){
              this.estadoTransaccion = "APPROVED";
              this.setInformation(transaccion, this.estadoTransaccion);
              this.abrirDialogoRespuesta(this.estadoTransaccion);
            } else if (transaccion.transactionResponse.state === "DECLINED"){
              this.estadoTransaccion = "DECLINED";
              this.setInformation(transaccion, this.estadoTransaccion);
              this.abrirDialogoRespuesta(this.estadoTransaccion);
            } else if (transaccion.transactionResponse.state === "PENDING"){
              this.estadoTransaccion = "PENDING";
              this.abrirDialogoRespuesta(this.estadoTransaccion);
            }
          }
        }
      })
    }, 2000)
  }

  abrirDialogoRespuesta(estadoTransaccion){
    this.dialog.open(ViewTransactionComponent, { data: { estadoTransaccion }});
  }
  setInformation(transaccion, estado){
    if(estado === "APPROVED"){
      this.informacionEstado = 'Aprobada'
    } else if (estado === "DECLINED"){
      this.informacionEstado = 'Rechazada'
    }
    const fecha = new Date(transaccion.transactionResponse.operationDate);
    const [mes, dia, año] =  [fecha.getMonth(), fecha.getDate(), fecha.getFullYear()];
    const [hora, minutos] = [fecha.getHours(), fecha.getMinutes()];
    this.metodoPago = transaccion.paymentMethod;
    this.informacionFecha = `${año}-${mes}-${dia} ${hora}:${minutos}`;
    this.informacionNombre = transaccion.payer.fullName;
    this.informacionBanco = transaccion.extraParameters.FINANCIAL_INSTITUTION_NAME;
    this.informacionIDTransaccion = transaccion.transactionResponse.transactionId || this.idOrden;
  }
}
