import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-application-fee-payment',
  templateUrl: './application-fee-payment.component.html',
  styleUrls: ['./application-fee-payment.component.scss'],
})
export class ApplicationFeePaymentComponent implements OnInit {
  /** CONSTANTS */
  /** API path for application information */
  readonly APPLICATION_API = 'api/aplicacion/';
  /** API route for creating payments */
  readonly APPLICATION_FEE_PAYMENT_API = 'api/pago-application-fee/';
  /** Api for submitting the transactions */
  readonly TRANSACTION_API = 'api/transaccion/';
  /** API for creating payment signatures */
  readonly SIGNATURE_API = 'api/transaccion/payU/token/';
  /** API for making payments */
  readonly PAYMENT_API = 'api/transaccion/payU/pay';
  /** Api for retrieving the exchange rates of the currencies */
  readonly EXCHANGE_RATES_API = 'api/tasa-cambio/actual/';

  /** Variable that holds the payment information */
  payment = {
    programName: '',
    university: '',
    applicationFee: 0,
    applicationFeeConfirmado: true,
    currency: '',
    total: 0,
  };

  /** Variable that holds the application id */
  applicationId: string;
  /** Variable that holds the user id*/
  userId: string;

  /** Current page status
   * 0 --> Purchase overview page
   * 1 --> Checkout page
   */
  pageStatus = 0;

  constructor(
    private ngxSpinner: NgxSpinnerService,
    private alertService: AlertService,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    const applicationId = this.activatedRoute.snapshot.params.id;
    this.applicationId = applicationId;
    this.getApplicationInfo({ id: applicationId });
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.userId = user._id;
    }
  }
  /**
   * Retrieves the application information from the backend
   * @param id application id
   */
  getApplicationInfo({ id }: { id: string }): void {
    this.ngxSpinner.show();
    this.apiService.get({ api: `${this.APPLICATION_API}${id}` }).subscribe(
      (response) => {
        if (response.path === '_id') {
          this.navigationService.navigateTo({ path: 'not-found' });
        } else {
          this.payment.programName = response.programa.nombre;
          this.payment.university = response.programa.universidad.nombre;
          this.payment.applicationFee = response.applicationFee;
          this.payment.applicationFeeConfirmado = response.applicationFeeConfirmado;
          this.payment.currency = response.programa.universidad.ciudad.pais.moneda;
          this.getExchangeRates();
        }
      },
      (err) => {
        this.ngxSpinner.hide();
        this.alertService.showError({ msg: 'There was an error, please try again later' });
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  /**
   * Retrieves the exchange rate information from th backend
   */
  getExchangeRates(): void {
    this.ngxSpinner.show();
    this.apiService.get({ api: this.EXCHANGE_RATES_API }).subscribe(
      (response) => {
        this.setupTotal({ exchangeRates: response });
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was an error retrieving the exchange rates info from the backend, please try again later',
        });
        this.ngxSpinner.hide();
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  /**
   * Setups the total value of the payment
   * @param exchangeRates list of exchange rates
   */
  setupTotal({ exchangeRates }: { exchangeRates: any[] }) {
    for (const rate of exchangeRates) {
      if (rate.moneda === this.payment.currency) {
        this.payment.total = this.payment.applicationFee * rate.tasa;
        break;
      }
    }
  }

  /**
   * Handles the click on the back button
   */
  onBackClick(): void {
    this.location.back();
  }

  /**
   * Handles the click on the make payment button
   */
  onMakePaymentClick(): void {
    this.pageStatus = 1;
  }

  /** Handles click on return from checkout button */
  onReturnFromCheckout(): void {
    this.pageStatus = 0;
  }

  /**
   * First step of creating a one time fee payment, creates a payment to U360 record in the backend
   * @param paymentInformation PayU payment information, passed in by child component
   */
  startPurchase(paymentInformation): void {
    /** Create the shopping cart data */
    this.ngxSpinner.show();
    const total = this.payment.total.toFixed(2);
    const today = new Date();
    const paymentData = {
      estudiante: this.userId,
      valor: total,
      aplicacion: this.applicationId,
      createdAt: today,
      medioDePago: 'PayU',
    };
    // Submit the shopping cart data to the backend
    this.apiService.post({ api: this.APPLICATION_FEE_PAYMENT_API, data: paymentData }).subscribe(
      (response) => {
        paymentInformation['value'] = total;
        paymentInformation['currency'] = 'USD';
        const paymentId = response.result._id;
        this.createTransaction({ paymentInformation, paymentId });
      },
      (err) => {
        this.ngxSpinner.hide();
        this.alertService.showError({
          msg: this.translate.instant('Shopping-cart.error-general'),
        });
      }
    );
  }

  /**
   * Second step of payment, creates a transaction in the backend
   * @param paymentInformation consolidated payment information
   * @param paymentId id of the payment in the backend
   */
  createTransaction({ paymentInformation, paymentId }): void {
    const total = this.payment.total.toFixed(2);
    const transaction = {
      estudiante: paymentInformation.merchantPayerId,
      valor: total,
      pagoApplicationFee: paymentId,
    };

    this.apiService.post({ api: this.TRANSACTION_API, data: transaction }).subscribe(
      (response) => {
        const transactionId = response.result._id;
        paymentInformation['referenceCode'] = transactionId;
        this.createSignature({ paymentInformation, transactionId });
      },
      (err) => {
        this.ngxSpinner.hide();
        this.alertService.showError({
          msg: this.translate.instant('Shopping-cart.error-general'),
        });
      }
    );
  }

  /** Third step of the payment creates a signature for the payment
   * @param paymentInformation consolidated payment information
   * @param transactionId transaction id as it appears in the backend
   */
  createSignature({ paymentInformation, transactionId }): void {
    const total = this.payment.total.toFixed(2);
    const signature = {
      referencePayu: transactionId,
      amount: total,
      currency: 'USD',
    };
    this.apiService.post({ api: this.SIGNATURE_API, data: signature }).subscribe(
      (response) => {
        const signatureId = response.firma;
        paymentInformation['signature'] = signatureId;
        this.makePayment({ paymentInformation });
      },
      (err) => {
        this.ngxSpinner.hide();
        this.alertService.showError({
          msg: this.translate.instant('Shopping-cart.error-general'),
        });
      }
    );
  }

  /** Fourth and last method of payment, makes the payment
   * @param paymentInformation: Consolidated payment information
   */
  makePayment({ paymentInformation }): void {
    this.ngxSpinner.show();
    this.apiService.post({ api: this.PAYMENT_API, data: paymentInformation }).subscribe(
      (response) => {
        if (response.success === true) {
          this.alertService.showSuccess({
            msg: this.translate.instant('Shopping-cart.payment-succesfull'),
          });
          this.ngxSpinner.hide();
          this.location.back();
        } else {
          this.alertService.showError({ msg: response.msg });
          this.ngxSpinner.hide();
        }
      },
      (err) => {
        this.ngxSpinner.hide();
        this.alertService.showError({
          msg: this.translate.instant('Shopping-cart.error-general'),
        });
      }
    );
  }
}
