<div class="student-detail">
    <div class="header">
        <img class="close-img" src="assets/utils/close-outline.svg"
            (click)="$event.stopPropagation(); selfClose()" alt="close"> Cerrar
    </div>
    <div class="content">
        <img *ngIf="data.student.foto" class="img-student" [src]="data.student.foto" alt="student photo">
        <div class="student-information">
            <div class="header-info">
                <div *ngIf="data.student.universidad && (data.student.logoUniversidad || data.student.universidad?.logo) && data.student.universidad?.ciudad?.pais?.icono"
                    class="university-content">
                    <img id="img-university" [src]="data.student.logoUniversidad || data.student.universidad.logo" alt="university logo">
                    <img id="img-city" [src]="data.student.universidad?.ciudad?.pais?.icono" alt="country logo">
                </div>
                <label class="students-name">{{data.student.nombre}}</label>
            </div>
            <div class="container-info">
                <label>{{data.student.programa}}</label>
                <label> {{data.student.ano}}, {{data.student.universidad?.ciudad?.nombre}}</label>
                <label> {{data.student.universidad?.nombre}}</label>
                <p>{{data.student?.descripcion}}</p>
            </div>
        </div>
    </div>
</div>