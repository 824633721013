import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-service-detail-modal',
  templateUrl: './service-detail-modal.component.html',
  styleUrls: ['./service-detail-modal.component.scss']
})
export class ServiceDetailModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ServiceDetailModalComponent>) { }

  ngOnInit(): void {
  }

  /**
   * Closes self
   */
  public selfClose() {
    this.dialogRef.close();
  }
}
