// user.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentUser$: Observable<any> = this.user.asObservable();

  private userTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentUserType$: Observable<string> = this.userTypeSubject.asObservable();

  constructor() {
    this.setUser(JSON.parse(localStorage.getItem('user')));
  }

  setUser(userData: any): void {
    this.user.next(userData);
    this.userTypeSubject.next(userData ? userData.tipo : '');
  }

  updateUserType(userType: string): void {
    this.userTypeSubject.next(userType);
  }
}
