<!-- bar-chart.component.html -->
<div class="wrapper">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Resultados Iniciales del Test</h4>
      <p>
        Conóce en esta sección cuáles son tus competencias individuales más altas e identifica cuales vas a desarrollar.
      </p>
      <hr class="hrLine" />
    </div>
  </div>
  <div class="topSideCharts">
    <div *ngIf="radarChart" class="radar-chart-container">
      <p class="chartTitle">Porcentajes de habilidades</p>
      <apx-chart
        [tooltip]="radarChart.tooltip"
        [yaxis]="radarChart.yaxis"
        [xaxis]="radarChart.xaxis"
        [colors]="radarChart.colors"
        [series]="radarChart.series"
        [chart]="radarChart.chart"
        [plotOptions]="radarChart.plotOptions"
        [markers]="radarChart.markers"
        [grid]="radarChart.grid"
        [labels]="radarChart.labels"
        [legend]="radarChart.legend"
        [dataLabels]="radarChart.dataLabels"></apx-chart>
    </div>

    <div class="average-chart" *ngIf="averageChartoptions">
      <p class="chartTitle">Promedio general</p>
      <div class="averageChartData">
        <div>
          <apx-chart
            [series]="averageChartoptions.series"
            [chart]="averageChartoptions.chart"
            [plotOptions]="averageChartoptions.plotOptions"
            [fill]="averageChartoptions.fill"
            [stroke]="averageChartoptions.stroke"
            [labels]="averageChartoptions.labels"></apx-chart>
        </div>

        <div class="row container-tip">
          <div class="col-1">
            <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/cara-feliz.png" alt="cara feliz" />
          </div>

          <div class="col-10">
            <p class="desc-tip">
              {{ averageLevelDescription }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="chartOptions" class="chart-container">
    <p class="chartTitle">Competencias principales</p>
    <apx-chart
      [options]="chartOptions.options"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [theme]="chartOptions.theme"></apx-chart>
  </div>
  <div *ngIf="chartOptions" class="chart-container">
    <p class="chartTitle">Competencias secundarias</p>
    <apx-chart
      [options]="secondarySkillsChartOptions.options"
      [series]="secondarySkillsChartOptions.series"
      [chart]="secondarySkillsChartOptions.chart"
      [plotOptions]="secondarySkillsChartOptions.plotOptions"
      [tooltip]="secondarySkillsChartOptions.tooltip"
      [dataLabels]="secondarySkillsChartOptions.dataLabels"
      [legend]="secondarySkillsChartOptions.legend"
      [xaxis]="secondarySkillsChartOptions.xaxis"
      [yaxis]="secondarySkillsChartOptions.yaxis"
      [labels]="secondarySkillsChartOptions.labels"
      [responsive]="secondarySkillsChartOptions.responsive"
      [fill]="secondarySkillsChartOptions.fill"
      [stroke]="secondarySkillsChartOptions.stroke"
      [theme]="secondarySkillsChartOptions.theme"></apx-chart>
  </div>
</div>
