<div class="create-alert-modal">
  <div class="close">
    <mat-icon (click)="selfClose()">close</mat-icon>
  </div>
  <div class="create-alert-title">
    {{'Alerts.create-ticket' | translate}}
  </div>
  <div class="form-group">
    <label for="application-select">{{'Alerts.title'|translate}}</label> <br>
    <input type="text" [(ngModel)]="title" id="title-select">
  </div>
  <div class="form-group" *ngIf="isRecruitment">
    <label for="application-select"> {{'Alerts.select-student'|translate}}</label> <br>
    <select [(ngModel)]="selectedStudent" (ngModelChange)="onChangeStudent({id: $event})" id="application-select">
      <option *ngFor="let s of students" [value]="s._id">{{s.nombre}} {{s.apellido}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="application-select"> {{'Alerts.select-application' | translate}}</label> <br>
    <select [(ngModel)]="selectedApplicationId" id="application-select">
      <option *ngIf="!selectedStudent && isRecruitment" disabled>{{'Alerts.select-student' | translate}}</option>
      <option *ngFor="let application of applications" [value]="application._id">{{application.programa.nombre}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="application-select"> {{'Alerts.priority'|translate}}</label> <br>
    <select [(ngModel)]="priority" id="priority-select">
      <option value="Baja">{{'Alerts.low'|translate}}</option>
      <option value="Media">{{'Alerts.medium' | translate}}</option>
      <option value="Alta">{{'Alerts.high'|translate}}</option>
    </select>
  </div>
  <div>
    <textarea id="reason-textarea" [(ngModel)]="ticketReason" [placeholder]="'Alerts.ticket-reason'|translate"></textarea>
  </div>
  <button (click)="onCreateTicket()" id="create-ticket-button">{{'Alerts.create-ticket' | translate}}</button>
</div>
