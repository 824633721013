<div class="university-parent">
  <div class="university-container">
    <div class="header">
      <img class="back-button" src="assets/utils/detail-arrow.png" (click)="goBack()" alt="go back" />
      <h2 class="university-title"> Volver </h2>
    </div>
    
    <div class="row">
      <div class="col-6 container-info-img-university" >
        <div class="container-card-inf-program" style="margin-bottom: 2%;">
          <div class="container-name-university">
            <div class="container-name-country">
              <div class="university-info-header">
                  <h2 class="university-name-h2">{{ university.nombre | titlecase }}</h2>
                  <img class="logo-university" *ngIf="university?.logo" [src]="university.logo" [alt]="'Logo de ' + university.nombre">
              </div>
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/campus.png" alt="campus">
                </div>
                <div class="name-campus">{{ university?.campus }}</div>
              </div>
              <div class="container-city">
                <div class="container-img-ciy">
                  <img [src]="university?.ciudad?.pais?.icono" alt="country" >
                </div>
                <div class="name-city">{{ university.ciudad?.nombre }}, {{university.ciudad.pais.nombre }}</div>
              </div>
  
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/tipo-programa.png" alt="Type of university">
                </div>
                <div class="name-campus">Tipo: {{ ((university.tipo | lowercase) == 'public') ?
                  'Pública':'Privada'}}</div>
              </div>
  
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/programa-blanco.png" alt="program graduation work permit">
                </div>
                <div class="name-campus">Post-Graduation Work Permit: {{ (university.pgwp)?"Sí":"No"}}</div>
              </div>
  
              <div class="container-city" *ngIf="university.programasInternacionales">
                <div class="container-img-ciy">
                  <img src="assets/utils/international.png" alt="international">
                </div>
                <div class="name-campus"  >Programas internacionales: {{ university.programasInternacionales | number }}</div>
              </div>
              
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/matricula.png" alt="tuition">
                </div>
                <div  class="name-campus">Costos por año: {{ ((university.costsPerYear | number) + ' ' + university.ciudad.pais.moneda + '/Año' )|| 'No definido' }}</div>
              </div>
              
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/trabajar-mientras-estudia.png" alt="can work while studying">
                </div>
                <div  class="name-campus">Trabajar mientras estudia: {{ (university.workStudying)? 'Sí':'No' || "ND"}}</div>
              </div>
              
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/costo-vida.png" alt="living cost">
                </div>
                <div  class="name-campus">Costo de vida (Sin renta): {{university.livingCost | number}}
                  {{university.ciudad.pais.moneda}}/Mes</div>
              </div>
  
              <div class="container-city">
                <div class="container-img-ciy">
                  <img src="assets/utils/costo-aplicacion.png" alt="application fee">
                </div>
                <div  class="name-campus">Cuota de aplicación: {{ university.applicationFee ? (university.applicationFee | number) + ' ' + university.ciudad.pais.moneda : 'ND'}}</div>
              </div>
            </div>
          </div>
  
          <div class="container-buttons">
            <button class="btn-view-prog" routerLink="../../program/search"
              [queryParams]="{ 'universidad': university.nombre }">Ver Programas</button>
              <button class="btn-view-webi" >Contacto</button>
          </div>
        </div>
      </div>

      <div class="col-6 container-card-inf-program-dudas" *ngIf="university?.videosResuelve?.length > 0">    
        <app-list-videos-qa [university]="university"></app-list-videos-qa>
      </div>

      <div class="col-6 container-info-img-university">
        <!-- Info -->
        <div class="container-card-inf-program-2 container-about">
          <div>
          <h5 class="program-name-h5"> Acerca de {{ university.nombre | titlecase }}</h5>
          <p class="about-reduced" style="display:block" *ngIf="university.about">{{university.about | truncate:[438]}}</p>
          <p class="about-big" *ngIf="university.about && showMore" [innerHTML]="university.about"></p>
          <p *ngIf="!university.about"> Sin descripción</p>
          </div>
          <button class="view-more" (click)="changeShowMore()" *ngIf="university.about && !showMore">Ver más</button>
          <button class="view-more" (click)="changeShowMore()" *ngIf="university.about && showMore">Ver menos</button>
        </div>
      </div>

      <div *ngIf="university?.videosResuelve?.length > 0 then siDudas;else noDudas"></div>
      <ng-template #siDudas>
        <div class="col-6 container-info-img-university">
          <div class="col-12 container-card-inf-program-videosFotos" style="margin-top: 0%; margin-left: -2.5%;">
            <!-- Photos and Videos -->
            <app-videos-photos [university]="university"></app-videos-photos>
          </div>
        </div>
       </ng-template>
  
       <ng-template #noDudas>
        <div class="col-12 container-info-img-university">
          <div class="col-12 container-card-inf-program-videosFotos" style="margin-top: 2%">
            <!-- Photos and Videos -->
            <app-videos-photos [university]="university"></app-videos-photos>
          </div>
        </div>
       </ng-template>
    
    
      <!-- FORM CARD -->
      <div class="col-6 container-info-img-university">
        <div class="container-card-inf-program-3">
          <app-form-contact [university]="university"></app-form-contact>
          <iframe loading="lazy" src="https://share.hsforms.com/1FrBV0HlSQUqWk_h0H1_V-gch3zo" style="border:0px #ffffff none;"
            frameborder="1" marginheight="0px" marginwidth="0px" height="800px" width="100%" allowfullscreen></iframe>
        </div>
      </div>
      
      <div *ngIf="university?.videosResuelve?.length > 0 then siDudasUbicacion;else noDudasUbicacion" [hidden]="true"></div>
      <ng-template #siDudasUbicacion>
          <div class="col-6 container-card-inf-program-ub">
            <app-ubication [university]="university"></app-ubication>
          </div>
      </ng-template>
      
      <ng-template #noDudasUbicacion>
          <div class="col-6 container-card-inf-program-ub">
            <!-- Ubication -->
            <app-ubication [university]="university"></app-ubication>
          </div>
      </ng-template>
    </div>
  </div>

  <!-- Programs -->
  <app-similar-programs [isUniversity]="true"></app-similar-programs>

  <!-- Test section -->
  <app-test-section></app-test-section>

</div>
