<div style="padding-bottom: 55px;">
    <div class="program-container">
        <div class="container-title">
            <h5>Programas Relacionados</h5>
        </div>
        <div class="image-slider">
            <div class="container-similar-programs" *ngIf="similarPrograms.length">
                <div *ngFor="let program of similarPrograms" class="container-card">
                    <app-program-card [program]="program" [showRequisitesButton]="true"></app-program-card>
                </div>
            </div>
        </div>
    </div>
</div>