import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-principal',
  templateUrl: './service-principal.component.html',
  styleUrls: ['./service-principal.component.scss']
})
export class ServicePrincipalComponent implements OnInit {

  colors = ["#3A5FD9", "#5C9B9B", "#FD5675", "#F2AF49", "#545BA8"];

  constructor() { }

  ngOnInit(): void {
  }
}
