<div
  class="card menu-container"
  [ngClass]="{ 'menu-collapsed-mobile': !isActiveMenuMobile, 'college-menu': isColegio, 'full-menu': isStudent }"
  #boxSidebarMenu
  *ngIf="isStudent || isRecruitment">
  <div class="card-body menu-options-container">
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.documents || menuOptions.testIA || isConoceteVisible)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/documentos-2-gris.png" alt="documents" />
        <p
          routerLink="/profile"
          [ngClass]="{
            'option-active': menuOptions.documents || menuOptions.testIA || isConoceteVisible,
            'display-mobile-none': !isActiveMenuMobile
          }"
          (click)="activeOption('documents'); toggleConocete()">
          Conócete
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="isConoceteVisible">
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.documents }"
            routerLink="/profile"
            (click)="activeOption('documents')">
            Mi perfil
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.testIA }"
            routerLink="/leaderTest"
            (click)="activeOption('testIA')">
            Test de competencias
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.englishTest }"
            routerLink="/english-test"
            (click)="activeOption('englishTest')">
            Test Inglés
          </p>
        </div>
      </div>
    </div>

    <hr *ngIf="isStudent || isRecruitment" />
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!menuOptions.dashboard || showDashboard"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p
          [ngClass]="{
            'option-active': menuOptions.dashboard || showDashboard,
            'display-mobile-none': !isActiveMenuMobile
          }"
          routerLink="current-test"
          (click)="activeOption('initalResults'); toggleDashboard()">
          Tablero de resultados
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showDashboard">
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.initalResults }"
            routerLink="current-test"
            (click)="activeOption('initalResults')">
            Resultados iniciales
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.dashboard }"
            routerLink="/skill-dashboard"
            (click)="activeOption('dashboard')">
            Resultados vs Universidades
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.progressionChart }"
            routerLink="progression"
            (click)="activeOption('progressionChart')">
            Progreso general
          </p>
        </div>
      </div>
    </div>

    <hr />
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.institutionType || showExplore)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p
          routerLink="/universityType"
          [ngClass]="{
            'option-active': menuOptions.institutionType || showExplore,
            'display-mobile-none': !isActiveMenuMobile
          }"
          (click)="activeOption('institutionType'); toggleExplore()">
          Explora y planea
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showExplore">
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.institutionType }"
            routerLink="universityType"
            (click)="activeOption('institutionType')">
            Tipo de instituciones
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.exploreSkills }"
            routerLink="explore-skills"
            (click)="activeOption('exploreSkills')">
            Explorar competencias
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.experiencesExample }"
            routerLink="experience-example"
            (click)="activeOption('experiencesExample')">
            Ejemplo de experiencias
          </p>
        </div>
      </div>
    </div>

    <hr *ngIf="isStudent || isRecruitment" />
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.cv || showMisExperiencias)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p
          [ngClass]="{
            'option-active': menuOptions.cv || showMisExperiencias,
            'display-mobile-none': !isActiveMenuMobile
          }"
          routerLink="/curriculum"
          (click)="activeOption('cv'); toggleMisExperiencias()">
          Desarrolla y Expón
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showMisExperiencias">
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.experiencesPlanner }"
            [routerLink]="['/experience-planner']"
            (click)="activeOption('experiencesPlanner')">
            Mi plan de experiencias
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.experiences }"
            [routerLink]="['/experience-record', userId]"
            (click)="activeOption('experiences')">
            Experiencias Registradas
          </p>
        </div>
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.cv }" routerLink="/curriculum" (click)="activeOption('cv')">
            Mi hoja de vida
          </p>
        </div>

        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.coverLetter }"
            routerLink="/cover-letter"
            (click)="activeOption('coverLetter')">
            Carta de intención
          </p>
        </div>
      </div>
    </div>

    <hr *ngIf="isStudent || isRecruitment" />
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.search || showMatch)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/loupe.png" alt="search" />
        <p
          [ngClass]="{
            'option-active': menuOptions.search || showMatch,
            'display-mobile-none': !isActiveMenuMobile
          }"
          routerLink="/home"
          (click)="activeOption('search'); toggleMatch()">
          Conéctate y aplica
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showMatch">
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.search }" routerLink="/home" (click)="activeOption('search')">
            Explorador Universidades
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': menuOptions.favourites }"
            routerLink="/wishlist"
            (click)="activeOption('favourites')">
            Wish List
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
