import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  /** Pipe to cut a text to the specified limit and add ellipsis points afterwards (...)
   * @param: limit -> max number of characters
   */
  transform(value: string, limit: number): string {
    if (value.length > limit) {
       return value.substring(0, limit) + '...';
    } else {
      return value;
    }
  }

}
