import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-blocked-modal',
  templateUrl: './blocked-modal.component.html',
  styleUrls: ['./blocked-modal.component.scss']
})
export class BlockedModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<BlockedModalComponent>,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  /**
   * Handles the click on the ok button
   */
  onOkClick(): void {
    this.dialogRef.close();
  }

}
