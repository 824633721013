<div class="scholarship-card">
  <div class="scholarship-card-header">
    <mat-icon (click)="onAdditionalInfoClick()" class="info-button"> info
    </mat-icon>
  </div>
  <div class="scholarship-info">
    <img [src]="image" class="scholarship-img" alt="scholarship">
    <div class="scholarship-data">
      {{ "Scholarship-card.scholarship-for" | translate }}<br>
      <span [matTooltip]="university" matTooltipPosition="above" class="university"> {{university |ellipsis:20}} </span> <br>
      <span class="scholarship-percentage"> {{ countryScholarship.nombre }}</span> <br>
      <span *ngIf="scholarship.fuente" class="scholarship-percentage"> {{ scholarship.fuente }}</span> <br>
      <span class="scholarship-percentage"> {{percentage}} %</span>
    </div>
  </div>

  <button *ngIf="applyStatus" (click)="onApply()" class="apply">{{"Scholarship-card.apply" | translate}}</button>
</div>
