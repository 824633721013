<div class="h-100">
  <div class="ambassadors principal-container">
    <div class="text-separator row m-0 p-0">
      <div class="col-12 m-0 p-0 d-lg-flex justify-content-center">
        <div class="pr-3 display-mobile-none">
          <img class="girl-img" src="assets/home/images/girl.png" alt="girl" />
        </div>
        <div class="principal-text-container visa-container pt-lg-3">
          <img
            class="display-mobile-none"
            id="birrete-icon"
            src="assets/home/images/birrete-rojo.png"
            alt=" birrete rojo" />
          <h2><strong>¿Quieres estudiar en Europa, Canadá o Estados Unidos?</strong></h2>
          <p>
            Usa los filtros y test para encontrar tu destino y programa ideal. U360 te pondrá en contacto con un
            representante autorizado para guiarte.
          </p>
          <div class="div-button-explore row m-0">
            <ng-multiselect-dropdown
              class="col-lg-6 col-5 pl-0 pr-0"
              placeholder="Programa"
              [settings]="settingsA"
              [data]="disciplineDropdownList"
              [(ngModel)]="discipline"
              (onSelect)="onSubmitSearch({ page: 1 })"></ng-multiselect-dropdown>

            <ng-multiselect-dropdown
              class="col-lg-6 col-5 drop"
              placeholder="Nivel"
              [settings]="settingsA"
              [data]="accreditationDropdownList"
              [(ngModel)]="accreditation"
              (onSelect)="onSubmitSearch({ page: 1 })"></ng-multiselect-dropdown>

            <div class="Search-Img-Out col-12 row m-0">
              <div class="col-lg-11 col-8 pr-2 pl-0">
                <div class="search-bar">
                  <input
                    (keydown.enter)="onSubmitSearch({ page: 1 })"
                    class="search-input"
                    [placeholder]="
                      scrWidth > 951
                        ? 'Busca programas, universidades, destinos...'
                        : 'Programas, universidades, destinos...'
                    "
                    type="text"
                    id="program-name"
                    [(ngModel)]="name" />
                </div>
              </div>
              <div class="search-img-container col-lg-1 col-2">
                <img
                  (click)="onSubmitSearch({ page: 1 })"
                  class="search-image"
                  src="assets/utils/second-search-icon.png"
                  alt="search" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="tabs-content" class="search"></div>
  </div>
</div>
