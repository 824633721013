import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-free-guides-resources',
  templateUrl: './free-guides-resources.component.html',
  styleUrls: ['./free-guides-resources.component.scss'],
})
export class FreeGuidesResourcesComponent implements OnInit {
  keyword: string = '';
  categoryId: string = '';
  description: string = '';
  category: string = '';
  freeGuides = [];
  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getCategoryByKeyword();
  }

  getCategoryByKeyword() {
    this.route.queryParamMap.subscribe((params) => {
      this.keyword = params.get('category');
    });

    this.apiService.get({ api: `api/guias-gratuitas/clave/${this.keyword}` }).subscribe((response) => {
      if (response.success) {
        this.categoryId = response.result._id;
        this.description = response.result.descripcion;
        this.category = response.result.categoria;
        this.getGuidesByCategory(this.categoryId);
      }
    });
  }

  getGuidesByCategory(categoryId) {
    this.apiService.get({ api: `api/guia-gratuita/categoria/${categoryId}` }).subscribe((response) => {
      if (response.success) {
        this.freeGuides = response.result;
        for (let i = 0; i < this.freeGuides.length; i++) {
          if ((i + 1) % 4 === 0) {
            this.freeGuides[i]['color'] = 'yellow';
          } else if ((i + 1) % 3 === 0) {
            this.freeGuides[i]['color'] = 'green';
          } else if ((i + 1) % 2 === 0) {
            this.freeGuides[i]['color'] = 'blue';
          } else {
            this.freeGuides[i]['color'] = 'pink';
          }
        }
      }
    });
  }
}
