import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
})
export class WorkExperienceComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the api path for use services */
  readonly USER_API = 'api/user/';
  readonly USER_ONE_API = 'api/oneUser/';
  /** Variable that holds the work experience form */
  workExperienceForm: FormGroup;
  /** Variable that holds the current user id */
  userId: String;
  /** Variable that signals if the save-changes button is loading */
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.workExperienceForm = this.formBuilder.group({
      tieneExperiencia: false,
      experiencias: this.formBuilder.array([]),
    });
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.userId = student;
      } else {
        this.userId = user._id;
      }
      this.getUserInfo();
    }
  }

  /**
   * Retrieves the information of the current user form the backend
   */
  getUserInfo() {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.USER_ONE_API + this.userId }).subscribe(
      (response) => {
        if (response.tieneExperiencia) {
          this.hasExperiences.setValue(response.tieneExperiencia);
        }
        for (const experience of response.experiencias) {
          const experienceForm = this.formBuilder.group({
            posicion: experience.posicion,
            nombreEmpresa: experience.nombreEmpresa,
            anoInicio: [experience.anoInicio, [Validators.min(1900), Validators.max(2099)]],
            anoFin: [experience.anoFin, [Validators.min(1900), Validators.max(2099)]],
            informacionAdicional: experience.informacionAdicional,
          });
          this.experiences.push(experienceForm);
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Work-experience.load-error'),
        });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Returns the list of work experiences
   */
  get experiences(): FormArray {
    return this.workExperienceForm.get('experiencias') as FormArray;
  }

  /**
   * Returns the has experiences form control
   */
  get hasExperiences(): AbstractControl {
    return this.workExperienceForm.get('tieneExperiencia');
  }

  /**
   * Handles the click on the add work experience button
   */
  onAddExperince(): void {
    const experience = this.formBuilder.group({
      posicion: '',
      nombreEmpresa: '',
      anoInicio: [undefined, [Validators.max(2099), Validators.min(1900)]],
      anoFin: [undefined, [Validators.max(2099), Validators.min(1900)]],
      informacionAdicional: '',
    });

    this.experiences.push(experience);
  }

  /**
   * Handles the click on the delete experince button
   */
  onDeleteExperience({ index }: { index: number }): void {
    this.experiences.removeAt(index);
  }

  /**
   * Handles the form submission
   */
  onSubmitForm(): void {
    this.loading = true;
    const data = this.workExperienceForm.value;

    let errorForm;
    for (const work of data.experiencias) {
      if (
        data.tieneExperiencia == true &&
        (work.anoFin == null || work.anoInicio == null || work.nombreEmpresa.length < 1 || work.posicion.length < 1)
      ) {
        errorForm = true;
      } else {
        errorForm = false;
      }
    }

    if (errorForm == true) {
      this.alertService.showError({
        msg: this.translate.instant('Porfavor llena todos los campos obligatorios'),
      });
      this.loading = false;
    } else {
      this.apiService.put({ api: this.USER_API + this.userId, data }).subscribe(
        (response) => {
          if (response.success === false) {
            this.alertService.showError({
              msg: 'Hubo un error actualizando los datos',
            });
          } else {
            this.alertService.showSuccess({
              msg: 'Se han actualizado los datos correctamente',
            });
            this.navigationService.navigateTo({ path: 'profile' });
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'Hubo un error actualizando los datos',
          });
          this.loading = false;
        },
        (complete?) => {
          this.loading = false;
        }
      );
    }
  }
}
