<div class="col-xl-12" *ngIf="!isMyCv">
  <img class="back-button" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()" />
</div>
<div id="boxBaner" class="profile-section">
  <img [src]="fotoBanner" alt="Foto de baner" class="banner-image" for="bannerImage" />
  <div class="h-100 w-100">
    <div class="div-icon-link">
      <div class="mt-3">
        <div [hidden]="actived != 'icon'">
          <div class="d-flex justify-content-end">
            <img
              id="img-copiar"
              class="img-icon-banner"
              (click)="LinkProfileCopy()"
              src="/assets/curriculum/icon-banner.png"
              alt="" />
          </div>
        </div>
        <div [hidden]="actived != 'txt'">
          <div class="d-flex justify-content-around div-enlace" (click)="hideTxt()">
            <div>
              <img src="/assets/curriculum/documentos-2-gris.png" alt="" class="img-icon-enlace" />
            </div>
            <div>
              <label class="txt-style txt-skills">Enlace copiado</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start div-principal">
      <img [src]="fotoPerfil" alt="Foto de perfil" class="profile-photo" />
      <div class="d-flex justify-content-start div-info">
        <div class="w-100 d-flex justify-content-between align-items-end align-items-lg-start">
          <label class="titulo-1">{{ nombre }}</label>
          <img
            class="img-pencil"
            routerLink="/curriculum-edit"
            src="/assets/curriculum/pencil.png"
            alt="editar"
            *ngIf="isMyCv" />
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="display-mobile-none">
            <div class="w-100 txt-style">
              <label class="titulo-2">
                <b>Colegio:</b>
                {{ colegio }}
              </label>
            </div>
            <div class="w-100 txt-style">
              <label class="titulo-2">
                <b>Proyecto:</b>
                {{ proyecto }}
              </label>
            </div>
            <div class="w-100 txt-style">
              <label class="titulo-2">
                <b>Palabras clave:</b>
                {{ palabrasClave }}
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end" *ngIf="endprofile">
            <img class="img-master" [src]="sourceBadge" alt="insignia" />
            <label class="txt-style txt-skills txt-master px-1">Perfil Nivel {{ skillsAverage.level }}</label>
            <div class="tooltip-icon">
              <img class="img-question" src="/assets/curriculum/preguntas.png" alt="tooltip" />
              <span>
                Cumple con todos los requisitos para acceder a una universidad Top Tier, sin embargo, debe continuar
                trabajando en su perfil.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="div-secundario">
  <div class="display-mobile profile-info">
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Colegio:</b></label>
      <label class="titulo-2 col-8 p-0">{{ colegio }}</label>
    </div>
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Proyecto:</b></label>
      <label class="titulo-2 col-8 p-0">{{ proyecto }}</label>
    </div>
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Palabras clave:</b></label>
      <label class="titulo-2 col-8 p-0">{{ palabrasClave }}</label>
    </div>
  </div>
  <div class="div-about-me display-mobile-none">
    <label class="about-me-title line txt-style">Sobre mi</label>
    <br />
    <div class="about-me-description">
      <label class="txt-style txt-about-me-description">{{ sobreMi }}</label>
    </div>
  </div>

  <div class="div-about-me display-mobile">
    <label class="about-me-title line txt-style">Sobre mi</label>
    <br />
    <div class="about-me-description">
      <label class="txt-style txt-about-me-description">{{ sobreMi }}</label>
    </div>
  </div>
</div>

<div class="btn-experiences-container" padding="0 10px 0 80px">
  <button class="btn-experiences" (click)="goToExperiences()">Mis experiencias</button>
</div>

<div class="d-flex flex-column flex-lg-row mt-2 mt-lg-5 div-terciario">
  <div class="languages">
    <label class="txt-style txt-skills">Idiomas:</label>
    <div class="d-flex flex-column flex-lg-row justify-content-between w-100">
      <div *ngFor="let idioma of idiomas" class="idiomas-container">
        <div>
          <label class="language">
            <b>{{ idioma.nombre }}</b>
            {{ idioma.nivel }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mt-3 mt-lg-0 border-left areas-container">
    <label class="txt-style txt-skills">Áreas de interés</label>
    <div class="d-flex areas-icon-container w-100">
      <div *ngFor="let area of areas">
        <div>
          <p class="areaName">
            <b>{{ area.nombre }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex border-left mt-3 mt-lg-0 paises-container">
    <label class="txt-style txt-skills">Paises de interés</label>
    <div class="d-flex paises-icon-container">
      <div *ngFor="let pais of matchingCountries">
        <div class="countryNameIcon">
          <img class="countryImage" [src]="pais.icono" alt="pais.nombre" />
          <p class="areaName">
            <b>{{ pais.nombre }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="studentInformationContainer">
  <div *ngIf="chartOptions" class="chart-container">
    <p class="chartTitle">Competencias principales</p>
    <apx-chart
      [options]="chartOptions.options"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [theme]="chartOptions.theme"></apx-chart>
  </div>

  <div *ngIf="chartOptions" class="chart-container">
    <p class="chartTitle">Competencias secundarias</p>
    <apx-chart
      [options]="secondarySkillsChartOptions.options"
      [series]="secondarySkillsChartOptions.series"
      [chart]="secondarySkillsChartOptions.chart"
      [plotOptions]="secondarySkillsChartOptions.plotOptions"
      [tooltip]="secondarySkillsChartOptions.tooltip"
      [dataLabels]="secondarySkillsChartOptions.dataLabels"
      [legend]="secondarySkillsChartOptions.legend"
      [xaxis]="secondarySkillsChartOptions.xaxis"
      [yaxis]="secondarySkillsChartOptions.yaxis"
      [labels]="secondarySkillsChartOptions.labels"
      [responsive]="secondarySkillsChartOptions.responsive"
      [fill]="secondarySkillsChartOptions.fill"
      [stroke]="secondarySkillsChartOptions.stroke"
      [theme]="secondarySkillsChartOptions.theme"></apx-chart>
  </div>
</div>

<div class="studentInformationContainer">
  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Perfil Individual</label>
    <br />
    <div class="about-me-description" [innerHtml]="individualProfile"></div>
  </div>

  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Logros y Responsabilidades</label>
    <br />
    <div class="about-me-description" [innerHtml]="achievements"></div>
  </div>

  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Historial Académico:</label>
    <br />
    <div class="about-me-description" [innerHtml]="academicRecord"></div>
  </div>

  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Historial Laboral:</label>
    <br />
    <div class="about-me-description" [innerHtml]="employmentHistory"></div>
  </div>

  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Competencias Relevantes:</label>
    <br />
    <div class="about-me-description" [innerHtml]="relevantSkills"></div>
  </div>

  <div class="div-studenInformation display-mobile-none">
    <label class="about-me-title line txt-style">Certificaciones y Formación Adicional:</label>
    <br />
    <div class="about-me-description" [innerHtml]="aditionalFormation"></div>
  </div>
</div>

<div class="d-flex mt-3 mt-lg-5 competencias" *ngIf="skillsScore; else noSkillsTest">
  <div>
    <div class="titleWrapper">
      <label class="about-me-title txt-style mb-2 mb-lg-4">Competencias</label>
    </div>
    <label class="skillsTitle txt-style mb-2 mb-lg-4">Puntaje general:</label>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="" />
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Puntaje general ({{ skillsAverage.level }})</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{ skillsAverage.score }}%</label>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            {{ skillsAverage.levelDescription }}
          </label>
        </div>
      </div>
    </div>

    <label class="skillsTitle txt-style mb-2 mb-lg-4">Competencias principales:</label>
    <div class="mb-4 d-flex" *ngFor="let skill of skillsObjects">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="" />
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">{{ skill.name }} ({{ skill.level }})</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{ skill.score }}%</label>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">Nivel {{ skill.levelDescription }}</label>
        </div>
      </div>
    </div>

    <label class="skillsTitle txt-style mb-2 mb-lg-4">Competencias secundarias:</label>
    <div class="mb-4 d-flex" *ngFor="let skill of secondarySkillsObjects">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="" />
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">{{ skill.name }} ({{ skill.level }})</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{ skill.score }}%</label>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">Nivel {{ skill.levelDescription }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noSkillsTest>
  <div class="d-flex mt-3 mt-lg-5 withoutSkillTestMessage">
    <div>
      <label class="about-me-title txt-style mb-2 mb-lg-4">
        ¡Aún no has realizado ningún test! ¡Ve y realiza uno para conocer tu nivel!
      </label>
    </div>
    <button class="btn-experiences" (click)="goToTest()">Realizar</button>
  </div>
</ng-template>

<div class="mt-1 mt-lg-5 competencias" *ngIf="logros.length > 0">
  <label class="about-me-title txt-style mb-lg-3">Logros e intereses</label>
  <div class="d-flex flex-column flex-lg-row card-mobile">
    <div class="text-center">
      <img class="img-trophy mt-2 mt-lg-0" src="/assets/curriculum/trophy.png" alt="" />
    </div>
    <div class="mt-2 mt-lg-0">
      <ul>
        <li class="logro" *ngFor="let item of logros">{{ item.descripcion }}</li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="referencias.length > 0" class="referencias mb-4">
  <div class="mt-3 mt-lg-5">
    <label class="about-me-title txt-style mb-2 mb-lg-3">Notas de Referencia</label>
  </div>

  <div class="mb-3 mb-lg-0 mt-lg-5" *ngFor="let item of referencias">
    <div class="d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="" />
      </div>
      <div class="ml-lg-4 card-mobile w-100">
        <div>
          <label class="txt-style txt-title-capacities">{{ item.nombre }}</label>
        </div>
        <div>
          <label class="txt-style txt-subtitle-capacities">{{ item.cargo }}</label>
        </div>
        <div>
          <label class="txt-style txt-capacities mt-2 mt-lg-0">{{ item.nota }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
