import { ActivatedRoute } from '@angular/router';
import { S3Service } from './../../services/s3.service';
import { ApiService } from './../../services/api.service';
import { NavigationService } from './../../services/navigation.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-academic-history',
  templateUrl: './academic-history.component.html',
  styleUrls: ['./academic-history.component.scss'],
})
export class AcademicHistoryComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the list of options for the study level select field */
  readonly studylevelOptions = [
    {
      name: this.translate.instant('Academic-history.bachelor-option'),
      value: 'Bachiller',
    },
    {
      name: this.translate.instant('Academic-history.technical-degree-option'),
      value: 'Tecnico',
    },
    {
      name: this.translate.instant('Academic-history.undergraduate-option'),
      value: 'Pregrado',
    },
    {
      name: this.translate.instant('Academic-history.post-graduate-option'),
      value: 'Postgrado',
    },
  ];
  /** Variable that holds the api for academic history */
  readonly ACADEMIC_HISTORY_API = 'api/academic-history/';

  /** Variable that holds the academic history form array */
  academicHistoryArray: FormArray;
  /** variable that holds the id of the current user */
  id: string;
  /** Variable that signals if the academic history is being edited or created */
  creating = false;
  /** Variable that holds the current acaddemic history id */
  academicHistoryId: string;

  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private alertService: AlertService,
    private apiService: ApiService,
    private s3Service: S3Service,
    private activatedRoute: ActivatedRoute,
    private ngxSpinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.academicHistoryArray = this.fb.array([]);
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getUserInfo();
    }
  }
  /** Adds a new entry to the academic history array */
  onAddEntry(): void {
    const entry = this.fb.group({
      estudiosRealizados: '',
      institucion: '',
      tituloAlcanzado: '',
      fechaDeInicio: '',
      fechaDeFinalizacion: '',
      notaPromedio: 20,
      certificadoDeNotas: '',
      diploma: '',
      leadershipAwards: '',
    });

    this.academicHistoryArray.push(entry);
  }

  /**
   * Removes an entry from the academic history array
   * @param index index of the entry that is going to be removed
   */
  onRemoveEntry({ index }: { index: number }): void {
    this.academicHistoryArray.removeAt(index);
  }

  /** Retrieves the user information from the backend */
  getUserInfo(): void {
    this.apiService
      .get({ api: this.ACADEMIC_HISTORY_API + 'estudiante/' + this.id })
      .subscribe(
        (response) => {
          if (response !== null) {
            this.creating = false;
            this.academicHistoryId = response._id;
            for (const entry of response.historial) {
              const formEntry = this.fb.group({
                estudiosRealizados: entry.estudiosRealizados,
                institucion: entry.institucion,
                tituloAlcanzado: entry.tituloAlcanzado,
                fechaDeInicio: entry.fechaDeInicio,
                fechaDeFinalizacion: entry.fechaDeFinalizacion,
                notaPromedio: entry.notaPromedio,
                certificadoDeNotas: entry.certificadoDeNotas,
                diploma: entry.diploma,
                leadershipAwards: entry.leadershipAwards,
              });
              this.academicHistoryArray.push(formEntry);
            }
          } else {
            this.creating = true;
            this.onAddEntry();
          }
        },
        (error) => {
          this.alertService.showError({
            msg: this.translate.instant('Academic-history.load-error'),
          });
        }
      );
  }

  /** Submits the form to the backend and saves the data */
  onSubmitForm(): void {
    if (this.creating) {
      const data = {
        estudiante: this.id,
        historial: this.academicHistoryArray.value,
      };
      this.apiService.post({ api: this.ACADEMIC_HISTORY_API, data }).subscribe(
        (response) => {
          if (response.error === false) {
            this.alertService.showError({
              msg: 'Hubo un error actualizando los datos',
            });
          } else {
            this.alertService.showSuccess({ msg: 'Se han actualizado los datos correctamente' });
            this.navigationService.navigateTo({ path: 'profile'});
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'Hubo un error actualizando los datos',
          });
        }
      );
    } else {
      const data = {
        historial: this.academicHistoryArray.value,
      };
      this.apiService
        .put({ api: this.ACADEMIC_HISTORY_API + this.academicHistoryId, data })
        .subscribe(
          (response) => {
            if (response.success === false) {
              this.alertService.showError({
                msg: 'Hubo un error actualizando los datos',
              });
            } else {
              this.alertService.showSuccess({ msg: 'Se han actualizado los datos correctamente' });
              this.navigationService.navigateTo({ path: 'profile'});
            }
          },
          (err) => {
            this.alertService.showError({
              msg: 'Hubo un error actualizando los datos',
            });
          }
        );
    }
  }

  /**
   * Uploads a diploma file to S3
   * @param event file upload event
   * @param index index of the entry in the academicHistory array
   */
  onUploadDiploma({ event, index }: { event: any; index: number }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Academic-history.you-must-upload-1-file'));
    } else {
      this.ngxSpinner.show();
      const subKey = this.s3Service.generateKey();
      const studyName = this.academicHistoryArray.controls[index].get(
        'estudiosRealizados'
      ).value;
      const file = event.target.files[0];
      const key = `user/${this.id}/studies/${studyName}/diploma/${subKey}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.alertService.showError({
            msg: 'Hubo un error subiendo el documento',
          });
          this.ngxSpinner.hide();
        } else {
          // Assign the value to the corresponding component
          this.academicHistoryArray.controls[index]
            .get('diploma')
            .setValue(data.Location);
          this.ngxSpinner.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

  /**
   * Uploads a certificate file to S3
   * @param file upload event
   * @param index index of the entry in the academic history array
   */
  onUploadCertificate({ event, index }: { event: any; index: number }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Academic-history.you-must-upload-1-file'));
    } else {
      this.ngxSpinner.show();
      const subKey = this.s3Service.generateKey();
      const studyName = this.academicHistoryArray.controls[index].get(
        'estudiosRealizados'
      ).value;
      const file = event.target.files[0];
      const key = `user/${this.id}/studies/${studyName}/certificate/${subKey}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.alertService.showError({
            msg: 'Hubo un error subiendo el documento',
          });
          this.ngxSpinner.hide();
        } else {
          // Assign the value to the corresponding component
          this.academicHistoryArray.controls[index]
            .get('certificadoDeNotas')
            .setValue(data.Location);
          this.ngxSpinner.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }

  /**
   * Creates a date with a format that is recognized by browser date inputs
   * @param date date in string format
   */
  createdFormattedDate({ date }: { date: string }): string {
    if (date !== null) {
      return new Date(date).toISOString().substring(0, 10);
    } else {
      return null;
    }
  }
}
