<div class="form-container">
  <nb-card>
    <nb-card-header>
      <h2 class="title">{{data.isEditing ? 'Editar programa' : 'Añadir programa'}}</h2>
    </nb-card-header>
    <nb-card-body>
      <form #newProgram="ngForm">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="universidad" class="label">Universidad</label>
              <br/>
              <input class="input" nbInput name="universidad" fullWidth id="universidad" #universidad="ngModel" [(ngModel)]="additionalFav.universidad" required>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="programa" class="label">Programa</label>
              <br/>
              <input class="input" nbInput name="programa" fullWidth id="programa" #programa="ngModel" [(ngModel)]="additionalFav.programa" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="tipoPrograma" class="label">Tipo programa</label>
              <br/>
              <input class="input" nbInput name="tipoPrograma" fullWidth id="tipoPrograma" #tipoPrograma="ngModel" [(ngModel)]="additionalFav.tipoPrograma" required>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="ubicacion" class="label">Ubicación</label>
              <br/>
              <input class="input" nbInput name="ubicacion" fullWidth id="ubicacion" #ubicacion="ngModel" [(ngModel)]="additionalFav.ubicacion" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="stdLength" class="label">Length</label>
              <br/>
              <input class="input" nbInput name="stdLength" fullWidth id="stdLength" #stdLength="ngModel" [(ngModel)]="additionalFav.stdLength" required>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="start" class="label">Start date</label>
              <br/>
              <input class="input" nbInput name="start" fullWidth id="start" #start="ngModel" [(ngModel)]="additionalFav.start" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="fee" class="label">App fee</label>
              <br/>
              <input class="input" nbInput name="fee" fullWidth id="" #fee="ngModel" [(ngModel)]="additionalFav.fee" required>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="tuitionCost" class="label">Tuition cost</label>
              <br/>
              <input class="input" nbInput name="tuitionCost" fullWidth id="tuitionCost" #tuitionCost="ngModel" [(ngModel)]="additionalFav.tuitionCost" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="link" class="label">URL de destino</label>
              <br>
              <input class="input" nbInput name="link" fullWidth id="" #link="ngModel" [(ngModel)]="additionalFav.link" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="link" class="label">Comentarios</label>
              <br>
              <input class="input" nbInput name="comentarios" fullWidth id="" #link="ngModel" [(ngModel)]="additionalFav.comentarios">
            </div>
          </div>
        </div>
        <div class="d-flex-end" *ngIf="!data.isEditing">
          <button nbButton class="aggregate-button" (click)="onAddFavourite(newProgram)" type="submit">Agregar</button>
        </div>
        <div class="d-flex" *ngIf="data.isEditing">
          <button nbButton class="aggregate-button normal-button" (click)="onDeleteFavourite(newProgram)" type="button">Eliminar</button>
          <button nbButton class="aggregate-button" (click)="onEditFavourite(newProgram)" type="submit">Editar</button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>
