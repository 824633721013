import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-view-webinar',
  templateUrl: './card-view-webinar.component.html',
  styleUrls: ['./card-view-webinar.component.scss']
})
export class CardViewWebinarComponent implements OnInit {

  @Output() applyWebinar = new EventEmitter<any>();
  @Input() webinar;

  constructor() { }

  ngOnInit(): void {

  }

  public onApply() {
    this.applyWebinar.emit();
  }

}
