<div>
    <div class="col-xl-12">
        <h2 class="title-tip">¿Qué es una carta de intención?</h2>
    </div>
    <div class="col-xl-12">
        <p>
            La carta de intención o conocida también por su siglas en inglés SOP (Statement of Purpose) , 
            es quizá uno de los requisitos más importantes para ser aceptado en una institución universitaria 
            internacional o nacional; a través de este documento tienes la oportunidad de presentarte y convencer 
            a los Admision Officers de que eres la persona indicada y eres elegible para un cupo en su universidad.
        </p>
    </div>
    <div class="col-xl-12 container-sub-title">
        <h3 class="sub-title-tip">¡Para tener en cuenta!</h3>
    </div>
    <div class="col-xl-12">
        <p>Los siguientes son elementos que definen un excelente SOP (Statement of Purpose)</p>
    </div>
    <div class="row container-item-sop">
        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/documento-estrella.png" alt="">
            <label>Experiencia personal</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/hombre-estrella.png" alt="">
            <label>Experiencia profesional</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/mano-moneda.png" alt="">
            <label>Soporte financiero</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/universidad.png" alt="">
            <label>Justifica tu elección de universidad, país y programa</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/gorro-universidad.png" alt="">
            <label>Intereses y planes  académicos/profesionales</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/Group.png" alt="">
            <label>Descripción de tus planes futuros</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/certificado.png" alt="">
            <label>Logros notables académicos y extracurriculares</label>
        </div>

        <div class="col-xl-6 box-item-sop">
            <img class="img-tip" src="/assets/u360tips/mano-corazon.png" alt="">
            <label>Servicio comunitario y trabajo caritativo</label>
        </div>

    </div>
</div>